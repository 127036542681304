import './PayoutMethodGiftCard.css';
import {useEffect, useState} from 'react';
import React from 'react';
import { getCompanyInvites, getPayoutTransactions, getPayoutMethods, savePayoutMethod, getUserEmail } from '../../utils/api';
import congrats from '../../assets/design/confetti.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const PayoutMethodGiftCard = ({userType}) => {
  const [options, setOptions] = useState([]);
  const [methods, setMethods] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState({});
  const [email, setEmail] = useState('');
  let refEmail = React.createRef();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadPayoutMethods(){
      let res = await getPayoutMethods('GiftCard');

      console.log('payout methods', res);

      if (res.success){
        setMethods(res.data);
      }

      else{
        console.log('no payout methods');
      }
    }

    async function loadEmail(){
      let _email = await getUserEmail();
      if (_email.success)
        setEmail(_email.data);
    }

    loadPayoutMethods();
    loadEmail();

    document.title = "LeadrPro - Manage Payouts";
  }, []);

  async function handleClick(item){
    setSelectedMethod(item);
    setShowModal(true);

    console.log(item);
  }

  async function handleEmailClick(){
    setShowModal(false);
    setShowModal2(true);
  }

  async function handleConfirmClick(){
    let res = await savePayoutMethod(selectedMethod.payout_method_id, email);

    if (res.success){
      //setShowModal(false);
      //setShowModal2(false);
      //setShowModalConfirm(true);
      navigate('/account/payout');
    }
  }

  async function handleConfirmClick2(){
    let res = await savePayoutMethod(selectedMethod.payout_method_id, refEmail.current.value);

    if (res.success){
      setShowModal(false);
      setShowModal2(false);
      setShowModalConfirm(true);

      navigate('/account/payout');
    }
  }

  return (
    <div className="accountinfo payoutmethodgiftcard">
      <div className="account__back-link"><a href="/account/payout">Back</a></div>
      <div className="account__header">Gift Cards</div>

      <div className='accountinfo__form payoutmethods__form'>
        <h3>Choose one of the gift cards</h3>

        <div className='payoutmethodgiftcard__cards'>
          {methods.map(item => (
            <div className={`payoutmethodgiftcard__card ${"payoutmethodgiftcard__card--" + item.display_name.toLowerCase()}`} onClick={() => handleClick(item)}>
              <div className="payoutmethodgiftcard__card-name">{item.display_name}</div>

              <div className="payoutmethodgiftcard__card-logo-small">
              <span><img src={"/assets/payoutmethods/" + item.icon_url} alt="" /></span>
              </div>

              <div className="payoutmethodgiftcard__card-logo-large">
              <span><img src={"/assets/payoutmethods/lg-" + item.icon_url} alt="" /></span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
      <div className="overlay">
      <div className="modal payoutmethodgiftcard__modal">
      <div className="modal__close" onClick={() => setShowModal(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>

        <h2>Enter the best email to send the gift card to</h2>

        <p style={{marginTop: "50px"}}>
          We're going to send the card to <strong>{email}</strong>
        </p>

        <div className="form-group">
          <button className="btn btn--primary" onClick={() => handleConfirmClick()}>Confirm</button>
        </div>

        <div className="form-group" style={{marginTop:"50px"}}>
          If not provide best email
        </div>

        <div className="form-group">
          <button className="btn" onClick={() => handleEmailClick()}>New email</button>
        </div>
        </div>
      </div>
      )}

    {showModal2 && (
      <div className="overlay">
      <div className="modal payoutmethodadd__modal">
      <div className="modal__close" onClick={() => setShowModal2(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>

        <h2>Enter the best email to send the gift card to</h2>

        <div className="form-group">
          <label className="label">Email</label>
          <input type="text" className="input" ref={refEmail} placeholder='Email' />
        </div>

        <div className="form-group">
          <button className="btn btn--primary" onClick={() => handleConfirmClick2()}>Confirm</button>
        </div>
        </div>
      </div>
      )}

    {showModalConfirm && (
      <div className="overlay">
      <div className="modal payoutmethodadd__modal">
      <div className="modal__close" onClick={() => setShowModalConfirm(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>

        <div>
          <img src={congrats} alt="" />
        </div>

        <div className="form-group">
          Success! You will now receive gift cards to this email.
        </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default PayoutMethodGiftCard;