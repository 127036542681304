import './Policies.css';
import React, { useEffect } from "react";

function Policies() {

  useEffect(() => {
    document.title = 'LeadrPro - Policies';
  }, []);

    return (
      <>
      <div className='policies'>
        <h1>LeadrPro Policies</h1>

        <div className='policies__menu'>
          <div><a href='#noshow'>No-show policy</a></div>
          <div><a href='#reschedule'>Reschedule policy</a></div>
          <div><a href='#cancel'>Seller cancellation policy</a></div>
          <div><a href='#payout'>Payout/Attendance policy</a></div>
          <div><a href='#generalhost'>General policy - hosts</a></div>
          <div><a href='#generalparticipant'>General policy - participants</a></div>
          <div><a href='#integrity'>LeadrPro Integrity and Compensation Policy</a></div>
        </div>

        <div className='policies__item' id='noshow'>
          <h2>No-Show Policy</h2>

          <p>If you are hosting a demo, please keep the following guidelines in mind:</p>

          <ul>
            <li>You must show up to scheduled demo's on your queue.</li>
            <li>If you want to reschedule or cancel your demo you must do so 12 hours before the scheduled demo time in order to give the other party notice.
</li>
            <li>Before leaving a no show call, please send the other party a message.</li>
            <li>If you are running late, send a message to the other participant to let them know.</li>
          </ul>

          <p>If you are a paid participant in a demo, please keep the following guildelines in mind:</p>

          <ul>
            <li>You must show up to scheduled demo's on your queue.</li>
            <li>If you want to reschedule or cancel your demo you must do so 12 hours before the scheduled demo time.
</li>
            <li>Don't stay on the call for more than 10 mins if the other party has not yet arrived.</li>
            <li>Before leaving a no show call, please send the other party a message.</li>
            <li>If you are running late, send a message to the other participant to let them know.</li>
          </ul>
        </div>

        <div className='policies__item' id='reschedule'>
          <h2>Reschedule Policy</h2>

          <ul>
            <li>If you want to reschedule your demo you must do so 12 hours before the scheduled demo time.</li>
            <li>Please send a message to the other party when rescheduling your demo.</li>
            <li>You may only reschedule a demo twice before it is automatically declined.</li>
          </ul>
        </div>

        <div className='policies__item' id='cancel' style={{marginBottom: '40px'}}>
          <h2>Seller Cancellation Policy</h2>

          <p>
          Should a seller decide to cancel a scheduled demo, it is important to note that a cancellation fee of $25 will be applicable.
          </p>
        </div>

        <div className='policies__item' id='payout'>
          <h2>Payout/Attendance Policy</h2>

          <ul>
            <li>Users must complete the scheduled meeting to get paid.</li>
            <li>If the meeting is not able to take place, you will not be paid for the meeting.</li>
            <li>Both parties must be present for a meeting to be marked as completed.</li>
            <li>You will only get paid for completed meetings, not simply for joining the meeting</li>
            <li>All scheduled meetings should happen on the Leadrpro platform unless otherwise approved by the meeting host.</li>
            <li>Any meetings that occur off platform will require manual verification and may take up to 3 business days for payouts to be processed.</li>
            <li>Users must complete the feedback form post-demo in order to receive their payout.</li>
          </ul>
        </div>

        <div className='policies__item' id='generalhost'>
          <h2>General Policy - For Hosts</h2>

          <ul>
            <li>All vendors must be selling a software solution or service.</li>
            <li>All vendors have the right to refuse or cancel a demo at any time at their discretion.</li>
            <li>All vendors are subject to final review before approval by LeadrPro.</li>
            <li>All demos are subject to final review before approval by LeadrPro.</li>
            <li>All demos may be cancelled at any time for any reason by LeadrPro.</li>
            <li>
              All LeadrPro demos must be run through the LeadrPro platform.
              <p>- If an add-on is enabled to use your own video host, you will still be required to join through the LeadrPro link.</p>
              <p>
              - If a demo is taken offline it may result in a forfeited demo payout or even account suspension.
              </p>
            </li>
          </ul>
        </div>

        <div className='policies__item' id='generalparticipant'>
          <h2>General Policy - For Participants</h2>

          <ul>
            <li>All users must have a valid use case for software solutions in a business setting.</li>
            <li>All users must be seeking software solutions for their business.</li>
            <li>All users must have a valid work email.</li>
            <li>All users must have a verified LinkedIn profile.</li>
            <li>All users job titles must match their current employment status on LinkedIn.</li>
            <li>All users must currently work at the company listed on their profile.</li>
            <li>Profile information must be accurate and up to date.</li>
            <li>Users participating in demos must be over the age of 18.</li>
            <li>Users are subject to final review before approval by LeadrPro.</li>
            <li>Demos are subject to final review before approval by LeadrPro.</li>
            <li>Any demo may be cancelled at any time for any reason by LeadrPro.</li>
          </ul>
        </div>

        <div id='integrity' className='policies__item'>
          <h2>LeadrPro Integrity and Compensation Policy</h2>

          <p><strong>1. Purpose: </strong>
This policy aims to uphold the quality and integrity of interactions between Buyers and Vendors on the LeadrPro platform. By ensuring accurate and honest responses, we create a trusted environment conducive to effective B2B sales demos. Crucially, adherence to this policy is directly tied to the compensation of Buyers for their demo participation.</p>
        <br/>

      <p><strong>2. Scope: </strong>
This policy is applicable to all Buyers registered on the LeadrPro platform.</p>
      <br/>

      <p>
        <strong>3. Policy: </strong>
        <div>
        <strong>a. Honest Qualifying Responses:</strong>
Buyers are required to provide accurate and honest answers to all qualifying questions.
Any misrepresentation, falsehood, or omission in qualifying responses is a breach of this policy.
        </div>
        <br/>
        
        <strong>b. Review and Verification:</strong>
        <p>
        LeadrPro retains the right to assess and validate the accuracy of the responses offered by Buyers.
Buyers discovered to have provided misleading or false answers may be subject to consequences, as detailed below.
        </p>
        <br/>

        <strong>c. Compensation Policy:</strong>
        <p>
        Buyers are eligible for the $75 payout only for demos where they have answered qualifying questions truthfully and accurately.
        In instances where dishonesty is detected, Buyers will not receive compensation for that particular demo.
        </p>
        <br/>

        <strong>d. Penalties for Misrepresentation: </strong>
        <br/>

        First Offense: Warning will be issued to the Buyer and forfeiture of compensation for the relevant demo.
          <br/>
Second Offense: Suspension of the Buyer's account for 30 days and forfeiture of compensation for the relevant demo.
        <br/>
        Third Offense: Permanent ban from the LeadrPro platform.
      </p>
      <br/>

      <strong>4. Reporting: </strong>

      <p>Vendors are encouraged to highlight cases where they suspect a Buyer has not been truthful in their qualifying responses. All such reports will undergo a rigorous review process to ascertain their validity.</p>
      <br/>

      <strong>5. Appeals: </strong>
      <p>Buyers who feel they have been unjustly accused of dishonesty have the right to appeal the decision. Such appeals will be evaluated by a dedicated team at LeadrPro to guarantee fairness.</p>
      <br/>

      <strong>6. Review: </strong>
      <p>LeadrPro will reassess this policy either annually or whenever deemed necessary by our management team.
</p>        
</div>

        {/* <div className='policies__item'>
          <h2>Reschedule Policy</h2>

          <p>
          NO SHOW POLICIES
BUYER NO SHOW POLICY
you can’t no show
if you want to reschedule or cancel you must do so 12 hours before the scheduled demo time
no show policy
each no show will decrease earning potential and credibility score
don’t stay on the call for more than 10 mins
before leaving a no show call send other party a message
if running late send a DM
SELLER NO SHOW POLICY
you can’t no show
if you want to reschedule or cancel you must do so 12 hours before the scheduled demo time
sellers pay flat rate of $75 (highest buyer payout)
don’t stay on the call for more than 10 mins
before leaving a no show call send other party a message
if running late send a DM
          </p>
        </div> */}
    </div>
    </>
    );
}

export default Policies;