import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { addEmailCampaign, getFaqQuestions, getEventsRoundtable, getEventsWebinars } from '../../utils/api';
import { getTrimmedString } from '../../utils/utils';
import './HomepageHub_Events.css';
import medialogos from '../../assets/design/logos/medialogos.png';

import hub1 from '../../assets/design/homepagehub/hub1b.png';
import hub2 from '../../assets/design/homepagehub/softwarehub2.png';
import hub3 from '../../assets/design/homepagehub/hub3b.png';
import pattern1 from '../../assets/design/homepagehub/pattern1.png';
import pattern2 from '../../assets/design/homepagehub/pattern2.png';
import vendor1 from '../../assets/design/homepagehub/vendor1.png';
import vendor2 from '../../assets/design/homepagehub/vendor2.png';
import blog1 from '../../assets/design/homepagehub/blog1.png';
import blog2 from '../../assets/design/homepagehub/blog2.png';
import blog3 from '../../assets/design/homepagehub/blog3.png';
import footer from '../../assets/design/homepagehub/footer.png';
import herocards from '../../assets/design/homepagehub/software/herocards.png';
import herobg from '../../assets/design/homepagehub/software/herobg.png';
import heroicon from '../../assets/design/homepagehub/events/heroicon.png';
import paymentlogos from '../../assets/design/homepagehub/software/paymentlogos.png';
import relevance from '../../assets/design/homepagehub/software/softwarehub_relevance.png';
import logobg from '../../assets/design/homepagehub/software/logo_bg.png';
import booking from '../../assets/design/homepagehub/software/booking.png';
import relevant from '../../assets/design/homepagehub/software/rel.png';
import heroimage from '../../assets/design/homepagehub/events/eventshero.png';
import StartDiscovering from './StartDiscovering';
import ScrollToTop from '../../components/layout/ScrollToTop';
import oursponsors from '../../assets/design/homepagehub/events/oursponsors.png';
import moment from 'moment-timezone';

function HomepageHub_Events() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [faq,setFaq] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [roundtable, setRoundtable] = useState([]);
  const [webinars, setWebinars] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  function formatDate(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    
    // Determine the appropriate suffix for the day
    let daySuffix;
    if (day >= 11 && day <= 13) {
        daySuffix = "th";
    } else {
        switch (day % 10) {
            case 1: daySuffix = "st"; break;
            case 2: daySuffix = "nd"; break;
            case 3: daySuffix = "rd"; break;
            default: daySuffix = "th";
        }
    }
    
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;

    //const timezone = moment.tz.guess(); // Assuming the time is in EST
    let timezone = moment.tz(moment.tz.guess()).format('z');  // PST or PDT

    return `${month} ${day}${daySuffix}, ${year} • ${hours12}:${minutes} ${ampm} ${timezone}`;
}

  useEffect(() => {
    async function loadData(){
      
    }

    async function loadRoundtable(){
      let res = await getEventsRoundtable();

      if (res.success){
        setRoundtable(res.data);
      }
    }

    async function loadWebinars(){
      let res = await getEventsWebinars();

      if (res.success){
        setWebinars(res.data);
      }
    }

    async function loadFaq(){
      let res = await getFaqQuestions('events');

      if (res.success){
        setFaq(res.data);
      }
    }

    loadData();
    loadFaq();
    loadRoundtable();
    loadWebinars();
  }, []);

  async function handleFaqClick(item){
    setSelectedFaq(item);
  }

  async function handleNewsletterSubmit(){
    setNewsletterLoading(true);

    if (newsletterEmail.length === 0){
      alert('Must enter a valid email');
      setNewsletterLoading(false);
      return;
    }

    let res = await addEmailCampaign(newsletterEmail, 'Discover');

    if (res.success){
      setNewsletterDone(true);
      setNewsletterLoading(false);
      setNewsletterEmail('');
    }
  }

  return (
    <div className='homepagehubevents'>
      <ScrollToTop />
      <Helmet>
        <title>LeadrPro - Attend local events and webinars</title>
      </Helmet>

      <div className='homepagehubevents__hero'>
        <div className='homepagehubevents__hero-left'>
          <div className='homepagehubevents__hero-icon'>
            <img src={heroicon} alt='' /> <span className='homepagehubevents__hero-icon-text'>Events Hub</span>
          </div>

          <div className='homepagehub__hero-h1'>
            Network with<br/>
            industry experts
          </div>

          <div className='homepagehub__hero-subheader'>
            Discover in-person events or online webinars where you can meet and network with industry experts who also share their stories and experiences building successful businesses.
          </div>

          <div className='homepagehub__hero-btns'>
            <button onClick={() => navigate('/events')} className='homepagehubevents__hero-btn homepagehubevents__hero-btn--primary'>
              Find an event
            </button>

            {/* <button className='homepagehub__hero-btn'>
              Host an event
            </button> */}
          </div>

          <div className='homepagehubevents__our-sponsors'>
              <div className='homepagehubevents__our-sponsors-label'>
                Our sponsors
              </div>

              <div className='mt-20'>
                <img src={oursponsors} alt='' />
              </div>
          </div>
        </div>

        <div className='homepagehubevents__hero-right'>
          <img src={heroimage} alt='' />
        </div>
      </div>

      <div className='homepagehubevents__events-wrapper'>
          <div className='homepagehubevents__events-header'>
              Upcoming roundtable events
          </div>

          <div className='homepagehubevents__events-list'>
            {roundtable.filter(x => new Date(x.start_date) >= new Date()).map(item => (
              <div className='homepagehubevents__event'>
                <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`} className='link'>
                <div className='homepagehubevents__event-thumbnail'>
                  {item.image_url && item.image_url.length > 0 && (
                    <img src={item.image_url} alt='' />
                  )}

                  {(item.image_url == null || item.image_url == '') && (
                    <img src='https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675' />
                  )}
                </div>

                <div className='homepagehubevents__event-title'>
                  {item.name}
                </div>

                <div className='homepagehubevents__event-date-location'>
                  <div className='homepagehubevents__event-date'>
                    {formatDate(new Date(item.start_date))}
                  </div>

                  <div className='homepagehubevents__event-location'>
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.8272 0.0892003C4.36574 0.35642 3.09739 1.01853 2.06141 2.05513C0.770863 3.34638 0.000312632 5.15425 6.10456e-08 6.89137C-0.00031251 8.71756 1.19971 11.182 3.75273 14.5982C4.6248 15.7652 6.08132 17.5303 6.37877 17.7808C6.72597 18.0731 7.27709 18.0731 7.6243 17.7808C8.1643 17.3261 10.5174 14.3272 11.5448 12.7842C12.8329 10.8497 13.649 9.14854 13.9067 7.86091C14.1304 6.74262 13.9484 5.42078 13.3979 4.16493C12.5999 2.34462 10.8771 0.825744 8.98667 0.275854C8.02352 -0.0043144 6.75029 -0.0795012 5.8272 0.0892003ZM6.53268 4.05859C5.4383 4.23524 4.5566 4.98154 4.17595 6.0533C4.00316 6.53995 4.00316 7.45646 4.17595 7.94311C4.49327 8.83666 5.16149 9.50733 6.04825 9.8224C6.54818 9.99999 7.45489 9.99999 7.95481 9.8224C8.84158 9.50733 9.50979 8.83666 9.82711 7.94311C10.0015 7.45214 9.99996 6.5402 9.82405 6.04436C9.33563 4.66803 7.947 3.83022 6.53268 4.05859Z" fill="white"/>
</svg>

                  <span className='ml-10'>{item.city} {item.state}</span>
                  </div>
                </div>

                <div className='homepagehubevents__event-description'>
                  {getTrimmedString(item.description, 120)}
                </div>
                </Link>
              </div>
            ))}
          
          </div>
      </div>

      <div className='homepagehubevents__events-wrapper'>
          <div className='homepagehubevents__events-header'>
              Upcoming webinars
          </div>

          <div className='homepagehubevents__events-list'>
            {webinars.filter(x => new Date(x.start_date) >= new Date()).map(item => (
              <div className='homepagehubevents__event'>
                <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`} className='link'>
                <div className='homepagehubevents__event-thumbnail'>
                  {item.image_url && item.image_url.length > 0 && (
                    <img src={item.image_url} alt='' />
                  )}

                  {(item.image_url == null || item.image_url == '') && (
                    <img src='https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675' />
                  )}
                </div>

                <div className='homepagehubevents__event-title'>
                  {item.name}
                </div>

                <div className='homepagehubevents__event-date-location'>
                  <div className='homepagehubevents__event-date'>
                    {formatDate(new Date(item.start_date))}
                  </div>
                </div>

                {item.short_description && item.short_description.length > 0 && (
                  <div className='homepagehubevents__event-description'>
                    {getTrimmedString(item.short_description, 120)}
                  </div>
                )}
                </Link>
              </div>
            ))}
            
          </div>
      </div>

      <div className='homepagehubevents__get-notified'>
        <div className='homepagehubevents__get-notified-header'>
          Don't want to miss out on future events? Get notified of exciting new events and webinars
        </div>

        <div className=''>
        <div className='homepagehubevents__get-notified-input'>
            <div className='homepagehub__blog-newsletter-input'>
              <input type='text' placeholder='Your email address' onChange={(e) => setNewsletterEmail(e.target.value)} />

              {newsletterLoading && (
                <Spinner />
              )}

              {newsletterDone && !newsletterLoading && (
                <button>Success!</button>
              )}

              {newsletterLoading === false && newsletterDone === false && (
              <button className='homepagehub__blog-newsletter-btn' onClick={() => handleNewsletterSubmit()}>
                Subscribe
              </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='homepagehubsoftware__vendor-hub'>
        <div className='homepagehubsoftware__vendor-hub-bg'>
          <img src={logobg} alt='' />
        </div>
        <div className='homepagehubsoftware__vendor-hub-copy'>
          <div className='homepagehubevents__sponsor-h2'>Interested in sponsoring an event or webinar?</div>

          <div className='homepagehubevents__vendor-hub-text mt-30'>
            Take advantage of sponsoring an event or webinar and get High Visibility,
          Marketing to a Targeted Audience and Positive Brand Association.
          </div>
        </div>

        <div className='homepagehubsoftware__vendor-hub-cta'>
          <button onClick={() =>navigate('/sell-software')}>Sponsor an event</button>
        </div>
      </div>

      {faq.length > 0 && (
      <div className='homepagehub__faq'>
        <div className='center homepagehub__faq-title'>Frequently Asked Questions</div>

        <div className='homepagehub__faq-items'>
          {faq.map((item, index) => (
            <div key={index} className='homepagehub__faq-item'>
              <div className='homepagehub__faq-item-question' onClick={() => handleFaqClick(item)}>
                {item.question}
              </div>

              {selectedFaq != null && selectedFaq.faq_question_id == item.faq_question_id && (
                <FadeIn delay={300} duration={300}>
                <div className='homepagehub__faq-item-answer'>
                  {item.answer}
                </div>
                </FadeIn>
              )}
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
}

export default HomepageHub_Events;