import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getMonthlyReport, getMonthlyReportByMonth, getMailOutboxCampaigns, getMailOutboxEmais, createSellerRequestCampaign, sendEventUserRewardCampaign } from '../../utils/api/admin';
import './MailOutbox.css';

function MailOutbox() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [emails, setEmails] = useState([]);
  const [emailsLoaded, setEmailsLoaded] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showEmails, setShowEmails] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [sources, setSources] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [sourceId, setSourceId] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [isSingleEmail, setIsSingleEmail] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const [subject, setSubject] = useState('');
  const [singleEmail, setSingleEmail] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  async function loadData(){
    let res = await getMailOutboxCampaigns();
    if (res.success){
      setCampaigns(res.data);
      setLoaded(true);

      let array_sources = res.data.map(x => x.type).filter((type, index, self) => self.indexOf(type) === index);

      setSources(array_sources);

      let array_temp = [];

      for (let i = 0; i < res.data.length; i++){
        if (array_temp.includes(res.data[i].name) == false){
          array_temp.push(res.data[i].name);
        }
      }

      setTemplates(array_temp);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getMailOutboxCampaigns();
      if (res.success){
        setCampaigns(res.data);
        setLoaded(true);

        let array_sources = res.data.map(x => x.type).filter((type, index, self) => self.indexOf(type) === index);

        setSources(array_sources);

        let array_temp = [];

        for (let i = 0; i < res.data.length; i++){
          if (array_temp.includes(res.data[i].name) == false){
            array_temp.push(res.data[i].name);
          }
        }

        setTemplates(array_temp);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleShowEmails(mail_outbox_campaign_id){
    setShowEmails(true);
    setSelectedCampaignId(mail_outbox_campaign_id);
  }

  useEffect(async () => {
    if (selectedCampaignId){
      let res = await getMailOutboxEmais(selectedCampaignId);

      if (res.success){
        setEmails(res.data);
        setEmailsLoaded(true);
      }
    }
  }, [selectedCampaignId]);

  useEffect(() => {
  }, [selectedTemplate]);

  async function handleSourceChange(val){
    setSelectedType(val);
  }

  async function handleSendCampaign(){
    let res = await createSellerRequestCampaign(selectedCompanyId, subject, quantity, selectedCampaignId, singleEmail);

    if (res.success){
        loadData();
        setShowCreate(false);
    }
  }

  async function handleSendEventUser(version){
    let res = await sendEventUserRewardCampaign(version);

    if (res.success){
      alert('sent');
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Mail Campaigns</title>
      </Helmet>

      <h1>Mail Campaigns</h1>

      <p>
        Outbound email campaigns with tracking.
      </p>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
          <div>
            <div className='mailoutbox__filters'>
              <div>
                <select className='select input' onChange={(e) => setSelectedTemplate(e.target.value)}>
                  <option>Select template...</option>
                  
                  {templates.map(t => (
                    <option value={t}>
                      {t}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <select className='select input' onChange={(e) => handleSourceChange(e.target.value)}>
                  <option>Select source...</option>

                  {sources.map(item => (
                    <option value={item.toLowerCase()}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className=''>
                  <input type='text' className='input' placeholder='Source ID...' value={sourceId} onChange={(e) => setSourceId(e.target.value)} />
              </div>

              <div>
                <button className='btn' onClick={() => setShowCreate(true)}>+ Create Campaign</button>
              </div>

              <div>
                <button className='btn' onClick={() => handleSendEventUser('A')}>Event User Reward: A</button>

                <button className='btn ml-10' onClick={() => handleSendEventUser('B')}>Event User Reward: B</button>
              </div>
            </div>

          <table className='admindashboard__table table'>
            <tr>
              <th>Date sent</th>
              <th>Template</th>
              <th>Source</th>
              <th>Details</th>
              <th>Sent</th>
              <th>Clicked</th>
              <th>Applications</th>
              <th>Approved</th>
              <th></th>
            </tr>
            {campaigns.filter(x => (x.name == selectedTemplate || selectedTemplate == '') && 
              (x.type.toLowerCase() == selectedType || selectedType == '') && 
              (x.source_id == sourceId || sourceId == '')
            ).map(item => (
              <tr>
                <td>
                  <strong>{daysOfWeek[new Date(item.date_created).getDay()]}</strong>{' '}
                  {new Date(item.date_created).toLocaleString()}</td>

                <td>
                  <div>{item.name}</div>
                  <div>Subj: {item.subject}</div>
                  <div>{item.template_name}</div>
                  </td>

                <td>
                  <div>
                    {item.type}
                  </div>

                  <div>
                    {item.source_id}
                  </div>
                  </td>

                <td>
                  {item.type == 'Company' && (
                    <div className='flex' style={{alignItems: 'center'}}>
                      <div className=''>
                       <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} style={{maxHeight: '50px', borderRadius: '6px', maxWidth: 'unset'}} />
                     </div>

                      <div className='ml-10'>{item.company_name}</div>
                    </div>
                  )}
                </td>

                <td>{item.number_sent}</td>
                <td>{item.number_clicked}</td>

                <td>
                  {item.type == 'Company' && (
                    <div>
                      {item.application_count}
                    </div>
                  )}
                </td>

                <td>
                  {item.type == 'Company' && (
                    <div>
                      {item.approved_count}
                    </div>
                  )}
                </td>

                <td>
                  <button className='btn' onClick={() => handleShowEmails(item.mail_outbox_campaign_id)}>View emails</button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

    {showEmails && (
        <>
          <div className='overlay'></div>
          <div className='modal mailoutbox__modal'>
            <div className='modal__close' onClick={() => setShowEmails(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Mail Outbox</h2>

            <div className='mt-10'>
              <table className='admindashboard__table table'>
              <th>Date sent</th>
                <th>Email</th>
                <th>Company</th>
                <th># of apps</th>
                <th></th>
                {emails.map(item => (
                  <tr>
                    <td>{new Date(item.date_created).toLocaleString()}</td>

                    <td>
                      <div>
                        {new Date(item.user_register_date).toLocaleDateString()}
                      </div>
                      <div>
                        {item.first_name} {item.last_name}
                      </div>

                      <div>
                        {item.email}
                      </div>

                      <div>
                        {item.job_title}
                      </div>

                      <div>
                        Verified: {item.work_email_verified == true ? <span class="admindashboard__green-flag">Yes</span> : <span class="admindashboard__red-flag">No</span>}
                      </div>
                    </td>

                    <td>{item.company_name}</td>

                    <td>{item.demo_applications}</td>

                    <td>
                    <div className={`admindashboard__demo-request-status ${item.status.toLowerCase()}`}>
                      {item.status}
                    </div>
                  </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}

      {showCreate && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowCreate(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Create Campaign</h2>

            <div>
              <div>
                <select className='select input' value={selectedCampaignId} onChange={(e) => setSelectedCampaignId(e.target.value)}>
                  <option>Select campaign...</option>
                  <option value='1'>Buyer Request - Monday</option>
                  <option value='2'>Buyer Request - Xseed</option>
                  <option value='3'>Buyer Request - Hatica</option>
                  <option value='4'>Buyer Request - Fraud</option>
                </select>
              </div>

              <div className='mt-10'>
                <div><strong>Company</strong></div>
                <select className='input select' value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
                  <option>Select company</option>
                  <option value='12621'>Monday.com</option>
                  <option value='14457'>Xseed</option>
                  <option value='12710'>Hatica</option>
                  <option value='9507'>Fraud.net</option>
                </select>
              </div>

              <div className='mt-10'>
                <div><strong>Quantity</strong></div>
                <input type='text' className='input' value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder='How many emails to send' />
              </div>

              <div className='mt-10'>
                <div><strong>Subject</strong></div>
                <input type='text' className='input' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject...' />
              </div>

              <div className='mt-10'>
                <input type='checkbox' checked={isSingleEmail} onChange={(e) => setIsSingleEmail(e.target.checked)} /> <label>Single user</label>

                {isSingleEmail && (
                <div className='mt-10'>
                  <input type='text' value={singleEmail} onChange={(e) => setSingleEmail(e.target.value)} placeholder='Email' className='input' />
                </div>
                )}
                </div>

                <div className='mt-10'>
                  <button className='btn' onClick={() => handleSendCampaign()}>Send campaign</button>
                </div>
              </div>
            </div>
        </>
      )}
    </>
  );
}

export default MailOutbox;