import React from 'react';
import { chakra, Heading, Box, Flex, Link, Image } from '@chakra-ui/react';

const CategoryMaster = () => {
  const categories = [
    {
      title: 'Cloud Infrastructure Monitoring Software',
      overview:
        'Cloud infrastructure monitoring software is a specialized tool designed to provide real-time visibility into the performance, availability, and utilization of cloud-based resources. These tools play a vital role in managing and maintaining cloud environments, ensuring optimal performance and resource allocation.',
      url: 'https://www.leadrpro.com/cloud-infrastructure-monitoring-software',
    },
    {
      title: 'Contract Management Software',
      overview:
        'Contract management software is a software solution designed to help businesses manage their contracts more effectively. The software typically includes features for contract creation, negotiation, approval, storage, and analysis. Contract management software is suitable for a wide range of businesses, including healthcare, legal, government, and finance.',
      url: 'https://www.leadrpro.com/contract-management-software',
    },
    {
      title: 'DNS Security Software',
      overview:
        'DNS is a foundational technology that translates domain names into IP addresses, enabling users to access websites and other resources on the internet. DNS servers act as the "phone book" of the internet, mapping human-readable domain names to machine-readable IP addresses.',
      url: 'https://www.leadrpro.com/dns-security-software',
    },
    {
      title: 'Employee Advocacy Software',
      overview:
        'Employee advocacy software is a set of tools and applications designed to help businesses leverage the power of their employees social networks to promote their brand and reach new audiences. Employee advocacy software allows businesses to provide employees with pre-approved content and messaging that they can share on their personal social media accounts. With employee advocacy tools, businesses can increase their reach, engage with new audiences, and build their brand.',
      url: 'https://www.leadrpro.com/employee-advocacy-software',
    },
    {
      title: 'Equipment Rental Software',
      overview:
        'Equipment Rental Software is a software solution designed specifically for businesses that rent out equipment. The software typically includes features for managing inventory, tracking rental equipment and availability, generating invoices, and processing payments. Equipment Rental Software is suitable for a wide range of businesses, including construction companies, event rental companies, and equipment rental businesses in general.',
      url: 'https://www.leadrpro.com/equipment-rental-software',
    },
    {
      title: 'Experience Management Software',
      overview:
        'Experience management software is designed to help businesses enhance the experiences of their customers, employees, and products by providing a comprehensive platform for data collection, analysis, and personalized interactions. These platforms offer various functionalities to cater to the unique needs of different industries, such as retail, eCommerce, hospitality, healthcare, finance, and technology.',
      url: 'https://www.leadrpro.com/experience-management-software',
    },
    {
      title: 'Fraud Detection Software',
      overview:
        'Fraud detection software is a set of tools and applications designed to identify and prevent fraudulent activities. Fraud detection software can analyze data from various sources, including financial transactions, customer behavior, and historical data, to identify potential fraud. Fraud detection tools use advanced algorithms and machine learning to identify patterns and anomalies in data, allowing businesses to detect fraud before it becomes a significant problem.',
      url: 'https://www.leadrpro.com/fraud-detection-software',
    },
    {
      title: 'Interview Scheduling Software',
      overview:
        "Interview scheduling software is a powerful tool designed to streamline the hiring process by automating scheduling tasks, improving communication, and enhancing the candidate experience. In this guide, we'll explore the benefits of interview scheduling software, as well as some top recommendations for businesses looking to optimize their hiring process.",
      url: 'https://www.leadrpro.com/interview-scheduling-software',
    },
    {
      title: 'Invoice Management Software',
      overview:
        'Invoice management software is designed to streamline the invoicing process by automating tasks such as data entry, approval workflows, and payment processing. By doing so, it can help businesses save time, reduce errors, and improve cash flow management. ',
      url: 'https://www.leadrpro.com/invoice-management-software',
    },
    {
      title: 'Online Appointment Scheduling Software',
      overview:
        'Online appointment scheduling software has become a vital tool for businesses that rely on appointments, such as healthcare providers, salons, and spas. This software automates the process of scheduling appointments, making it easier for both businesses and customers to book and manage appointments. In this article, we will provide an overview of online appointment scheduling software, discuss its benefits, and offer recommendations on how to choose and implement it.',
      url: 'https://www.leadrpro.com/online-appointment-scheduling-software',
    },
    {
      title: 'Project Management Software',
      overview:
        'Project management software is a set of tools designed to help teams manage projects efficiently, collaborate effectively, and meet their goals. Project management collaboration tools can range from simple task management software to more complex project management platforms that include features such as team collaboration tools, time tracking, and reporting. With the rise of remote work and distributed teams, collaborative project management software has become increasingly popular as it allows teams to work together seamlessly, regardless of their location.',
      url: 'https://www.leadrpro.com/project-management-software',
    },
    {
      title: 'Recruiting Automation Software',
      overview:
        'Recruiting automation software is a set of tools and applications designed to automate the recruitment process. Recruiting automation software can streamline the hiring process by automating tasks such as resume screening, candidate tracking, and scheduling interviews. With recruiting automation tools, recruiters can save time and improve the efficiency of their hiring process.',
      url: 'https://www.leadrpro.com/recruiting-automation-software',
    },
    {
      title: 'Request For Proposal Software',
      overview:
        "Request for proposal (RFP) software is a tool that organizations can use to streamline their procurement process and improve vendor management. In this guide, we will explore what RFP software is, the benefits of using it, and some recommendations for selecting and implementing the right software for your organization's needs.",
      url: 'https://www.leadrpro.com/request-for-proposal-software',
    },
    {
      title: 'Sales Gamification Software',
      overview:
        'Sales gamification software is a tool that uses game-like elements to motivate and engage sales teams and improve their performance. It typically involves setting up challenges, competitions, and rewards that incentivize salespeople to achieve their goals and perform at their best. Sales gamification software is designed to make work more fun and engaging, while also providing valuable data and insights into sales performance and behavior.',
      url: 'https://www.leadrpro.com/sales-gamification-software',
    },
    {
      title: 'Team Building Software',
      overview:
        'Team building software is a collection of tools and applications designed to help teams work collaboratively, communicate effectively, and achieve common goals. Team building software can range from simple communication and collaboration tools to more complex project management platforms. With the rise of remote work, team building software has become increasingly popular as it allows teams to work together seamlessly, regardless of their location.',
      url: 'https://www.leadrpro.com/team-building-software',
    },
    {
      title: 'User Generated Content Software',
      overview:
        'User-generated content (UGC) has become an essential aspect of modern marketing strategies. With the rise of social media and online reviews, consumers have become increasingly influential in shaping brand reputations. UGC software helps businesses harness the power of UGC by curating and displaying user-generated content across various digital channels. In this guide, we will provide an overview of UGC software, its benefits, and recommendations for selecting the right software for your business.',
      url: 'https://www.leadrpro.com/user-generated-content-software',
    },
    {
      title: 'Video Interviewing Software',
      overview:
        'Video interviewing software is a tool designed to streamline the hiring process by allowing companies to interview candidates remotely using video technology. Instead of conducting in-person interviews, companies can use video interviewing software to conduct live or pre-recorded interviews, evaluate candidates, and collaborate with hiring teams. Video interviewing software can be used for various stages of the hiring process, including pre-screening, first-round interviews, and even final interviews.',
      url: 'https://www.leadrpro.com/video-interviewing-software',
    },
    {
      title: 'Data-As-A-Service Software',
      overview:
        'DaaS is a cloud-based data management solution that provides organizations with access to real-time data from various sources. This modern approach streamlines data integration, storage, quality, and security, enabling organizations to become more data-centric.',
      url: 'https://www.leadrpro.com/data-as-a-service-software',
    },
    {
      title: 'Payroll Software',
      overview:
        'Payroll software is a tool that automates payroll processes, making it easier for businesses to manage employee salaries, benefits, and taxes. It helps streamline the process of paying employees and ensures that they are paid accurately and on time. Payroll software comes in two types: cloud-based and on-premise.',
      url: 'https://www.leadrpro.com/payroll-software',
    },
    {
      title: 'Advertising Software',
      overview:
        'Advertising software, also known as ad tech or ad platforms, refers to the tools and technology used by marketers to create, manage, and optimize digital advertising campaigns across various channels such as search engines, social media, and display networks. Over the years, the advertising industry has evolved significantly, embracing the power of automation, data, and artificial intelligence (AI) to enhance efficiency and performance.',
      url: 'https://www.leadrpro.com/advertising-software',
    },
    {
      title: 'Contractor Management Software',
      overview:
        "Contractor management software is a tool that helps companies manage their independent contractors effectively. This software provides a centralized location to manage all aspects of a contractor's engagement, from onboarding and performance management to offboarding. The software automates many of the processes involved in contractor management, making it easier for companies to manage a large number of contractors at once.",
      url: 'https://www.leadrpro.com/contractor-management-software',
    },
    {
      title: 'Corporate Wellness Software',
      overview:
        'Corporate wellness software is a tool used by companies to help employees improve their physical and mental health. This software provides a platform for companies to manage wellness programs, track employee progress, and improve overall employee health. Companies can use this software to provide wellness programs such as health assessments, fitness programs, and nutrition coaching. Some examples of corporate wellness software include Virgin Pulse, Fitbit Health Solutions, and Limeade.',
      url: 'https://www.leadrpro.com/corporate-wellness-software',
    },
    {
      title: 'Corporate Wellness Software',
      overview:
        'Corporate wellness software is a tool used by companies to help employees improve their physical and mental health. This software provides a platform for companies to manage wellness programs, track employee progress, and improve overall employee health. Companies can use this software to provide wellness programs such as health assessments, fitness programs, and nutrition coaching. Some examples of corporate wellness software include Virgin Pulse, Fitbit Health Solutions, and Limeade.',
      url: 'https://www.leadrpro.com/corporate-wellness-software',
    },
    {
      title: 'Data-As-A-Service Software',
      overview:
        'DaaS is a cloud-based data management solution that provides organizations with access to real-time data from various sources. This modern approach streamlines data integration, storage, quality, and security, enabling organizations to become more data-centric.',
      url: 'https://www.leadrpro.com/data-as-a-service-software',
    },
    {
      title: 'Data Visualization Software',
      overview:
        'Data visualization software is a tool that allows users to transform complex data into visual representations such as charts, graphs, and maps. By presenting data visually, users can better understand patterns, trends, and insights that might not be easily visible in spreadsheets or databases. Data visualization software comes in a variety of forms, from general-purpose tools like Microsoft Excel to more specialized software like Tableau, Power BI, QlikView, and Google Data Studio.',
      url: 'https://www.leadrpro.com/data-visualization-software',
    },
    {
      title: 'Marketing Analytics Software',
      overview:
        'Marketing analytics software is a tool that enables businesses to track, measure, and analyze data related to their marketing campaigns. This data is used to make data-driven decisions that can help businesses improve their marketing strategies, increase their return on investment (ROI), and boost customer engagement and loyalty.',
      url: 'https://www.leadrpro.com/marketing-analytics-software',
    },
    {
      title: 'Payroll Software',
      overview:
        'Payroll software is a tool that automates payroll processes, making it easier for businesses to manage employee salaries, benefits, and taxes. It helps streamline the process of paying employees and ensures that they are paid accurately and on time. Payroll software comes in two types: cloud-based and on-premise.',
      url: 'https://www.leadrpro.com/payroll-software',
    },
    {
      title: 'Sales Coaching Software',
      overview:
        'Sales coaching software is a technology solution designed to enhance the skills, performance, and overall effectiveness of your sales team. Through a variety of features such as video-based training, AI-driven analytics, gamification elements, and performance tracking, these tools can help your sales reps develop and refine their sales strategies.',
      url: 'https://www.leadrpro.com/sales-coaching-software',
    },
    {
      title: 'Sales Engagement Software',
      overview:
        'Sales engagement software is a comprehensive platform designed to streamline and enhance the sales process by automating repetitive tasks, providing a centralized communication hub, and offering valuable insights to drive better results.',
      url: 'https://www.leadrpro.com/sales-engagement-software',
    },
    {
      title: 'Sales Training Software',
      overview:
        'Sales training software comes in different forms, from e-learning modules and virtual classrooms to gamified training programs and coaching platforms. Regardless of the type, these software solutions are designed to help sales teams improve their skills and knowledge, and ultimately, sell more effectively.',
      url: 'https://www.leadrpro.com/sales-training-software',
    },
    {
      title: 'Talent Management Software',
      overview:
        'Talent management software is a suite of HR tools designed to manage and develop talent within an organization. It includes features such as applicant tracking systems, performance management, learning management, succession planning, and compensation management. These tools help HR teams manage employee data, automate workflows, and provide real-time data analytics.',
      url: 'https://www.leadrpro.com/talent-management-software',
    },
    {
      title: 'Talent Management Software',
      overview:
        'Talent management software is a suite of HR tools designed to manage and develop talent within an organization. It includes features such as applicant tracking systems, performance management, learning management, succession planning, and compensation management. These tools help HR teams manage employee data, automate workflows, and provide real-time data analytics.',
      url: 'https://www.leadrpro.com/talent-management-software',
    },
  ];

  //alphabetical helper function
  function sortCategoriesByTitle(categories) {
    return [...categories].sort((a, b) => a.title.localeCompare(b.title));
  }

  const sortedCategories = sortCategoriesByTitle(categories);

  return (
    <>
      <Box px={24} py={24} mx='auto' mr={10} mb={36}>
        <Heading size='3xl' pb={16}>
          Software Categories
        </Heading>

        <Box px={10}>
          {sortedCategories.map((category) => (
            <Flex p={10} w='full' alignItems='center' justifyContent='center'>
              <Box
                mx='auto'
                px={8}
                py={4}
                rounded='lg'
                shadow='lg'
                bg='white'
                // maxW='2xl'
              >
                <Flex justifyContent='space-between' alignItems='center'>
                  <Link
                    fontSize='2xl'
                    color='gray.700'
                    href={category.url}
                    fontWeight='700'
                    _hover={{
                      color: 'gray.600',
                      _dark: {
                        color: 'gray.200',
                      },
                      textDecor: 'underline',
                    }}>
                    {category.title}
                  </Link>
                  <Link
                    px={3}
                    py={1}
                    href='https://www.leadrpro.com/software'
                    bg='purple.500'
                    color='gray.100'
                    fontSize='sm'
                    fontWeight='700'
                    rounded='md'
                    _hover={{
                      bg: 'purple.400',
                    }}>
                    Explore Solutions
                  </Link>
                </Flex>

                <Box mt={6}>
                  <chakra.p mt={2} color='gray.600'>
                    {category.overview}
                  </chakra.p>
                </Box>

                <Flex justifyContent='space-between' alignItems='center' mt={4}>
                  <Link
                    href={category.url}
                    color='blue.600'
                    _hover={{
                      textDecor: 'underline',
                    }}>
                    Read more
                  </Link>
                </Flex>
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CategoryMaster;

//Alternate UI map
/*
{categories.map((category) => (
          <Flex
            _dark={{
              bg: '#3e3e3e',
            }}
            p={50}
            w='full'
            alignItems='center'
            justifyContent='center'>
            <Box
              bg='white'
              _dark={{
                bg: 'gray.800',
              }}
              mx={{
                lg: 8,
              }}
              display={{
                lg: 'flex',
              }}
              maxW={{
                lg: '5xl',
              }}
              shadow={{
                lg: 'lg',
              }}
              rounded={{
                lg: 'lg',
              }}>
              <Box
                w={{
                  lg: '50%',
                }}>
                <Box
                  h={{
                    base: 64,
                    lg: 'full',
                  }}
                  rounded={{
                    lg: 'lg',
                  }}
                  bgSize='cover'
                  style={{
                    backgroundImage:
                      "url('https://images.unsplash.com/photo-1593642532400-2682810df593?ixlib=rb-1.2.1&ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80')",
                  }}></Box>
              </Box>

              <Box
                py={12}
                px={6}
                maxW={{
                  base: 'xl',
                  lg: '5xl',
                }}
                w={{
                  lg: '50%',
                }}>
                <chakra.h2
                  fontSize={{
                    base: '2xl',
                    md: '3xl',
                  }}
                  color='gray.800'
                  fontWeight='bold'>
                  {category.title}
                </chakra.h2>
                <chakra.p
                  mt={4}
                  color='gray.600'
                  _dark={{
                    color: 'gray.400',
                  }}>
                  {category.overview}{' '}
                  <Link
                    href={category.url}
                    color='blue.600'
                    _hover={{
                      textDecor: 'underline',
                    }}>
                    {' '}
                    Read more...
                  </Link>
                </chakra.p>

                <Box mt={8}>
                  <Link
                    bg='purple.600'
                    color='gray.100'
                    px={5}
                    py={3}
                    fontWeight='semibold'
                    rounded='lg'
                    _hover={{
                      bg: 'gray.800',
                    }}>
                    Explore Solutions
                  </Link>
                </Box>
              </Box>
            </Box>
          </Flex>
        ))}

*/
