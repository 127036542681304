import './WorkHours.css';
import {useEffect, useState, useSyncExternalStore} from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { getUserWorkHours, saveWorkHour, pauseCompany, getUserCompany, refreshToken } from '../../utils/api';
import { refresh } from '../../services/auth.service';

const WorkHours = () => {
  const [workHours, setWorkHours] = useState([]);
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [hours, setHours] = useState([]);
  const [userWorkHours, setUserWorkHours] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  async function loadWorkHours(){
    let res2 = await getUserWorkHours();
    let tmp_data = [];

    if (res2.success){
      setUserWorkHours(res2.data);
      tmp_data = res2.data;
      setLoading(false);
    }

    else if (res.status === 403){
      await refresh();

      let res2 = await getUserWorkHours();
      let tmp_data = [];

      if (res2.success){
        setUserWorkHours(res2.data);
        tmp_data = res2.data;
      }

      setLoading(false);
    }

    let res = days.map((item, index) => {
      let start_time = '';
      let end_time = '';

      if (tmp_data.filter(x => x.day_of_week === index).length > 0){
        start_time = tmp_data.filter(y => y.day_of_week === index)[0].start_time;
        end_time = tmp_data.filter(y => y.day_of_week === index)[0].end_time;
      }

      return {
        day: index,
        name: item,
        start_time: start_time,
        end_time: end_time,
        is_active: tmp_data.filter(item2 => item2.day_of_week===index).length > 0
      }
    });

    setWorkHours(res);
    //loadCompany();
  }

  useEffect(() => {
    async function loadWorkHours(){
      let res2 = await getUserWorkHours();
      let tmp_data = [];

      if (res2.success){
        setUserWorkHours(res2.data);
        tmp_data = res2.data;
        setLoading(false);
      }

      else if (res.status === 403){
        await refresh();

        let res2 = await getUserWorkHours();
        let tmp_data = [];

        if (res2.success){
          setUserWorkHours(res2.data);
          tmp_data = res2.data;
        }

        setLoading(false);
      }

      let res = days.map((item, index) => {
        let start_time = '';
        let end_time = '';

        if (tmp_data.filter(x => x.day_of_week === index).length > 0){
          start_time = tmp_data.filter(y => y.day_of_week === index)[0].start_time;
          end_time = tmp_data.filter(y => y.day_of_week === index)[0].end_time;
        }

        return {
          day: index,
          name: item,
          start_time: start_time,
          end_time: end_time,
          is_active: tmp_data.filter(item2 => item2.day_of_week===index).length > 0
        }
      });

      setWorkHours(res);
      //loadCompany();
    }

    loadWorkHours();
  }, []);

  useEffect(() => {
    async function loadHours(){
      // load hours from 12:00am - 11:30pm
      // for every hour, load the hour at 0 and the 30 minute interval
      let tmp = [];
      for (let i = 0; i < 24; i++){
        let h = 0;
        let isAm = true;

        if (i > 12)
        {
          h = i - 12;
          isAm = false;
        }
        else if (i === 0)
          h = 12;
        else
          h = i;

        let obj = {text: `${h}:00${isAm ? " am": " pm"}`, value: (i * 100)};
        let obj2 = {text: `${h}:30${isAm ? " am" : "pm"}`, value: (i * 100) + 30};

        tmp.push(obj);
        tmp.push(obj2);
      }

      setHours(tmp);
    }

    loadHours();
  }, []);

  async function handleDaySelect(index){
    if (userWorkHours.filter(item => item.day_of_week===index).length === 0){
      // select workhour
      let data = {day_of_week: index, is_active: true, start_time: 900, end_time: 1700};
      let res = await saveWorkHour(data);

      if (res.success){
        let tmp = {
          day_of_week: index,
          is_active: true,
          start_time: 900,
          end_time: 1700
        };

        setUserWorkHours([...userWorkHours, tmp]);
        loadWorkHours();
      }

      else if (res.status === 403){
        await refresh();

        let res = await saveWorkHour(data);

        if (res.success){
          let tmp = {
            day_of_week: index,
            is_active: true,
            start_time: 900,
            end_time: 1700
          };

          setUserWorkHours([...userWorkHours, tmp]);
          loadWorkHours();
        }
      }
    }

    else{
      // already selected
      // deselect
      let data = {day_of_week: index, is_active: false};
      let res = await saveWorkHour(data);

      let tmp = userWorkHours.filter(item => item.day_of_week !== index);
      setUserWorkHours(tmp);
    }

    loadSaveBanner();
  }

  async function handleSaveHourStart(e, day_of_week){
    let data = {day_of_week, start_time: e.target.value};
    let res = await saveWorkHour(data);

    // save workhours starttime for day
    let tmp = workHours.map(x => {
      if (parseInt(x.day) === parseInt(day_of_week)){
        x.start_time = parseInt(e.target.value);
      }

      return x;
    });

    setWorkHours(tmp);
    loadSaveBanner();
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleSaveHourEnd(e, day_of_week){
    let data = {day_of_week, end_time: e.target.value};
    let res = await saveWorkHour(data);

    let tmp = workHours.map(x => {
      if (parseInt(x.day) === parseInt(day_of_week)){
        x.end_time = parseInt(e.target.value);
      }

      return x;
    });

    setWorkHours(tmp);
    loadSaveBanner();
  }

  return (
    <div className="workhours">
      <div className=""><button className='btn' onClick={() => navigate(-1)}>Back</button></div>
      <div className="account__header">Weekly Work Hours</div>

      <p className="form-group">
        Set your weekly work hours. Demo's will only be booked within these times.
      </p>

      {loading && (
        <Spinner />
      )}

      <div className="workhours__days">
        {workHours.map((item, index) => (
          <div className="workhours__day">
             <div className="checkbox-wrapper" style={{marginBottom: "0px"}} key={item}>
             <label className="searchfilter__container">
                <input type="checkbox" className="searchfilter__checkbox" checked={userWorkHours.filter(x => x.day_of_week === index).length > 0} onChange={() => handleDaySelect(index)} />
                <span className="searchfilter__checkbox-label">{item.name}</span>
                <span className="searchfilter__checkmark"></span>
                </label>
              </div>

              {(userWorkHours.filter(item => item.day_of_week===index).length > 0) && (
                <>
              <div className="workhours__form-group">
                <select className="select" onChange={(e) => handleSaveHourStart(e, index)}>
                  {hours.map((item2 => (
                    <option value={item2.value} selected={item2.value === item.start_time}>
                      {item2.text}
                    </option>
                  )))}
                </select>
              </div>

              <div className="workhours__form-group">
                <select className="select" onChange={(e) => handleSaveHourEnd(e, index)}>
                {hours.map((item2 => (
                    <option value={item2.value} selected={item2.value === item.end_time}>
                      {item2.text}
                    </option>
                  )))}
                </select>
              </div>
              </>
              )}
          </div>
        ))}
      </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
        Profile updated successfully!
        </div>
      </FadeIn>
      )}
    </div>
  );
}

export default WorkHours;