import { useState, useEffect, useRef } from "react";
import './EventHome.css';
import { addUserEventTracking } from '../../utils/api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import "react-datepicker/dist/react-datepicker.css";
import { getSeoName } from "../../utils/utils";
import roundtable_guests from '../../assets/events/guests.png';
import chase from '../../assets/design/logos/jpm.png';
import mucker from '../../assets/design/logos/mucker.png';
import kl from '../../assets/design/logos/kl.png';
import medialogos from '../../assets/design/logos/medialogos.png';
import img_austin from '../../assets/design/events/austin.png';
import img_boston from '../../assets/design/events/boston.png';
import img_chicago from '../../assets/design/events/chicago.png';
import img_denver from '../../assets/design/events/denver.png';
import img_irvine from '../../assets/design/events/irvine.png';
import img_losangeles from '../../assets/design/events/losangeles.png';
import img_nashville from '../../assets/design/events/nashville.png';
import img_newyork from '../../assets/design/events/newyork.png';
import img_portland from '../../assets/design/events/portland.png';
import img_saltlakecity from '../../assets/design/events/saltlakecity.png';
import img_sandiego from '../../assets/design/events/sandiego.png';
import img_sanfrancisco from '../../assets/design/events/sanfrancisco.png';
import img_seattle from '../../assets/design/events/seattle.png';

function EventHome() {
    const [event, setEvent] = useState([]);
    let {slug} = useParams();
    const navigate = useNavigate();
    const parentRef = useRef(null);
    const absoluteChildRef = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
      console.log('image loaded');

      const updateParentHeight = () => {
          if (parentRef.current && absoluteChildRef.current) {
            //  const childHeight = absoluteChildRef.current.offsetHeight - 15;
              const childHeight = absoluteChildRef.current.getBoundingClientRect().height;

              if (childHeight > 0)
                parentRef.current.style.height = `${childHeight}px`;

              console.log('child height', absoluteChildRef.current, childHeight);
          }
      };

      updateParentHeight();
      window.addEventListener('resize', updateParentHeight);

      return () => {
          window.removeEventListener('resize', updateParentHeight);
      };
  }, [imageLoaded]);

    return (
      <div className="eventhome">
        <ScrollToTop />
        
        <Helmet>
          <meta name="description" content="" />

          {event && (
            <title>{`The Founders Roundtable`}</title>
          )}
        </Helmet>

        <div ref={parentRef} className="eventhome__hero">
          <div className="eventhome__hero-left">
            <h1>The Founders Roundtable</h1>

            <div className="eventhome__hero-left-subheader">
              18 cities, 75 Founders, <span><i><strong>100% unfiltered</strong></i></span>
            </div>

            <div className="eventhome__hero-left-buttons">
              <div>
                <button onClick={() => {
                  addUserEventTracking('Watch now', 'Founders roundtable');
                  navigate('/events');
                }
                } className="btn eventhome__hero-left-button--watch">Watch Now</button>
              </div>

              <div>
                <button onClick={() => navigate('/events')} className="btn eventhome__hero-left-button--details">More Details</button>
              </div>
            </div>

            <div className="eventhome__sponsors">
              <div className="center eventhome__sponsors-label">Brought to you by</div>

              <div className='eventhome__sponsors-list'>
                <div><img src={chase} /></div>
                <div><img src={kl} /></div>
                <div><img src={mucker} /></div>
              </div>
            </div>
          </div>

          <div className="eventhome__hero-right">
            <div className="eventhome__hero-right-image">
              <img ref={absoluteChildRef} src={roundtable_guests} alt='' onLoad={() => setImageLoaded(true)} />
            </div>
          </div>
        </div>

        <div className="eventhome__timeline">
          <div className="eventhome__timeline-year">
            <h2>2024</h2>

            <div className="eventhome__timeline-items">
              <div>
                <a href='https://www.leadrpro.com/event/1200/founders-roundtable-x-salt-lake-city-edition-from-seed-to-series-c-whats-in-store-for-2024'>
                  <img src={img_saltlakecity} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/1198/founders-roundtable-x-nasvhille-edition-from-seed-to-series-c-whats-in-store-for-2024'>
                  <img src={img_nashville} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/1197/founders-roundtable-x-san-francisco-edition-from-seed-to-series-c-whats-in-store-for-2024'>
                  <img src={img_sanfrancisco} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/1196/founders-roundtable-x-portland-edition-from-seed-to-series-c-whats-in-store-for-2024'>
                  <img src={img_portland} alt='' />
                </a>
              </div>
            </div>
          </div>

          <div className="eventhome__timeline-year">
            <h2>2023</h2>

            <div className="eventhome__timeline-items">
              <div>
                <a href='https://www.leadrpro.com/event/1196/founders-roundtable-x-portland-edition-from-seed-to-series-c-whats-in-store-for-2024'>
                  <img src={img_denver} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/6/founders-roundtable-x-nytechweek-from-seed-to-series-c-what-are-founders-facing-in-2023'>
                  <img src={img_newyork} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/5/founders-roundtable-x-chicago-edition-from-seed-to-series-c-what-are-founders-facing-in-2023'>
                  <img src={img_chicago} alt='' />
                </a>
              </div>

              <div>
                <a href='https://www.leadrpro.com/event/2/founders-roundtable-x-boston-edition-from-seed-to-series-c-what-are-founders-facing-in-2023'>
                  <img src={img_boston} alt='' />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="eventhome__testimonial">
          <div className="eventhome__testimonial-left">
            <div className="eventhome__testimonial-left-quote">
              “Such an awesome platform to help stay on top of the latest and greatest. I've been a member for over 2 years.”
            </div>

            <div className="eventhome__testimonial-left-quote-name">
              Bradley Schaufenbuel, VP and Chief Information Security Officer at Paychex
            </div>
          </div>

          <div className="eventhome__testimonial-right">
            <div className="eventhome__testimonial-image">
              <img src='https://ik.imagekit.io/ivgrhmd4k/bradley.jpg?updatedAt=1715805813403' />
            </div>
          </div>
        </div>

        <div className="eventhome__media">
          <div>
            <img src={medialogos} alt='' />
          </div>
        </div>
      </div>
    );
}

export default EventHome;