import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getAdminDemosUpcoming } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV } from 'react-icons/fa';

function DemosUpcoming() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [demos, setDemos] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [currentDemo, setCurrentDemo] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadDemosUpcoming(){
      let res = await getAdminDemosUpcoming();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    loadDemosUpcoming();
  }, []);

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      {loaded === false && (
        <Spinner />
      )}

      <div>
        {loaded && (
        <>
          <h1>Demos Upcoming {`(${demos.length})`}</h1>

          <div className='admindashboard__demos'>
            {demos.map(item => (
              <div className='admindashboard__demo-card'>
                <div className='admindashboard__demo-card-top-banner'>
                  <span>#{item.demo_id} - </span>
                  <span>{new Date(item.demo_date).toLocaleDateString()}</span>

                  <div className='admindashboard__demo-card-submenu'>
                  <span className='admindashboard__demo-card-submenu-icon' onClick={() => {
                    if (currentDemo != null && currentDemo.demo_id === item.demo_id){
                      setShowSubMenu(!showSubMenu);
                    }

                    else{
                    setShowSubMenu(true);
                    setCurrentDemo(item);
                    }
                  }}>
                    <FaEllipsisV />
                  </span>

                  {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item'>Buyer Details</div>

                      {item.buyer_feedback_count > 0 && loaded && (
                      <div className='dashboard__submenu-item'>Open Feedback</div>
                      )}
                    </div>
                    </FadeIn>
                    )}
                  </div>
                </div>

                <div style={{display: 'flex'}}>
                  <div className='admindashboard__demo-card-left'>
                    <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        {item.seller_company_name}
                      </div>
                    </div>

                    <div className='admindashboard__demo-card-body'>
                      <div><strong>Pay method: </strong>{item.payment_type}</div>
                      <div><strong>Last active: </strong>
                      {item.last_active && (
                        <>
                          {new Date(item.last_active).toLocaleDateString()}
                        </>
                      )}
                      </div>

                      <div>
                      {item.seller_is_inactive && (
                        <div className='admindashboard__not-active'>Inactive</div>
                      )}
                      </div>
                    </div>
                  </div>

                  <div className='admindashboard__demo-card-right'>
                    <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        {item.buyer_company_name}
                      </div>
                    </div>

                    <div className='admindashboard__demo-card-body'>
                      <div>
                        {item.interest_level == 'Very interested' && (
                          <div className='dashboarddemo__very-interested'>
                            {item.interest_level}
                          </div>
                        )}

                        {item.interest_level === 'Somewhat interested' && (
                          <div className='dashboarddemo__somewhat-interested'>
                            {item.interest_level}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                </div>

                <div>
                    {item.seller_has_viewed === true && (
                      <div className='admindashboard__has-been-reviewed'>Has been reviewed</div>
                    )}
                    
                    {item.seller_has_viewed === false && (
                      <div className='admindashboard__has-not-been-reviewed'>Has not been reviewed</div>
                    )}
                  </div>
              </div>
            ))}
          </div>
        </>
        )}
      </div>
    </>
  );
}

export default DemosUpcoming;
;
