import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getPayNowSellers, createCompanyDemoRequests } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';

function Companies() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [indexable, setIndexable] = useState([]);
  const [sellersLoaded, setSellersLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [companyFilter, setCompanyFilter] = useState('All');
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [sendingRequests, setSendingRequests] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [showNoAvailable, setShowNoAvailable] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  useEffect(() => {
    async function loadSellers(){
      let res = await getPayNowSellers();

      if (res.success){
        setSellers(res.data);
        setSellersLoaded(true);
        setLoaded(true);
      }

      else{
        alert('failed reload and retry');
      }
    }

    loadSellers();
  }, []);

  async function handleSearch(query){
    setSearchQuery(query);
  }

  async function handleSendRequests(company_id){
    setSendingRequests(true);
    setCurrentCompanyId(company_id);

    let res = await createCompanyDemoRequests(company_id);

    if (res.success){
      if (res.has_available === true){
        setSendingRequests(false);
        alert("Requests sent");
      }

      else{
        setSendingRequests(false);
        setShowNoAvailable(true);
      }
    }

    setShowSubMenu(false);
  }

  return (
    <>
      <Helmet>
        <title>Admin - Sellers</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
         <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
           <h1>Sellers {`(${sellers.filter(x => {
                 if (searchQuery == '')
                 {
                   if (companyFilter == 'All')
                     return x;

                   else if (companyFilter == 'Active'){
                     if (x.is_inactive == false)
                       return x;
                   }

                   else if (companyFilter == 'Inactive')
                   {
                     if (x.is_inactive == true)
                       return x;
                   }
                 }

                 else if (searchQuery.length > 0){
                   if (x.name.toLowerCase().indexOf(searchQuery) > -1)
                     return x;
                 }
               }).length})`}</h1>

           <div className='admindashboard__search-filter'>
             <input type='text' placeholder='Search...' className='input' value={searchQuery} onChange={(e) => handleSearch(e.target.value)} />
           </div>
         </div>

         <div>
          <div className='admindashboard__index-filters'>
              <button className='btn' onClick={() => setCompanyFilter('All')}>All</button>
              <button className='btn' onClick={() => setCompanyFilter('Active')}>Only active</button>
              <button className='btn' onClick={() => setCompanyFilter('Inactive')}>Not active</button>
            </div>
          </div>

         <div>
           {sellersLoaded == false && (
             <Spinner/>
           )}

           <select className='input select'>
            <option>Sort by...</option>
            <option>Views - desc</option>
           </select>

           {sellersLoaded && (
             <div className='admindashboard__companies'>
               {sellers.filter(x => {
                 if (searchQuery == '')
                 {
                   if (companyFilter == 'All')
                     return x;

                   else if (companyFilter == 'Active'){
                     if (x.is_inactive == false && x.is_paused == false)
                       return x;
                   }

                   else if (companyFilter == 'Inactive')
                   {
                     if (x.is_inactive == true || x.is_paused == true)
                       return x;
                   }
                 }

                 else if (searchQuery.length > 0){
                   if (x.name.toLowerCase().indexOf(searchQuery) > -1)
                     return x;
                 }
               }).map((item, index) => (
                 <div className='admindashboard__company-card'>
                    {/* <div className='admindashboard__company-thumbnail'>
                      <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt="" />
                    </div> */}
                    <div>
                      <strong>#{index}.</strong>
                    </div>

                     <div className='buyerdashboard__recommendation-logo'>
                       <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                     </div>

                     <div>
                      <div className='admindashboard__table-company-name'>{item.name} {`(${item.company_id})`}</div>

                      <div style={{fontSize: '12px'}}>Date joined: {new Date(item.date_created).toLocaleDateString()}</div>

                      {item.last_active && (
                       <div style={{fontSize: '12px'}}>Last active: {new Date(item.last_active).toLocaleDateString()}</div>
                     )}

                      {item.last_active == null && (
                        <div>
                          <span style={{fontSize: '12px'}}>Last active:</span> <strong style={{color: 'red'}}>Never</strong>
                        </div>
                      )}

                      <div>
                        Indexed: {item.is_indexed == true ? <span className='admindashboard__green-flag'>Yes</span>: <span className='admindashboard__red-flag'>No</span>}
                      </div>

                      <div>
                        Views: {item.views}
                      </div>

                      <div><a href={item.website} target='_blank' className='link'>Company website</a></div>
                      <div>
                        <Link target='_blank' to={`/company/${item.company_id}/${item.url_slug}`} className='link'>Profile</Link>
                      </div>

                      <div>
                        <Link target='_blank' to={`/reviews/${item.company_id}/${item.url_slug}`} className='link'>Reviews</Link>
                      </div>
                     </div>

                       <div>
                         <strong>Pending: </strong>
                        {parseInt(item.pending) > 0 && (
                          <span className='admindashboard__green-label'>{item.pending}</span>
                        )}

                        {parseInt(item.pending) == 0 && (
                          <span className='admindashboard__red-label'>{item.pending}</span>
                        )}
                       </div>

                       <div>
                         <strong>Rescheduling:</strong> {parseInt(item.rescheduling) > 0 && (
                          <span className='admindashboard__green-label'>{item.rescheduling}</span>
                         )}

                         {parseInt(item.rescheduling) === 0 && (
                          <span className='admindashboard__red-label'>{item.rescheduling}</span>
                         )}
                       </div>

                       <div>
                         <strong>Upcoming:</strong> {parseInt(item.approved) > 0 && (
                          <span className='admindashboard__green-label'>{item.approved}</span>
                         )}

                         {parseInt(item.approved) == 0 && (
                          <span className='admindashboard__red-label'>{item.approved}</span>
                         )}
                       </div>

                       <div>
                          <strong>Completed:</strong> {parseInt(item.completed) > 0 ? <span className='admindashboard__green-label'>{item.completed}</span> : <span className='admindashboard__red-label'>{item.completed}</span>}
                      </div>

                     {/* {item.is_inactive == false && (
                       <div className='admindashboard__is-active'><strong>Active</strong></div>
                     )}

                     {item.is_inactive == true && (
                       <div className='admindashboard__not-active'><strong>Not Active</strong></div>
                     )} */}

                     <div>
                      <div className=''>
                        <span className='admindashboard__demo-card-submenu-icon' onClick={() => {
                          if (currentCompany != null && currentCompany.company_id === item.company_id){
                            setShowSubMenu(!showSubMenu);
                          }

                          else{
                          setShowSubMenu(true);
                          setCurrentCompany(item);
                          }
                        }}>
                          <FaEllipsisV />
                        </span>

                        {showSubMenu == true && currentCompany.company_id == item.company_id && (
                            <FadeIn delay={100} duration={100}>
                          <div className='dashboard__submenu'>
                            {sendingRequests === false && (
                              <div className='dashboard__submenu-item' onClick={() => handleSendRequests(item.company_id)}>Send Requests</div>
                            )}

                            {sendingRequests === true && (
                              <Spinner />
                            )}
                          </div>
                          </FadeIn>
                          )}
                        </div>
                     </div>
                 </div>
               ))}
             </div>
           )}
         </div>
       </div>
      )}

      {showNoAvailable && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowNoAvailable(false)}>
              <FaTimes />
            </div>

            <div className='center'>
              <h2 className='modal__h2'>Uh oh...There are no users available for this company</h2>
            </div>

            <p>
              It might be good to modify this companies ICP selections in order to improve outreach.
            </p>

            <div className='center' style={{marginTop: '20px'}}>
              <button className='btn' onClick={() => setShowNoAvailable(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Companies;
;
