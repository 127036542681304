import './SellerFeedback.css';
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import congrats from '../../assets/design/dashboard/congrats.png';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef } from '../../utils/api';
import { refresh } from '../../services/auth.service';

function SellerFeedback() {
  const [referenceId, setReferenceId] = useState('');
  const [demo, setDemo] = useState({});
  const [buyerObjections, setBuyerObjections] = useState([]);
  const [sellerObjections, setSellerObjections] = useState([]);
  const [selectedObjectionReason, setSelectedObjectionReason] = useState(null);
  const [objectionReason, setObjectionReason] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [estimatedValue, setEstimatedValue] = useState(0);
  const [valid, setValid] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    async function loadDemo(){
      const id = new URLSearchParams(search).get('refid');
      setReferenceId(id);

      let response = await getBuyerDemoFromRef(id);

      if (response.success){
        if (response.data.length > 0){
          setDemo(response.data[0]);
          setValid(true);

          if (response.data[0].demo_status_id === 4)
            setCompleted(true);
        }

        else{
          setValid(false);
          setCurrentStep(-1);
        }
      }

      else if (response.status === 403){
        await refresh();

        if (response.data.length > 0){
          setDemo(response.data[0]);
          setValid(true);

          if (response.data[0].demo_status_id === 4)
            setCompleted(true);
        }

        else{
          setValid(false);
          setCurrentStep(-1);
        }
      }

      else{
        setValid(false);
      }

      setLoaded(true);
    }

    loadDemo();
  }, []);

  useEffect(() => {
    async function fetchBuyerObjections() {
      let data = await getBuyerObjections();
      if (data.success) {
        setBuyerObjections(data.data);
      }
    }

    async function fetchSellerObjections() {
      let data = await getSellerObjections();
      if (data.success) {
        setSellerObjections(data.data);
      }
    }

    fetchSellerObjections();
    fetchBuyerObjections();
  }, []);

  async function yesClickHandler(){
    setCurrentStep(1);
   // let res = await addSellerFeedback(demo.demo_id, true);
  }

  function noClickHandler(){
    setCurrentStep(2);
  }

  async function handleAcvClick(){
    // save acv in feedback
    let res = await addSellerFeedback(demo.demo_id, true, estimatedValue);

    if (res.success){
      setCurrentStep(3);
    }

    else{
      console.log('failed');
    }
  }

  async function submitSellerFeedback(e){
    e.preventDefault();

    let objectionReason = e.target.elements.objectionReason.value;
    let objectionDescription = e.target.elements.objectionDescription.value;

    if (objectionDescription.length === 0){
      alert("Please enter a reason");
      return;
    }
    //post request to db
    let res = await addSellerFeedback(
      demo.demo_id,
      false,
      estimatedValue,
      objectionReason,
      objectionDescription
    );

    if (res.success) {
      setCurrentStep(3);
    } else {
      console.log('failed!');
    }
  }

return (
  <div className='feedback'>
     {demo.demo_status_id === 1 && (
      <>
      <p>This meeting has not been completed yet.</p>

      <p>
        It has been scheduled for {new Date(demo.scheduled_date).toLocaleDateString()}
      </p>

      <p>
        <a href={`https://meetings.leadrpro.com/demo?refid=${demo.reference_id}`} target="_blank">Join Meeting</a>
      </p>
      </>
    )}

    {currentStep === -1 && (
      <div>
        <p>Invalid Feedback link.</p>
        <p>Please contact support if you think this is an error.</p>
        <p><a href="mailto:support@leadrpro.com">Contact us</a></p>
      </div>
    )}

    {loaded && currentStep === 0 && (
    <div className="">
      <p className='feedback__text--large'>
        Do you think the prospect will be moving forward in the sales process?
      </p>

    <p className='feedbackpopup__btn-container-parent'>
      <button className='btn btn--primary' onClick={() =>
          yesClickHandler()}>
        Yes
      </button>

      <button className='btn btn--secondary' onClick={noClickHandler}>
        No
      </button>
    </p>
  </div>
    )}

{loaded && currentStep === 1 && (
  <div>
      <p className="center">
        <img src={congrats} alt='' />
      </p>

      <p className='feedbackpopup__text--large'>
        That's great!
      </p>

      <p className='feedbackpopup__text--small'>
        What do you think the estimated ACV would be?
      </p>

      <div className="form-group">
      <input
        type='text'
        autoComplete='off'
        value={estimatedValue}
        onChange={(event) =>
          {
            if (!Number.isInteger(Number(event.target.value))){
              alert("Must be a numeric value");
            }
            else{
              setEstimatedValue(event.target.value);
            }
          }
        }
        className='sellerdashboard__feedback-modal-acv-input'
        name='Estimated Value'
        id='estimatedValue'
        placeholder='Estimated ACV in USD'
      />
      </div>

      <p>
        <button
          className='btn feedbackpopup__submit-btn' onClick={handleAcvClick}>
          Submit
        </button>
      </p>
    </div>
)}

{loaded && currentStep === 2 && (
  <div>

  <form
    onSubmit={(e) =>
      submitSellerFeedback(e)
    }>
    <div className='form-group'>
      <label>
        <div
          className='feedbackpopup__text--small-no'>
          Why do you think the prospect won't move
          forward?
        </div>
      </label>

      <select
        className='input_box dropdown-font'
        type='text'
        id='objectionReason'
        onChange={(e) =>
          setSelectedObjectionReason(
            e.target.value
          )
        }
        name='choose reason'>
        {sellerObjections.map((item) => (
          <option
            value={
              item.seller_objection_reason_id
            }>
            {item.reason}
          </option>
        ))}
      </select>
      </div>

      <div className="form-group">
      <div className='feedbackpopup__text--small'>
        What do you think the estimated ACV would
        have been?
      </div>
      <input
        type='text'
        autoComplete='off'
        value={estimatedValue}
        onChange={(event) =>
          {
            if (!Number.isInteger(Number(event.target.value))){
              alert("Must be a numeric value");
            }
            else{
              setEstimatedValue(event.target.value);
            }
          }
        }
        className='input form-control'
        name='Estimated Value'
        id='estimatedValue'
        placeholder='Estimated ACV in USD'
      />
    </div>

    <div className='form-group'>
      <label className='feedbackpopup__text--small'>
        Please let us know your thoughts on how
        the call went
      </label>
      <textarea
        autoComplete='off'
        value={objectionReason}
        onChange={(event) =>
          setObjectionReason(event.target.value)
        }
        className='input_box feedbackpopup__text-area'
        type='text'
        name='Objection Reason'
        id='objectionDescription'
        placeholder='Maximum 40 characters'
      />
    </div>

    <div className='feedbackpopup__no-btn-parent'>
      <button className='btn feedbackpopup__submit-btn'>
        Submit
      </button>
    </div>
  </form>
  </div>
)}

{loaded && currentStep === 3 && (
  <div>
    <img src={congrats} alt='' />

    <div className='feedback__text--large'>
      Thank you!
    </div>

    <div className='feedback__text--small'>
      Your feedback has been recorded!
    </div>
  </div>
)}
</div>
)
   }

   export default SellerFeedback;