import React from 'react';
import {
  Box,
  Flex,
  chakra,
  Icon,
  VStack,
  Image,
  Center,
  Button,
} from '@chakra-ui/react';

const PricingHowItWorksBuyer = () => {
  const Feature = (props) => {
    return (
      <Flex alignSelf='start' w='full'>
        <Icon
          boxSize={5}
          mt={2}
          mr={2}
          _light={{
            color: 'green.500',
          }}
          viewBox='0 0 20 20'
          fill='currentColor'
          // as={IoCheckmark}
        />
        <chakra.p
          fontSize='lg'
          color='gray.600'
          _dark={{
            color: 'gray.400',
          }}
          {...props}
        />
      </Flex>
    );
  };

  return (
    <Flex
      bg='black'
      p={10}
      pb={{ base: 0, md: 20 }}
      w='full'
      justifyContent='center'
      alignItems='center'>
      <Box w='full' pt={8}>
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}
          justifyContent='center'
          mb={{
            base: 6,
            sm: 0,
          }}>
          <Flex
            flex={{
              sm: 1,
              lg: 'initial',
            }}
            w={{
              lg: 2.3 / 7,
            }}
            borderTopLeftRadius='lg'
            borderBottomLeftRadius='xl'
            borderTopRightRadius={{ base: 'lg', md: 'none' }}
            borderBottomRightRadius={{ base: 'lg', md: 'none' }}
            bg='white'
            my={6}
            direction='column'>
            <VStack
              spacing={1}
              justifyContent='center'
              p={8}
              textAlign='center'
              w='full'
              shadow='xl'>
              <chakra.span fontSize='3xl' fontWeight='bold'>
                APPLY
              </chakra.span>
              <chakra.p mb={6} fontSize='md' color='gray.500'>
                Browse our community of software solutions <br />
                and easily apply to paid demos
              </chakra.p>
            </VStack>

            <Box
              bg='gray.300'
              h='full'
              borderBottomLeftRadius='lg'
              borderBottomRightRadius={{ base: 'lg', md: 'none' }}>
              <Image
                p={4}
                pt='50px'
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/Scheduling.png?updatedAt=1683232061415'
              />
            </Box>
          </Flex>

          <Flex
            flex={{
              base: 1,
              lg: 'initial',
            }}
            w={{
              lg: 2.4 / 7,
            }}
            rounded='lg'
            bg='white'
            mt={{
              base: 4,
              sm: -4,
            }}
            mb={{ base: 4, md: 0 }}
            shadow='xl'
            zIndex={30}
            direction='column'>
            <VStack
              spacing={1}
              justifyContent='center'
              p={8}
              textAlign='center'
              w='full'
              shadow='xl'>
              <chakra.span fontSize='3xl' fontWeight='bold'>
                DISCOVER
              </chakra.span>
              <chakra.p mb={6} fontSize='md' color='gray.500'>
                Discover the most innovative software <br />
                solutions for your business
              </chakra.p>
            </VStack>
            <VStack
              fontSize='sm'
              h='full'
              roundedBottom='lg'
              bg='gray.300'
              shadow={'xl'}>
              <Center
                mr={{ base: 0, md: 6 }}
                px={{ base: 4, md: 0 }}
                mt={12}
                mb={{ base: 6, md: 0 }}>
                <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Meeting.png?updatedAt=1683232061421' />
              </Center>

              <Box
                w='full'
                ml={3}
                display={{ base: 'none', md: 'inline-flex' }}
                rounded='md'>
                <chakra.a
                  href='https://www.leadrpro.com/signup'
                  w='full'
                  display='inline-flex'
                  alignItems='center'
                  justifyContent='center'
                  px={5}
                  py={3}
                  mx={14}
                  mt={4}
                  border='solid transparent'
                  fontSize='md'
                  fontWeight='bold'
                  rounded='md'
                  bgColor='#49b792'
                  color='white'
                  _hover={{
                    bg: 'purple.600',
                    color: 'white',
                    transform: 'scale(1.02)',
                    fontSize: 'md',
                    fontWeigh: 'bold',
                  }}
                  cursor='pointer'>
                  Create Account{' '}
                  <Icon
                    boxSize={4}
                    ml={2}
                    mt={1}
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                      clipRule='evenodd'
                    />
                  </Icon>
                </chakra.a>
              </Box>

              <Box>
              <div style={{padding: '20px', fontSize: '12px'}}>
                Enter Promo Code When Prompted, To Redeem Your $10 Gift Card
                </div>
              </Box>
            </VStack>
          </Flex>

          <Flex
            flex={{
              sm: 1,
              lg: 'initial',
            }}
            w={{
              lg: 2.3 / 7,
            }}
            borderBottomRightRadius='lg'
            borderTopRightRadius='lg'
            borderTopLeftRadius={{ base: 'lg', md: 'none' }}
            borderBottomLeftRadius={{ base: 'lg', md: 'none' }}
            bg='white'
            my={6}
            direction='column'>
            <VStack
              spacing={1}
              justifyContent='center'
              p={8}
              textAlign='center'
              w='full'
              shadow='xl'>
              <chakra.span fontSize='3xl' fontWeight='bold'>
                GET PAID
              </chakra.span>
              <chakra.p mb={6} fontSize='md' color='gray.500'>
                Withdraw funds instantly via Venmo, PayPal, Zelle
                <a href='/blog'>,</a> CashApp or Gift Card
              </chakra.p>
            </VStack>
            <Box
              borderBottomRightRadius='lg'
              borderBottomLeftRadius={{ base: 'lg', md: 'none' }}
              bg='gray.300'
              h='full'>
              <Image
                p={4}
                pt='50px'
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/GettingPaid.png?updatedAt=1683232061454'
              />
            </Box>
          </Flex>
          <Box mt={10} display={{ base: 'inline-block', md: 'none' }}>
            <div style={{flexDirection: 'column'}}>
              <Button px={{ base: 8, md: 10 }} bg='#49b792' color='white'>
                <a href='https://www.leadrpro.com/signup'>
                  Create Account
                  <Icon
                    boxSize={4}
                    ml={2}
                    mt={1}
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                      clipRule='evenodd'
                    />
                  </Icon>
                </a>
              </Button>

          <div style={{padding: '20px', fontSize: '12px', color: 'white'}}>
                Enter Promo Code When Prompted, To Redeem Your $10 Gift Card
                </div>
            </div>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default PricingHowItWorksBuyer;
