import './SellerRecommend.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getSellerRecommendations, addSellerRecommendationStatus, createBuyerDemo, getSellerRecommendationsBookmarked } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaTimes, FaCheck, FaBookmark, FaSpinner } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import banner from '../../assets/design/app/header.png';
const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companylogos/';

function SellerRecommend() {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmBookmark, setShowConfirmBookmark] = useState(false);
  const [showRequestConfirm, setShowRequestConfirm] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [doNotShow, setDoNotShow] = useState(false);
  const [doNotShowBookmark, setDoNotShowBookmark] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [bookmarked, setBookmarked] = useState([]);
  const [bookmarkedLoaded, setBookmarkedLoaded] = useState(false);

  async function loadData(){
    let res = await getSellerRecommendations();

    if (res.success){
      setData(res.data);

      if (res.data.length === 0)
        setNoResults(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getSellerRecommendations();

      if (res.success){
        setData(res.data);

        if (res.data.length === 0)
          setNoResults(true);
      }
    }

    setLoaded(true);
    setShowLoading(false);
  }

  async function loadBookmarked(){
    let res = await getSellerRecommendationsBookmarked();

    if (res.success){
      console.log(res.data);
      setBookmarked(res.data);
      setBookmarkedLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getSellerRecommendationsBookmarked();

      if (res.success){
        setBookmarked(res.data);
        setBookmarkedLoaded(true);
      }
    }
  }

  useEffect(() => {
    loadData();

    setTimeout(() => {
      setShowResults(true);
    }, 1000);
  }, []);

  async function hide(id){
    setSelectedId(id);

    if (localStorage.getItem('sr_donotshow') == 'true'){
      setIsFading(true);

      // Optionally, you can set a delay before removing the element from the DOM
      setTimeout(async () => {
        setIsFading(false);

        let _tmp = data.filter(x => x.registered_user_id !== id);
        setData(_tmp);

        let res = await addSellerRecommendationStatus(id, 'Hide', 'Seller recommendations page');
        setSelectedId(null);

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 300);
    }

    else
      setShowConfirm(true);
  }

  async function hideConfirm(){
    if (selectedId){
      setIsFading(true);

      // Optionally, you can set a delay before removing the element from the DOM
      setTimeout(async () => {
        setIsFading(false);

        let _tmp = data.filter(x => x.registered_user_id !== selectedId);
        setData(_tmp);

        let res = await addSellerRecommendationStatus(selectedId, 'Hide', 'Seller recommendations page');
        setSelectedId(null);

        if (showBookmarks === true){
          await loadBookmarked();
        }

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 300);

      setShowConfirm(false);

      if (doNotShow){
        localStorage.setItem('sr_donotshow', 'true');
      }
    }
  }

  async function handleDetailsClick(id, item){
    if (localStorage.getItem('sr_donotshowconfirmation') == 'true'){
      // do not show modal
      setSelectedItem(item);
      setSubmitting(true);
      await sendRequestInstant(item);
    }

    else{
      setShowDetails(true);
      setSelectedItem(item);
    }
  }

  async function handleBookmarkClick(id, item){
    setSelectedId(id);
    if (localStorage.getItem('sr_donotshowbookmark') == 'true'){
      let res = await addSellerRecommendationStatus(id, 'Bookmark', 'Seller recommendations page');

      setIsFading(true);

      setTimeout(async () => {
        setIsFading(false);
        setShowConfirmBookmark(false);
        let _tmp = data.filter(x => x.registered_user_id !== id);
        setData(_tmp);

        setSelectedId(null);

        if (doNotShowBookmark){
          localStorage.setItem('sr_donotshowbookmark', 'true');
        }

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }

      }, 300);
    }

    else{
      setShowConfirmBookmark(true);
      setSelectedId(id);
      setSelectedItem(item);
    }
  }

  async function handleBookmarkConfirm(){
    let res = await addSellerRecommendationStatus(selectedItem.registered_user_id, 'Bookmark', 'Seller recommendations page');

    if (res.success){
      setIsFading(true);

      setTimeout(async () => {
        setIsFading(false);
        setShowConfirmBookmark(false);
        let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
        setData(_tmp);
        setSelectedId(null);

        if (doNotShowBookmark){
          localStorage.setItem('sr_donotshowbookmark', 'true');
        }

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }

      }, 300);
    }

    else if (res.status === 403){
      await refresh();

      let res = await addSellerRecommendationStatus(selectedItem.registered_user_id, 'Bookmark', 'Seller recommendations page');

      if (res.success){
        setIsFading(true);

        setTimeout(async () => {
          setIsFading(false);
          setShowConfirmBookmark(false);
          let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
          setData(_tmp);

          setSelectedId(null);
        }, 300);
      }
    }
  }

  async function sendRequest(){
    setSendingRequest(true);
    let res = await addSellerRecommendationStatus(selectedItem.registered_user_id, 'Approved', 'Seller recommendations page');

    if (res.success){
      let data2 = {buyer_id: selectedItem.registered_user_id, credit_cost: 5, message: 'Booked through seller recommendations'}
      await createBuyerDemo(data2);

      if (skipConfirmation === true){
        localStorage.setItem('sr_donotshowconfirmation', 'true');
      }

      if (showBookmarks === true){
        await loadBookmarked();
      }

      setTimeout(() => {
        let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
        setData(_tmp);
        setShowDetails(false);
        setShowRequestConfirm(true);
        setSendingRequest(false);
        setSubmitting(false);

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 2000);
    }

    else if (res.status === 403){
      await refresh();

      let data2 = {buyer_id: selectedItem.registered_user_id, credit_cost: 5, message: 'Booked through seller recommendations'}
      await createBuyerDemo(data2);


      if (skipConfirmation === true){
        localStorage.setItem('sr_donotshowconfirmation', 'true');
      }

      if (showBookmarks === true){
        await loadBookmarked();
      }

      setTimeout(() => {
        let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
        setData(_tmp);
        setShowDetails(false);
        setShowRequestConfirm(true);
        setSendingRequest(false);
        setSubmitting(false);

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 2000);
    }
  }

  async function sendRequestInstant(selectedItem){
    setSendingRequest(true);
    let res = await addSellerRecommendationStatus(selectedItem.registered_user_id, 'Approved', 'Seller recommendations page');

    if (res.success){
      let data2 = {buyer_id: selectedItem.registered_user_id, credit_cost: 5}
      await createBuyerDemo(data2);

      if (skipConfirmation === true){
        localStorage.setItem('sr_donotshowconfirmation', 'true');
      }

      setTimeout(async () => {
        let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
        setData(_tmp);
        setShowDetails(false);
        setShowRequestConfirm(true);
        setSendingRequest(false);
        setSubmitting(false);

        if (showBookmarks === true){
          await loadBookmarked();
        }

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 2000);
    }

    else if (res.status === 403){
      await refresh();

      let data2 = {buyer_id: selectedItem.registered_user_id, credit_cost: 5}
      await createBuyerDemo(data2);

      if (skipConfirmation === true){
        localStorage.setItem('sr_donotshowconfirmation', 'true');
      }

      setTimeout(async () => {
        let _tmp = data.filter(x => x.registered_user_id !== selectedItem.registered_user_id);
        setData(_tmp);
        setShowDetails(false);
        setShowRequestConfirm(true);
        setSendingRequest(false);
        setSubmitting(false);

        if (showBookmarks === true){
          await loadBookmarked();
        }

        if (_tmp.length === 0){
          setShowLoading(true);
          setTimeout(() => {
            loadData();
          }, 2000);
        }
      }, 2000);
    }
  }

  async function handleSkipConfirmation(e){
    setSkipConfirmation(e.target.checked)
  }

  async function handleShowBookmarked(){
    setShowBookmarks(true);
    loadBookmarked();
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Your ideal customer profile recommendations</title>
      </Helmet>

      <ScrollToTop />
      <div className='buyerrecommends sellerrecommends'>
        {showResults === false && (
          <div className='buyerrecommends__splash'>
            Building your recommendations...
            <div className='buyerrecommends__subtext'>Finding you the most relevant candidates</div>
            <Spinner /> 
          </div>
        )}

        <div className='sellerrecommends__results'> 
          {loaded === true && showResults == true && noResults === false && (
            <div className='sellerrecommends__no-results'>
              <h2 className='sellerrecommends__h2'>Based on your Ideal Customer Profile, these people are a great fit</h2>

              <div className='sellerrecommends__btns mar20--top'>
                <Link to='/account/icp' className='btn'>Configure your ideal customer profile</Link>

                <span style={{marginLeft: '10px'}} className='btn' onClick={() => handleShowBookmarked()}><FaBookmark /> Bookmarks</span>
               </div>
            </div>
          )}

          {showLoading === true && (
            <div className='center'>
              <Spinner />
            </div>
          )}

          {loaded === true && showResults == true && data.length > 0 && (
            <FadeIn delay={300} duration={500}>

            <div className='sellerrecommends__items'>
              {data.map((item, index) => (
                <div key={index} className={`sellerrecommends__item fading-element ${isFading && item.registered_user_id === selectedId ? 'fade-out' : ''}`} id={`item_${item.registered_user_id}`}>

                  {item.interested == true && (
                    <div className='sellerrecommends__interested'>
                      This person has expressed interest
                    </div>
                  )}
                  <div className='sellerrecommends__logo-wrapper'>
                  <div className='sellerrecommends__logo'>
                    {item.logo && (
                      <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/' + item.logo} alt="" />
                    )}

                    {!item.logo && (
                      <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/default-buyer.png'} alt="" />
                    )}
                  </div>
                  </div>

                  <div className='sellerrecommends__name-wrapper'>
                    <div className='sellerrecommends__item-name'>
                      <div className='sellerrecommends__job-title'>{item.job_title}</div>
                      <div className='sellerrecommends__company-name'>{item.company_name}</div>
                    </div>

                      {item.description && item.description.length > 0 && (
                      <div className='sellerrecommends__item-description'>
                        {item.description}
                      </div>
                    )}
                  </div>

                  <div className='sellerrecommends__item-specs' onClick={() => handleDetailsClick(item.registered_user_id, item)}>
                    <div>
                      <strong>Industry: </strong>{item.industry}
                    </div>

                    <div><strong>Company size: </strong>{item.company_size}</div>

                    {item.department && item.department.length > 0 && (
                      <div><strong>Department: </strong>{item.department}</div>
                    )}

                    <div><strong>Seniority: </strong>{item.seniority}</div>

                    {item.country && item.country.length > 0 && (
                      <div><strong>Location: </strong>{item.country}</div>
                    )}

                    {item.website && item.website.length > 0 && (
                      <div><strong>Website: </strong><a className='link' target='_blank' href={item.website}>Company Website</a></div>
                    )}
                  </div>

                  <div className='sellerrecommends__options'>
                    {submitting === false && (
                    <div className='sellerrecommends__option sellerrecommends__item-check' onClick={() => handleDetailsClick(item.registered_user_id, item)}>
                      <FaCheck />
                    </div>
                    )}

                    {submitting === true && selectedItem.registered_user_id === item.registered_user_id && (
                      <div className='sellerrecommends__option'>
                        <Spinner />
                      </div>
                    )}

                    <div className='sellerrecommends__option sellerrecommends__item-bookmark' onClick={() => handleBookmarkClick(item.registered_user_id, item)}>
                      <FaBookmark />
                    </div>

                    <div className='sellerrecommends__option sellerrecommends__item-hide' title='Not interested' onClick={() => hide(item.registered_user_id)}>
                      <FaTimes />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </FadeIn>
          )}

          {loaded === true && showResults == true && noResults === true && (
            <div className='sellerrecommends__no-results'>
              <h2 className='buyerrecommends__h2 center'>Based on your Ideal Customer Profile, these people are a great fit</h2>

              <div className=''>
                No more results found...

                <div className='sellerrecommends__subtext'>To increase the talent pool, make sure you have your proper ICP's set. <Link className='link' to='/account/icp'>Configure ICP's</Link></div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showDetails && (
        <FadeIn delay={100} duration={300}>
          <div className='overlay'></div>

          <div className='modal sellerrecommends__modal'>
            <div className='modal__close' onClick={() => setShowDetails(false)}>
              <FaTimes />
            </div>
            <div className='sellerrecommends__modal-logo'>
              {selectedItem.logo && (
                <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/' + selectedItem.logo} alt="" />
              )}

              {!selectedItem.logo && (
                <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/default-buyer.png'} alt="" />
              )}
            </div>

            <div className='buyerrecommends__modal-body sellerrecommends__modal-body'>
              <div className='sellerrecommends__modal-name' onClick={() => setShowDetails(false)}>
                {selectedItem.job_title} working at {selectedItem.company_name}
              </div>

              <p style={{marginTop: '30px'}}>
                Send a request to apply for a demo?
              </p>

              {/* <div className='sellerrecommends__modal-specs'>
                <div><strong>Company size: </strong>{selectedItem.company_size}</div>

                {selectedItem.department && selectedItem.department.length > 0 && (
                  <div><strong>Department: </strong>{selectedItem.department}</div>
                )}

                <div><strong>Seniority: </strong>{selectedItem.seniority}</div>

                {selectedItem.country && selectedItem.country.length > 0 && (
                  <div><strong>Location: </strong>{selectedItem.country}</div>
                )}

                {selectedItem.website && selectedItem.website.length > 0 && (
                  <div><strong>Website: </strong><a className='link' href={selectedItem.website}>Company Website</a></div>
                )}
              </div> */}

              {/* {selectedItem.description && selectedItem.description.length > 0 && (
                <div className='buyerrecommends__modal-description'>
                  <strong>Company Info:</strong>
                  {selectedItem.description}
                </div>
              )} */}

              <div className='buyerrecommends__modal-cta'>
                <>
                {sendingRequest && (
                    <Spinner />
                  )}

              {sendingRequest === false && (
                <button onClick={() => sendRequest()} className='btn sellerrecommends__btn-book'>
                    <>
                      {'Send a request'}
                    </>
                </button>
              )}
                </>
              </div>

              <div style={{marginTop: '20px'}}>
                  <input type='checkbox' value={skipConfirmation} id="cbSkip" onChange={(e) => handleSkipConfirmation(e)} /> <label htmlFor='cbSkip'>Skip confirmation next time</label>
              </div>
            </div>
          </div>
        </FadeIn>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal sellerrecommends__modal-decline'>
            <div className='modal__close' onClick={() => setShowConfirm(false)}>
              <FaTimes />
            </div>

            <div className='modal__h2'>
              Not a good fit?
            </div>

            <div>
              Not a problem. We will use this information to help build your ideal customer profile and suggest better matches in the future.
            </div>

            <div className='center' style={{marginTop: '40px'}}>
              <div><button className='btn btn--primary' onClick={() => hideConfirm()}>Yes, not a good fit</button></div>

              <div className='sellerrecommends__disclaimer'>
                <input type='checkbox' value={doNotShow} onChange={(e) => setDoNotShow(e.target.checked)} /> Don't show this notification again
              </div>
            </div>
          </div>
        </>
      )}

      {showConfirmBookmark && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirmBookmark(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Bookmark for later?
            </h2>

            <div className='mar20--top'>
              Need more time to see if this is a good fit?
            </div>

            <div className='center mar20--top'>
              <button className='btn' onClick={() => handleBookmarkConfirm()}>Bookmark for later</button>

              <div className='sellerrecommends__disclaimer'>
                <input type='checkbox' value={doNotShow} onChange={(e) => setDoNotShowBookmark(e.target.checked)} /> Don't show this notification again
              </div>
            </div>
          </div>
        </>
      )}

      {showRequestConfirm && (
        <FadeIn delay={200} duration={400}>
          <div className='overlay'></div>
          <div className='modal sellerrecommends__modal-confirm'>
            <div className=''>
              <h2 className='modal__h2'>Request Successfully Sent!</h2>

              <p className='mar20--top'>
                There's nothing to do now. We will notify you via email and through your dashboard once the person has received the request and has successfully submitted their application.
              </p>
            </div>

            <div className='mar20--top center'>
              <button className='btn' onClick={() => setShowRequestConfirm(false)}>Close</button>
            </div>
          </div>
        </FadeIn>
      )}

      {showBookmarks && (
        <>
          <div className='overlay'></div>
          <div className='modal sellerrecommends__modal-bookmarks'>
            <div className='modal__close' onClick={() => setShowBookmarks(false)}><FaTimes /></div>

            <h2 className='modal__h2'>My Bookmarks</h2>

            {bookmarkedLoaded === false && (
              <Spinner />
            )}
            <div className='sellerrecommends__bookmarks'>

              {/* ppp */}
              {bookmarkedLoaded === true && bookmarked.length === 0 && (
                <p>
                  No bookmarks found...
                </p>
              )}
              <table className='table'>
                {bookmarked.map(item => (
                  <tr>
                    <td>
                    <div className='sellerrecommends__bookmark-logo'>
                    {item.logo && (
                      <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/' + item.logo} alt="" />
                    )}

                    {!item.logo && (
                      <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/default-buyer.png'} alt="" />
                    )}
                  </div>
                      <div>{item.job_title}</div>
                      <div>{item.company_name}</div>
                    </td>

                    <td>
                      <div>
                        <div>
                          <strong>Industry:</strong> {item.industry}
                        </div>
                        <div>
                          <strong>Company size:</strong> {item.company_size}
                        </div>
                        <div>
                          <strong>Seniority:</strong> {item.seniority}
                        </div>
                        <div>
                          <strong>Location: </strong> {item.country}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div>
                        {submitting === false && (
                          <div className='sellerrecommends__option sellerrecommends__item-check' onClick={() => handleDetailsClick(item.registered_user_id, item)}>
                          <FaCheck />
                        </div>
                        )}

                        {submitting === true && selectedItem.registered_user_id === item.registered_user_id && (
                          <div className='sellerrecommends__option sellerrecommends__item-check'>
                          <Spinner />
                        </div>
                        )}

                        {submitting === true && selectedItem.registered_user_id !== item.registered_user_id && (
                          <div className='sellerrecommends__option sellerrecommends__item-check'>
                          <FaCheck />
                        </div>
                        )}

                        <div className='sellerrecommends__option sellerrecommends__item-hide' title='Not interested' onClick={() => hide(item.registered_user_id)}>
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SellerRecommend;
