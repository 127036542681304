import './Confirmation.css';
import './CalendarLinks.css';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  Popover,
  PopoverTrigger,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { FaQuestionCircle, FaRegCalendarAlt, FaGoogle, FaApple, FaMicrosoft } from 'react-icons/fa';

function CalendarLinks({
  scheduled_date_time,
  persona_type,
  company_name,
  reference_id,
  meeting_link,
}) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    let _date = new Date(scheduled_date_time);
    let _strDate = _date.toISOString().replace(/-|:|\.\d\d\d/g, '');
    setStartDate(_strDate);

    let _endDate = new Date(scheduled_date_time);
    _endDate.setMinutes(_endDate.getMinutes() + 30);
    let _strDateEnd = _endDate.toISOString().replace(/-|:|\.\d\d\d/g, '');
    setEndDate(_strDateEnd);
  }, [scheduled_date_time]);

  async function handleShowPopUp(){
    setShowPopUp(!showPopUp);
  }

  function generateVTimeZone() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = moment.tz(timezone);
  
    const tzOffsetStandard = tz.format('Z');
    const tzOffsetDaylight = tz.format('Z', moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).isDST());
  
    const tzNameStandard = tz.zoneAbbr();
    const tzNameDaylight = tz.zoneAbbr(true);
  
    const tzStartYear = 2023;
    const tzEndYear = 2024;
  
    let tzRuleStandard = '';
    let tzRuleDaylight = '';
  
    for (let year = tzStartYear; year <= tzEndYear; year++) {
      const startOfYear = moment.tz(`01/01/${year}`, timezone);
      const startOfDaylightSavingTime = startOfYear.clone().month(2).day(8).hour(2).minute(0).second(0);
      const startOfStandardTime = startOfYear.clone().month(10).day(1).hour(2).minute(0).second(0);
  
      if (startOfDaylightSavingTime.year() !== year) {
        startOfDaylightSavingTime.add(1, 'week');
      }
  
      if (startOfStandardTime.year() !== year) {
        startOfStandardTime.add(1, 'week');
      }
  
      tzRuleDaylight += `DTSTART:${startOfDaylightSavingTime.format('YYYYMMDDTHHmmss')}\n`;
      // tzRuleDaylight += `TZOFFSETFROM:${tzOffsetStandard}\n`;
      // tzRuleDaylight += `TZOFFSETTO:${tzOffsetDaylight}\n`;

      tzRuleDaylight += `TZOFFSETFROM:-00:00:00\n`;
      tzRuleDaylight += `TZOFFSETTO:-00:00:00\n`;
      tzRuleDaylight += `TZNAME:${tzNameDaylight}\n`;
  
      tzRuleStandard += `DTSTART:${startOfStandardTime.format('YYYYMMDDTHHmmss')}\n`;

      tzRuleStandard += `TZOFFSETFROM:-00:00:00\n`;
      tzRuleStandard += `TZOFFSETTO:-00:00:00\n`;

      // tzRuleStandard += `TZOFFSETFROM:${tzOffsetDaylight}\n`;
      // tzRuleStandard += `TZOFFSETTO:${tzOffsetStandard}\n`;
      tzRuleStandard += `TZNAME:${tzNameStandard}\n`;
    }
  
    const vtimezone = `BEGIN:VTIMEZONE\nTZID:${timezone}\nTZURL:http://tzurl.org/zoneinfo-outlook/${timezone}\n${tzRuleStandard}BEGIN:DAYLIGHT\n${tzRuleDaylight}END:DAYLIGHT\nEND:VTIMEZONE\n`;
  
    return vtimezone;
  }

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  function generateVTimezone() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
  
    // This is a simple and not fully correct VTIMEZONE component,
    // as it doesn't handle DST transitions or historical changes in time zone rules.
    // You would need to use a library or external data to handle this correctly.
    const vtimezone = `
  BEGIN:VTIMEZONE
  TZID:${timezone}
  BEGIN:STANDARD
  DTSTART:${formatDate(now)}
  TZOFFSETFROM:-0000
  TZOFFSETTO:-0000
  TZNAME:${timezone}
  END:STANDARD
  END:VTIMEZONE
  `;
  
    return vtimezone;
  }
  
  function formatDate(date) {
    return date.toISOString().replace(/[-:]/g, '').split('.')[0];
  }
  
  // Helper function to format offsets
  function formatOffset(offset) {
    const sign = offset >= 0 ? '+' : '-';
    const absoluteOffset = Math.abs(offset);
    const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0');
    const minutes = String(absoluteOffset % 60).padStart(2, '0');
    return `${sign}${hours}${minutes}`;
  }

  function generateVTimeZoneApple() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tz = moment.tz(timezone);
    let offset = getOffset(timezone);
    alert(offset);
  
    const tzOffsetStandard = tz.format('Z');
    const tzOffsetDaylight = tz.format('Z', moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).isDST());
  
    const tzNameStandard = tz.zoneAbbr();
    const tzNameDaylight = tz.zoneAbbr(true);
  
    const tzStartYear = 2023;
    const tzEndYear = 2024;
  
    let tzRuleStandard = '';
    let tzRuleDaylight = '';
  
    for (let year = tzStartYear; year <= tzEndYear; year++) {
      const startOfYear = moment.tz(`01/01/${year}`, timezone);
      const startOfDaylightSavingTime = startOfYear.clone().month(2).day(8).hour(2).minute(0).second(0);
      const startOfStandardTime = startOfYear.clone().month(10).day(1).hour(2).minute(0).second(0);
  
      if (startOfDaylightSavingTime.year() !== year) {
        startOfDaylightSavingTime.add(1, 'week');
      }
  
      if (startOfStandardTime.year() !== year) {
        startOfStandardTime.add(1, 'week');
      }
  
      tzRuleDaylight += `DTSTART:${startOfDaylightSavingTime.format('YYYYMMDDTHHmmss')}\n`;
      // tzRuleDaylight += `TZOFFSETFROM:${tzOffsetStandard}\n`;
      // tzRuleDaylight += `TZOFFSETTO:${tzOffsetDaylight}\n`;

      tzRuleDaylight += `TZOFFSETFROM:-00:00:00\n`;
      tzRuleDaylight += `TZOFFSETTO:-00:00:00\n`;
      tzRuleDaylight += `TZNAME:${tzNameDaylight}\n`;
  
      tzRuleStandard += `DTSTART:${startOfStandardTime.format('YYYYMMDDTHHmmss')}\n`;

      tzRuleStandard += `TZOFFSETFROM:-00:00:00\n`;
      tzRuleStandard += `TZOFFSETTO:-00:00:00\n`;

      // tzRuleStandard += `TZOFFSETFROM:${tzOffsetDaylight}\n`;
      // tzRuleStandard += `TZOFFSETTO:${tzOffsetStandard}\n`;
      tzRuleStandard += `TZNAME:${tzNameStandard}\n`;
    }
  
    const vtimezone = `BEGIN:VTIMEZONE\nTZID:${timezone}\nTZURL:http://tzurl.org/zoneinfo-outlook/${timezone}\n${tzRuleStandard}BEGIN:DAYLIGHT\n${tzRuleDaylight}END:DAYLIGHT\nEND:VTIMEZONE\n`;
  
    return vtimezone;
  }

  function generateUUID() {
    let uuid = '';
    for (let i = 0; i < 32; i++) {
      const randomHex = Math.floor(Math.random() * 16).toString(16);
      uuid += (i === 8 || i === 12 || i === 16 || i === 20) ? '-' : '';
      uuid += randomHex;
    }
    return uuid;
  }  

  const downloadCalendar = () => {
    //alert(scheduled_date_time);
    const vtimezone = generateVTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const date = new Date(scheduled_date_time);
    console.log(scheduled_date_time);
    console.log('---------------------');
    console.log(date);
    console.log('---------------------');
    //alert(date);
    const dateString = date.toISOString().replace(/[:-]/g, '');
    const iCalDateString = `${dateString.substring(0, 15)}`;
    let uid = generateUUID();

    let _endDate = new Date(scheduled_date_time);
    _endDate.setMinutes(_endDate.getMinutes() + 30);
    const dateStringEnd = _endDate.toISOString().replace(/[:-]/g, '');
    const iCalDateStringEnd = `${dateStringEnd.substring(0, 15)}`;

    console.log(iCalDateString);
    console.log(iCalDateStringEnd);
    // DTSTART;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateString}
    //DTEND;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateStringEnd}
/*
// DTSTART:${iCalDateString}
// DTEND:${iCalDateStringEnd}
*/
    let calendarData = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//LeadrPro//LeadrPro.com//EN
${vtimezone}
BEGIN:VEVENT
STATUS:CONFIRMED
SEQUENCE:0
UID:${uid}
DTSTAMP:20220418T120000Z
DTSTART;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateString}
DTEND;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateStringEnd}
SUMMARY:LeadrPro Demo Meeting
DESCRIPTION:Meeting+link:+https://meetings.leadrpro.com/demo?refid=${reference_id}&sf=true&output=xml&ctz=${Intl.DateTimeFormat().resolvedOptions().timeZone}
END:VEVENT
END:VCALENDAR`;
    const calendarBlob = new Blob([calendarData], { type: 'text/calendar' });
    const calendarURL = URL.createObjectURL(calendarBlob);
    const link = document.createElement('a');
    link.href = calendarURL;
    link.download = 'calendar.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadCalendarApple = () => {
    const vtimezone = generateVTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const date = new Date(scheduled_date_time);
    console.log(scheduled_date_time);
    console.log('---------------------');
    console.log(date);
    console.log('---------------------');
    let offset = getOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

    date.setMinutes(date.getMinutes() + offset);
console.log('offset', offset);
    const dateString = date.toISOString().replace(/[:-]/g, '');
    const iCalDateString = `${dateString.substring(0, 15)}`;
    let uid = generateUUID();

    let _endDate = new Date(scheduled_date_time);
    _endDate.setMinutes(_endDate.getMinutes() + offset);
    _endDate.setMinutes(_endDate.getMinutes() + 30);
    const dateStringEnd = _endDate.toISOString().replace(/[:-]/g, '');
    const iCalDateStringEnd = `${dateStringEnd.substring(0, 15)}`;

    console.log('icaldatestring', iCalDateString);
    console.log('icaldatestringend', iCalDateStringEnd);
    // DTSTART;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateString}
    //DTEND;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateStringEnd}
/*
// DTSTART:${iCalDateString}
// DTEND:${iCalDateStringEnd}
*/
    let calendarData = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//LeadrPro//LeadrPro.com//EN
${vtimezone}
BEGIN:VEVENT
STATUS:CONFIRMED
SEQUENCE:0
UID:${uid}
DTSTAMP:20220418T120000Z
DTSTART;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateString}
DTEND;TZID=${Intl.DateTimeFormat().resolvedOptions().timeZone}:${iCalDateStringEnd}
SUMMARY:LeadrPro Demo Meeting
DESCRIPTION:Meeting+link:+https://meetings.leadrpro.com/demo?refid=${reference_id}&sf=true&output=xml&ctz=${Intl.DateTimeFormat().resolvedOptions().timeZone}
END:VEVENT
END:VCALENDAR`;
    const calendarBlob = new Blob([calendarData], { type: 'text/calendar' });
    const calendarURL = URL.createObjectURL(calendarBlob);
    const link = document.createElement('a');
    link.href = calendarURL;
    link.download = 'calendar.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <div className='calendarlinks'>
        {!meeting_link && (
          <button className='btn--calendar'
            href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Meeting+with+${encodeURIComponent(
              persona_type
            )}&dates=${startDate}/${endDate}&details=Meeting+link:+https://meetings.leadrpro.com/demo?refid=${reference_id}&sf=true&output=xml&ctz=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`}
            target='_blank' onClick={() => handleShowPopUp()}>
            <FaRegCalendarAlt /> Add to Calendar
          </button>
        )}

        {meeting_link && (
          <button className='btn--calendar'
            href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Meeting+with+${encodeURIComponent(
              persona_type
            )}&dates=${startDate}/${endDate}&details=Meeting+link:+${meeting_link}&sf=true&output=xml&ctz=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`}
            target='_blank' onClick={() => handleShowPopUp()}>
            <FaRegCalendarAlt /> Add to Calendar
          </button>
        )}

        {showPopUp && (
          <div className='calendarlinks__popup'>
            <div className=''>
              <a className='btn--calendar calendarlinks__popup-button'
              href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Meeting+with+${encodeURIComponent(
                persona_type
              )}&dates=${startDate}/${endDate}&details=Meeting+link:+https://meetings.leadrpro.com/demo?refid=${reference_id}&sf=true&output=xml&ctz=${
                Intl.DateTimeFormat().resolvedOptions().timeZone
              }`}
              target='_blank' onClick={() => handleShowPopUp()}>
                  <FaGoogle /> Google Calendar
            </a>
            </div>

            <div className=''>
              <button className='btn--calendar calendarlinks__popup-button' onClick={() => downloadCalendarApple()}>
                  <FaApple /> Apple
            </button>
            </div>

            <div className=''>
              <button className='btn--calendar calendarlinks__popup-button' onClick={() => downloadCalendar()}>
                  <FaMicrosoft /> Outlook
            </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarLinks;
