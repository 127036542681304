import './Company.css';
import './Company2.css';
import { useEffect, useState } from 'react';
import { searchCompanyCategory, saveCompany, unsaveCompany, getCompany, getCompanyCategories, getCompanyReviewAverages, getCompanyReviews, getBuyerDemoFromCompanyId, getPublicCompanyMedia, refreshToken, addToWaitlist, createSellerDemo, addCompanyView, getUserProfile, workEmailIsVerified, userIsOwner, addDemoMessages, updateSIDToViewed, addCompanyClick, hasAvailableApplications} from '../../utils/api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import SellerConfirm from '../search/SellerConfirm';
import {Helmet} from "react-helmet";
import BuyerBookingSellerFromCompany from '../../components/demos/BuyerBookingSellerFromCompany';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import SubmitTimes from '../../components/qualifier/SubmitTimes';
import { isAdmin, isAuthed, isBuyer, isEventAttendee, refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import ScrollToTop from '../../components/layout/ScrollToTop';
import { FaClock, FaRegClock, FaUsers, FaRegStar, FaStar, FaWrench, FaUser, FaMagic, FaRegCommentDots, FaCommentDots, FaCheck, FaRegHeart, FaHeart, FaShare, FaTimes, FaRegCheckCircle, FaRegBell, FaCheckCircle, FaRegEdit, FaBriefcase, FaHeadphonesAlt, FaCalendarAlt, FaCodeBranch, FaCode, FaChartLine, FaAward, FaRocket, FaDollarSign, FaGlobe, FaSlack, FaStripe, FaShopify, FaUnlockAlt, FaProductHunt } from 'react-icons/fa';
import CompanyFeed from '../feed/CompanyFeedForProfile';
import FadeIn from '../../utils/fadein';
import { getSeoName, checkSession } from '../../utils/utils';
import { loginUser } from '../../utils/api';
import imgCoin from '../../assets/rewards/lp-coin.png';
import queryString from 'query-string';
import LeadForm from '../../components/leadform/LeadForm';
import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};

function Company2({refreshPoints}) {
    const [company, setCompany] = useState({companies: []});
    const [reviewAverages, setReviewAverages] = useState([]);
    const [showBooking, setShowBooking] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasBooked, setHasBooked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [showQualifier, setShowQualifier] = useState(false);
    const [showSelectedTime, setShowSelectedTime] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [currentDemo, setCurrentDemo] = useState({});
    const [hasDemo, setHasDemo] = useState(false);
    const [media, setMedia] = useState([]);
    const [showScreenshot, setShowScreenshot] = useState(false);
    const [selectedScreenshot, setSelectedScreenshot] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [showWaitlist, setShowWaitlist] = useState(false);
    const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [userIsOnWaitlist, setUserIsOnWaitlist] = useState(false);
    const [painPoint, setPainPoint] = useState('');
    const [icp, setIcp] = useState('');
    const [better, setBetter] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [showUnverified, setShowUnverified] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [isOwnerLoaded, setIsOwnerLoaded] = useState(false);
    let { id } = useParams();
    const [applied, setApplied] = useState(false);
    const [showReviews, setShowReviews] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [submittingMessage, setSubmittingMessage] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');
    const [submittingLogin, setSubmittingLogin] = useState(false);
    const [showMaxReached, setShowMaxReached] = useState(false);
    const [hasAvailable, setHasAvailable] = useState(true);
    const [showPricing, setShowPricing] = useState(false);
    const [formType, setFormType] = useState('Pricing');
    let imageUrl = process.env.REACT_APP_IMAGE_URL;
    const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
    const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
    const navigate = useNavigate();

    useEffect(() => {
      const parsed = queryString.parse(window.location.search);
      let sid = parsed['sid'];
  
      if (sid && sid.length > 0)
      {
        updateSIDToViewed(sid);
      }
    }, []);

    useEffect(async function(){
      await checkIsOwner();
        await loadCompany();
        await loadReviewAverages();
        await loadMedia();
        await addCompanyView(id, 'Company details');
        await loadUserProfile();
        await checkSession('Company');
    }, []);

    useEffect(() => {
      if (showConfirmation){
        refreshPoints();
      }
    }, [showConfirmation]);

    async function checkIsOwner(){
      let res = await userIsOwner(id);

      if (res.success){
        setIsOwner(res.data);
        setIsOwnerLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await userIsOwner(id);

        if (res.success){
          setIsOwner(res.data);
          setIsOwnerLoaded(true);
        }
      }
    }

    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        let _user = res.data;
        if (_user.work_email_verified){
          setIsVerified(true);
        }

        else{
          setIsVerified(false);
        }

        if (_user.is_disabled){
          setIsEnabled(false);
        }

        else{
          setIsEnabled(true);
        }

        setProfileLoaded(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getUserProfile();

        if (res.success){
          let _user = res.data;
          if (_user.work_email_verified){
            setIsVerified(true);
          }
  
          else{
            setIsVerified(false);
          }

          if (_user.is_disabled){
            setIsEnabled(false);
          }
  
          else{
            setIsEnabled(true);
          }
  
          setProfileLoaded(true);
        }
      }
    }

    function getStars(number){
      let mainindex = 0;
      if (!Number.isInteger(Math.ceil(number)))
        number = 0;
  
      let el = [];
      for (let i = 0; i < number; i++){
        el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
        mainindex++;
      }

      for (let i = 0; i < 5 - number; i++){
        el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
        mainindex++;
      }
  
      return (
        <div>
          {el}
        </div>
      )
    }

    async function loadMedia(){
      let res = await getPublicCompanyMedia(id);

      if (res.success){
        setMedia(res.data);
      }
    }

    async function loadReviewAverages(){
      let response = await getCompanyReviewAverages(id);

      if (response.success){
        setReviewAverages(response.data);
      }
    }

    async function loadCompany(){
      let response = await getCompany(id);

      if(response.success){
        setCompany(response.data[0]);
        setIsSaved(response.data[0].is_saved);
        setUserIsOnWaitlist(response.data[0].user_on_waitlist !== '0');

        if (response.data[0].pain_point)
          setPainPoint(response.data[0].pain_point.replace(/\n/g, '<br>'));

        if (response.data[0].ideal_customer_profile)
          setIcp(response.data[0].ideal_customer_profile.replace(/\n/g, '<br>'));

        if (response.data[0].unique_value_proposition)
          setBetter(response.data[0].unique_value_proposition.replace(/\n/g, '<br>'));
      }

      else if (response.status === 403){
        await refresh();
        let response = await getCompany(id);

        if(response.success){
          setCompany(response.data[0]);
          setIsSaved(response.data[0].is_saved);

          setUserIsOnWaitlist(response.data[0].user_on_waitlist !== '0');

          if (response.data[0].pain_point)
            setPainPoint(response.data[0].pain_point.replace(/\n/g, '<br>'));

          if (response.data[0].ideal_customer_profile)
            setIcp(response.data[0].ideal_customer_profile.replace(/\n/g, '<br>'));

          if (response.data[0].unique_value_proposition)
            setBetter(response.data[0].unique_value_proposition.replace(/\n/g, '<br>'));
          }
      }

      setLoaded(true);
    }

  async function demoAccepted(){
    setApplied(true);
  }

  async function handleScreenshotClick(image){
    setShowScreenshot(true);
    setSelectedScreenshot(image);
  }

  async function handleCloseScreenshotClick(){
    setShowScreenshot(false);
    setSelectedScreenshot('');
  }

  async function handleWaitlistClick(demo_id, item){
    setShowWaitlist(true);
  }
  
  async function handleWaitlistConfirmClick(){
    setSubmitting(true);
    let res = await addToWaitlist(id);

    if (res.success){
      setShowWaitlistConfirm(true);
      setShowWaitlist(false);
      setSubmitting(false);
      setUserIsOnWaitlist(true);
      loadCompany();
    }

    else if (res.status === 403){
      await refresh();
      let res = await addToWaitlist(id);

      if (res.success){
        setShowWaitlistConfirm(true);
        setShowWaitlist(false);
        setSubmitting(false);
        setUserIsOnWaitlist(true);
        loadCompany();
      }
    }
  }

  async function loadCurrentDemo(){
    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success){
      if (_res.data.length > 0){
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
      }

      else{
        setHasDemo(false);
        alert('no demo');
      }
    }
  }

  //loadCurrentDemo();

  async function handleApplyClick(){
    let _verified = await workEmailIsVerified();

    if (_verified.success){
      if (_verified.data === false){
        if (isBuyer() == true)
        {
         // setShowUnverified(true);
          //return;
        }
      }
    }

    else if (_verified.status === 403){
      await refresh();

      let _verified = await workEmailIsVerified();

      if (_verified.success){
        if (_verified.data === false){
          if (isBuyer() == true)
          {
           // setShowUnverified(true);
           // return;
          }
        }
      }
    }

    let _check = await hasAvailableApplications();

    if (_check.success){
      if (_check.data === true){
        setShowMaxReached(false);
        setHasAvailable(true);
      }

      else{
        setHasAvailable(false);
        setShowMaxReached(true);
        //setBookingLoading(false);
        return;
      }
    }

    setSubmitting(true);
    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success){
      if (_res.data.length > 0){
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
        setShowQualifier(true);
      }

      else{
        let data = {seller_id: company.seller_id};
        let _res = await createSellerDemo(data);
  
        if (_res.success){
         let _res = await getBuyerDemoFromCompanyId(id);

         if (_res.success){
           if (_res.data.length > 0){
             setCurrentDemo(_res.data[0]);
             setHasDemo(true);
             setShowQualifier(true);
           }
          }
        }
      }
    }

    else if (_res.status === 403){
      await refresh();

        let _res = await getBuyerDemoFromCompanyId(id);

        if (_res.success){
          if (_res.data.length > 0){
            setCurrentDemo(_res.data[0]);
            setHasDemo(true);
            setShowQualifier(true);
          }

          else{
            let data = {seller_id: company.seller_id};
            let _res = await createSellerDemo(data);
      
            if (_res.success){
            let _res = await getBuyerDemoFromCompanyId(id);

            if (_res.success){
              if (_res.data.length > 0){
                setCurrentDemo(_res.data[0]);
                setHasDemo(true);
                setShowQualifier(true);
              }
              }
            }
          }
        }
    }

    setSubmitting(false);
    await addCompanyClick(id, 'Company details');
  }

  async function handleSaveClick(){
    let res = await saveCompany(id);

    if (res.success){
      setIsSaved(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await saveCompany(id);

      if (res.success){
        setIsSaved(true);
      }
    }
  }

  async function handleUnsaveClick(){
    let res = await unsaveCompany(id);

    if (res.success){
      setIsSaved(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await unsaveCompany(id);

      if (res.success){
        setIsSaved(false);
      }
    }
  }

  async function handleSendMessage(){
    if (messageText.length == 0){
      return;
    }

    let tmpid = null;

    setSubmittingMessage(true);

    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success){
      if (_res.data.length > 0){
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
        tmpid = _res.data[0].reference_id;
      }

      else{
        let data = {seller_id: company.seller_id};
        let _res = await createSellerDemo(data);
  
        if (_res.success){
         let _res = await getBuyerDemoFromCompanyId(id);

         if (_res.success){
           if (_res.data.length > 0){
             setCurrentDemo(_res.data[0]);
             setHasDemo(true);
             tmpid = _res.data[0].reference_id;
           }
          }
        }
      }
    }

    else if (_res.status === 403){
      await refresh();

      let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success){
      if (_res.data.length > 0){
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
        tmpid = _res.data[0].reference_id;
      }

      else{
        let data = {seller_id: company.seller_id};
        let _res = await createSellerDemo(data);
  
        if (_res.success){
         let _res = await getBuyerDemoFromCompanyId(id);

         if (_res.success){
           if (_res.data.length > 0){
             setCurrentDemo(_res.data[0]);
             setHasDemo(true);
             tmpid = _res.data[0].reference_id;
           }
          }
        }
      }
    }
    }

    let res = await addDemoMessages(tmpid, messageText);

    if (res.success){
      setShowChat(false);
      setSubmittingMessage(false);
      setMessageSent(true);
    }

    else if(res.status === 403){
      await refresh();

      let res = await addDemoMessages(currentDemo.reference_id, messageText);

      if (res.success){
        setShowChat(false);
        setSubmittingMessage(false);
        setMessageSent(true);
      }
    }
  }

  function generateMetaDescription(inputString) {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        // Truncate the string to the maximum length minus 1 (for the ellipsis)
        // and ensure we don't cut off in the middle of a word.
        let trimmedString = inputString.substr(0, maxLength - 1);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return `${trimmedString}…`;
    }
  }

  async function handleWaitlistClick(){
    // setSelectedCompanyId(item.company_id);
    // setCurrentDemo(item);
    setShowWaitlist(true);
  }

  async function handleLogin(){
    //setLoginStatus('Invalid password');
    handleSubmitLogin();
  }

  async function handleSubmitLogin(e){
   // e.preventDefault();
   // let el1 = e.target.elements.email.value;
   // let el2 = e.target.elements.password.value;
    setSubmittingLogin(true);

    if (loginEmail.length > 0 && loginPassword.length > 0){
        let response = await loginUser(loginEmail, loginPassword);

        if (response.success){
           let tokens = response.data;

           localStorage.setItem('access_token', tokens.accessToken);
           localStorage.setItem('refresh_token', tokens.refreshToken);

           setCookie('access_token', tokens.accessToken, 1);
           window.location = window.location;
        }

        else{
            //setHasError(true);
            setLoginStatus("Oops! Incorrect email or password");
            setSubmittingLogin(false);
        }
    }

    else{
      setLoginStatus("Please enter a valid email/password");
      setSubmittingLogin(false);
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';

    if (process.env.REACT_APP_NODE_ENV === 'Dev'){
        _domain = 'domain=leadrpro-client-staging.vercel.app';
    }

    document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  async function handleGetPricing(type){
    setFormType(type);
    setShowPricing(true);
  }

  return (
    <div className='company__wrapper'>
      <ScrollToTop />
    <div className='company container'>
      {loaded === true && (
        <Helmet>
          <title>{company.name} - Company info, pricing and reviews</title>

          {(company.description == null || company.description.length == 0) && (
            <meta name='description' content={`Book a 30-minute discovery demo with ${company.name} and earn $75. Discover the latest in innovative software solutions while getting paid.`} />
          )}
          
          {company.description != null && company.description.length > 0 && (
            <meta name='description' content={generateMetaDescription(company.description)} />
          )}

          <link rel="canonical" href={`https://www.leadrpro.com/company/${company.company_id}/${company.url_slug}`} />

          <meta property="og:title" content={`${company.name} - Company info, pricing and reviews`} />
          <meta property="og:type" content="product" />
          <meta property="og:url" content={`https://www.leadrpro.com/company/${company.company_id}/${company.url_slug}`} />

          {company.thumbnail_image && company.thumbnail_image.length > 0 && (
            <meta property="og:image" content={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + company.thumbnail_image} />
        )}

          {company.description && company.description.length > 0 && (
            <meta property="og:description" content={generateMetaDescription(company.description)} />
          )}

        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@yourtwitterhandle" />
        <meta name="twitter:title" content="Eco-Friendly Living: Sustainable Products for a Greener Tomorrow" />
        <meta name="twitter:description" content="Explore our range of eco-friendly products designed to promote sustainable living. From organic clothing to renewable energy solutions, find everything you need to reduce your environmental footprint." />
        <meta name="twitter:image" content="https://example.com/images/twitter-image.jpg" /> */}

        </Helmet>
      )}

      {loaded === false && (
        <section className='company__placeholders'>
          <div className='company__placeholder'>
            <Spinner />
          </div>

          <div className='company__placeholder'>
          </div>
        </section>
      )}

      {loaded === true && (
        <section className='company__body'>
      <div className="company__header">
        <div className="company__stats">
        <div className="company__logo">
          {company.logo && (
           <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + company.logo} alt="" />
            )}
                
            {!company.logo && (
              <span className="searchresults__company-initial company__initial">
                {company.name && company.name[0]}
              </span>
            )}
          </div>

          <div className="company__details">
          {company.website && company.website.length > 0 && (
            <div className='company__link'>
              <a href={company.website.startsWith('http') ? company.website : 'https://' + company.website} rel='nofollow noopener' target='_blank'>Company Website</a>
            </div>
          )}

            <h1 className="company__name">{company.name}</h1>

            {company.industry_name && (
              <div className="company__industry">{company.industry_name}</div>
            )}

            <div className="company__review">
              {company.average_rating && (
                <div className="company__stars">
                    {getStars(Math.ceil(company.average_rating))}

                    <span className="company__star-count">{Math.round(company.average_rating * 10) / 10} out of 5</span>
                </div>
              )}

              {company.average_rating == null && (
                <div className="company__stars">
                  {getStars(Math.ceil(0))}

                  <span className="company__star-count">No reviews</span>
                </div>
              )}
            </div>

            {loaded && company.buyer_feedback.length > 0 && (
              <div>
                <a href='#reviews' className='company__link'>{company.buyer_feedback.length} reviews</a>
              </div>
            )}
          </div>
        </div>

        <div className="company__info-btns">
          {isAuthed() && !showConfirmation && (
            <>
              {profileLoaded == false && (
                <Spinner />
              )}

              {loaded == true && profileLoaded === true && company.on_waitlist === false && company.demo_pending === false && company.is_seller && company.is_paused === false && company.is_inactive === false && (
                <div className='company__btns'>
                  {submitting === false && applied === false && isEnabled === true && isOwner == false && isOwnerLoaded && (
                    <button className='btn company__btn-book' onClick={() => handleApplyClick()}>Book a Demo {'(Earn $75)'}</button>
                  )}

                  {isOwnerLoaded == false && (
                    <Spinner />
                  )}

                  {submitting && (
                    <Spinner />
                  )}
                </div>
              )}

              {loaded && profileLoaded && (company.is_seller === false || company.is_paused == true || company.is_inactive == true) && (
                <div style={{margin: '20px 0px'}}>
                  {company.user_on_waitlist === false && (
                    <button onClick={() => handleWaitlistClick()} className='btn company__btn-notify'><FaRegBell /> Notify me when available</button>
                  )}

                  {company.user_on_waitlist && (
                    <span className='browsesellers__on-waitlist'><FaCheckCircle style={{color: 'mediumseagreen'}} /> You're on the list</span>
                  )}
                </div>
              )}

              {loaded == false && (
                <Spinner />
              )}

              {company.on_waitlist === false && (company.demo_pending || applied === true) && (
                <div className='company__application-pending' style={{fontSize: 'small'}}>
                  <FaRegClock /> <strong>You've applied to demo. Hang tight!</strong>
                </div>
              )}

              <div className='company__options'>
                {isAuthed() == true && company.is_seller === true && company.is_paused === false && company.is_inactive === false && (
                  <div className='company__option' onClick={() => setShowChat(true)}><FaRegCommentDots /> Chat</div>
                )}

                  {isSaved == false && (
                    <div className='company__option' onClick={() => handleSaveClick()}>
                      <FaRegHeart /> <span>Save</span>
                    </div>
                  )}

                  {isSaved == true && (
                    <div className='company__option' onClick={() => handleUnsaveClick()}>
                      <FaHeart style={{color: 'salmon'}} /> <span>Saved</span>
                    </div>
                  )}

                <div className='company__option' onClick={() => setShowShare(true)}><FaShare /> Share</div>
              </div>

              {company.on_waitlist === true && (
                <>
                  {!userIsOnWaitlist && (
                    <span>
                      <button className='btn btn--primary' onClick={() => handleWaitlistClick()}>Join the Waitlist</button>
                    </span>
                  )}

                  {userIsOnWaitlist && (
                    <span>
                      You're on the waitlist
                    </span>
                  )}
                </>
              )}
            </>
          )}

          {isAuthed() && isAdmin() && (
            <>
              <div className='company__advice-btns'>
                <span><button className='btn' onClick={() => handleGetPricing('Pricing')}>Get Pricing</button></span>

                <span><button className='btn' onClick={() => handleGetPricing('WatchDemo')}>Watch Demo</button></span>

                <span><button className='btn' onClick={() => handleGetPricing('RequestDemo')}>Request Demo</button></span>
              </div>
            </>
          )}

          {!isAuthed() && (
            <div className='company__login-form' style={{marginTop: '40px'}}>
              <div>
                <div style={{textAlign: 'left'}}>
                  <strong>Login to book a demo</strong>
                </div>

                <div className='mt-10'>
                  <input type='text' placeholder='Email address' value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} className='input' />
                </div>

                <div className='mt-10'>
                  <input type='password' placeholder='Password' value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} className='input' />
                </div>

                <div className='mt-10'>
                  {submittingLogin === false && (
                  <button className='btn btn--primary company__btn-signup' onClick={() => handleLogin()}>Login</button>
                  )}

                  {submittingLogin && (
                    <Spinner />
                  )}
                </div>

                <div className='mt-10 company__login-status'>
                  {loginStatus}
                </div>
              </div>

              <hr className='mt-20' />

              <div className='mt-10'>
              Or <a href='/signup' className='link'>Create free account to book a demo</a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="company__info-description">
        {company.thumbnail_image && company.thumbnail_image.length > 0 && (
          <div className='company__thumbnail'>
            <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + company.thumbnail_image} alt="" />
          </div>
        )}

          {company.description && (
            <div>
               {company.description}
            </div>
          )}

          {!company.description && loaded === true && (
            <p>
              No description found
            </p>
          )}
      </div>

      {submitting == false && isOwnerLoaded && isOwner == true && (
        <div style={{position: 'absolute', bottom: '5px', right: '5px'}}>
          <Link to='/account/company/edit' className='btn' style={{padding: '5px 10px'}}><FaRegEdit /> Edit Page</Link>
        </div>
      )}
      </section>
      )}

      {loaded && company.featuremaps.length > 0 && (
        <section className=''>
          <h2>Pro Features</h2>
          <div className='company__pro-features'>

          {company.featuremaps.map((x, index) => (
            <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'>
            <IconDisplay iconName={x.icon} />
            </span>
            <span className='company__pro-feature-label'>{x.name}</span>
          </div>
          ))}

          {/* <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaRocket />
            <IconDisplay iconName={'FaRocket'} />
            </span>
            <span className='company__pro-feature-label'>Perfect for startups</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaHeadphonesAlt /></span>
            <span className='company__pro-feature-label'>Customer Support</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaCalendarAlt /></span>
            <span className='company__pro-feature-label'>30 Day Free Trial</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaCodeBranch /></span>
            <span className='company__pro-feature-label'>5-Minute Implementation</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaCode /></span>
            <span className='company__pro-feature-label'>No-Code Solution</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaChartLine /></span>
            <span className='company__pro-feature-label'>Real-time tracking and analytics</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaUsers /></span>
            <span className='company__pro-feature-label'>Team management</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaAward /></span>
            <span className='company__pro-feature-label'>In business for over 10 years</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaAward /></span>
            <span className='company__pro-feature-label'>We are a proud Fortune 500 company</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaDollarSign /></span>
            <span className='company__pro-feature-label'>Fully Transparent Pricing</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaGlobe /></span>
            <span className='company__pro-feature-label'>Free Online Profile</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaSlack /></span>
            <span className='company__pro-feature-label'>Members get access to our Slack community</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaStripe /></span>
            <span className='company__pro-feature-label'>Stripe Integration</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaShopify /></span>
            <span className='company__pro-feature-label'>Shopify Integration</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaUnlockAlt /></span>
            <span className='company__pro-feature-label'>The latest security standards</span>
          </div>

          <div className='company__pro-feature'>
            <span className='company__pro-feature-icon'><FaProductHunt /></span>
            <span className='company__pro-feature-label'>#1 Product Hunt product of the day</span>
          </div> */}

 
          </div>

          {/* <div className='center mt-20'>
            <button>View All Pro Features</button>
          </div> */}
        </section>
      )}

      {loaded && company && company.features && company.features.length > 0 && (
        <section>
        <div className='company__features'>
          <h2>Features</h2>

          <ul>
          {company.features.map((x, index) => (
            <li className='company__feature' key={index}>
              <FaCheck /> <span style={{marginLeft: '10px'}}>{x.feature_text}</span>
            </li>
          ))}
          </ul>
        </div>
        </section>
      )}

      {company && company.pricing && company.pricing.length > 0 && (
        <section>
          <div className='company__pricing'>
            <h2>Pricing</h2>

            <div className='company__pricing-list'>
              {company.pricing.map((price, index) => (
                <div className='company__pricing-item' key={index}>
                  <div className='company__pricing-item-name'>{price.name}</div>
                  <div className='company__pricing-item-description'>{price.description}</div>
                  <div className='company__pricing-item-price'>${price.price}
                  
                  {price.unit && price.unit.length > 0 && (
                  <span className='company__pricing-item-unit'>/ {price.unit}</span>
                  )}
                  </div>
                </div>
              ))}

              <div className='company__pricing-item'>
                <div className='center' style={{padding: '30px'}}>
                  {isAuthed() == true && (
                    <button className='btn btn--primary' onClick={() => handleApplyClick()} style={{backgroundColor: 'dodgerblue', padding: '10px 20px'}}>Book a demo to learn more</button>
                  )}

                  {isAuthed() == false && (
                    <button className='btn btn--primary' onClick={() => navigate('/signup')} style={{backgroundColor: 'dodgerblue', padding: '10px 20px'}}>Book a demo to learn more</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {media.length > 0 && (
        <section>
          <h2>Product Screenshots</h2>

          <div className='company__screenshots'>
            {media.map((item, index) => (
              <div key={index} className='company__screenshot' onClick={() => handleScreenshotClick(item.media_source)}>
                <img src={screenshotUrl + item.media_source} alt="" />
              </div>
            ))}
          </div>
        </section>
      )}

      {showScreenshot && (
        <>
          <div className='overlay'></div>
          <div className='modal company__screenshot-modal'>
            <img src={screenshotUrl + selectedScreenshot} alt="" style={{maxWidth: "100%"}} />

            <div className='modal__close' onClick={() => handleCloseScreenshotClick()}>
              <FaTimes />
            </div>
          </div>
        </>
      )}

      {/* {categories.length > 0 && (
      <section className="company__categories">
        <h2>Categories</h2>

        <div className="company__category-list">
          {categories.map(item => (
            <div>
              {item.name}
            </div>
          ))}
        </div>
      </section>
      )} */}

      {loaded && company.pain_point && (
        <section className='company__info'>
          <h2><FaWrench /> Pain we are solving</h2>

          <p dangerouslySetInnerHTML={{ __html: painPoint }}>
            
          </p>
        </section>
      )}

      {loaded && company.ideal_customer_profile && (
      <section className='company__info'>
        <h2><FaUser /> Our ideal customer profile (ICP)</h2>

        <p dangerouslySetInnerHTML={{ __html: icp }}>
            
          </p>
      </section>
      )}

      {loaded && company.unique_value_proposition && (
        <section className='company__info'>
          <h2><FaMagic /> What we do better than others (Unique Value Proposition)</h2>

          <p dangerouslySetInnerHTML={{ __html: better }}>
            
            </p>
        </section>
      )}

      {loaded == true && (
      <section>
      {loaded  && (
        <div className='company__review-tabs'>
          <span onClick={() => setShowReviews(true)} className={`${showReviews == true ? 'company__review-tab--selected': 'company__review-tab'}`}>Reviews</span>
          <span onClick={() => setShowReviews(false)} className={`${showReviews == false ? 'company__review-tab--selected': 'company__review-tab'}`}>Discussion</span>
        </div>
      )}

      {showReviews === false && (
        <FadeIn delay={400} duration={400}>
          <CompanyFeed slug={id} />
        </FadeIn>
      )}

      {loaded && company.buyer_feedback.length === 0 && showReviews && (
        <div className="company__reviews">
          <strong>No reviews posted yet...</strong>
        </div>
      )}

      {loaded && company.buyer_feedback.length > 0 && showReviews && (
      <>
        <h2>Reviews {`(${company.buyer_feedback.length})`}</h2>

        <div className='company__ratings' id='reviews'>
          {loaded && company.buyer_feedback.slice(0, 3).map((item, index) => (
            <div className='company__rating' key={index}>
              <div className='company__rating-date'>{new Date(item.date_created).toLocaleDateString()}</div>
              <div className='company__rating-header'>{item.job_title} working in {item.industry?? 'Software'}</div>

              <div className='company__rating-stats'>
                <span className='company__rating-stats-label'><FaUsers /> Company size: </span>
                <span>{item.buyer_company_size}</span>
              </div>

              <div className='company__rating-stars'>
                {item.pain_point && (
                <div><span>Does this solution solve a pain point?</span> {getStars(parseInt(item.pain_point))}</div>
                )}

                {item.ease_of_integration && (
                <div><span>How you rate the ease of integration?</span> {getStars(parseInt(item.ease_of_integration))}</div>
                )}

                {item.likely_to_recommend && (
                <div><span>Would you recommend this solution?</span> {getStars(parseInt(item.likely_to_recommend))}</div>
                )}

                {item.overall_demo && (
                <div><span>How would you rate the overall demo experience?</span> {getStars(parseInt(item.overall_demo))}</div>
                )}
              </div>

              <div className='company__rating-feedback1'>
                "{item.feedback1}"
              </div>
            </div>
          ))}

          <div className=''>
            <Link className='btn' to={`/reviews/${company.company_id}/${company.url_slug != null && company.url_slug != '' ? company.url_slug : getSeoName(company.name)}`}>View all reviews</Link>
          </div>
        </div>
      </>
      )}
      </section>
      )}

      {showBooking && (
        <>
        <div className="overlay"></div>
          <BuyerBookingSellerFromCompany selectedBuyer={company} setShowBooking={setShowBooking} setShowConfirm={setShowConfirm} setHasBooked={setHasBooked} />
        </>
      )}

      {showConfirm && (
        <div className="overlay">
            <SellerConfirm company={company} setShow={setShowConfirm} />
        </div>
      )}

      {showQualifier && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--pad0'>
            <QualifierQuestions currentDemo={currentDemo} demo_id={currentDemo.demo_id} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
          </div>
        </>
      )}

      {showSelectedTime && (
        <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              {company.booking_style === 'daily_view' && (
                <AvailableTimes demo={currentDemo} demo_id={currentDemo.demo_id} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
              )}

              {company.booking_style === 'list_view' && (
                <SubmitTimes demo={currentDemo} demo_id={currentDemo.demo_id} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
              )}
            </div>
        </div>
      )}

    {showConfirmation && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowConfirmation(false)}>
          <FaTimes />
        </div>

        <div className='buyerdashboard__plus-points'>
          <span><img src={imgCoin} alt='' /></span>
          <strong className='ml-10'>+5</strong>
        </div>

        <h2 className='modal__h2 center'>Success!</h2>

        <p className=''>Your request is now being reviewed. You should hear back within 24 hours.</p>

        <p className=''>In the meantime, keep your profile up to date and check back daily for more demo opportunities!</p>

        <div className='center mt-20'>
          <button className='btn' onClick={() => setShowConfirmation(false)}>Close</button>
        </div>
      </div>
      </>
    )}
    </div>


    {showUnverified === true && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <h2 className='modal__h2'>
            ⚠️ Complete setting up your account!
          </h2>
          <p style={{marginTop:"20px"}}>
            You must verify your work email before you can apply for a demo.
          </p>

          <p>
            <a href='/account/work-email' className='link'>Verify work email</a>
          </p>
          <div className='center' style={{marginTop: "30px"}}>
            <button className='btn btn--secondary' onClick={() => setShowUnverified(false)}>Close</button>
          </div>
        </div>
      </>
    )}

    {showChat && (
      <>
        <div className='overlay'></div>
        <div className='modal company__modal-chat'>
          <div className='modal__close' onClick={() => setShowChat(false)}><FaTimes /></div>

          <div style={{fontSize: '14px', fontWeight: 'bold', color: '#999', marginBottom: '10px'}}>Ask a question</div>

          <div>
            <div className="company__details" style={{padding: '0px'}}>
              <div className="company__name">{company.name}</div>

              {company.industry_name && (
                <div className="company__industry">{company.industry_name}</div>
              )}

              <div className="company__review">
                {company.average_rating && (
                  <div className="company__stars">
                      {getStars(Math.ceil(company.average_rating))}

                      <span className="company__star-count">{Math.round(company.average_rating * 10) / 10} out of 5</span>
                  </div>
                )}

                {company.average_rating == null && (
                  <div className="company__stars">
                    {getStars(Math.ceil(0))}

                    <span className="company__star-count">No reviews posted  yet</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{marginTop: '20px'}}>
            <div style={{marginBottom: '10px'}}><strong>Send a message</strong></div>
            <textarea className='textarea input' value={messageText} onChange={(e) => setMessageText(e.target.value)} placeholder='Leave a message'></textarea>
          </div>

          <div style={{marginTop: '20px'}}>
            {submittingMessage == false && (
              <button className='btn btn--primary btn--send' onClick={() => handleSendMessage()}>Send Message</button>
            )}

            {submittingMessage && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {messageSent && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setMessageSent(false)}><FaTimes /></div>

          <div>
            <div className='center' style={{fontSize: '40px'}}>
              <FaRegCheckCircle />
            </div>

            <div style={{fontWeight: 'bold'}} className="center">
              Message successfully sent. Please wait 24-48hrs for a response.
            </div>

            <div className='center' style={{marginTop: '20px'}}>
              <button className='btn btn--small' onClick={() => setMessageSent(false)}>Close</button>
            </div>
          </div>
        </div>
      </>
    )}

    {showShare && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowShare(false)}><FaTimes /></div>

        <div className='modal__h2'>
          Share this company with a friend
        </div>

        <div style={{marginTop: '20px'}}>
          Earn $250 for every new seller and $50 for each new buyer that you refer to our platform.
        </div>

        <div style={{marginTop: '20px'}}>
          <a className='link' href='https://leadrpro.partnerstack.com/?group=leadrproaffiliatepartners' target='_blank'>Join our referral program</a>
        </div>
      </div>
    </>
    )}

{showWaitlist && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowWaitlist(false)}>
            <FaTimes />
          </div>

          <h2 className='modal__h2' style={{lineHeight:'1.1'}}>Get notified when <span style={{color: 'mediumpurple', textDecoration:'underline'}}>{company.name}</span> becomes available!</h2>

          <div className='mt-20'>You will be added to the waitlist and will be notified as soon as this company is ready to host demos.</div>

          <div className='center mt-20'>
            {submitting === false && (
            <button className='btn btn--primary btn--full' style={{padding: '10px'}} onClick={() => handleWaitlistConfirmClick()}><FaRegBell /> Sign me up</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {showWaitlistConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
          <div className='modal__close' onClick={() => setShowWaitlistConfirm(false)}>
            <FaTimes />
          </div>

          <div className='center' style={{fontSize: '30px', color: 'mediumseagreen'}}><FaCheckCircle /></div>

          <h3 className='modal__h2'>
            You have been added to the waitlist.
          </h3>

          <p className='mt-20'>
          You will be notified once this company is ready to host demos.
          </p>
        </div>
      </>
    )}

  {showMaxReached && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowMaxReached(false)}>
            <FaTimes />
          </div>

          <p>
            You have reached your maximum applications per day. Please come back tomorrow to apply for more.
          </p>
        </div>
      </>
    )}

    <LeadForm showform={showPricing} setShowForm={setShowPricing} formType={formType} company_id={id} />
    </div>
  );
}

export default Company2;
