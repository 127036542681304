import './PayoutMethod.css';
import {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

const PayoutMethod = ({userType}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    document.title = "LeadrPro - Manage Payouts";
  }, []);

  return (
    <div className="accountinfo payoutmethods">
      <Helmet>
          <meta name="description" content="" />
        </Helmet>
        
      <div className="account__back-link"><a href="/account/payout">Back</a></div>
      <div className="account__header">Payout methods</div>

      <div className='accountinfo__form payoutmethods__form'>
        <h3>Choose payout methods</h3>

        <div className="payoutmethods__methods">
        <a href="method/add">
          <div className="payoutmethods__method">
            <div>Online payout systems</div>

            <div className="payoutmethods__method-icons">
              <span><img src={"/assets/payoutmethods/paypal.png"} alt="" /></span>
              <span><img src={"/assets/payoutmethods/venmo.png"} alt="" /></span>
              <span><img src={"/assets/payoutmethods/cash.png"} alt="" /></span>
              <span><img src={"/assets/payoutmethods/zelle.png"} alt="" /></span>
            </div>
          </div>
          </a>

          <a href="method/gift-card">
          <div className="payoutmethods__method">
            <div>Gift cards</div>

            <div>
              <span><img src={"/assets/payoutmethods/amazon.png"} alt="" /></span>
              <span><img src={"/assets/payoutmethods/visa.png"} alt="" /></span>
            </div>
          </div>
          </a>

          {/* <div className="payoutmethods__method">
            <div>Bank account</div>

            <div>
              <span><img src={"/assets/payoutmethods/plaid.png"} alt="" /></span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

  export default PayoutMethod;