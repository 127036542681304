import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {AdminDemosBookedThroughEvent, AdminDemosBookedThroughSellers, AdminDashboardBreakdown, AdminDemosBookedBy30, AdminDemosBookedBy60, AdminDemosBookedBy90} from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';

function Home() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [demos, setDemos] = useState([]);
  const [showDemos, setShowDemos] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await AdminDashboardBreakdown();

      if (res.success){
        setData(res.data[0]);
        setLoaded(true);
      }

      else{
        alert('failed to load and to refresh');
      }
    }

    loadData();
  }, []);

  async function loadEventDemos(){
    let res = await AdminDemosBookedThroughEvent();

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }

    else{
      alert('failed to load and retry');
    }
  }

  async function loadSellerDemos(){
    let res = await AdminDemosBookedThroughSellers();

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }
  }

  async function loadPast30Demos(){
    let res = await AdminDemosBookedBy30();

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }
  }

  async function loadPast60Demos(){
    let res = await AdminDemosBookedBy60();

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }
  }

  async function loadPast90Demos(){
    let res = await AdminDemosBookedBy90();

    if (res.success){
      setDemos(res.data);
      setShowDemos(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Dashboard</title>
      </Helmet>

      <h1>Dashboard</h1>

      {loaded=== false && (
        <Spinner />
      )}
      {loaded === true && (
        <>
      <div>
        <table className='admindashboard__table table'>
          <tr>
            <th></th>
            <th></th>
          </tr>
          
          <tr className='link' onClick={() => loadEventDemos()}>
            <td>Demos submitted through event users</td>
            <td>{data.booked_by_event} {`(${data.booked_by_event_approved} approved)`}</td>
          </tr>

          <tr className='link' onClick={() => loadPast30Demos()}>
            <td>Demos submitted by new buyers - 30 days</td>
            <td>{data.booked_by_new_30} {`(${data.booked_by_new_30_approved} approved)`}</td>
          </tr>

          <tr className='link' onClick={() => loadPast60Demos()}>
            <td>Demos submitted by new buyers - 60 days</td>
            <td>{data.booked_by_new_60} {`(${data.booked_by_new_60_approved} approved)`}</td>
          </tr>

          <tr className='link' onClick={() => loadPast90Demos()}>
            <td>Demos submitted by new buyers - 90 days</td>
            <td>{data.booked_by_new_90} {`(${data.booked_by_new_90_approved} approved)`}</td>
          </tr>

          <tr className='link' onClick={() => loadSellerDemos()}>
            <td>Demos submitted by sellers</td>
            <td>{data.booked_by_sellers} {`(${data.booked_by_sellers_approved} approved)`}</td>
          </tr>

          <tr>
            <td>Demos submitted by buyers {'(only)'}</td>
            <td>{data.booked_by_buyers_only} {`(${data.booked_by_buyers_only_approved} approved)`}</td>
          </tr>
        </table>
      </div>

      <div>
        <table className='admindashboard__table table'>
          <tr>
            <th></th>
            <th></th>
          </tr>
          
          <tr>
            <td>Pending demo applications</td>
            <td>{data.pending_demos}</td>
          </tr>

          <tr>
            <td>Upcoming demo applications</td>
            <td>{data.upcoming_demos}</td>
          </tr>

          <tr>
            <td>Declined demo applications</td>
            <td>{data.declined_demos}</td>
          </tr>

          <tr>
            <td>Completed demo applications</td>
            <td>{data.completed_demos}</td>
          </tr>
        </table>
      </div>
      </>
      )}

      {showDemos && (
        <>
          <div className='overlay'></div>

          <div className='modal admindashboard__modal--wide'>
            <div className='modal__close' onClick={() => setShowDemos(false)}><FaTimes /></div>

            <h2 className='modal__h2'>Demos Submitted {`(${demos.length})`}</h2>

            <div>
              <table className='admindashboard__table table'>
                <tr>
                  <th>Buyer | Seller</th>
                  <th>Scheduled For</th>
                  <th>Buyer {'<>'} Seller</th>
                  <th>Buyer Status</th>
                  <th>Seller Status</th>
                  <th>Demo Status</th>
                </tr>

                {demos.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                      <div><strong>#{index}</strong></div>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>

                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>
                    </div>
                  </td>

                  <td>
                    {item.demo_date && (
                      <span>
                    {new Date(item.demo_date).toDateString()}
                      </span>
                    )}

                    {item.demo_date == null && (
                      <span><i>n/a</i></span>
                    )}
                  </td>

                  <td>
                    <strong>{item.buyer_company_name} {' <> '} {item.seller_company_name}</strong>
                  </td>

                  <td>
                    {item.buyer_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.buyer_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.seller_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.seller_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.demo_status_id === 1 && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.demo_status_id === 4 && (
                      <span className='admindashboard__green-flag'>Completed</span>
                    )}

                    {item.demo_status_id === 2 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}

                    {item.demo_status_id === 5 && (
                      <span className='admindashboard__red-flag'>Expired</span>
                    )}

                    {item.demo_status_id === 3 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}
                  </td>
                </tr>
                ))}
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;