import {useState,useEffect} from 'react';
import './DemoConfirmation.css';
import { useLocation } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, getDemoQualifierQuestions, answerQualifierQuestion, buyerConfirmsDemo } from '../../utils/api';
import { FaRegCalendarCheck } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';

function DemoConfirmation({demo_id, isCompleted, setIsCompleted, setIsCancelled, setShowQualifier, setShowSelectedTime}) {
  const [referenceId, setReferenceId] = useState('');
  const [demo, setDemo] = useState({});
  const search = useLocation().search;
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  // let { token } = useParams();

  useEffect(() => {
    async function checkToken(){
      const token = new URLSearchParams(search).get('token');
      let _res = await buyerConfirmsDemo(token);

      if (_res.success){
        setLoaded(true);
        setVerified(true);
      }

      else{
        setLoaded(true);
        setVerified(false);
      }
    }

    checkToken();
  }, []);

return (
  <div className='center democonfirmation' style={{padding: "80px", fontWeight: "bold"}}>
    {loaded === false && (
      <div className='center'>
        <Spinner />
      </div>
    )}

    {loaded && verified && (
      <>
          <div style={{fontSize: '25px'}}><FaRegCalendarCheck /></div>
          <h1>Your demo has been confirmed!</h1>

          <p>
            Please don't forget to be on time during the scheduled date/time.
          </p>

          <p>
            You can find all relevant meeting details in your <Link className='link' to={'/dashboard/buyer'}>Dashboard</Link>.
          </p>
      </>
    )}

    {loaded && !verified && (
      <>
        <h1>Not a valid demo!</h1>
      </>
    )}
  </div>
)
}

export default DemoConfirmation;