import React from 'react';
import {
  Flex,
  Icon,
  chakra,
  Box,
  SimpleGrid,
  Button,
  VStack,
  Text,
  Stack,
  Image,
  Link,
} from '@chakra-ui/react';
import ScrollToTop from '../../../components/layout/ScrollToTop';


const PricingSellerHeader = () => {
  const Feature = (props) => {
    return (
      <Flex>
        <ScrollToTop />
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color='brand.500'
          _dark={{
            color: 'brand.300',
          }}
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path
            fillRule='evenodd'
            d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
            clipRule='evenodd'></path>
        </Icon>
        <chakra.p
          fontSize='lg'
          color='gray.700'
          _dark={{
            color: 'gray.400',
          }}
          {...props}
        />
      </Flex>
    );
  };

  return (
    <>
      <Box px={8} py={24} mx='auto'>
        <Box
          w={{
            base: 'full',
            md: 11 / 12,
            xl: 9 / 12,
          }}
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}>
          <chakra.h1
            mb={6}
            fontSize={{
              base: '4xl',
              md: '6xl',
            }}
            fontWeight='bold'
            lineHeight='none'
            letterSpacing={{
              base: 'normal',
              md: 'tight',
            }}
            color='purple.500'>
            Zero obligation. No commitments. <br />
            <Text
              display={{
                base: 'block',
                lg: 'inline',
              }}
              w='full'
              bgClip='text'
              // bgGradient='linear(to-r, white,purple.500)'
              color='white'
              fontWeight='extrabold'
              fontSize={'8xl'}>
              100% risk free.
            </Text>{' '}
          </chakra.h1>
          <chakra.p
            px={{
              base: 0,
              lg: 24,
            }}
            mb={6}
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color='gray.300'>
            The average cost of a sales demo through an SDR is $897. <br /> The
            average cost of a meeting through LeadrPro is $500. <br /> Signing
            up is free and easy. No contracts, no commitments. <br /> Come see
            for yourself and let us change the way you do sales forever.{' '}
            {/* <chakra.span fontStyle='italic'>forever</chakra.span> */}
          </chakra.p>
          <Stack
            direction={{
              base: 'column',
              sm: 'row',
            }}
            mb={{
              base: 4,
              md: 8,
            }}
            spacing={2}
            justifyContent={{
              sm: 'left',
              md: 'center',
            }}>
            <Button
              as='a'
              variant='solid'
              colorScheme='brand'
              display='inline-flex'
              alignItems='center'
              justifyContent='center'
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              size='lg'>
              Get Started
              <Icon boxSize={4} ml={1} viewBox='0 0 20 20' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </Icon>
            </Button>
            <Button
              as='a'
              href='https://www.leadrpro.com/signup/seller'
              colorScheme='gray'
              display='inline-flex'
              alignItems='center'
              justifyContent='center'
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              bg='purple.500'
              color='white'
              _hover={{
                color: 'white',
                bg: 'purple.600',
                transform: 'scale(1.05)',
              }}
              size='lg'
              cursor='pointer'>
              Create your free account!
              <Icon boxSize={4} ml={1} viewBox='0 0 20 20' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                  clipRule='evenodd'
                />
              </Icon>
            </Button>
          </Stack>
        </Box>
      </Box>

      <Flex
        bg='#edf3f8'
        _dark={{
          bg: '#3e3e3e',
        }}
        p={20}
        w='auto'
        justifyContent='center'
        alignItems='center'>
        <Box
          shadow='xl'
          bg='white'
          _dark={{
            bg: 'gray.800',
          }}
          px={8}
          py={20}
          mx='auto'>
          <Box w='250px' _hover={{ transform: 'scale(1.1)' }} ml={4}>
            <div>
              <a
                href='https://techcrunch.com/2022/12/07/product-demo-leadrpro/'
                target='_blank'>
                <span>Check out our article in </span>
                <img
                  src='https://ik.imagekit.io/ivgrhmd4k/techcrunch.png?ik-sdk-version=javascript-1.4.3&updatedAt=1670657405540'
                  alt=''
                />
              </a>
            </div>
          </Box>
          <SimpleGrid
            alignItems='center'
            columns={{
              base: 1,
              lg: 2,
            }}
            spacingY={{
              base: 10,
              lg: 32,
            }}
            spacingX={{
              base: 10,
              lg: 24,
            }}>
            <Box>
              <chakra.h2
                mb={3}
                fontSize={{
                  base: '3xl',
                  md: '4xl',
                }}
                fontWeight='extrabold'
                textAlign={{
                  base: 'center',
                  sm: 'left',
                }}
                _light={{
                  color: 'black',
                }}
                lineHeight='shorter'
                letterSpacing='tight'>
                Our risk free guarantee...
              </chakra.h2>
              <chakra.p
                mb={6}
                fontSize={{
                  base: 'lg',
                  md: 'xl',
                }}
                textAlign={{
                  base: 'center',
                  sm: 'left',
                }}
                color='gray.600'>
                Zero obligation. No Commitments. 100% risk free.
                <br />
                Only pay for demos you've completed.
                <br /> So, what are you waiting for? Join the LeadrPro ecosystem
                and start booking demos with high-intent leads today!
              </chakra.p>

              <Button
                href='https://www.leadrpro.com/signup/seller'
                as='a'
                variant='solid'
                w={{
                  base: 'full',
                  sm: 'auto',
                }}
                bg='purple.500'
                color='white'
                _hover={{
                  color: 'white',
                  bg: 'purple.600',
                  transform: 'scale(1.05)',
                }}
                size='lg'>
                Let's get started!
              </Button>
            </Box>
            <VStack
              direction='column'
              flexGrow={1}
              spacing={5}
              alignItems='start'>
              <chakra.header fontWeight={800} fontSize={'2xl'}>
                Our free features
              </chakra.header>
              <Feature>Only pay for demos you've completed</Feature>
              <Feature>
                Your company listed to thousands of high value prospects
              </Feature>
              <Feature>
                High visibility among hundreds of qualified ICP's
              </Feature>
              <Feature>Custom, unique profile page</Feature>

              <Feature>Ability to browse prospects before booking</Feature>
              <Feature>Ability to chat instantly with prospects</Feature>
              <Feature>
                Vet prospects in depth through custom qualifier questions
              </Feature>

              <Feature>Always 100% free to sign up!</Feature>
            </VStack>
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
};

export default PricingSellerHeader;
