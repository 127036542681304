import './PriceLead.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getLeadCardApproved, getLeadCardNotes, saveLeadCardNote, checkHasPaymentMethod } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaTimes, FaRegCheckCircle, FaRegStickyNote, FaChartLine, FaCaretLeft } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';
 import ScrollToTop from '../../components/layout/ScrollToTop';
 import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};

const PriceLead = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [data, setData] = useState(null);
  const [referenceId, setReferenceId] = useState('');
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState('Pending');
  const [showPurchase, setShowPurchase] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [notes, setNotes] = useState([]);
  const [notesLoaded,setNotesLoaded] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  let {id} = useParams();
  const navigate = useNavigate();

  async function loadNotes(){
    let res = await getLeadCardNotes(id);

    if (res.success){
      setNotes(res.data);
      setNotesLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getLeadCardApproved(id);

      if (res.success == true){
        setData(res.data);
        setLoading(false);
        setSuccess(true);
      }

      else{
        setLoading(false);
        setSuccess(false);
        setData(null);
      }
    }

    async function loadNotes(){
      let res = await getLeadCardNotes(id);

      if (res.success){
        setNotes(res.data);
        setNotesLoaded(true);
      }
    }



      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        //setLoading(false);
        //setSuccess(true);
      }

      else if (response.status === 403){
        await refresh();
        let response = await getUserProfile();

        if (response.success){
          setUser(response.data);
        ///  setLoading(false);
        //  setSuccess(true);
        }
      }

      else{
        //setLoading(false);
      }
    }

    loadUserProfile();
    loadData();
    loadNotes();
  }, []);

  async function handleConfirmPurchase(){
    setShowPurchase(false);
    setShowConfirm(true);
  }

  async function handleSaveNote(){
    //alert('save');
    let res = await saveLeadCardNote(id, noteText);

    if (res.success){
      loadNotes();
      setNoteText('');
    }
  }

  return (
    <div className="pricelead">
      <ScrollToTop />
      {loading && (
        <Spinner />
      )}

      {loading == false && success == false && (
        <div>
          <i>Invalid lead card ID.</i>
        </div>
      )}

      {loading === false && success == true && (
        <>
          {currentTab == 'Pending' && (
            <div>
              <div>
                <Link to='/dashboard/my-price-leads' style={{textDecoration: 'underline'}}>My Price Leads</Link>
              </div>

              <div className='priceleads__cards'>
                <div className='pricelead__card'>
                  <div>
                    <div>
                      <strong>Reference #: {data.reference_id}</strong>
                    </div>

                    <div className='priceleads__card-action'>
                    <span><IconDisplay iconName={data.type_icon_name} /></span>
                    <span className='ml-10'>{data.type_description}</span>
                  </div>

                    <div className='form-group pricelead__name'>
                      {data.first_name}{' '}{data.last_name}
                    </div>

                    <div className='form-group'>
                      <strong>Company name</strong>

                      <div>

                        {data.company_name}
                      </div>
                    </div>

                    <div className='form-group'>
                      <strong>Job title</strong>
                      <div>
                        {data.job_title}
                      </div>
                    </div>

                    <div className='form-group'>
                    <strong>Contact details</strong>

                    <p>
                      Email: <i>{data.contact_email}</i>
                    </p>

                    <p>
                      Phone: <i>{data.contact_phone}</i>
                    </p>
  
                  </div>

                    <div className='form-group'>
                      <strong>Company size</strong>
                      <div>{data.company_size}</div>
                    </div>

                    <div className='form-group'>
                      <strong>Number of users</strong>
                      <div>{data.number_of_users}</div>
                    </div>

                    <div className='form-group'>
                      <strong>Annual revenue</strong>
                      <div>{data.annual_revenue}</div>
                    </div>
                  </div>

                  <div>
                    <div className='form-group'>
                      <strong>Looking for</strong>

                      <div>
                        {data.request_body}
                      </div>
                    </div>

                    <div className='form-group'>
                    <strong>Looking for key features</strong>
                    <div>
                      {data.key_features}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Price expectations</strong>
                    <div>
                      {data.price_expectations}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Timeframe</strong>

                    <div>
                      {data.timeframe}
                    </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>
          )}

          {/* <div className='pricelead__timeline'>
            <h2><FaChartLine /> Activity timeline</h2>

            <div className='pricelead__timeline-items'>
              <div className='pricelead__timeline-item'>
                <strong>10.22.2024</strong>
                <span className='pricelead__timeline-description'>User requested pricing information</span>
              </div>

              <div className='pricelead__timeline-item'>
                <strong>10.22.2024</strong>
                <span className='pricelead__timeline-description'>Follow up by our software advisor</span>
              </div>

              <div className='pricelead__timeline-item'>
                <strong>10.22.2024</strong>
                <span className='pricelead__timeline-description'>Demo date scheduled</span>
              </div>
            </div>
          </div> */}

          <div className='pricelead__admin-notes'>
            <h2><FaRegStickyNote /> Admin Notes</h2>

            {notes.length==0 && notesLoaded == true && (
              <>
              <i>No notes added.</i>
              </>
            )}
            <div>
              {notes.map(item => (
                <div className='pricelead__admin-note'>
                  <div className='pricelead__admin-note-date'>{new Date(item.date_created).toLocaleString()}</div>
                  <div className='pricelead__admin-note-text'>{item.note_text}</div>

                  <div className='pricelead__admin-note-name'>- {item.first_name}</div>
                </div>
              ))}

            </div>

            <div className='mt-20'>
              <textarea className='input textarea' placeholder='Add note' value={noteText} onChange={(e) => setNoteText(e.target.value)}></textarea>
              <span><button className='btn btn--primary' onClick={() => handleSaveNote()}>Save note</button></span>
            </div>
          </div>

        </>
      )}

{showPurchase && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide'>
            <div className='modal__close' onClick={() => setShowPurchase(false)}>
              <FaTimes />
            </div>
            <h2 className='modal__h2'>Confirm purchase</h2>

            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            <div style={{width: '50%', padding: '20px'}}>
                <strong>Additional features:</strong>
                <div className='priceleads__purchase-features'>
                  <div><FaRegCheckCircle /> Get recommended by us to the prospect</div>
                  <div><FaRegCheckCircle /> Get contact information instantly</div>
                  <div><FaRegCheckCircle /> Manual outreach to close the demo included</div>

                </div>

                <div className='mt-10'>
                  <strong>Purchasing</strong>
                  <div>
                  <i>Lead #: LFJJL2423k</i>
                  </div>
                </div>

                <div className='priceleads__purchase-total mt-20'>
                  <span><strong>Cost</strong></span>: $120
                </div>
              </div>

              <div style={{width: '50%', fontSize: '16px', padding: '20px'}}>

                <div>
                  <strong>Description</strong>

                  <div style={{fontSize: '14px'}}>
                  Unlock access to high-quality, verified leads that are ready to boost your business. Our leads are tailored to fit your specific needs and help you connect with potential customers who are genuinely interested in your products or services. Here's what you’ll get with each purchase:


                  </div>
                </div>

                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => handleConfirmPurchase()}>Confirm purchase</button>
                </div>
              </div>
            </div>

            <div style={{marginTop: '20px', fontSize: '12px', color: '#aaa'}}>
            By clicking 'Confirm Purchase' below you authorize LeadrPro to charge the card on file for this purchase.

            </div>
          </div>
        </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='center'>
            <svg style={{height: '40px', fill: '#412b6e'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </div>

            <div className='center mt-20' style={{fontSize: '20px'}}>
              <strong>Success! Your lead card is ready.</strong>
            </div>

            <div className='mt-10'><strong>What's next?</strong></div>
            
            <ul className='mt-10'>
              <li>Get started on your outreach—these leads are fresh and eager to hear from you!</li>
            </ul>

            <div className='mt-20 center' style={{fontSize: '20px', fontWeight: 'bold'}}>
              Confirmation #: LDKFJFLSJ234
            </div>

            <div className='mt-20'>
              <button className='btn btn--confirm' onClick={() => setShowConfirm(false)}>View full lead details</button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

  export default PriceLead;