import { useState, useEffect } from "react";
import './EventManage.css';
import { getEvent, eventRegistration, getHostedEvent, createEventHostInvite, cancelEventHostInvite, updateEventHost, deleteEventHost, approveEventGuest, updateEventUrl, addEventQuestion, removeEventQuestion, eventQuestionMoveUp, eventQuestionMoveDown, updateEventQuestion, getEventAnswers, createEventPost, getEventPostsForHost, removeEventPost, updateEventPost, deleteEvent, updateMediaUrl, getEventSpeakersForHost, addEventSpeaker, updateEventSpeaker, deleteEventSpeaker, getSponsorList, updateEventSponsor
 } from '../../utils/api';
 import { getEventById } from "../../utils/api/admin";
import {
  refresh
} from '../../services/auth.service';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import FadeIn from "../../utils/fadein";
import ScrollToTop from "../../components/layout/ScrollToTop";
import Spinner from "../../components/layout/Spinner";
import moment from 'moment-timezone';
import { FaCalendar, FaArrowUp, FaArrowDown, FaCalendarAlt, FaTimes, FaEllipsisV, FaLocationArrow, FaMapMarkerAlt, FaLink, FaDownload, FaEdit } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { getSeoName } from "../../utils/utils";
import { Editor } from '@tinymce/tinymce-react';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';

function EventManage({id}) {
    const [event, setEvent] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isFree, setIsFree] = useState(true);
    const [requiresApproval, setRequiresApproval] = useState(false);
    const [showAddHost, setShowAddHost] = useState(false);
    const [hostEmail, setHostEmail] = useState('');
    const [inviting, setInviting] = useState(false);
    const [showInviteConfirm, setShowInviteConfirm] = useState(false);
    const [showHostEdit, setShowHostEdit] = useState(false);
    const [hostRole, setHostRole] = useState('Admin');
    const [editHostRole, setEditHostRole] = useState('');
    const [selectedHostId, setSelectedHostId] = useState(null);
    const [selectedHost, setSelectedHost] = useState(null);
    const [urlSlug, setUrlSlug] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showQuestionEdit, setShowQuestionEdit] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [eventPost, setEventPost] = useState('');
  const [eventPostNew, setEventPostNew] = useState('');
  const [posts, setPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [sendPostTo, setSendPostTo] = useState('All');
  const [sendEmail, setSendEmail] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [embedMedia, setEmbedMedia] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [showAddSpeaker, setShowAddSpeaker] = useState(false);
  const [showEditSpeaker, setShowEditSpeaker] = useState(false);
  const [speakerName, setSpeakerName] = useState('');
  const [speakerBio, setSpeakerBio] = useState('');
  const [speakerProfilePicture, setSpeakerProfilePicture] = useState('');
  const [selectedSpeakerId, setSelectedSpeakerId] = useState(null);
  const [selectedSpeaker, setSelectedSpeaker]= useState(null);
  const [fileName, setFileName] = useState('');
  const [sponsorList, setSponsorList] = useState([]);
  const [sponsorId, setSponsorId] = useState(null);
  const navigate = useNavigate();
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

    async function loadEvent(){
      let res = await getEventById(id);

      if (res.success){
        setEvent(res.data);
        setIsFree(res.data.is_free);
        setRequiresApproval(res.data.requires_approval);
        setUrlSlug(res.data.url_slug);
        setQuestions(res.data.questions);
        setMediaUrl(res.data.media_url);
        setEmbedMedia(res.data.embed_media);
        setSponsorId(res.data.sponsor_id);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getEventById(id);

        if (res.success){
          setEvent(res.data);
          setIsFree(res.data.is_free);
          setRequiresApproval(res.data.requires_approval);
          setUrlSlug(res.data.url_slug);
          setQuestions(res.data.questions);
          setMediaUrl(res.data.media_url);
          setEmbedMedia(res.data.embed_media);
          setSponsorId(res.data.sponsor_id);
          setLoaded(true);
        }
      }
    }

    async function loadSponsorList(){
      let res = await getSponsorList();

      if (res.success){
        setSponsorList(res.data);
      }
    }

    async function loadSpeakers(){
      let res = await getEventSpeakersForHost(id);

      if (res.success){
        //setSpeakers(res.data);
      }
    }

    async function loadPosts(){
      let res = await getEventPostsForHost(id);

      if (res.success){
        setPosts(res.data);
      }

      else if (res.status == 403){
        await refresh();

        let res = await getEventPostsForHost(id);

        if (res.success){
          setPosts(res.data);
        }
      }
    }

    useEffect(() => {
      loadSponsorList();
      loadEvent();
      loadPosts();
      loadSpeakers();
    }, []);

    async function loadSpeakers(){
      let res = await getEventSpeakersForHost(id);

      if (res.success){
       // setSpeakers(res.data);
      }
    }

    async function handleInviteHost(){
      if (hostEmail.length > 0){
        setInviting(true);

        let res = await createEventHostInvite(id, hostEmail, hostRole);

        if (res.success){
          setInviting(false);
          setShowAddHost(false);
          setShowInviteConfirm(true);
          setHostEmail('');
          setHostRole('Admin');
          loadEvent();
        }

        else if (res.status === 403){
          await refresh();

          let res = await createEventHostInvite(id, hostEmail, hostRole);

          if (res.success){
            setInviting(false);
            setShowAddHost(false);
            setShowInviteConfirm(true);
            setHostEmail('');
            setHostRole('Admin');
            loadEvent();
          }
        }
      }
    }

    async function handleCancelInvite(id){
      let res = await cancelEventHostInvite(id);

      if (res.success){
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await cancelEventHostInvite(id);

        if (res.success){
          loadEvent();
        }
      }
    }

    async function handleUpdateHost(){
      let res = await updateEventHost(selectedHostId, editHostRole);

      if (res.success){
        setShowHostEdit(false);
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await updateEventHost(selectedHostId, editHostRole);

        if (res.success){
          setShowHostEdit(false);
          loadEvent();
        }
      }
    }

    function loadSaveBanner(){
      setShowSaveBanner(true);
      const timer = setTimeout(() => {
        setShowSaveBanner(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

    async function handleDeleteHost(){
      let res = await deleteEventHost(selectedHostId);

      if (res.success){
        setShowHostEdit(false);
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await deleteEventHost(selectedHostId);

        if (res.success){
          setShowHostEdit(false);
          loadEvent();
        }
      }
    }

    async function approveGuest(user_id){
      let res = await approveEventGuest(id, user_id);

      if (res.success){
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await approveEventGuest(id, user_id);

        if (res.success){
          loadEvent();
        }
      }
    }

    async function handleUpdateUrl(){
      let res = await updateEventUrl(id, urlSlug);

      if (res.success){
        if (res.exists === false){
          loadSaveBanner();
        }

        else{
          alert("URL already exists");
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await updateEventUrl(id, urlSlug);

        if (res.success){
          if (res.exists === false){
            loadSaveBanner();
          }
  
          else{
            alert("URL already exists");
          }
        }
      }
    }

    async function saveQuestion(){
      if (newQuestion.length === 0){
        alert("Must enter a valid question");
        return;
      }

      let result = await addEventQuestion(id, newQuestion);
  
      if (result.success){
        let id = result.data;
        let obj = {event_question_id: id, question: newQuestion};
        let new_array = questions;
        new_array.push(obj);
        setQuestions(new_array);
        setNewQuestion('');
        loadEvent();
        loadSaveBanner();
      }
  
      else if (result.status === 403){
        await refresh();
  
        let result = await addEventQuestion(id, newQuestion);
  
        if (result.success){
          let id = result.data;
          let obj = {event_question_id: id, question: newQuestion};
          let new_array = questions;
          new_array.push(obj);
          setQuestions(new_array);
          setNewQuestion('');
          loadEvent();
          loadSaveBanner();
        }
      }
      setNewQuestion('');
    }

    function editQuestion(event_question_id){
      let q = questions.find(item => item.event_question_id === event_question_id);
      setCurrentQuestion(q);
      setShowQuestionEdit(true);
    }
  
    async function removeQuestion(event_question_id){
      setSelectedId(event_question_id);
      let res = await removeEventQuestion(event_question_id);

      if (res.success){
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await removeEventQuestion(event_question_id);

      if (res.success){
        loadEvent();
      }
      }
      setShowConfirmRemove(true);
    }

    async function moveUp(event_question_id, index){
      let prev = questions[index-1];
      let res = await eventQuestionMoveUp(event_question_id, prev.event_question_id, index);
  
      if (res.success){
       loadSaveBanner();
       loadEvent();
      }
  
      else if (res.status === 403){
        await refresh();
  
        let res = await eventQuestionMoveUp(event_question_id, prev.event_question_id, index);
  
        if (res.success){
          loadSaveBanner();
          loadEvent();
        }
      }
    }
  
    async function moveDown(event_question_id, index){
      let next = questions[index+1];
      let res = await eventQuestionMoveDown(event_question_id, next.event_question_id, index);
  
      if (res.success){
      // await getCompany();
      loadEvent();
       loadSaveBanner();
      }
  
      else if (res.status === 403){
        await refresh();
        let res = await eventQuestionMoveDown(event_question_id, next.event_question_id, index);
  
        if (res.success){
       // await getCompany();
       loadEvent();
        loadSaveBanner();
        }
      }
    }

    async function updateQuestion(){
      let res = await updateEventQuestion(currentQuestion.event_question_id, currentQuestion.question);
  
      if (res.success){
        setShowQuestionEdit(false);
  
        // let obj = {event_question_id: currentQuestion.event_question_id, question: currentQuestion.question};
        // let _tmp = questions.filter(item => item.event_question_id !== currentQuestion.event_question_id);
        // _tmp.push(obj);
        // setQuestions(_tmp);
        loadEvent();
        loadSaveBanner();
      }
  
      else if (res.status === 403){
        await refresh();
  
        let res = await updateEventQuestion(currentQuestion.event_question_id, currentQuestion.question);
  
        if (res.success){
          setShowQuestionEdit(false);
  
          // let obj = {event_question_id: currentQuestion.event_question_id, question: currentQuestion.question};
          // let _tmp = questions.filter(item => item.event_question_id !== currentQuestion.event_question_id);
          // _tmp.push(obj);
          // setQuestions(_tmp);
          loadEvent();
          loadSaveBanner();
        }
      }
    }

    function handleQuestionChange(value){
      setCurrentQuestion({...currentQuestion, question: value})
    }

    async function handleShowAnswers(registered_user_id){
      let _answers = await getEventAnswers(id, registered_user_id);

      if (_answers.success){
        setAnswers(_answers.data);
        setShowAnswers(true);
      }

      else if (_answers.status === 403){
        await refresh();
        let _answers = await getEventAnswers(id, registered_user_id);

        if (_answers.success){
          setAnswers(_answers.data);
          setShowAnswers(true);
        }
      }
    }

    async function handleEditorSave(){
      if (eventPost.length == 0){
        return;
      }

      let res = await createEventPost(id, '', eventPost, false, sendPostTo, sendEmail);

      if (res.success){
        setShowEditor(false);
        loadPosts();
      }

      else if (res.status === 403){
        await refresh();

        let res = await createEventPost(id, '', eventPost, false, sendPostTo, sendEmail);

        if (res.success){
          setShowEditor(false);
          loadPosts();
        }
      }
    }

    async function handleRemovePost(event_post_id){
      let res = await removeEventPost(event_post_id);

      if (res.success){
        loadPosts();
      }

      else if (res.status === 403){
        await refresh();
        let res = await removeEventPost(event_post_id);

        if (res.success){
          loadPosts();
        }
      }
    }

    async function confirmPostUpdate(){
      let res = await updateEventPost(selectedPostId, eventPost);

      if (res.success){
        setShowEditor(false);
        setSelectedPostId(null);
        loadPosts();
      }

      else if (res.status === 403){
        await refresh();
        let res = await updateEventPost(selectedPostId, eventPost);

        if (res.success){
          setShowEditor(false);
          setSelectedPostId(null);
          loadPosts();
        }
      }
    }

    async function handleEditPost(event_post_id, post_body){
      setSelectedPostId(event_post_id);
      setEventPostNew(post_body);
     // setEventPost(post_body);
      setShowEditor(true);
      // let res = await removeEventPost(event_post_id);

      // if (res.success){
      //   loadPosts();
      // }

      // else if (res.status === 403){
      //   await refresh();
      //   let res = await removeEventPost(event_post_id);

      //   if (res.success){
      //     loadPosts();
      //   }
      // }
    }

    async function handleSendToChange(index){
      if (index == 0)
        setSendPostTo('All');

      else if (index == 1)
        setSendPostTo('Guests');

      else if (index == 2)
        setSendPostTo('Pending');
    }

    async function handleDeleteEvent(){
      setShowConfirmDelete(true);
    }

    async function handleDelete(){
      let res = await deleteEvent(id);

      if (res.success){
        navigate('/account/events')
      }

      else if (res.status === 403){
        await refresh();

        let res = await deleteEvent(id);

        if (res.success){
          navigate('/account/events')
        }
      }
    }

    // first_name, last_name, job_title, linkedin_url, company_name, company_size, demo_date, moving_to_proposal, feedback1, feedback2, feedback3
    function handleExport(){
      let filter = event.guest_list;
  
      let allrows = [];
        let row0 = [];
        row0.push('First name');
        row0.push('Last name');
        row0.push('Email');
        row0.push('Job title');
        row0.push('Department');
        row0.push('Company size');
        row0.push('Company name');

        if (event.questions.length > 0){
          for (let i = 0; i < event.questions.length; i++){
            row0.push(`${event.questions[i].question.replace(/[,']/g, "")}`);
          }
    
          allrows.push(row0);
        }
  
      let rows = filter.map((item, index) => {
        let row = [];
  
        row.push((item.first_name?.replace(/[,']/g, "")) ?? "");
        row.push((item.last_name?.replace(/[,']/g, "")) ?? "");
        row.push((item.email?.replace(/[,']/g, "")) ?? "");
        row.push((item.job_title?.replace(/[,']/g, "")) ?? "");
        row.push((item.department?.replace(/[,']/g, "")) ?? "");
        row.push((item.company_size?.replace(/[,']/g, "")) ?? "");
        row.push((item.company_name?.replace(/[,']/g, "")) ?? "");

        for (let i = 0; i < item.answers.length; i++){
          row.push(item.answers[i].answer.replace(/[,']/g, ""));
        }
  
        return row;
      });
  
      let result = [...allrows, ...rows];
      
      let csvContent = "data:text/csv;charset=utf-8,";
      
      result.forEach(function(rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
      });
  
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    }

    async function handleUpdateMediaUrl(){
      let res = await updateMediaUrl(id, mediaUrl, embedMedia);

      if (res.success){
        loadSaveBanner();
      }

      else if (res.status === 403){
        await refresh();

        let res = await updateMediaUrl(id, mediaUrl, embedMedia);

        if (res.success){
          loadSaveBanner();
        }
      }
    }

    async function handleAddSpeaker(){
      let res = await addEventSpeaker(id, speakerName, speakerBio, speakerProfilePicture);

      if (res.success){
        await loadSpeakers();
        setShowAddSpeaker(false);
      }
    }

    async function handleUpdateSpeaker(){
      let res = await updateEventSpeaker(selectedSpeakerId, speakerName, speakerBio, speakerProfilePicture);

      if (res.success){
        setShowEditSpeaker(false);
        await loadSpeakers();
      }
    }

    async function handleRemoveSpeaker(){
      let res = await deleteEventSpeaker(selectedSpeakerId);

      if (res.success){
        setSubmitting(false);
        setShowEditSpeaker(false);
        await loadSpeakers();
      }
    }

    function onError(){
      console.log('error');
    }
  
    function onSuccess(e){
      setFileName(`https://ik.imagekit.io/ivgrhmd4k/eventspeaker/${e.name}`);
      setSpeakerProfilePicture(e.name);
    }

    async function handleSponsorChange(val){
      //alert(val);
      let res = await updateEventSponsor(id, val);

      if (res.success){
        loadSaveBanner();
      }
    }

    return (
        <div className="manageevent">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />

          {event && (
            <title>{`Manage event`}</title>
          )}
        </Helmet>

        <div className="manageevent__form">
          <div>
            <Link className="btn" to='/account/events'>Back</Link>
          </div>

          {loaded && (
            <h1>Manage Event <Link to={`/event/${id}/${getSeoName(event.name)}`} className="manageevent__public-link" target="_blank">View Public Page</Link></h1>
          )}

          <div className="manageevent__menu">
            <div className="btn">Overview</div>
            <div className="btn"><a href="#guests">Guests</a></div>
            <div className="btn"><a href="#hosts">Hosts</a></div>
            <div className="btn"><a href="#speakers">Speakers</a></div>
            <div className="btn"><a href='#questions'>Questions</a></div>
            <div className="btn"><a href='#posts'>Posts</a></div>
          </div>

          <div className="manageevent__details">
            {loaded === false && (
              <Spinner />
            )}
            {loaded && event && (
              <>

            <div style={{display: 'flex'}}>
              <div className="manageevent__left">
            <div className="createevent__form-name createevent__form-group">
              <div className="manageevent__name">{event.name}</div>
            </div>

            {/* <div className="createevent__form-description createevent__form-group">
              <label>Description</label>
              <div>
                {event.description}
              </div>
            </div> */}

            <div className="createevent__form-dates createevent__form-group">
              <div>
                <label>Start Date:</label> {new Date(event.start_date).toLocaleString()}
              </div>
            </div>

            <div className="createevent__form-group">
            <div>
                <label>End Date:</label> {new Date(event.end_date).toLocaleString()}
              </div>
            </div>

            {event.website && event.website.length > 0 && (
            <div className="createevent__form-group">
              <label>Event website</label>

              <div>
                <a href={event.website} target="_blank">{event.website}</a>
              </div>
            </div>
            )}

            {event.venue_instructions && event.venue_instructions.length > 0 && (
            <div className="createevent__form-group">
              <label>Venue instructions</label>

              <div>
                {event.venue_instructions}
              </div>
            </div>
            )}

            <div className="createevent__form-group">
              <label>Is Webinar: {event.is_webinar == true ? 'Yes': 'No'}</label> 
            </div>

            {event.is_webinar && (
              <div className="createevent__form-group">
                <label>Webinar URL: </label> {(event.webinar_url !== null && event.webinar_url.length > 0) ? event.webinar_url : <span className="createevent__red-flag">NOT SET</span>}
              </div>
            )}

            <div className="createevent__form-group">
              <label>Is free: {isFree == true ? 'Yes': 'No'}</label>
            </div>

            <div className="createevent__form-group">
              <label>Status: <span style={{position: 'relative', marginLeft: '30px'}} className={`eventdashboard__status eventdashboard__status--${event.status.toLowerCase()}`}>{event.status}</span></label>
            </div>

            <div className="createevent__form-group">
              <label>Capacity:</label> {event.capacity != null ? event.capacity : <span className="createevent__red-flag">NOT SET</span>}
            </div>

            <div className="createevent__form-group">
              <label>Sponsor:</label>
              <select className="select input" value={sponsorId} onChange={(e) => handleSponsorChange(e.target.value)}>
                <option>Select sponsor...</option>

                {sponsorList.map((item, index) => (
                  <option value={item.company_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            </div>

            <div className="manageevent__right">
              <div className="manageevent__thumbnail">
                {event.image_url && (
                  <img src={event.image_url} />
                )}

                {(event.image_url == null || event.image_url.length === 0) && (
                  <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                )}
              </div>

            {event.is_webinar === false && (
            <div style={{marginTop: '20px'}}>
              <h3><strong><FaMapMarkerAlt /> Location</strong></h3>

              {event.address1 && event.address1.length > 0 && (
                <div>
                  {event.address1} {event.address2}
                </div>
              )}

              {event.city && event.city.length > 0 && (
                <div>
                  {event.city} {event.state} {event.zipcode}
                </div>
              )}
            </div>
            )}

            {event.is_webinar && (
              <div>
                <FaLink /> URL: {event.webinar_url}
              </div>
            )}

            <div style={{marginTop: '10px'}}>
                <h3><strong>Event page</strong></h3>

                <div>
                  <div style={{margin:'5px 0px', fontSize: '12px'}}>https://www.leadrpro.com/e/{urlSlug}</div>
                  <span>
                    <input type="text" className="input" style={{padding: '5px'}} placeholder="custom-url" value={urlSlug} onChange={(e) => setUrlSlug(e.target.value)} />
                  </span>

                  <div style={{marginTop: '5px'}}>
                    <button className="btn btn--primary" onClick={() => handleUpdateUrl()}>Update</button>
                  </div>
                </div>
            </div>

            {event.is_webinar && (
            <div style={{marginTop: '10px'}}>
                <h3>
                  <strong>Media URL</strong>
                  <span style={{marginLeft: '20px', fontSize: '12px', fontWeight: 'normal'}}><input type='checkbox' checked={embedMedia} onChange={(e) => setEmbedMedia(e.target.checked)} /> Embed</span>
                </h3>

                <div style={{fontSize: '12px'}}>
                  <i>Post event download link.</i>
                </div>
                <div>
                  <span>
                    <input type="text" className="input" style={{padding: '5px'}} placeholder="Media url" value={mediaUrl} onChange={(e) => setMediaUrl(e.target.value)} />
                  </span>

                  <div style={{marginTop: '5px'}}>
                    <button className="btn btn--primary" onClick={() => handleUpdateMediaUrl()}>Update</button>
                  </div>
                </div>
            </div>
            )}
            </div>
            </div>

            <div>
              <Link className="btn btn--full" style={{ display: 'block', padding: '10px'}} to={`/edit-event/${id}`}><FaEdit /> Edit Event</Link>
            </div>
            </>
            )}
            </div>
{/* 
            <div className="manageevent__guests" id="guests">
              <h2>Guests {loaded == true? `(${event.guest_list.length})` : ''}</h2>

            <div className="createevent__form-group">
              {loaded && event && (
              <label>Requires approval: {requiresApproval == true ? 'Yes': 'No'}</label>
              )}
            </div>

              <p className="manageevent__description">
                Review attendee requests.
              </p>

              <div style={{margin: '10px 0px'}}>
                <button className="btn" onClick={() => handleExport()}><FaDownload /> Export guest list</button>
              </div>

              {loaded && event.guest_list.length == 0 && (
                <div className="center">
                  <i>No registrants found</i>
                </div>
              )}

              {loaded && event.guest_list.length > 0 && (
                <div className="manageevent__guest-list">
                  {event.guest_list.map(item => (
                    <>
                    <div className="manageevent__guest">
                    <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                      <div className="manageevent__guest-photo">
                        <img src={imageUrl + 'profileimages/tr:di-@@companylogos@@default-buyer.png/' + item.profile_image} alt="" />
                      </div>
                      <div className="manageevent__guest-name">
                        {item.first_name} {item.last_name} <span style={{marginLeft: '10px', fontSize: '12px'}}>{item.email}</span>
                      </div>
                    </div>
  
                    <div style={{display: 'flex'}}>
                      <div>
                      {event.enable_questions && (
                        <button className="btn" onClick={() => handleShowAnswers(item.registered_user_id)}>Answers</button>
                      )}

                      {item.is_approved === false && (
                        <button className="btn" onClick={() => approveGuest(item.registered_user_id)}>Approve</button>
                      )}
                        </div>

                      {item.is_approved && (
                        <div className="manageevent__guest-status">Approved</div>
                      )}
                    </div>
                  </div>
                  </>
                  ))}
                </div>
              )}
            </div> */}

            {/* <div className="manageevent__guests" id="hosts">
              <div>
                <h2>Hosts</h2>

                <p>
                  Hosts can invite other people to manage the event and approve registrants.
                </p>

                <div style={{textAlign: 'right'}}>
                  <button className="btn" style={{padding: '5px'}} onClick={() => setShowAddHost(true)}>Invite Host</button>
                </div>
              </div>

              <p className="manageevent__description">
                Add hosts and event managers.
              </p>

              {loaded && event.hosts.length == 0 && (
                <div>
                  <i>No hosts found</i>
                </div>
              )}

              {loaded && event.hosts.length > 0 && (
                <div className="manageevent__guest-list">

                {event.hosts.map(item => (
                <div className="manageevent__guest">
                    <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                      <div className="manageevent__guest-photo">
                        <img src={imageUrl + 'profileimages/tr:di-@@companylogos@@default-buyer.png/' + item.profile_image} alt="" />
                      </div>

                      {item.first_name && item.first_name.length > 0 && (
                      <div className="manageevent__guest-name">{item.first_name} {item.last_name}</div>
                      )}

                      {(item.first_name == null || item.first_name.length == 0) && (
                        <div className="manageevent__guest-name">
                          {item.email}
                        </div>
                      )}

                      <div className={`manageevent__role manageevent__role--${item.role.toLowerCase()}`}>
                        {item.role}
                      </div>
                    </div>
  
                    <div>
                      <div className="manageevent__host-menu" onClick={() => {
                        setShowHostEdit(true);
                        setSelectedHost(item);
                        setSelectedHostId(item.event_host_id);
                      }}>
                        <FaEllipsisV />
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              )} 
            </div> */}

            {/* <div className="manageevent__guests" id="speakers">
              <div>
                <h2>Speakers</h2>

                <p>
                  Bio of those speaking at the event.
                </p>

                <div style={{textAlign: 'right'}}>
                  <button className="btn" style={{padding: '5px'}} onClick={() => setShowAddSpeaker(true)}>Add speaker</button>
                </div>

                <div>
                  {speakers.length === 0 && (
                   <div className="center mt-10">
                     <i>
                      No speakers found...
                    </i>
                    </div>
                  )}

                  {speakers.length > 0 && (
                    <div>
                      {speakers.map(item => (
                        <div>
                          <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between'}}>
                          <div className="manageevent__guest-photo">
                            <img src={imageUrl + 'eventspeaker/tr:di-@@companylogos@@default-buyer.png/' + item.speaker_profile_picture} alt="" />
                          </div>

                          {item.speaker_name && item.speaker_name.length > 0 && (
                          <div className="manageevent__guest-name">
                            {item.speaker_name}
                          </div>
                          )}

                          {(item.speaker_name == null || item.speaker_name.length == 0) && (
                            <div className="manageevent__speaker-bio">
                              <i>No name set</i>
                            </div>
                          )}

                          {item.speaker_bio && item.speaker_bio.length > 0 && (
                          <div className="manageevent__speaker-bio">
                            {item.speaker_bio}
                          </div>
                          )}

                      <div>
                      <div className="manageevent__host-menu" onClick={() => {
                        setShowEditSpeaker(true);
                        setSelectedSpeaker(item);
                        setSpeakerName(item.speaker_name);
                        setSpeakerBio(item.speaker_bio);
                        setSpeakerProfilePicture(item.speaker_profile_picture);
                        setSelectedSpeakerId(item.event_speaker_id);
                      }}>
                        <FaEllipsisV />
                      </div>
                    </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            {/* {loaded && event.invites.length > 0 && (
            <div className="manageevent__guests">
              <div>
                <h2>{'Hosts (Invited)'}</h2>

                {loaded && event.invites.length > 0 && (
                  <>
                    {event.invites.map(item => (
                      <div className="manageevent__guest">
                        <div>{item.email} <span style={{fontSize: '12px', marginLeft: '10px', color: "#999"}}>{item.role}</span></div>

                        <div>
                          <span className='btn' onClick={()=> handleCancelInvite(item.event_host_invite_id)}>
                            Cancel invite
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            )} */}

          {/* <div className="manageevent__guests" id="posts">
            <div>
              <h2>Posts</h2>

              <p>
                Send a message to all event guests.
              </p>

              <div style={{marginTop: '10px'}}>
                <button className="btn" onClick={() => setShowEditor(true)}>Launch editor</button>
              </div>

              {posts && posts.length == 0 && (
                <div className="center">
                  <i>No posts found</i>
                </div>
              )}

              {posts && posts.length > 0 && (
              <div className="manageevent__posts">
                {posts.map(item => (
                  <div className="manageevent__post">
                    <div>
                    <div className="manageevent__guest-photo">
                        <img src={imageUrl + 'profileimages/tr:di-@@profileimages@@default-buyer.png/' + item.profile_image} alt="" />
                      </div>
                    </div>

                    <div style={{marginLeft: '10px'}}>
                      <div className="manageevent__post-date">{new Date(item.date_created).toLocaleString()}</div>

                      <div className="manageevent__post-body">
                      <p dangerouslySetInnerHTML={{ __html: item.post_body.replace(/\n/g, '<br>') }}></p>
                      <div>
                        {item.first_name && item.first_name.length > 0 && (
                        <strong>- {item.first_name}</strong>
                        )}
                      </div>

                      <div style={{marginTop: '10px'}}>
                          <button className="btn" onClick={() => handleRemovePost(item.event_post_id)}>Remove</button>

                          <button className="btn" onClick={() => handleEditPost(item.event_post_id, item.post_body)}>Edit</button>
                      </div>
                      </div>
                    </div>
                   </div>
                ))}
              </div>
              )}
            </div>
          </div> */}
{/* 
          <div className="manageevent__guests" id="hosts">
              <div>
                <h2>Questions</h2>

                <p>
                  Attendees will be required to answer these questions before they register.
                </p>

                <div style={{padding: '20px'}}>
                <input type="text" className='input' placeholder='Type a question' value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />

                  <div style={{marginTop: "10px"}}>
                    <button className='btn btn--primary' onClick={() => saveQuestion()}>Add</button>
                  </div>

                  {loaded && questions && (
                  <div className='companyedit__questions'>
                    {loaded === false && (
                      <div>
                        <Spinner />
                      </div>
                    )}

                    {loaded && questions.length === 0 && (
                      <div className="center">
                        <i>No questions found...</i>
                      </div>
                    )}
                  {questions.map((item, index) => (
                    <div className='companyedit__question' key={index}>
                      <div className='companyedit__question-label'>{`Question: ${index + 1}`}</div>
                      <div>{item.question}</div>
                      <div className='companyedit__question-btns'>
                        <span className='btn' onClick={() => removeQuestion(item.event_question_id)}>Remove</span>
                        <span className='btn' onClick={() => editQuestion(item.event_question_id)}>Edit</span>

                        {index > 0 && (
                          <span className='btn' onClick={() => moveUp(item.event_question_id, index)}>Move Up <FaArrowUp /></span>
                        )}

                        {index < questions.length - 1 && (
                          <span className='btn' onClick={() => moveDown(item.event_question_id, index)}>Move Down <FaArrowDown /></span>
                        )}
                      </div>
                    </div>
                  ))}
                  </div>
                  )}
                </div>
              </div>
          </div> */}

          <div className="manageevent__guests center" id="delete">
              <p>
                <button className="btn btn--delete" onClick={() => handleDeleteEvent()}>Delete this event</button>
              </p>
          </div>
        </div>

        {showAddHost && (
          <FadeIn delay={200} duration={200}>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowAddHost(false)}>
                <FaTimes />
              </div>
              <h2 className="modal__h2">Invite Host</h2>

              <p className="manageevent__description">
                Add a host to help manage the event.
              </p>

              <p>
                <label>Enter email</label>
                <input type="email" className="input" value={hostEmail} onChange={(e) => setHostEmail(e.target.value)} placeholder="Email address" />
              </p>

              <p style={{marginTop: '10px'}}>
                <label>Role</label>
                <select className="select input" onChange={(e) => setHostRole(e.target.value)}>
                  <option value='Admin'>Admin</option>
                  <option value='Member'>Member</option>
                </select>
              </p>

              <p style={{marginTop: '20px'}}>
                {inviting === false && (
                <button className="btn" style={{padding: '5px'}} onClick={() => handleInviteHost()}>Send invite</button>
                )}

                {inviting && (
                  <Spinner />
                )}
              </p>
            </div>
          </FadeIn>
        )}

        {showInviteConfirm && (
          <>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowInviteConfirm(false)}>
                <FaTimes />
              </div>

              <h2 className="modal__h2">Invite Sent</h2>

              <p style={{marginTop: '10px'}}>
                Email has been sent.
              </p>
            </div>
          </>
        )}

        {showHostEdit && (
          <FadeIn delay={300} duration={300}>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowHostEdit(false)}>
                <FaTimes />
              </div>

              <h2 className="modal__h2">Edit host</h2>

              <p style={{marginTop: '10px'}}>
                <label>Role</label>
                
                <select className="select input" value={editHostRole} onChange={(e) => setEditHostRole(e.target.value)}>
                  <option value='Admin'>Admin</option>
                  <option value='Member'>Member</option>
                </select>
              </p>

              <p style={{marginTop: '20px'}}>
                <button className="btn btn--primary" onClick={() => handleUpdateHost()}>Update</button>
              </p>

              <p style={{marginTop: '10px'}}>
                <span className="link link--cancel" onClick={() => handleDeleteHost()}>Remove host access</span>
              </p>
            </div>
          </FadeIn>
        )}

{showQuestionEdit && (
        <>
          <div className='overlay'></div>
          <div className='companyedit__modal modal'>
            <div className='modal__close' onClick={() => setShowQuestionEdit(false)}>
              <FaTimes />
            </div>
            <h2 style={{fontSize: "22px", fontWeight: "bold"}}>Update qualifer question</h2>
            <br/>

            <div>
              <textarea style={{height: "100px"}} className='input' value={currentQuestion.question} onChange={(e) => handleQuestionChange(e.target.value)} />
            </div>

            <div className='center' style={{marginTop: "20px"}}>
              <button className="btn btn--primary" onClick={() => updateQuestion()}>Update</button>
              <div className='link link--cancel' style={{marginTop: "10px"}} onClick={() => setShowQuestionEdit(false)}>Cancel</div>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
              <FadeIn delay={150} duration={300}>
                <div className='profileinfo__saved-banner'>
                  Event updated successfully
                </div>
              </FadeIn>
            )}

      {showAnswers && (
        <FadeIn delay={300} duration={300}>
          <div className="overlay"></div>
          <div className="modal manageevent__modal-answers">
            <div className="modal__close" onClick={() => setShowAnswers(false)}><FaTimes /></div>

            <div>
              <table className="table manageevent__table">
                {answers.map(a => (
                  <tr>
                  <td style={{width: '50%'}}><strong>{a.question}</strong></td>
                  <td>{a.answer}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </FadeIn>
      )}

      {showConfirmDelete && (
        <>
          <div className="overlay"></div>
          <div className="modal">
            <div className="modal__close" onClick={() => setShowConfirmDelete(false)}>
              <FaTimes />
            </div>

            <h2 className="modal__h2">Confirm</h2>

            <p style={{marginTop: '10px'}}>
              Are you sure you want to delete this event? You will lose all guests, hosts and posts and will not be able to recover them.
            </p>

            <p style={{marginTop: '10px'}} className="center">
              <button className="btn btn--delete" onClick={() => handleDelete()}>Yes, delete this event</button>
            </p>
          </div>
        </>
      )}

      {showAddSpeaker && (
          <FadeIn delay={200} duration={200}>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowAddSpeaker(false)}>
                <FaTimes />
              </div>
              <h2 className="modal__h2">Add event speaker</h2>

              <p className="manageevent__description">
                Add speaker bio for event.
              </p>

              <p>
                <label>Speaker Name</label>
                <input type="email" className="input" value={speakerName} onChange={(e) => setSpeakerName(e.target.value)} placeholder="Name" />
              </p>

              <p className="mt-10">
                <label>Speaker bio</label>
                <textarea className="input textarea" placeholder="Speaker bio" value={speakerBio} onChange={(e) => setSpeakerBio(e.target.value)}></textarea>
              </p>

              <p className="mt-10">
                <label>Profile picture</label>

                <div className='companyedit__upload'>
                  <div>
                    {fileName}
                  </div>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/eventspeaker"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
              </p>

              <p className="mt-20">
                {inviting === false && (
                  <button className="btn" style={{padding: '5px'}} onClick={() => handleAddSpeaker()}>Add speaker</button>
                )}

                {inviting && (
                  <Spinner />
                )}
              </p>
            </div>
          </FadeIn>
        )}

      {showEditSpeaker && (
          <FadeIn delay={200} duration={200}>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowEditSpeaker(false)}>
                <FaTimes />
              </div>
              <h2 className="modal__h2">Edit event speaker</h2>

              <p className="manageevent__description">
                Edit speaker bio for event.
              </p>

              <p>
                <label>Speaker Name</label>
                <input type="email" className="input" value={speakerName} onChange={(e) => setSpeakerName(e.target.value)} placeholder="Name" />
              </p>

              <p className="mt-10">
                <label>Speaker bio</label>
                <textarea className="input textarea" placeholder="Speaker bio" value={speakerBio} onChange={(e) => setSpeakerBio(e.target.value)}></textarea>
              </p>

              <p className="mt-10">
                <label>Profile picture</label>
                <div className='companyedit__upload'>
                  <div>
                    {fileName}
                  </div>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/eventspeaker"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
              </p>

              <p className="mt-20 center">
                {submitting === false && (
                  <button className="btn" style={{padding: '5px'}} onClick={() => handleUpdateSpeaker()}>Save changes</button>
                )}

                {submitting && (
                  <Spinner />
                )}
              </p>

              <p className="mt-10 center">
                <span className='link link--cancel' onClick={() => handleRemoveSpeaker()}>Remove speaker</span>
              </p>
            </div>
          </FadeIn>
        )}

      {showEditor && (
        <FadeIn delay={300} duration={300}>
          {/* <div className="overlay"></div> */}
          <div className="modal" style={{width: '90%', marginLeft: '-45%', zIndex: '1000'}}>
            <div className="modal__close" onClick={() => setShowEditor(false)}><FaTimes /></div>
          <Editor
      apiKey='cdi8hbnuihfmdcpjhe2c0jf8b7bov88gr0amec9khuxoykpg'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(newValue, editor) => setEventPost(newValue)}
      initialValue={eventPostNew}
    />

      <div className="center" style={{marginTop: '10px'}}>
        <div>
          <input type="checkbox" onChange={(e) => setSendEmail(e.target.checked)} /> Send email to recipients
        </div>

        {sendEmail && (
          <p style={{padding: '10px'}}>
            <select className="select" onChange={(e) => handleSendToChange(e.target.selectedIndex)}>
              <option value='All'>All</option>
              <option value='Guests'>Guests</option>
              <option value='Pending'>Pending</option>
            </select>
          </p>
        )}

        {selectedPostId == null && (
          <button style={{backgroundColor: 'dodgerblue', padding: '10px'}} className="btn btn--primary btn--full" onClick={() => handleEditorSave()}>Send post</button>
        )}

        {selectedPostId != null && (
          <button style={{backgroundColor: 'dodgerblue', padding: '10px'}} className="btn btn--primary btn--full" onClick={() => confirmPostUpdate()}>Save changes</button>
        )}
      </div>
          </div>
        </FadeIn>
      )}
      </div>
          );
      }

export default EventManage;