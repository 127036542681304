import './BuyerFilters.css';
import './SearchFilters.css';
import { useState, useEffect } from 'react';
import arrow from '../../assets/design/dropDownArrow.png';
import x from '../../assets/design/filterTagX.png';
import { getCompanySizes, getCompanyIndustries, getIndustriesWithBuyers, getSeniorityLevels, getPersonaTypes, searchIndustriesWithBuyers, searchDepartments, searchJobTitles, getDepartmentsWithBuyers } from '../../utils/api';
import classNames from "classnames";
import queryString from 'query-string';
import Spinner from '../../components/layout/Spinner';
import { FaAward, FaChartLine, FaHorseHead, FaSlidersH } from 'react-icons/fa';
import { findRenderedComponentWithType } from 'react-dom/cjs/react-dom-test-utils.production.min';

const BuyerFilters = ({setSelectedFilters, selectedFilters, removeFilter, setShowFilter}) => {
  const [companySizes, setCompanySizes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [personaTypes, setPersonaTypes] = useState([]);
  const [industryQuery, setIndustryQuery] = useState("");
  const [departmentQuery, setDepartmentQuery] = useState("");
  const [jobTitleQuery, setJobTitleQuery] = useState("");
  const [industryResults, setIndustryResults] = useState([]);
  const [departmentResults, setDepartmentResults] = useState([]);
  const [jobTitleResults, setJobTitleResults] = useState([]);
  const [showPersonaType, setShowPersonaType] = useState(true);
  const [showIndustry, setShowIndustry] = useState(true);
  const [showDepartment, setShowDepartment] = useState(true);
  const [showJobTitle, setJobTitle] = useState(true);
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false);
  const [showJobTitleDropdown, setShowJobTitleDropdown] = useState(false);
  const [showCompanySize, setShowCompanySize] = useState(true);
  const [showSeniority, setShowSeniority] = useState(true);
  const [showSaved, setShowSaved] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCompanySizes, setSelectedCompanySizes] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedSeniority, setSelectedSeniority] = useState([]);
  const [selectedPersonaTypes, setSelectedPersonaTypes] = useState([]);
  const [industryLoaded, setIndustryLoaded] = useState(false);
  const [departmentLoaded, setDepartmentLoaded] = useState(false);

  useEffect(loadResults, []);

  useEffect(() => {
    let _companysizes = selectedFilters.filter(item => {
      return item.type === 'CompanySize'
    }).map(item2 => {
      return item2.value
    });

    let _seniority = selectedFilters.filter(item => {
      return item.type === 'Seniority'
    }).map(item2 => {
      return item2.value
    });

    let _companystages = selectedFilters.filter(item => {
      return item.type === 'CompanyStage'
    }).map(item2 => {
      return item2.value
    });

    let _categories = selectedFilters.filter(item => {
      return item.type === 'Category'
    }).map(item2 => {
      return {"category_id": item2.value, "name":item2.name}
    });

    let _industries = selectedFilters.filter(item => {
      return item.type === 'Industry'
    }).map(item2 => {
      return {industry_id: item2.value, name: item2.name}
    });

    let _personatypes = selectedFilters.filter(item => {
      return item.type === 'Persona'
    }).map(item2 => {
      return item2.value;
    });

    let _departments = selectedFilters.filter(item => {
      return item.type === 'Department'
    }).map(item2 => {
      return {department_id: item2.value, name: item2.name}
    });

    setSelectedCompanySizes(_companysizes);
    setSelectedStages(_companystages);
    setSelectedCategories(_categories);
    setSelectedIndustries(_industries);
    setSelectedDepartments(_departments);
    setSelectedSeniority(_seniority);
    setSelectedPersonaTypes(_personatypes);
  }, [selectedFilters]);

  async function readParameters(){
    const search = window.location.search; // could be '?foo=bar'  
    const parsed = queryString.parse(search);
    const _personaTypeId = parsed["persona"];

    if (_personaTypeId){
      selectPersonaTypes(_personaTypeId);
    }
  }

  function loadResults() {
    loadCompanySizes();
    loadIndustry();
    loadDepartments();
    loadSeniority();
    loadPersonaType();
  }

  async function loadCompanySizes(){
    let data = await getCompanySizes();
    setCompanySizes(data.data);
  }

  async function loadIndustry(){
    let data = await getIndustriesWithBuyers();
    setIndustries(data.data);
    setIndustryLoaded(true);
  }

  async function loadDepartments(){
    let data = await getDepartmentsWithBuyers();
    setDepartments(data.data);
    setDepartmentLoaded(true);
  }

  async function loadSeniority(){
    let data = await getSeniorityLevels();
    setSeniority(data.data);
  }

  async function loadPersonaType(){
    try {
      let _personatypes = await getPersonaTypes('Buyer');

      if (_personatypes.success){
        setPersonaTypes(_personatypes.body);
      }
    } catch (error) {
    }
  }

  useEffect(async () => {
    await readParameters();
  }, [personaTypes]);

  async function toggleCompanySize(){
    setShowCompanySize(!showCompanySize);
  }

  async function toggleIndustry(){
    setShowIndustry(!showIndustry);
  }

  async function toggleDepartmnet(){
    setShowDepartment(!showDepartment);
  }

  async function toggleJobTitle(){
    setShowJobTitleDropdown(!showJobTitle);
  }

  async function toggleSeniority(){
    setShowSeniority(!showSeniority);
  }

  async function togglePersonaType(){
    setShowPersonaType(!showPersonaType);
  }

  async function toggleSaved(){
    setShowSaved(!showSaved);
  }

  async function selectCompanySize(company_size_id, value){
    if (!selectedCompanySizes.includes(company_size_id))
    {
      setSelectedCompanySizes([...selectedCompanySizes, company_size_id]);
      // search bar filter
      addFilter('CompanySize', company_size_id, value, removeCompanySize);
    }

    else{
      // remove
      setSelectedCompanySizes(selectedCompanySizes.filter(item => item !== company_size_id));

      // remove from main filter
      removeFilter('CompanySize', company_size_id);
    }
  }

  async function addFilter(type, value, name, del){
    let obj = {type, value, name, del};
    let merge = [...selectedFilters, obj];
    setSelectedFilters(merge);
  }

  async function removeCompanySize(company_size_id){
  }

  async function handleIndustryChange(e){
    if (e.target.selectedIndex > 0){
      selectIndustry(parseInt(e.target.value), e.target.options[e.target.selectedIndex].text);
      //await industrySearch(e.target.value);
      //setSelectedIndustries
    }
  }

  async function industrySearch(query){
    setShowIndustryDropdown(true);
    let data = await searchIndustriesWithBuyers(query);

    if (data.data){
        setIndustryResults(data.data);
    }
  }

  async function handleDepartmentChange(e){
    //setDepartmentResults([]);
    //setDepartmentQuery(e.target.value);
    //await departmentSearch(e.target.value);
   // let tmp = e.target.options[e.selectedIndex].text;
   if (e.target.selectedIndex > 0){
    selectDepartment(e.target.options[e.target.selectedIndex].text, parseInt(e.target.value));
   }
  }

  async function handleJobTitleChange(e){
    setJobTitleResults([]);
    setJobTitleQuery(e.target.value);

    await jobTitleSearch(e.target.value);
    //await departmentSearch(e.target.value);
   // let tmp = e.target.options[e.selectedIndex].text;
   // selectDepartment(e.target.options[e.target.selectedIndex].text);
  }

  async function jobTitleSearch(query){
    setShowJobTitleDropdown(true);
    let data = await searchJobTitles(query);

    if (data.data){
      setJobTitleResults(data.data);
    }
  }

  async function departmentSearch(query){
    setShowDepartmentDropdown(true);
    let data = await searchDepartments(query);

    if (data.data){
      setDepartmentResults(data.data);
    }
  }

  async function selectIndustry(industry_id, name){
    setShowIndustryDropdown(false);
    setIndustryQuery('');
    setSelectedIndustries([...selectedIndustries, {industry_id, name}]);

    addFilter("Industry", industry_id, name);
  }

  async function selectDepartment(name, department_id){
     setShowDepartmentDropdown(false);
     setDepartmentQuery('');
     setSelectedDepartments([...selectedDepartments, {department_id, name}]);

     addFilter("Department", department_id, name);
  }

  async function selectJobTitle(item){
    setShowJobTitleDropdown(false);
     setJobTitleQuery('');
    // setSelectedDepartments([...selectedDepartments, item]);
    setSelectedJobTitles([...selectedJobTitles, item]);
    console.log(item);
    console.log(selectedJobTitles);
     addFilter("JobTitle", item, item);
  }

  async function selectSeniority(seniority_level_id, name){
    if (!selectedSeniority.includes(seniority_level_id))
    {
      setSelectedSeniority([...selectedSeniority, seniority_level_id]);
      // search bar filter
      addFilter('Seniority', seniority_level_id, name, removeSeniorityLevel);
    }

    else{
      // remove
      setSelectedSeniority(selectedSeniority.filter(item => item !== seniority_level_id));

      // remove from main filter
      removeFilter('Seniority', seniority_level_id);
    }
  }

  async function selectPersonaTypes(persona_type_id, name){
        if (!selectedPersonaTypes.includes(persona_type_id))
        {
          setSelectedPersonaTypes([...selectedPersonaTypes, persona_type_id]);
          // search bar filter
          addFilter('Persona', persona_type_id, name);
        }
    
        else{
          // remove
          setSelectedPersonaTypes(selectedPersonaTypes.filter(item => item !== persona_type_id));
    
          // remove from main filter
          removeFilter('Persona', persona_type_id);
        }
      }

  async function removeSeniorityLevel(seniority_level_id){
  const list = selectedSeniority.filter(item => {
    return item !== seniority_level_id
  });

  setSelectedSeniority(list);
  }

  async function removeIndustry(industry_id){
    const list = selectedIndustries.filter(item => {
      return item.industry_id !== industry_id
    });

    setSelectedIndustries(list);
    removeFilter("Industry", industry_id);
  }

  async function removeDepartment(department_id){
    const list = selectedDepartments.filter(item => {
      return item.department_id !== department_id
    });

    setSelectedDepartments(list);
    removeFilter("Department", department_id);
  }

  async function removeJobTitle(jobtitle){
    const list = selectedJobTitles.filter(item => {
      return item !== jobtitle
    });

    setSelectedJobTitles(list);
    removeFilter("JobTitle", jobtitle);
  }

  return (
    <div className="buyerfilter">
      <h3 style={{padding:' 5px 10px', fontWeight: 'bold'}}><FaSlidersH /> Filters</h3>
      <div className="filter-section-header" onClick={toggleIndustry}>
          <span className="filter-card-subheader">Industry</span>
        </div>

        <div className={classNames('searchfilter__section', {
        "show":showIndustry })}>
              <div className="searchfilter__dropdown">
              
                <div>
                  {industryLoaded && (
                  <select className="select input" onChange={(e) => handleIndustryChange(e)}>
                    <option>Select industry</option>
                    {industries.filter(i => selectedIndustries.filter(x => x.industry_id === i.industry_id).length === 0).map((item, index) => (
                      <option value={item.industry_id}>{item.name}</option>
                    ))}
                  </select>
                  )}

                  {!industryLoaded && (
                    <Spinner />
                  )}
                </div>

                {/* <div style={{marginTop: "10px", position: "relative", display:"none"}}>
                  <input type="text" name="industry" id="txtIndustry" value={industryQuery} onChange={handleIndustryChange} className="category-dropdown" placeholder="Search" />

                  {industryResults.length > 0 && showIndustryDropdown && (
                  <div className="searchfilter__search-results">
                      {industryResults.map((item, index) => (
                          <div className="home__hero-category-results-item" key={index} onClick={() => selectIndustry(item.industry_id, item.name)}>{item.name}</div>
                      ))}
                  </div>
                  )}
                </div> */}

                <div className="searchfilter__category-tags">
                  {selectedIndustries.map((item, index) => (
                    <div className="searchfilter__category-tag" key={index}>
                      <span className="searchfilter__category-tag-text">{item.name}</span>
                      <img className="searchfilter__category-tag-x" onClick={() => removeIndustry(item.industry_id)} src={x} alt="" />
                    </div>
                  ))}
              </div>
              </div>
          </div>

          <div className="filter-section-header" onClick={toggleDepartmnet}>
          <span className="filter-card-subheader">Department</span>
        </div>

        <div className={classNames('searchfilter__section', {
        "show":showDepartment })}>
              <div className="searchfilter__dropdown">
                <div>
                  {departmentLoaded && (
                  <select className="select input" onChange={(e) => handleDepartmentChange(e)}>
                    <option>Select department</option>
                    {departments.map((item, index) => (
                      <option value={item.department_id}>{item.name}</option>
                    ))}
                  </select>
                  )}

                  {!departmentLoaded && (
                    <Spinner />
                  )}
                </div>

                <div style={{marginTop: "10px", position: "relative", display:"none"}}>
                  <input type="text" name="industry" id="txtDepartment" value={departmentQuery} onChange={handleDepartmentChange} className="category-dropdown" placeholder="Search" />

                  {departmentResults.length > 0 && showDepartmentDropdown && (
                  <div className="searchfilter__search-results">
                      {departmentResults.map((item, index) => (
                          <div className="home__hero-category-results-item" key={index} onClick={() => selectDepartment(item.department)}>{item.department}</div>
                      ))}
                  </div>
                  )}
                </div>

                <div className="searchfilter__category-tags">
                  {selectedDepartments.map((item, index) => (
                    <div className="searchfilter__category-tag" key={index}>
                      <span className="searchfilter__category-tag-text">{item.name}</span>
                      <img className="searchfilter__category-tag-x" onClick={() => removeDepartment(item.department_id)} src={x} alt="" />
                    </div>
                  ))}
              </div>
              </div>
          </div>

          <div className="filter-section-header" onClick={toggleJobTitle}>
          <span className="filter-card-subheader">Job title</span>
        </div>

        <div className={classNames('searchfilter__section', {
        "show":showJobTitle })}>
              <div className="searchfilter__dropdown">
                <div>
                  {/* <input type="text" placeholder="Search" value={jobTitleQuery} onChange={handleJobTitleChange} className="input" /> */}
                  {/* <select className="select input" onChange={(e) => handleDepartmentChange(e)}>
                    <option>Select department</option>
                    {departments.map((item, index) => (
                      <option>{item.department}</option>
                    ))}
                  </select> */}
                </div>

                <div style={{marginTop: "10px", position: "relative"}}>
                  <input type="text" name="jobtitle" id="txtJobTitle" autoComplete='off' value={jobTitleQuery} onChange={handleJobTitleChange} className="category-dropdown" placeholder="Search" />

                  {jobTitleResults.length > 0 && showJobTitleDropdown && (
                  <div className="searchfilter__search-results">
                    <div className="home__hero-category-results-item" style={{position: 'absolute', right: '10px', top: '5px'}} onClick={() => setShowJobTitleDropdown(false)}>Close</div>

                      {jobTitleResults.map((item, index) => (
                          <div className="home__hero-category-results-item" key={index} onClick={() => selectJobTitle(item.job_title)}>{item.job_title}</div>
                      ))}
                  </div>
                  )}
                </div>

                <div className="searchfilter__category-tags">
                  {selectedJobTitles.map((item, index) => (
                    <div className="searchfilter__category-tag" key={index}>
                      <span className="searchfilter__category-tag-text">{item}</span>
                      <img className="searchfilter__category-tag-x" onClick={() => removeJobTitle(item)} src={x} alt="" />
                    </div>
                  ))}
              </div>
              </div>
          </div>

          <div className="filter-section-header" onClick={toggleCompanySize}>
            <span className="filter-card-subheader">Company Size</span>
          </div>

          <div className={classNames('searchfilter__section', {
        "show":showCompanySize })}>
          <div className="searchfilter__dropdown">
            {companySizes.map((item, index) => (
              <div className="searchfilter__checkbox-wrapper" key={index}>

              <label className="searchfilter__container">
                <input type="checkbox" className="searchfilter__checkbox" checked={selectedCompanySizes.includes(item.company_size_id)} onChange={() => {selectCompanySize(item.company_size_id, item.value)}} />
                <span className="searchfilter__checkbox-label">{item.value}</span>
                <span className="searchfilter__checkmark"></span>
              </label>

                {/* <input className="checkbox searchfilter__checkbox--round"  checked={selectedCompanySizes.includes(item.company_size_id)} onChange={() => {selectCompanySize(item.company_size_id, item.value)}} type="checkbox" />
                <span>{item.value}</span> */}
              </div>
            ))}
            </div>
          </div>

        {/* <div className="filter-section-header" onClick={togglePersonaType}>
          <span className="filter-card-subheader">Select Persona Type</span>
        </div>

        <div className={classNames('searchfilter__section', {
      "show":showPersonaType })}>
            <div className="">
              <div style={{marginTop: "10px"}}>
                <div className="searchfilter__dropdown">
                  {personaTypes.map((item, index) => (
                    <div className="searchfilter__row" key={index}>
                      <label className="searchfilter__container">
                        <input type="checkbox" className="searchfilter__checkbox" checked={selectedPersonaTypes.includes(item.persona_type_id)} onChange={() => {selectPersonaTypes(item.persona_type_id, item.name)}} />
                <span className="searchfilter__checkbox-label">{item.name}</span>
                <span className="searchfilter__checkmark"></span>
                </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
        </div> */}

        <div className="filter-section-header" onClick={toggleSeniority}>
        
          <span className="filter-card-subheader">Seniority level</span>

          <img src={arrow} alt="" className={classNames('searchfilter__arrow', {"searchfilter__arrow--open": showSeniority})} />
        </div>

        <div className={classNames('searchfilter__section', {
      "show":showSeniority })}>
        <div className="searchfilter__dropdown">
        {seniority.map((item, index) => (
            <div className="searchfilter__checkbox-wrapper" key={index}>
              <label className="searchfilter__container">
                <input type="checkbox" className="searchfilter__checkbox" checked={selectedSeniority.includes(item.seniority_level_id)} onChange={() => {selectSeniority(item.seniority_level_id, item.name)}} />
                <span className="searchfilter__checkbox-label">{item.name}</span>
                <span className="searchfilter__checkmark"></span>
              </label>

            {/* <input className="checkbox searchfilter__checkbox--round"  checked={selectedSeniority.includes(item.seniority_level_id)} type="checkbox" onChange={() => {selectSeniority(item.seniority_level_id, item.name)}} />
            <span>{item.name}</span> */}
          </div>
          ))}
        </div>
        </div>

        <div className="filter-section-header" onClick={toggleSaved} style={{display:"none"}}>
          <span className="filter-card-subheader">Saved</span>

          <img src={arrow} alt="" className={classNames('searchfilter__arrow', {"searchfilter__arrow--open": showSaved})} />
        </div>

        <div className={classNames('searchfilter__section', {
      "show":showSaved })}>
        <div className="searchfilter__dropdown">
        {industries.map((item, index) => (
            <div key={index}>
              <input type="checkbox" />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
        </div>

        <div className="searchfilter__filter-btn">
          <button className="btn btn--action" onClick={() => setShowFilter(false)}>Search</button>
        </div>
    </div>
)
}

export default BuyerFilters;
