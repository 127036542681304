import './Confirmation.css';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import congrats from '../../assets/design/signup/congrats.png';

function Confirmation() {
    useEffect(() => {       
        document.title = "LeadrPro - Success!";
    }, []);

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="card confirmation createaccount">
            <div className="" style={{padding: '30px'}}>
                <h1 className="card__header createaccount__left-header">Welcome!</h1>

                <p style={{fontSize: "14px"}}>
                    Welcome aboard! You are now a part of a vibrant community of software enthusiasts, where you can discover, buy and sell software. Keep an eye on your inbox for updates, tips and offers that will help you get the most out of LeadrPro. Happy exploring!
                </p>

                <p style={{marginTop: "100px"}}>
                    <a href="/dashboard/buyer" className="btn btn--primary">Go to my dashboard</a>
                </p>
            </div>

            {/* <div className="createaccount__right" style={{padding: "20px 30px"}}>
                <div className="">
                    <img src={congrats} alt="" />
                </div>
            </div> */}
            </div>
        </div>
    );
}

export default Confirmation;