import './Home2.css';
import 'animate.css';

import screen_buyers from '../../assets/design/homepage/screen_buyers.png';
import screen_sellers2 from '../../assets/design/homepage/screen_sellers2.png';
import screen_hero from '../../assets/design/homepage/screen_hero3.png';
import screen_roi from '../../assets/design/homepage/screen_roi.png';
import content1 from '../../assets/design/homepage/content1.png';
import content2 from '../../assets/design/homepage/content2.png';
import content1b from '../../assets/design/homepage/content1b.png';
import content2b from '../../assets/design/homepage/content2b.png';
import logos from '../../assets/design/homepage/logos.png';
import payment from '../../assets/design/homepage/payment2.png';
import logo_bite from '../../assets/design/homepage/clients/bite.png';
import logo_jurny from '../../assets/design/homepage/clients/jurny.png';
import logo_lumi from '../../assets/design/homepage/clients/lumi.png';
import logo_slintel from '../../assets/design/homepage/clients/slintel.png';
import logo_voicea from '../../assets/design/homepage/clients/voicea.png';
import logo_gocheckkids from '../../assets/design/homepage/clients/gocheckkids.png';
import logo_joany from '../../assets/design/homepage/clients/joany.png';
import logo_involve from '../../assets/design/homepage/clients/involve.png';
import icon_earn from '../../assets/design/homepage/icon_earn.png';
import icon_research from '../../assets/design/homepage/icon_research.png';
import icon_bookdemos from '../../assets/design/homepage/icon_bookdemos.png';
import icon_instant from '../../assets/design/homepage/icon_instant.png';
import icon_visibility from '../../assets/design/homepage/icon_visibility.png';
import icon_marketfit from '../../assets/design/homepage/icon_marketfit.png';
import icon_leftarrow from '../../assets/design/homepage/icon_leftarrow.png'
import icon_rightarrow from '../../assets/design/homepage/icon_rightarrow.png';

import logo_enterprise_accenture from '../../assets/design/logos/enterprise/accenture.png';
import logo_enterprise_adp from '../../assets/design/logos/enterprise/adp.png';
import logo_enterprise_atlassian from '../../assets/design/logos/enterprise/atlassian.png';
import logo_enterprise_benchmark from '../../assets/design/logos/enterprise/benchmark.png';
import logo_enterprise_carvana from '../../assets/design/logos/enterprise/carvana.png';
import logo_enterprise_docusign from '../../assets/design/logos/enterprise/docusign.png';
import logo_enterprise_expedia from '../../assets/design/logos/enterprise/expedia.png';
import logo_enterprise_facebook from '../../assets/design/logos/enterprise/facebook.png';
import logo_enterprise_gusto from '../../assets/design/logos/enterprise/gusto.png';
import logo_enterprise_hungryroot from '../../assets/design/logos/enterprise/hungryroot.png';
import logo_enterprise_invesco from '../../assets/design/logos/enterprise/invesco.png';
import logo_enterprise_keymortgage from '../../assets/design/logos/enterprise/keymortgage.png';
import logo_enterprise_lathamandwatkins from '../../assets/design/logos/enterprise/lathamandwatkins.png';
import logo_enterprise_lexisnexis from '../../assets/design/logos/enterprise/lexisnexis.png';
import logo_enterprise_lyft from '../../assets/design/logos/enterprise/lyft.png';
import logo_enterprise_riteaid from '../../assets/design/logos/enterprise/rite-aid.png';
import logo_enterprise_servicetitan from '../../assets/design/logos/enterprise/servicetitan.png';
import logo_enterprise_shift from '../../assets/design/logos/enterprise/shift.png';
import logo_enterprise_sonos from '../../assets/design/logos/enterprise/sonos.png';
import logo_enterprise_transunion from '../../assets/design/logos/enterprise/transunion.png';
import logo_enterprise_travelers from '../../assets/design/logos/enterprise/travelers.png';

import logo_midmarket_asana from '../../assets/design/logos/midmarket/asana.png';
import logo_midmarket_bombfell from '../../assets/design/logos/midmarket/bombfell.png';
import logo_midmarket_brex from '../../assets/design/logos/midmarket/brex.png';
import logo_midmarket_carta from '../../assets/design/logos/midmarket/carta.png';
import logo_midmarket_chargebee from '../../assets/design/logos/midmarket/chargebee.png';
import logo_midmarket_clari from '../../assets/design/logos/midmarket/clari.png';
import logo_midmarket_premisehealth from '../../assets/design/logos/midmarket/premise_health.png';
import logo_midmarket_promenade from '../../assets/design/logos/midmarket/promenade.png';
import logo_midmarket_segment from '../../assets/design/logos/midmarket/segment.png';
import logo_midmarket_texada from '../../assets/design/logos/midmarket/texada.png';
import logo_midmarket_theorem from '../../assets/design/logos/midmarket/theorem.png';
import logo_midmarket_touchofmodern from '../../assets/design/logos/midmarket/touch-of-modern.png';
import logo_midmarket_tradesy from '../../assets/design/logos/midmarket/tradesy.png';
import logo_midmarket_wholesystems from '../../assets/design/logos/midmarket/whole-systems.png';
import logo_midmarket_wrike from '../../assets/design/logos/midmarket/wrike.png';

import logo_smb_funeral_busselfamilyfuneral from '../../assets/design/logos/smb/funeral/busselfamilyfuneral.png';
import logo_smb_funeral_strawser from '../../assets/design/logos/smb/funeral/strawser_staleyfuneralhomes.png';
import logo_smb_funeral_wingate from '../../assets/design/logos/smb/funeral/wingatefuneralhome.png';

import logo_smb_general_aspen from '../../assets/design/logos/smb/general/aspen-remodelers.png';
import logo_smb_general_ckc from '../../assets/design/logos/smb/general/ckc.png';
import logo_smb_general_la from '../../assets/design/logos/smb/general/la_green_designs.png';

import logo_smb_gym_crossfit from '../../assets/design/logos/smb/gym/crossfitbellator.png';
import logo_smb_gym_livefree from '../../assets/design/logos/smb/gym/livefree.png';
import logo_smb_gym_peek from '../../assets/design/logos/smb/gym/peekskillstrength.jpg';

import logo_smb_insurance_ssgi from '../../assets/design/logos/smb/insurance/ssgi.png';
import logo_smb_insurance_thearizona from '../../assets/design/logos/smb/insurance/thearizonagroup.png';

import logo_smb_pedia_childrenshospital from '../../assets/design/logos/smb/pediatricians/childrenshospitalofchicago.png';
import logo_smb_pedia_osf from '../../assets/design/logos/smb/pediatricians/osfhealthcare.svg';
import logo_smb_pedia_valley from '../../assets/design/logos/smb/pediatricians/valleychildrenshospital.png';

import logo_smb_rental_green from '../../assets/design/logos/smb/rental/greentortoise.png';
import logo_smb_rental_julian from '../../assets/design/logos/smb/rental/juliancabins.png';
import logo_smb_rental_sea from '../../assets/design/logos/smb/rental/seabreeze.png';

import { useEffect, useState } from 'react';
import {
  getPersonaTypes,
} from '../../utils/api';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Home2() {
  const [acv, setAcv] = useState(0);
  const [conversion, setConversion] = useState(0);
  const [paybackPeriod, setPaybackPeriod] = useState(3);
  const [costpersql, setCostPerSql] = useState(0);
  const [personaTypes, setPersonaTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [bookedLogos, setBookedLogos] = useState([]);
  const [enterpriseLogos, setEnterpriseLogos] = useState([]);
  const [midMarketLogos, setMidMarketLogos] = useState([]);
  const [smbLogos, setSmbLogos] = useState([]);
  const [funeralLogos, setFuneralLogos] = useState([]);
  const [generalLogos, setGeneralLogos] = useState([]);
  const [gymLogos, setGymLogos] = useState([]);
  const [insuranceLogos, setInsuranceLogos] = useState([]);
  const [pediatricianLogos, setPediatriciansLogos] = useState([]);
  const [rentalLogos, setRentalLogos] = useState([]);
  const [selectedLogos, setSelectedLogos] = useState("Enterprise");
  const [testimonials, setTestimonials] = useState([]);
  const [showSmb, setshowSmb] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState({});  // array index
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(function () {
    document.title = 'LeadrPro - The Power is Yours';

    function loadOurClients(){
      let our_clients= [];
      our_clients.push({
        name: "Lumi",
        logo: logo_lumi,
        description: "Acquired by Narvar"
      });

      our_clients.push({
        name: "Slintel",
        logo: logo_slintel,
        description: "Acquired by 6sense"
      });

      our_clients.push({
        name: "Voicea",
        logo: logo_voicea,
        description: "Acquired by Cisco"
      });

      our_clients.push({
        name: "Jurny",
        logo: logo_jurny,
        description: "Closed $9.5M Series A"
      });

      our_clients.push({
        name: "Bite",
        logo: logo_bite,
        description: "Closed $10M Series A"
      });

      our_clients.push({
        name: "GoCheckKids",
        logo: logo_gocheckkids,
        description: "Closed $10M Series B"
      });

      setClients(our_clients);
    }

    function loadTestimonials(){
      let _list = [];

      _list.push({
        name: "GoCheck",
        logo: logo_gocheckkids,
        text: "LeadrPro helped us hit a record quarter during the height of the pandemic by getting us more demos with Pediatricians!"
      });

      _list.push({
        name: "Involve",
        logo: logo_involve,
        text: "LeadrPro helped us quickly realize we had poor market fit and so we were able to pivot our product. Through the meetings LeadrPro generated, we took the new product to $1MM in ARR in less than 6 months and closed a $16MM Series A shortly after. They got us the 'at-bats' we sorely needed to make the hard but necessary decisions"
      });

      _list.push({
        name: "Joany",
        logo: logo_joany,
        text: "LeadrPro helped us grow from just under 2,000 customers to over 200,000 in less than a year and half"
      });

      setTestimonials(_list);
      setCurrentTestimonial(_list[0]);
    }

    function loadEnterpriseLogos(){
      let _list = [];

      _list.push(logo_enterprise_accenture);
      _list.push(logo_enterprise_adp);
      _list.push(logo_enterprise_atlassian);
      _list.push(logo_enterprise_benchmark);
      _list.push(logo_enterprise_carvana);
      _list.push(logo_enterprise_docusign);
      _list.push(logo_enterprise_expedia);
      _list.push(logo_enterprise_facebook);
      _list.push(logo_enterprise_gusto);
      _list.push(logo_enterprise_hungryroot);
      _list.push(logo_enterprise_invesco);
      _list.push(logo_enterprise_keymortgage);
      _list.push(logo_enterprise_lathamandwatkins);
      _list.push(logo_enterprise_lexisnexis);
      _list.push(logo_enterprise_lyft);
      _list.push(logo_enterprise_riteaid);
      _list.push(logo_enterprise_servicetitan);
      _list.push(logo_enterprise_shift);
      _list.push(logo_enterprise_sonos);
      _list.push(logo_enterprise_transunion);
      _list.push(logo_enterprise_travelers);

      setEnterpriseLogos(_list);
    }

    function loadMidMarketLogos(){
      let _list = [];

      _list.push(logo_midmarket_asana);
      _list.push(logo_midmarket_wholesystems);
      _list.push(logo_midmarket_bombfell);
      _list.push(logo_midmarket_touchofmodern);
      _list.push(logo_midmarket_wrike);
      _list.push(logo_midmarket_brex);
      _list.push(logo_midmarket_carta);
      _list.push(logo_midmarket_chargebee);
      _list.push(logo_midmarket_clari);
      _list.push(logo_midmarket_promenade);
      _list.push(logo_midmarket_segment);
      _list.push(logo_midmarket_texada);
      _list.push(logo_midmarket_premisehealth);
      _list.push(logo_midmarket_theorem);
      _list.push(logo_midmarket_tradesy);

      setMidMarketLogos(_list);
    }

    function loadSmbFuneralLogos(){
      let _list = [];

      _list.push(logo_smb_funeral_busselfamilyfuneral);
      _list.push(logo_smb_funeral_strawser);
      _list.push(logo_smb_funeral_wingate);

      setFuneralLogos(_list);
    }

    function loadSmbGeneralLogos(){
      let _list = [];

      _list.push(logo_smb_general_aspen);
      _list.push(logo_smb_general_ckc);
      _list.push(logo_smb_general_la);

      setGeneralLogos(_list);
    }

    function loadSmbGymLogos(){
      let _list = [];

      _list.push(logo_smb_gym_crossfit);
      _list.push(logo_smb_gym_livefree);
      _list.push(logo_smb_gym_peek);

      setGymLogos(_list);
    }

    function loadSmbInsuranceLogos(){
      let _list = [];

      _list.push(logo_smb_insurance_ssgi);
      _list.push(logo_smb_insurance_thearizona);

      setInsuranceLogos(_list);
    }

    function loadSmbPediatricianLogos(){
      let _list = [];

      _list.push(logo_smb_pedia_childrenshospital);
      _list.push(logo_smb_pedia_osf);
      _list.push(logo_smb_pedia_valley);

      setPediatriciansLogos(_list);
    }

    function loadSmbRentalLogos(){
      let _list = [];

      _list.push(logo_smb_rental_green);
      _list.push(logo_smb_rental_julian);
      _list.push(logo_smb_rental_sea);

      setRentalLogos(_list);
    }

    loadOurClients();
    loadEnterpriseLogos();
    loadMidMarketLogos();
    loadSmbFuneralLogos();
    loadSmbGeneralLogos();
    loadSmbGymLogos();
    loadSmbInsuranceLogos();
    loadSmbPediatricianLogos();
    loadSmbRentalLogos();
    loadTestimonials();
  }, []);

  function testimonialLeftClick(){
    let index = currentTestimonialIndex;

    index -= 1;

    if (index < 0)
    {
      index += testimonials.length;
    }

    setCurrentTestimonialIndex(index);
  }

  function testimonialRightClick(){
    let index = currentTestimonialIndex;

    index += 1;

    if (index >= testimonials.length){
      index -= testimonials.length;
    }

    setCurrentTestimonialIndex(index);
  }

  useEffect(() => {
    setCurrentTestimonial(testimonials[currentTestimonialIndex]);
  }, [currentTestimonialIndex]);

  useEffect(() => {
    //setCostPerSql(parseInt(acv * 0.1 * conversion));
    setCostPerSql(parseInt(((acv / 12) * paybackPeriod) * (conversion / 100)));

    if (costpersql > 0)
      document.getElementById('calculator__savings').style.display =
        'inline-block';
  }, [acv, conversion, paybackPeriod]);

  useEffect(() => {
    getPersonaTypes('Buyer').then((result) => {
      if (result.success) {
        setPersonaTypes(result.body);
      }
    });
  }, []);

  function getAlpha() {
    let alpha = 'abcdefghijklmnopqrstuvwxyz';
    let res = Array.from(alpha).map((item, index) => {
      return (
        <span key={index}>
          <Link
            to={{
              pathname: '/search/buyers',
              search: '?lett=' + item,
            }}>
            <span>{item.toUpperCase()}</span>
          </Link>
        </span>
      );
    });

    return res;
  }

  async function handleSliderChange(e) {
    setConversion(e.target.value);
  }

  async function handleSliderChange2(e) {
    setPaybackPeriod(e.target.value);
  }

  async function handleEnterpriseClick(){
    setshowSmb(false);
    setSelectedLogos("Enterprise");
  }

  async function handleMidMarketClick(){
    setshowSmb(false);
    setSelectedLogos("MidMarket")
  }

  async function handleSmbClick(){
    setshowSmb(!showSmb);
    setSelectedLogos("Funeral");
  }

  return (
    <div className='home'>
      <Helmet>
        <meta name='description' content='' />
      </Helmet>

      <section className='home__hero'>
        <div className="home__section-content">
        <div className='home__hero-left-side'>
          <div className='home__hero-text--large'>#1 Software Marketplace</div>

          <div className='home__hero-text--medium'>
            Connecting buyers with sellers of software<br />
            solutions <span className="home__highlight">instantly</span>
          </div>

          <div className="home__iam">
            <div><a href="/signup" className="btn btn--action home__btn-buyer">Sign up</a></div>
          </div>

          <div className="home__trustedby">
            <div>Trusted by companies backed by</div>

            <div style={{marginTop: "15px"}}>
              <img src={logos} alt="" />
            </div>
          </div>

          {/* <div className='home__hero-category'>
            <img
              src={searchicon}
              alt=''
              className='icon home__hero-category-icon'
            />
            <input
              className='input home__hero-input'
              value={query}
              type='text'
              id='txtCategory'
              onChange={handleChange}
              name='category'
              placeholder="Type software category you're looking for"
            />

            {results.length > 0 && (
              <div className='home__hero-category-results'>
                <h3>Categories</h3>

                <div style={{ marginTop: '20px', marginBottom: '30px' }}>
                  {results.map((item, index) => (
                    <div className='home__hero-category-results-item' key={index}>
                      <Link
                        to={{
                          pathname: '/search/sellers',
                          search:
                            '?category_name=' +
                            encodeURIComponent(encodeURIComponent(item.name)) +
                            '&category_id=' +
                            encodeURIComponent(
                              encodeURIComponent(item.category_id)
                            ),
                        }}>
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>

                {resultsCompany.length > 0 && (
                  <>
                    <h3 style={{marginTop:"20px"}}>Companies</h3>

                    <div style={{ marginTop: '20px' }}>
                      {resultsCompany.map((item, index) => (
                        <div className='home__hero-category-results-item' key={index}>
                          <Link
                            to={{
                              pathname: '/company/' + item.company_id,
                            }}>
                            {item.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div> */}
        </div>

        <div className='home__hero-right'>
          <div className='home__hero-images'>
            <img
              src={screen_hero}
              className='home__hero-image'
              alt=''
            />
          </div>
        </div>
        </div>
      </section>

      <section className="home__ourclients">
      <div className="home__section-content">
        <h3>Our Clients</h3>

        <div className="home__ourclients-list">
        {clients.map(item => (
          <div className="home__ourclients-item">
            <div className="home__ourclients-logo"><img src={item.logo} alt="" /></div>
            <div className="home__ourclients-description">{item.description}</div>
          </div>
        ))}
        </div>
        </div>
      </section>

      <section className="home__forbuyers home__hero">
      <div className="home__section-content">
      <div className='home__hero-left-side'>
          <h3>For Buyers</h3>

          <div className='home__hero-text--medium'>
            Get paid to evaluate the latest software<br/>
            for your business
          </div>

          <div style={{marginTop: "30px"}}>
            <div><a href="/signup" className="btn home__btn-signup">Sign up</a></div>
          </div>

          <div style={{marginTop: "30px"}}>
            <div>Choose your preferred payout method</div>

            <div className="home__forbuyers-payment">
              <img src={payment} alt="" />
            </div>
          </div>
        </div>

        <div className='home__hero-right'>
          <div className='home__hero-images'>
            <img
              src={screen_buyers}
              className='home__hero-image'
              alt=''
            />
          </div>
        </div>
        </div>
      </section>

      <section className='home__features'>
      <div className="home__section-content">
        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_earn} alt='earn' />
            <span className='home__feature-name'>Earn</span>
          </div>
          <div className='home__feature-text'>
            Get paid to sit through product demonstrations while learning about
            the latest innovations. Keep the cash or donate to your favorite
            charity.
          </div>
        </div>

        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_research} alt='research' />
            <span className='home__feature-name'>Research</span>
          </div>
          <div className='home__feature-text'>
            See what tech stacks other companies similar in size and stage are
            using across their departments by seeing reviews and recommendations
            that are most relevant to you.
          </div>
        </div>

        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_bookdemos} alt='book' />
            <span className='home__feature-name'>Book Demos</span>
          </div>
          <div className='home__feature-text'>
            {' '}
            Instantly book a demo with a Saas company and receive AI driven
            recommendations for similar products that might be a good fit for
            you based on your company's needs. Book all your product demos in
            minutes.
          </div>
        </div>
        </div>
      </section>

      <section className="home__forsellers home__hero">
      <div className="home__section-content">
        <div className='home__hero-right'>
          <div className='home__hero-images'>
            <img
              src={screen_sellers2}
              className='home__hero-image'
              alt=''
            />
          </div>
        </div>

        <div className='home__hero-left-side home__forsellers-right'>
          <h3>For Sellers</h3>

          <div className='home__hero-text--medium'>
            Book demos with prospects and fill your pipeline <span className="home__highlight">instantly</span>.
          </div>

          <div style={{marginTop: "30px"}}>
            <div><a href="/search/buyers" className="btn home__btn-bookdemo">Book Demo</a></div>
          </div>
        </div>
        </div>
      </section>

      <section className='home__features' style={{background: "#F9F9F9"}}>
      <div className="home__section-content">
        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_instant} alt='' />
            <span className='home__feature-name'>Instant</span>
          </div>
          <div className='home__feature-text'>
            Fill your sales pipeline by booking product demos with decision
            makers, influencers, champions, end-users or implementers that fall
            within your ICP, all at the click of a button.
          </div>
        </div>

        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_visibility} alt='' />
            <span className='home__feature-name'>Gain Visibility</span>
          </div>
          <div className='home__feature-text'>
            View the stats and metrics of how you are converting from completed
            demos to customers. Additionally view a breakdown of categorized
            objections so you know how to prioritize your company's product
            roadmap or generate rebuttals for your sales teams.
          </div>
        </div>

        <div className='home__feature'>
          <div className='home__feature-icon'>
            <img src={icon_marketfit} alt='' />
            <span className='home__feature-name'>Market Fit</span>
          </div>
          <div className='home__feature-text'>
            Know with certainty how your product stacks up in the market with
            our proprietary Fit Score.
          </div>
        </div>
        </div>
      </section>

      <section className='home__hero home__bookeddemos'>
      <div className="home__section-content">
        <h3>LeadrPro clients have booked demos with</h3>

        <div className="home__bookeddemos-menu">
          <span className={selectedLogos === "Enterprise" ? "selected" : ""} onClick={() => handleEnterpriseClick()}>Enterprise</span>
          <span className={selectedLogos === "MidMarket" ? "selected" : ""} onClick={() => handleMidMarketClick()}>Mid-Market</span>
          <span className={showSmb ? "selected" : ""} onClick={() => handleSmbClick()}>SMB's</span>

          {showSmb && (
          <div className="home__bookeddemos-menu-2">
            <span className={selectedLogos === "Funeral" ? "selected" : ""} onClick={() => setSelectedLogos("Funeral")}>Funeral Homes</span>
            <span className={selectedLogos === "General" ? "selected" : ""} onClick={() => setSelectedLogos("General")}>General Contractors</span>
            <span className={selectedLogos === "Gym" ? "selected" : ""} onClick={() => setSelectedLogos("Gym")}>Gym Owners</span>
            <span className={selectedLogos === "Insurance" ? "selected" : ""} onClick={() => setSelectedLogos("Insurance")}>Insurance Brokers</span>
            <span className={selectedLogos === "Pedia" ? "selected" : ""} onClick={() => setSelectedLogos("Pedia")}>Pediatricians</span>
            <span className={selectedLogos === "Rental" ? "selected" : ""} onClick={() => setSelectedLogos("Rental")}>Short Term Rental Owners</span>
          </div>
          )}
        </div>

        <div className="home__bookeddemos-logos">
          {selectedLogos === "Enterprise" && enterpriseLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

          {selectedLogos === "MidMarket" && midMarketLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

          {selectedLogos === "Funeral" && funeralLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

          {selectedLogos === "General" && generalLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

        {selectedLogos === "Gym" && gymLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

        {selectedLogos === "Insurance" && insuranceLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

        {selectedLogos === "Pedia" && pediatricianLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}

        {selectedLogos === "Rental" && rentalLogos.map(item => (
            <div>
              <img src={item} alt="" />
            </div>
          ))}
        </div>
        </div>
      </section>

      <section className='home__hero calculator' style={{paddingTop: "100px"}}>
      <div className="home__section-content">
        <div className='home__hero-left-side'>
          <h3>Calculate your ROI</h3>

          <div className='form-group'>
            <label className='calculator__label'>Enter ACV $</label>

            <div style={{ marginTop: '10px' }}>
              <span className='calculator__acv'>$</span>
              <input
                type='text'
                placeholder='0'
                value={acv}
                className='calculator__acv'
                onChange={(event) => setAcv(event.target.value)}></input>
            </div>
          </div>

          <div className='form-group'>
            <label className='calculator__label'>
              Enter Conv % from SQL to customer
            </label>
            <div>
              <div className='calculator__slider-value'>{conversion}%</div>
              <input
                type='range'
                min='1'
                max='100'
                value={conversion}
                className='calculator__slider'
                id='myRange'
                onChange={handleSliderChange}
                onBlur={handleSliderChange}
              />
            </div>
          </div>

          <div className='form-group'>
            <label className='calculator__label'>
              Enter payback period
            </label>
            <div>
              <div className='calculator__slider-value'>{paybackPeriod} months</div>
              <input
                type='range'
                min='1'
                max='24'
                value={paybackPeriod}
                className='calculator__slider'
                id='paybackPeriod'
                onChange={handleSliderChange2}
                onBlur={handleSliderChange2}
              />
            </div>
          </div>

          <div className='form-group calculator__sql'>
            <label className='calculator__label--bold'>Cost per SQL</label>

            <div className='calculator__result'>
              <span className='calculator__cost'>${costpersql.toLocaleString()}</span>

              <span className='calculator__savings' id='calculator__savings'>
              Save 50% or more per SQL with LeadrPro
              </span>
            </div>
          </div>
        </div>

        <div className='home__hero-right' style={{padding: "30px"}}>
          <div className='home__hero-images'>
            <img
              src={screen_roi}
              className='home__hero-image'
              alt=''
            />
          </div>
        </div>
        </div>
      </section>

      <section className='home__directories'>
      <div className="home__section-content">
        <h3>Browse LeadrPro's Directories</h3>

        <div className='home__alphabets'>
          <div>
            <h4>Persona Search</h4>

            <div className='home__persona-types'>
              {personaTypes.map((item, index) => (
                <div key={index}>
                  <Link
                    to={{
                      pathname: '/search/buyers',
                      search: '?persona=' + item.persona_type_id,
                    }}>
                    <img
                      src={`/assets/personatypes/${item.icon_image}`}
                      alt=''
                    />
                  </Link>

                  <div className='home__persona-type-label'>{item.name}</div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4>Company Search</h4>

            <div className='home__alphabet'>{getAlpha()}</div>
          </div>
        </div>
        </div>
      </section>

      <section className="home__ourclients">
      <div className="home__section-content">
        <h3>Testimonials</h3>

        <div className="home__testimonials">
          <div className="home__testimonials-leftarrow">
              <img src={icon_leftarrow} onClick={() => testimonialLeftClick()} alt="" />
          </div>

          {testimonials.map((item, index) => (
            <div className="home__testimonial" style={{display: index === currentTestimonialIndex ? "block" : "none"}}>
                <div className="home__testimonial-company">
                  <img src={item.logo} alt="" />
                  <span>{item.name}</span>
                </div>

                <div className="home__testimonial-text">
                  "{item.text}"
                </div>
            </div>
          ))}
          
          <div className="home__testimonials-rightarrow">
            <img src={icon_rightarrow} alt="" onClick={() => testimonialRightClick()} />
          </div>
        </div>
        </div>
      </section>

      <section className="home__sponsors">
      <div className="home__section-content">
        <h3 style={{color: "white"}}>LeadrPro proudly sponsors</h3>

        <div className="home__sponsors-list">
          <a href="https://www.youtube.com/c/PodSaas" target="_blank">
          <div className="home__sponsor">
            <img className="home__sponsor-image" src={content1} alt="" />
            <img className="home__sponsor-image-hover" src={content1b} alt="" />
          </div>
          </a>

          <a href="https://www.youtube.com/c/HowToStartAStartup" target="_blank">
          <div className="home__sponsor">
            <img className="home__sponsor-image" src={content2} alt="" />
            <img className="home__sponsor-image-hover" src={content2b} alt="" />
          </div>
          </a>
        </div>
        </div>
      </section>

      {/* <section className="home__ourclients">
        <h3>LeadrPro clients have booked demos with</h3>

        <div className="home__ourclients-list">
        {clients.map(item => (
          <div className="home__ourclients-item">
            <div className="home__ourclients-logo"><img src={item.logo} alt="" /></div>
            <div className="home__ourclients-description">{item.description}</div>
          </div>
        ))}
        </div>
      </section> */}

      {/* <section
        className='home__trusted'
        data-aos='fade-zoom-in'
        data-aos-offset='200'
        data-aos-easing='ease-in-sine'
        data-aos-duration='600'>
        <div className='home__trusted-copy'>Trusted by Companies backed by</div>

        <div className='home__trusted-companies'>
          <div>
            <img src={company1} alt='mucker capital' />
          </div>

          <div>
            <img src={company2} alt='foundation capital' />
          </div>

          <div>
            <img src={company3} alt='BAM Ventures' />
          </div>
        </div>
      </section> */}

      {/* <section className='home__hero'>
        <div className='home__hero-left-side'>
          <div className='home__hero-text--large'>
            Pay Only For Completed
            <br />
            Meetings With
          </div>

          <div className='home__hero-text--medium'>
            Decision Makers, Champions, and Influencers within your target ICPs
          </div>
        </div>

        <div className='home__hero-right'>
          <div className='home__hero-images'>
            <img
              src={heroscreenshot3}
              className='home__hero-image--back'
              alt=''
            />
            <img
              src={heroscreenshot4}
              className='home__hero-image--front'
              alt=''
            />
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Home2;
