import './LeadCard.css';
import { useState, useEffect } from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { sellerConfirmsDemo, sellerReschedule, checkPromoCode, refreshToken, getMeetingLinkStatus, addUserTestimonial, getPackageItemsRemainingCount, getDemoAvailableTimes, getUserCompany, getDemoForLeadCard, checkHasPaymentMethod, getPaymentType } from '../../utils/api';
import CalendarLinks from './CalendarLinks';
import FadeIn from '../../utils/fadein';
import Spinner from '../layout/Spinner';
import React from 'react';
import { Switch } from '@chakra-ui/react';
import starNotFilled from "../../assets/design/review/star-not-filledv2.svg";
import starFilled from "../../assets/design/review/star-filled.svg";
import { FaRegCalendarCheck, FaTimes } from 'react-icons/fa';
import { refresh, getCurrentUser } from '../../services/auth.service';

function LeadCard({demo_id, demos, setDemos, currentDemo, setShowQualifierAnswers, show_buttons = true, payment_type = 'pay_later', refreshRemaining}) {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [showTimes, setShowTimes] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showRescheduleConfirmation, setShowRescheduleConfirmation] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [promoLoaded, setPromoLoaded] = useState(false);
  const [invalidPromo, setInvalidPromo] = useState(false);
  const [promo, setPromo] = useState(null);
  const [selectedTimeId, setSelectedTimeId] = useState(0);
  const [selectedTime, setSelectedTime] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [useCustomLink, setUseCustomLink] = useState(false);
  const [customMeetingLink, setCustomMeetingLink] = useState('');
  const [meetingLinkEnabled, setMeetingLinkEnabled] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [userCompany, setUserCompany] = useState(null);
  const [paymentType,setPaymentType] = useState('pay_now');
  const [data, setData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  //const [paymentType, setPaymentType] = useState('');
  const [paymentTypeLoaded, setPaymentTypeLoaded] = useState(false);
  const user = getCurrentUser();
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  let refPromo = React.createRef();
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  useEffect(async () => {
    async function loadLeadCard(){
      let res = await getDemoForLeadCard(demo_id);

      if (res.success){
        setData(res.data);
        setDataLoaded(true);
      }
    }

    async function loadPaymentMethod() {
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
      } 

      setPaymentMethodLoaded(true);
    }

    async function loadPaymentType() {
      let res = await getPaymentType();

      if (res.success) {
        setPaymentType(res.data);
      } 

      setPaymentTypeLoaded(true);
    }

    async function loadRemainingCount(){
      let res = await getPackageItemsRemainingCount();
  
      if (res.success){
        setRemainingCount(res.data);
        setRemainingCountLoaded(true);
      }
    }

    // async function loadUserCompany(){
    //   let res = await getUserCompany();

    //   if (res.success){
    //     setUserCompany(res.data);
    //     setPaymentType(res.data.payment_type);
    //   }
    // }

    async function loadMeetingStatus(){
      let res = await getMeetingLinkStatus();

      if (res.success){
        if (res.data == 'Enabled')
          setMeetingLinkEnabled(true);
        else
          setMeetingLinkEnabled(false);
      }

      else{
        setMeetingLinkEnabled(false);
      }
    }

    async function loadAvailableTimes(){
      let res = await getDemoAvailableTimes(currentDemo.demo_id);
      setAvailableTimes(res.data);
    }

    await loadLeadCard();
    await loadPaymentMethod();
    await loadPaymentType();
    await loadRemainingCount();
    await loadAvailableTimes();
    await loadMeetingStatus();
    //await loadUserCompany();
  }, []);

  async function handleAccept() {
    setShowTimes(true);
  }

  async function handleConfirm() {
    if (selectedTimeId === null || selectedTimeId === 0) {
      alert('Must select a time in order to book this demo');
      return;
    }

    if (!isProcessing) {
      setIsProcessing(true);
    } else {
      return;
    }

    setSubmitting(true);

    let res = await sellerConfirmsDemo(currentDemo.demo_id, selectedTime, selectedTimeId, customMeetingLink);

    if (res.success){
      setShowTimes(false);
      setShowConfirmation(true);

      let _filter = demos.filter((tmp) => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);

      _obj.seller_status = 'Approved';
      _obj.scheduled_date_time = selectedTime;
      _filter.push(_obj);
      setDemos(_filter);
      setIsProcessing(false);
      setSubmitting(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await sellerConfirmsDemo(currentDemo.demo_id, selectedTime, selectedTimeId, customMeetingLink);

      if (res.success){
        setShowTimes(false);
        setShowConfirmation(true);

        let _filter = demos.filter((tmp) => tmp.demo_id !== currentDemo.demo_id);
        let _obj = Object.assign({}, currentDemo);

        _obj.seller_status = 'Approved';
        _obj.scheduled_date_time = selectedTime;
        _filter.push(_obj);
        setDemos(_filter);
        setIsProcessing(false);
        setSubmitting(false);
      }
    }
  }

  function handleTimeChange(item) {
    setSelectedTimeId(item.demo_available_time_id);
    setSelectedTime(item.available_time);
  }

  async function confirmReschedule() {
    let res = await sellerReschedule(currentDemo.demo_id);

    if (res.success){
      let _filter = demos.filter((tmp) => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);
      _obj.seller_status = 'Rescheduled';
      _filter.push(_obj);
      setDemos(_filter);

      setShowTimes(false);
      setShowReschedule(false);
      setShowRescheduleConfirmation(true);
      setShowQualifierAnswers(false);
    }
  }

  function handleClose() {
    setShowConfirmation(false);
    setShowQualifierAnswers(false);
    setShowTimes(false);

    if (refreshRemaining){
      refreshRemaining();
    }
  }

  async function handleSwitchToggle(){
    setUseCustomLink(!useCustomLink);
  }

  function handleFeedbackClick(i){
    setFeedbackValue(i);
  }

  function renderStars(){
    let arr = [];
    let _value = feedbackValue;

    for (let i = 0; i < 5; i++){
      if (i <= _value){
        arr.push(<span onClick={() => handleFeedbackClick(i) }><img src={starFilled} className="review__star-filled" /></span>);
      }

      else{
        arr.push(<span onClick={() => handleFeedbackClick(i) }><img src={starNotFilled} className="review__star-not-filled" /></span>);
      }
    }

    return (
      <div className='dashboarddemo__star'>
        {arr}
      </div>
    )
  }

  async function handleSubmitFeedback(){
    setSubmittingFeedback(true);
    if (feedbackText.length > 0 || feedbackValue > 0){
      let res = await addUserTestimonial(feedbackValue + 1, feedbackText);

      setSubmittingFeedback(false);
      setFeedbackSuccess(true);
    }

    setFeedbackSuccess(true);
  }

  return (
    <div className='modal__leadcard modal modal--pad0'>
      <div className='modal__body'>
        <div className='leadcard__header'>
          {!showConfirmation && (
            <div className='leadcard__header-title'>
              <div className='dashboarddemo__company-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' +
                    currentDemo.logo
                  } alt='' />
              </div>

              <div className='leadcard__company-name'>
                {currentDemo.job_title && currentDemo.job_title.length > 0
                  ? currentDemo.job_title
                  : currentDemo.persona_type}
                <br />
                at {currentDemo.company_name}
              </div>
            </div>
          )}
        </div>

        <div className='leadcard__body'>
          {!showConfirmation && (
            <div className='leadcard__properties'>
              <div>
                {currentDemo.department &&
                  currentDemo.department.length > 0 && (
                    <div className='leadcard__property'>
                      <strong className='leadcard__property-header'>
                        Department
                      </strong>
                      <div>{currentDemo.department}</div>
                    </div>
                  )}

                {currentDemo.seniority_level && (
                  <div className='leadcard__property'>
                    <strong className='leadcard__property-header'>
                      Seniority Level
                    </strong>
                    <div>{currentDemo.seniority_level}</div>
                  </div>
                )}

                <div className='leadcard__property'>
                  <strong className='leadcard__property-header'>
                    Job Title
                  </strong>
                  <div>{currentDemo.job_title}</div>
                </div>

                {currentDemo.company_size && (
                  <div className='leadcard__property'>
                  <strong className='leadcard__property-header'>
                    Company Size
                  </strong>
                  <div>{currentDemo.company_size}</div>
                </div>
                )}

                {currentDemo.country_name && (
                  <div className='leadcard__property'>
                    <strong className='leadcard__property-header'>
                      Location
                    </strong>
                    <div>{currentDemo.country_name}</div>
                  </div>
                )}

                {currentDemo.timezone && (
                  <div className='leadcard__property'>
                    <strong className='leadcard__property-header'>
                      Timezone
                    </strong>
                    <div>{currentDemo.timezone}</div>
                  </div>
                )}

                {currentDemo.website && (
                  <div className='leadcard__property'>
                    <strong className='leadcard__property-header'>
                      Company Website
                    </strong>
                    <div>
                      <a href={currentDemo.website.startsWith('http')
                            ? currentDemo.website
                            : 'https://' + currentDemo.website}
                        target='_blank'
                        className='link'
                        rel='nofollow'>
                        {currentDemo.website}
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {currentDemo.linkedin_url && (
                <>
                  <div className='leadcard__property'>
                    <a href={currentDemo.linkedin_url.startsWith('http')
                          ? currentDemo.linkedin_url
                          : 'https://' + currentDemo.linkedin_url
                      }
                      target='_blank'
                      className='link'>
                      LinkedIn Profile
                    </a>
                  </div>
                </>
              )}

              {currentDemo.companyDescription &&
                currentDemo.company_description.length > 0 && (
                  <div className='leadcard__property'>
                    <strong className='leadcard__property-header'>
                      Company description
                    </strong>
                    <div>{currentDemo.company_description}</div>
                  </div>
                )}
            </div>
          )}

          {showTimes === false && !showConfirmation && (
            <>
            <div className='leadcard__answers'>
              {currentDemo.qualifier_answers.length === 0 && (currentDemo.is_invite == false || currentDemo.is_invite == undefined || currentDemo.is_invite == null) && (
                <div style={{ marginTop: '10px' }}>
                  <i>No qualification answers available</i>
                </div>
              )}

              {currentDemo.is_invite == true && (
                <div style={{ marginTop: '10px' }}>
                  <div className='leadcard__qualifier-question'>
                    {currentDemo.invite_question}
                  </div>

                  <div className='leadcard__invite-answer'>
                    {currentDemo.invite_answer}
                  </div>
                </div>
              )}

              {currentDemo.qualifier_answers.length > 0 && currentDemo.qualifier_answers.filter(x => x.answer && x.answer.length > 0).map((item, index) => (
                <div className='leadcard__qualifier' key={index}>
                  <div className='leadcard__qualifier-question'>
                    {index + 1}. {item.question}
                  </div>
                  <div className='leadcard__qualifier-answer'>
                    {item.answer}
                  </div>
                </div>
              ))}

              {show_buttons && user.registered_user_id != currentDemo.seller_id && (
                <div>
                  <i>Demo must be assigned to you to approve.</i>
                </div>
              )}

              {show_buttons && user.registered_user_id == currentDemo.seller_id && (
                  <div className='leadcard__btns'>
                    {!showTimes && !showConfirmation && (
                      <>
                      {(paymentMethodLoaded == false || remainingCountLoaded == false && (
                        <Spinner />
                      ))}
                        {paymentMethodLoaded && payment_type === 'pay_later' && hasPaymentMethod && (
                          <>
                            <div>
                              <button
                                className='btn btn--primary btn--dark'
                                onClick={() => handleAccept()}>
                                Step 2: Confirm a time
                              </button>
                            </div>
                          </>
                        )}

                      {paymentMethodLoaded && payment_type === 'pay_now' && remainingCount > 0 && remainingCountLoaded == true && (
                        <>
                          <div>
                            <button
                              className='btn btn--primary btn--dark'
                              onClick={() => handleAccept()}>
                              Step 2: Confirm a time
                            </button>
                          </div>
                        </>
                        )}

                        {paymentMethodLoaded && payment_type === 'pay_later' && !hasPaymentMethod &&  (
                          <span>
                            <Link className='btn btn--payment' to={'/account/payment-method'}>Enter Payment Method To Book</Link>
                          </span>
                        )}

                        {paymentMethodLoaded && payment_type === 'pay_now' && remainingCount == 0 && remainingCountLoaded && (
                          <span>
                            <Link className='btn btn--payment' to={'/select-plan'}>
                              Purchase Credits to Book This Demo
                            </Link>
                          </span>
                        )}
                      </>
                    )}
                  </div>
                    )}
            </div>
            </>
          )}

          {showTimes && showConfirmation === false && (
            <div className='leadcard__available-times'>
              <div className='modal__header'>
                <div style={{fontSize: "23px"}}>
                  Please select a date and time:
                </div>
                <div className='leadcard__subtext'
                  style={{
                    fontSize: '12px',
                    fontStyle: 'italic',
                    fontWeight: 'normal',
                    marginTop: '5px',
                  }}>
                  (* Times are shown in your current timezone.)
                </div>
              </div>

              <div>
                {/* {currentDemo.demo_available_times.map((time) => (
                  <div className='leadcard__available-time'>
                    <input id={`dt_${time.demo_available_time_id}`} type='radio' name='available_time' onChange={() => handleTimeChange(time)}
                    />{' '}
                    <label htmlFor={`dt_${time.demo_available_time_id}`}>
                    {weekDays[new Date(time.available_time).getDay()]}{' '}

                    {new Date(time.available_time).toLocaleString(undefined, options)}
                    </label>
                  </div>
                ))} */}

              {availableTimes.map((time, index) => (
                  <div key={index} className='leadcard__available-time'>
                    <input id={`dt_${time.demo_available_time_id}`} type='radio' name='available_time' onChange={() => handleTimeChange(time)}
                    />{' '}
                    <label htmlFor={`dt_${time.demo_available_time_id}`}>
                    {weekDays[new Date(time.available_time).getDay()]}{' '}

                    {new Date(time.available_time).toLocaleString(undefined, options)}
                    </label>
                  </div>
                ))}
              </div>

              <div className='leadcard__no-problem'>
                <div>
                  <strong>None of these times work?</strong>
                </div>

                <div>
                  You can request that this person submit new times.
                </div>

                <div className='mt-10'>
                  <button className='link' onClick={() => setShowReschedule(true)}>Send Request For New Times</button>
                </div>
              </div>

              {(currentDemo.seller_company_id && (currentDemo.seller_company_id == '375' || currentDemo.seller_company_id == '3270' || currentDemo.seller_company_id == '4520' || currentDemo.seller_company_id == '12858' || currentDemo.seller_company_id == '12520' || meetingLinkEnabled === true)) && (
              <div className='leadcard__custom-link'>
                <div display='flex' alignItems='center'>
                  <img src='https://ik.imagekit.io/ivgrhmd4k/path33305.svg?updatedAt=1684608692669' style={{height: "20px", verticalAlign: "middle"}} />

                  <label htmlFor='email-alerts' mb='0'>
                    Use Custom Meeting URL
                  </label>
                  <Switch id='email-alerts' onChange={() => handleSwitchToggle()} />
                </div>

                {useCustomLink && (
                  <div style={{marginTop: "10px"}}>
                    <input type='text' className='input' placeholder='Custom meeting URL (https://www...)' value={customMeetingLink} onChange={(e) => setCustomMeetingLink(e.target.value)} />
                    <span style={{fontSize: "10px"}}>* Zoom, Google Meets, etc</span>
                  </div>
                )}
              </div>
              )}

              {showTimes && !showConfirmation && show_buttons && (
                  <div style={{marginTop: '20px'}}>
                    {submitting === false && (
                    <button
                      className='btn btn--primary btn--dark'
                      onClick={() => handleConfirm()} style={{backgroundColor: "mediumaquamarine", width: '100%'}}>
                      Book This Demo
                    </button>
                    )}

                    {submitting && (
                      <div className='center'>
                        <Spinner />
                      </div>
                    )}

                    {/* <div style={{fontSize: 'x-small', fontStyle: 'italic', marginTop: "5px"}}>
                    * By approving this demo you are agreeing to pay a $25 cancelation fee (at LeadrPro’s discretion) if you later cancel. Rescheduling is allowed.
                    </div> */}
                  </div>
                )}
            </div>
          )}

          {showRescheduleConfirmation && (
            <>
              <div className='overlay'></div>
              <div className='modal'>
                <p>Request has been sent. Hang tight!</p>

                <p className='center mt-30'>
                  <button
                    className='btn btn--primary'
                    onClick={() => setShowRescheduleConfirmation(false)}>
                    Close
                  </button>
                </p>
              </div>
            </>
          )}

          {showReschedule && (
            <>
              <FadeIn delay={150} duration={200}>
                <div className='overlay'></div>

                <div className='modal'>
                  <h2 className='modal__h2'>Confirm reschedule?</h2>

                  <div className='center mt-20'>
                    Are you sure you want to reschedule this demo? The original
                    date will no longer be valid and the other participant will
                    be required to submit new available times.
                  </div>

                  <div className='center mt-20'>
                    <button
                      className='btn btn--primary btn--dark'
                      onClick={() => confirmReschedule()}>
                      Confirm
                    </button>
                  </div>

                  <div className='modal__close' onClick={() => setShowReschedule(false)}>
                    <FaTimes />
                  </div>
                </div>
              </FadeIn>
            </>
          )}

          {showConfirmation && (
            <div className='leadcard__confirmation'>
              <div className='leadcard__confirmation-left'>
              <div className='leadcard__confirmation-header'><FaRegCalendarCheck /></div>

              <div className='modal__header' style={{fontSize: '16px'}}>
                You have successfully booked a demo with{' '}
                {currentDemo.job_title} from {currentDemo.company_name}.
              </div>

              <div className='' style={{fontSize: '16px'}}>
                Your demo is scheduled for{' '}
                <span className='dashboarddemo__highlight'>
                  {weekDays[new Date(selectedTime).getDay()]}{' '}
                  {new Date(selectedTime).toLocaleString(undefined, options)}
                </span>
              </div>

              <CalendarLinks
                persona_type={currentDemo.company_name}
                reference_id={currentDemo.reference_id}
                scheduled_date_time={selectedTime}
              />

              <div className='dashboarddemo__calendar-reminder'>
                <div>
                  * Reminder! Add this event to your Google calendar to avoid missing your time.
                </div>
              </div>
              </div>

              <div className='leadcard__confirmation-right'>
                <h2 className='modal__h2'>Tell us how we're doing!</h2>

                {feedbackSuccess === false && (
                  <>
                    <div className='leadcard__confirmation-stars'>
                      {renderStars()}
                    </div>

                    <div className='leadcard__feedback-text'>
                      <textarea className='textarea form-control' placeholder='Leave us feedback...' value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)}></textarea>
                    </div>
                  </>
                )}

                <div className='leadcard__confirmation-submit'>
                  {feedbackSuccess === false && submittingFeedback === false && (
                  <button className='btn btn--primary' onClick={() => handleSubmitFeedback()}>Submit Feedback</button>
                  )}

                  {submittingFeedback && (
                    <Spinner />
                  )}

                  {feedbackSuccess && (
                    <>
                      <div>Thank you for your feedback!</div>
                      <div>It helps us improve our products and services.</div>
                    </>
                  )}

                  <div className='mt-30'>
                    <span className='link link--cancel' onClick={() => handleClose()}>close</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='modal__close' onClick={() => setShowQualifierAnswers(false)}>
          <FaTimes />
        </div>
      </div>
    </div>
  );
}

export default LeadCard;
