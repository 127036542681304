import './BuyerRecommend.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getBuyerRecommendations, addBuyerRecommendationStatus } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaTimes, FaCheck, FaBookmark } from 'react-icons/fa';
import star_selected from '../../assets/design/rating-star-selected.png';
import star_deselect from '../../assets/design/rating-star-unselected.png';
import ScrollToTop from '../../components/layout/ScrollToTop';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';

function BuyerRecommend() {
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showQualifier, setShowQualifier] = useState(false);
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  async function loadData(){
    let arr = [];
    let obj1 = {
      id: 1,
      name: 'monday.com',
      description: 'Used and loved by more than 180K global customers, monday.com is the most customizable and easy-to-use work management platform, enabling teams of all sizes and industries to plan, manage, streamline...',
      thumbnail: 'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/logo_XW54hc_aG.jpg'
    };

    let obj2 = {
      id: 2,
      name: 'Braintrust',
      description: 'Braintrust is building the world’s first and largest user-owned talent network that connects skilled, vetted engineers, designers, product and project managers with Fortune 500 to high-growth startups. ...',
      thumbnail: 'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/logo_K5_Yfa0kp.jpg'
    };

    let obj3 = {
      id: 3,
      name: 'DataLakeHouse.io',
      description: 'DataLakeHouse.io (DLH.io) is built for data workers who work with people, customer, and operations data to automate data workflows, saving time cleansing, transforming, wrangling, and centralizing data for a single view of the organization....',
      thumbnail: 'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/logo_35FShVKDO.jpg'
    };

    let obj4 = {
      id: 4,
      name: 'DataLakeHouse.io',
      description: 'DataLakeHouse.io (DLH.io) is built for data workers who work with people, customer, and operations data to automate data workflows, saving time cleansing, transforming, wrangling, and centralizing data for a single view of the organization....',
      thumbnail: 'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/logo_35FShVKDO.jpg'
    };

    let obj5 = {
      id: 5,
      name: 'DataLakeHouse.io',
      description: 'DataLakeHouse.io (DLH.io) is built for data workers who work with people, customer, and operations data to automate data workflows, saving time cleansing, transforming, wrangling, and centralizing data for a single view of the organization....',
      thumbnail: 'https://ik.imagekit.io/ivgrhmd4k/companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/logo_35FShVKDO.jpg'
    };

    arr.push(obj1);
    arr.push(obj2);
    arr.push(obj3);
    arr.push(obj4);
    arr.push(obj5);

    let res = await getBuyerRecommendations();

    if (res.success){
      setData(res.data);
      setNoResults(res.data.length === 0);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getBuyerRecommendations();

      if (res.success){
        setData(res.data);
        setNoResults(res.data.length === 0);
      }
    }
    // let sliced = arr.splice(0, 3);
    // console.log('sliced', sliced);

    //setData(arr);
    //loadCurrentSet(arr);
    // setCurrentData(sliced);
    setLoaded(true);
    setShowLoader(false);
  }

  useEffect(() => {
    loadData();

    setTimeout(() => {
      setShowResults(true);
    }, 7000);
  }, []);

  function getStars(number){
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span className="review__star"><img src={star_selected} alt="star" /></span>);
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span className="review__star--unselected"><img src={star_deselect} alt="star" /></span>);
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  async function loadCurrentSet(arr){
    let numElements = arr.length >= 3 ? 3 : arr.length;
    let tmp = arr;
    let sliced = tmp.splice(0, numElements);
    
    console.log('sliced', sliced);
    console.log('tmp', tmp);

    setCurrentData(sliced);
    setData(tmp);
  }

  async function hide(id){
    setSelectedId(id);
  }

  async function handleDetailsClick(id, item){
    setShowDetails(true);
    setSelectedItem(item);
    
  }

  async function handleBookmarkClick(id, item){

  }

  function handleQualifierClick(demo_id, item){
    setCurrentData(item);
    //setCurrentDemoId(demo_id);
    setShowQualifier(true);
  }

  useEffect(() => {
    if (selectedId){
      setIsFading(true);

      // Optionally, you can set a delay before removing the element from the DOM
      setTimeout(async () => {
        setIsFading(false);

        let _tmp = data.filter(x => x.company_id !== selectedId);
        setData(_tmp);

        let res = await addBuyerRecommendationStatus(selectedId, 'Hide');
        setSelectedId(null);

        if (_tmp.length === 0){
          setShowLoader(true);
          setTimeout(async () => {
            await loadData();
          }, 2000);
        }
      }, 300);
    }
  }, [selectedId]);

  return (
    <>
    <ScrollToTop />
      <Helmet>
        <title>Your Software Recommendations</title>
      </Helmet>

      <div className='buyerrecommends'>
        {showResults === false && (
          <div className='buyerrecommends__splash'>
            Building your recommendations...
            <div className='buyerrecommends__subtext'>Finding you the most relevant software</div>
            <Spinner /> 
          </div>
        )}

        <div className='buyerrecommends__results'>
          {loaded === true && showResults && (
            <FadeIn delay={300} duration={500}>
              <h2 className='buyerrecommends__h2 center'>People in your field, found great success with the following software</h2>

              {showLoader === true && (
                <div className='center'>
                  <Spinner />
                </div>
              )}

            {data.length > 0 && (
              <FadeIn delay={200} duration={500}>
            <div className='buyerrecommends__items'>
              {data.map(item => (
                <div className={`buyerrecommends__item fading-element ${isFading && item.company_id === selectedId ? 'fade-out' : ''}`} id={`item_${item.company_id}`}>
                  <div className='buyerrecommends__item-thumbnail' onClick={() => handleDetailsClick(item.company_id, item)}>
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt="" />
                  </div>

                  <div className='buyerrecommends__item-info'>
                  <div className='buyerrecommends__item-name' onClick={() => handleDetailsClick(item.company_id, item)}>
                    {item.name}
                  </div>

                  {item.average_rating && (
                    <div className='buyerrecommends__rating'>
                      {getStars(Math.ceil(item.average_rating))} <span className='buyerrecommends__average-rating-value'>{Math.round(item.average_rating * 10) / 10}</span>
                    </div>
                  )}

                  <div className='buyerrecommends__item-description' onClick={() => handleDetailsClick(item.company_id, item)}>
                    {item.tagline && item.tagline.length > 0 && (
                      <span>{item.tagline}</span>
                    )}

                    {!item.tagline && item.description && item.description.length > 0 && (
                      <span>{item.description.substring(0, item.description.length > 100? 100 : item.description.length)}...</span>
                    )}

                  {/* {item.features && item.features.length > 0 && (
                <ul className='buyerrecommends__key-features'>
                  {item.features.map(x => (
                    <li>{x.feature_text}</li>
                  ))}
                </ul>
                )} */}
                  </div>
                  </div>
{/* 
                  <div className='buyerrecommends__item-testimonial'>
                    <div><strong>CTO working in Aerospace</strong></div>
                    <div>{getStars(4)}</div>
                    <div><i>Great platform with many features that connects my whole team.</i></div>
                  </div> */}

                  {/* <div className='buyerrecommends__btns'>
                    <div><button className='btn btn--primary buyerrecommends__btn-book'>More Info</button></div>
                  </div> */}

                  {/* <div className='buyerrecommends__item-bookmark' onClick={() => handleBookmarkClick(item.company_id, item)}>
                    <FaBookmark />
                  </div> */}

                  <div className='buyerrecommends__actions'>
                    <div className='btn btn--primary btn--cta' onClick={() => handleDetailsClick(item.company_id, item)}>
                      Apply for a demo
                    </div>

                    <div className='buyerrecommends__item-hide' title='Not interested' onClick={() => hide(item.company_id)}>
                      <FaTimes />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </FadeIn>
            )}

            {loaded === true && noResults === true && (
              <div className='buyerrecommends__no-results'>
                No Matches Found...

                <div className='buyerrecommends__subtext'>
                  But you can still book meetings and discover software through our <Link className='link' to='/software'>discover software page</Link>.
                </div>

                {/* <div className='mar20--top center'>
                  <Link className='link' to='/account/workspace'>{'< Back to workspace'}</Link>
                </div> */}
              </div>
            )}

            {/* <div className='mar20--top center'>
              <Link className='link' to='/account/workspace'>{'< Back to your Workspace'}</Link>
            </div> */}
            </FadeIn>
          )}
        </div>
      </div>

      {showDetails && (
        <FadeIn delay={100} duration={500}>
          <div className='overlay'></div>

          <div className='modal buyerrecommends__modal'>
            <div className='modal__close buyerrecommends__modal-close' onClick={() => setShowDetails(false)}>
              <FaTimes />
            </div>
            <div className='buyerrecommends__modal-thumbnail'>
              <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + selectedItem.thumbnail_image} alt="" />
            </div>

            <div className='buyerrecommends__modal-body'>
              <div className='buyerrecommends__modal-name'>
                {selectedItem.name}
              </div>

              {selectedItem.average_rating && (
                <div className='buyerrecommends__rating'>
                  {getStars(Math.ceil(selectedItem.average_rating))} <span className='buyerrecommends__average-rating-value'>{Math.round(selectedItem.average_rating * 10) / 10}</span>
                </div>
              )}

              <div className='buyerrecommends__modal-description'>
                {selectedItem.description && selectedItem.description.length > 0 && (
                      <span>{selectedItem.description}</span>
                    )}

                {/* {selectedItem.features && selectedItem.features.length > 0 && (
                <ul className='buyerrecommends__key-features'>
                  {selectedItem.features.map(x => (
                    <li>{x.feature_text}</li>
                  ))}
                </ul>
                )} */}
              </div>

              <div className='buyerrecommends__modal-cta'>
                <Link to={`/company/${selectedItem.company_id}`} className='btn btn--primary buyerrecommends__btn-book'>{'View Company Details'}</Link>
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default BuyerRecommend;
