import React, { useState } from 'react';
import {
  Box,
  Flex,
  chakra,
  Icon,
  Stack,
  Link,
  Text,
  Image,
  Badge,
  Heading,
  useColorModeValue,
  useToken,
  Button,
} from '@chakra-ui/react';
import ScrollToTop from '../../../components/layout/ScrollToTop';

import TestimonialTestBuyer from './TestimonialTestBuyer';
import PricingHowItWorksBuyer from './PricingHowItWorksBuyer';
import StatsHeroBuyer from './StatsHeroBuyer';
import FAQBuyer from './FAQBuyer';
import { Helmet } from 'react-helmet';
import PricingSellerHeader from './PricingSellerHeader';

const BuyerLandingGoogleAds = () => {
  const topBg = useColorModeValue('gray.800', 'gray.700');
  // const bottomBg = useColorModeValue('gray.200', 'gray.200');
  const bottomBg = useColorModeValue('black', 'gray.200');
  const [bottomBgHex] = useToken('colors', [bottomBg]);
  const [frequency, setFrequency] = useState('month');

  const Feature = (props) => {
    return (
      <Flex align='center'>
        <ScrollToTop />
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color='brand.500'
            _dark={{
              color: 'brand.300',
            }}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'></path>
          </Icon>
        </Flex>
        <Box ml={4}>
          <chakra.span
            mt={2}
            color='gray.700'
            _dark={{
              color: 'gray.400',
            }}>
            {props.children}
          </chakra.span>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Helmet>
        <title>Software Collaboration Tools | How It Works - LeadrPro</title>
      </Helmet>

      <section>
        <Box px={8} py={12} pb={20} mx={10} roundedTop='lg' bg='black'>
          <Box
            w={{
              base: 'full',
              md: 11 / 12,
              xl: 9 / 12,
            }}
            mx='auto'
            textAlign={{
              base: 'left',
              md: 'center',
            }}>
            <Text
              fontSize={'6xl'}
              pb={0}
              fontWeight={'900'}
              // color='purple.500'

              bgClip='text'
              bgGradient='linear(to-r, purple.500,white)'>
              Get Paid To Discover Software!
            </Text>
            <Image
              maxW={'xl'}
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/GettingPaid.png?updatedAt=1683232061454'
            />
            <chakra.h1
              mb={6}
              fontSize={{
                base: '4xl',
                md: '6xl',
              }}
              fontWeight='bold'
              lineHeight='none'
              letterSpacing={{
                base: 'normal',
                md: 'tight',
              }}
              color='purple.500'>
              Zero obligation. No commitments. <br />
              <Text
                display={{
                  base: 'block',
                  lg: 'inline',
                }}
                w='full'
                bgClip='text'
                // bgGradient='linear(to-r, purple.300,purple.500)'
                color='purple.500'
                fontWeight='extrabold'
                fontSize={'8xl'}>
                100% risk free.
              </Text>{' '}
            </chakra.h1>

            <Stack
              direction={{
                base: 'column',
                sm: 'row',
              }}
              mb={{
                base: 4,
                md: 6,
              }}
              mt={20}
              spacing={2}
              justifyContent={{
                sm: 'left',
                md: 'center',
              }}>
              <Button
                as='a'
                variant='solid'
                colorScheme='brand'
                display='inline-flex'
                alignItems='center'
                justifyContent='center'
                w={{
                  base: 'full',
                  sm: 'auto',
                }}
                mb={{
                  base: 2,
                  sm: 0,
                }}
                size='lg'
                cursor='pointer'>
                Click Here To Get Started
                <Icon
                  boxSize={4}
                  ml={1}
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  />
                </Icon>
              </Button>
              <Button
                as='a'
                href='https://www.leadrpro.com/signup/seller'
                // colorScheme='gray'
                display='inline-flex'
                alignItems='center'
                justifyContent='center'
                w={{
                  base: 'full',
                  sm: 'auto',
                }}
                mb={{
                  base: 2,
                  sm: 0,
                }}
                bgGradient='linear(to-r, purple.600,purple.300)'
                color='white'
                _hover={{
                  color: 'white',
                  bgGradient: 'linear(to-r, purple.600,purple.300)',
                  transform: 'scale(1.1)',
                }}
                size='lg'
                cursor='pointer'>
                Sign me up!
                <Icon
                  boxSize={4}
                  ml={1}
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                    clipRule='evenodd'
                  />
                </Icon>
              </Button>
            </Stack>
          </Box>
        </Box>
      </section>
      <section>
        <Box px={10}>
          <TestimonialTestBuyer />
        </Box>
      </section>

      <Flex
        boxSize='full'
        bg='white'
        px={10}
        alignItems='center'
        justifyContent='center'>
        <Box
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          // roundedTop='md'
          shadow='base'
          w='full'
          bg={bottomBg}>
          <Box
            pt={20}
            rounded='md'
            // bg={topBg}
            // bg="purple.500"
            bg='black'>
            <Box w='full' px={[10, , 4]} mx='auto'>
              <a href='/'>
                <chakra.p
                  fontSize={['lg', , '3xl']}
                  color='gray.200'
                  textTransform='uppercase'
                  fontWeight='semibold'>
                  WELCOME TO LEADRPRO
                </chakra.p>
              </a>

              <Text
                mb={2}
                fontSize={['2xl', , '7xl']}
                fontWeight='bold'
                lineHeight='tight'
                color='white'>
                Discover software{' '}
                <chakra.span color='purple.500'>while getting paid</chakra.span>
              </Text>

              <chakra.p mb={0} fontSize={['lg', , 'xl']} color='gray.200'>
                Apply to paid demos. Discover new software. Cash out instantly.
                It's that simple!
              </chakra.p>
            </Box>
            <Box
              bg={`linear-gradient(180deg, transparent 70%, ${bottomBgHex} 30%)`}>
              <Box mb={10}>
                <PricingHowItWorksBuyer />
              </Box>
            </Box>
            <StatsHeroBuyer />
          </Box>
        </Box>
      </Flex>
      <FAQBuyer />
    </>
  );
};

export default BuyerLandingGoogleAds;
