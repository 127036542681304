import './RoleDropdown.css';
import {useState} from 'react';
import { searchCategories, addUserInterest, removeUserInterest, searchInterestCategories } from '../../utils/api';

function CategoryMultiSelect({categories, setCategories, interestLevel}) {
  const [categoryQuery, setCategoryQuery] = useState('');
  const [categoryResults, setCategoryResults] = useState([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);

  async function handleCategoryChange(e){
    setCategoryResults([]);
    setCategoryQuery(e.target.value);
    await categorySearch(e.target.value);
  }

    async function categorySearch(query){
      setShowCategoryDropdown(true);
      let data = await searchInterestCategories(query);

      if (data.data){
        setCategoryResults(data.data);
      }
  }

   async function selectCategory(category_id, name){
    setCategories([...categories, {category_id, name}]);
    setCategoryQuery('');
    setShowCategoryDropdown(false);

    let res = await addUserInterest(category_id, interestLevel);
  }

  async function handleRemove(id){
    let arr = categories.filter(item => item.category_id !== id);
    setCategories(arr);

    let res = await removeUserInterest(id, interestLevel);
  }

return (
 <>
    <div className="form__group">
        <div>
            <input type="text" name="category" id="txtCategory" value={categoryQuery} onChange={handleCategoryChange}  className="category-dropdown" placeholder="Choose from the list" />

              {categoryResults.length > 0 && showCategoryDropdown && (
              <div className="searchfilter__search-results">
                  {categoryResults.map((item, index) => (
                      <div key={index} className="home__hero-category-results-item" onClick={() => selectCategory(item.category_id, item.name)}><span>{item.name}</span></div>
                  ))}
              </div>
              )}
            </div>

        <div className="companyedit__categories">
        {categories !== undefined && categories.map((item, index) => (
          <div className="companyedit__category" key={index}>
            <span>{item.name}</span>

            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove(item.category_id)}>
            <path d="M2.82812 9.32812L8.48498 3.67127" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.82812 3.67188L8.48498 9.32873" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        ))}
        </div>
      </div>
 </> 
)
}

export default CategoryMultiSelect;