import React, { useState } from 'react';
import {
  Box,
  Flex,
  chakra,
  Icon,
  Stack,
  Link,
  Text,
  Badge,
  SimpleGrid,
  useColorModeValue,
  useToken,
  Center,
  Button,
  Image,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

import TestimonialTest from './TestimonialTest';
import FAQSeller from './FAQSeller';
import PricingSellerHeader from './PricingSellerHeader';
import GrowthPlaybookBanner from './GrowthPlaybookBanner';

const SellerPricing = () => {
  const topBg = useColorModeValue('gray.800', 'gray.700');
  // const bottomBg = useColorModeValue('gray.200', 'gray.200');
  const bottomBg = useColorModeValue('black', 'gray.200');
  const [bottomBgHex] = useToken('colors', [bottomBg]);
  const [frequency, setFrequency] = useState('month');

  const Feature = (props) => {
    return (
      <Flex align='center'>
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color='brand.500'
            _dark={{
              color: 'brand.300',
            }}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'></path>
          </Icon>
        </Flex>
        <Box ml={4}>
          <chakra.span
            mt={2}
            color='gray.700'
            _dark={{
              color: 'gray.400',
            }}>
            {props.children}
          </chakra.span>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Helmet>
        <title>Business Intelligence Platform | Pricing - LeadrPro</title>
      </Helmet>
      <Flex
        boxSize='full'
        // bg="#F9FAFB"
        bg='white'
        _dark={{
          bg: 'gray.600',
        }}
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          rounded='md'
          shadow='base'
          w='full'
          bg={bottomBg}>
          <Box pt={20} rounded='md' bg='black'>
            <Box w='full' px={[10, , 4]} mx='auto'>
              <Text
                mb={2}
                fontSize={['2xl', , '7xl']}
                fontWeight='bold'
                lineHeight='tight'
                color='white'>
                Simple{' '}
                <chakra.span color='purple.500'>transparent </chakra.span>
                pricing
              </Text>

              <chakra.p mb={6} fontSize={['lg', , 'xl']} color='gray.200'>
                Find the right pricing plan for your business - no matter what
                your needs.
              </chakra.p>
            </Box>
            <Box
              bg={`linear-gradient(180deg, transparent 70%, ${bottomBgHex} 30%)`}>
              <SimpleGrid
                columns={[1, , , 2]}
                gap='24px'
                rounded='md'
                mx={[10, , 24]}
                textAlign='left'>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Pay per demo
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $500
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo{' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        Pay per demo
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={'100px'} spacing={4}>
                        <Feature>Calendar Integration</Feature>
                        <Feature>Video Chat</Feature>
                        <Feature>Instant Messaging</Feature>
                        <Feature>Unlimited Qualifier Questions</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Premium Package
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $400
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo ($4,000 total){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        10 Demo Package
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={5} spacing={4}>
                        <Feature>
                          All Features From Pay Per Demo Included
                        </Feature>
                        <Feature>Rollover Demos</Feature>
                        <Feature>Team Management</Feature>
                        <Feature>Real Time Notifications</Feature>
                        <Feature>Only Pay For Approved Demos</Feature>
                        <Feature>Custom Onboarding</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
              </SimpleGrid>
              <SimpleGrid
                pb='100px'
                columns={[1, , , 2]}
                gap='24px'
                rounded='md'
                mx={[10, , 24]}
                textAlign='left'
                mt={5}>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Growth Package
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $300
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo ($6,000 Total){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        20 Demo Package
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={'100px'} spacing={4}>
                        <Feature>All Features From Premium Included</Feature>
                        <Feature>Prospect Search</Feature>
                        <Feature>Video Recording Integration</Feature>
                        <Feature>Automatic Candidate Filtering</Feature>
                        <Feature>Dedicated Customer Success Manager</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Custom - Full Service
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        Custom
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          (Contact Sales){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        Need more demos? Contact our sales team to get started
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={5} spacing={4}>
                        <Feature>All Features From Growth Included</Feature>
                        <Feature>
                          Deliver Booked Demos Directly To Your Dashboard
                        </Feature>
                      </Stack>
                      <a
                        href='https://calendly.com/leadrpro/leadrpro-demo'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          mt={'200px'}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          bg='purple.500'
                          color='white'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
              </SimpleGrid>

              {/* <Box mb={20}>
                <Flex
                  mb={12}
                  mx={[10, , 24]}
                  p={10}
                  mt={5}
                  rounded='md'
                  bg='#F9FAFB'
                  _dark={{
                    bg: 'gray.700',
                  }}
                  align='center'
                  direction={['column', , 'row']}>
                  <Stack flex='0.8' pr={10}>
                    <Badge
                      mb={1}
                      fontSize='xs'
                      letterSpacing='wide'
                      colorScheme='purple'
                      fontWeight='medium'
                      rounded='full'
                      px={4}
                      py={1}
                      w='fit-content'>
                      Limited Time Offer
                    </Badge>
                    <Text textAlign='left'>
                      Get your first two demos{' '}
                      <chakra.span fontWeight='bold'>50%</chakra.span> off! Sign
                      up to LeadrPro today and get your first two demos at half
                      price! We guarantee you'll love it so much you'll wonder
                      how you ever did sales without us!
                    </Text>
                  </Stack>
                  <Link
                    as={'a'}
                    href='https://calendly.com/leadrpro/leadrpro-demo'
                    target={'_blank'}
                    w={['full', , 'auto']}
                    display='inline-flex'
                    alignItems='center'
                    justifyContent='center'
                    px={5}
                    py={3}
                    mt={[5, , 0]}
                    border='solid transparent'
                    fontWeight='semibold'
                    rounded='md'
                    shadow='md'
                    h='fit-content'
                    color='white'
                    _dark={{
                      color: 'white',
                    }}
                    bg='purple.500'
                    _hover={{
                      bg: 'purple.600',
                      color: 'white',
                      transform: 'scale(1.05)',
                    }}
                    flex='0.2'>
                    Create a free account!
                  </Link>
                </Flex>
              </Box> */}

              <TestimonialTest />
              <GrowthPlaybookBanner />
            </Box>
          </Box>
        </Box>
      </Flex>
      <Center>
        <Box mt={10} mb={'200px'} textAlign='center'>
          <Center>
            <Image
              mb={8}
              w={'100px'}
              src='https://ik.imagekit.io/ivgrhmd4k/logos/logo_cvTdzqJku.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650838236165'
              alt='leadrpro logo'
            />
          </Center>

          <Heading
            fontWeight={800}
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            lineHeight={'110%'}
            mb={4}>
            Try LeadrPro Today
          </Heading>
          <chakra.p
            mb={2}
            fontSize={['md', , 'lg']}
            color='black'
            _dark={{
              color: 'gray.400',
            }}
            maxW='xl'>
            {/* Become a host today and{' '} */}
            <chakra.span fontStyle={'italic'}>
              Become a host today and change the way you do sales forever!
            </chakra.span>
          </chakra.p>
          <chakra.p
            mb={6}
            fontSize={['md', , 'lg']}
            color='black'
            _dark={{
              color: 'gray.400',
            }}
            maxW='xl'>
            <chakra.span fontWeight={600}>
              Sign up and get your first demo free.
            </chakra.span>
          </chakra.p>
          <Link
            as={'a'}
            href='https://calendly.com/leadrpro/leadrpro-demo'
            target={'_blank'}
            w={['full', , 'auto']}
            display='inline-flex'
            alignItems='center'
            justifyContent='center'
            px={10}
            py={3}
            mt={[5, , 0]}
            border='solid transparent'
            fontWeight='semibold'
            rounded='md'
            shadow='md'
            h='fit-content'
            color='white'
            bg='purple.500'
            _hover={{
              bg: 'purple.600',
              color: 'white',
              transform: 'scale(1.05)',
            }}
            flex='0.2'>
            Create your free account!
          </Link>
        </Box>
      </Center>
    </>
  );
};

export default SellerPricing;
