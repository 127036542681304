import './BuyerFeedback.css';
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import congrats from '../../assets/design/dashboard/congrats.png';
import { getBuyerObjections, addBuyerFeedback, getBuyerDemoFromRef } from '../../utils/api';

function BuyerFeedback({selectedPersonaType, setSelectedPersonaType}) {
  const [referenceId, setReferenceId] = useState('');
 // const [selectedPersonaType, setSelectedPersonaType] = useState(null);
  const [personaTypes, setPersonaTypes] = useState([]);
  const [demoId, setDemoId] = useState(0);
  const [demo, setDemo] = useState({});
  const [buyerObjections, setBuyerObjections] = useState([]);
  const [selectedObjectionReason, setSelectedObjectionReason] = useState(null);
  const [objectionReason, setObjectionReason] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [valid, setValid] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    async function loadDemo(){
      const id = new URLSearchParams(search).get('refid');
      setReferenceId(id);

      let response = await getBuyerDemoFromRef(id);

      if (response.success){
        if (response.data.length > 0){
          setDemo(response.data[0]);
          setValid(true);

          if (response.data[0].demo_status_id === 4)
            setCompleted(true);
        }

        else{
          setValid(false);
          setCurrentStep(-1);
        }
      }

      else{
        setValid(false);
      }

      setLoaded(true);
    }

    loadDemo();
  }, []);

  useEffect(() => {
    async function fetchBuyerObjections() {
      let data = await getBuyerObjections();
      if (data.success) {
        setBuyerObjections(data.data);
      }
    }

    fetchBuyerObjections();
  }, []);

  async function yesClickHandler(){
    setCurrentStep(1);
    let res = await addBuyerFeedback(demo.demo_id, true);
  }

  function noClickHandler(){
    setCurrentStep(2);
  }

  async function submitBuyerFeedback(e){
    e.preventDefault();

    let objectionReason = e.target.elements.objectionReason.value;
    let objectionDescription = e.target.elements.objectionDescription.value;

    let res = await addBuyerFeedback(
      demo.demo_id,
      false,
      objectionReason,
      objectionDescription
    );

    if (res.success) {
      setCurrentStep(3);
    } else {
      console.log('failed!');
    }
  }

  if (demo.demo_status_id === 1){
    return (
        <div className="feedback">
          <p>This meeting has not been completed yet.</p>
    
          <p>
            It has been scheduled for {new Date(demo.scheduled_date).toLocaleDateString()}
          </p>
  
          <p>
            <a href={`https://meetings.leadrpro.com/demo?refid=${demo.reference_id}`} target="_blank">Join Meeting</a>
          </p>
        </div>
    )
  }
  
return (
  <div className='feedback'>
     {currentStep === -1 && (
      <div>
        <p>Invalid Feedback link.</p>
        <p>Please contact support if you think this is an error.</p>
        <p><a href="mailto:support@leadrpro.com">Contact us</a></p>
      </div>
    )}

    {loaded && completed && currentStep === 0 && (
    <div className="">
    <p className='feedback__text--large'>
    Please answer the following to complete the
    process and receive your {15 * demo.credits}$!
    </p>

  <p className='feedback__text--small'>
  Would you be interested in learning more about <strong>{demo.company_name}</strong>?
  </p>

  <p className='feedbackpopup__btn-container-parent'>
    <button className='btn btn--primary' onClick={() =>
        yesClickHandler()}>
      Yes
    </button>

    <button className='btn btn--secondary' onClick={noClickHandler}>
      No
    </button>
  </p>
  </div>
    )}

{loaded && currentStep === 1 && (
  <div>
    <div className="center">
      <img src={congrats} alt='' />
    </div>

    <p className='feedback__text--large'>
      That's great!
    </p>

    <p className='feedback__text--small'>
      We're glad it went well!
    </p>

    <p className='feedback__text--small'>
      We'll let the seller know you would like to
      move forward
    </p>
  </div>
)}

{loaded && currentStep === 2 && (
  <div>
      <div className='feedback__text--large'>
        Oh no! We're sorry to hear that!
      </div>

      <form onSubmit={(e) => submitBuyerFeedback(e)}>
        <div className='form-group'>
          <label className='feedback__text--small'>
              Please select one of the reasons below
              as to why you won't be moving forward
          </label>

          <select className='input' type='text' id='objectionReason' onChange={(e) =>
              setSelectedObjectionReason(
                e.target.value
              )
            }
            name='choose reason'>
            {buyerObjections.map((item) => (
              <option value={item.objection_reason_id}>
                {item.reason}
              </option>
            ))}
          </select>
        </div>

        <div className='form-group'>
          <label className='feedback__text--small'>
            Please let the seller know how they could
            improve with future buyers
          </label>

          <textarea
            autoComplete='off'
            value={objectionReason}
            onChange={(event) =>
              setObjectionReason(event.target.value)
            }
            className='input_box feedback__text-area'
            type='text'
            required='required'
            name='Objection Reason'
            id='objectionDescription'
            placeholder='Maximum 40 characters'
          />
        </div>

        <div className='form__group'>
          <button className='btn feedbackpopup__submit-btn'>
            Submit
          </button>
        </div>
      </form>
    </div>
)}

{loaded && currentStep === 3 && (
  <div>
    <img src={congrats} alt='' />

    <div className='feedback__text--large'>
      Thank you!
    </div>

    <div className='feedback__text--small'>
      Your feedback has been recorded!
    </div>
  </div>
)}
</div>
)
   }

   export default BuyerFeedback;