import React, { useState, useEffect } from 'react';
import close from '../../assets/design/pricing/closing-x.png';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import {declineDemoBuyer, addBuyerDeclineReason, declineDemoSeller, addSellerDeclineReason, getBuyerDeclinedReasons} from '../../utils/api';
import { getCurrentUser, refresh } from '../../services/auth.service';

const DeclinedPopup = ({ sellerDeclinedReasons, setShowDeclinedPopup, role, currentDemo, demos, setDemos, source, setShowReschedule}) => {
  const [buyerDeclinedReasons, setBuyerDeclinedReasons] = useState([]);
  const [declineReason, setDeclineReason] = useState('');
  const [selectedDeclineReason, setSelectedDeclineReason] = useState(null);
  const [showAreYouSure, setShowAreYouSure] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  async function fetchBuyerDeclinedReasons() {
    let data = await getBuyerDeclinedReasons();
    if (data.success) {
      setBuyerDeclinedReasons(data.data);
    }
  }

  useEffect(() => {
    fetchBuyerDeclinedReasons();
  }, []);

  function closingClick() {
    setShowDeclinedPopup(false);
  }

  function notificationClosingClick() {
    setShowNotification(false);
    setShowDeclinedPopup(false);
  }

  async function handleSubmit(event, id) {
    event.preventDefault();

    if (selectedDeclineReason === null){
      alert("Please select a reason");
      return;
    }

    setIsProcessing(true);

    if (role === 'Buyer') {
      let res = await declineDemoBuyer(id, source, declineReason);

      if (res.success){
        await addBuyerDeclineReason(id, selectedDeclineReason, declineReason);
        setShowAreYouSure(!showAreYouSure);
        setShowNotification(true);
        currentDemo.demo_status = 'Declined';
        currentDemo.buyer_status = 'Declined';
      }

      else{
        await refresh();

        let res = await declineDemoBuyer(id, source, declineReason);

        if (res.success){
          await addBuyerDeclineReason(id, selectedDeclineReason, declineReason);
          setShowAreYouSure(!showAreYouSure);
          setShowNotification(true);
          currentDemo.demo_status = 'Declined';
          currentDemo.buyer_status = 'Declined';
        }
      }
    }
    
    else {
      let res = await declineDemoSeller(id, source);

      if (res.success){
        await addSellerDeclineReason(id, selectedDeclineReason, declineReason);
        setShowAreYouSure(!showAreYouSure);
        setShowNotification(true);
        currentDemo.demo_status = 'Declined';
        currentDemo.seller_status = 'Declined';
      }

      else if (res.status === 403){
        await refresh();

        let res = await declineDemoSeller(id, source);

        if (res.success){
          await addSellerDeclineReason(id, selectedDeclineReason, declineReason);
          setShowAreYouSure(!showAreYouSure);
          setShowNotification(true);
          currentDemo.demo_status = 'Declined';
          currentDemo.seller_status = 'Declined';
        }
      }
    }

    let _filtered = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
    setDemos(_filtered);
  }

  async function handleRescheduleClick(){
    setShowReschedule(true);
    setShowDeclinedPopup(false);
  }

  return (
    <>
    <FadeIn delay={120} duration={200}>
      <div className='overlay'></div>
      {showAreYouSure && (
        <div className='modal'>
          <img src={close} className='modal__close' onClick={closingClick} />

          {role === 'Buyer' && (
            <h1 className='declinedpopups__header-text'>Are you sure you want to decline the demo with <strong>{currentDemo.company_name}</strong>?</h1>
          )}

          {role === 'Seller' && source === 'Cancel' && (
            <h2 className='modal__h2'>
              Cancel this demo
            </h2>
          )}

          <p style={{fontSize: "11px", margin: "5px 0px"}}>
            * You will not be able to book again with this person!
          </p>

          {role === 'Buyer' && source === 'Cancel' && (
            <p style={{backgroundColor: "#e5fff6", padding: "10px", fontSize: "14px"}}>
              Are you looking to reschedule instead? <span className='btn btn--primary btn--small' onClick={() => handleRescheduleClick()}>Reschedule</span>
            </p>
          )}

          {/* {role === 'Seller' && source === 'Cancel' && (
            <p style={{backgroundColor: "#e5fff6", padding: "10px", fontSize: "14px"}}>
              Are you looking to reschedule instead? <span className='btn btn--primary btn--small' onClick={() => handleRescheduleClick()}>Reschedule</span>
            </p>
          )} */}
          
          <form onSubmit={(e) => handleSubmit(e, currentDemo.demo_id)}>
            <div className='form-group' style={{ marginBottom: '20px' }}>
              <label className='label input-label'>Choose the reason</label>
              <select
                className='input select input_box dropdown-font'
                onChange={(e) => setSelectedDeclineReason(e.target.value)}
                name='choose reason'>
                <option value='0'>Select a reason</option>
                {role === 'Buyer' && (
                  <>
                    {buyerDeclinedReasons.length > 0 &&
                      buyerDeclinedReasons.map((item) => (
                        <option value={item.decline_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                  </>
                )}
                {role === 'Seller' && (
                  <>
                    {sellerDeclinedReasons.length > 0 &&
                      sellerDeclinedReasons.map((item) => (
                        <option value={item.decline_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                  </>
                )}
              </select>
            </div>

            <div className='form-group'>
              <label className='label input-label'>
                If other reason, please briefly explain why:
              </label>

              <p style={{fontSize: '12px', fontStyle: "italic"}}>
              * will not be shared with other person
              </p>
              <textarea
                autoComplete='off'
                value={declineReason}
                onChange={(event) => setDeclineReason(event.target.value)}
                className='input_box textarea'
                type='text'
                name='Objection Reason'
                placeholder='Enter here'
              />
            </div>

            <div className='center' style={{marginTop: "10px"}}>
              {isProcessing === true && (
                <Spinner />
              )}

              {isProcessing === false && source === 'Cancel' && (
                <button className='btn btn--danger'>Confirm Cancel</button>
              )}

              {isProcessing === false && source !== 'Cancel' && (
                <button className='btn btn--danger'>Not a fit</button>
              )}
            </div>
          </form>
        </div>
      )}

      {showNotification && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <img src={close} className='modal__close' onClick={notificationClosingClick} />

            {role === 'Seller' && (
              <>
                <h2 className='modal__h2'>
                  You have declined a demo with <span className='dashboarddemo__highlight'>
                      {currentDemo.company_name}!
                    </span>
                </h2>

                <div className='modal__body'>
                  We will use this information to improve your future matches.
                </div>

                  <div className='center'>
                    <button className='btn' onClick={() => notificationClosingClick()}>Close</button>
                  </div>
              </>
            )}

            {role === 'Buyer' && (
              <>
                <div className='modal__header'>
                  <div style={{fontSize: "20px"}}>
                    You have declined this demo with <span className='dashboarddemo__highlight'>
                      {currentDemo.company_name}.
                    </span>
                  </div>
                </div>

                {source === 'Cancel' && (
                <div className='modal__body'>
                  We have informed them of your cancellation. Please check back often for new demo opportunities!
                </div>
                )}

                <div className='center'>
                  <button className='btn btn--secondary' onClick={() => notificationClosingClick()}>Close</button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      </FadeIn>
    </>
  );
};

export default DeclinedPopup;