import React, { useState } from 'react';
import {
  Box,
  Flex,
  chakra,
  Icon,
  Stack,
  Link,
  Text,
  Badge,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react';

import TestimonialTestBuyer from './TestimonialTestBuyer';
import PricingHowItWorksBuyer from './PricingHowItWorksBuyer';
import StatsHeroBuyer from './StatsHeroBuyer';
import FAQBuyer from './FAQBuyer';
import { Helmet } from 'react-helmet';
import BuyerLogos from './BuyerLogos';

const PricingTest = () => {
  const topBg = useColorModeValue('gray.800', 'gray.700');
  // const bottomBg = useColorModeValue('gray.200', 'gray.200');
  const bottomBg = useColorModeValue('black', 'gray.200');
  const [bottomBgHex] = useToken('colors', [bottomBg]);
  const [frequency, setFrequency] = useState('month');

  const Feature = (props) => {
    return (
      <Flex align='center'>
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color='brand.500'
            _dark={{
              color: 'brand.300',
            }}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'></path>
          </Icon>
        </Flex>
        <Box ml={4}>
          <chakra.span
            mt={2}
            color='gray.700'
            _dark={{
              color: 'gray.400',
            }}>
            {props.children}
          </chakra.span>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Helmet>
        <title>Discover software while getting paid - LeadrPro</title>
        <link rel="canonical" href="https://www.leadrpro.com/how-it-works" />
      </Helmet>
      <Flex
        boxSize='full'
        bg='white'
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          rounded='md'
          shadow='base'
          w='full'
          bg={bottomBg}>
          <Box pt={{ base: 10, md: 20 }} rounded='md' bg='black'>
            <Box w='full' px={[10, , 4]} mx='auto'>
              <a href='/'>
                <chakra.p
                  textAlign='center'
                  fontSize={['lg', , '3xl']}
                  color='gray.200'
                  textTransform='uppercase'
                  fontWeight='semibold'
                  mb={{ base: 6, md: 0 }}>
                  WELCOME TO LEADRPRO
                </chakra.p>
              </a>

              <Text
                textAlign='center'
                mb={{ base: 6, md: 2 }}
                fontSize={['2xl', , '7xl']}
                fontWeight='bold'
                lineHeight='tight'
                color='white'>
                Discover software{' '}
                <chakra.span textAlign='center' color='purple.500'>
                  while getting paid
                </chakra.span>
              </Text>

              <chakra.p
                textAlign='center'
                mb={{ base: -12, md: 0 }}
                fontSize={['lg', , 'xl']}
                color='gray.200'>
                Apply to paid demos. Discover new software. Cash out instantly.
                It's that simple!
              </chakra.p>
            </Box>
            <Box
              bg={`linear-gradient(180deg, transparent 70%, ${bottomBgHex} 30%)`}>
              <Box mb={10}>
                <PricingHowItWorksBuyer />
              </Box>

              <BuyerLogos />

              <TestimonialTestBuyer />
            </Box>
            <StatsHeroBuyer />
          </Box>
        </Box>
      </Flex>
      <FAQBuyer />
    </>
  );
};

export default PricingTest;
