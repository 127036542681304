import './PriceLeads.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getLeadCardsPending, leadCardPurchase, checkHasPaymentMethod, getUserCompany, getPaymentType, getPackageItemsRemainingCount, purchaseLeadCard } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaTimes, FaRegCheckCircle, FaRegLightbulb, FaCoins } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';
import ScrollToTop from '../../components/layout/ScrollToTop';
import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};

const PriceLeads = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState('Pending');
  const [showPurchase, setShowPurchase] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [hasPaymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const [paymentType, setPaymentType] = useState('');
  const [paymentTypeLoaded, setPaymentTypeLoaded] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [confirmationId, setConfirmationId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getLeadCardsPending();

      if (res.success){
        setData(res.data);
        setLoading(false);
      }
    }

    async function loadPaymentType(){
      let res = await getPaymentType();

      if (res.success){
        setPaymentType(res.data);
        setPaymentTypeLoaded(true);
       // alert(res.data);
      }
    }

    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
        console.log('user company', res.data);
      }
    }

    async function loadPaymentMethod() {
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
      }
      
      else if (res.status === 403) {
        await refresh();

        let res = await checkHasPaymentMethod();

        if (res.success) {
          setHasPaymentMethod(res.data);
        }
      }

      setPaymentMethodLoaded(true);
    }

    async function loadRemainingCount(){
      let res = await getPackageItemsRemainingCount();
  
      if (res.success){
        setRemainingCount(res.data);
        setRemainingCountLoaded(true);
      }
    }
    

      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
        setSuccess(true);
      }

      else if (response.status === 403){
        await refresh();
        let response = await getUserProfile();

        if (response.success){
          setUser(response.data);
          setLoading(false);
          setSuccess(true);
        }
      }

      else{
        setLoading(false);
      }
    }

    loadRemainingCount();
    loadUserCompany();
    loadUserProfile();
    loadData();
    loadPaymentMethod();
    loadPaymentType();
  }, []);

  async function handleConfirmPurchase(){
    //let res = await leadCardPurchase(selectedId);
    setSubmitting(true);
    let res = await purchaseLeadCard(selectedId);

    if (res.success){
      setShowPurchase(false);
      setShowConfirm(true);
      setConfirmationId(res.data);
      setSubmitting(false);
    }

    else{
      //alert('nope');
      setErrorMessage(res.message);
      setSubmitting(false);
      setShowError(true);
    }
  }

  async function handlePurchaseClick(id, item){
    setSelectedId(id);
    setSelectedItem(item);
    setShowPurchase(true);
  }

  return (
    <div className="priceleads">
      <ScrollToTop />
      {loading && (
        <Spinner />
      )}

      {loading === false && success == true && (
        <>
          {currentTab == 'Pending' && (
            <div>
              {/* <div className='priceleads__header'>
                <div><svg style={{height: '40px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.4 96L144 96l-36.2 0L96 96l0 8.8L96 144l0 40.4 0 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3 48 96c0-26.5 21.5-48 48-48l76.6 0 49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48 416 48c26.5 0 48 21.5 48 48l0 44.3 22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4l0-89 0-40.4 0-39.2 0-8.8-11.8 0L368 96l-71.4 0-81.3 0zM0 448L0 242.1 217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1 512 448s0 0 0 0c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64c0 0 0 0 0 0zM176 160l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg></div>
                <strong>You have 2 available leads to review</strong>
              </div> */}

              <div className='' style={{padding: '0px 10px'}}>
                <span><Link to='/dashboard/my-price-leads' style={{textDecoration: 'underline'}}>Back to My Leads</Link></span>
                <h1>Pending leads</h1>
              </div>

              {data.length > 0 && (
              <div className='priceleads__explainer'>
                <span className='mr-10'><FaRegLightbulb /></span>
                These leads are ready to review! Please note that they will remain visible here while the users request remains open. Once closed, you will no longer be able to review or purchase the lead.
              </div>
              )}

              {data.length == 0 && loading == false && (
                <div>
                  <i>You have no pending leads currently.</i>
                </div>
              )}

              <div className='priceleads__cards'>
              {data.map(item => (
                  <div className='priceleads__card'>
                  <div style={{fontSize: '14px', fontWeight: 'bold'}}>
                    Reference #: {item.reference_id}
                  </div>

                  <div className='form-group priceleads__card-job-title'>
                    {item.headline != null && item.headline.length > 0 && (
                    <>
                    {item.headline}
                    </>
                    )}

                    {(item.headline == null || item.headline.length == 0) && (
                      <>
                        {item.job_title}
                      </>
                    )}
                  </div>

                  <div className='priceleads__card-action'>
                    <span><IconDisplay iconName={item.type_icon_name} /></span>
                    <span className='ml-10'>{item.type_description}</span>
                  </div>

                  {/* <div className='priceleads__card-action'>
                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M200.6 32C205 19.5 198.5 5.8 186 1.4S159.8 3.5 155.4 16L144.7 46.2l-9.9-29.8C130.6 3.8 117-3 104.4 1.2S85 19 89.2 31.6l8.3 25-27.4-20c-10.7-7.8-25.7-5.4-33.5 5.3s-5.4 25.7 5.3 33.5L70.2 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l152.6 0c-5.4-9.4-8.6-20.3-8.6-32l0-224c0-29.9 20.5-55 48.2-62c1.8-31 17.1-58.2 40.1-76.1C271.7 104.7 256.9 96 240 96l-22.2 0 28.3-20.6c10.7-7.8 13.1-22.8 5.3-33.5s-22.8-13.1-33.5-5.3L192.5 55.1 200.6 32zM363.5 185.5L393.1 224 344 224c-13.3 0-24-10.7-24-24c0-13.1 10.8-24 24.2-24c7.6 0 14.7 3.5 19.3 9.5zM272 200c0 8.4 1.4 16.5 4.1 24l-4.1 0c-26.5 0-48 21.5-48 48l0 80 192 0 0-96 32 0 0 96 192 0 0-80c0-26.5-21.5-48-48-48l-4.1 0c2.7-7.5 4.1-15.6 4.1-24c0-39.9-32.5-72-72.2-72c-22.4 0-43.6 10.4-57.3 28.2L432 195.8l-30.5-39.6c-13.7-17.8-35-28.2-57.3-28.2c-39.7 0-72.2 32.1-72.2 72zM224 464c0 26.5 21.5 48 48 48l144 0 0-128-192 0 0 80zm224 48l144 0c26.5 0 48-21.5 48-48l0-80-192 0 0 128zm96-312c0 13.3-10.7 24-24 24l-49.1 0 29.6-38.5c4.6-5.9 11.7-9.5 19.3-9.5c13.4 0 24.2 10.9 24.2 24z"/></svg></span> This person has shown interest through one of our incentivised outreaches.
                  </div> */}

                  {/* <div className='priceleads__card-action'>
                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256l0 40c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-40C0 114.6 114.6 0 256 0S512 114.6 512 256l0 144.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24l-32 0c-26.5 0-48-21.5-48-48s21.5-48 48-48l32 0c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40L464 256c0-114.9-93.1-208-208-208zM144 208l16 0c17.7 0 32 14.3 32 32l0 112c0 17.7-14.3 32-32 32l-16 0c-35.3 0-64-28.7-64-64l0-48c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64l0 48c0 35.3-28.7 64-64 64l-16 0c-17.7 0-32-14.3-32-32l0-112c0-17.7 14.3-32 32-32l16 0z"/></svg></span> Your company was recommended to this user by our software experts.
                  </div> */}

                  {/* <div className='priceleads__card-action'>
                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M159.3 5.4c7.8-7.3 19.9-7.2 27.7 .1c27.6 25.9 53.5 53.8 77.7 84c11-14.4 23.5-30.1 37-42.9c7.9-7.4 20.1-7.4 28 .1c34.6 33 63.9 76.6 84.5 118c20.3 40.8 33.8 82.5 33.8 111.9C448 404.2 348.2 512 224 512C98.4 512 0 404.1 0 276.5c0-38.4 17.8-85.3 45.4-131.7C73.3 97.7 112.7 48.6 159.3 5.4zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z"/></svg></span> This person has requested a demo of your product.
                  </div> */}

                  {/* <div className='priceleads__card-action'>
                    <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M416 176c0 97.2-93.1 176-208 176c-38.2 0-73.9-8.7-104.7-23.9c-7.5 4-16 7.9-25.2 11.4C59.8 346.4 37.8 352 16 352c-6.9 0-13.1-4.5-15.2-11.1s.2-13.8 5.8-17.9c0 0 0 0 0 0s0 0 0 0l.2-.2c.2-.2 .6-.4 1.1-.8c1-.8 2.5-2 4.3-3.7c3.6-3.3 8.5-8.1 13.3-14.3c5.5-7 10.7-15.4 14.2-24.7C14.7 250.3 0 214.6 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176zM231.5 383C348.9 372.9 448 288.3 448 176c0-5.2-.2-10.4-.6-15.5C555.1 167.1 640 243.2 640 336c0 38.6-14.7 74.3-39.6 103.4c3.5 9.4 8.7 17.7 14.2 24.7c4.8 6.2 9.7 11 13.3 14.3c1.8 1.6 3.3 2.9 4.3 3.7c.5 .4 .9 .7 1.1 .8l.2 .2s0 0 0 0s0 0 0 0c5.6 4.1 7.9 11.3 5.8 17.9c-2.1 6.6-8.3 11.1-15.2 11.1c-21.8 0-43.8-5.6-62.1-12.5c-9.2-3.5-17.8-7.4-25.2-11.4C505.9 503.3 470.2 512 432 512c-95.6 0-176.2-54.6-200.5-129zM228 72c0-11-9-20-20-20s-20 9-20 20l0 14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1c0 0 0 0 0 0s0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4l0 14.6c0 11 9 20 20 20s20-9 20-20l0-13.8c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7L228 72z"/></svg></span> This person has specifically expressed interest in your companies pricing information.
                  </div> */}

                  <div className='form-group'>
                    <strong>Date Created</strong>
                    <div>
                      {new Date(item.date_created).toLocaleDateString()}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for</strong>

                    <div>
                      {item.request_body}
                    </div>
                  </div>

                {showDetails == false && (
                  <div className='center'>
                    <button className='btn btn--confirm' onClick={() => setShowDetails(true)}>Review</button>
                  </div>
                )}

                {showDetails && (
                <div className='priceleads__hidden-section'>
                  <div className='form-group'>
                    <strong>Company name</strong>
                    <div>
                      {item.company_name}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Company size</strong>
                    <div>
                      {item.company_size}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Number of users</strong>
                    <div>
                      {item.number_of_users}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Annual revenue</strong>
                    <div>
                      {item.annual_revenue}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for key features</strong>
                    <div>
                      {item.key_features}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Price expectations</strong>
                    <div>
                      {item.price_expectations}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Timeframe</strong>

                    <div>
                      {item.timeframe}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Contact details</strong>
 
                    <p>
                      {(item.email_preference == true && item.phone_preference == true && (
                        <>
                          <div>Email and phone number available</div>
                        </>
                      ))}

                      {(item.email_preference == true && item.phone_preference == false && (
                        <div>Email address available</div>
                      ))}

                      {(item.email_preference == false && item.phone_preference == true && (
                        <div>Phone number available</div>
                      ))}
                    </p>

                    <div className='priceleads__btns'>
                      <button className='btn btn--primary' onClick={() => handlePurchaseClick(item.reference_id, item)}>Purchase</button>
                    </div>
                  </div>
                  </div>
                )}
                </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {/* {showPurchase && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide'>
            <div className='modal__close' onClick={() => setShowPurchase(false)}>
              <FaTimes />
            </div>
            <h2 className='modal__h2'>Confirm purchase</h2>

            <div style={{display: 'flex'}}>
              <div style={{width: '50%', fontSize: '14px', padding: '20px'}}>
              By clicking 'Confirm Purchase' below you authorize LeadrPro to charge the card on file for the purchase of 5 demos. These demos do not expire and are only used up once you successfully complete a schedule meeting on our platform.

              </div>

              <div style={{width: '50%', padding: '20px'}}>
                <div className='priceleads__purchase-features'>
                  <div><FaRegCheckCircle /> Get recommended by us to the prospect</div>
                  <div><FaRegCheckCircle /> Get contact information instantly</div>
                  <div><FaRegCheckCircle /> Manual outreach to close the demo included</div>

                </div>

                <div className='priceleads__purchase-total mt-20'>
                  <span><strong>Cost</strong></span>: $120
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}

{showPurchase && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide'>
            <div className='modal__close' onClick={() => {
              setShowPurchase(false);
              setShowError(false);
              setErrorMessage('');
            }}>
              <FaTimes />
            </div>
            <h2 className='modal__h2' style={{padding: '0px 20px'}}>Confirm purchase</h2>

            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            <div style={{width: '50%', padding: '20px'}}>
                <strong>Additional features:</strong>
                <div className='priceleads__purchase-features'>
                  <div><FaRegCheckCircle /> Get recommended by us to the prospect</div>
                  <div><FaRegCheckCircle /> Get contact information instantly</div>
                  <div><FaRegCheckCircle /> Automatic follow-up to schedule a demo</div>

                </div>

                <div className='mt-10'>
                  <strong>Purchasing</strong>

                  <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                  <i>Lead #: {selectedId}</i>
                  </div>
                </div>

                {(selectedItem.lead_form_submission_type_id == 1 || selectedItem.lead_form_submission_type_id == 3) && (
                  <div className='priceleads__purchase-total mt-20'>
                    <span><strong>Cost</strong></span>: $199
                  </div>
                )}

                {selectedItem.lead_form_submission_type_id == 2 && paymentTypeLoaded == true && paymentType == 'pay_now' && (
                  <div>
                    
                <div className='priceleads__purchase-total mt-20'>
                  <FaCoins /> <strong>{remainingCount} demo credits remaining</strong>
                </div>
                  </div>
                )}

                {selectedItem.lead_form_submission_type_id == 4 && paymentTypeLoaded == true && paymentType == 'pay_now' && (
                  <div>
                <div className='priceleads__purchase-total mt-20'>
                  <FaCoins /> <strong>{remainingCount} demo credits remaining</strong>
                </div>
                  </div>
                )}
              </div>

              <div style={{width: '50%', fontSize: '16px', padding: '20px'}}>
                <div>
                <div className='priceleads__card-action'>
                    <span><IconDisplay iconName={selectedItem.type_icon_name} /></span>
                    <span className='ml-10'>{selectedItem.type_description}</span>
                  </div>

                  {/* <strong>Description</strong> */}

                  <div style={{fontSize: '14px'}} className='mt-10'>
                  Unlock access to high-quality, verified leads that are ready to boost your business. Our leads are tailored to fit your specific needs and help you connect with potential customers who are genuinely interested in your products or services. Here's what you’ll get with each purchase:


                  </div>
                </div>

                {/* pay now - price / rec lead */}
                {paymentTypeLoaded == true && paymentType == 'pay_now' && (selectedItem.lead_form_submission_type_id == 1 || selectedItem.lead_form_submission_type_id == 3) && (
                  <>
                  {hasPaymentMethodLoaded == true && hasPaymentMethod == true && (
                    <div className='mt-20'>
                      {submitting == false && (
                        <button className='btn btn--confirm' onClick={() => handleConfirmPurchase()}>Confirm purchase</button>
                      )}

                      {submitting == true && (
                        <Spinner />
                      )}
                    </div>
                  )}

                  {showError==true && errorMessage.length > 0 && (
                    <div style={{color: 'red'}} className='mt-10'>
                      <i>{errorMessage}</i>
                    </div>
                  )}

                  {hasPaymentMethodLoaded == true && hasPaymentMethod == false && (
                    <div className='mt-20'>
                      <Link className='btn btn--confirm' to='/account/payment-method'>
                        Add a valid payment method <FaIcons.FaRegCreditCard />
                      </Link>
                    </div>
                  )}
                  </>
                )}

                {/* pay later / price - rec lead */}
                {paymentTypeLoaded == true && paymentType == 'pay_later' && (selectedItem.lead_form_submission_type_id == 1 || selectedItem.lead_form_submission_type_id == 3) && (
                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => handleConfirmPurchase()}>Confirm purchase</button>
                </div>
                )}

                {/* pay now - price / demo request / use credit */}
              {paymentTypeLoaded == true && paymentType == 'pay_now' && remainingCountLoaded == true && remainingCount > 0 &&
                (selectedItem.lead_form_submission_type_id == 2 || selectedItem.lead_form_submission_type_id == 4) && (
                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => handleConfirmPurchase()}>Use available credit</button>
                </div>
                )}

                {/* pay later - demo lead */}
              {paymentTypeLoaded == true && paymentType == 'pay_later' &&
                (selectedItem.lead_form_submission_type_id == 2 || selectedItem.lead_form_submission_type_id == 4) && (
                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => navigate('/select-plan')}></button>
                </div>
                )}

                {/* pay now - demo request / no credits left */}
                {paymentTypeLoaded == true && paymentType == 'pay_now' && remainingCountLoaded == true && remainingCount == 0 &&
                (selectedItem.lead_form_submission_type_id == 2 || selectedItem.lead_form_submission_type_id == 4) && (
                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => navigate('/select-plan')}>Purchase demo credits</button>
                </div>
                )}

                {/* pay later / demo lead  */}
              {paymentTypeLoaded == true && paymentType == 'pay_later' &&
                (selectedItem.lead_form_submission_type_id == 2 || selectedItem.lead_form_submission_type_id == 4) && (
                <div className='mt-20'>
                  <button className='btn btn--confirm' onClick={() => navigate('/select-plan')}>Confirm purchase</button>
                </div>
                )}
              </div>
            </div>

            <div style={{marginTop: '20px', fontSize: '12px', color: '#aaa'}}>
            By clicking 'Confirm Purchase' below you authorize LeadrPro to charge the card on file for this purchase.

            </div>
          </div>
        </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='center'>
            <svg style={{height: '40px', fill: '#412b6e'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </div>

            <div className='center mt-20' style={{fontSize: '20px'}}>
              <strong>Success! Your lead card is ready.</strong>
            </div>

            <div className='mt-10'><strong>What's next?</strong></div>
            
            <ul className='mt-10'>
              <li>Get started on your outreach—these leads are fresh and eager to hear from you!</li>
            </ul>

            <div className='mt-20 center' style={{fontSize: '20px', fontWeight: 'bold'}}>
              Confirmation #: {confirmationId}
            </div>

            <div className='mt-20'>
              <button className='btn btn--confirm' onClick={() => navigate('/dashboard/price-lead/' + selectedId)}>View full lead details</button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

  export default PriceLeads;