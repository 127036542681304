import './SavedBuyers.css';
import {useState, useEffect} from 'react';
import { getSavedBuyers, saveBuyer, savedSearchBuyers, createBuyerDemos } from '../../utils/api';
import React from 'react';
import heart from '../../assets/design/heart-vector.png';
import heart_blue from '../../assets/design/heart-blue.png';
import classNames from "classnames";
import {isAuthed, isSeller} from '../../services/auth.service';

function SavedBuyers() {
  const [results, setResults] = useState([]);
  const [completed, setCompleted] = useState(false);
  const colors = ['orange', 'purple', 'green', 'dodgerblue', 'teal', 'pink'];
  const [hasError, setHasError] = useState(false);
  const [showSelectedBuyers, setShowSelectedBuyers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const refEmail = React.createRef();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedBuyer, setSelectedBuyer] = useState({});
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [savedResults, setSavedResults] = useState([]);
  let imagePath = process.env.REACT_APP_IMAGE_PATH;
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  let authed = isAuthed();

  useEffect(() => {
    async function loadSavedBuyers(){
      let res = await getSavedBuyers();

      if (res.success){
        console.log('saved results', res);
      }
    }

    loadSavedBuyers();
  }, []);

  useEffect(() =>{
    async function loadResults() {
    //  let sizes = selectedFilters.filter(item => item.type === 'CompanySize').map(item => item.value);

    //  let personaTypeId = selectedFilters.filter(item => item.type === 'Persona').map(item => item.value);

     // let industries = selectedFilters.filter(item => item.type === "Industry").map(item => item.value);

     // let seniority = selectedFilters.filter(item => item.type === "Seniority").map(item => item.value);

      let response = await savedSearchBuyers();
      if (response.success){
        setResults(response.data);

        if(response.data.length > 0){
          let full = parseInt(response.data[0].full_count);
         // setTotalResults(full);
        }

        else{
          // no results found
        //  setTotalResults(0);
        }

        setCompleted(true);
      }

      let response2 = await getSavedBuyers();
      if (response2.success){
        let tmp1 = response2.data.map((item) => item.buyer_id);

        setSavedResults(tmp1);
      }
     }

     loadResults();
  }, []);

  async function handleSelectBuyer(id){
    let buyer = results.filter(item => item.registered_user_id === id)[0];

    if (selectedBuyers.find(item => item.registered_user_id===id)){
      // remove id
      setSelectedBuyers(selectedBuyers.filter(item => item.registered_user_id !== id));
    }

    else{
      // add id
      setSelectedBuyers([...selectedBuyers, buyer]);
    }
  }

  function handleSelectAll(){
    // set all results to selected
    setSelectAll(!selectAll);
  }

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    let total = 0;
    selectedBuyers.map(item => {
      total += item.credit_cost;
    });
    setTotalCredits(total);
  }, [selectedBuyers]);

  function bookDemo(e) {
    e.preventDefault();

    if (selectedBuyers.length > 0 && isAuthed())
      setShowSelectedBuyers(true);

    if (!isAuthed()){
      //setShowJoin(true);
    }
  }

  async function save(e, user_id) {
    e.preventDefault();

    if (!isAuthed()){
     // setShowJoin(true);
    }

    else{
    let res = await saveBuyer(user_id);

    if (!savedResults.includes(user_id))
      setSavedResults([...savedResults, user_id]);
    else{
      let tmp = savedResults.filter(item => item !== user_id);
      setSavedResults(tmp);
    }
  }
  }

  async function bookMeetings(){
    setShowSelectedBuyers(false);
    setShowCompleted(true);

    let response = await createBuyerDemos(selectedBuyers);
    let tmp = selectedBuyers.map(item => item.registered_user_id);

    let newResults = results.map(item => {
      if (tmp.includes(item.registered_user_id)){
        item.demo_status = 1;
      }

      return item;
    });

    setResults(newResults);
    setSelectedBuyers([]);
    setSelectAll(false);
  }

  function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    return `${rhours > 12 ? rhours - 12:rhours}:${rminutes < 10 ? "0" + rminutes : rminutes} ${rhours > 11 ? "PM" : "AM"}`;
    return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  }
  
  async function handleHourClick(i){
    console.log(i);
    setSelectedTime(i);
  }

  async function handleCancelTime(){
    setShowSelectedTime(false);
    setShowSelectedBuyers(true);
  }

  function loadDemoHours(){
    let el = [];
      for (let i = 0; i < 1440; i+=30){
        el.push(<div className={`demo__hours-item ${selectedTime===i?"demo__hours-item--selected":""}`} data-value={i} onClick={() => handleHourClick(i)}>{timeConvert(i)}</div>);
      }
  
      return (
        <div className="demo__hours">
          {el}
        </div>
      )
  }

  async function handleSaveTime(){
    setShowSelectedTime(false);
  }

  return (
    <div className="savedbuyers">
      <h1>Saved Searches</h1>

      <div style={{marginTop: "30px"}}>
      <table data-pagecount="10" data-id="lsdfj" id="buyertable" className={classNames("table pagination searchresults__table", {
        "hide":!completed
    })}>
        <tbody>
        <tr>
          <th>Persona type</th>
          <th>Company</th>
          <th>Seniority</th>
          <th>Size</th>
          <th>Industry</th>

          {authed && (
          <th>Credits and interest level</th>
          )}

          <th></th>
        </tr>

        <tr>
          <td>
            <label className="searchfilter__container">
              <input type="checkbox" className="searchfilter__checkbox" onChange={handleSelectAll} />
              <span className="searchfilter__checkbox-label">Select All</span>
              <span className="searchfilter__checkmark"></span>
          </label></td>
        </tr>
        {results.map((item, index) => (
      <tr className="buyerresults__card" key={index}>
        <td>
          <div className="searchresults__persona">

          {item.demo_status !== 1 && (
          <label className="searchfilter__container">
              <input type="checkbox" className="searchfilter__checkbox" checked={selectedBuyers.filter(item2 => item2.registered_user_id === item.registered_user_id).length > 0} onChange={() => handleSelectBuyer(item.registered_user_id)} />
          <span className="buyerresults__checkbox-label">{item.persona_type}</span>
          <span className="searchfilter__checkmark"></span>
          </label>
          )}

          {item.demo_status === 1 && (
            <span>{item.persona_type}</span>
          )}
          </div>
        </td>

        <td>
          <div className="searchresults__persona">
          <div className="searchresults__company-logo">
            {item.logo && (
                <img src={imageUrl + item.logo} alt="" />
                )}
                {!item.logo && (
                  <span className="searchresults__company-initial">{item.company_name[0]}</span>
                )}
          </div>

          <div className="searchresults__company-name">
            {item.company_name}
          </div>
          </div>
        </td>

        <td>
          {item.seniority}
        </td>

        <td>
          {item.company_size}
        </td>

        <td>
          {item.industry_name}
        </td>

        {authed && (
        <td>
          <div className="buyerresults__credits">
            <div className="searchresults__credits">{item.credit_cost}</div>
            <div className="interest-container">
              {/* <span className="searchresults__interest searchresults__interest--high">high</span> */}
            </div>
          </div>
        </td>
        )}

        <td>
          <div className="searchresults__buttons">
          <span className={classNames({"searchresults__save-selected": savedResults.filter((x => x === item.registered_user_id)).length > 0}, "searchresults__save")} onClick={(e) => save(e, item.registered_user_id)}>
                <img src={savedResults.filter((x => x === item.registered_user_id)).length > 0 ? heart_blue: heart} alt="" /> Save
              </span>
              
              {item.demo_status !== 1 && (
              <button className="btn-secondary btn searchresults__btn-book btn--action" onClick={bookDemo} >Book Demo</button>
              )}

              {item.demo_status === 1 && (
              <span>Demo pending</span>
              )}
          </div>
        </td>
      </tr>
      ))}
      </tbody>
      </table>
      </div>

      {showSelectedBuyers && (
        <>
        <div className="overlay">
          <div className="selectedBuyers">
            <div className="selectedBuyers__items">
              {selectedBuyers.map((item, index) => (
                <div className="selectedBuyers__item" key={index} style={{borderColor: colors[index]}}>
                  <div className="selectedBuyers__logo">
                  {item.logo && (
                  <img src={imagePath + "/assets/logos/" + item.logo} alt="" />
                  )}
                  {!item.logo && (
                    <span className="selectedBuyers__initial">{item.company_name[0]}</span>
                  )}
                  </div>

                  <div className="selectedBuyers__info">
                    <div className="selectedBuyers__role">{item.persona_type}</div>
                    <div className="selectedBuyers__company-name">{item.company_name}</div>
                  </div>

                  <div className="selectedBuyers__company-info">
                    <div>{item.seniority}</div>
                    <div>{item.company_size}</div>
                    <div>{item.industry_name}</div>
                  </div>

                  <div className="selectedBuyers__demo">
                    <div className="selectedBuyers__demo-duration" style={{fontWeight:"bold", marginBottom:"0px"}}>30 minute demo</div>
                    {/* <div>7:00pm (PST)</div>
                    <div>Mon 12, July 2022</div> */}
                  </div>

                  <div className="selectedBuyers__buttons">
                    {/* <div>
                      <button className="btn" onClick={() => handleChangeTime(item)}>Change time</button>
                    </div> */}

                    <div style={{marginTop:"0px"}}>
                      <span className="link link--cancel" onClick={() => handleSelectBuyer(item.registered_user_id)}>Delete</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="selectedBuyers__calendar">
              <Calendar onChange={onChange} value={value} tileClassName={tileClassName} onClickDay={handleClickDay} />
            </div> */}

            <div className="selectedBuyers__footer">
              <div className="selectedBuyers__footer-credits">
                <div>Total cost</div>
                <div className="selectedBuyers__footer-credits--bold">{totalCredits} credits</div>
              </div>

              <div className="selectedBuyers__buttons">
                <button className="btn btn--action" onClick={bookMeetings}>Book meetings</button>

                <a href="javascript:void(0)" style={{marginLeft: "15px"}} onClick={() => setShowSelectedBuyers(false)}>Cancel</a>
              </div>
            </div>
          </div>

          {showSelectedTime && (
          <div className="selectedBuyers__time">
        <div>
            <div className="account__back-link">
              <a href="javascript:void(0)" onClick={() => setShowSelectedTime(false)}>Back</a>
            </div>

            <div>
            <div className="selectedBuyers__time-logo">
            {selectedBuyer.logo && (
                <img src={"/assets/companylogos/" + selectedBuyer.logo} alt="" />
                )}
                {!selectedBuyer.logo && (
                  <span className="selectedBuyers__initial">{selectedBuyer.company_name[0]}</span>
                )}
          </div>
            </div>

            <div className="selectedBuyers__role">
              <div className="selectedBuyers__persona-type">{selectedBuyer.persona_type}</div>
              <div className="selectedBuyers__company-name">from {selectedBuyer.company_name}</div>
            </div>

            <div className="selectedBuyers__company-info">
              <div>{selectedBuyer.seniority}</div>
              <div>{selectedBuyer.company_size}</div>
              <div>{selectedBuyer.industry_name}</div>
            </div>

            <div style={{marginTop: "30px"}}>
              <div>Cost</div>
              <div>12 Credits</div>
            </div>
        </div>

        <div className="selectedBuyers__time-hours">
            <div className="selectedBuyers__selected-date">Monday, February 20</div>
             
            <div style={{marginTop: "20px"}}>
              {loadDemoHours()}
            </div>

            <div>
              <button className="btn btn--primary" onClick={() => handleSaveTime()}>Save time</button>
            </div>

            <div className="center">
              <a href="javascript:void(0)" className="link--cancel" onClick={() => handleCancelTime()}>Cancel</a>
            </div>
        </div>

        <div>
          {/* <Calendar onChange={onChange} value={value} tileClassName={tileClassName} onClickDay={handleClickDay} /> */}
        </div>
      </div>
          )}
      </div>
      </>
      )}
    </div>
  )
}

export default SavedBuyers;