import './QualifierQuestions.css';
import {useEffect, useState} from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import { getUserCompany, addQualifierQuestion, removeQualifierQuestion, updateQualifierQuestion, refreshToken, qualifierQuestionMoveUp, qualifierQuestionMoveDown } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaArrowUp, FaArrowDown, FaTimes } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const QualifierQuestions = () => {
  const [newQuestion, setNewQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showQuestionEdit, setShowQuestionEdit] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [removing, setRemoving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCompany(){
      // return the current users company information
      let result = await getUserCompany();
      if (result.success){
        let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions} = result.data;
        setQuestions(questions);
        setLoading(false);
        }

        else if (result.status === 403){
          let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let result = await getUserCompany();
            if (result.success){
              let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions} = result.data;
              setQuestions(questions);
              setLoading(false);
            }
          }
        }
      }

    getCompany();
  }, []);

  async function getCompany(){
    // return the current users company information
    let result = await getUserCompany();
    if (result.success){
      let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions} = result.data;
      setQuestions(questions);
      setLoading(false);
      }

      else if (result.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let result = await getUserCompany();
          if (result.success){
            let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions} = result.data;
            setQuestions(questions);
            setLoading(false);
          }
        }
      }
    }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function editQuestion(qualifier_question_id){
    let q = questions.find(item => item.qualifier_question_id === qualifier_question_id);
    setCurrentQuestion(q);
    setShowQuestionEdit(true);
  }

  async function removeQuestion(qualifier_question_id){
    setSelectedId(qualifier_question_id);
    setShowConfirmRemove(true);
    // let new_questions = questions.filter(item => item.qualifier_question_id !== qualifier_question_id);
    // setQuestions(new_questions);

    // await removeQualifierQuestion(qualifier_question_id);
    // loadSaveBanner();
  }

  async function handleConfirmRemove(){
    setRemoving(true);
    let new_questions = questions.filter(item => item.qualifier_question_id !== selectedId);
    setQuestions(new_questions);

    let res = await removeQualifierQuestion(selectedId);

    if (res.success){
      loadSaveBanner();
      setShowConfirmRemove(false);
      setRemoving(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await removeQualifierQuestion(selectedId);

      if (res.success){
        loadSaveBanner();
        setShowConfirmRemove(false);
        setRemoving(false);
      }
    }
  }

  async function updateQuestion(){
    let res = await updateQualifierQuestion(currentQuestion.qualifier_question_id, currentQuestion.question);

    if (res.success){
      setShowQuestionEdit(false);

      let obj = {qualifier_question_id: currentQuestion.qualifier_question_id, question: currentQuestion.question};
      let _tmp = questions.filter(item => item.qualifier_question_id !== currentQuestion.qualifier_question_id);
      _tmp.push(obj);
      setQuestions(_tmp);
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateQualifierQuestion(currentQuestion.qualifier_question_id, currentQuestion.question);

      if (res.success){
        setShowQuestionEdit(false);

        let obj = {qualifier_question_id: currentQuestion.qualifier_question_id, question: currentQuestion.question};
        let _tmp = questions.filter(item => item.qualifier_question_id !== currentQuestion.qualifier_question_id);
        _tmp.push(obj);
        setQuestions(_tmp);
        loadSaveBanner();
      }
    }
  }

  async function saveQuestion(){
    if (newQuestion.length === 0){
      alert("Must enter a valid question");
      return;
    }
    let result = await addQualifierQuestion(newQuestion);

    if (result.success){
      let id = result.data;
      let obj = {qualifier_question_id: id, question: newQuestion};
      let new_array = questions;
      new_array.push(obj);
      setQuestions(new_array);

      loadSaveBanner();
    }

    else if (result.status === 403){
      await refresh();

      let result = await addQualifierQuestion(newQuestion);

      if (result.success){
        let id = result.data;
        let obj = {qualifier_question_id: id, question: newQuestion};
        let new_array = questions;
        new_array.push(obj);
        setQuestions(new_array);

        loadSaveBanner();
      }
    }
    setNewQuestion('');
  }

  function handleQuestionChange(value){
    setCurrentQuestion({...currentQuestion, question: value})
  }

  async function moveUp(qualifier_question_id, index){
    let prev = questions[index-1];
    let res = await qualifierQuestionMoveUp(qualifier_question_id, prev.qualifier_question_id, index);

    if (res.success){
     await getCompany();
     loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await qualifierQuestionMoveUp(qualifier_question_id, prev.qualifier_question_id, index);

      if (res.success){
        await getCompany();
        loadSaveBanner();
      }
    }
  }

  async function moveDown(qualifier_question_id, index){
    let next = questions[index+1];
    let res = await qualifierQuestionMoveDown(qualifier_question_id, next.qualifier_question_id, index);

    if (res.success){
     await getCompany();
     loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await qualifierQuestionMoveDown(qualifier_question_id, next.qualifier_question_id, index);

      if (res.success){
      await getCompany();
      loadSaveBanner();
      }
    }
  }

  return (
    <div className="qualifierquestions">
      <Helmet>
        <title>LeadrPro - Qualification Questions</title>
      </Helmet>
      <div className=""><button className='btn' onClick={() => navigate(-1)}>Back</button></div>
      <div className="account__header">Qualification Questions</div>

      <div className="qualifierquestions__description">
        <p>Add qualification questions to vet any potential prospects before you accept any demos.</p>
        <p>Add a minimum of (3) questions, a few examples being:</p>

        <div className='qualifierquestions__examples'>
          <p>
            Q: What is your budget for this particular product?
          </p>
          <p>
            Q: How big is your team/company using this product?
          </p>
          <p>
            Q: Are you the decision maker for your company?
          </p>
        </div>
      </div>

      {loading == true && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {loading === false && questions.length === 0 && (
        <div className='qualifierquestions__no-questions'>
          You have not added any questions...
        </div>
      )}

      <div className="qualifierquestions__body">
        <div className="">
            <div>
                {questions.length > 0 && (
                  <div className='companyedit__questions'>
                  {questions.map((item, index) => (
                    <div className='companyedit__question' key={index}>
                      <div className='companyedit__question-label'>{`Question: ${index + 1}`}</div>
                      <div>{item.question}</div>
                      <div className='qualifierquestions__question-btns'>
                        <span className='btn' onClick={() => removeQuestion(item.qualifier_question_id)}>Remove</span>
                        <span className='btn' onClick={() => editQuestion(item.qualifier_question_id)}>Edit</span>

                        {index > 0 && (
                          <span className='btn' onClick={() => moveUp(item.qualifier_question_id, index)}>Move Up <FaArrowUp /></span>
                        )}

                        {index < questions.length - 1 && (
                          <span className='btn' onClick={() => moveDown(item.qualifier_question_id, index)}>Move Down <FaArrowDown /></span>
                        )}
                      </div>
                    </div>
                  ))}
                  </div>
                )}

                <div style={{padding: '0px 20px'}}>
                  <input type="text" className='input' placeholder='Enter question...' value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />

                  <div className='mt-10'>
                    <button className='btn' onClick={() => saveQuestion()}>Add qualifier question</button>
                  </div>
                </div>
              </div>
            </div>
        </div>

        {showQuestionEdit && (
        <>
          <div className='overlay'></div>
          <div className='companyedit__modal modal'>
            <div className='modal__close' onClick={() => setShowQuestionEdit(false)}>
              <FaTimes />
            </div>

            <h2 style={{fontSize: "22px", fontWeight: "bold"}}>Update qualifer question</h2>
            <br/>

            <div>
              <textarea style={{height: "100px"}} className='input' value={currentQuestion.question} onChange={(e) => handleQuestionChange(e.target.value)} />
            </div>

            <div className='center' style={{marginTop: "20px"}}>
              <button className="btn btn--primary" onClick={() => updateQuestion()}>Update</button>
              <div className='link link--cancel' style={{marginTop: "10px"}} onClick={() => setShowQuestionEdit(false)}>Cancel</div>
            </div>
          </div>
        </>
      )}

      {showConfirmRemove && (
        <FadeIn delay={100} duration={100}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirmRemove(false)}>
              <FaTimes />
            </div>

            <div className='modal__h2 center'>
              Are you sure you want to delete this qualification question?
            </div>

            <div className='center mt-20'>
              {removing === false && (
              <button className='btn btn--danger' onClick={() => handleConfirmRemove()}>Confirm</button>
              )}

              {removing === true && (
                <div className='mt-20'>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </FadeIn>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
        Profile updated successfully!
        </div>
      </FadeIn>
      )}
    </div>
  );
}

export default QualifierQuestions;