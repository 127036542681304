import { useState, useEffect } from 'react';
import './ResetPassword.css';
import {useLocation} from "react-router-dom";
import { resetPassword, validateForgotPassword } from '../../utils/api';
import React from "react";
import {Helmet} from "react-helmet";

const ResetPassword = () => {
    const search = useLocation().search;
    const [hasError, setHasError]= useState(false);
    const [error, setError]= useState('');
    const [token, setToken] = useState('');
    const [validToken, setValidToken] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    let tok = new URLSearchParams(search).get('token');

    useEffect(() => {
       async function validation(){
        //setToken(tok);
        let res = await validateForgotPassword(tok);

        if (res.success){
            setHasError(false);
            setError('');
            setValidToken(true);
            setIsChecked(true);
        }

        else{
            setHasError(true);
            setError("Token has expired");
            setValidToken(false);
            setIsChecked(true);
        }
       }

       validation();
    }, [])

    async function handleSubmit(e) {
        e.preventDefault();
        let _password = e.target.elements.password.value;
        let _confirm = e.target.elements.confirm.value;

        if (_password === _confirm){
            let res = await resetPassword(_password, tok);

            if (res.success){
                setShowConfirm(true);
            }

            else{
               setHasError(true);
               setError(res.message);
            }
        }

        else{
            setHasError(true);
            setError("Passwords do not match");
        }
    }

  return (
    <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="card resetpassword">
            {!showConfirm && (
                <form onSubmit={handleSubmit}>
                    <h1 className="card__header">Reset password</h1>

                    {validToken && (
                        <>
                    <div className='form__group'>
                        <label className='label input__label' for='txtEmail'>Set a new password</label>

                    <input className='input' type='password' id='txtPassword' required placeholder='Enter password' name="password" minLength="6" />
                    </div>

                    <div className='form-group'>
                        <label className='label input__label' for='txtConfirm'>Confirm password</label>

                    <input className='input' type='password' minLength="6" id='txtConfirm' required placeholder='Confirm password' name="confirm" />
                    </div>

                    {hasError && (
                        <div className="label--error">
                            {error}
                        </div>
                    )}

                    <div className="form__group" style={{marginTop: "72px"}}>
                        <button className='btn btn-primary'>
                            Continue
                        </button>
                    </div>

                    <p className="card-text center">Just remembered? <a href="/login">Sign in</a></p>
                    </>
                    )}

                    {!validToken && isChecked && (
                        <div>
                        <div>
                            Token has expired
                        </div>

                        <div className="center" style={{marginTop: "20px"}}>
                            <a href="/forgot-password">Send it again</a>
                        </div>
                        </div>
                    )}
                </form>
            )}

                {showConfirm && (
                    <>
                    <h1 className="card__header">Success!</h1>
                    
                    <div className="card__text">
                        Your password has been successfully changed.
                    </div>

                    <div className="form__group" style={{marginTop: "15px"}}>
                        <a href="/login">Sign in</a>
                    </div>
                    </>
                )}
            </div>
        </div>
  )
}

export default ResetPassword;
