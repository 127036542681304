import './Home3.css';
import 'animate.css';

import unicorn from '../../assets/design/home3/hero.png';
import unicorn_logos from '../../assets/design/home3/unicorn-logos.png';
import arrow from '../../assets/design/home3/arrow.png';
import payoutlogos from '../../assets/design/home3/logos.png';
import sellerlogos from '../../assets/design/home3/seller_logos.png';
import howitworks1 from '../../assets/design/home3/howitworks_sellers1.png';
import howitworks2 from '../../assets/design/home3/howitworks_sellers2.png';
import howitworks2b from '../../assets/design/home3/howitworks2b.png';
import howitworks3 from '../../assets/design/home3/howitworks_sellers3.png';
import community from '../../assets/design/home3/community.png';
import docusign from '../../assets/design/home3/docusign.png';
import travelers from '../../assets/design/home3/travelers.png';
import meta from '../../assets/design/home3/meta.png';
import ulta from '../../assets/design/home3/ulta.png';
import involve from '../../assets/design/home3/involve.png';
import payengine from '../../assets/design/home3/payengine.png';
import liquid from '../../assets/design/home3/liquid.png';
import boomi from '../../assets/design/home3/boomi.png';
import roi from '../../assets/design/home3/roi.png';
import tc from '../../assets/design/home3/tc.png';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function SellersHome() {
  const [acv, setAcv] = useState(0);
  const [conversion, setConversion] = useState(0);
  const [paybackPeriod, setPaybackPeriod] = useState(3);
  const [costpersql, setCostPerSql] = useState(0);
  const [personaTypes, setPersonaTypes] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [showSmb, setshowSmb] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState({});  // array index
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(function () {
    document.title = 'LeadrPro - The Power is Yours';
  }, []);

  useEffect(() => {
    setCurrentTestimonial(testimonials[currentTestimonialIndex]);
  }, [currentTestimonialIndex]);

  useEffect(() => {
    //setCostPerSql(parseInt(acv * 0.1 * conversion));
    setCostPerSql(parseInt(((acv / 12) * paybackPeriod) * (conversion / 100)));

    if (costpersql > 0)
      document.getElementById('calculator__savings').style.display =
        'inline-block';
  }, [acv, conversion, paybackPeriod]);

  return (
    <div className='home3'>
      <div className='home3__wrapper'>
      <Helmet>
        <meta name='description' content='' />
      </Helmet>

      <section className='home3__hero'>
        <div className='home3__hero-left-side'>
          <div className='home3__hero-left-side-header'>
            Book demos with prospects and fill your pipeline <span className='home3__hero-highlight'>instantly</span>

            <span className='home3__hero-arrow'><img src={arrow} alt='' /></span>
          </div>

          <div className='home3__hero-button'>
            <a href='/signup/seller' className='btn btn--primary'>Create your free account</a>
          </div>

          <div className='home3__hero-testimonial'>
            <div className='home3__hero-testimonial-logo'>
              <img src={boomi} alt='' />
            </div>

            <div className='home3__hero-testimonial-text'>
              <div>"Great supplement to our current SDR efforts. We've been able to reduce our overhead by 50%."</div>
              <div className='home3__hero-testimonial-author'>- Head of Sales Enablement at <strong>Boomi</strong></div>
            </div>

            <div className='home3__techcrunch'>
              <a href='https://techcrunch.com/2022/12/07/product-demo-leadrpro/' target="_blank">
              <span className='home3__techcrunch-copy'>As featured in </span>
              <img src={tc} alt='' />
              </a>
            </div>
          </div>
        </div>

        <div className='home3__hero-right-side'>
          <div className='home3__hero-unicorn'>
            <img src={unicorn_logos} alt='' />
          </div>
        </div>
      </section>

      <section className='home3__payout-logos'>
        <div className='home3__payout-logos-header'>Trusted by companies backed by</div>

        <div className='home3__payout-logos-image'>
          <img src={sellerlogos} alt='' />
        </div>
      </section>

      <section className='home3__howitworks'>
        <div className='home3__howitworks-header'>How It Works</div>

        <div className='home3__howitworks-steps'>
          <div className='home3__howitworks-step home3__howitworks-step--1'><img src={howitworks1} alt='' /></div>

          <div className='home3__howitworks-step home3__howitworks-step--2'>
            <img src={howitworks2} alt='' />
          </div>

          <div className='home3__howitworks-step home3__howitworks-step--3'>
            <img src={howitworks3} alt='' />
          </div>
        </div>

        <div className='center'>
          <a className='btn btn--primary' href='/pricing'>Pricing</a>
        </div>
      </section>
    </div>

    <section className='home3__testimonials'>
        <div className='home3__testimonials-header'>Testimonials</div>

        <div className='home3__testimonials-items'>
          <div className='home3__testimonials-item home3__testimonials-item--1'>
            <div className='home3__testimonials-item-text'>"LeadrPro helped us get the at-bats we needed to pivot the product to market fit. We were able to close a $16MM Series A."</div>

            <div className='home3__testimonials-item-author'>
              <div className='home3__testimonials-item-logo'><img src={involve} alt='' /></div>
              <div className='home3__testimonials-item-author-name'>Involve</div>
            </div>
          </div>

          <div className='home3__testimonials-item home3__testimonials-item--2'>
            <div className='home3__testimonials-item-text'>"LeadrPro has been instrumental in us getting to a Series A."</div>

            <div className='home3__testimonials-item-author'>
              <div className='home3__testimonials-item-logo'><img src={payengine} alt='' /></div>
              <div className='home3__testimonials-item-author-name'>PayEngine</div>
            </div>
          </div>

          <div className='home3__testimonials-item home3__testimonials-item--3'>
            <div className='home3__testimonials-item-text'>"We replaced our entire SDR team with LeadrPro and generate 3x the volume at 30% the cost"</div>

            <div className='home3__testimonials-item-author'>
              <div className='home3__testimonials-item-logo'><img src={liquid} alt='' /></div>
              <div className='home3__testimonials-item-author-name'>Liquid</div>
            </div>
          </div>
        </div>
      </section>

      <section className='home3__community'>
        <div className='home3__community-header'>The LeadrPro ROI</div>

        <div className='home3__community-logos'>
          <img src={roi} style={{height: "150px"}} alt='' />
        </div>
      </section>
    </div>
  );
}

export default SellersHome;