import './AvailableTimes.css';
import {useState,useEffect} from 'react';
import close from '../../assets/design/dashboard/close.png';
import FadeIn from '../../utils/fadein';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';
import { getSellerWorkHours, saveRescheduleAvailableTimes, addRescheduleReason, refreshToken } from '../../utils/api';
import { FaRegCalendarCheck, FaTimes } from 'react-icons/fa';

function RescheduleTimes({company_name, demo, demos, setDemos, demo_id, seller_id, setShowReschedule, showSteps = true, enableReason = true}) {
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [sellerWorkHours, setSellerWorkHours] = useState([]);
  const [dates, setDates] = useState([]);
  const [hasWorkHours, setHasWorkHours] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReason, setShowReason] = useState(enableReason);
  const [reason, setReason] = useState('');
  const [submittingReason, setSubmittingReason] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    async function loadSellerWorkHours(){
      let res = await getSellerWorkHours(seller_id);
      if (res.success){
        setSellerWorkHours(res.data);

        if (res.data.length > 0){
          setHasWorkHours(true);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getSellerWorkHours(seller_id);

          if (res.success){
            setSellerWorkHours(res.data);

            if (res.data.length > 0){
              setHasWorkHours(true);
            }
          }
        }
      }
    }

    loadSellerWorkHours();
  }, []);

  useEffect(() => {
    async function loadDates(){
      let today = new Date();
      let _dates = [];
      today.setDate(today.getDate() + 1);

      // load all days for the next 2 weeks
      for (let i = 0; i < 14; i++){
        let _start = new Date(today);
        _start.setDate(today.getDate() + i);

        let _dayofweek = _start.getDay();

        if (sellerWorkHours.length > 0){
          let tmp = sellerWorkHours.filter(item => item.day_of_week === _dayofweek);
          if (tmp.length > 0){
            _dates.push(_start);
          }
          else{
            console.log('not found');
          }
        }

        else{
          _dates.push(_start);
        }
      }

      setDates(_dates);
    }

    loadDates();
  }, [sellerWorkHours]);

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  async function handleBackClick(){
    setShowReschedule(false);
  }

  async function handleDateSelected(e, item){
    if (e.target.checked){
      let _date = new Date(item);
      _date.setHours(e.target.value);
      _date.setMinutes(0);
      _date.setSeconds(0);

      setSelectedTimes([...selectedTimes, _date]);
    }

    else{
     // let _new = selectedTimes.filter(tmp => tmp.item === )
     let _date = new Date(item);
     let _filtered = selectedTimes.filter(tmp => {
      if (tmp.getMonth() === _date.getMonth() && tmp.getDate() === _date.getDate() && tmp.getHours() === parseInt(e.target.value))
        return false;

      return true;
      //return tmp.getMonth() !== _date.getMonth() && tmp.getDate() !== _date.getDate() && tmp.getHours() !== parseInt(e.target.value);
     });

     setSelectedTimes(_filtered);
    }
  }

  async function handleSubmit(){
    // save available times
    if (selectedTimes.length === 0){
      alert('Must select at least 3 times');
    }

    else if (selectedTimes.length < 3){
      alert("Must select at least 3 times");
    }

    else{
      setSubmitting(true);
      // submit times and set demo to 'Approved' for buyer
      let _timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let res = await saveRescheduleAvailableTimes(demo.demo_id, selectedTimes, _timezone);

      if (res.success){
        setSubmitting(false);
        setShowConfirmation(true);

        let _filter = demos.filter(tmp => tmp.demo_id !== demo.demo_id);
        let _obj = Object.assign({}, demo);
        _obj.buyer_status = 'Rescheduled';
        _obj.seller_status = 'Approved';
        _filter.push(_obj);
        setDemos(_filter);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await saveRescheduleAvailableTimes(demo.demo_id, selectedTimes, _timezone);

          if (res.success){
            setSubmitting(false);
            setShowConfirmation(true);

            let _filter = demos.filter(tmp => tmp.demo_id !== demo.demo_id);
            let _obj = Object.assign({}, demo);
            _obj.buyer_status = 'Rescheduled';
            _obj.seller_status = 'Approved';
            _filter.push(_obj);
            setDemos(_filter);
          }
        }
      }
    }
  }

  async function handleReasonSubmit(){
    if (reason === ''){
      alert("Please select a reason");
      return;
    }

    setSubmittingReason(true);
    let res = await addRescheduleReason(demo.demo_id, reason);

    if (res.success){
      setShowReason(false);
      setSubmittingReason(false);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addRescheduleReason(demo.demo_id, reason);

        if (res.success){
          setShowReason(false);
          setSubmittingReason(false);
        }
      }
    }
  }

  function loadHoursCheckbox(day_of_week, item){
    let _now = new Date();

    let seller_timezone = demo.seller_timezone;

    if (seller_timezone === null){
      seller_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    let seller_offset = getOffset(seller_timezone) * -1;
    
   // let seller_offset= getOffset('America/New_York');
    let buyer_offset = _now.getTimezoneOffset();
   // let diff = (Math.abs(seller_offset) - Math.abs(buyer_offset)) / 60;
    let diff = (seller_offset - buyer_offset) / 60;

    diff = Math.round(diff); // for 30 minute offsets

    let el = [];
      if (sellerWorkHours.length > 0){
        let workhour = sellerWorkHours.find(x => x.day_of_week === day_of_week);

        if (workhour){
          let start = Math.floor(workhour.start_time / 100) + diff;
          let end = Math.floor(workhour.end_time / 100) + diff;

          if (start < 0){
            start = start + 24;
            end = end + 24;
          }

          //end = 24;
          // fix asia issue
          if (end > 24){
            end = 24;
          }

          for (let i = start; i < end; i++){
              if (i < 0)
              continue;

              el.push(<div key={i}><span><input type="checkbox" id={day_of_week + '_' + i} value={i} onChange={(e) => handleDateSelected(e, item)} /></span><label htmlFor={day_of_week
                + '_' + i}> {i <= 12 ? (i === 0 ? "12" : i) : i - 12}:00{i >= 12? "pm" : "am"}</label></div>);
          }
        }
    }

    else{
      let start = 9 + diff;
      let end = 15 + diff;

      //end = 27;

      // fix asia issue
      if (end > 24){
        end = 24;
      }

      for (let i = start; i < end; i++){
          el.push(<div><span><input type="checkbox" value={i} id={day_of_week + '_' + i} onChange={(e) => handleDateSelected(e, item)} /></span><label htmlFor={day_of_week
            + '_' + i}> {i <= 12 ? i : i - 12}:00{i >= 12? "pm" : "am"}</label></div>);
      }
    }

    return (
      <>
        {el}
      </>
    )
  }

return (
  <FadeIn delay={120} duration={300}>
  <div className='availabletimes'>
    <div>

    {!showConfirmation && !showReason && (
      <div className='modal__body'>
      <div className='mar10--top qualifierquestion__label'>Reschedule your meeting with <span className='dashboarddemo__highlight'>{company_name}</span></div>

      <div style={{margin: '10px 0px', backgroundColor: '#e1ecff', color: 'black', padding: '5px', borderRadius: '5px'}}>
        <strong>Company timezone: </strong><i>{demo.seller_timezone}</i>
      </div>
      
      <div>
        Select which time(s) you will be available for the meeting:
      </div>

      <div style={{fontSize: "12px", marginTop:"10px"}}>
        (* Select all that apply)
      </div>

      <div style={{float: 'right', fontSize: 'small'}}>
        Times shown in your local timezone
      </div>

      <div className='availabletimes__times'>
        {dates.map((item, index) => (
          <>
            <div className='availabletimes__time' key={index}>
              <div className='availabletimes__day'>{dayOfWeek[item.getDay()]} - {item.getMonth() + 1}/{item.getDate()}</div>

              {hasWorkHours && (
                <div className='availabletimes__hours'>
                  {loadHoursCheckbox(item.getDay(), item)}
                </div>
              )}

              {!hasWorkHours && (
                <>
                <div className='availabletimes__hours'>
                  {loadHoursCheckbox(item.getDay(), item)}
                </div>
                </>
              )}
          </div>
          </>
        ))}
      </div>
      </div>
    )}
    </div>

    {showConfirmation && (
      <div className='modal__body'>
        <div className='center' style={{marginBottom: "20px", fontSize: '50px'}}>
          <FaRegCalendarCheck />
        </div>

        <p className=' center'>
          Your times have been successfully submitted!<br/>
          Please wait up to 24 hours for confirmation from the other person.
        </p>

        <p>
          If you have any questions/comments or need further assistance with this demo, please feel <Link to='/leave-feedback' className='link'>free to reach out</Link>.
        </p>

        <p className='center' style={{fontStyle:'italic', fontSize: '14px', marginTop: "20px"}}>
          ** Do not show up to your original scheduled date/time as it is on longer available.
        </p>

        <div className='center' style={{marginTop: "30px"}}>
          <button className='btn btn--secondary' onClick={() => handleBackClick()}>Close</button>
        </div>
      </div>
    )}

    {!showConfirmation && !showReason && (
      <div className='center' style={{marginTop: "20px"}}>
        {submitting === false && (
          <button className='btn btn--confirm' onClick={() => handleSubmit()}>Submit Times</button>
        )}

        {submitting && (
          <Spinner />
        )}
      </div>
    )}

    {showReason && (
      <div className='modal__body'>
        <h2 className='modal__h2'>Are you sure you want to reschedule?</h2>

        <p>
          You will lose the current timeslot selected and will need to resubmit new times.
        </p>
        <p>
          Please select a reason from the following:
        </p>

        <div style={{marginTop: "30px"}}>
          <div>
            <input type="radio" id='rb1' name="reschedulereason" onChange={() => setReason('Meeting conflict')}/> <label htmlFor='rb1'>Meeting conflict</label>
          </div>

          <div>
            <input type="radio" id='rb2' name="reschedulereason" onChange={() => setReason('Work emergency')}/> <label htmlFor='rb2'>Work emergency</label>
          </div>

          <div>
            <input type="radio" id='rb3' name="reschedulereason" onChange={() => setReason('Technology issue / connectivity problem')}/> <label htmlFor='rb3'>Technology issue / connectivity problem</label>
          </div>

          <div>
            <input type="radio" id='rb4' name="reschedulereason" onChange={() => setReason('Miscommunication or scheduling error')}/> <label htmlFor='rb4'>Miscommunication or scheduling error</label>
          </div>

          <div>
            <input type="radio" id='rb5' name="reschedulereason" onChange={() => setReason('Unexpected workload or deadline')}/> <label htmlFor='rb5'>Unexpected workload or deadline</label>
          </div>

          <div>
            <input type="radio" id='rb6' name="reschedulereason" onChange={() => setReason('Unforeseen circumstances')}/> <label htmlFor='rb6'>Unforeseen circumstances (such as inclement weather or unexpected event)</label>
          </div>

          <div className='' style={{marginTop: "20px"}}>
            {submittingReason === false && (
              <button className='btn btn--primary' onClick={() => handleReasonSubmit()}>Next</button>
            )}

            {submittingReason && (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    )}

    <div className='modal__close' onClick={() => handleBackClick()}>
      <FaTimes />
      </div>
  </div>
  </FadeIn>
)
}

export default RescheduleTimes;