import './AddPaymentMethod.css';
import {useEffect, useState} from 'react';
import { getCustomerId, createSetupIntent } from '../../utils/api';
import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
 import SetupForm from './SetupForm';
 import PaymentStatus from './PaymentStatus';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethod = () => {
  const [intent, setIntent] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [options, setOptions] = useState({});

  async function handleCreateIntent(e){
    e.preventDefault();
    let res = await createSetupIntent();

    if (res.success){
      setIntent(res.data);

      let _obj = {
        clientSecret: res.data.client_secret
      }

      setOptions(_obj);
      setShowForm(true);
    }

    else{
     // alert('failed creating intent');
    }
  }

  return (
    <div className="addpaymentmethod">
      <p style={{fontSize: 'small', marginBottom: '30px'}}>
        * By adding your payment information, You agree to be charged a 1-time test fee of $1, which will be refunded to you within 24-48 hours.
      </p>
      {showForm === false && (
      <div className='center'>
        <button className='btn btn--primary' onClick={(e) => handleCreateIntent(e)}>Add payment method</button>
      </div>
      )}

      {showForm && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm />
        </Elements>
      )}
    </div>
  );
}

export default AddPaymentMethod;