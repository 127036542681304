import './LeadForm.css';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { FaQuestionCircle, FaRegCalendarAlt, FaGoogle, FaApple, FaMicrosoft, FaTimes, FaCheckCircle } from 'react-icons/fa';
import csrep from './assets/csrep.jpg';
import { saveLeadFormSubmit } from '../../utils/api';
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import { Spinner } from '@chakra-ui/react';

function LeadForm({showform, setShowForm, formType, company_id}) {

  const [showAdviceSuccess, setShowAdviceSuccess] = useState(false);
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [email,setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [lookingFor, setLookingFor] = useState('');
  const [timeToCall, setTimeToCall] = useState('');
  const [enableEmail, setEnableEmail] = useState(false);
  const [enablePhone, setEnablePhone] = useState(false);
  const [referenceId, setReferenceId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const currentuser = getCurrentUser();

  async function handleSubmitAdvice(){
    if (isAuthed() == false && (name.length == 0 || lookingFor.length == 0)){
      alert("Please complete all required fields!");
      return;
    }

    if (isAuthed() == false && email.length == 0 && phoneNumber.length == 0){
      alert("Plese let us know how we can reach you!");
      return;
    }

    setSubmitting(true);

    let res = await saveLeadFormSubmit(name, jobTitle, email, phoneNumber, formType, lookingFor, company_id, timeToCall, enableEmail, enablePhone, companyName);

    if (res.success){
      setShowAdviceSuccess(true);
      setShowForm(false);
      setReferenceId(res.data);
      setSubmitting(false);

      setEnableEmail(false);
      setEnablePhone(false);
    }
  }

  return (
     <>
      {showform && (
        <>
          <div className='overlay'></div>
          <div className='modal browseproducts__modal'>
            <div className='modal__close' onClick={() => setShowForm(false)}>
              <FaTimes />
            </div>

            {formType == 'Expert' && (
            <div className='leadform__modal-header'>
              <div className='leadform__csrep-image'>
                <img src={csrep} alt='' />
                <div className='leadform__csrep-name'>Chris</div>
              </div>

              <div className='leadform__csrep-description'>
                {isAuthed() && (
                  <div className='leadform__welcome-back'>
                    Welcome back <strong>{currentuser.first_name}!</strong>
                  </div>
                )}
                <h3>Talk to a software expert for free</h3>

                <ul className='mt-10 mb-10'>
                  <li><FaCheckCircle /> Get 3-4 recommendations from our experts for free</li>
                  <li><FaCheckCircle /> Get direct answers to your software related questions</li>
                  <li><FaCheckCircle /> We'll negotiate a meeting between you and the desired solution</li>

                </ul>
              </div>
            </div>
            )}

            {formType == 'Pricing' && (
              <div className='leadform__modal-header'>
              <div className='leadform__csrep-image'>
                <img src={csrep} alt='' />
                <div className='leadform__csrep-name'>Chris</div>
              </div>

              <div>
              {isAuthed() && (
                  <div className='leadform__welcome-back'>
                    Welcome back <strong>{currentuser.first_name}!</strong>
                  </div>
                )}

                <h3>Request pricing information </h3>

                <ul className='mt-10 mb-10'>
                  <li><FaCheckCircle /> Get direct pricing information from our experts for free</li>
                  <li><FaCheckCircle /> Get answers to questions you may have</li>
                  <li><FaCheckCircle /> We'll negotiate a meeting between you and the desired solution</li>

                </ul>
              </div>
            </div>
            )}

          {formType == 'RequestDemo' && (
              <div className='leadform__modal-header'>
              <div className='leadform__csrep-image'>
                <img src={csrep} alt='' />
                <div className='leadform__csrep-name'>Chris</div>
              </div>

              <div>
              {isAuthed() && (
                  <div className='leadform__welcome-back'>
                    Welcome back <strong>{currentuser.first_name}!</strong>
                  </div>
                )}

                <h3>Request a free product demo </h3>

                <ul className='mt-10 mb-10'>
                  <li><FaCheckCircle /> Get direct pricing information from our experts for free</li>
                  <li><FaCheckCircle /> Get answers to questions you may have</li>
                  <li><FaCheckCircle /> We'll negotiate a meeting between you and the desired solution</li>

                </ul>
              </div>
            </div>
            )}

            <div className='browseproducts__modal-form'>
              {formType == 'Expert' && (
              <div><strong>Tell us a bit about yourself and what you're looking for</strong></div>
              )}

              {formType == 'Pricing' && (
                <div>
                  <strong>Any additional details about your specific company needs?</strong>
                </div>
              )}

              <div>
                {isAuthed() == false && (
                  <>
                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Your name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Your job title' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                    </div>

                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Your company name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </div>
                  </>
                )}

                <div className='form-group'>
                  <textarea id='txtWhatAreYouLookingFor' className='input' placeholder='What are you looking for?' value={lookingFor} onChange={(e)=> setLookingFor(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <strong>How would you like us to contact you?</strong>
                  <div>
                    <input type='checkbox' value={enableEmail} id='cbEmail' onChange={(e) => setEnableEmail(e.target.checked)} /> <label htmlFor='cbEmail'>Email</label>
                  </div>

                  <div>
                    <input type='checkbox' value={enablePhone} id='cbPhone' onChange={(e) => setEnablePhone(e.target.checked)} /> <label htmlFor='cbPhone'>Phone</label>
                  </div>

                  {enableEmail == true && isAuthed() == false && (
                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Contact email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  )}

                  {enablePhone == true && isAuthed() == false && (
                    <>
                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Contact phone number' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>

                    <div className='form-group'>
                      <input type='text' className='input' placeholder='Best time to call' value={timeToCall} onChange={(e) => setTimeToCall(e.target.value)} />
                    </div>
                    </>
                  )}
                </div>

                <div className='form-group'>
                  {submitting == false && (
                  <button className='btn' onClick={() => handleSubmitAdvice()}>Submit Request</button>
                  )}

                  {submitting && (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
          </>
      )}

{showAdviceSuccess && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide'>
            <div className='modal__close' onClick={() => setShowAdviceSuccess(false)}>
              <FaTimes />
            </div>
            
            <div className='leadform__modal-header'>
              <div className='leadform__csrep-image'>
                <img src={csrep} alt='' />
                <div className='leadform__csrep-name'>Chris</div>
              </div>

              <div className='leadform__modal-body'>
                <h3>Your request has been sent!</h3>

                <div className='leadform__refid'>
                  Reference #: {referenceId}
                </div>

                <p className='mt-10'>
                  Our software experts will now get to work finding you the best solutions matching your specific needs.
                </p>

                <p className='mt-10'>
                  Allow us 12-24 hours in order to process your request.
                </p>

                <p className='mt-10'>
                  If you have any further questions, please <a href='/contact' className='link'>contact our support team</a> with your reference #.
                </p>
              </div>

              <div>

              </div>
            </div>
          </div>
        </>
      )}
        </>
  );
}

export default LeadForm;
