import './ForgotPassword.css';
import { forgotPassword } from '../../utils/api';
import {useState} from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import FadeIn from '../../utils/fadein';

const ForgotPassword = () => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');

    async function handleSubmit(e){
        e.preventDefault();

        let email = e.target.elements.email.value;
        let res = await forgotPassword(email);

        if (res.success){
            setHasError(false);
            setError('');
            setShowConfirm(true);
        }

        else{
            setHasError(true);
            setError(res.message);
        }
    };

    return (
        <FadeIn delay={300} duration={300}>
        <div className="center card__wrapper">
        <Helmet>
            <title>Forgot Your Password</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://www.leadrpro.com/forgot-password" />
        </Helmet>

        <div className="card forgotpassword">
            {showConfirm === false && (
                <h1 className="card__header">Forgot Your Password?</h1>
            )}

            {showConfirm && (
                <h1 className="card__header">Email Sent!</h1>
            )}
                {!showConfirm && (
                <div>
                    <form onSubmit={handleSubmit}>
                        <p className="">
                            Enter your email address in the form below and we'll send you further instructions for resetting your password.
                        </p>

                        <div className='form__group' style={{marginTop:"23px"}}>
                            <label className='label input__label' for='txtEmail'>Email</label>

                        <input className='input input_box' type='email' required placeholder='Enter your email' name="email" />
                        </div>

                        {hasError && (
                            <div className="label--error">
                                {error}
                            </div>
                        )}

                        <div className="form__group center">
                            <button className='btn btn-primary forgotpassword__btn'>
                                Send reset email
                            </button>
                            
                        </div>

                        <div className="form__group center">
                            <Link to="/login" className="cancel link--cancel">Back to sign in</Link>
                        </div>
                    </form>
                </div>
                )}

                {showConfirm && (
                    <>
                    <div className="card__text">
                        We've sent an email to your email address. Follow the steps provided in the email to update your password or select Sign In if you don't want to change your password at this time.
                    </div>

                    <div className="form__group" style={{marginTop: "70px"}}>
                        <a href="/login" className="btn btn-primary">Sign in</a>
                    </div>
                    </>
                )}
        </div>
        </div>
        </FadeIn>
    );
}

export default ForgotPassword;
