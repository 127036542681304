import './Rewards.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import BuyerFeedback from '../../components/feedback/BuyerFeedback';
import SellerFeedback from '../../components/feedback/SellerFeedback';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, refreshToken, hRefreshToken, addUserEventTracking, verifyStl, addBuyerRole } from '../../utils/api';
import RescheduleTimes from "../../components/qualifier/RescheduleTimes";
import { FaAward, FaChartLine, FaHorseHead } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import reward_nike from '../../assets/rewards/rewards_nike.png';
import reward_uber from '../../assets/rewards/rewards_uber.png';
import reward_airbnb from '../../assets/rewards/rewards_airbnb.png';
import reward_doordash from '../../assets/rewards/rewards_doordash.png';
import reward_starbucks from '../../assets/rewards/rewards_starbucks.png';
import reward_apple from '../../assets/rewards/rewards_apple.png';
import reward_cashlist from '../../assets/rewards/rewards_cashlist.png';
import reward_bestbuy from '../../assets/rewards/rewards_bestbuy.png';
import reward_cash from '../../assets/rewards/rewards_cash.png';
import reward_venmo from '../../assets/rewards/rewards_venmo.png';
import reward_paypal from '../../assets/rewards/rewards_paypal.png';
import reward_zelle from '../../assets/rewards/rewards_zelle.png';
import bookdemos from '../../assets/rewards/bookdemos.png';
import { Spinner } from '@chakra-ui/react';
import booking from '../../assets/design/homepagehub/software/booking.png';
import herocards from '../../assets/design/homepagehub/software/herocards.png';
import herobg from '../../assets/design/homepagehub/software/herobg.png';
import heroicon from '../../assets/design/homepagehub/software/heroicon.png';
import logobg from '../../assets/design/homepagehub/software/logo_bg.png';

function Rewards() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [isBuyer, setIsBuyer] = useState(false);
    const [demo, setDemo] = useState({});
    const [valid, setValid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
      async function loadStl(){
        const tok = new URLSearchParams(search).get('stl');

        if (tok && tok.length > 0){
          let res = await verifyStl(tok);

          if (res.success){
            if (res.valid){
              localStorage.setItem('access_token', res.data.accessToken);
              localStorage.setItem('refresh_token', res.data.refreshToken);

              window.location = window.location;
            }
          }

          else{
            setLoaded(true);
          }
        }

        else{
          setLoaded(true);
        }
      }
      
          if (isAuthed() == false)
            loadStl();
          else
            setLoaded(true);
    }, []);

    async function handleEarn(){
      setSubmitting(true);

      if (isAuthed()){
        let res = await addBuyerRole();

        if (res.success){
          await hRefreshToken();

          window.location = '/dashboard/buyer';
        }
      }

      else{
        window.location = '/signup';
      }
    }

    return (
        <div className="rewards">
          <ScrollToTop />
          <Helmet>
            <meta name="description" content="" />
            <title>Book Demos & Earn Rewards</title>
          </Helmet>

          {/* <div className="rewards__top-section">
            <div className='rewards__top-section-image'>
              <img src={bookdemos} alt='' />
            </div>

            <div className='rewards__top-section-list'>
              <h1>Book demos. Earn Rewards!</h1>

              <div className='rewards__top-section-items'>
                <div className='rewards__top-section-item'>
                  <span><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.8781 12.8607L0 7.81976L2.16924 5.57809L4.8781 8.38532L12.4651 0.537109L14.6343 2.77878L4.8781 12.8607Z" fill="#5A44FE"/>
</svg>
</span> <span className='ml-10'>Discover the latest software</span>
                </div>

                <div className='rewards__top-section-item'>
                  <span><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.8781 12.8607L0 7.81976L2.16924 5.57809L4.8781 8.38532L12.4651 0.537109L14.6343 2.77878L4.8781 12.8607Z" fill="#5A44FE"/>
</svg>
</span> <span className='ml-10'>Apply for a 30 min demo</span>
                </div>

                <div className='rewards__top-section-item'>
                  <span><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.8781 12.8607L0 7.81976L2.16924 5.57809L4.8781 8.38532L12.4651 0.537109L14.6343 2.77878L4.8781 12.8607Z" fill="#5A44FE"/>
</svg>
</span> <span className='ml-10'>Provide feedback and earn rewards!</span>
                </div>
              </div>

              <div className='mt-10'>
                {submitting == false && (
                  <button className='btn btn--primary' onClick={() => handleEarn()}>Start Earning Rewards</button>
                )}

                {submitting && (
                  
                  <Spinner />
                )}
              </div>
            </div>
          </div> */}

          <div className='homepagehubsoftware__hero' style={{paddingTop: '10px'}}>
        <div className='homepagehubsoftware__hero-left'>
          <div className='homepagehubsoftware__hero-icon'>
            <img src={heroicon} alt='' /> <span className='homepagehubsoftware__hero-icon-text'>Software Hub</span>
          </div>

          <div className='homepagehub__hero-h1' style={{lineHeight: '1'}}>
            Meet with top companies<br/>
            &amp; earn rewards
          </div>

          <div className='homepagehub__hero-subheader'>
            Research hundreds of reviews and discussions and find what your business truly needs. Book a demo and earn rewards for your time.
          </div>

          <div className='homepagehub__hero-btns'>
            <button onClick={() => handleEarn()} className='homepagehub__hero-btn homepagehub__hero-btn--primary'>
              Start earning rewards
            </button>
          </div>
        </div>

        <div className='homepagehubsoftware__hero-right'>
          <img src={herocards} alt='' />
        </div>

        <div className='homepagehubsoftware__hero-bg'>
          <img src={herobg} alt='' />
        </div>
      </div>

          <div className='homepagehub__hub-description-item' style={{display: 'none'}}>
          <div className='homepagehub__hub-description-info'>
            <div className='homepagehub__hub-description-info-header'>
              Earn rewards while you search for ways to upgrade your business
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              We pay out $75 for every demo completed on LeadrPro!
              Once your demo is completed, you can cash out using PayPal, Venmo, CashApp, Zelle, Amazon Gift Card or Visa.
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn' onClick={() => navigate('/software')}>Start earning rewards</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div className='homepagehub__hub-description-vector-image'>
              <img src={booking} alt='' />
            </div>
          </div>
        </div>

          <div className="rewards__list-wrapper">
            <div className='rewards__description'>
              Earn rewards for meeting with top tier software companies.
            </div>

            <div className="rewards__list-items">
              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_nike} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_uber} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_airbnb} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_doordash} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_starbucks} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_apple} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_cashlist} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_bestbuy} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_cash} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_venmo} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_paypal} alt='' />
              </div>

              <div className='rewards__list-item' onClick={() => handleEarn()}>
                <img src={reward_zelle} alt='' />
              </div>
            </div>
          </div>

          <div className='homepagehub__hub-description-item' style={{padding: '6% 10%'}}>
          <div className='homepagehub__hub-description-info'>
            <div className='homepagehub__hub-description-info-header'>
              Earn rewards while you search for ways to upgrade your business
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              Earn rewards for every demo completed on LeadrPro!
              Match with software solutions tailored to you and start earning today.
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn' onClick={() => handleEarn()}>Start earning rewards</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div className='homepagehub__hub-description-vector-image'>
              <img src={booking} alt='' />
            </div>
          </div>
        </div>

        <div className='homepagehubsoftware__vendor-hub'>
        <div className='homepagehubsoftware__vendor-hub-bg'>
          <img src={logobg} alt='' />
        </div>
        <div className='homepagehubsoftware__vendor-hub-copy'>
          <div className='homepagehubsoftware__vendor-hub-h2'>Interested in becoming a vendor?</div>

          <div className='homepagehubsoftware__vendor-hub-text'>
            Do you have an innovative software solution you would like to share on LeadrPro? Sign up as a vendor and demo your software to hundreds of high profile buyers.
          </div>

          <div className='homepagehubsoftware__vendor-hub-text2'>
            Boost your sales with LeadrPro
          </div>
        </div>

        <div className='homepagehubsoftware__vendor-hub-cta'>
          <button onClick={() =>navigate('/sell-software')}>Visit our vendor hub</button>

          <div className='mt-30 center pointer' onClick={() => navigate('/signup/seller')}>
            Sign up
          </div>
        </div>
      </div>
        </div>
    );
}

export default Rewards;