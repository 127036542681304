import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import './Homepage.css';
import medialogos from '../../assets/design/logos/medialogos.png';
import bg from '../../assets/design/homepage/background1.png';
import HomepageBuyer from './HomepageBuyer';
import HomepageSeller from './HomepageSeller';

function Homepage() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <div className='homepage'>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='homepage__hero' style={{backgroundImage: 'url(https://ik.imagekit.io/ivgrhmd4k/homepage/background1.png?updatedAt=1716232259085)', backgroundSize: 'contain'}}>
        <div className='homepage__hero-h1'>
          Discover the best <span style={{color: '#00bf63'}}>software</span>
          <br />
          through community
        </div>

        <div className='homepage__hero-interested-in'>
          I am interested in
        </div>

        <div className='center homepage__hero-btns'>
          <a href='#buyer' className='homepage__hero-btn homepage__hero-btn--primary'>Becoming a member</a>

          <a href='#seller' className='homepage__hero-btn'>Listing as a vendor</a>
        </div>

        <div className='homepage__hero-ph'>
        <div>
            <a href="https://www.producthunt.com/posts/leadrpro-for-events?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-leadrpro&#0045;for&#0045;events" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=431555&theme=light&period=daily" alt="LeadrPro&#0032;for&#0032;Events - The&#0032;networking&#0032;app&#0032;for&#0032;events | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            </div>
        </div>
      </div>

      <div className='homepage__media-logos'>
        <img src={medialogos} />
      </div>

      <div id='buyer'>
        <HomepageBuyer />
      </div>

      <div id='seller'>
        <HomepageSeller />
      </div>
    </div>
  );
}

export default Homepage;