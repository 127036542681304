import './PriceLeadsPurchased.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getLeadCardsApproved, hasPendingLeads } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaTimes, FaRegCheckCircle, FaRegStickyNote } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';
 import * as FaIcons from 'react-icons/fa';

 const IconDisplay = ({ iconName }) => {
   // Dynamically get the icon component from the FaIcons object
   const IconComponent = FaIcons[iconName];
 
   // If the icon is found, render it
   if (IconComponent) {
     return <IconComponent />;
   }
 
   // Fallback if icon is not found
   return <p>Icon not found</p>;
 };

const PriceLeadsPurchased = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [currentTab, setCurrentTab] = useState('Pending');
  const [showPurchase, setShowPurchase] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [hasPending, setHasPending] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getLeadCardsApproved();

      if (res.success){
        setData(res.data);
        setLoading(false);
      }
    }

    async function checkPending(){
      let res = await hasPendingLeads();

      if (res.success){
        setHasPending(res.data);
      }
    }
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
        setSuccess(true);
      }

      else if (response.status === 403){
        await refresh();
        let response = await getUserProfile();

        if (response.success){
          setUser(response.data);
          setLoading(false);
          setSuccess(true);
        }
      }

      else{
        setLoading(false);
      }
    }

    loadUserProfile();
    loadData();
    checkPending();
  }, []);

  return (
    <div className="priceleads">

      {loading && (
        <Spinner />
      )}

      {loading === false && success == true && (
        <>
          {currentTab == 'Pending' && (
            <div>
              {hasPending&& (
              <div className='priceleads__header'>
                <Link to='/dashboard/price-leads'>
                <div><svg style={{height: '40px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.4 96L144 96l-36.2 0L96 96l0 8.8L96 144l0 40.4 0 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3 48 96c0-26.5 21.5-48 48-48l76.6 0 49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48 416 48c26.5 0 48 21.5 48 48l0 44.3 22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4l0-89 0-40.4 0-39.2 0-8.8-11.8 0L368 96l-71.4 0-81.3 0zM0 448L0 242.1 217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1 512 448s0 0 0 0c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64c0 0 0 0 0 0zM176 160l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg></div>
                <strong>You have NEW leads to review</strong>
                </Link>
              </div>
              )}

              <div className='mt-10'>
                <h1>Your purchased leads</h1>
              </div>

              <p>
                Manage and track your active leads here.
              </p>

              {loading == false && data.length == 0 && (
                <div>
                  <i>No leads currently available.</i>
                </div>
              )}

              <div className='priceleads__cards'>
                {data.map(item => (
                  <div className='priceleads__card'>
                  <div>
                    <i>Reference #: {item.reference_id}</i>
                  </div>

                  <div className='priceleads__card-action'>
                    <span><IconDisplay iconName={item.type_icon_name} /></span>
                    <span className='ml-10'>{item.type_description}</span>
                  </div>

                  <div className='form-group'>
                    <strong>Date Created</strong>
                    <div>
                      {new Date(item.date_created).toLocaleDateString()}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Name</strong>
                    
                    <div>
                    {item.first_name} {item.last_name}
                    </div>
                  </div>

                  <div class='form-group'>
                    <strong>Company name</strong>

                    <div>
                      {item.company_name}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Job title</strong>
                    
                    <div>
                      {item.job_title}
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for</strong>

                    <div>
                      {item.request_body}
                    </div>
                  </div>

                  {item.note_count != '0' && (
                    <div className='form-group priceleadspurchased__note-count'>
                      <FaRegStickyNote /> {item.note_count} admin notes
                    </div>
                  )}

                {showDetails == false && (
                  <div className='center'>
                    <button className='btn btn--confirm' onClick={() => navigate('/dashboard/price-lead/' + item.reference_id)}>Open lead details</button>
                  </div>
                )}

                {showDetails && (
                <div className='priceleads__hidden-section'>
                  <div className='form-group'>
                    <strong>Company size</strong>
                    <div></div>
                  </div>

                  <div className='form-group'>
                    <strong>Number of users</strong>
                    <div></div>
                  </div>

                  <div className='form-group'>
                    <strong>Annual revenue</strong>
                    <div></div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for key features</strong>
                    <div>
                    
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Price expectations</strong>
                    <div>
                    
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Timeframe</strong>

                    <div>
                    
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Contact details</strong>

                    <p>
                      Email and phone number available.
                    </p>
                  </div>
                  </div>
                )}
                </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

  export default PriceLeadsPurchased;