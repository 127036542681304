import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes, FaStar, FaRegStar } from 'react-icons/fa';
import './HomepageBuyer.css';
import medialogos from '../../assets/design/logos/medialogos.png';
import memberlogos from '../../assets/design/homepage/memberlogos.png';
import jpm from '../../assets/design/logos/jpm.png';
import mucker from '../../assets/design/logos/mucker.png';
import kl from '../../assets/design/logos/kl.png';
import executive_events from '../../assets/design/homepage/executive_events.png';
import sponsors from '../../assets/design/logos/sponsors.png';
import badge1 from '../../assets/design/homepage/badge1.png';
import slack from '../../assets/design/homepage/slack.png';
import virtual1 from '../../assets/events/virtualevent1.png';
import virtual2 from '../../assets/events/virtualevent2.png';
import virtual3 from '../../assets/events/virtualevent3.png';
import virtual4 from '../../assets/events/virtualevent4.png';
import virtual5 from '../../assets/events/virtualevent5.png';
import virtual6 from '../../assets/events/virtualevent6.png';
import virtual7 from '../../assets/events/virtualevent7.png';
import virtual8 from '../../assets/events/virtualevent8.png';
import virtual9 from '../../assets/events/virtualevent9.png';

import howtostart from '../../assets/design/homepage/howtostart.png';
import podsaas from '../../assets/design/homepage/podsaas.png';
import roundtable from '../../assets/design/homepage/roundtable.png';
import thoughtleaders from '../../assets/design/homepage/thoughtleaders.png';
import { getAvailableSellersAll } from '../../utils/api';

function HomepageBuyer() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getAvailableSellersAll();

      if (res.success){
        setSellers(res.data);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  return (
    <div className='homepagebuyer'>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='homepagebuyer__hero'>
        <div className='homepagebuyer__hero-left'>
          <div className='homepagebuyer__hero-h1'>
            Our members work at the top organizations in the world.
          </div>

          <div className='center homepagebuyer__hero-btns'>
            <button className='homepage__hero-btn homepage__hero-btn--primary' onClick={() => navigate('/signup')}>Become a member</button>
            <button className='homepage__hero-btn' onClick={() => navigate('/signup/seller')}>View benefits</button>
          </div>
        </div>

        <div className='homepagebuyer__hero-right'>
          <img src={memberlogos} />
        </div>
      </div>

      {/* <div className='homepagebuyer__member-benefits'>
        <button className='btn'>Member benefits</button>
      </div> */}

<div className='homepagebuyer__unlimited homepagebuyer__section'>
        <div className='homepagebuyer__unlimited-thumbnails'>
          <div><img src={roundtable} alt='' /></div>
          <div><img src={thoughtleaders} alt='' /></div>
          <div><img src={howtostart} alt='' /></div>
          <div><img src={podsaas} alt='' /></div>
        </div>

        <div className='homepagebuyer__unlimited-overlay'>
        </div>

        <div className='homepagebuyer__unlimited-content'>
          <h2 className='homepagebuyer__unlimited-content-header'>Unlimited access to exclusive content</h2>

          <div className='mt-20 homepagebuyer__unlimited-content-subheader'>
            Learn from top industry leaders and experts. Ready to watch? Join now.
          </div>

          <div className='mt-20 homepagebuyer__unlimited-content-form'>
            <div>
              <input className='homepagebuyer__unlimited-content-input' type='text' placeholder='Email address' />
            </div>

            <div>
              <button className='homepagebuyer__unlimited-content-btn'>Get Started</button>
            </div>
          </div>
        </div>
      </div>

      <div className='homepagebuyer__discover-software homepagebuyer__section'>
        <div className='homepagebuyer__discover-software-companies'>
          <div className='ml-20'>
            <Link className='link' to='/software'>Explore All Software</Link>
          </div>

          <div className='homepagebuyer__discover-software-list'>
            {sellers.slice(0, 9).map(item => (
              <div className='homepagebuyer__discover-software-list-item'>
                <div className='mb-10 bold'>{item.name}</div>

                {item.average_rating && (
                <div className='sellersearch__ratings' style={{marginTop: '0px'}}>
                    <span>{getStars(Math.ceil(item.average_rating))}</span>
                </div>
                )}

                <div className='center mt-10'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='homepagebuyer__discover-software-info'>
          <h2 className='homepagebuyer__h2'>Discover Software</h2>

          <p className='mt-30 homepagebuyer__copy ta-r'>
            Stay updated with the latest solutions from a community of your peers and <span style={{color: 'rgb(72, 13, 190)'}}>unlock special perks</span>.
          </p>

          <p className='mt-30 ta-r'>
            <button className='homepagebuyer__btn'>Become a member</button>
          </p>
        </div>
      </div>

      <div className='homepagebuyer__virtual-events homepagebuyer__section'>
        <div className='homepagebuyer__virtual-events-info'>
          <div>
            <h2 className='homepagebuyer__h2 flex'><img src={badge1} alt='' style={{height: '80px'}} /><span>Virtual Events</span></h2>
          </div>

          <p className='mt-30 homepagebuyer__copy homepagebuyer__virtual-events-info-copy'>
            These educational segments focus on addressing hot button issues by bringing you an all-star panel of curated experts and <span style={{color: '#480dbe'}}>Thought Leadrs</span>.
          </p>

          <p className='mt-30'>
            <button className='homepagebuyer__btn' onClick={() => navigate('/signup')}>Join now</button>
          </p>
        </div>

        <div className='homepagebuyer__virtual-events-items'>
          <div className='homepagebuyer__virtual-events-list'>
            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual1} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Andy Paul
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual2} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Jared Robin
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual3} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Todd Dewett, PhD
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual4} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Christopher Foulon
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual5} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Anthony Iannarino
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual6} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Chris Dyer
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual7} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Chris Orlob
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual8} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                John Barrows
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual9} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                David Edelman
              </div>
            </div>
          </div>

          <div>
            <span className='link' onClick={() => navigate('/webinars')}>View All</span>
          </div>
        </div>
      </div>

      <div className='homepagebuyer__executive-events'>
        <div className='flex' style={{alignItems: 'center'}}>
          <div className='homepagebuyer__executive-events-photos'>
            <img src={executive_events} alt='' />
          </div>

          <div className='homepagebuyer__executive-events-info'>
            <h2 className='homepagebuyer__h2 ta-r'>Executive Events</h2>

            <p className='mt-30 homepagebuyer__copy ta-r'>
              Gain access to in person experiences and build personal connections with <span style={{color: '#480dbe'}}>Industry Leaders and Executives</span>.
            </p>

            <p className='mt-30 ta-r'>
              <button className='homepagebuyer__btn'>Get access</button>
            </p>
          </div>
        </div>

        <div className='mt-30'>
          <div className="center eventhome__sponsors-label">Sponsored by</div>

          <div className='homepagebuyer__sponsors-list'>
            <div><img src={sponsors} /></div>
          </div>
        </div>
      </div>

      <div className='homepagebuyer__executive-members'>
        <div className='homepagebuyer__executive-members-info'>
            <h2 className='homepagebuyer__h2'>Executive Members</h2>

            <p className='homepagebuyer__copy mt-30'>
              Requirements: VP level or above, C-Suite, Founders
            </p>

            <div className='mt-30'>
              <button className='homepagebuyer__executive-members-btn'>Apply Now</button>
            </div>
        </div>

        <div className='homepagebuyer__executive-members-image'>
          <img src={slack} alt='' />
        </div>
      </div>

      <div className="eventhome__testimonial">
          <div className="eventhome__testimonial-left">
            <div className="eventhome__testimonial-left-quote">
              “Such an awesome platform to help stay on top of the latest and greatest. I've been a member for over 2 years.”
            </div>

            <div className="eventhome__testimonial-left-quote-name">
              Bradley Schaufenbuel, VP and Chief Information Security Officer at Paychex
            </div>
          </div>

          <div className="eventhome__testimonial-right">
            <div className="eventhome__testimonial-image">
              <img src='https://ik.imagekit.io/ivgrhmd4k/bradley.jpg?updatedAt=1715805813403' />
            </div>
          </div>
        </div>

        <div className='homepage__media-logos'>
          <img src={medialogos} />
        </div>
    </div>
  );
}

export default HomepageBuyer;