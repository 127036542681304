import "./NewUser.css"
import Login from "../../signup/Login"
import { useState, useEffect } from "react";

function NewUser ({ loggedIn, setStage, setUserInfo, userInfo, companySelected }) {
  const initialEmail = {
    email: ""
  }
  const [email, setEmail] = useState({ ...initialEmail });

  const handleChange = ({target}) => {
    setEmail({
      ...email,
      [target.name]: target.value,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setUserInfo({
      ...userInfo,
      ["email"]: email.email
    })
    setStage((value) => value + 1)
  }

  const handleCancel = (event) => {
    setStage((value) => value - 1);
  }


  return (
    <div className="review__new-user-container">
      <h2 className="review__new-user-title">Help others by reviewing {companySelected.name}</h2>

      <div className="review__email-prompt">
        <h4 className="review__provide-email-prompt">Please provide your email before that</h4>
        <span className="review__newuser-login">
          Already have an account? Save time and <a href="/login" className="review__newuser-login-link">log in</a>
        </span>
      </div>

      <form onSubmit={handleSubmit}>
      <div className="review__input-group">
        <label className="review__input-label">Email</label>
        <input
          type="email"
          id="email"
          className="input review__input-prompt"
          placeholder="Enter here"
          name="email"
          value={email.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="review__btn-group">
        <button type="cancel" className="btn-secondary review__previous-btn" onClick={handleCancel}>Previous page</button>
        <button type="submit" className="btn-action review__continue-btn">Save and continue</button>
      </div>
      </form>
    </div>
  )
}

export default NewUser;