import './OrderHistory.css';
import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { getCustomerId, createSetupIntent, checkHasPaymentMethod, getPaymentMethods, removePaymentMethod, refreshToken, getCompanyPackagePurchases} from '../../utils/api';
import {Helmet} from "react-helmet";
import { refresh } from '../../services/auth.service';
import AddPaymentMethod from '../payment/AddPaymentMethod';
import Spinner from '../../components/layout/Spinner';
import mc from '../../assets/design/payments/mastercard.png';
import visa from '../../assets/design/payments/visa.png';
import { FaAward, FaChartLine, FaHorseHead, FaExclamation, FaExclamationCircle, FaUser } from 'react-icons/fa';

const OrderHistory = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [packagePurchases, setPackagePurchases] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [paymentLoaded, setPaymentLoaded] = useState(false);

  useEffect(() => {
    async function loadPackagePurchases(){
      let res = await getCompanyPackagePurchases();

      if (res.success){
        setPackagePurchases(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getCompanyPackagePurchases();

        if (res.success){
          setPackagePurchases(res.data);
          setLoaded(true);
        }
      }
    }

    async function loadPaymentMethod(){
      setPaymentLoaded(false);

      let res = await checkHasPaymentMethod();

      if (res.success){
        setHasPaymentMethod(res.data);
        setPaymentLoaded(true);
      }
    }

    async function loadPaymentMethods(){
      let res = await getPaymentMethods();
      
      if (res.success){
        setPaymentMethods(res.data);
        setPaymentLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getPaymentMethods();
    
        if (res.success){
          setPaymentMethods(res.data);
        }
      }
    }

    loadPaymentMethod();
    loadPaymentMethods();
    loadPackagePurchases();
      document.title = "LeadrPro - Manage Payment Methods";
  }, []);

  async function handleRemoveMethod(){
    let res = await removePaymentMethod(selectedPaymentMethodId);

    if (res.success){
      window.location = window.location;
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await removePaymentMethod(selectedPaymentMethodId);

        if (res.success){
          window.location = window.location;
        }
      }
    }

    setShowConfirm(false);
  }

  async function handleRemoveClick(payment_method_id){
    setShowConfirm(true);
    setSelectedPaymentMethodId(payment_method_id);
  }

  return (
    <div className="orderhistory">
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className=""><Link className='btn' to="/account/home">Back</Link></div>
      <div className="account__header">Order History</div>

      {loaded === false && (
        <div className='center'>
          <Spinner />
        </div>
      )}

        {paymentLoaded == true && paymentMethods.length > 0 && (
          <>
            <p style={{marginTop: "20px", marginBottom: '20px'}}>
              Note that you will only be charged once you have successfully completed a demo and have received final feedback. If you have any questions about your purchases, please reach out to our <Link className='link' to='/leave-feedback'>support team</Link>.
            </p>
          </>
        )}

{paymentLoaded == true && paymentMethods.length === 0 && (
          <>
            <p style={{marginTop: "30px"}}>
              You must have a valid payment method on file in order to continue to book demos. Note that you will only be charged once you have successfully completed a demo and have received final feedback.
            </p>

            <AddPaymentMethod />
          </>
        )}

      {loaded && (
        <>
        <table className='orderhistory__table table'>
          <tr>
            <th>Date</th>
            <th>Package</th>
            <th>Number of Demos</th>
            <th>Cost per Demo</th>
            <th>Total</th>
            <th>Status</th>
            <th><FaUser /> User</th>
          </tr>
          {packagePurchases.map(item => (
            <tr>
              <td>
                {new Date(item.date_paid).toLocaleString()}
              </td>

              <td>
                <div className='orderhistory__item-name'>
                  {item.name === 'Growth' && (
                    <span><FaChartLine /></span>
                  )}

                  {item.name === 'Premium' && (
                    <span><FaAward /></span>
                  )}
                  {' '}
                  {item.name}</div>
              </td>

              <td>
                +{item.number_of_demos + item.package_extra_demos}
              </td>

              <td>
                {item.modified_price && (
                  <>
                    <span style={{textDecoration: 'line-through', color: 'red'}}>${item.cost_per_demo}</span>
                    <br/>
                    <span>${item.modified_price}</span>
                  </>
                )}

                {!item.modified_price && (
                  <span>${item.cost_per_demo}</span>
                )}
              </td>

              <td>
                ${item.cost_per_demo * item.number_of_demos}
              </td>

              <td>
                <div className='orderhistory__status--paid'>{item.status}</div>
              </td>

              <td>
                <span>{item.first_name}</span>{' '}
                {item.last_name && item.last_name.length > 0 && (
                  <span>{item.last_name}</span>
                )}
              </td>
            </tr>
          ))}
        </table>
      </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2>Are you sure you want to remove this payment method?</h2>

            <p>Note that you will not be able to take on more demos until a valid payment method has been set.</p>

            <p style={{marginTop: "30px"}}>
              <button className='btn btn--primary btn--active' onClick={() => handleRemoveMethod()}>Confirm</button>
              <span className='link' style={{marginLeft: "20px"}} onClick={() => setShowConfirm(false)}>Cancel</span>
            </p>
          </div>
        </>
      )}
    </div>
  )
}

  export default OrderHistory;