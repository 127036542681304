import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getLeadFormSubmissions } from '../../utils/api/admin';

function LeadFormSubmission() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getLeadFormSubmissions();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Lead Form Submissions {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Contact</th>
              <th>Request</th>
              <th>Status</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.date_created}</td>
                <td>
                  <div>{item.name}</div>
                  <div>{item.job_title}</div>
                </td>

                <td>
                  <div>{item.contact_email}</div>
                  <div>{item.contact_phone}</div>
                </td>

                <td>
                  <div>{item.request_body}</div>
                </td>

                <td>
                  <div>
                    {item.status == 'Pending' && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}
                  </div>
                </td>

                <td>
                  <span className='link'>
                    Open details
                  </span>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default LeadFormSubmission;