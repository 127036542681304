import './SearchResults.css';
import './SellerResults.css';
import './SellerSearch.css';
import './BrowseSellers.css';
import './BrowseSellers2.css';
import {useState, useEffect} from 'react';
import { searchCompanies, getAvailableSellersAll, saveCompany, getSavedCompanies, createSellerDemo, refreshToken, buyerCreatesSellerDemo, getIndustriesWithSellers, addBuyerRequest, getUserProfile, addToWaitlist, searchAvailableSellers, addEmailCampaign, checkCaptcha, hasAvailableApplications } from '../../utils/api';
import { getSeoName } from '../../utils/utils';
import { isAuthed, refresh } from '../../services/auth.service';
import Share from './Share';
import Spinner from '../../components/layout/Spinner';
import JoinModal from './JoinModal';
import BuyerBookingSeller from '../../components/demos/BuyerBookingSeller';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import FadeIn from '../../utils/fadein';
import close from '../../assets/design/dashboard/close.png';
import {genTables} from '../../utils/pagination';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Recaptcha from "react-google-recaptcha";

const BrowseSellers2 = ({selectedFilters, selectedCategory}) => {
  const [results, setResults] = useState([]);
  const [resultsWaitlist, setResultsWaitlist] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirmation, setShowConfirmation]= useState(false);
  const [selectedSeller, setSelectedSeller] = useState({});
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [completed, setCompleted] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [showQualifier, setShowQualifier] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [qualifierCompleted, setQualifierCompleted] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [currentDemoId, setCurrentDemoId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [buyerRequest, setBuyerRequest] = useState('');
  const [showRequest, setShowRequest] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showUnverified, setShowUnverified] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [waitlist, setWaitlist] = useState([]);
  const [industries2, setIndustries2] = useState([]);
  const [query, setQuery] = useState('');
  const [showSearching, setShowSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [color, setColor] = useState('mediumpurple');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [isHuman, setIsHuman] = useState(false);
  const [hasAvailable, setHasAvailable] = useState(true);
  const [showMaxReached, setShowMaxReached] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const captchaRef = useRef(null);
  let refQuery = React.createRef();

  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  useEffect(() => {
    document.title = "LeadrPro - Discover the latest innovative software";

    if (isAuthed()){
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    async function loadCheckStatus(){
      let _ishuman = getCookie('is_human');
      if (_ishuman === 'true' || isAuthed()){
 
      }

      else{
        navigate('/security-check');
      }
    }

    loadCheckStatus();
  }, []);

  useEffect(() => {
    async function loadAvailableSellers(){
      let _res = await getAvailableSellersAll();

      if (_res.success){
        setResults(_res.data);
        setCompleted(true);
       // genTables();
        setLoaded(true);

        if (_res.enabled === false){
          setIsEnabled(false);
        }
        setWaitlist(_res.data.filter(x => x.on_waitlist === true));
      }

      else{
        if (_res.status === 403){
          let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let _res = await getAvailableSellersAll();

            if (_res.success){
              setResults(_res.data);
              setCompleted(true);
              //genTables();
              setLoaded(true);

              if (_res.enabled === false){
                setIsEnabled(false);
              }

              setWaitlist(_res.data.filter(x => x.on_waitlist === true));
            }
          }

          else{
            //window.location = '/login';
          }
        }

        else{
          let _res = await getAvailableSellersAll();

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            //genTables();
            setLoaded(true);
            setWaitlist(_res.data.filter(x => x.on_waitlist === true));
          }
        }
      }

      setIsSearching(false);
    }

    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        let _user = res.data;
        if (_user.work_email_verified){
          setIsVerified(true);
        }

        else{
          setIsVerified(false);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let res = await getUserProfile();

            if (res.success){
              let _user = res.data;
              if (_user.work_email_verified){
                setIsVerified(true);
              }
      
              else{
                setIsVerified(false);
              }
            }
          }
      }
    }

    async function loadIndustries(){
      let res = await getIndustriesWithSellers();

      if (res.success){
        setIndustries(res.data);
      }
    }

    loadAvailableSellers();
    loadIndustries();
    loadUserProfile();
  }, []);

  async function handleQualifierClick(demo_id, item){
    let _check = await hasAvailableApplications();

    if (_check.success){
      if (_check.data === true){
        setShowMaxReached(false);
        setHasAvailable(true);
      }

      else{
        setHasAvailable(false);
        setShowMaxReached(true);
        return;
      }
    }

    else if (_check.status === 403){
      await refresh();

      let _check = await hasAvailableApplications();

      if (_check.success){
        if (_check.data === true){
          setShowMaxReached(false);
          setHasAvailable(true);
        }
  
        else{
          setHasAvailable(false);
          setShowMaxReached(true);
          return;
        }
      }
    }

    setSelectedCompanyId(item.company_id);
    setBookingLoading(true);

    if (isLoggedIn===false){
      navigate('/join-now');
      setShowModal(true);
      setBookingLoading(false);
      return;
    }
    if (isVerified === false){
      setShowUnverified(true);
      setBookingLoading(false);
      return;
    }

    if (demo_id === null){
      // create a pending demo for application
      let data = {seller_id: item.seller_id};
      let _res = await createSellerDemo(data);

      if (_res.success){
        setCurrentDemo(item);
        setCurrentDemoId(_res.data);
        setShowQualifier(true);
      }

      else{
        setCurrentDemo(item);
        setCurrentDemoId(_res.demo_id);
        setShowQualifier(true);
      }

      setBookingLoading(false);
    }

    else{
      setCurrentDemo(item);
      setCurrentDemoId(demo_id);
      setShowQualifier(true);
      setBookingLoading(false);
    }
  }

  async function handleWaitlistClick(demo_id, item){
    setSelectedCompanyId(item.company_id);
    setCurrentDemo(item);
    setShowWaitlist(true);
  }

  async function handleWaitlistConfirmClick(){
    setSubmitting(true);
    let res = await addToWaitlist(selectedCompanyId);

    if (res.success){
      setShowWaitlistConfirm(true);
      setShowWaitlist(false);
      setSubmitting(false);

      const updatedItems = waitlist.map(item => {
        if (item.company_id === selectedCompanyId) {
          // Return a new object with the updated property
          return { ...item, user_on_waitlist: '1' };
        }
        return item; // Return the item as is
      });

      setWaitlist(updatedItems);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addToWaitlist(selectedCompanyId);

        if (res.success){
          setShowWaitlistConfirm(true);
          setShowWaitlist(false);
          setSubmitting(false);
          //setWaitlist([...waitlist, selectedCompanyId]);

          const updatedItems = waitlist.map(item => {
            if (item.company_id === selectedCompanyId) {
              // Return a new object with the updated property
              return { ...item, user_on_waitlist: '1' };
            }
            return item; // Return the item as is
          });

          setWaitlist(updatedItems);
        }
      }
    }

    else{
      alert("Something went wrong");
    }
  }

  function demoAccepted(demo_id){
    //let _filter = demos.filter(item => item.demo_id !== demo_id);
    //setDemos(_filter);
    let _filter = results.filter(item => item.company_id !== selectedCompanyId);
    setResults(_filter);
  }

  async function handleSubmitRequest(){
    if (buyerRequest && buyerRequest.length > 0){
      let res = await addBuyerRequest(buyerRequest);

      if (res.success){
        setRequestComplete(true);
      }

      else{
        setRequestComplete(true);
      }
    }
  }

  async function handleAvailableClick(){
    setShowComingSoon(false);
  }

  async function handleComingSoonClick(){
    setShowComingSoon(true);
  }

  async function loadAvailableSellers(){
    let _res = await searchAvailableSellers(query, selectedIndustry);

    if (_res.success){
      console.log('data', _res.data);
      setResults(_res.data);
      setCompleted(true);
      //genTables();
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let _res = await searchAvailableSellers(query, selectedIndustry);

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            genTables();
            setLoaded(true);
          }
        }

        else{
          //window.location = '/login';
        }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }
  }

  async function search(q){
    setIsSearching(true);
    let _res = await searchAvailableSellers(q, selectedIndustry);

    if (_res.success){
      setResults(_res.data);
      setCompleted(true);
      genTables();
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let _res = await searchAvailableSellers(q, selectedIndustry);

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            genTables();
            setLoaded(true);
          }
        }

        else{
         // window.location = '/login';
        }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }

    setIsSearching(false);
  }

  async function searchAll(q, industry_id){
    setIsSearching(true);
    let _res = await searchAvailableSellers(q, industry_id);

    if (_res.success){
      setResults(_res.data);
      setCompleted(true);
      genTables();
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let _res = await searchAvailableSellers(q, industry_id);

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            //genTables();
            setLoaded(true);
          }
        }

        else{
         // window.location = '/login';
        }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          //genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }

    setIsSearching(false);
  }

  async function handleSearch(){
    setIsSearching(true);
    setShowDropdown(false);
    loadAvailableSellers();
  }

  async function handleIndustryClick(id){
    setIsSearching(true);
    setSelectedIndustry(id);
    setShowDropdown(false);

    await searchAll('', id);
  }

  async function handleNavSearch(id){
    setQuery('');
    setSelectedIndustry(id);
    setShowSearching(true);
    searchAll('', id);
  }

  async function handleQueryChange(q){
    setQuery(q);
    setShowDropdown(false);

    if (q.length === 0){
      search('');
    }
  }

  async function handleClearClick(){
    setShowDropdown(false);
    setQuery('');
    setSelectedIndustry(0);
    searchAll('', 0);
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      setIsSearching(true);
      setShowDropdown(false);
      search(refQuery.current.value);
      //loadAvailableSellers();
    }
  }

  async function handleNewsletterSubmit(){
    setNewsletterLoading(true);

    if (newsletterEmail.length === 0){
      alert('Must enter a valid email');
      setNewsletterLoading(false);
      return;
    }

    let res = await addEmailCampaign(newsletterEmail, 'Discover');

    if (res.success){
      setNewsletterDone(true);
      setNewsletterLoading(false);
      setNewsletterEmail('');
    }
  }

  async function handleMoreInfo(company_id, name){
    navigate(`/company/${company_id}/${getSeoName(name)}`);
    window.scrollTo(0, 0);
  }

  async function handleRecaptcha(e){
    e.preventDefault();

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    let res = await checkCaptcha(token);

    if (res.success){
      alert('human');
    }

    else{
      alert('not human');
    }
    console.log(res);
  }

  return (
    <>
    <div className='sellersearch__wrapper'>
      <div className="sellersearch" style={{width: "90%", maxWidth: "100%"}}>
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className='browsesellers__nav-industries'>
        <div className='browsesellers__nav-industries-list'>
          <span onClick={() => handleClearClick()}>All</span>
          {industries.slice(0, 7).map(item => (
            <span onClick={() => handleNavSearch(item.industry_id)} className={selectedIndustry == item.industry_id ? 'selected': ''}>
              {item.name}
            </span>
          ))}
        </div>
      </div>
      
      {showSearching === false && (
        <FadeIn delay={200} duration={200}>
      <div style={{fontSize:"50px", fontWeight: "bold", padding: "4%", paddingBottom: "0px", paddingTop: "0px", textAlign: "center"}}>
        Discover the latest innovative software 2
      </div>
      </FadeIn>
      )}

      <div className='browsesellers__input-wrapper'>
        <input type="text" value={query} placeholder='Search for software' style={{padding: "15px", backgroundColor: "#fff", border:"solid 1px #ddd", border: "none", width: "80%", fontSize: "18px", outline: "none"}} onFocus={() => setShowDropdown(true)} onChange={(e) => handleQueryChange(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} ref={refQuery} />

        <button onClick={() => handleSearch()} style={{backgroundColor: "dodgerblue", color: "white", padding: "5px 10px", fontSize: "12px", borderRadius: "30px"}}>
          {isSearching && (
            <span>
              <Spinner />
            </span>
          )}

          {isSearching === false && (
            <span>Search</span>
          )}
        </button>
      </div>

        <FadeIn delay={200} duration={200}>
        <div className='browsesellers__dropdown' style={{display: showDropdown ? 'block': 'none'}}>
          <div style={{position: "absolute", top: "8px", right: "8px", fontWeight: "bold", fontSize: "12px", cursor: "pointer", color: "#ccc"}} onClick={() => handleClearClick()}>Clear</div>
          <strong>Suggested Industries:</strong>
          <div className='browsesellers__dropdown-links'>
            {industries.map(i => (
              <span className={`link ${selectedIndustry == i.industry_id ? ' selected': ''}`} onClick={() => handleIndustryClick(i.industry_id)}>
                &bull; {i.name}
              </span>
            ))}
          </div>
        </div>
        </FadeIn>

      {showBooking && (
        <>
        <div className="overlay"></div>
        <BuyerBookingSeller company={selectedSeller} selectedBuyer={selectedSeller} setSelectedSeller={setSelectedSeller} setShowBooking={setShowBooking} setShowConfirm={setShowConfirm} selectedSellers={selectedSellers} setSelectedSellers={setSelectedSellers} />
        </>
      )}

      {showModal && (
        <FadeIn delay={250} duration={250}>
          <JoinModal setShowModal={setShowModal} />
        </FadeIn>
      )}

      <div className='browsesellers__options'>
          <div className={`browsesellers__option ${showComingSoon ? '' : ' browsesellers__option--selected'}`} onClick={() => handleAvailableClick()}>Available</div>
          <div className={`browsesellers__option ${showComingSoon === false ? '' : ' browsesellers__option--selected'}`} onClick={() => handleComingSoonClick()}>Coming Soon</div>
        </div>

      {isSearching && (
        <Spinner />
      )}

      {results.length === 0 && isSearching === false && loaded && (
        <div className='sellersearch__no-results'>
          No results found.
        </div>
      )}

      {results.length > 0 && isSearching === false && (
      <div>
        {loaded && showComingSoon===false && (
            <FadeIn delay={400} duration={400}>
          <div style={{display: "flex", flexWrap: "wrap"}}>
        {results.filter(x => x.on_waitlist === false).map(item => (
          <div className="browsesellers__item browsesellers2__item">
            <div className="browsesellers__company-thumbnail browsesellers2__company-thumbnail">
              <Link to={'/company/' + item.company_id + '/' + getSeoName(item.name)}>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt="" />
                  )}
              </Link>
            </div>

            <div className='browsesellers2__company-info'>
              <div className='sellerresults__company-info'>
                <div className='browsesellers__item-industry'>{item.industry_name}</div>
                <div className="browsesellers2__company-name">
                  {item.name}
                </div>

                {item.average_rating && (
                <div className='sellersearch__ratings'>
                    ⭐{Math.round(item.average_rating * 10) / 10}
                </div>
                )}

              <div className="sellerresults__description">
                <div className='browsesellers2__company-description'>
                  {item.description ? (item.description.length > 180 ? item.description.substring(0, 180) + "..." : item.description) : 'No description found'}
                </div>
              </div>
              </div>
            </div>

            <div className='browsesellers2__btns'>
              {item.demos_completed === '0' && item.demos_pending === '0' && (
                <>
                  {selectedCompanyId === item.company_id && bookingLoading && (
                    <Spinner />
                  )}

                  {bookingLoading === false && isEnabled === true && (
                  <button className='btn browsesellers2__btn-apply' onClick={() => handleQualifierClick(item.demo_id, item)}>
                    <span>
                    Book Demo
                    </span>
                  </button>
                  )}
                </>
              )}
            </div>

        {/* <div>
          <div className="searchresults__buttons sellerresults__buttons">
            {item.demo_status !== 1 && item.demo_status !== 4 && selectedSellers.includes(item.seller_id) === false && (
              <button className="btn btn--primary" onClick={() => handleQualifierClick(item.demo_id, item)} >Apply</button>
            )}

            {(item.demo_status === 1 || item.demo_status === 4 || selectedSellers.includes(item.seller_id)) && (
              <span>Pending</span>
            )}
          </div>
        </div> */}
        </div>
        ))}
        </div>
        </FadeIn>
        )}

{loaded && showComingSoon===true && (
  <FadeIn delay={400} duration={400}>
          <div style={{display: "flex", flexWrap: "wrap"}}>
        {waitlist.map(item => (
      <div className="browsesellers__item">
            <div className="browsesellers__company-thumbnail">
            <a href={'/company/' + item.company_id + '/' + getSeoName(item.name)}>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt="" />
                  )}
              </a>
            </div>

        <div>
          <div>
            <div className='sellerresults__company-info'>
              <div className='browsesellers__item-industry'>{item.industry_name}</div>
              <div className="browsesellers__company-name">
                  {item.name}
                </div>

              <div className="sellerresults__description">
                <div className='dashboarddemo__company-description-text'>
                  {item.description ? (item.description.length > 180 ? item.description.substring(0, 180) + "..." : item.description) : 'No description found'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='browsesellers__btns'>
            {item.user_on_waitlist === "0" && waitlist.filter(x => x === item.company_id).length === 0 && (
              <button className='btn btn--primary browsesellers__btn-apply' onClick={() => handleWaitlistClick(item.demo_id, item)} >Join the waitlist</button>
            )}

            {
              (item.user_on_waitlist !== "0" || waitlist.filter(x => x === item.company_id).length > 0) && (
                <span>On the waitlist</span>
              )
            }
          </div>
        </div>
        </div>
        ))}
        </div>
        </FadeIn>
        )}
        </div>
        )}

        {showSelectedTime && (
        <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              <AvailableTimes demo={currentDemo} demo_id={currentDemoId} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
            </div>
        </div>
      )}

        {showShare && (
          <div className="overlay">
          <FadeIn duration={250} delay={250}>
            <Share setShow={setShowShare} />
          </FadeIn>
          </div>
        )}

    {showConfirmation && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <img src={close} className="modal__close" onClick={() => setShowConfirmation(false)} />
        
        <div className='center' style={{fontSize: "50px", marginBottom: "20px"}}>
          🎉
        </div>

        <div className=''>Your request is now being reviewed. You should hear back within 24 hours.</div>

        <div className=''>In the meantime, keep your profile up to date and check back daily for more demo opportunities!</div>

        <div className='center' style={{marginTop: "20px"}}>
          <button className='btn btn--primary btn--dark' onClick={() => setShowConfirmation(false)}>Close</button>
          
        </div>
      </div>
      </>
    )}

      {/* <div className="sellerresults">
      {results.map((item, index) => (
        <div key={index} className="seller-results-card sellerresults__card">
          <div className="results-body sellerresults__body">
            <div className="search-result-image sellerresults__image">
              {item.logo && (
              <img src={"/assets/companylogos/" + item.logo} alt="" />
              )}
              {!item.logo && (
                <span className="sellerresults__initial">{item.name[0]}</span>
              )}
            </div>

            <div className="search-result-info sellerresults__info">
              <div className="results-company-title sellerresults__info-title">
                {item.name}
              </div>

              <div className="results-company-rating sellerresults__rating">
                <div>
                  <div style={{display:"inline-block", paddingRight:"10px"}}>
                   {getStars(item.rating)}
                  </div>

                  {item.rating_count > 0 && (
                    <>
                  <span className="rating-text">{item.rating}</span>
                  <span className="score-text">({item.rating_count})</span>
                    </>
                  )}
                </div>

                <div className="review-link sellerresults__review-link">
                  <a href="#" className="result-links">{item.review_count} reviews</a>
                </div>
              </div>
            </div>
            
            <div className="sellerresults__summary">
            <p className="results-summary sellerresults__description">
              {item.description ? item.description: 'No description found'}<br/> <a href={"/company/" + item.company_id} className="result-links">Learn more about {item.name}</a>
            </p>
            </div>

            <div className="search-result-buttons sellerresults__buttons">
              <div>
                <button className={classNames({"btn--action": savedResults.filter((x => x === item.company_id)).length > 0}, "btn-secondary btn btn__book")} onClick={(e) => save(e, item.company_id)} >Save</button>
              </div>

              <div>
                <button className="btn-secondary btn btn__book" onClick={() => setShowModal(true)} >Book</button>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div> */}

      {showQualifier && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--pad0 modal__qq'>
            <QualifierQuestions demo_id={currentDemoId} setIsCompleted={setQualifierCompleted} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
          </div>
        </>
      )}

    </div>

    {showRequest && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowRequest(false)}>X</div>

          {requestComplete === false && (
            <>
                <h2>Submit a software request</h2>

          <p style={{marginTop: "20px"}}>
            Have a specific software need in mind? Let us know and we will comb through our extensive database and find you the best solutions.
          </p>

          <div style={{marginTop: "10px"}}>
            <textarea className='input textarea' placeholder='Ex: I am looking for content marketing software in the healthcare industry...' value={buyerRequest} onChange={(e) => setBuyerRequest(e.target.value)} style={{height: "100px"}}>

            </textarea>
          </div>

          <div style={{marginTop: "10px"}}>
            <button className='btn btn--primary' onClick={() => handleSubmitRequest()}>Submit request</button>
          </div>
          </>
          )}

          {requestComplete && (
            <>
              <h2>Request submitted!</h2>

            <p>
              Your request has been submitted! We normally take 24-48 hours to find you the best possible results. Hang tight and we will notify you when available!
            </p>
            </>
          )}
        </div>
      </>
    )}

    {showUnverified === true && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <h2 className='modal__h2'>
            ⚠️ Complete setting up your account!
          </h2>
          <p style={{marginTop:"20px"}}>
            You must verify your work email before you can apply for a demo.
          </p>

          <p>
            <a href='/account/work-email' className='link'>Verify work email</a>
          </p>
          <div className='center' style={{marginTop: "30px"}}>
            <button className='btn btn--secondary' onClick={() => setShowUnverified(false)}>Close</button>
          </div>
        </div>
      </>
    )}
    </div>

    {showWaitlist && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <img src={close} className="modal__close" onClick={() => setShowWaitlist(false)} />

          <div className='sellersearch__waitlist-company-info'>
            <div className="sellersearch__company-logo">
              {currentDemo.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
                
                {!currentDemo.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
            </div>

            <div className='sellersearch__waitlist-company-name'>
              {currentDemo.name}
            </div>
          </div>
          <div>You will be added to the waitlist and will be notified as soon as this company is ready to host demos.</div>

          <div className='center' style={{marginTop: "10px"}}>
            {submitting === false && (
            <button className='btn btn--primary' onClick={() => handleWaitlistConfirmClick()}>Sign me up</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {showWaitlistConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
        <img src={close} className="modal__close" onClick={() => setShowWaitlistConfirm(false)} />
          <div className='center'>🎉 Success!</div>

          <p>
            You have been added to the waitlist. You will be notified once this company is ready to host demos.
          </p>
        </div>
      </>
    )}

    {showMaxReached && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close'>
            <img src={close} onClick={() => setShowMaxReached(false)} />
          </div>

          <p>
            You have reached your maximum applications per day. Please come back tomorrow to apply for more.
          </p>
        </div>
      </>
    )}

      <div className='browsesellers__footer'>
        <div className='browsesellers__footer-left'>
          <div className='browsesellers__footer-left-h2'>
            Stay updated with the latest software solutions directly in your inbox ✉️
          </div>

          <div style={{marginTop: "30px"}}>
            <input type='email' className='input' value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)} placeholder='Email address' style={{color: "black"}} />
          </div>

          <div style={{marginTop: "10px"}}>
            {!newsletterLoading && !newsletterDone && (
              <button className='btn btn--primary' onClick={() => handleNewsletterSubmit()} style={{backgroundColor: "mediumaquamarine"}}>Sign up</button>
            )}

            {newsletterLoading && (
              <Spinner />
            )}

            {newsletterDone && !newsletterLoading && (
              <i>Success! You are now signed up</i>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BrowseSellers2;