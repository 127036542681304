import { useState, useEffect } from "react";
import './CreateEvent.css';
import './EditEvent.css';
import { getEvent, eventRegistration, getHostedEvent, deleteEvent, updateEvent, updateEventThumbnail, updateEventHost } from '../../utils/api';
import {
  getCurrentUser,
  isAuthed,
  logout,
  isSeller,
  isBuyer,
  refresh
} from '../../services/auth.service';
import classNames from "classnames";
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import Spinner from "../../components/layout/Spinner";
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FadeIn from "../../utils/fadein";
import { FaTimes } from "react-icons/fa";
import { Editor } from '@tinymce/tinymce-react';

function EditEvent() {
    const [event, setEvent] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timezone, setTimezone] = useState('');
    const [isFree, setIsFree] = useState(true);
    const [isPublished, setIsPublished] = useState(false);
    const [name, setName] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [highlights, setHighlights] = useState('');
    const [website, setWebsite] = useState('');
    const [fileName, setFileName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [urlSlug, setUrlSlug] = useState('');
    const [venueInstructions, setVenueInstructions] = useState('');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [requiresApproval, setRequiresApproval] = useState(false);
    const [enableQuestions, setEnableQuestions] = useState(false);
    const [showSaveBanner, setShowSaveBanner] = useState(false);
    const [showEditDescription, setShowEditDescription] = useState(false);
    const [showEditHighlights, setShowEditHighlights] = useState(false);
    const [capacity, setCapacity] = useState(null);
    const [isWebinar, setIsWebinar] = useState(false);
    const [webinarUrl, setWebinarUrl] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    let { id } = useParams();
    const navigate = useNavigate();

    async function loadEvent(){
      let res = await getHostedEvent(id);

      if (res.success){
        setEvent(res.data);
        setName(res.data.name);
        setDescription(res.data.description);
        setHighlights(res.data.highlights);
        setWebsite(res.data.website);
        setVenueInstructions(res.data.venue_instructions);
        setStartDate(new Date(res.data.start_date));
        setEndDate(new Date(res.data.end_date));
        setTimezone(res.data.timezone);
        setAddress1(res.data.address1);
        setAddress2(res.data.address2);
        setCity(res.data.city);
        setState(res.data.state);
        setZipcode(res.data.zipcode);
        setFileName(res.data.image_url);
        setIsFree(res.data.is_free);
        setRequiresApproval(res.data.requires_approval);
        setEnableQuestions(res.data.enable_questions);
        setIsPublished(res.data.status == 'Published' ? true: false);
        setUrlSlug(res.data.url_slug);
        setCapacity(res.data.capacity);
        setIsWebinar(res.data.is_webinar);
        setWebinarUrl(res.data.webinar_url);
        setMetaDescription(res.data.meta_description);
        setShortDescription(res.data.short_description);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getHostedEvent(id);

        if (res.success){
          setEvent(res.data);
          setName(res.data.name);
          setDescription(res.data.description);
          setHighlights(res.data.highlights);
          setWebsite(res.data.website);
          setVenueInstructions(res.data.venue_instructions);
          setStartDate(new Date(res.data.start_date));
          setEndDate(new Date(res.data.end_date));
          setTimezone(res.data.timezone);
          setAddress1(res.data.address1);
          setAddress2(res.data.address2);
          setCity(res.data.city);
          setState(res.data.state);
          setZipcode(res.data.zipcode);
          setFileName(res.data.image_url);
          setIsFree(res.data.is_free);
          setRequiresApproval(res.data.requires_approval);
          setEnableQuestions(res.data.enable_questions);
          setIsPublished(res.data.status == 'Published' ? true: false);
          setUrlSlug(res.data.url_slug);
          setCapacity(res.data.capacity);
          setIsWebinar(res.data.is_webinar);
          setWebinarUrl(res.data.webinar_url);
          setMetaDescription(res.data.meta_description);
          setShortDescription(res.data.short_description);
          setLoaded(true);
        }
      }
    }

    useEffect(() => {
      loadEvent();
      document.title = "LeadrPro - Edit Event";
    }, []);

    function onError(){
      console.log('error');
    }
  
    async function onSuccess(e){
      setFileName(`https://ik.imagekit.io/ivgrhmd4k/Events/${e.name}`);

      let res = await updateEventThumbnail(id, `https://ik.imagekit.io/ivgrhmd4k/Events/${e.name}`);

      if (res.success){
        alert('done');
      }
    }

    // useEffect(() => {
    //   if (fileName.length > 0){
    //     handleSaveChanges();
    //   }
    // }, [fileName]);

    async function handleDelete(){
      let res = await deleteEvent(id);

      if (res.success){
        navigate('/account/events')
      }

      else if (res.status === 403){
        await refresh();

        let res = await deleteEvent(id);

        if (res.success){
          navigate('/account/events')
        }
      }
    }

    async function handleSaveChanges(){
      setSubmitting(true);

      let status = isPublished ? 'Published' : 'Draft';

      let res = await updateEvent(id, name, description, startDate, endDate, website, venueInstructions, address1, address2, city, state, zipcode, fileName, isFree, requiresApproval, status, enableQuestions, capacity, timezone, isWebinar, webinarUrl, metaDescription, highlights, shortDescription);

      if (res.success){
        setSubmitting(false);
        loadSaveBanner();
        loadEvent();
      }

      else if (res.status === 403){
        await refresh();

        let res = await updateEvent(id, name, description, startDate, endDate, website, venueInstructions, address1, address2, city, state, zipcode, fileName, isFree, requiresApproval, status, enableQuestions, capacity, timezone, isWebinar,  webinarUrl, metaDescription, highlights, shortDescription);

        if (res.success){
          setSubmitting(false);
          loadSaveBanner();
          loadEvent();
        }
      }
    }

    function loadSaveBanner(){
      setShowSaveBanner(true);
      const timer = setTimeout(() => {
        setShowSaveBanner(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

    async function handleEditorSave(){
      handleSaveChanges();
      setShowEditDescription(false);
    }

    async function handleHighlightsEditorSave(){
      handleSaveChanges();
      setShowEditHighlights(false);
    }

    return (
        <div className="createevent">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />

          {event && (
            <title>{`Edit event`}</title>
          )}
        </Helmet>

        {loaded === false && (
          <Spinner />
        )}

        {loaded && (
          <FadeIn delay={300} duration={300}>
        <div className="createevent__form">
          <div className="createevent__back">
            <Link className="btn" to='/account/events'>Back</Link>
          </div>

          <h1>Edit Event</h1>

          <div>
          <span><Link className="btn" to={`/event/${id}/aaa`}>Public page</Link></span>
          <span><Link className="btn" to={`/manage-event/${id}`}>Manage event</Link></span>
          </div>

          <div style={{display: 'flex'}}>
          <div className="createevent__left">
            <div className="createevent__form-name createevent__form-group">
              <label>Event name</label>
              <input type="text" placeholder="Event name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="createevent__form-name createevent__form-group">
              <label>Short Description</label>
              <input type="text" placeholder="Event name" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
            </div>

            <div className="createevent__form-description createevent__form-group">
              <label>Description</label>
              <div>
                <button className="btn" onClick={() => setShowEditDescription(true)}>Launch editor</button>
              </div>
              
              {/* <textarea className="input textarea" placeholder="Describe your event" value={description} onChange={(e) => setDescription(e.target.value)}></textarea> */}
            </div>

            <div className="createevent__form-description createevent__form-group">
              <label>Highlights</label>
              <div>
                <button className="btn" onClick={() => setShowEditHighlights(true)}>Launch editor</button>
              </div>
              
              {/* <textarea className="input textarea" placeholder="Describe your event" value={description} onChange={(e) => setDescription(e.target.value)}></textarea> */}
            </div>

            <div className="createevent__form-dates">
              <div className="createevent__form-group">
                <label>Start Date</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect  dateFormat="Pp" />
              </div>

              <div className="createevent__form-group">
                <label>End Date</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showTimeSelect  dateFormat="Pp" />
              </div>
            </div>

            <div className="createevent__form-dates createevent__form-group">
              <div>
                <label>Timezone</label>

                <select name="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                  <option>Select timezone...</option>
                  <option value="America/New_York">America/New_York</option>
                  <option value="America/Detroit">America/Detroit</option>
                  <option value="America/Kentucky/Louisville">America/Kentucky/Louisville</option>
                  <option value="America/Kentucky/Monticello">America/Kentucky/Monticello</option>
                  <option value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</option>
                  <option value="America/Indiana/Vincennes">America/Indiana/Vincennes</option>
                  <option value="America/Indiana/Winamac">America/Indiana/Winamac</option>
                  <option value="America/Indiana/Marengo">America/Indiana/Marengo</option>
                  <option value="America/Indiana/Petersburg">America/Indiana/Petersburg</option>
                  <option value="America/Indiana/Vevay">America/Indiana/Vevay</option>
                  <option value="America/Chicago">America/Chicago</option>
                  <option value="America/Indiana/Tell_City">America/Indiana/Tell_City</option>
                  <option value="America/Indiana/Knox">America/Indiana/Knox</option>
                  <option value="America/Menominee">America/Menominee</option>
                  <option value="America/North_Dakota/Center">America/North_Dakota/Center</option>
                  <option value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</option>
                  <option value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</option>
                  <option value="America/Denver">America/Denver</option>
                  <option value="America/Boise">America/Boise</option>
                  <option value="America/Phoenix">America/Phoenix</option>
                  <option value="America/Los_Angeles">America/Los_Angeles</option>
                  <option value="America/Metlakatla">America/Metlakatla</option>
                  <option value="America/Anchorage">America/Anchorage</option>
                  <option value="America/Juneau">America/Juneau</option>
                  <option value="America/Sitka">America/Sitka</option>
                  <option value="America/Yakutat">America/Yakutat</option>
                  <option value="America/Nome">America/Nome</option>
                  <option value="America/Adak">America/Adak</option>
                  <option value="Pacific/Honolulu">Pacific/Honolulu</option>
              </select>
                {/* <input type="text" placeholder="Enter timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)} /> */}
              </div>
            </div>

            <div className="createevent__form-group">
              <label>Event website</label>
              <input type="url" placeholder="Event website" value={website} onChange={(e) => setWebsite(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>Venue instructions</label>
              <textarea type="text" placeholder="Venue instructions" value={venueInstructions} onChange={(e) => setVenueInstructions(e.target.value)}></textarea>
            </div>

            <div className="createevent__form-group">
              <label>Capacity</label>
              <input type="number" placeholder="50" value={capacity} onChange={(e) => setCapacity(e.target.value)} className="input"/>
            </div>

            <div className="createevent__options">
              <div className="createevent__option">
                <div>Is Free</div>

                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={isFree} onChange={(e) => setIsFree(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Requires Approval</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={requiresApproval} onChange={(e) => setRequiresApproval(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Published</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={isPublished} onChange={(e) => setIsPublished(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Enable Questions</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={enableQuestions} onChange={(e) => setEnableQuestions(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Is Webinar</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={isWebinar} onChange={(e) => setIsWebinar(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>
            </div>
          </div>

          <div className="createevent__right">
          <div className="createevent__form-group">
              <label>Thumbnail</label>

              <div className="createevent__thumbnail">
                {fileName && fileName.length > 0 && (
                  <img src={fileName} />
                )}

                {(event.image_url == null || event.image_url.length === 0) && (
                  <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                )}
              </div>
              
              <div className='companyedit__upload'>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/Events"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
            </div>

          <div className="createevent__form-location createevent__form-group">
              <div>
                <label>Address 1</label>
                <input type="text" placeholder="Address 1" value={address1} onChange={(e) => setAddress1(e.target.value)} />
              </div>
            </div>

            <div className="createevent__form-group">
              <label>Address 2</label>
              <input type="text" placeholder="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>City</label>
              <input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>State</label>
              <input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>Zipcode</label>
              <input type="text" placeholder="Enter location" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>Meta Description</label>
              <textarea placeholder="60 character max" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)}></textarea>
            </div>

            {isWebinar && (
              <FadeIn delay={100} duration={100}>
            <div className="createevent__form-group">
              <label>Webinar URL</label>
              <input type="text" placeholder="Enter location" value={webinarUrl} onChange={(e) => setWebinarUrl(e.target.value)} />
            </div>
            </FadeIn>
            )}
          </div>
        </div>

        <div className="center">
              {submitting === false && (
              <button className="btn btn--primary" style={{padding: '10px 20px'}} onClick={() => handleSaveChanges()}>Save Changes</button>
              )}

              {submitting && (
                <Spinner />
              )}

              {/* <div className="editevent__delete-event">
                <button className="link link--cancel" onClick={() => setShowDeleteConfirm(true)}>Delete Event</button>
              </div> */}

              <div style={{marginTop: '20px'}}>
                <span className="btn" onClick={() => navigate(-1)}>Back</span>
              </div>
            </div>
        </div>
        </FadeIn>
        )}

        {showDeleteConfirm && (
          <>
            <div className="overlay"></div>
            <div className="modal">
              <div className="modal__close" onClick={() => setShowDeleteConfirm(false)}>
                <FaTimes />
              </div>
              <h2 className="modal__h2">Confirm deletion</h2>

              <p style={{marginTop: '10px'}}>
                Are you sure you want to delete this event? All guests and hosts will be removed from this event as well.
              </p>

              <div style={{marginTop: '20px'}}>
                <button className="btn btn--delete" onClick={() => handleDelete()}>Confirm</button>
              </div>
            </div>
          </>
        )}

{showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Event updated successfully
          </div>
        </FadeIn>
      )}

{/* plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss', */}

      {showEditDescription && (
        <FadeIn delay={300} duration={300}>
          {/* <div className="overlay"></div> */}
          <div className="modal" style={{width: '90%', marginLeft: '-45%', zIndex: '1000'}}>
            <div className="modal__close" onClick={() => setShowEditDescription(false)}><FaTimes /></div>
          <Editor
      apiKey='cdi8hbnuihfmdcpjhe2c0jf8b7bov88gr0amec9khuxoykpg'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(newValue, editor) => setDescription(newValue)}
      initialValue={event.description}
    />

      <div className="center" style={{marginTop: '10px'}}>
        <button className="btn btn--primary btn--full" onClick={() => handleEditorSave()}>Save</button>
      </div>
          </div>
        </FadeIn>
      )}

{showEditHighlights && (
        <FadeIn delay={300} duration={300}>
          {/* <div className="overlay"></div> */}
          <div className="modal" style={{width: '90%', marginLeft: '-45%', zIndex: '1000'}}>
            <div className="modal__close" onClick={() => setShowEditHighlights(false)}><FaTimes /></div>
          <Editor
      apiKey='cdi8hbnuihfmdcpjhe2c0jf8b7bov88gr0amec9khuxoykpg'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(newValue, editor) => setHighlights(newValue)}
      initialValue={event.highlights}
    />

      <div className="center" style={{marginTop: '10px'}}>
        <button className="btn btn--primary btn--full" onClick={() => handleHighlightsEditorSave()}>Save</button>
      </div>
          </div>
        </FadeIn>
      )}
        </div>
    );
}

export default EditEvent;