import { useState, useEffect } from 'react';
import './DemoMessages.css';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { Link, useParams } from 'react-router-dom';
import { getInbox, refreshToken} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import {useNavigate} from 'react-router-dom';
import { FaInbox } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import AppLink from '../../components/layout/AppLink';
import HeaderApp from '../../assets/design/app/header.png';

const DemoMessages = () => {
  let user = getCurrentUser();
  const [inbox, setInbox] = useState([]);
  const [loaded ,setLoaded] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  useEffect(() => {
    async function loadInbox(){
      let _res = await getInbox();

      if (_res.success){
        setInbox(_res.data);
      }

      else if (_res.status === 403){
        await refresh();

        let _res = await getInbox();

        if (_res.success){
          setInbox(_res.data);
        }
      }

      setLoaded(true);
    }

    loadInbox();
  }, []);

  async function handleNavigate(id){
    navigate('/dashboard/message/' + id);
  }

  return (
    <>
    <ScrollToTop />
      <div className='demomessages__demos'>
        <h1 className='buyerdashboard__h1'>Inbox</h1>

        {/* <p className='demomessages__description'>
          <span>All your communications for approved demos will appear here.</span>
        </p> */}

        {inbox.length > 0 && (
        <div>
          <div>
            <strong>{inbox.length} Conversations</strong>
          </div>
          <table className='table'>
            <tr>
              <th>Last sent</th>
              <th>From</th>
              <th>Demo status</th>
              <th></th>
            </tr>
          {inbox.map((item, index) => (
            <tr className='demomessages__demo' onClick={() => handleNavigate(item.reference_id)}>
              {user.registered_user_id === item.buyer_id && (
                <>
                <td>
                  {item.last_sent && (
                  <div className="demomessages__last-sent">{new Date(item.last_sent).toLocaleString(undefined, options)}</div>
                  )}
                </td>

                  <td style={{width: "330px"}}>
                  <Link to={'/dashboard/message/' + item.reference_id} key={index}>
                    <span className='demomessages__company-logo'>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                    </span> <span style={{marginLeft: "10px"}}>Demo with <strong>{item.seller_first_name ? item.seller_first_name + ' from ' : ''} {item.seller_company_name}</strong></span>
                    </Link>
                  </td>

                  <td>
                    <span className={'demomessages__status ' + item.demo_status.toLowerCase()}>{item.demo_status}</span>
                  </td>

                  <td>{item.unread_count > 0 ? <span className='demomessages__unread'>New</span> : ''}</td>
                </>
              )}

              {user.registered_user_id === item.seller_id && item.seller_id != item.buyer_id && (
                <>
                  <td className='demomessages__last-sent'>{new Date(item.last_sent).toLocaleString(undefined, options)}</td>

                  <td>
                  <a href={'/dashboard/message/' + item.reference_id} key={index}>
                    <span className='demomessages__company-logo'>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                    </span> <span style={{marginLeft: "10px"}}>Demo with <strong>{item.buyer_first_name ? item.buyer_first_name + ' from ' : ''} {item.buyer_company_name}</strong>
                    </span>
                    </a>
                    </td>

                    <td>
                    <span className={'demomessages__status ' + item.demo_status.toLowerCase()}>{item.demo_status}</span>
                  </td>

                  <td>{item.unread_count > 0 ? <span className='demomessages__unread'>New</span> : ''}</td>
                </>
              )}
            </tr>
          ))}
          </table>
        </div>
        )}

        {/* <div style={{marginTop: '30px'}} className='center'>
          <Link to='/app'>
            <img src={HeaderApp} alt='' style={{borderRadius: '10px', width: '80%'}} />
          </Link>
        </div> */}

        {inbox.length === 0 && loaded === true && (
          <>
            <h2>You have no messages</h2>
          </>
        )}

        {loaded === false && (
          <Spinner />
        )}
      </div>
    </>
  )
}

export default DemoMessages;