import './QualifierQuestions.css';
import {useState,useEffect} from 'react';
import { getDemoQualifierQuestions, answerQualifierQuestion, addDemoInterest, refreshToken, GetCompanyQualifierQuestions } from '../../utils/api';
import { FaTimes, FaStar, FaRegStar } from 'react-icons/fa';
import { refresh } from '../../services/auth.service';
import { getTrimmedString, getBaseImageUrl } from '../../utils/utils';

function QualifierQuestions({demo_id, currentDemo, company_id, setShowQualifier, setShowSelectedTime}) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showInterest, setShowInterest] = useState(true);
  const [interestLevel, setInterestLevel] = useState('');
  const [answer, setAnswer] = useState('');
  const baseImageUrl = getBaseImageUrl();

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  useEffect(() => {
    async function loadQuestions(){
      let res = await getDemoQualifierQuestions(demo_id);

      if (res.success){
        setQuestions(res.data);

        if (res.data.length > 0){
          setCurrentQuestion(res.data[0]);
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await getDemoQualifierQuestions(demo_id);

        if (res.success){
          setQuestions(res.data);

          if (res.data.length > 0){
            setCurrentQuestion(res.data[0]);
          }
        }
      }
    }

    loadQuestions();
  }, []);

  async function nextQuestion(){
    if (answer.length === 0){
      alert("Must enter an answer");
      return;
    }

    let res = await answerQualifierQuestion(currentQuestion.qualifier_question_id, answer, demo_id);

    if (res.success){
      if (currentQuestionIndex === questions.length - 1){
        setShowQualifier(false);
        setShowSelectedTime(true);
      }

      else{
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setCurrentQuestion(questions[currentQuestionIndex + 1]);
        setAnswer('');
      }
    }

    else if (res.status === 403){
      await refresh();

      let res = await answerQualifierQuestion(currentQuestion.qualifier_question_id, answer, demo_id);

      if (res.success){
        if (currentQuestionIndex === questions.length - 1){
          setShowQualifier(false);
          setShowSelectedTime(true);
        }

        else{
          setCurrentQuestionIndex(currentQuestionIndex + 1);
          setCurrentQuestion(questions[currentQuestionIndex + 1]);
          setAnswer('');
        }
      }
    }
  }

  async function handleBackClick(){
    setShowQualifier(false);
  }

  async function saveInterest(){
    if (interestLevel === ''){
      alert("Please select a level of interest");
      return;
    }
    let res = await addDemoInterest(demo_id, interestLevel);

    if (res.success){
      setShowInterest(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await addDemoInterest(demo_id, interestLevel);

      if (res.success){
        setShowInterest(false);
      }
    }
  }

return (
  <div className='qualifierquestions'>
    {showInterest === false && (
      <>
      {/* {currentDemo != null && (
          <div className='qualifierquestions__company-info'>
            <div className='buyerdashboard__recommendation-thumbnail'>
                {currentDemo.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + currentDemo.thumbnail_image} alt={`${currentDemo.name} thumbnail`} />
                  )}
                </div>
                
                <div className='buyerdashboard__recommendation-name'>
                  <div>{currentDemo.name}</div>

                  <div className="company__review">
                    {currentDemo.average_rating && (
                      <div className="">
                          {getStars(Math.ceil(currentDemo.average_rating))}

                          <div className='mb-20'>
                            <span className="" style={{fontWeight: 'bold', fontSize: '12px'}}>{Math.round(currentDemo.average_rating * 10) / 10} out of 5</span>
                          </div>
                      </div>
                    )}
                  </div>

                  <div className='buyerdashboard__recommendation-description'>
                    {currentDemo.description && currentDemo.description.length > 0 && (
                      <>
                    {getTrimmedString(currentDemo.description, 160)}
                    </>
                    )}
                  </div>
                </div>
          </div>
        )} */}
        
      <div className='modal__body'>
        <h2>Step 1 of 2</h2>

        <div className='mar10--top qualifierquestion__label'>Please answer the following questions to apply. 
        </div>

        <div className='qualifierquestion__question'><span>Q:</span> {currentQuestion.question}</div>

        <div>
          <textarea className='input textarea' placeholder='Enter answer' style={{height: "100px"}} value={answer} onChange={(e) => setAnswer(e.target.value)}>
          </textarea>
        </div>
      </div>

      <div className='center' style={{marginTop: "20px"}}>
        <button className='btn btn--confirm' onClick={() => nextQuestion()}>Next</button>
      </div>
      </>
    )}

    {showInterest && (
      <>
        {/* {currentDemo != null && (
          <div className='qualifierquestions__company-info'>
            <div className='buyerdashboard__recommendation-thumbnail'>
                {currentDemo.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + currentDemo.thumbnail_image} alt={`${currentDemo.name} thumbnail`} />
                  )}
                </div>
                
                <div className='buyerdashboard__recommendation-name'>
                  <div>{currentDemo.name}</div>

                  <div className="company__review">
                    {currentDemo.average_rating && (
                      <div className="">
                          {getStars(Math.ceil(currentDemo.average_rating))}

                          <div className='mb-20'>
                            <span className="" style={{fontWeight: 'bold', fontSize: '12px'}}>{Math.round(currentDemo.average_rating * 10) / 10} out of 5</span>
                          </div>
                      </div>
                    )}
                  </div>

                  <div className='buyerdashboard__recommendation-description'>
                    {currentDemo.description && currentDemo.description.length > 0 && (
                      <>
                    {getTrimmedString(currentDemo.description, 160)}
                    </>
                    )}
                  </div>
                </div>
          </div>
        )} */}

       <div className='modal__body'>
        <h2>Step 1 of 2</h2>

        <div className='mar10--top qualifierquestion__label'>Please answer the following questions to apply.</div>

        <div className='qualifierquestion__question'><span>Q:</span> How interested are you in this specific software solution?</div>

        <div>
          <div>
            <input id='rbVery' type="radio" name="interest" onChange={() => setInterestLevel('Very interested')} /> <label htmlFor='rbVery'>Very interested</label>
          </div>

          <div>
            <input id='rbSome' type="radio" name="interest" onChange={() => setInterestLevel('Somewhat interested')} /> <label htmlFor='rbSome'>Somewhat interested</label>
          </div>

          <div>
            <input id='rbResearch' type="radio" name="interest" onChange={() => setInterestLevel('Just researching')} /> <label htmlFor='rbResearch'>Just researching</label>
          </div>
        </div>
      </div>

      <div className='center mt-20'>
        <button className='btn btn--confirm' onClick={() => saveInterest()}>Continue</button>
      </div>
      </>
    )}

    <div className='modal__close' onClick={() => handleBackClick()}>
        <FaTimes />
      </div>
  </div>
)
}

export default QualifierQuestions;