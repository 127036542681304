import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getCategoriesWithCompanies, updateCategory, indexCategory } from '../../utils/api/admin';

function Categories() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [slug, setSlug] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getCategoriesWithCompanies();
    setData(res.data);
    setLoaded(true);
  }

  useEffect(() => {
    async function loadData(){
      let res = await getCategoriesWithCompanies();
      setData(res.data);
      setLoaded(true);
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEdit(item){
    setName(item.name);
    setDescription(item.description);
    setShortDescription(item.short_description);
    setMetaDescription(item.meta_description);
    setSlug(item.slug);
    setShowEdit(true);
  }

  async function handleUpdate(){
    setSubmitting(true);
    let res = await updateCategory(selectedId, {name, description, short_description: shortDescription, meta_description: metaDescription, slug});
    setShowEdit(false);
    setSubmitting(false);
    loadData();
    loadSaveBanner();
  }

  async function handleIndexed(){
    let res = await indexCategory(selectedId);
    setShowEdit(false);
    loadSaveBanner();
  }

  return (
    <>
      <Helmet>
        <title>Admin - Categories</title>
      </Helmet>

      <h1>Categories {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
        <div>
         <table className='admindashboard__table table'>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Name</th>
            <th>Short description</th>
            <th>Description</th>
            <th>Meta description</th>
            <th>Indexed</th>
            <th></th>
          </tr>

          {data.map((item, index) => (
            <tr key={index}>
              <td><strong>#{index}.</strong></td>
              <td>{item.category_id}</td>
              <td>{item.name}</td>
              <td>{item.short_description}</td>
              <td>{item.description}</td>
              <td>
                <div>
                  <strong>Meta description: </strong>
                  <span>{item.meta_description}</span>
                </div>

                <div>
                  <strong>URL slug: </strong>
                  <span>{item.slug}</span>
                </div>
              </td>

              <td>
                {item.is_indexed && (
                  <span className='admindashboard__green-flag'>Yes</span>
                )}

                {item.is_indexed === false && (
                  <span className='admindashboard__red-flag'>No</span>
                )}
              </td>

              <td>
                <span className='cursor' onClick={() => {
                  setSelectedId(item.category_id);
                  handleEdit(item);}
                  }>
                  <FaEllipsisV />
                </span>
              </td>
            </tr>
          ))}
         </table>
       </div>
      )}

      {showEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '60%', marginLeft: '-30%'}}>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Edit category</h2>

            <div className='mt-10'>
              <div className='form-group'>
                <label>Name</label>
                <input type='text' className='input' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
              </div>

              <div className='form-group'>
                <label>Description</label>
                <textarea type='text' className='input' placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
              </div>

              <div className='form-group'>
                <label>Short description</label>
                <textarea type='text' className='input' placeholder='Short description' value={shortDescription} onChange={(e) => setShortDescription(e.target.value)}></textarea>
              </div>

              <div className='form-group'>
                <label>Meta description</label>
                <textarea type='text' className='input' placeholder='Meta description' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)}></textarea>
              </div>

              <div className='mt-10 center'>
                {submitting === false && (
                <button className='btn' onClick={() => handleUpdate()}>Update</button>
                )}

                {submitting && (
                  <Spinner />
                )}
              </div>

              <div className='mt-10 center'>
                <button className='btn' onClick={() => handleIndexed()}>Mark as indexed</button>
              </div>
            </div>
          </div>
        </>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default Categories;