import React, { useState, useEffect } from 'react';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import {declineDemoBuyer, addBuyerDeclineReason, getBuyerDeclinedReasons} from '../../utils/api';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { FaTimes } from 'react-icons/fa';

const BuyerDecline = ({ setShowDeclinedPopup, currentDemo, demos, setDemos, source, setShowReschedule}) => {
  const [buyerDeclinedReasons, setBuyerDeclinedReasons] = useState([]);
  const [declineReason, setDeclineReason] = useState('');
  const [selectedDeclineReason, setSelectedDeclineReason] = useState(null);
  const [showAreYouSure, setShowAreYouSure] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  async function fetchBuyerDeclinedReasons() {
    let data = await getBuyerDeclinedReasons();
    if (data.success) {
      setBuyerDeclinedReasons(data.data);
    }
  }

  useEffect(() => {
    fetchBuyerDeclinedReasons();
  }, []);

  function closingClick() {
    setShowDeclinedPopup(false);
  }

  function notificationClosingClick() {
    setShowNotification(false);
    setShowDeclinedPopup(false);
  }

  async function handleSubmit(event, id) {
    event.preventDefault();

    if (selectedDeclineReason === null){
      alert("Please select a reason");
      return;
    }

    setIsProcessing(true);

    let res = await declineDemoBuyer(id, source, declineReason);

    if (res.success){
      await addBuyerDeclineReason(id, selectedDeclineReason, declineReason);
      setShowAreYouSure(!showAreYouSure);
      setShowNotification(true);
      currentDemo.demo_status = 'Declined';
      currentDemo.buyer_status = 'Declined';
    }

    else{
      await refresh();

      let res = await declineDemoBuyer(id, source, declineReason);

      if (res.success){
        await addBuyerDeclineReason(id, selectedDeclineReason, declineReason);
        setShowAreYouSure(!showAreYouSure);
        setShowNotification(true);
        currentDemo.demo_status = 'Declined';
        currentDemo.buyer_status = 'Declined';
      }
    }

    let _filtered = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
    setDemos(_filtered);
  }

  return (
    <>
    <FadeIn delay={120} duration={200}>
      <div className='overlay'></div>
      {showAreYouSure && (
        <div className='modal'>
          <div className='modal__close' onClick={closingClick}>
            <FaTimes />
          </div>

          <h2 className='modal__h2'>
            Cancel demo
          </h2>

          <h1 className='declinedpopups__header-text'>Are you sure you want to decline the demo with <strong>{currentDemo.company_name}</strong>?</h1>
          
          <form onSubmit={(e) => handleSubmit(e, currentDemo.demo_id)}>
            <div className='form-group mb-20'>
              <label className='label input-label'>Reason</label>
              <select
                className='input select input_box dropdown-font'
                onChange={(e) => setSelectedDeclineReason(e.target.value)}
                name='choose reason'>
                <option value='0'>Select a reason</option>
                  <>
                    {buyerDeclinedReasons.length > 0 &&
                      buyerDeclinedReasons.map((item, index) => (
                        <option key={index} value={item.decline_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                  </>
              </select>
            </div>

            <div className='form-group'>
              <label className='label input-label'>
                Additional notes:
              </label>

              <textarea
                autoComplete='off'
                value={declineReason}
                onChange={(event) => setDeclineReason(event.target.value)}
                className='input_box textarea'
                name='Objection Reason'
                placeholder='Enter here' />
            </div>

            <div className='center mt-10'>
              {isProcessing === true && (
                <Spinner />
              )}

              {isProcessing === false && source === 'Cancel' && (
                <button className='btn btn--danger'>Confirm Cancellation</button>
              )}

              {isProcessing === false && source !== 'Cancel' && (
                <button className='btn btn--danger'>Not a fit</button>
              )}
            </div>
          </form>
        </div>
      )}

      {showNotification && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={notificationClosingClick}>
              <FaTimes />
            </div>

            <>
              <div className='modal__header'>
                <div style={{fontSize: "20px"}}>
                  You have declined this demo with <span className='dashboarddemo__highlight'>
                    {currentDemo.company_name}.
                  </span>
                </div>
              </div>

              {source === 'Cancel' && (
              <div className=''>
                We have informed them of your cancellation. Please check back often for new demo opportunities!
              </div>
              )}

              <div className='center mt-20'>
                <button className='btn btn--secondary' onClick={() => notificationClosingClick()}>Close</button>
              </div>
            </>
          </div>
        </>
      )}
      </FadeIn>
    </>
  );
};

export default BuyerDecline;