import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  keyframes,
  Center,
  Image,
} from '@chakra-ui/react';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string,
  name: string,
  title: string,
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const TestimonialTest = () => {
  const size = '96px';
  const color = 'seagreen';

  const pulseRing = keyframes`
	0% {
    transform: scale(0.33);
  }
  40%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`;

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.700')}>
        <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
          <Stack spacing={0} align={'center'}>
            <Heading>Hear What Our Clients Say</Heading>
            <Text>Used By The Most Innovative Saas Teams</Text>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 10, md: 4, lg: 10 }}>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Overhead Reduction</TestimonialHeading>
                <TestimonialText>
                  An amazing supplement to our current SDR efforts. We've been
                  able to reduce our overhead by 50%!
                </TestimonialText>
              </TestimonialContent>

              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/logos/boomi.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662005652191'
                }
                name={'Head Of Sales Enablement'}
                title={'Boomi'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Growth Traction</TestimonialHeading>
                <TestimonialText>
                  LeadrPro helped us get the at-bats we needed to pivot the the
                  product to market fit. LeadrPro has been instrumental in us
                  getting to a Series A!
                </TestimonialText>
              </TestimonialContent>

              {/* <AvatarTest /> */}
              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/logos/payengine.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666732947239'
                }
                name={'Head Of Sales'}
                title={'Pay Engine'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Mindblowing Savings</TestimonialHeading>
                <TestimonialText>
                  We replaced our entire SDR team with LeadrPro and generate 3x
                  the volume at 30% the cost!
                </TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/logos/liquid.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662005849434'
                }
                name={'CEO'}
                title={'Liquid'}
              />
            </Testimonial>
          </Stack>
        </Container>
      </Box>
      <Box bg='gray.300' py={20}>
        <Center>
          <Image
            w='6xl'
            src='https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/Group_1589_M2IgTsX9U.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664484320381'
          />
        </Center>
      </Box>
    </>
  );
};

export default TestimonialTest;
