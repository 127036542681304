import './Video.css';
import {useEffect, useState} from 'react';
import { updatePassword, connectZoom, zoomIsSynced, zoomDeauthenticate, createZoomMeeting } from '../../utils/api';
import zoomIcon from '../../assets/design/account/zoom.png';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import queryString from "query-string"

const Video = ({userType}) => {
  const [options, setOptions] = useState([]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [isZoomSynced, setIsZoomSynced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const parsed = queryString.parse(window.location.search);
  const code = parsed["code"]

  const navigate = useNavigate();


  useEffect(() => {
    document.title = "LeadrPro - Zoom integration";
          
    const connect = async () => {
      try {
        setIsLoading(true);
        console.log('before the is synced call');
        let synced = await zoomIsSynced();
        console.log('after the isynced call');
        if(synced.data){
          return setIsZoomSynced(true);
        }
        if (code) {
          const response = await connectZoom(code);
          // console.log("*****",response, "*****")
          if (response.success == true) {
            setIsZoomSynced(true);
            console.info("*****")
            console.log(response, "this is the data returned from the server")
            console.info("*****")
          } else {
            console.log("connectZoom did not connect")
          }
        }
      } catch (err) {
        console.log("this is the err ",err)
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }

    connect();
  }, [code]);

  const handleClick = (e) => {
    e.preventDefault();
    console.log("button CLicked")
    navigate(`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_API_KEY}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`)
  }

  async function deauth () {
    let response = await zoomDeauthenticate();
    if(response.success == true){
      return setIsZoomSynced(false);
    } else {
      console.log("Error couldnt deauth", response);
    }
  }

  async function createMeeting () {
    let response = await createZoomMeeting(20);
    if(response.success == true){
      console.log("zoom meeting created");
      console.log("this is the response: ", response)
    } else {
      console.log("zoom meeting was not created");
      console.log("this is the response: ", response);
    }
  }

  return (
    <div className="companyinfo">
      <Helmet>
          <meta name="description" content="" />
      </Helmet>
      <div className="account__back-link"><a href="/account/home">Back</a></div>

      <div className="account__header">Video Integration</div>

      <div className='accountinfo__form calendar__form'>

        <div className="calendar__item">
          <div className="calendar__icon"><img src={zoomIcon} alt="" /></div>
          <div className="calendar__title">Zoom</div>

          {isLoading && (
            <div><span>Loading...</span></div>
          )}

          {!isZoomSynced && !isLoading &&(
            <a className="calendar__btn" href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_API_KEY}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`}>
              <button>Connect</button>
            </a>
          )}

          {isZoomSynced && !isLoading && (
            <div className="video__btn-wrapper">
              <div className="calendar__btn"><span style={{backgroundColor:"#afddaf", color:"black", padding: "5px 10px", borderRadius: "10px", fontSize: "12px"}}>Connected  ✔</span></div>
              <button className="btn-action video__btn-disconnect" onClick={() => deauth()}>Disconnect</button>
              <button className='btn-action' onClick={createMeeting}>Create Meeting</button>
            </div>
          )}
        </div>
        </div>
    </div>
)
}

  export default Video;