import './PersonaTypes.css';
import {useState,useEffect} from 'react';
import { getPersonaTypes } from '../utils/api';

function PersonaType({selectedPersonaType, setSelectedPersonaType}) {
  const [showPersonaTypeDropdown, setShowPersonaTypeDropdown] = useState(false);
 // const [selectedPersonaType, setSelectedPersonaType] = useState(null);
  const [personaTypes, setPersonaTypes] = useState([]);
  let role = localStorage.getItem('user_role');
  if (!role){
    // eslint-disable-next-line no-const-assign
    role = 'Buyer';
  }

  useEffect(() => {
    async function loadPersonaTypes(){
      try {
        let _personatypes = await getPersonaTypes(role);
  
        if (_personatypes.success){
          setPersonaTypes(_personatypes.body);
        }
        else{
        }
      } catch (error) {
        console.log(error.message);
      }
    }

      loadPersonaTypes();
  }, []);

  async function selectPersonaType(persona_type){
    console.log('p1', persona_type);
    console.log('p2', selectedPersonaType);
    if(selectedPersonaType)
    {
      console.log('removing filter');
      console.log('removing id',  selectedPersonaType.persona_type_id);
     // await removeFilter('Persona', selectedPersonaType.persona_type_id);
    }
    await setSelectedPersonaType(persona_type);

  //  await addFilter("Persona", persona_type.persona_type_id, persona_type.name);
  }
return (
<div style={{marginTop: "10px"}}>
 <div className="searchfilter__selected-persona" onClick={() => setShowPersonaTypeDropdown(!showPersonaTypeDropdown)}>
   {selectedPersonaType && selectedPersonaType !== 0 && (
     <>
      <div className="searchfilter__persona-image"><img src={`/assets/personatypes/${selectedPersonaType.icon_image}`} alt="" /></div>

      <div className='searchfilter__persona-text'>{selectedPersonaType.name}</div>
    </>
   )}

   {selectedPersonaType === null && (
    <div>Select persona type</div>
   )}
 </div>

 {showPersonaTypeDropdown && (
 <div className="searchfilter__dropdown-persona">
   {personaTypes.filter(item => selectedPersonaType === null || item.persona_type_id !== selectedPersonaType.persona_type_id).map(item => (
     <div onClick={() => selectPersonaType(item)}>
       <div className="searchfilter__persona-image"><img src={`/assets/personatypes/${item.icon_image}`} alt="" /></div>
       <div className="searchfilter__persona-text">{item.name}</div>
     </div>
   ))}
 </div>
 )}
</div>
)
   }

   export default PersonaType;