import './Recommended.css';
import star_selected from '../../assets/design/rating-star-selected.png';
import star_deselect from '../../assets/design/rating-star-unselected.png';
import { useState, useEffect } from "react";
import { loginUser, sendInvites, getAffiliates, getAffiliatesByCategory, addClick, getCompanyFromCategory, getCategoryFromSlug } from '../../utils/api';
import classNames from "classnames";
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { getCompany } from '../../utils/api';
import { isAuthed, refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';

function Recommended({setShow}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [category, setCategory] = useState(null);
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [companyLoaded, setCompanyLoaded] = useState(false);
    const navigate = useNavigate();
    const [affiliates, setAffiliates] = useState([]);
    let { id } = useParams();
    let imageUrl = process.env.REACT_APP_IMAGE_URL;
    const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companylogos/';
    const affiliateUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'affiliates/';

    useEffect(() => {       
    }, []);

    async function loadCompany(){
      let response = await getCompanyFromCategory(id);

      if(response.success){
        setCompany(response.data[0]);
        setCompanies(response.data);
      }

      else if (response.status === 403){
        await refresh();

        let response = await getCompanyFromCategory(id);

        if(response.success){
          setCompany(response.data[0]);
          setCompanies(response.data);
        }
      }

      setCompanyLoaded(true);
    }

    async function loadCategory(){
      let res = await getCategoryFromSlug(id);

      if (res.success){
        setCategory(res.data);
      }
    }

    async function loadAffiliates(){

      let res = await getAffiliatesByCategory(id);

      if (res.success){
        setAffiliates(res.data);
      }
    }

    useEffect(() => {
      loadCategory();
      loadCompany();
      loadAffiliates();
    }, []);


    function getStars(number){
      if (!Number.isInteger(Math.ceil(number)))
        number = 0;
  
      let el = [];
      for (let i = 0; i < number; i++){
        el.push(<span className="review__star"><img src={star_selected} alt="star" /></span>);
      }

      for (let i = 0; i < 5 - number; i++){
        el.push(<span className="review__star--unselected"><img src={star_deselect} alt="star" /></span>);
      }
  
      return (
        <div>
          {el}
        </div>
      )
    }

    async function handleClick(id, affiliate_url){
      let res = await addClick(id);
    }

    return (
        <div className="">
        <Helmet>
          <title>{`Best ${category?.name} Solutions`}</title>
          <meta name="description" content="" />
        </Helmet>

        <div className="recommended">
          {category && (
            <>
              <h1>Best {category.name}</h1>

              <div className='recommended__category-description'>
                {category.description}
              </div>
            </>
        )}

        {companyLoaded === true && company != null && (
          <div className="recommended__company-wrapper">
            {companyLoaded === true && companies.length > 0 && (
              <>
                {companies.map(item => (
                <div className='recommended__affiliate'>
                <div className="recommended__affiliate-logo">
                  {item.logo && (
                    <img src={baseImageUrl + 'tr:di-@@companylogo@@default-buyer.png/' + item.logo} alt="" />
                  )}
                  </div>

                  <div className='recommended__affiliate-body'>
                    <div className='recommended__affiliate-description'>
                    <div className='recommended__affiliate-title'>
                      {item.name}
                    </div>

                      <div>
                        {item.description}
                      </div>
                    </div>
                  </div>

                  <div className='recommended__affiliate-cta'>
                    <Link to={`/company/${item.company_id}`} className='btn btn--primary btn-cta'>{'Book a demo (Earn $75)'}</Link>
                  </div>
              </div>
                ))}
              </>
            )}

        <div className="company__info-btns">
          {isAuthed() && (
            <>
              {/* {loaded && profileLoaded === true && company.on_waitlist === false && company.demo_pending === false && company.is_seller && (
                <span>
                  {submitting === false && applied === false && isEnabled === true && (
                    <button className='btn btn--primary' style={{backgroundColor: "mediumaquamarine"}} onClick={() => handleApplyClick()}>Apply to demo this product</button>
                  )}

                  {submitting && (
                    <Spinner />
                  )}
                </span>
              )} */}
{/* 
              {company.on_waitlist === false && (company.demo_pending || applied === true) && (
                <span style={{fontSize: 'small'}}>
                  <FaRegClock /> <strong>Your application is pending</strong>
                </span>
              )} */}

              {/* {company.on_waitlist === true && (
                <>
                  {!userIsOnWaitlist && (
                    <span>
                      <button className='btn btn--primary' onClick={() => handleWaitlistClick()}>Join the Waitlist</button>
                    </span>
                  )}

                  {userIsOnWaitlist && (
                    <span>
                      You're on the waitlist
                    </span>
                  )}
                </>
              )} */}
            </>
          )}

          {!isAuthed() && (
            <>
              <a href='/signup' className='btn btn--primary'>Create free account</a>
            </>
          )}
        </div>
      </div>
      )}

      {affiliates.length > 0 && (
      <div className='recommended__affiliates'>
        <h2>You might also like</h2>

        <div>
            {affiliates.map(item => (
              <div className='recommended__affiliate'>
                <div className="recommended__affiliate-logo">
                  {item.logo && (
                    <img src={affiliateUrl + 'tr:di-@@affiliates@@default-buyer.png/' + item.logo} alt="" />
                  )}
                  </div>

                  <div className='recommended__affiliate-body'>
                    <div className='recommended__affiliate-description'>
                    <div className='recommended__affiliate-title'>
                      {item.name} <span className='recommended__affiliate-cta-text'>{item.cta_text}</span>
                    </div>

                      <div>
                        {item.description}
                      </div>
                    </div>
                  </div>

                  <div className='recommended__affiliate-cta'>
                    <a onClick={() => handleClick(item.affiliate_id, item.affiliateUrl)} className='btn btn--primary btn-cta'>Learn More</a>
                  </div>
              </div>
            ))}
        </div>
      </div>
      )}
    </div>
  </div>
    );
}

export default Recommended;