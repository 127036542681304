import { useState, useEffect } from "react";
import './CreateEvent.css';
import { createEvent } from '../../utils/api';
import {
  getCurrentUser,
  isAuthed,
  logout,
  isSeller,
  isBuyer,
  isEventHost,
  refresh
} from '../../services/auth.service';
import classNames from "classnames";
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import Spinner from "../../components/layout/Spinner";
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import { Editor } from '@tinymce/tinymce-react';
import FadeIn from "../../utils/fadein";
import { FaTimes, FaAsterisk } from "react-icons/fa";

function CreateEvent() {
    const [event, setEvent] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [website, setWebsite] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timezone, setTimezone] = useState('');
    const [venueInstructions, setVenueInstructions] = useState('');
    const [isFree, setIsFree] = useState(true);
    const [requiresApproval, setRequiresApproval] = useState(false);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [fileName, setFileName] = useState('');
    const [isPublished, setIsPublished] = useState(false);
    const [capacity, setCapacity] = useState(null);
    const [showEditDescription, setShowEditDescription] = useState(false);
    const [enableQuestions, setEnableQuestions] = useState(false);
    const [isWebinar, setIsWebinar] = useState(false);
    const [webinarUrl, setWebinarUrl] = useState('');
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      document.title = "LeadrPro - Manage Events";
    }, []);

    async function handleCreateEvent(){
      if (name.length == 0){
        alert("Please enter an event name");
        return;
      }

      if (timezone.length == 0){
        alert("Must enter a valid timezone");
        return;
      }

      let _status = 'Draft';

      if (isPublished)
        _status = 'Published';

      let res = await createEvent(name, description, startDate, endDate, website, _status, fileName, capacity, timezone, enableQuestions, isWebinar, webinarUrl, shortDescription, requiresApproval);

      if (res.success){
        navigate('/manage-event/' + res.data);
      }

      else if (res.status === 403){
        await refresh();

        let res = await createEvent(name, description, startDate, endDate, website, _status, fileName, capacity, timezone, enableQuestions, isWebinar, webinarUrl, shortDescription, requiresApproval);

        if (res.success){
          navigate('/manage-event/' + res.data);
        }
      }
    }

    function onError(){
      console.log('error');
    }
  
    function onSuccess(e){
      setFileName(`https://ik.imagekit.io/ivgrhmd4k/Events/${e.name}`);
    }

    async function handleEditorSave(){
      //handleSaveChanges();
      setShowEditDescription(false);
    }

    return (
        <div className="createevent">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />

          {event && (
            <title>{`LeadrPro - Create new event`}</title>
          )}
        </Helmet>

        <div className="createevent__form">
          <div className="mb-10">
            <Link className="btn" to='/account/events'>&lt; Back</Link>
          </div>

          {isEventHost() === false && (
            <div>
              <i>You do not have the <strong>event host</strong> role turned on. To turn it on and manage your other roles, please <Link className="link" to={'/manage-roles'}>click here</Link>.</i>
            </div>
          )}

          {isEventHost() && (
            <>
          <h1>Create New Event</h1>

          <div style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic', marginBottom: '10px', color: 'tomato'}}><FaAsterisk /> Required fields</div>

          <div className="createevent__form-body">

            <div className="createevent__left">
            <div className="createevent__form-name createevent__form-group">
              <label>Event name <span className="required__icon"><FaAsterisk /></span></label>
              <input type="text" placeholder="Event name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="createevent__form-name createevent__form-group">
              <label>Short Description</label>
              <input type="text" placeholder="Tagline" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} />
            </div>

            <div className="createevent__form-description createevent__form-group">
              <label>Description</label>

              <div>
                <button className="btn" onClick={() => setShowEditDescription(true)}>Launch editor</button>
              </div>

              {/* <textarea className="input textarea" placeholder="Describe your event" value={description} onChange={(e) => setDescription(e.target.value)}></textarea> */}
            </div>

            <div className="createevent__form-dates createevent__form-group">
              <div>
                <label>Start Date <span className="required__icon"><FaAsterisk /></span></label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect  dateFormat="Pp" />
              </div>

              <div>
                <label>End Date <span className="required__icon"><FaAsterisk /></span></label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} showTimeSelect  dateFormat="Pp" />
              </div>
            </div>

            <div className="createevent__form-dates createevent__form-group">
              <div>
                <label>Timezone</label>

                <select name="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                  <option>Select timezone...</option>
                  <option value="America/New_York">America/New_York</option>
                  <option value="America/Detroit">America/Detroit</option>
                  <option value="America/Kentucky/Louisville">America/Kentucky/Louisville</option>
                  <option value="America/Kentucky/Monticello">America/Kentucky/Monticello</option>
                  <option value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</option>
                  <option value="America/Indiana/Vincennes">America/Indiana/Vincennes</option>
                  <option value="America/Indiana/Winamac">America/Indiana/Winamac</option>
                  <option value="America/Indiana/Marengo">America/Indiana/Marengo</option>
                  <option value="America/Indiana/Petersburg">America/Indiana/Petersburg</option>
                  <option value="America/Indiana/Vevay">America/Indiana/Vevay</option>
                  <option value="America/Chicago">America/Chicago</option>
                  <option value="America/Indiana/Tell_City">America/Indiana/Tell_City</option>
                  <option value="America/Indiana/Knox">America/Indiana/Knox</option>
                  <option value="America/Menominee">America/Menominee</option>
                  <option value="America/North_Dakota/Center">America/North_Dakota/Center</option>
                  <option value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</option>
                  <option value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</option>
                  <option value="America/Denver">America/Denver</option>
                  <option value="America/Boise">America/Boise</option>
                  <option value="America/Phoenix">America/Phoenix</option>
                  <option value="America/Los_Angeles">America/Los_Angeles</option>
                  <option value="America/Metlakatla">America/Metlakatla</option>
                  <option value="America/Anchorage">America/Anchorage</option>
                  <option value="America/Juneau">America/Juneau</option>
                  <option value="America/Sitka">America/Sitka</option>
                  <option value="America/Yakutat">America/Yakutat</option>
                  <option value="America/Nome">America/Nome</option>
                  <option value="America/Adak">America/Adak</option>
                  <option value="Pacific/Honolulu">Pacific/Honolulu</option>
              </select>

                {/* <input type="text" placeholder="Enter timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)} /> */}
              </div>
            </div>

            <div className="createevent__form-group">
              <label>Event website</label>
              <input type="url" placeholder="Event website" value={website} onChange={(e) => setWebsite(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>Venue instructions</label>
              <textarea type="text" placeholder="Venue instructions" value={venueInstructions} onChange={(e) => setVenueInstructions(e.target.value)}></textarea>
            </div>

            <div className="createevent__form-group">
              <label>Capacity</label>
              <input type="number" placeholder="50" value={capacity} onChange={(e) => setCapacity(e.target.value)} className="input"/>
            </div>

            <div className="createevent__options">
              <div className="createevent__option">
                <div>Is Free</div>

                <div className="header__modal-switch--round"><label class="switch">
                  <input type="checkbox" checked={isFree} onChange={(e) => setIsFree(e.target.checked)}  />
                  <span class="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Requires Approval</div>
                <div className="header__modal-switch--round"><label class="switch">
                  <input type="checkbox" checked={requiresApproval} onChange={(e) => setRequiresApproval(e.target.checked)}  />
                  <span class="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Enable Questions</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={enableQuestions} onChange={(e) => setEnableQuestions(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              <div className="createevent__option">
                <div>Is Webinar</div>
                <div className="header__modal-switch--round"><label className="switch">
                  <input type="checkbox" checked={isWebinar} onChange={(e) => setIsWebinar(e.target.checked)}  />
                  <span className="slider round"></span>
                      </label>
                </div>
              </div>

              {/* <div className="createevent__option">
                <div>Published</div>
                <div className="header__modal-switch--round"><label class="switch">
                  <input type="checkbox" checked={isPublished} onChange={(e) => setIsPublished(e.target.checked)}  />
                  <span class="slider round"></span>
                      </label>
                </div>
              </div> */}
            </div>

            <div className="createevent__option mt-20">
                <div>Publish?</div>
                <div className="header__modal-switch--round"><label class="switch">
                  <input type="checkbox" checked={isPublished} onChange={(e) => setIsPublished(e.target.checked)}  />
                  <span class="slider round"></span>
                      </label>
                </div>
              </div>

            <div style={{marginTop: '20px'}}>
              <button className="btn btn--full btn--primary" style={{padding: '10px', background: 'mediumseagreen'}} onClick={() => handleCreateEvent()}>Create event {`${isPublished === true ? '(Publish)' : '(Save as draft)'}`}</button>
            </div>
          </div>

          <div className="createevent__right">
          <div className="createevent__form-group">
              <label>Thumbnail</label>
              
              <div className="createevent__thumbnail">
              <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
              </div>
              <div className='companyedit__upload'>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/Events"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
            </div>

            {isWebinar === false && (
              <>
            <div className="createevent__form-location createevent__form-group">
              <div>
                <label>Address 1</label>
                <input type="text" placeholder="Address 1" value={address1} onChange={(e) => setAddress1(e.target.value)} />
              </div>
            </div>

            <div className="createevent__form-group">
              <label>Address 2</label>
              <input type="text" placeholder="Address 2" value={address2} onChange={(e) => setAddress2(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>City</label>
              <input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>State</label>
              <input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
            </div>

            <div className="createevent__form-group">
              <label>Zipcode</label>
              <input type="text" placeholder="Enter location" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
            </div>
            </>
            )}

            {isWebinar && (
              <FadeIn delay={100} duration={100}>
            <div className="createevent__form-group">
              <label>Webinar URL</label>
              <input type="text" placeholder="Enter location" value={webinarUrl} onChange={(e) => setWebinarUrl(e.target.value)} />
            </div>
            </FadeIn>
            )}
          </div>
          </div>
          </>
          )}
        </div>

        {showEditDescription && (
        <FadeIn delay={300} duration={300}>
          {/* <div className="overlay"></div> */}
          <div className="modal" style={{width: '90%', marginLeft: '-45%', zIndex: '1000'}}>
            <div className="modal__close" onClick={() => setShowEditDescription(false)}><FaTimes /></div>
          <Editor
      apiKey='cdi8hbnuihfmdcpjhe2c0jf8b7bov88gr0amec9khuxoykpg'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(newValue, editor) => setDescription(newValue)}
      initialValue={event.description}
    />

      <div className="center" style={{marginTop: '10px'}}>
        <button className="btn btn--primary btn--full" onClick={() => handleEditorSave()}>Save</button>
      </div>
          </div>
        </FadeIn>
      )}
        </div>
    );
}

export default CreateEvent;