import { useState, useEffect } from 'react';
import './UserProfile.css';
import { getCurrentUser } from '../../services/auth.service';
import { refreshToken, getUserCompany, getSeniorityLevels, getCountries, getDepartments, getPublicUserProfile, createBuyerDemo} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import {Helmet} from "react-helmet";
import { isAuthed } from '../../services/auth.service';
import {useNavigate} from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import JoinModalSeller from '../signup/JoinModalSeller';
import { FaLinkedin } from 'react-icons/fa';
import close from '../../assets/design/dashboard/close.png';

const UserProfile = () => {
  let user = getCurrentUser();
  const [loaded ,setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [seniority, setSeniority] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [biography, setBiography] = useState('');
  const [showBooking, setShowBooking] = useState(false);
  const [showBookingConfirm, setShowBookingConfirm] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [copied, setCopied] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();
  let { slug } = useParams();

  useEffect(async () => {
    if (isAuthed())
      setIsLoggedIn(true);

    async function loadUserProfile(){
      let res = await getPublicUserProfile(slug);

      if (res.success){
        setUserProfile(res.data);
        setJobTitle(res.data.job_title);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setBiography(res.data.biography);
        setLoaded(true);
      }
    }

    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
        setLoaded(true);
      }
    }

    async function loadSeniority(){
      let data = await getSeniorityLevels();
      setSeniority(data.data);
    }

    await loadSeniority();
    await loadUserProfile();
    await loadUserCompany();
  }, [slug]);

  async function handleBookDemo(){
    if (isLoggedIn)
      setShowBooking(true);
    else
      setShowJoin(true);
  }

  async function handleSendRequest(){
    setSubmitting(true);
    let data = {buyer_id: userProfile.registered_user_id, credit_cost: 5, message: 'Seller Booked Through Buyer Profile'}
    let res = await createBuyerDemo(data);

    if (res.success){
      if (res.exists === true){
        alert(res.message)
      }

      else{
        setShowBooking(false);
        setShowBookingConfirm(true);
      }

      setSubmitting(false);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await createBuyerDemo(data, 'Seller Booked Through Buyer Profile');

          if (res.success){
            if (res.exists === true){
              alert(res.message)
            }

            else{
              setShowBooking(false);
              setShowBookingConfirm(true);
            }
          }
        }

        setSubmitting(false);
      }
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
    <Helmet>
        <meta name='description' content='' />
        <title>{"Book a demo with " + userProfile?.first_name + " " + userProfile?.last_name}</title>
      </Helmet>

      <div className='userprofile'>
        <div className='userprofile__main'>
          {userProfile && loaded === true && (
          <div className='userprofile__about'>
            <div className='userprofile__about-image'>
              {userProfile.profile_image && userProfile.profile_image !== '' && (
                <img src={baseImageUrl + 'profileimages/' + userProfile.profile_image} alt="logo found" />
              )}

              {(!userProfile.profile_image || userProfile.profile_image === '') && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}
            </div>

            <div className='userprofile__about-right'>
            {userProfile.is_buyer && (
              <div className='feed__item-header-role'>Discovering Software</div>
            )}

            {userProfile.is_buyer === false && userProfile.is_seller && (
              <div className='feed__item-header-role feed__item-header-role-seller feed__item-header-role--small'>Software Provider</div>
            )}

            <div className='userprofile__about-name'>{userProfile.first_name} {userProfile.last_name}</div>

            <div className='userprofile__about-details'>
              <div>{userProfile.job_title}</div>
            </div>

            <div className='userprofile__about-company'>
              <div className='userprofile__about-company-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userProfile.company_logo} />
              </div>
              <div className='userprofile__about-company-name'>{userProfile.company_name}</div>
            </div>

            {userProfile.is_buyer && (
            <div style={{marginTop: "20px"}}>
              <button className='userprofile__btn' onClick={() => handleBookDemo()}>Invite to Apply for a Demo</button>
            </div>
            )}
            </div>
          </div>
            )}

        {userProfile && loaded && userProfile.biography && userProfile.biography.length > 0 && (
          <div className='userprofile__section'>
           <div><strong>About Me</strong></div>
            <div className='userprofile__bio'>
              {userProfile.biography}
            </div>
          </div>
            )}

          <div className='userprofile__section'>
            {userProfile && loaded && (
              <>
            <div className='userprofile__list-item'>
              <div className='userprofile__list-item-label'><strong>Job Title</strong></div>
              <div className='userprofile__list-item-value'>{userProfile.job_title}</div>
            </div>

            <div className='userprofile__list-item'>
              <div className='userprofile__list-item-label'><strong>Seniority Level</strong></div>
              <div className='userprofile__list-item-value'>{userProfile.seniority}</div>
            </div>

            <div className='userprofile__list-item'>
              <div className='userprofile__list-item-label'><strong>Department</strong></div>
              <div className='userprofile__list-item-value'>{userProfile.department_name}</div>
            </div>

            <div className='userprofile__list-item'>
              <div className='userprofile__list-item-label'><strong>Timezone</strong></div>
              <div className='userprofile__list-item-value'>{userProfile.tz}</div>
            </div>
            </>
            )}
          </div>

          {userProfile && loaded && userProfile.linkedin_url && userProfile.linkedin_url.length > 0 && (
          <div className='userprofile__section'>
            <div className='userprofile__social-item'>
                <div className='userprofile__social-item-icon'>
                  <FaLinkedin />
                </div>
                <div className='userprofile__social-item-name'><strong>LinkedIn</strong></div>
                <div className='userprofile__social-item-url'><a href={userProfile.linkedin_url}>{userProfile.linkedin_url}</a></div>
              </div>
          </div>
          )}

          <div>
            <div className='userprofile__section userprofile__company-info'>
              {userProfile && loaded && (
                <>
              <div className='userprofile__company-info-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userProfile.company_logo} />
              </div>

              <div className='userprofile__company-info-header'>
                <div className='userprofile__company-info-name'>{userProfile.company_name}</div>
                <div className='userprofile__company-info-description'>{userProfile.company_description}</div>
              </div>
              </>
              )}
            </div>

            <div className='userprofile__section' style={{display:"none"}}>
              {userProfile && loaded && (
              <div className='userprofile__company-review'>
                <div className='userprofile__company-review-industry'>{userProfile.industry}</div>

                <div>
                  <div className='userprofile__company-review-name'>{userProfile.company_name}</div>
                </div>

                <div className='userprofile__company-review-body'>
                  <div><strong>Company Review</strong></div>

                  <div className='userprofile__company-review-stars'>
                    <div className='userprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='userprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='publicbuyerprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='userprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>
                  </div>
                </div>

                <div className='userprofile__company-review-link'>
                  <a href=''>Full Company Profile</a>
                </div>
              </div>
              )}
            </div>

            {userProfile && loaded && (
            <div className='userprofile__section userprofile__list-items'>
              <div className='userprofile__list-item'>
                <div className='userprofile__list-item-label'><strong>Company Size</strong></div>
                <div className='userprofile__list-item-value'>{userProfile.company_size}</div>
              </div>

              <div className='userprofile__list-item'>
                <div className='userprofile__list-item-label'><strong>Industry</strong></div>
                <div className='userprofile__list-item-value'>{userProfile.industry}</div>
              </div>

              <div className='userprofile__list-item'>
                <div className='userprofile__list-item-label'><strong>Website</strong></div>
                <div className='userprofile__list-item-value'>{userProfile.website}</div>
              </div>
            </div>
            )}
          </div>
        </div>

        {loaded === false && (
          <Spinner />
        )}
      </div>

      {showBooking && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowBooking(false)}>
              <img src={close} />
            </div>
            <h2 className='modal__h2'>Send a meeting request</h2>
            <div className='' style={{marginTop: "30px"}}>
              <p>
                Interested in booking a meeting with me? Send a request and if I'm interested in your product/service I will apply right away!
              </p>
            </div>

            {submitting === false && (
            <div style={{marginTop: "30px"}} className='center'>
              <button className='btn btn--primary' onClick={() => handleSendRequest()}>Send Request</button>
            </div>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </>
      )}

      {showBookingConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowBookingConfirm(false)}>X</div>

            <div>
              Success! Your request has been sent and you will be notified when this person has submitted times.
            </div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => setShowBookingConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}

      {showJoin && (
        <>
          <FadeIn delay={250} duration={250}>
            <JoinModalSeller setShowModal={setShowJoin} />
          </FadeIn>
        </>
      )}
    </>
  )
}

export default UserProfile;