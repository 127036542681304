import React from 'react';
import {
  Box,
  chakra,
  Text,
  Stack,
  Button,
  Icon,
  Image,
  Center,
  Heading,
  Link,
} from '@chakra-ui/react';

const GrowthPlaybookBanner = () => {
  return (
    <>
      <Box px={8} py={24} mx='auto' rounded='md'>
        <Box
          w={{
            base: 'full',
            md: 11 / 12,
            xl: 9 / 12,
          }}
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}>
          <chakra.h1
            mb={6}
            fontSize={{
              base: '4xl',
              md: '6xl',
            }}
            fontWeight='bold'
            lineHeight='none'
            letterSpacing={{
              base: 'normal',
              md: 'tight',
            }}
            color='white'>
            Download our{' '}
            <Text
              display={{
                base: 'block',
                lg: 'inline',
              }}
              w='full'
              bgClip='text'
              bgGradient='linear(to-r, purple.200,purple.500)'
              fontWeight='extrabold'>
              free Growth Playbook!
            </Text>{' '}
          </chakra.h1>
          <chakra.p
            px={{
              base: 0,
              lg: 24,
            }}
            mb={6}
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color='white'>
            Host Chris Sheng (resident growth advisor at Mucker Capital) has
            worked with 250+ venture backed Saas companies on go-to-market and
            growth. He'll share actionable tips, tricks and hacks to fill your
            sales pipeline regardless of market conditions. Gain your power back
            by focusing on growth.
          </chakra.p>
          <Stack
            direction={{
              base: 'column',
              sm: 'row',
            }}
            mb={{
              base: 4,
              md: 8,
            }}
            spacing={2}
            justifyContent={{
              sm: 'left',
              md: 'center',
            }}>
            <Button
              mt={6}
              as='a'
              color={'white'}
              bg={'purple.500'}
              variant='solid'
              colorScheme='brand'
              display='inline-flex'
              alignItems='center'
              justifyContent='center'
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              _hover={{
                bg: 'purple.600',
                color: 'white',
                transform: 'scale(1.05)',
              }}
              size='lg'
              cursor='pointer'
              href='https://www.leadrpro.com/growth-playbook'>
              Get Your Growth Playbook Now
              <Icon boxSize={4} ml={1} viewBox='0 0 20 20' fill='currentColor'>
                <path
                  fillRule='evenodd'
                  d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </Icon>
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default GrowthPlaybookBanner;
