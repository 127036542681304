import './PayoutInfo.css';
import React from 'react';
import { useState, useEffect } from "react";
import { getCategories, searchCompanyCategories, updateCompanyCategory, updateCompany, savePayoutMethod } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import FadeIn from '../../utils/fadein';
import list from '../../assets/design/signin/list.png';
import list_payout from '../../assets/design/signup/list_payout.png';

function PayoutInfo({company_id, setCurrentStep, setShowLogoAndTimezone}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [categoryResults, setCategoryResults] = useState([]);
    const [categoryQuery, setCategoryQuery] = useState('');
    const [isSoftwareCompany, setIsSoftwareCompany] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [methods, setMethods] = useState([]);
    const [showMethods, setShowMethods] = useState(true);
    const [currentMethod, setCurrentMethod] = useState({});
    const [newSource, setNewSource] = useState('');
    let refSource = React.createRef();

    useEffect(() => {
      let _data = [{payout_method_id: 1, name: "PayPal", icon: "lg-paypal.png", icon_large: "lg-paypal.png", type: "Cash", show_name: false, label: "Email or username"}, 
      {payout_method_id: 2, name: "Venmo", icon: "lg-venmo.png", icon_large: "lg-venmo.png", type: "Cash", show_name: false, label: "Username"}, 
      {payout_method_id: 4, name: "Zelle", icon: "lg-zelle.png", icon_large: "lg-zelle.png", type: "Cash", show_name:false, label: "Phone number or email"}, 
      {payout_method_id: 3, name: "Cash App", icon: "lg-cash.png", icon_large: "lg-cash.png", type: "Cash", show_name: true, label: "Username"}, 
      {payout_method_id: 5, name: "Gift Card", icon: "amazon.png", icon_large: "lg-amazon.png", type: "Gift Card", show_name: true, label: "Email"}, 
      {payout_method_id: 6, name: "Gift Card", icon: "lg-visa.png", icon_large: "lg-visa.png", type: "Gift Card", show_name: true, label: "Email"}];

      setMethods(_data);
    }, []);

    function handleMethodClick(item){
      setCurrentMethod(item);
      setShowMethods(false);
    }

    function handleCancelClick(){
      setShowMethods(true);
      setCurrentMethod({});
    }

    function handleSubmit(){
      //setCurrentStep(51); // timezone
      setCurrentStep(6);  // phone
      setShowLogoAndTimezone(true);
    }

    async function handleSubmit2(){
      if (refSource.current.value.length > 0) {
        await savePayoutMethod(currentMethod.payout_method_id, refSource.current.value);

        let data = methods.map((item) => {
          if (item.payout_method_id === currentMethod.payout_method_id){
            item.source = refSource.current.value;
          }

          return item;
        });

        setShowMethods(true);
        //setResult(result => [...result, response]);
        setMethods(data);
        setCurrentMethod({});
      }

      else{
        alert("Cannot leave field empty");
      }
    }

    async function handleChange(e){
      let obj = currentMethod;
      obj.source = e.target.value;

      setCurrentMethod(obj);
      setNewSource(e.target.value);
    }

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="card createaccount">
            <div className="createaccount__left">
              {showMethods && (
                <>
              <h1 className="card__header createaccount__left-header">Payout method</h1>
              
              <p>
                <i>Select how you would like to be paid after each demo.</i>
              </p>
                <div className="payoutinfo__methods">
                  {methods.map((item, index) => (
                    <div key={index} className={`payoutinfo__method ${item.source && item.source.length > 0 ? "selected": ""}`} onClick={() => handleMethodClick(item)}>
                       <div className="payoutinfo__method-icon"><img className="payoutinfo__method-icon" src={"/assets/payoutmethods/" + item.icon} alt="" />
                      </div>

                      {item.show_name && (
                      <div className="payoutinfo__method-name">{item.name}</div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="center" style={{marginTop:"50px"}}>
                    <button className='btn btn-primary' onClick={handleSubmit}>
                        Save and continue
                    </button>
                  </div>
                </>
              )}

              {!showMethods && (
                <div className="payoutinfo__currentmethod">
                  <div className="payoutinfo__currentmethod-icon">
                    <img src={"/assets/payoutmethods/" + currentMethod.icon_large} alt="" />
                  </div>

                  <div className="payoutinfo__currentmethod-form">
                    <label className="label">{currentMethod.label}</label>
                    
                    <div>
                      <input type="text" placeholder="Enter here" value={currentMethod.source} className="input" onChange={handleChange} ref={refSource} />
                    </div>
                  </div>

                  <div style={{marginTop: "30px"}}>
                    <div><button className="btn btn--primary" onClick={handleSubmit2}>Submit</button></div>

                    <div style={{marginTop: "20px"}}><span className="link link--cancel" onClick={() => handleCancelClick()}>Cancel</span></div>
                  </div>
                </div>
              )}
                </div>

                <div className="createaccount__right">
                    <div className="createaccount__right-header">
                        To maximize your earnings:
                    </div>

                    <div className="createaccount__right-list">
                      <img src={list_payout} alt="" />
                  </div>
                </div>
            </div>
        </div>
    );
}

export default PayoutInfo;