import React, {useState, useEffect} from 'react';
import { createNewCustomer } from '../../utils/api';

const Payment = () => {

  async function handleCreateNewCustomer(){
    let res = await createNewCustomer();

    if (res.success){
      alert('new customer has been created');
      console.log('new customer has been created');
    }

    else{
      alert('failed');
    }
  }

  return (
    <>
      <div>
        <button className='btn btn--primary' onClick={() => handleCreateNewCustomer()}>Create new customer</button>
      </div>
    </>
  )
};

export default Payment;