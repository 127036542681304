import { useState, useEffect } from 'react';
import './BuyerProfile.css';
import { getCurrentUser } from '../../services/auth.service';
import { getInbox, refreshToken, getUserProfile, getUserCompany, getSeniorityLevels, getCountries, getDepartments, updateUserProfile, updateUser, getPublicUserProfile, createBuyerDemo} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import {Helmet} from "react-helmet";
import { isAuthed } from '../../services/auth.service';
import {useNavigate} from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import moment from 'moment-timezone';
import JoinModalSeller from '../signup/JoinModalSeller';
import { FaCopy, FaRegCopy } from 'react-icons/fa';
import close from '../../assets/design/dashboard/close.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BuyerProfile = () => {
  let user = getCurrentUser();
  const [inbox, setInbox] = useState([]);
  const [loaded ,setLoaded] = useState(false);
  const [showCompanyInfoEdit, setShowCompanyInfoEdit] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [seniority, setSeniority] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [biography, setBiography] = useState('');
  const [showBooking, setShowBooking] = useState(false);
  const [showBookingConfirm, setShowBookingConfirm] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [copied, setCopied] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();
  let { slug } = useParams();

  useEffect(async () => {
    if (isAuthed())
      setIsLoggedIn(true);

    async function loadUserProfile(){
      let res = await getPublicUserProfile(slug);

      if (res.success){
        setUserProfile(res.data);
        setJobTitle(res.data.job_title);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setBiography(res.data.biography);
        setLoaded(true);
      }

      else if (res.status === 403){

      }
    }

    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
        setLoaded(true);
      }
    }

    async function loadSeniority(){
      let data = await getSeniorityLevels();
      setSeniority(data.data);
    }

    await loadSeniority();
    await loadUserProfile();
    await loadUserCompany();
  }, []);

  async function handleBookDemo(){
    if (isLoggedIn)
      setShowBooking(true);
    else
      setShowJoin(true);
  }

  async function handleSendRequest(){
    let data = {buyer_id: userProfile.registered_user_id, credit_cost: 5}
    let res = await createBuyerDemo(data);

    if (res.success){
      if (res.exists === true){
        alert("Whoops! You have already booked a meeting with this person in the past!")
      }

      else{
        setShowBooking(false);
        setShowBookingConfirm(true);
      }
    }
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
    <Helmet>
        <meta name='description' content='' />
        <title>{"Book a demo with " + userProfile?.first_name + " " + userProfile?.last_name}</title>
      </Helmet>

      <div className='publicbuyerprofile'>
        <div className='publicbuyerprofile__left-side'>
          <div className='publicbuyerprofile__left-side-item'>
            <div>
              <strong>Your Profile URL</strong>
            </div>

            <div>
              <div>
                https://www.leadrpro.com/profile/{slug}
              </div>
              
              <div style={{marginTop:"10px"}}>
                <CopyToClipboard text={`https://www.leadrpro.com/profile/${slug}`} onCopy={handleCopy}>
                  <button className='link'><FaRegCopy /> {copied ? 'Copied!' : 'Copy'}</button>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className='publicbuyerprofile__left-side-item' style={{display:"none"}}>
            <div>
              <strong>Share Your Profile</strong>
            </div>

            <div style={{display: "flex", justifyContent: "space-evenly"}}>
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{height: "20px"}}><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
              </div>

              <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{height: "20px"}}><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"/></svg>
              </div>

              <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{height: "20px"}}><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg>
              </div>

              <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{height: "20px"}}><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM384 160c0 35.3-28.7 64-64 64c-15.4 0-29.5-5.4-40.6-14.5L194.1 256l85.3 46.5c11-9.1 25.2-14.5 40.6-14.5c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-2.5 .1-4.9 .4-7.3L174.5 300c-11.7 12.3-28.2 20-46.5 20c-35.3 0-64-28.7-64-64s28.7-64 64-64c18.3 0 34.8 7.7 46.5 20l81.9-44.7c-.3-2.4-.4-4.9-.4-7.3c0-35.3 28.7-64 64-64s64 28.7 64 64z"/></svg>
              </div>
            </div>
          </div>
        </div>

        <div className='publicbuyerprofile__main'>
          <div className='publicbuyerprofile__background'>
            </div>

          {userProfile && loaded === true && (
          <div className='publicbuyerprofile__about'>
            <div className='publicbuyerprofile__about-image'>
              {userProfile.profile_image && userProfile.profile_image !== '' && (
                <img src={baseImageUrl + 'profileimages/' + userProfile.profile_image} alt="logo found" />
              )}

              {(!userProfile.profile_image || userProfile.profile_image === '') && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}
            </div>
            <div className='publicbuyerprofile__about-name'>{userProfile.first_name} {userProfile.last_name}</div>

            <div className='publicbuyerprofile__about-details'>
              <div>{userProfile.job_title}</div>
            </div>

            <div className='publicbuyerprofile__about-company'>
              <div className='publicbuyerprofile__about-company-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userProfile.company_logo} />
              </div>
              <div className='publicbuyerprofile__about-company-name'>{userProfile.company_name}</div>
            </div>

            <div>
              <button className='publicbuyerprofile__btn' onClick={() => handleBookDemo()}>Book a Demo With Me</button>
            </div>
          </div>
            )}

          <div className='publicbuyerprofile__section'>
            {userProfile && loaded && userProfile.biography && userProfile.biography.length > 0 && (
            <>
           <div><strong>About Me</strong></div>

            <div className='publicbuyerprofile__bio'>
              {userProfile.biography}
            </div>
            </>
            )}
          </div>

          <div className='publicbuyerprofile__section'>
            {userProfile && loaded && (
              <>
            <div className='publicbuyerprofile__list-item'>
              <div className='publicbuyerprofile__list-item-label'><strong>Job Title</strong></div>
              <div className='publicbuyerprofile__list-item-value'>{userProfile.job_title}</div>
            </div>

            <div className='publicbuyerprofile__list-item'>
              <div className='publicbuyerprofile__list-item-label'><strong>Seniority Level</strong></div>
              <div className='publicbuyerprofile__list-item-value'>{userProfile.seniority}</div>
            </div>

            <div className='publicbuyerprofile__list-item'>
              <div className='publicbuyerprofile__list-item-label'><strong>Department</strong></div>
              <div className='publicbuyerprofile__list-item-value'>{userProfile.department_name}</div>
            </div>

            <div className='publicbuyerprofile__list-item'>
              <div className='publicbuyerprofile__list-item-label'><strong>Timezone</strong></div>
              <div className='publicbuyerprofile__list-item-value'>{userProfile.tz}</div>
            </div>
            </>
            )}
          </div>

          <div className='publicbuyerprofile__section'>
            {userProfile && loaded && (
            <div className='publicbuyerprofile__social-item'>
                <div className='publicbuyerprofile__social-item-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                </div>
                <div className='publicbuyerprofile__social-item-name'><strong>LinkedIn</strong></div>
                <div className='publicbuyerprofile__social-item-url'><a href={userProfile.linkedin_url}>{userProfile.linkedin_url}</a></div>
              </div>
            )}
          </div>

          <div>
            <div className='publicbuyerprofile__section-header'>
              Company Info
            </div>

            <div className='publicbuyerprofile__section publicbuyerprofile__company-info'>
              {userProfile && loaded && (
                <>
              <div className='publicbuyerprofile__company-info-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userProfile.company_logo} />
              </div>

              <div className='publicbuyerprofile__company-info-header'>
                <div className='publicbuyerprofile__company-info-name'>{userProfile.company_name}</div>
                <div className='publicbuyerprofile__company-info-description'>{userProfile.company_description}</div>
              </div>
              </>
              )}
            </div>

            <div className='publicbuyerprofile__section' style={{display:"none"}}>
              {userProfile && loaded && (
              <div className='publicbuyerprofile__company-review'>
                <div className='publicbuyerprofile__company-review-industry'>{userProfile.industry}</div>

                <div>
                  <div className='publicbuyerprofile__company-review-name'>{userProfile.company_name}</div>
                </div>

                <div className='publicbuyerprofile__company-review-body'>
                  <div><strong>Company Review</strong></div>

                  <div className='publicbuyerprofile__company-review-stars'>
                    <div className='publicbuyerprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='publicbuyerprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='publicbuyerprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>

                    <div className='publicbuyerprofile__company-review-star'>
                      <div>Does this solution solve a pain point?</div>
                      <div>⭐⭐⭐⭐</div>
                    </div>
                  </div>
                </div>

                <div className='publicbuyerprofile__company-review-link'>
                  <a href=''>Full Company Profile</a>
                </div>
              </div>
              )}
            </div>

            {userProfile && loaded && (
            <div className='publicbuyerprofile__section publicbuyerprofile__list-items'>
              <div className='publicbuyerprofile__list-item'>
                <div className='publicbuyerprofile__list-item-label'>Company Size</div>
                <div className='publicbuyerprofile__list-item-value'>{userProfile.company_size}</div>
              </div>

              <div className='publicbuyerprofile__list-item'>
                <div className='publicbuyerprofile__list-item-label'>Industry</div>
                <div className='publicbuyerprofile__list-item-value'>{userProfile.industry}</div>
              </div>

              <div className='publicbuyerprofile__list-item'>
                <div className='publicbuyerprofile__list-item-label'>Website</div>
                <div className='publicbuyerprofile__list-item-value'>{userProfile.website}</div>
              </div>
            </div>
            )}
          </div>
        </div>

        {loaded === false && (
          <Spinner />
        )}
      </div>

      {showBooking && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowBooking(false)}>
              <img src={close} />
            </div>
            <h2 className='modal__h2'>Book a demo with me</h2>
            <div className='' style={{marginTop: "30px"}}>
              <p>
                Interested in booking a meeting with me?
              </p>
            </div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => handleSendRequest()}>Send Request</button>
            </div>
          </div>
        </>
      )}

      {showBookingConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowBookingConfirm(false)}>X</div>

            <div>
              Success! Your request has been sent and you will be notified when this person has submitted times.
            </div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => setShowBookingConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}

      {showJoin && (
        <>
          <FadeIn delay={250} duration={250}>
            <JoinModalSeller setShowModal={setShowJoin} />
          </FadeIn>
        </>
      )}
    </>
  )
}

export default BuyerProfile;