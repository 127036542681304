
import './DemoInvite.css';
import {useEffect, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getPayoutTransactions, refreshToken, getUserProfile, getAllIndustries, getDemoFromInvite, saveDemoAvailableTimesForInvite, answerQualifierQuestion } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaRegClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaRegMoneyBillAlt, FaCamera, FaVideo } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DemoInvite = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [demo, setDemo] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [token, setToken] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);
  const [answer, setAnswer] = useState('');
  const [done, setDone] = useState(false);
  const search = useLocation().search;
  const navigate = useNavigate();
  let tok = new URLSearchParams(search).get('refid');

  useEffect(() => {

    async function loadDemo(){
      let res = await getDemoFromInvite(tok);

      if (res.success){
        setDemo(res.data);
        setLoading(false);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getDemoFromInvite(tok);

          if (res.success){
            setDemo(res.data);
            console.log(res.data);
            setLoading(false);
          }
        }
      }

      else{
       // alert('nope');
      }
    }
      // based on users current role as buyer or seller

    loadDemo();
  }, []);

  async function handleConfirm(){
    if (answer.length === 0){
      alert("Please answer the question provided!");
      return;
    }

    if (date1 == null || date2 == null || date3 == null){
      alert("Pleave provide up to 3 available times!");
      return;
    }

    let _times = [];
    _times.push(date1);
    _times.push(date2);
    _times.push(date3);

    let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let res = await saveDemoAvailableTimesForInvite(tok, _times, s_timezone, answer);

    if (res.success){
      setDone(true);
      setShowConfirm(true);
    }

    else {
      alert('nope');
    }
  }

  return (
    <>
    <Helmet>
        <title>Schedule your demo</title>
      </Helmet>

    <div className="demoinvite">
      {demo && loading === false && (
        <>
      <div className='demoinvite__left'>
        <div className='demoinvite__header'>You're Invited {demo.first_name}!</div>

        <div className='demoinvite__icons'>
          <div><FaVideo /> <span className='demoinvite__icon-label'>Meeting with: <strong>{demo.seller_company_name}</strong></span></div>
          <div><FaRegClock /> <span className='demoinvite__icon-label'>Time: <strong>30 min</strong></span></div>
          <div><FaRegMoneyBillAlt /> <span className='demoinvite__icon-label'>You earn: <strong>$75</strong></span></div>
        </div>

        <div className='demoinvite__question'>
          <div><strong>Please answer the following question: </strong></div>
          <div className='demoinvite__question-text'>{demo.qualification_question}</div>

          <div>
            <textarea className='input' placeholder='Your answer...' value={answer} onChange={(e) => setAnswer(e.target.value)}></textarea>
          </div>
        </div>
      </div>

      <div className='demoinvite__right'>
        <h1 className='demoinvite__h1'>Provide your availability</h1>

        <div className='demoinvite__p'>
          Select up to 3 times that you will be available in the next few days:
        </div>

        <div className='demoinvite__times'>
          <div>
            <div>Available time 1:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date1} onChange={(date) => setDate1(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>

          <div>
            <div>Available time 2:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date2} onChange={(date) => setDate2(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>

          <div>
            <div>Available time 3:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date3} onChange={(date) => setDate3(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>
        </div>

        <div>
          {done === false && (
            <button className='btn btn--primary demoinvite__btn-confirm' onClick={() => handleConfirm()}>Submit Times</button>
          )}

          {done && (
            <div className='demoinvite__confirm'>
              You're all set! We'll confirm with you shortly.
            </div>
          )}
        </div>
      </div>
      </>
      )}

      {loading && (
        <div style={{padding: "30px"}} className='center'>
          <Spinner />
        </div>
      )}
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal demoinvite__modal-confirm'>
          <div className='modal__close' onClick={() => setShowConfirm(false)}>
            <img src={close} />
          </div>
          
          <div className='demoinvite__modal-post-header'>
            Success!
          </div>

          <p className='demoinvite__modal-body'>
          Hang tight, and we'll confirm the date and time with you shortly.
          </p>

          <div>
            <button className='btn btn--primary' onClick={() => setShowConfirm(false)}>Close</button>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default DemoInvite;