import './JoinModal.css';
import {useState } from 'react';
import { createUser } from '../../utils/api';
import React from 'react';
import classNames from "classnames";

const JoinModal = ({setShowModal}) => {
  const [email, setEmail] = useState("");
  const [completed, setCompleted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const refEmail = React.createRef();

  async function handleSubmit(){
    let el1 = refEmail.current.value;
    let el2 = true;
    let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (el1.length === 0){
        setHasError(true);
        setErrorMessage('Must enter a valid email');
        return;
    }

    let data = {email: el1, optIn: el2, timezone: l_timezone};
    let res  = await createUser(data);

    setEmail(el1);

    if (res.success){
      window.location = '/account-setup?token=' + res.data;
      setHasError(false);
      setErrorMessage('');
      setCompleted(true);
    }
    else{
        setHasError(true);
        setErrorMessage(res.message);
    }
}

  return (
    <div className="overlay">
    <div className="joinmodal">
      <div className='joinmodal__left'>
        <div className="joinmodal__left-text--large">
          Join thousands of other LeadrPro users and participate in paid demos
        </div>

        <div className="joinmodal__left-text--medium">
          Get paid to sit through product demonstrations while learning about the latest innovations. Keep the cash or donate to your favorite charity.
        </div>
      </div>

      <div className='joinmodal__right'>
        <div className="joinmodal__close" onClick={() => setShowModal(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg></div>

        <h2>Create your account</h2>

        <div>
          {completed === false && (
          <div className='form__group'>
            <label className='label input__label' htmlFor='txtEmail'>Enter email</label>
            <input type="email" className="input" ref={refEmail} id="txtEmail" placeholder='Enter your email'></input>
            <div className="joinmodal__checkbox"><input type="checkbox" /> Yes, please keep me updated on news, events and offers</div>
          </div>
          )}

          {hasError === true && (
              <div className="form-group">
                  <span className={classNames("label", {"label--error":hasError})}>{errorMessage}</span>
              </div>
          )}

          {completed === false && (
            <div className='joinmodal__btn'>
              <div><button className="btn btn-primary" onClick={() => handleSubmit()}>Submit</button></div>
              <div className="joinmodal__signin">Already have an account? <a href="/login">Sign in</a></div>
            </div>
          )}

          {completed && (
            <div className="center">
              <h2>Success!</h2>

              <p>
                Check your inbox to verify your email!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  )
}

export default JoinModal;