import './CompanyFeature.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getFeaturesMap, updateFeatureMap } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
 import { isSeller } from '../../services/auth.service';
 import { refresh } from '../../services/auth.service';
import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};


const CompanyFeature = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [featuresmap, setFeaturesmap] = useState([]);
  const [grouped, setGrouped] = useState([]);
  const navigate = useNavigate();

  async function loadFeaturesMap(){
    let res = await getFeaturesMap();

    if (res.success){
      setFeaturesmap(res.data);
      const groupedFeatures = res.data.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = [];
        }
        acc[item.category_name].push(item);
        return acc;
      }, {});

      setGrouped(groupedFeatures);

      setLoading(false);
      setSuccess(true);
    }
  }

  useEffect(() => {
    async function loadFeaturesMap(){
      let res = await getFeaturesMap();

      if (res.success){
        setFeaturesmap(res.data);

            // Group features by category
          const groupedFeatures = res.data.reduce((acc, item) => {
            if (!acc[item.category_name]) {
              acc[item.category_name] = [];
            }
            acc[item.category_name].push(item);
            return acc;
          }, {});

          setGrouped(groupedFeatures);

          setLoading(false);
          setSuccess(true);
      }
    }

    loadFeaturesMap();
  }, []);

  async function handleFeatureClick(feature_id, selected){
    let res = await updateFeatureMap(feature_id, !selected);

    if (res.success){
      loadFeaturesMap();
    }
  }

  return (
    <div className="featuresmap">
      {loading && (
        <Spinner />
      )}

      {loading === false && success == true && (
        <>
        {Object.keys(grouped).map((category) => (
            <div key={category} className="companyfeature__category">
              <h3 className="companyfeature__category-title">{category}</h3>
              <div className="companyfeature__list">
                {grouped[category].map((item) => (
                  <div
                    key={item.feature_id}
                    className={`companyfeature__list-item ${item.selected ? 'selected' : ''}`}
                    onClick={() => handleFeatureClick(item.feature_id, item.selected)}
                  >
                    <span>
                      <IconDisplay iconName={item.icon} />
                    </span>
                    <span className="ml-10">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* <div className='companyfeature__list'>
            {featuresmap.map(item => (
              <div className={`companyfeature__list-item ${item.selected == true ? 'selected': ''}`} onClick={() => handleFeatureClick(item.feature_id, item.selected)}>
                <span><IconDisplay iconName={item.icon} />
                </span>
                <span className='ml-10'>{item.name}</span>
              </div> 
            ))}
          </div> */}
        </>
      )}
    </div>
  )
}

  export default CompanyFeature;