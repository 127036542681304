import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { indexEvent, eventIndexLog } from '../../utils/api';
import { getIndexableEvents } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';

function EventIndexing() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [indexable, setIndexable] = useState([]);
  const [indexableFilter, setIndexableFilter] = useState('All');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadIndexable(){
      let res = await getIndexableEvents();

      if (res.success){
        setIndexable(res.data);
        setLoaded(true);
      }

      else if (res.status == 403){
        await refresh();

        let res = await getIndexableEvents();

        if (res.success){
          setIndexable(res.data);
          setLoaded(true);
        }
      }
    }

    loadIndexable();
  }, []);

  async function loadIndexable(){
    let res = await getIndexableEvents();

    if (res.success){
      setIndexable(res.data);
      setLoaded(true);
    }

    else if (res.status == 403){
      await refresh();

      let res = await getIndexableEvents();

      if (res.success){
        setIndexable(res.data);
        setLoaded(true);
      }
    }
  }

  async function handlePageIndex(event_id){
    let res = await indexEvent(event_id);

    if (res.success){
      await loadIndexable();
    }

    else if (res.status === 403){
      await refresh();

      let res = await indexEvent(event_id);

      if (res.success){
        await loadIndexable();
      }
    }
  }

  async function handleIndexSubmit(event_id){
    let res = await eventIndexLog(event_id);

    if (res.success){
      loadIndexable();
    }

    else if (res.status === 403){
      await refresh();

      let res = await eventIndexLog(event_id);

      if (res.success){
        loadIndexable();
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Event Indexing</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
        <div>
            <h1>Event Indexing - {indexable.filter(x => {
                if (indexableFilter === 'All')
                  return x;

                else if (indexableFilter === 'Indexed')
                {
                  if (x.is_indexed === true)
                    return x;
                }

                else if (indexableFilter === 'NotIndexed'){
                  if (x.is_indexed === false)
                    return x;
                }
              }).length}</h1>

            <div className='admindashboard__index-filters'>
              <button className='btn' onClick={() => setIndexableFilter('All')}>All</button>
              <button className='btn' onClick={() => setIndexableFilter('Indexed')}>Indexed</button>
              <button className='btn' onClick={() => setIndexableFilter('NotIndexed')}>Not indexed</button>
            </div>

            <div className='admindashboard__indexes'>
              {indexable.filter(x => {
                if (indexableFilter === 'All')
                  return x;

                else if (indexableFilter === 'Indexed')
                {
                  if (x.is_indexed === true)
                    return x;
                }

                else if (indexableFilter === 'NotIndexed'){
                  if (x.is_indexed === false)
                    return x;
                }
              }).map(item => (
                <div className='admindashboard__index-item'>
                  <div className='buyerdashboard__recommendation-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.image_url} />
                  </div>

                  <div>
                    <div className='admindashboard__table-name'>#{item.event_id} - {item.name}</div>
   
                      <div style={{fontSize: '12px'}}><strong>Date created:</strong> {new Date(item.date_created).toLocaleDateString()}</div>

                      {item.last_active && (
                        <div style={{fontSize: '12px'}}>Last active: {new Date(item.last_active).toLocaleDateString()}</div>
                      )}

                      <div>
                        <strong>Canonical URL: </strong>
                        <a href={`https://www.leadrpro.com/event/${item.event_id}/${item.canonical_url_slug}`}>{`https://www.leadrpro.com/event/${item.event_id}/${item.canonical_url_slug}`}</a>
                      </div>

                      <div>
                        <strong>Last submitted: </strong>
                        {item.last_index_date && (
                          <span>{new Date(item.last_index_date).toLocaleDateString()}</span>
                        )}

                        {item.last_index_date == null && (
                          <span>Never</span>
                        )}
                      </div>

                      {item.is_indexed == true && (
                        <span className='admindashboard__is-active'><strong>Is Indexed</strong></span>
                      )}

                      {item.is_indexed == false && (
                        <div style={{marginTop: '10px'}} className=''>
                          <button className='btn' onClick={() => handlePageIndex(item.event_id)}>Mark as indexed</button>

                          <button className='btn' onClick={() => handleIndexSubmit(item.event_id)}>Submitted</button>
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
      )}
    </>
  );
}

export default EventIndexing;
