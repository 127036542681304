import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './DashboardDemo.css';
import close from '../../assets/design/dashboard/close.png';
import DeclinedPopup from './DeclinedPopup';
import RescheduleTimes from '../../components/qualifier/RescheduleTimes';
import SubmitReschedule from '../../components/qualifier/SubmitReschedule';
import CalendarLinks from '../../components/qualifier/CalendarLinks';
import { getSeoName } from '../../utils/utils';
import moment from 'moment-timezone';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaTimes, FaEllipsisV, FaRegCalendar, FaRegCalendarAlt } from 'react-icons/fa';
import { getBuyerDemosUpcoming } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import BuyerDecline from './BuyerDecline';

const BuyerDemosUpcoming = ({buyerDeclinedReasons}) => {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [showFutureDemoNotice, setShowFutureDemoNotice] = useState(false);
  const [showMeetingDetails, setShowMeetingDetails] = useState(false);
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [hasDemos, setHasDemos] = useState(true);
  const [showJoin, setShowJoin] = useState(true); // 11.17.2022 - show join button
  const [loaded, setLoaded] = useState(false);
  const [demos, setDemos] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const navigate = useNavigate();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  async function handleToggleMenu(item){
    if (item.demo_id == currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(true);
    }
  }

  useEffect(() => {
    async function loadDemos(){
      let res = await getBuyerDemosUpcoming();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getBuyerDemosUpcoming();

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
        }
      }
    }

    loadDemos();
  }, []);

  useEffect(() => {
    let _democount = demos.filter(
      (item) =>
        item.seller_status === 'Approved' &&
        item.buyer_status === 'Approved' &&
        item.demo_status === 'Pending'
    ).length;

    if (_democount > 0){
      setHasDemos(true);
    }
    else
      setHasDemos(false);
  }, [demos]);

  function companyInfoClickHandle(demo) {
    setShowCompanyInfo(true);
    setCurrentDemo(demo);
  }

  function trimCompanyInfo(string) {
    const length = 150;
    const trimmedString =
      string.length > length ? string.substring(0, length) + '...' : string;
    return trimmedString;
  }

  function declinedClickHandle(demo, id) {
    setCurrentDemo(demo);
    setShowDeclinedPopup(true);
    setShowSubMenu(false);
  }

  function formatDate(date) {
    let d = new Date(date);
    let weekDay = d.getDay();
    let day = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    return weekDays[weekDay] + ', ' + months[month] + ' ' + day + ' ' + year;
  }

  function tFormat(time) {
    time = time.toString();
    const zero = '0';

    if (time.length <= 3) {
      time = zero.concat(time);
      const temp = Array.from(time);
      temp.splice(2, 0, ':');
      const formatted = temp.toString().replace(/,/g, '');
      return formatted;
    } else {
      const temp = Array.from(time);
      temp.splice(2, 0, ':');
      const formatted = temp.toString().replace(/,/g, '');
      return formatted;
    }
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  function futureDemoNoticeClick(demo) {
    let dt = new Date();
    dt.setUTCHours(dt.getUTCHours() - 1);

    if (demo.reschedule_date_time !== null) {
      let dt = new Date();
      let dt2 = new Date(demo.reschedule_date_time);

      dt2.setUTCHours(dt2.getUTCHours() - 1);

      if (dt > dt2) {
        setShowJoin(true);
      } else {
        setShowJoin(false);
      }
    } else {
      let dt = new Date();
      let dt2 = new Date(demo.scheduled_date_time);

      dt2.setUTCHours(dt2.getUTCHours() - 1);

      if (dt > dt2) {
        setShowJoin(true);
      } else {
        setShowJoin(false);
      }
    }

    setShowSubMenu(false);
    setShowMeetingDetails(true);
    setCurrentDemo(demo);
  }

  function handleRescheduleClick(item) {
    setShowMeetingDetails(false);
    setCurrentDemo(item);
    setShowReschedule(true);
    setShowSubMenu(false);
  }

  function getTrimmedString(inputString = '') {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        // Truncate the string to the maximum length minus 1 (for the ellipsis)
        // and ensure we don't cut off in the middle of a word.
        let trimmedString = inputString.substr(0, maxLength - 1);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return `${trimmedString}…`;
    }
  }

  return (
    <>
    {loaded=== false && (
      <Spinner />
    )}

      {loaded && hasDemos === false && (
        <div className='dashboarddemo__no-results'>
          <h2>No upcoming demos found</h2>
        </div>
      )}

      {loaded == true && demos.length > 0 && (
        <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
        <tr>
            <th>Meeting with</th>
            <th></th>
            <th>Date scheduled</th>
            <th></th>
          </tr>
      {demos.map((item, index) => (
          <tr key={index}>
            <td style={{width: '60%'}}>
              <div style={{display: 'flex', alignItems: 'start'}}>
              <div className='buyerdashboard__recommendation-thumbnail' style={{width: '100%'}}>
              {item.thumbnail_image && (
                <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                )}
              </div>
              
              <div className='buyerdashboard__recommendation-name'>
                <div style={{fontSize: '20px'}}>{item.company_name}</div>

                <div className='buyerdashboard__recommendation-description' style={{fontSize: '12px'}}>
                  {item.description && item.description.length > 0 && (
                    <>
                      {getTrimmedString(item.description)}
                    </>
                  )}
                </div>
              </div>
              </div>
            </td>

             {/* <td>
                <div className='dashboard__table-company-logo'>
                  <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                </div>
            </td> */}

            {/* <td>
                  <div className='dashboarddemo__company-name'>
                    <span className='dashboarddemo__company-name'>
                      {item.company_name}
                    </span>

                      <div className='dashboarddemo__company-info-items'>
                      {item.seller_first_name && item.seller_first_name.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Met with:{' '}
                        </span>
                        <span>{item.seller_first_name}</span>{' '}

                        {item.seller_last_name && item.seller_last_name.length > 0 && (
                          <span>
                            {item.seller_last_name.substring(0,1).toUpperCase()}.
                          </span>
                        )}
                      </div>
                    )}

                    {item.seller_email && item.seller_email.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Follow-up email:{' '}
                        </span>
                        <span>{item.seller_email}</span>{' '}
                      </div>
                    )}
                    </div>
                  </div>
                </td> */}

            <td style={{width: '20%'}}>
              <div className=''>
                  <div className='center'>
                    <div style={{fontWeight: 'bold', fontSize: '12px'}} className='center'>
                      Meeting with
                    </div>

                    <div className='dashboarddemo__team-member-profile-image mt-10' style={{display:"inline-block"}}>
                      <img src={baseimageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                    </div>
                    <div style={{fontSize: "12px", fontWeight: 'bold'}}>
                      {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                    </div>
                  </div>
                </div>
              </td>

              <td style={{width: '20%'}}>
                <div className='center bold text12'>
                  Meeting on
                </div>

                <span className='dashboard__table-date' style={{fontWeight: 'bold', textDecoration: 'underline',cursor: 'pointer'}} onClick={() => futureDemoNoticeClick(item)}>
                    <FaRegCalendarAlt /> {new Date(
                      item.demo_date
                    ).toLocaleDateString()} at <span className='dashboard__table-date'>
                    {new Date(
                      item.demo_date
                    ).toLocaleTimeString()}
                </span>

                <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>
                </span>

                  {/* <div className='dashboarddemo__company-info-items'>
                  <div className='dashboarddemo__company-info-item' style={{fontSize: '12px'}}>
                  {item.reschedule_date_time && (
                  <>
                    <div
                      className={
                        item.buyer_status === 'Rescheduled' ||
                        item.seller_status === 'Rescheduled'
                          ? 'dashboarddemo__date-rescheduling'
                          : ''
                      }>
                      <>
                        {weekDays[new Date(item.reschedule_date_time).getDay()]}{' '}
                        {new Date(
                          item.reschedule_date_time
                        ).toLocaleDateString()}{' '}
                        <br />
                        at{' '}
                        {new Date(
                          item.reschedule_date_time
                        ).toLocaleTimeString()}
                      </>
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>
                  </>
                )}

                {!item.reschedule_date_time && (
                  <>
                    <div
                      className={
                        item.buyer_status === 'Rescheduled' ||
                        item.seller_status === 'Rescheduled'
                          ? 'dashboarddemo__date-rescheduling'
                          : ''
                      }>
                      <>
                        {weekDays[new Date(item.scheduled_date_time).getDay()]}{' '}
                        {new Date(
                          item.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        <br />
                        at{' '}
                        {new Date(
                          item.scheduled_date_time
                        ).toLocaleTimeString()}
                      </>
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>
                  </>
                )}
                  </div>
                  </div> */}
                </td>

                <td className='dashboard__table-menu-icon'>
                    <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                        <div className='dashboard__submenu'>
                          <div className='dashboard__submenu-item' onClick={() => futureDemoNoticeClick(item)}>
                            Meeting Details
                          </div>

                          {item.reference_id && (
                            <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                          )}

                          <div className='dashboard__submenu-item' onClick={() => handleRescheduleClick(item)}>
                            Need to reschedule
                          </div>

                          <div onClick={() => declinedClickHandle(item, item.demo_id)} className='dashboard__submenu-item'>Cancel demo</div>
                        </div>
                      </FadeIn>
                    )}
                  </td>

            {/* <div className='dashboarddemo'>
              <div className='dashboarddemo__company-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
              </div>

              <div className='dashboarddemo__company-info'>
                <span className='dashboarddemo__company-name'>
                  Demo with {item.company_name}
                </span>

                <div className='dashboarddemo__company-info-items'>
                  {item.seller_first_name && item.seller_first_name.length > 0 && (
                  <div className='dashboarddemo__company-info-item'>
                    <span className='dashboarddemo__company-info-label'>
                      Meeting with:{' '}
                    </span>
                    <span>{item.seller_first_name}</span>{' '}

                    {item.seller_last_name && item.seller_last_name.length > 0 && (
                      <span>
                        {item.seller_last_name.substring(0,1).toUpperCase()}.
                      </span>
                    )}
                  </div>
                  )}

                  <div className='dashboarddemo__company-info-item'>
                    <span className='dashboarddemo__company-info-label'>
                      Industry:{' '}
                    </span>
                    <span>{item.industry_name}</span>
                  </div>

                  {item.website && (
                  <div className='dashboarddemo__company-info-item'>
                    <span className='dashboarddemo__company-info-label'>
                      Website:{' '}
                    </span>
                    <span>
                    <a href={item.website.startsWith('http') ? item.website : 'https://' + item.website} target="_blank" rel="nofollow" style={{textDecoration: "underline"}}>{item.website}</a>
                    </span>
                  </div>
                )}
                </div>

                {item.description && (
                  <div className='dashboarddemo__company-description'>
                    <div className='dashboarddemo__company-description-text'>
                      {trimCompanyInfo(item.description)}
                    </div>
                  </div>
                )}

                {item.description && item.description.length > 0 && (
                  <div className='link__dash' onClick={() => companyInfoClickHandle(item)}>
                    More about company
                  </div>
                )}
              </div>

              <div className='dashboarddemo__date'>
                {item.seller_status === 'Rescheduled' && (
                  <span className='dashboarddemo__rescheduling-request-tag'>
                    Reschedule Requested
                  </span>
                )}

                {item.buyer_status === 'Rescheduled' && (
                  <span className='dashboarddemo__rescheduling-tag'>
                    Rescheduling
                  </span>
                )}

                <strong>Scheduled for:</strong>
                {item.reschedule_date_time && (
                  <>
                    <div
                      className={
                        item.buyer_status === 'Rescheduled' ||
                        item.seller_status === 'Rescheduled'
                          ? 'dashboarddemo__date-rescheduling'
                          : ''
                      }>
                      <>
                        {weekDays[new Date(item.reschedule_date_time).getDay()]}{' '}
                        {new Date(
                          item.reschedule_date_time
                        ).toLocaleDateString()}{' '}
                        <br />
                        at{' '}
                        {new Date(
                          item.reschedule_date_time
                        ).toLocaleTimeString()}
                      </>
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>
                  </>
                )}

                {!item.reschedule_date_time && (
                  <>
                    <div
                      className={
                        item.buyer_status === 'Rescheduled' ||
                        item.seller_status === 'Rescheduled'
                          ? 'dashboarddemo__date-rescheduling'
                          : ''
                      }>
                      <>
                        {weekDays[new Date(item.scheduled_date_time).getDay()]}{' '}
                        {new Date(
                          item.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        <br />
                        at{' '}
                        {new Date(
                          item.scheduled_date_time
                        ).toLocaleTimeString()}
                      </>
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>
                  </>
                )}
              </div>

              <div className='dashboarddemo__actions'>
                {item.buyer_status === 'Approved' &&
                  item.seller_status === 'Approved' && (
                    <Tooltip
                      label='Ready to start your meeting? Click here!'
                      aria-label='A tooltip'
                      placement='top'>
                      <button
                        className='btn btn--primary'
                        onClick={() => futureDemoNoticeClick(item)}>
                        Meeting Details
                      </button>
                    </Tooltip>
                  )}

                {item.buyer_status === 'Rescheduled' &&
                  item.seller_status === 'Approved' && (
                    <p className='dashboarddemo__actions-text'>
                      Waiting for confirmation
                    </p>
                  )}

                {item.seller_status === 'Rescheduled' && (
                  <>
                    <button
                      className='btn btn--primary btn--dark'
                      onClick={() => handleRescheduleClick(item)}>
                      Reschedule
                    </button>
                  </>
                )}

                <div className='dashboarddemo__actions-links'>
                  <div style={{marginTop: "10px"}}>
                        <Link
                          to={'/dashboard/message/' + item.reference_id}
                          className='btn btn--primary'>
                          
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"/></svg>{' '}
                              <Tooltip label='Have a question / comment?' aria-label='A tooltip' placement='bottom'>
                                <span>Send a message</span>
                              </Tooltip>
                          </Link>
                      </div>

                  <div className='center' style={{marginTop: "10px"}}>
                    <div className='btn btn--primary btn--cancel-demo'
                      onClick={() => declinedClickHandle(item, item.demo_id)}>
                      Cancel Demo
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </tr>
        ))}
        </table>
        </div>
      )}

      {showDeclinedPopup && (
        <div>
          <BuyerDecline
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Buyer'
            buyerDeclinedReasons={buyerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
            setShowReschedule={setShowReschedule}
          />
          {/* <DeclinedPopup
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Buyer'
            buyerDeclinedReasons={buyerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
            setShowReschedule={setShowReschedule}
          /> */}
        </div>
      )}

      {showReschedule && (
        <>
          <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              {currentDemo.booking_style === 'daily_view' && (
                <RescheduleTimes
                  demo={currentDemo}
                  demos={demos}
                  setDemos={setDemos}
                  demo_id={currentDemo.demo_id}
                  company_name={currentDemo.company_name}
                  seller_id={currentDemo.seller_id}
                  showSteps={true}
                  setShowReschedule={setShowReschedule}
                />
              )}

              {currentDemo.booking_style === 'list_view' && (
                <SubmitReschedule
                  demo={currentDemo}
                  demos={demos}
                  setDemos={setDemos}
                  demo_id={currentDemo.demo_id}
                  company_name={currentDemo.company_name}
                  seller_id={currentDemo.seller_id}
                  showSteps={true}
                  setShowReschedule={setShowReschedule}
                />
              )}
            </div>
          </div>
        </>
      )}

      {showCompanyInfo && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: "80%", marginLeft: "-40%", maxHeight: "80%"}}>
            <img
              src={close}
              onClick={() => setShowCompanyInfo(false)}
              className='modal__close'
            />
            <div className='modal__header'>Company Info</div>
            <div className=''>{currentDemo.description}</div>

            <div className='' style={{ marginTop: '40px' }}>
              <a className='link' href={
                  '/company/' +
                  currentDemo.company_id +
                  '/' +
                  getSeoName(currentDemo.company_name)
                }>
                See full company profile
              </a>
            </div>
          </div>
        </>
      )}

      {showMeetingDetails === true && (
        <FadeIn delay={200} duration={200}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowMeetingDetails(false)}>
              <FaTimes />
            </div>
            
            <div className='center'>
              <div style={{ marginBottom: '10px', fontSize: '30px'}}>
                <FaRegCalendarAlt />
              </div>

              <p>Your meeting is scheduled for</p>

              <div className='dashboarddemo__join-meeting-date'>
                {currentDemo.reschedule_date_time && (
                  <>
                    <div>
                      {new Date(
                        currentDemo.reschedule_date_time
                      ).toLocaleDateString()}{' '}
                      at{' '}
                      {new Date(
                        currentDemo.reschedule_date_time
                      ).toLocaleTimeString()}
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>

                    <CalendarLinks
                      scheduled_date_time={currentDemo.reschedule_date_time}
                      meeting_link={currentDemo.company_meeting_link}
                      persona_type={currentDemo.company_name}
                      reference_id={currentDemo.reference_id}
                    />
                    <div className='dashboarddemo__calendar-reminder'>
                    </div>
                  </>
                )}

                {!currentDemo.reschedule_date_time && (
                  <>
                    {currentDemo.scheduled_date_time && (
                      <div>
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleTimeString()}
                      </div>
                    )}

                    {!currentDemo.scheduled_date_time && (
                      <>
                        <div>
                          {formatDate(currentDemo.scheduled_date)} at{' '}
                          {tConvert(tFormat(currentDemo.scheduled_time))}
                        </div>
                      </>
                    )}

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>

                    <CalendarLinks
                      scheduled_date_time={currentDemo.scheduled_date_time}
                      persona_type={currentDemo.company_name}
                      reference_id={currentDemo.reference_id}
                      meeting_link={currentDemo.company_meeting_link}
                    />
                    <div className='dashboarddemo__calendar-reminder'>

                    </div>
                  </>
                )}
              </div>

              <div style={{ marginTop: '5px' }}>
                <div className='modal__header'>House Rules</div>

                <div className='dashboarddemo__house-rules'>
                  <div>
                    * Be on time and on a computer with a functional webcam
                  </div>
                  <div>
                    * Do not reschedule within 24 hours of the demo time
                  </div>
                  <div>
                    * If you do not show up, you may be locked from future demos
                    for up to 2 weeks.
                  </div>
                </div>
              </div>

              <div className='mar20'>
                Need to{' '}
                <span className='link' onClick={() => handleRescheduleClick(currentDemo)}>
                  Reschedule
                </span>
                ?
              </div>

              <div className='form-group center' style={{ marginTop: '50px' }}>
                {currentDemo.company_meeting_link && (
                  <a className='btn btn--primary btn--dark'
                    href={`${currentDemo.company_meeting_link}`} target='_blank'>
                    Join meeting
                  </a>
                )}

                {!currentDemo.company_meeting_link && (
                  <>
                    {showJoin && (
                      <a className='btn btn--primary btn--dark' href={`https://meetings.leadrpro.com/demo?refid=${currentDemo.reference_id}`} target='_blank'>
                        Join meeting
                      </a>
                    )}

                    {showJoin === false && (
                      <div style={{ fontSize: '14px' }}>
                        Meeting link will appear here 1-hour before scheduled time
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
};

export default BuyerDemosUpcoming;
