import './CompanyLogoAndTimezone.css';
import { useState, useEffect } from "react";
import React from 'react';
import { updateUser } from '../../utils/api';
import moment from 'moment-timezone';

function CompanyLogoAndTimezone({setShowPhoneNumber, setShowProfile, setShowLogoAndTimezone, companyId, userId, setCurrentStep}) {
  const [showTimezone, setShowTimezone] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState('');
  const [localTimeZone, setLocalTimeZone] = useState('');
  const [timezones, setTimezones] = useState([]);
  let imagePath = process.env.REACT_APP_IMAGE_PATH;
  let refTimezone = React.createRef();

  useEffect(() => {
    let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCurrentTimeZone(s_timezone);
    setLocalTimeZone(l_timezone);
  }, []);

  useEffect(() => {
    const timezoneList = moment.tz.names();
    setTimezones(timezoneList);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    let _timezone = '';
    if (refTimezone.current.value !== ''){
      _timezone = refTimezone.current.value;
    }

    else{
      _timezone = currentTimeZone;
    }

    let res = await updateUser({ timezone: localTimeZone, timezone_local: localTimeZone });

    if (res.success) {
      setShowPhoneNumber(true);
      setShowProfile(false);
      setShowLogoAndTimezone(false);
      setCurrentStep(6);
    }
    else {
      alert('failed');
    }
  }

  async function handleSkip(){
    setShowPhoneNumber(true);
    setShowProfile(false);
    setShowLogoAndTimezone(false);
    setCurrentStep(6);
  }

  return (
    <div className='card-wrapper center'>
      <div className='card companylogoandtimezone'>
        <h2 className='card-header'>
          Select your timezone
        </h2>

        <form
          className=''
          onSubmit={handleSubmit}>

        {currentTimeZone && currentTimeZone.length > 0 && (
          <div>
            Your current timezone: <strong>{currentTimeZone}</strong>
          </div>
        )}

        {showTimezone === false && (
          <div>
            <span className='link' onClick={() => setShowTimezone(true)}>Or choose a different timezone</span>
          </div>
        )}

          <div className="form-group" style={{display: showTimezone ? "block":"none"}}>
            <label className="label input-label">Select a different timezone</label>

            <select className='select input' ref={refTimezone} id="defaultTimeZoneId" name="defaultTimeZoneId">
              {timezones.map((timezone) => (
                <option key={timezone} value={timezone}>
                  {timezone}
                </option>
              ))}
            </select>
          </div>
          <div className='form-group'>
            <button className='btn btn-primary'>Save and continue</button>
          </div>
        </form>

      </div>
    </div>
  );
}

export default CompanyLogoAndTimezone;
