import './BuyerResults.css';
import './SelectedBuyers.css';
import 'react-calendar/dist/Calendar.css';
import {useState, useEffect} from 'react';
import Spinner from '../../components/layout/Spinner';
import BuyerBooking from '../../components/demos/BuyerBooking';
import { searchBuyers, saveBuyer, getSavedBuyers, createBuyerDemos } from '../../utils/api';
import classNames from 'classnames';
import JoinModal from './JoinModal';
import congrats from '../../assets/design/signup/congrats.png';
import close from '../../assets/design/dashboard/close.png';
import {isAuthed, isSeller, refresh} from '../../services/auth.service';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { FaFonticons, FaInbox } from 'react-icons/fa';

const BuyerResults = ({selectedFilters, setSelectedFilters, query, selectedCompanyId, setSelectedCompanyId}) => {
  const [results, setResults] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirmation, setShowConfirmation]= useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [savedResults, setSavedResults] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [showSelectedBuyers, setShowSelectedBuyers] = useState(false);
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedBuyer, setSelectedBuyer] = useState({});
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [totalCredits, setTotalCredits] = useState(0);
  const [showCompleted, setShowCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20; // resutls per page
  const [totalResults, setTotalResults] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  let authed = isAuthed();
  const navigate = useNavigate();

  useEffect(() =>{
    document.title = "LeadrPro - Search for buyers";

    if (authed && !isSeller()){
      navigate('/account/home');
    }
  }, []);

  useEffect(() => {
    let tmp = selectedBuyers.filter(x => x.registered_user_id !== selectedBuyer.registered_user_id);
    setSelectedBuyers([...tmp, selectedBuyer]);
  }, [selectedBuyer]);


  useEffect(() =>{
    async function loadResults() {
      setCompleted(false);

      let sizes = selectedFilters.filter(item => item.type === 'CompanySize').map(item => item.value);

      let personaTypeId = selectedFilters.filter(item => item.type === 'Persona').map(item => item.value);

      let industries = selectedFilters.filter(item => item.type === "Industry").map(item => item.value);

      let seniority = selectedFilters.filter(item => item.type === "Seniority").map(item => item.value);

      let departments = selectedFilters.filter(item => item.type === "Department").map(item => item.value);

      let job_titles = selectedFilters.filter(item => item.type === "JobTitle").map(item => item.value);
      
      let response = await searchBuyers(query, personaTypeId, industries, departments, job_titles, seniority, sizes, currentPage, selectedCompanyId);

      if (response.success){
        setCompleted(true);
        setResults(response.data);

        if(response.data.length > 0){
          let full = parseInt(response.data[0].full_count);
          setTotalResults(full);
        }

        else{
          // no results found
          setTotalResults(0);
        }

        setCompleted(true);
      }

      else if (response.status === 403){
        await refresh();

        let response = await searchBuyers(query, personaTypeId, industries, departments, job_titles, seniority, sizes, currentPage, selectedCompanyId);

        if (response.success){
          setCompleted(true);
          setResults(response.data);

          if(response.data.length > 0){
            let full = parseInt(response.data[0].full_count);
            setTotalResults(full);
          }

          else{
            // no results found
            setTotalResults(0);
          }

          setCompleted(true);
        }
      }

      let response2 = await getSavedBuyers();

      if (response2.success){
        let tmp1 = response2.data.map((item) => item.buyer_id);
        setSavedResults(tmp1);
      }
     }

     if (selectedFilters.length > 0 || query !== '')
     {
        setShowNoResults(false);
        loadResults();
     }
      else if (results.length === 0){
        setShowNoResults(true);
        setCompleted(true);
      }

      else{
        setResults([]);
        setTotalResults(0);
        setShowNoResults(true);
      }

      window.scrollTo(0,0);
  }, [selectedFilters, currentPage, selectedCompanyId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [query]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilters])

  useEffect(() => {
    let tmp = parseInt(totalResults) / parseInt(perPage);
    let remainder = totalResults % perPage;

    if (remainder > 0)
      tmp += 1;
    setNumberOfPages(Math.floor(tmp));
  }, [totalResults]);

  function bookDemo(e) {
    e.preventDefault();

    if (selectedBuyers.length > 0 && isAuthed())
      setShowSelectedBuyers(true);

    if (!isAuthed()){
      setShowJoin(true);
    }
  }

  function confirmation(){
    setShowBooking(false);
    setShowConfirmation(true);
  }

  function cancelConfirmation(){
    setShowConfirmation(false);
    setShowBooking(true);
  }

  function handleSelectAll(){
    // set all results to selected
    setSelectAll(!selectAll);
  }

  useEffect(() => {
    let total = 0;

    selectedBuyers.map(item => {
      total += item.credit_cost;
    });

    setTotalCredits(total);
  }, [selectedBuyers]);

  async function save(e, user_id) {
    e.preventDefault();

    if (!isAuthed()){
      setShowJoin(true);
    }

    else{
    let res = await saveBuyer(user_id);

    if (!savedResults.includes(user_id))
      setSavedResults([...savedResults, user_id]);
    else{
      let tmp = savedResults.filter(item => item !== user_id);
      setSavedResults(tmp);
    }
  }
  }

  async function bookMeetings(){
    setSubmitting(true);
    let response = await createBuyerDemos(selectedBuyers);

    if (response.success){
      setSubmitting(false);
      setShowSelectedBuyers(false);
      setShowCompleted(true);

      let tmp = selectedBuyers.map(item => item.registered_user_id);

      let newResults = results.map(item => {
        if (tmp.includes(item.registered_user_id)){
          item.demo_status = 1;
        }
  
        return item;
      });
  
      setResults(newResults);
      setSelectedBuyers([]);
      setSelectAll(false);
    }

    else if (response.status === 403){
      await refresh();

      let response = await createBuyerDemos(selectedBuyers);

      if (response.success){
        setSubmitting(false);
        setShowSelectedBuyers(false);
        setShowCompleted(true);

        let tmp = selectedBuyers.map(item => item.registered_user_id);

        let newResults = results.map(item => {
          if (tmp.includes(item.registered_user_id)){
            item.demo_status = 1;
          }
    
          return item;
        });
    
        setResults(newResults);
        setSelectedBuyers([]);
        setSelectAll(false);
      }
      }
    }

  function getPager(){
    let el = [];
    let maxPages = 5;
    let start = 1;
    let end = 10;

   if (currentPage < 10){
    start = 1;
    end = 10;
   }

   else if (currentPage > numberOfPages - 10){
    start = numberOfPages - 10;
    end = numberOfPages;
   }

   else{
    start = currentPage - 5;
    end = currentPage + 5;
   }

   if (numberOfPages < 10)
    end = numberOfPages;

    for (let i = start; i <= end; i++){
      el.push(<span key={i} className={`buyerresults__pager-item ${i === currentPage ? "buyerresults__pager-item--selected": ""}`} onClick={() => setCurrentPage(i)}>{i}</span>);
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  useEffect(() => {
    if (selectedBuyers.length === 0){
      setShowSelectedBuyers(false);
    }
  }
  , [selectedBuyers]);

  useEffect(() => {
    if (selectAll){
      // add all results to selected array
      // that aren't pending
      let tmp = selectedBuyers.map(item => item.registered_user_id);

      setSelectedBuyers(results.filter(item => {
        return item.demo_status !== 1
      }).map(item2 => item2));
    }

    else{
      // clear out selected arrayres
      setSelectedBuyers([]);
    }
  }, [selectAll]);

  async function handleSelectBuyer(id){
    let buyer = results.filter(item => item.registered_user_id === id)[0];

    if (selectedBuyers.find(item => item.registered_user_id===id)){
      // remove id
      setSelectedBuyers(selectedBuyers.filter(item => item.registered_user_id !== id));
    }

    else{
      // add id
      setSelectedBuyers([...selectedBuyers, buyer]);
    }
  }

  async function handleCancelTime(){
    setShowSelectedTime(false);
    setShowSelectedBuyers(true);
  }
  
  async function handleSaveTime(){
    setShowSelectedTime(false);
  }

  async function handleMoreInfo(item){
    setCurrentCompany(item);
    setShowMoreInfo(true);
  }

  return (
    <div className="">
      
      <Helmet>
          <meta name="description" content="" />
      </Helmet>

      <Spinner completed={completed} setCompleted={setCompleted} />

      {/* {results.length === 0 && completed && (
        <div style={{marginTop: "20px"}}><strong>No results found</strong></div>
      )} */}

      {results.length > 0 && (
          <div style={{fontWeight: "bold", marginTop: "15px", marginLeft: "10px"}}>
        Results: {results.length < 20 ? results.length : (perPage * currentPage)} out of {totalResults}
      </div>
      )}

      <table data-pagecount="10" data-id="lsdfj" id="buyertable" className={classNames("table pagination searchresults__table", {
        "hide":!completed
    })}>
        <tbody>
        <tr>
          <th>Job Title</th>
          <th>Company</th>
          <th>Department</th>
          <th>Company Size</th>
          <th>Industry</th>

          <th>Status</th>
        </tr>

        <tr>
          <td colSpan={3}>
            {results.length > 0 && (
            <label className="searchfilter__container" style={{display:"block"}}>
              <input type="checkbox" checked={selectAll} className="searchfilter__checkbox" onClick={handleSelectAll} />
              <span className="searchfilter__checkbox-label">Select All</span>
              <span className="searchfilter__checkmark"></span>

              {selectedBuyers.length > 0 && (
                <span style={{marginLeft: "20px"}}><button className='btn btn--primary btn--small' onClick={bookDemo}>Review Requests ({selectedBuyers.length})</button></span>
                )}
          </label>
            )}
          </td>
        </tr>

        {results.map((item, index) => (
      <tr className={`buyerresults__card ${selectedBuyers.filter(item3 => item3.registered_user_id===item.registered_user_id).length > 0? "selected" : ""}`} key={index}>
        <td>
          <div className="searchresults__persona">

          {item.demo_status !== 1 && (
          <label className="searchfilter__container">
              <input type="checkbox" className="searchfilter__checkbox" checked={selectedBuyers.filter(item2 => item2.registered_user_id === item.registered_user_id).length > 0} onChange={() => handleSelectBuyer(item.registered_user_id)} />
          <span className="buyerresults__checkbox-label">{item.job_title}</span>
          <span className="searchfilter__checkmark"></span>
          </label>
          )}

          {item.demo_status === 1 && (
            <label className="searchfilter__container">
            <input type="checkbox" disabled={true} className="searchfilter__checkbox" checked={selectedBuyers.filter(item2 => item2.registered_user_id === item.registered_user_id).length > 0} onChange={() => handleSelectBuyer(item.registered_user_id)} />
        <span className="buyerresults__checkbox-label">{item.job_title}</span>
        <span className="searchfilter__checkmark"></span>
        </label>
          )}
          </div>
        </td>

        <td>
          <div className="searchresults__persona">
          <div className="searchresults__company-logo">
            {item.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}

                {!item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
          </div>

          <div className="searchresults__company-name">
            <span>
              {item.company_name}
            </span>
          </div>
          </div>
        </td>

        <td className="searchresults__td-department">
          {item.department && item.department.length > 0 && (
            <>{item.department}</>
          )}

          {(!item.department || item.department.length === 0) && (
            <span className="searchresults__not-available">-</span>
          )}
        </td>

        <td className="searchresults__td-company-size">
          {item.company_size && (
            <>{item.company_size}</>
          )}

          {(!item.company_size || item.company_size.length === 0) && (
            <span className="searchresults__not-available">-</span>
          )}
        </td>

        <td className="searchresults__td-industry">
          {item.industry_name}
        </td>

        <td>
          <div className="searchresults__buttons">
          {/* <span className={classNames({"searchresults__save-selected": savedResults.filter((x => x === item.registered_user_id)).length > 0}, "searchresults__save")} onClick={(e) => save(e, item.registered_user_id)}>
                <img src={savedResults.filter((x => x === item.registered_user_id)).length > 0 ? heart_blue: heart} alt="" />
              </span> */}
              
              {/* {item.demo_status !== 1 && selectedBuyers.filter((x => x.registered_user_id === item.registered_user_id)).length === 0 && (
              <button style={{backgroundColor:"#F2F2F2", color:"black"}} className="btn-secondary btn searchresults__btn-book btn--action" onClick={bookDemo} >Request Demo</button>
              )}

              {item.demo_status !== 1 && selectedBuyers.filter((x => x.registered_user_id === item.registered_user_id)).length > 0 && (
              <button className="btn btn--primary" onClick={bookDemo} >Request Demo</button>
              )} */}

              {item.demo_status !== 1 && (
                <span className='searchresults__label-available'>Available</span>
              )}

              {item.demo_status === 1 && (
              <span className="searchresults__label-pending">Pending</span>
              )}
          </div>
        </td>
      </tr>
      ))}
      </tbody>
      </table>

      {selectedFilters.length > 0 && results.length === 0 && completed === true && (
        <div className="searchresults__noresults">
           <div className='center' style={{fontSize: '60px'}}>
            <FaInbox />
          </div>

          <h2>No search results found</h2>
          <p>Buyers matching your search criteria will be displayed here.</p>
        </div>
      )}

      {showNoResults && (
        <div className="searchresults__noresults">
          <div className='center' style={{fontSize: '60px'}}>
            <FaInbox />
          </div>

          <h2>Apply filters to find buyers</h2>
          <p>Buyers matching your search criteria will be displayed here.</p>
        </div>
      )}

      <div className="pager">
          {getPager()}
      </div>

        {results.length > 0 && (
      <div style={{fontWeight: "bold", marginTop: "15px", marginLeft: "10px"}}>
        Results: {results.length < 20 ? results.length : (perPage * currentPage)} out of {totalResults}
      </div>
        )}

      {showSelectedBuyers && (
        <>
        <div className="overlay">
          <div className="selectedBuyers">
            <div className='modal__close'><img src={close} onClick={() => setShowSelectedBuyers(false)} /></div>
            <h2>Review Requests ({selectedBuyers.length})</h2>

            <div className="selectedBuyers__items">
              {selectedBuyers.map((item, index) => (
                <div className="selectedBuyers__item" key={index}>
                  <div className="selectedBuyers__logo">
                  {item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                  )}
                  {!item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                  )}
                  </div>

                  <div className="selectedBuyers__info">
                    <div className="selectedBuyers__role">{item.job_title}</div>
                    <div className="selectedBuyers__company-name">{item.company_name}</div>
                  </div>

                  <div className="selectedBuyers__company-info">
                    <div><strong>Seniority: </strong>{item.seniority}</div>
                    <div><strong>Company Size: </strong>{item.company_size}</div>
                    <div><strong>Industry: </strong>{item.industry_name}</div>
                  </div>

                    {/* {item.description && (
                      <div className="selectedBuyers__company-description">
                        <div><strong>Company Info</strong></div>
                        <div style={{marginTop: "5px"}}>{item.description.length > 120 ? item.description.substring(0, 120) : item.description}...</div>
                      </div>
                    )} */}

                  {/* <div className="selectedBuyers__credits">
                    <>
                    <span>{item.credit_cost} credits</span>

                    {item.interest_level==="High" && (
                    <span className="" style={{marginLeft: "5px", verticalAlign: "middle"}}>
                      <img src={icon_tick} alt="" style={{height: "15px"}} />
                    </span>
                    )}
                    </>
                  </div> */}

                  {/* <div className="selectedBuyers__demo">
                    <div className="selectedBuyers__demo-duration" style={{fontWeight:"bold", marginBottom:"0px"}}>30 minute demo</div>
                    {item.scheduled_time && (
                    <div>{timeConvert(item.scheduled_time)}</div>
                    )}

                    {item.scheduled_date && (
                    <div>{new Date(item.scheduled_date).toLocaleDateString()}</div>
                    )}
                  </div> */}

                  <div className="selectedBuyers__buttons">
                    {/* <div>
                      <button className="btn" onClick={() => handleChangeTime(item)}>Change time</button>
                    </div> */}

                    <div style={{marginTop:"0px"}}>
                      <span className="link link--cancel" onClick={() => handleSelectBuyer(item.registered_user_id)}>X Remove</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="selectedBuyers__calendar">
              <Calendar onChange={onChange} value={value} tileClassName={tileClassName} onClickDay={handleClickDay} />
            </div> */}

            <div className="selectedBuyers__footer">
              {/* <div className="selectedBuyers__footer-credits">
                <div>Total cost</div>
                <div className="selectedBuyers__footer-credits--bold">{totalCredits} credits</div>
              </div> */}

              <div className="selectedBuyers__buttons">
                {submitting === false && (
                <button className="btn btn--primary" onClick={bookMeetings}>Send Demo Requests</button>
                )}

                {submitting && (
                  <Spinner />
                )}
              </div>
            </div>
          </div>

          {showSelectedTime && (
            <div>
              <BuyerBooking selectedBuyer={selectedBuyer} setSelectedBuyer={setSelectedBuyer} setShowSelectedBuyers={setShowSelectedBuyers} setShowSelectedTime={setShowSelectedTime} />

          {/* <div className="selectedBuyers__time" style={{display:"none"}}>
            <div>
            <div className="account__back-link">
              <a href="javascript:void(0)" onClick={() => setShowSelectedTime(false)}>Back</a>
            </div>

            <div>
            <div className="selectedBuyers__time-logo">
            {selectedBuyer.logo && (
                  <img src={imagePath + "/assets/logos/" + selectedBuyer.logo} alt="" />
                )}
                {!selectedBuyer.logo && (
                  <span className="selectedBuyers__initial">{selectedBuyer.company_name[0]}</span>
                )}
          </div>
            </div>

            <div className="selectedBuyers__role">
              <div className="selectedBuyers__persona-type">{selectedBuyer.persona_type}</div>
              <div className="selectedBuyers__company-name">from {selectedBuyer.company_name}</div>
            </div>

            <div className="selectedBuyers__company-info">
              <div>{selectedBuyer.seniority}</div>
              <div>{selectedBuyer.company_size}</div>
              <div>{selectedBuyer.industry_name}</div>
            </div>

            <div style={{marginTop: "42px"}}>
              <div style={{fontWeight: "500", fontSize: "9px"}}>Cost</div>
              <div className="selectedBuyers__time-cost">{selectedBuyer.credit_cost} Credits</div>
            </div>
        </div>

        <div className="selectedBuyers__time-hours">
            <div className="selectedBuyers__selected-date">Monday, February 20</div>
             
            <div style={{marginTop: "20px", marginBottom: "20px"}}>
              {loadDemoHours()}
            </div>

            <div>
              <button className="btn btn--primary" onClick={() => handleSaveTime()}>Save time</button>
            </div>

            <div className="center">
              <a href="javascript:void(0)" className="link--cancel" onClick={() => handleCancelTime()}>Cancel</a>
            </div>
        </div>

        <div>
          <Calendar onChange={onChange} value={value} tileClassName={tileClassName} onClickDay={handleClickDay} />
        </div>
      </div> */}
      </div>
          )}
      </div>
      </>
      )}

      {showJoin && (
      <div className="overlay">
        <JoinModal setShowModal={setShowJoin} />
      </div>
      )}

      {showCompleted && (
            <>
              <div className="overlay">
                <div className="selectedBuyers__completed selectedBuyers" style={{display:"flex", width: "auto", padding: "0px", overflow:" hidden"}}>
                  <div className="createaccount__left">
                  <h2 style={{padding: "0px"}}>Thanks for your requests!</h2>

                  <p style={{marginTop: "10px"}}>
                  Each person has received your request and you will be notified within 48 hours if they have accepted.
                  </p>

                  <p style={{marginTop: "10px"}}>
                  Go to your <a href="/dashboard/seller">dashboard</a> to check the status of your requests.
                  </p>

                  <div style={{marginTop: "40px"}}>
                    <button className="btn btn--primary" onClick={() => setShowCompleted(false)}>Continue searching</button>
                  </div>
                  </div>

                  <div className="createaccount__right">
                      <div className="login__right-list">
                          <img src={congrats} alt="" />
                      </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showMoreInfo && (
            <>
              <div className='overlay'></div>

              <div className='modal'>
                <div className='modal__close'><img src={close} onClick={() => setShowMoreInfo(false)} /></div>

                <div>
                  {currentCompany.description}
                </div>
              </div>
            </>
          )}

      {/* {showBooking===true && <BookingCalendar clickMe={bookDemo} clickConfirm={confirmation} /> } */}

      {/* {showConfirmation===true&& <BuyerConfirmation clickCancel={cancelConfirmation} />} */}
    </div>
  )
}

export default BuyerResults;
