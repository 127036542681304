import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getPageBreakdown } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV } from 'react-icons/fa';

function PageViews() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdownCurrentMonth, setPageBreakdownCurrentMonth] = useState([]);
  const [pageBreakdownLastMonth, setPageBreakdownLastMonth] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadBreakdown(){
      let res = await getPageBreakdown();

      if (res.success){
        setPageBreakdownCurrentMonth(res.data);
        setLoaded(true);
      }

      else if (res.status == 403){
        await refresh();

        let res = await getPageBreakdown();

        if (res.success){
          setPageBreakdownCurrentMonth(res.data);
          setLoaded(true);
        }
      }
    }

    async function loadBreakdownLastMonth(){
      let month = new Date().getMonth();
      let res = await getPageBreakdown(month-1);

      if (res.success){
        setPageBreakdownLastMonth(res.data);
        setLoaded(true);
      }

      else if (res.status == 403){
        await refresh();

        let res = await getPageBreakdown(month-1);

        if (res.success){
          setPageBreakdownLastMonth(res.data);
          setLoaded(true);
        }
      }
    }

    loadBreakdown();
    loadBreakdownLastMonth();
  }, []);

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <div>
          <div>
            <h2>April 2024</h2>
          <table className='dashboard__table table'>
            <tr>
              <th>Page Group</th>
              <th>Views</th>
            </tr>
            {pageBreakdownCurrentMonth.map(item => (
              <tr>
                <td>{item.page_name}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </table>
          </div>
        </div>

        <div className='ml-10'>
          <div>
            <h2>March 2024</h2>
          <table className='dashboard__table table'>
            <tr>
              <th>Page Group</th>
              <th>Views</th>
            </tr>
            {pageBreakdownLastMonth.map(item => (
              <tr>
                <td>{item.page_name}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </table>
          </div>
        </div>
       </div>
      )}
    </>
  );
}

export default PageViews;
;
