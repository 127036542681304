import './SellerRefer.css';
import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { companyInvite, getCompanyInvites, getCompanyTeam, refreshToken, deleteCompanyInvite, getSellerRoleTypes, updateSellerRole, removeSellerRole, getReferToken, addReferToken } from '../../utils/api';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import React from 'react';
import Spinner from '../../components/layout/Spinner';

const SellerRefer = () => {
  const [showModal, setShowModal] = useState(false);
  const [invites, setInvites] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [token, setToken] = useState('');
  const [hasToken, setHasToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  let refEmail = React.createRef();
  let refName = React.createRef();
  let refRole = React.createRef();
  let refEditRole = React.createRef();

  useEffect(() => {
    async function loadRoleTypes(){
      let res = await getSellerRoleTypes();

      if (res.success){
        setRoleTypes(res.data);
      }
    }

    async function loadToken(){
      let res = await getReferToken();

      if (res.success){
        if (res.data === ''){
          setLoading(false);
          setHasToken(false);
        }

        else{
          setLoading(false);
          setHasToken(true);
          setToken(res.data);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getReferToken();

          if (res.success){
            if (res.data === ''){
              setLoading(false);
              setHasToken(false);
            }

            else{
              setLoading(false);
              setHasToken(true);
              setToken(res.data);
            }
          }
        }
      }
    }

    loadToken();
  }, []);

  async function loadInvites(){
    // load 
    let res = await getCompanyInvites();

    if (res.success){
      setInvites(res.data);
    }

    else{
      if (res.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let res = await getCompanyInvites();

            if (res.success){
              setInvites(res.data);
            }
          }
      }
    }
   }
   
   async function loadTeam(){
    let res = await getCompanyTeam();

    if (res.success){
      setTeamMembers(res.data);
    }

    else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getCompanyTeam();

        if (res.success){
          setTeamMembers(res.data);
        }
      }
    }
   }

  useEffect(() => {
    loadInvites();
    loadTeam();
  }, []);

  async function handleSendInvite(){
    let name = refName.current.value;
    let email = refEmail.current.value;
    let role = refRole.current.value;

    let res = await companyInvite(name, email, role);

    if (res.success){
      setShowModal(false);
      loadInvites();
    }
  }

  async function handleInvalidate(company_invite_id){
    let res = await deleteCompanyInvite(company_invite_id);

    if (res.success){
      await loadInvites();
    }
  }

  async function handleEditClick(item){
    setShowEdit(true);
    setCurrentUser(item);
  }

  async function loadTeam(){
    let res = await getCompanyTeam();

    if (res.success){
      setTeamMembers(res.data);
    }
   }

  async function handleUpdateClick(){
    let res = await updateSellerRole(currentUser.registered_user_id, refEditRole.current.value);
    loadTeam();
    setShowEdit(false);
  }

  async function handleDeleteClick(item){
    setShowDelete(true);
    setCurrentUser(item);
  }

  async function handleDeleteConfirm(){
    let res = await removeSellerRole(currentUser.registered_user_id);

    if (res.success){
      setShowDelete(false);
      setShowDeleteConfirm(true);

      let filtered = teamMembers.filter(item => item.registered_user_id !== currentUser.registered_user_id);
      setTeamMembers(filtered);
    }

    else{
      alert("Something went wrong! User could not be removed");
    }
  }

  async function handleSignup(){
    let res = await addReferToken();

    if (res.success){
      setHasToken(true);
      setToken(res.data);
      setShowConfirm(true);
    }
  }

  return (
    <div className="sellerrefer">
      <div className="account__back-link"><Link to="/account/home">Back</Link></div>
      <div className="account__header">Referral Program</div>

      {loading && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {loading === false && (
        <div>
          {hasToken && (
            <div className='sellerrefer__has-token'>
              <p>
                Share your referral link to earn the following bonus payouts:
              </p> 

              <p style={{marginTop: "20px"}}>
                <ul className=''>
                  <li>Earn $25 for any successful sign up.</li>
                  <li>Earn $100 bonus after </li>
                </ul>
              </p>

              <p style={{marginTop: "20px"}}>
                <strong>Referral Link: </strong>
                <span>https://www.leadrpro.com/signup/seller?refer={token}</span>
              </p>
            </div>
          )}

          {hasToken === false && (
            <div className='sellerrefer__no-token'>
              <p>
                Sign up to join our referral program and earn a bonus payout for any successful signups that you bring in.
              </p>

              <p style={{marginTop: "20px"}}>
                <ul className=''>
                  <li>Earn $25 for any successful sign up.</li>
                  <li>Earn $100 bonus after </li>
                </ul>
              </p>

              <div style={{marginTop: "20px"}}>
                <button onClick={() => handleSignup()} className='btn btn--primary' style={{backgroundColor: "mediumaquamarine"}}>Sign me up</button>
              </div>
            </div>
          )}
        </div>
      )}

      {showModal && (
      <div className="overlay" id="divModal">
      <div className="modal">
        <div className="modal__close" onClick={() => setShowModal(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>

        <h2>Invite team member</h2>

        <div>
          <div className="form-group">
            <label className="label">Full name</label>
            <input type="text" className="input" placeholder="Enter name" ref={refName} />
          </div>

          <div className="form-group">
            <label className="label">Email address</label>
            <input type="text" className="input" placeholder="Enter name" ref={refEmail} />
          </div>

          <div className="form-group">
            <label className="label">Role</label>
            <select className="select input" ref={refRole}>
              {roleTypes.map(item => (
                <option value={item.seller_role_type_id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <button className="btn btn--primary" onClick={() => handleSendInvite()}>Send invite</button>
          </div>
        </div>
      </div>
      </div>
      )}

      {showEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>Update User Role</h2>

            <div>
              <i>{currentUser.email}</i>
            </div>

            <div className="form-group">
            <label className="label">Role</label>
            <select className="select input" ref={refEditRole}>
              {roleTypes.map(item => (
                <option value={item.seller_role_type_id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className='form-group center'>
            <button className='btn btn--primary' onClick={() => handleUpdateClick()}>Update</button>
          </div>

          <div className='center'>
            <span className='link link--skip link--cancel' onClick={() => setShowEdit(false)}>Cancel</span>
          </div>
          </div>
        </>
      )}

      {showDelete && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className="modal__close" onClick={() => setShowDelete(false)}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 1L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </div>

            <div className='modal__h2'>Are you sure you want to remove this user from your team?</div>

            <p style={{marginTop: "20px"}}>
              This person will no longer be able to book demos with prospects.
            </p>

            <div className='center' style={{marginTop: "20px"}}>
              <button className='btn btn--primary' onClick={() => handleDeleteConfirm()}>Confirm Removal</button>
            </div>
          </div>
        </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>

          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirm(false)}>X</div>

            <div>
              <p>
                Success! You have joined our referral program.<br/>
              Here is your referral link.
              </p>

              <p>
                <strong>Referral link: </strong>
                <span>https://www.leadrpro.com/signup/seller?refer={token}</span>
              </p>
            </div>
          </div>
        </>
      )}

      {showDeleteConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <p className='center'>
              Success! This user has been removed from your companies account.
            </p>

            <div className='center' style={{marginTop: "20px"}}>
              <button className='btn btn--primary' onClick={() => setShowDeleteConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SellerRefer;