import './Settings.css';
import {useEffect, useState} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { getUserCompany, getUserProfile, pauseCompany, updateEmail } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import {Helmet} from "react-helmet";
import FadeIn from '../../utils/fadein';

const Settings = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [email, setEmail] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    async function loadCompany(){
      let result = await getUserProfile();

      if (result.success){
        setEmail(result.data.email);
      }

      else if (result.status === 403){
        await refresh();

        let result = await getUserProfile();

        if (result.success){
          setEmail(result.data.email);
        }
      }
    }

    loadCompany();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEmailChange(){
    if (email.length > 0){
      let res = await updateEmail(email);

      if (res.success){
        loadSaveBanner();
        setShowMessage(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await updateEmail(email);

        if (res.success){
          loadSaveBanner();
          setShowMessage(true);
        }

        else{
          alert(res.message);
        }
      }

      else{
        alert(res.message);
      }
    }

    else{
      alert("Must include a valid email address");
    }
  }

  return (
    <div className="settings">
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className=""><Link className='btn' to="/account/home">Back</Link></div>
      <div className="account__header">Account Settings</div>

      <div>
        <div className='form-group'>
          <label><strong>Email</strong></label>
          <div>
            <input type='email' className='input' placeholder='Your email' value={email} onChange={(e) => setEmail(e.target.value)}></input>
          </div>

          <div className='mt-2' style={{marginTop: '10px'}}>
            <button className='btn btn--primary' onClick={() => handleEmailChange()}>Update</button>
          </div>
        </div>

        {showMessage === true && (
          <div className='form-group'>
            <i>Please check your email inbox for a verification link.</i>
          </div>
        )}
      </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
        Profile updated successfully!
        </div>
      </FadeIn>
      )}
    </div>
  )
}

  export default Settings;