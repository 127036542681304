import './EmailVerification.css';
import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import { validateToken, setPassword, verifyEmail } from '../../utils/api';
import React from "react";

const EmailVerification = ({showPassword, setRoleType, roleType, setShowSelectRole, setUserId, setAuthenticated}) => {
    const [role, setRole] = useState('');
    const [verified, setVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [token, setToken] = useState('');
    let [loaded, setLoaded] = useState(false);
    let [showError, setShowError] = useState(false);
    let inputPassword = React.createRef();  // React use ref to get input value
    let inputConfirm = React.createRef();
    let refRoleType = React.createRef();

    useEffect(() => {
        async function handleValidate(){
            let tok = new URLSearchParams(search).get('token');
            let result = await verifyEmail(tok);
    
            if (result.success){
                setVerified(true);
                setToken(tok);
            }
    
            else{
                setVerified(false);
            }

            setLoaded(true);
        }
        
        handleValidate();
    }, []);

    async function handleSubmit(e, role_type){
        e.preventDefault();
        let password = inputPassword.current.value;
        let confirm = inputConfirm.current.value;

        if (password.length === 0 || confirm.length === 0){
            setShowError(true);
            setErrorMessage('Must enter a valid password');
            return;
        }
        
        if (password === confirm){
            setShowError(false);
            let result = await setPassword(password, token, role_type);

            showPassword(false);
            setRoleType(role_type);
            setShowSelectRole(true);

            if (result.success){
                showPassword(false);
                setRoleType(role_type);

                localStorage.setItem('access_token', result.data.accessToken);
                localStorage.setItem('refresh_token', result.data.refreshToken);

                setUserId(result.data.userId);
                setAuthenticated(true);
            }

            else{
                console.log('error');
            }
        }
        else{
            setShowError(true);
        }
    }

    return (
        <div className="center card__wrapper">
          <div className="card emailverification">
          {loaded && verified && (
            <div>Your email has been verified!</div>
          )}

          {loaded && !verified && (
            <h3 className="card__header">Token is no longer valid</h3>
          )}
          </div>
        </div>
    )
}

export default EmailVerification;
