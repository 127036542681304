import './Share.css';
import { useState, useEffect } from "react";
import { sendInvites } from '../../utils/api';
import { useNavigate } from 'react-router-dom';

function Share({setCurrentStep, setShow}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [completed, setCompleted] = useState(false);

    useEffect(() => {       
        document.title = "LeadrPro - Success!";
        
    }, []);

    async function handleSubmit(e){
        e.preventDefault();

        if (email1.length === 0 && email2.length === 0 && email3.length === 0){
          alert("Must enter at least one valid email");
        }

        else{
          let data = [];
          if (email1.length > 0)
          data.push(email1);

          if (email2.length > 0)
          data.push(email2);

          if (email3)
          data.push(email3);

          let _res = await sendInvites(data);
          //setCurrentStep(100);
          setCompleted(true);
        }
    }

    function handleSkip(){
      // move to success creen
      //setCurrentStep(100);
      setShow(false);
    }

    return (
        <div className="center card__wrapper">
        <div className="card referfriends createaccount" style={{position: "relative"}}>
            <div className="createaccount__left">
                {completed === false && (
                  <>
                <h1 className="card__header createaccount__left-header">Refer friends</h1>

                <div>
                    <div className="form-group">
                      <label className="label">Email 1</label>
                      <input type="email" placeholder="Email" className="input" value={email1} onChange={(e) => setEmail1(e.target.value)} />
                    </div>

                    <div className="form-group">
                      <label className="label">Email 2</label>
                      <input type="email" placeholder="Email" className="input" value={email2} onChange={(e) => setEmail2(e.target.value)} />
                    </div>

                    <div className="form-group">
                      <label className="label">Email 3</label>
                      <input type="email" placeholder="Email" className="input" value={email3} onChange={(e) => setEmail3(e.target.value)} />
                    </div>
                </div>

                <div className='form-group center'>
                  {/* <a href="" className="">Previous page</a> */}
                  <button className='btn btn-primary' onClick={handleSubmit}>Send invites</button>

                  <div className="center" style={{marginTop: "20px"}}>
                    <span className="link link--cancel" onClick={handleSkip}>Cancel</span>
                  </div>
                </div>
                </>
                )}

                {completed && (
                  <div>
                    <h1 className="card__header createaccount__left-header">Success</h1>

                    <p style={{fontSize: "14px", lineHeight: "1.5"}}>
                    You successfully referred to 3 friends. You're going to earn $5 for every new signup!
                    </p>
                  </div>
                )}
            </div>

                <div className="createaccount__right">
                  {completed === false && (
                    <>
                  <div className="login__right-header">
                      Earn $5 for every new signup!
                  </div>

                  <div className="createaccount__right-subheader">
                    Refer friends, coworkers or peers. Instant credit, available for withdrawal within 24 hours.
                  </div>
                  </>
                  )}
                </div>

                <div style={{position: "absolute", top: "10px", right: "10px", fontSize: "18px", cursor: "pointer"}} onClick={() => setShow(false)}>
                  X
                </div>
            </div>
        </div>
    );
}

export default Share;