import { useState, useEffect } from 'react';
import './RedeemReward.css';
import { getCurrentUser } from '../../services/auth.service';
import { getInbox, getAvailableRewards, getPointRedeemAmounts, getUserWaitlist, refreshToken, getUserAvailablePoints, createUserPointReward, getUserPointHistory, getUserPendingPoints} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import {useNavigate} from 'react-router-dom';
import ScrollToTop from '../../components/layout/ScrollToTop';
import { FaTimes } from 'react-icons/fa';
import imgCoin from '../../assets/rewards/lp-coin.png';

const RedeemReward = ({refreshPoints}) => {
  let user = getCurrentUser();
  const [inbox, setInbox] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [loaded ,setLoaded] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedReward, setSelectedReward] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedAmountObj, setSelectedAmountObj] = useState(null);
  const [sendTo, setSendTo] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [amounts, setAmounts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('Must provide a valid payout method');
  const [availableBalance, setAvailableBalance] = useState(0);
  const [pendingBalance, setPendingBalance] = useState(0);
  const [showPointHistory, setShowPointHistory] = useState(false);
  const [pointHistory, setPointHistory] = useState([]);
  const [showRewardHistory, setShowRewardHistory] = useState(false);
  const [rewardHistory, setRewardHistory] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'Rewards/';
  
  const navigate = useNavigate();

  useEffect(() => {
    async function loadRewards(){
      let res = await getAvailableRewards();

      if (res.success){
        setRewards(res.data);
        setLoaded(true);
      }
    }

    async function loadPendingBalance(){
      let res = await getUserPendingPoints();

      if (res.success){
        setPendingBalance(res.data);
      }
    }

    async function loadPointHistory(){
      let res = await getUserPointHistory();

      if (res.success){
        setPointHistory(res.data);
      }
    }

    async function loadRedeemAmounts(){
      let res = await getPointRedeemAmounts();

      if (res.success){
        setAmounts(res.data);
      }
    }

    async function loadBalance(){
      let res = await getUserAvailablePoints();

      if (res.success){
        setAvailableBalance(res.data);
      }
    }

    loadRewards();
    loadRedeemAmounts();
    loadBalance();
    loadPointHistory();
    loadPendingBalance();
  }, []);

  async function loadBalance(){
    let res = await getUserAvailablePoints();

    if (res.success){
      setAvailableBalance(res.data);
    }
  }

  async function handleClick(id, item){
    setSelectedId(id);
    setSelectedReward(item);
    setShowConfirm(true);
  }

  async function handleConfirm(){
    if (sendTo == ''){
      setShowError(true);
      return;
    }

    if (selectedAmount == null){
      setShowError(true);
      setErrorMessage('Please select a valid amount');
      return;
    }

    let res = await createUserPointReward(selectedId, sendTo, selectedAmount);

    if (res.success){
      await loadBalance();
      setShowError(false);
      setShowConfirm(false);
      setShowSuccess(true);

      setSelectedAmount(null);
      refreshPoints();
    }

    else{
      alert("Failed to process");
    }
  }

  async function handleAmountClick(id, obj){
    setSelectedAmount(id);
    setSelectedAmountObj(obj);
  }

  async function handleRewardHistoryClick(){
    setShowRewardHistory(true);
  }

  return (
    <>
    <div className='redeemrewards'>
      <ScrollToTop />
      <div className='demomessages__demos'>
        <h1 className='buyerdashboard__h1'>Rewards Overview</h1>

        <div className='redeemrewards__cards'>
          <div className='redeemrewards__card'>
            <div className='redeemrewards__card-label'>Available Points</div>
            <div className='redeemrewards__card-points'>{availableBalance}</div>
          </div>

          <div className='redeemrewards__card pending'>
            <div className='redeemrewards__card-label'>Pending Points</div>
            <div className='redeemrewards__card-points'>{pendingBalance}</div>
          </div>
        </div>

        <div style={{position: 'absolute', right: '40px'}}>
          <span className='link' onClick={()=> setShowPointHistory(true)}>View Point History</span>

          {/* <span className='link ml-10'>View reward history</span> */}
        </div>

        <div className='redeemrewards__reward-items'>
          {loaded == false && (
            <Spinner />
          )}

          {rewards.map(item => (
            <div className='redeemrewards__reward-item' onClick={() => handleClick(item.point_reward_id, item)}>
              <div className='redeemrewards__reward-item-image'>
                    <img src={screenshotUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.image_url} />
                        </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>
        <div className='modal redeemrewards__modal'>
          <div className='modal__close' onClick={() => setShowConfirm(false)}>
            <FaTimes />
          </div>

          <h2 className='modal__h2'>Redeem {selectedReward.name} {selectedReward.type == 'gift_card' ? 'Gift Card': ''}</h2>

          <p className='redeemreward__modal-available-points'>
            <span><img src={imgCoin} alt='' /></span>
            <strong className='ml-10'>Available Points: {availableBalance}</strong>
          </p>

        <p className='mt-20'>
          <strong>Choose the amount:</strong>
        </p>

        <div className='redeemrewards__amounts'>
          {amounts.map(x => (
            <>
              {availableBalance >= parseInt(x.number_of_points) && (
              <div className={`redeemrewards__amount ${selectedAmount == x.point_redeem_amount_id ? 'selected': ''}`} onClick={() => handleAmountClick(x.point_redeem_amount_id, x)}>
              <div className='redeemrewards__amount-dollar'>{x.display_name}</div>
              <div className='redeemrewards__amount-points'>{x.number_of_points} Points</div>
            </div>
              )}

{availableBalance < parseInt(x.number_of_points) && (
              <div className={`redeemrewards__amount inactive ${selectedAmount == x.point_redeem_amount_id ? 'selected': ''}`}>
              <div className='redeemrewards__amount-dollar'>{x.display_name}</div>
              <div className='redeemrewards__amount-points'>{x.number_of_points} Points</div>
            </div>
              )}
            </>
          ))}
        </div>
          {/* <div>
            {selectedReward.name}
          </div> */}

          <div className='redeemrewards__form'>
            <div>
              <div><strong>Where do we send it</strong></div>
              <div><input type='text' className='input' placeholder='Email address / Phone number' value={sendTo} onChange={(e) => setSendTo(e.target.value)} /></div>

              {showError && (
                <div className='redeemrewards__form-error'>
                  {errorMessage}
                </div>
              )}
            </div>

            <div>
              <button className='btn redeemrewards__form-btn' onClick={() => handleConfirm()}>Confirm &amp; Redeem</button>
            </div>
          </div>
        </div>
      </>
    )}

    {showSuccess && (
      <>
        <div className='overlay'></div>
        <div className='modal center'>
          <div className='modal__close' onClick={() => setShowSuccess(false)}>
            <FaTimes />
          </div>

          <div className='center redeemreward__modal-header'>
            <span>
              <img src={imgCoin} alt='' />
            </span> <span className='ml-10'>-{selectedAmountObj.number_of_points}</span>
          </div>

        <p>
          <strong>Success!</strong> Your reward is on its way. Distribution's occur on the following Friday from the date of your request.
        </p>

        <p className='mt-30 center'>
          <button className='btn btn--primary' onClick={() => setShowSuccess(false)}>Back to rewards</button>
        </p>
        </div>
      </>
    )}

    {showPointHistory && (
      <>
        <div className='overlay'></div>
        <div className='modal redeemrewards__modal-history'>
          <div className='modal__close' onClick={() => setShowPointHistory(false)}>
            <FaTimes />
          </div>
          <h2 className='modal__h2'>Point History</h2>

          <div className='mt-10'>
            <table className='table redeemrewards__point-history-table'>
              <tr>
                <th>Activity</th>
                <th>Points</th>
                <th>Status</th>
              </tr>

              {pointHistory.map(item => (
                <tr>
                  <td>
                    <div className='flex' style={{alignItems: 'center'}}>
                      {item.name == 'Demo approved' && (
                        <>
                          <span><img src={imgCoin} alt='' style={{height: '20px'}} /></span>
                        </>
                      )}

                    {item.name == 'Demo application' && (
                        <>
                          <span><img src={imgCoin} alt='' style={{height: '20px'}} /></span>
                        </>
                      )}

                      {item.name != 'Demo application' && item.name != 'Demo approved' && (
                        <>
                          <span><img src={imgCoin} alt='' style={{height: '20px'}} /></span>
                        </>
                      )}

                      <span className='ml-10'>{item.name}</span>
                    </div>
                  </td>

                  <td>+{item.points_earned}</td>

                  <td>
                    {item.status == 'Pending' && (
                      <span>Pending</span>
                    )}

                    {item.status == 'Approved' && (
                      <span>Approved</span>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </>
    )}
    </>
  )
}

export default RedeemReward;