import { Fa500Px, FaSearch, FaShoppingCart, FaMapMarkerAlt, FaMapPin, FaCheck, FaCheckCircle, FaRegCheckCircle, FaHeadset, FaTimes } from 'react-icons/fa';
import './Support.css';
import {useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { saveSiteFeedback } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import { refresh } from '../../services/auth.service';

function Support() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [subject, setSubject] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  async function handleSupportClick(){
    setShowPopup(!showPopup);
  }

  async function handleSubmit(){
    setSubmitting(true);
    let res = await saveSiteFeedback(feedbackText, subject);

    if (res.success){
      setSubmitting(false);
      setShowConfirmation(true);
    }

    else if(res.status === 403){
      await refresh();

      setSubmitting(true);
      let res = await saveSiteFeedback(feedbackText, subject);

      if (res.success){
        setSubmitting(false);
        setShowConfirmation(true);
      }
    }
  }

  async function handleClose(){
    setShowPopup(false);
    setShowConfirmation(false);
    setFeedbackText('');
    setSubject('');
  }

return (
  <div className='support'>
    {showPopup && (
      <FadeIn delay={100} duration={100}>
        <div className='support__popup'>
          <div className='modal__close' onClick={() => handleClose()}>
            <FaTimes />
          </div>
          <h2>How can we help?</h2>

          <p className='support__blurb mt-10'>
            If you have any questions, comments or concerns please leave us a message and we will get back to you within 24 hours.
          </p>

          {showConfirmation === false && (
            <>
          <div className='mt-10'>
            <input type='text' className='input' placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
          </div>

          <div className='mt-10'>
            <textarea className='input textarea' placeholder='Include all relevant details' value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)}></textarea>
          </div>

          <div className='mt-10 center'>
            <button className='btn btn--full btn--wide btn--primary' onClick={() => handleSubmit()}>Submit</button>
          </div>
          </>
          )}

          {showConfirmation && (
            <div className='mt-10'>
              <FaRegCheckCircle /> Your message has been sent. Please give us up to 24 hours to review and get back to you. Thank you!
            </div>
          )}
        </div>
      </FadeIn>
    )}

    <div className='support__icon' onClick={() => handleSupportClick()}>
      <FaHeadset />
    </div>
  </div>
  )
   }

   export default Support;