import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Center,
  chakra,
  Image,
  Heading,
  Link,
} from '@chakra-ui/react';

const FAQTestBuyer = () => {
  return (
    <div id='faq-buyer'>
      <Box display={{ base: 'none', md: 'block' }}>
        <Center>
          <chakra.h1 fontSize='4xl' mt={10}>
            {' '}
            Frequently Asked Questions{' '}
          </chakra.h1>
        </Center>
        <Box px={'300px'} py={10}>
          <Accordion allowToggle>
            <AccordionItem
              borderTop={'2px solid black'}
              borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>How much do I get paid?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                Payout amounts vary per demo but start at at rate of $75 per
                completed demo.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>Does this cost me anything to use?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                LeadrPro is always free to use for all demo participants. Just
                sign up, apply to demos and discover new software solutions!
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>How long are the demos?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                Demos do not have a fixed time but most demos average between 30
                to 60 minutes.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>How should I prepare for LeadrPro demos?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={4}>
                Make sure to be in a quiet room, have webcam access and be on
                time!
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>What is expected of me during the meeting?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                There are no contractual obligations but if you would like to
                move forward with the company you're meeting with we will make
                an intro.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>What do I do after I complete a demo on LeadrPro?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                After you complete a demo simply return to your LeadrPro
                dashboard and after filling out a short feedback form you'll be
                ready to get paid!
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>
                    Is there a limit to how many demos I can participate in?
                  </h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                No! Our platform is always free for participants and there is no
                limit to the amount of demos you can apply to.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>What if I need to cancel a demo?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                All demos can be cancelled as long as they are within our 24
                hour courtesy window.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={'2px solid black'}>
              <AccordionButton
                _hover={{
                  bg: 'black',
                  color: 'white',
                }}>
                <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                  <h2>What if I have more questions?</h2>
                </Box>
                <AccordionIcon mr={4} />
              </AccordionButton>

              <AccordionPanel pb={4} px={14}>
                No problem! Feel free to reach out to our sales team at{' '}
                <chakra.span fontStyle={'italic'}>
                  support@leadrpro.com
                </chakra.span>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderTop='px solid black'></AccordionItem>
          </Accordion>
        </Box>
        <Center>
          <Box mt={10} mb={'200px'} textAlign='center'>
            <Center>
              <Image
                mb={8}
                w={'100px'}
                src='https://ik.imagekit.io/ivgrhmd4k/logos/logo_cvTdzqJku.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650838236165'
                alt='leadrpro logo'
              />
            </Center>

            <Heading
              fontWeight={800}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'110%'}
              mb={4}>
              Try LeadrPro Today
            </Heading>
            <chakra.p
              mb={4}
              fontSize={['md', , 'lg']}
              color='black'
              _dark={{
                color: 'gray.400',
              }}
              maxW='xl'>
              <chakra.span fontStyle={'italic'}>
                Shouldn't you be compensated for your time <br />
                even when being marketed to?
              </chakra.span>
            </chakra.p>
            <chakra.p
              mb={6}
              fontSize={['md', , 'lg']}
              color='black'
              _dark={{
                color: 'gray.400',
              }}
              maxW='xl'>
              <chakra.span fontWeight={600}>
                Sign up and start getting paid.
              </chakra.span>
            </chakra.p>
            <Link
              as={'a'}
              href='https://www.leadrpro.com/signup'
              target={'_blank'}
              w={['full', , 'auto']}
              display='inline-flex'
              alignItems='center'
              justifyContent='center'
              px={10}
              py={3}
              mt={[5, , 0]}
              border='solid transparent'
              fontWeight='semibold'
              rounded='md'
              shadow='md'
              h='fit-content'
              color='white'
              bg='purple.500'
              _hover={{
                bg: 'purple.600',
                color: 'white',
                transform: 'scale(1.05)',
              }}
              flex='0.2'>
              Create your free account!
            </Link>
          </Box>
        </Center>
      </Box>
    </div>
  );
};

export default FAQTestBuyer;
