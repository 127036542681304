import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  chakra,
  Center,
  Box,
  Button,
  Link,
} from '@chakra-ui/react';
import {
  FaSearchDollar,
  FaFileContract,
  FaMoneyBillWave,
} from 'react-icons/fa';

import { ReactElement } from 'react';

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const StatsHeroBuyer = () => {
  return (
    <>
      <Container maxW={'7xl'} py={14}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={10}
          alignItems={'center'}>
          <Stack px={10}>
            <Box mb={8} textAlign='left'>
              <Heading color='white' mb={4}>
                This is software discovery{' '}
                <chakra.span fontStyle='italic' color='purple.500'>
                  reimagined.
                </chakra.span>{' '}
              </Heading>
              <Text color={'gray.200'} fontSize={'lg'}>
                Most of the time we aren't even aware that there are software
                solutions to our biggest day-to-day painpoints.
              </Text>
            </Box>

            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                />
              }
              color='gray.300'>
              <Feature
                icon={
                  <Icon as={FaFileContract} color={'yellow.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                text={'Application'}
              />
              <Feature
                icon={
                  <Icon as={FaSearchDollar} color={'purple.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('purple.100', 'purple.900')}
                text={'Innovation'}
              />
              <Feature
                icon={
                  <Icon as={FaMoneyBillWave} color={'green.500'} w={5} h={5} />
                }
                iconBg={useColorModeValue('green.100', 'green.900')}
                text={'Solutions'}
              />
            </Stack>

            <Box w='full' display={{ base: 'none', md: 'inline-flex' }}>
              <chakra.a
                mt={6}
                href='/software'
                w='full'
                display='inline-flex'
                alignItems='center'
                justifyContent='center'
                px={2}
                py={3}
                mx='120px'
                fontWeight='bold'
                rounded='md'
                bg='purple.500'
                color='white'
                _hover={{
                  bg: 'purple.600',
                  color: 'white',
                  transform: 'scale(1.01)',
                }}
                cursor='pointer'>
                Browse our current softwares{' '}
                <Icon
                  boxSize={4}
                  ml={2}
                  mt={1}
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                    clipRule='evenodd'
                  />
                </Icon>
              </chakra.a>
            </Box>
          </Stack>
          <Box display={{ base: 'inline-block', md: 'none' }}>
            <Center>
              <a href='/software'>
                <Button bg='purple.500' color='white' px={10} mt={6}>
                  Browse Paid Demos
                  <Icon
                    boxSize={4}
                    ml={2}
                    mt={1}
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                      clipRule='evenodd'
                    />
                  </Icon>
                </Button>
              </a>
            </Center>
          </Box>

          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={
                'https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/Group_1587_F3Jw2TSK5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664480068709'
              }
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
      </Container>
      <Box bg='gray.200' py={20}>
        <Center>
          <Image
            w='6xl'
            src='https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/Group_1588_8GgUVettz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664480931524'
          />
        </Center>
      </Box>

      <Box bg='black' rounded='lg'>
        <Container maxW={'6xl'}>
          <Stack
            as={Box}
            textAlign={'center'}
            // spacing={{ base: 8, md: 14 }}
            // py={{ base: 20, md: 36 }}
            py={{ base: 12, md: 20 }}>
            <Text
              mb={2}
              fontSize={['2xl', , '6xl']}
              fontWeight='bold'
              lineHeight='tight'
              color='white'>
              Are you interested in
              <Text
                as={'span'}
                // bgClip='text'
                // bgGradient='linear(to-r, purple.200,purple.500)'
                color='purple.500'>
                {' '}
                becoming a host?
              </Text>
            </Text>
            <Text color={'gray.200'} fontSize='lg'>
              Do you have an innovative software solution you would like to
              share on LeadrPro? <br />
              Sign up as a host and start booking demos today!
            </Text>
            <Stack
              direction={'column'}
              spacing={3}
              align={'center'}
              alignSelf={'center'}
              position={'relative'}
              display={{ base: 'none', md: 'inline-block' }}>
              <Box mt={10}>
                <Link
                  as={'a'}
                  href='https://calendly.com/leadrpro/leadrpro-demo'
                  target={'_blank'}
                  w={['full', , 'auto']}
                  display='inline-flex'
                  alignItems='center'
                  justifyContent='center'
                  px={10}
                  py={3}
                  mt={[5, , 0]}
                  fontWeight='semibold'
                  rounded='md'
                  shadow='md'
                  h='fit-content'
                  bg='purple.500'
                  color='white'
                  _hover={{
                    bg: 'purple.600',
                    color: 'white',
                    transform: 'scale(1.05)',
                  }}
                  flex='0.2'>
                  Book A Meeting With Sales Team
                  <Icon
                    boxSize={4}
                    ml={2}
                    mt={1}
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                      clipRule='evenodd'
                    />
                  </Icon>
                </Link>
              </Box>
            </Stack>
            <Center display={{ base: 'inline-block', md: 'none' }}>
              <a href='https://calendly.com/leadrpro/leadrpro-demo'>
                <Button bg='purple.500' color='white' mt={8}>
                  Book a meeting with us
                  <Icon
                    boxSize={4}
                    ml={2}
                    mt={1}
                    viewBox='0 0 20 20'
                    fill='currentColor'>
                    <path
                      fillRule='evenodd'
                      d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                      clipRule='evenodd'
                    />
                  </Icon>
                </Button>
              </a>
            </Center>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default StatsHeroBuyer;
