import './UpdatePassword.css';
import {useEffect, useState} from 'react';
import { updatePassword } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';

const UpdatePassword = ({userType}) => {
  const [options, setOptions] = useState([]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    document.title = "LeadrPro - Update your password";
  }, []);

  async function handleSubmit(e){
    e.preventDefault();

    let current = e.target.elements.currentpassword.value;
    let newPassword = e.target.elements.newpassword.value;
    let confirm = e.target.elements.confirmpassword.value;

    if (newPassword === confirm){
      let res = await updatePassword(current, newPassword);

      if (res.success){
        setHasError(false);
        setError('');
        setConfirm(true);
      }

      else{
        setConfirm(false);
      }
    }

    else{
      setHasError(true);
      setError("Passwords do not match!");
    }
  }

  return (
    <div className="companyinfo">
      <Helmet>
          <meta name="description" content="" />
      </Helmet>
      <div className="account__back-link"><Link to="/account/home">Back</Link></div>
      <div className="account__header">Update Password</div>

      <form className='accountinfo__form' onSubmit={handleSubmit}>
          <div className="form__group">
            <label className="label">Current password</label>
            <input type="password" id="currentpassword" required className="input" placeholder="Current password" />
          </div>

          <div className="form__group">
            <label className="label">New password</label>
            <input type="password" id="newpassword" required className="input" placeholder="New password" />
          </div>

          <div className="form__group">
            <label className="label">Confirm password</label>
            <input type="password" id="confirmpassword" required className="input" placeholder="Confirm password" />
          </div>

          <div className="form__group">
            <button className="btn btn--primary">Update Password</button>
          </div>

          {confirm && (
            <div style={{backgroundColor: "#c6ff9f ", padding: "20px"}}>
              Your password has been successfully updated!
            </div>
          )}
      </form>
    </div>
)
}

  export default UpdatePassword;