import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Center,
  chakra,
  Image,
  Heading,
  Link,
} from '@chakra-ui/react';

const FAQTest = () => {
  return (
    <div>
      <Center>
        <chakra.h1 fontSize='4xl' mt={10}>
          {' '}
          Frequently Asked Questions{' '}
        </chakra.h1>
      </Center>
      <Box px={'300px'} py={10}>
        <Accordion allowToggle>
          <AccordionItem
            borderTop={'2px solid black'}
            borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>How does the demo booking process work?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              Simply pick a plan and our team will find you the best candidates
              for your one on one video demo. Only pay for demos completed.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>How many demos per month should I expect?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              Your monthly average number of demos will reflect the LeadrPro
              pricing plan you pick. <br /> On average our customers see an
              average of 10-20 completed demos per month.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>How are demos conducted?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              All demos are private and hosted on our custom video platform with
              screen sharing and recording compatability.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>What if I need to cancel a demo?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={4}>
              All demos can be cancelled as long as they are within our 24 hour
              courtesy window.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>How long are the demos?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              Demos do not have a fixed time limit but most demos average
              between 30 to 60 minutes.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>What if a person doesn't show up to a booked demo?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              These things happen - demos can be rescheduled up to 3 times.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom={'2px solid black'}>
            <AccordionButton
              _hover={{
                bg: 'black',
                color: 'white',
              }}>
              <Box flex='1' textAlign='left' fontSize={'xl'} px={4} py={4}>
                <h2>What if I have more questions?</h2>
              </Box>
              <AccordionIcon mr={4} />
            </AccordionButton>

            <AccordionPanel pb={4} px={14}>
              No problem! Feel free to reach out to our sales team at{' '}
              <chakra.span fontStyle={'italic'}>
                support@leadrpro.com
              </chakra.span>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
      <Center>
        <Box mt={10} mb={'200px'} textAlign='center'>
          <Center>
            <Image
              mb={8}
              w={'100px'}
              src='https://ik.imagekit.io/ivgrhmd4k/logos/logo_cvTdzqJku.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650838236165'
              alt='leadrpro logo'
            />
          </Center>

          <Heading
            fontWeight={800}
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            lineHeight={'110%'}
            mb={4}>
            Try LeadrPro Today
          </Heading>
          <chakra.p
            mb={2}
            fontSize={['md', , 'lg']}
            color='black'
            _dark={{
              color: 'gray.400',
            }}
            maxW='xl'>
            {/* Become a host today and{' '} */}
            <chakra.span fontStyle={'italic'}>
              Become a host today and change the way you do sales forever!
            </chakra.span>
          </chakra.p>
          <chakra.p
            mb={6}
            fontSize={['md', , 'lg']}
            color='black'
            _dark={{
              color: 'gray.400',
            }}
            maxW='xl'>
            <chakra.span fontWeight={600}>
              Sign up and get your first demo free.
            </chakra.span>
          </chakra.p>
          <Link
            as={'a'}
            href='https://calendly.com/leadrpro/leadrpro-demo'
            target={'_blank'}
            w={['full', , 'auto']}
            display='inline-flex'
            alignItems='center'
            justifyContent='center'
            px={10}
            py={3}
            mt={[5, , 0]}
            border='solid transparent'
            fontWeight='semibold'
            rounded='md'
            shadow='md'
            h='fit-content'
            color='white'
            bg='purple.500'
            _hover={{
              bg: 'purple.600',
              color: 'white',
              transform: 'scale(1.05)',
            }}
            flex='0.2'>
            Create your free account!
          </Link>
        </Box>
      </Center>
    </div>
  );
};

export default FAQTest;
