import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getProFeatures, getProFeatureCategories, updateProFeature, addProFeature } from '../../utils/api/admin';
import './ProFeatures.css';
import { FaEdit, FaPlus } from 'react-icons/fa';
import * as FaIcons from 'react-icons/fa';

const IconDisplay = ({ iconName }) => {
  // Dynamically get the icon component from the FaIcons object
  const IconComponent = FaIcons[iconName];

  // If the icon is found, render it
  if (IconComponent) {
    return <IconComponent />;
  }

  // Fallback if icon is not found
  return <p>Icon not found</p>;
};

function ProFeatures() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false); // New state to determine if adding a new feature
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getProFeatures();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getProFeatures();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    async function loadCategories(){
      let res = await getProFeatureCategories();

      if (res.success){
        setCategories(res.data);
      }
    }

    loadData();
    loadCategories();
  }, []);

  const handleAddClick = () => {
    setEditItem({
      category_name: '',
      name: '',
      description: '',
      icon: '',
    });
    setIsAddingNew(true);
    setShowModal(true);
  };

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditClick = (item) => {
    setEditItem(item);
    setIsAddingNew(false);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setLoaded(false);

    if (isAddingNew == false){
      const response = await updateProFeature(editItem.feature_id, editItem.name, editItem.description, editItem.icon, editItem.feature_category_id);
      if (response.success) {
        // setData((prevData) =>
        //   prevData.map((item) => (item.id === editItem.id ? editItem : item))
        // );
        }

      loadData();
      setShowModal(false);
    }

    else{
      const response = await addProFeature(editItem.name, editItem.description, editItem.icon, editItem.feature_category_id);

      loadData();
      setShowModal(false);
    }
    setLoaded(true);
  };

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Features {`(${data.length})`}</h1>

      <div>
        <button className='add-button' onClick={handleAddClick}>
            <FaPlus /> Add New Feature
          </button>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Category</th>
              <th>Name</th>
              <th>Description</th>
              <th>Icon</th>
              <th></th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.category_name}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                  <IconDisplay iconName={item.icon} />
                </td>
                <td>{item.icon}</td>

                <td>
                <button
                      className='edit-button'
                      onClick={() => handleEditClick(item)}
                    >
                      <FaEdit /> Edit
                    </button>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

{showModal && editItem && (
        <>
          <div className='overlay' onClick={() => setShowModal(false)}></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>{isAddingNew ? 'Add New Feature' : 'Edit Feature'}</h2>

            <div className='modal__content'>
              <form>
                <div className='form-group'>
                  <label htmlFor='category_name'>Category</label>

                  <select
                    id='category_name'
                    name='feature_category_id'
                    className='input select'
                    value={editItem.feature_category_id}
                    onChange={handleInputChange}
                  >
                    <option value=''>
                      Select a category
                    </option>
                    {categories.map((category, index) => (
                      <option key={index} value={category.feature_category_id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Name</label>
                  <input
                    type='text'
                    className='input'
                    id='name'
                    name='name'
                    value={editItem.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='description'>Description</label>
                  <textarea
                    id='description'
                    className='input'
                    name='description'
                    value={editItem.description}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='icon'>Icon</label>
                  <input
                    type='text'
                    className='input'
                    id='icon'
                    name='icon'
                    value={editItem.icon}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  type='button'
                  className='btn btn--primary'
                  onClick={handleSaveChanges}
                >
                  {isAddingNew ? 'Add Feature' : 'Save Changes'}
                </button>
              </form>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default ProFeatures;