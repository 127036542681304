import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes, FaStar, FaRegStar } from 'react-icons/fa';
import './HomepageSeller.css';
import medialogos from '../../assets/design/logos/medialogos.png';
import memberlogos from '../../assets/design/homepage/memberlogos.png';
import jpm from '../../assets/design/logos/jpm.png';
import mucker from '../../assets/design/logos/mucker.png';
import kl from '../../assets/design/logos/kl.png';
import executive_events from '../../assets/design/homepage/executive_events.png';
import sponsors from '../../assets/design/logos/sponsors.png';
import csv from '../../assets/design/homepage/csv.png';
import sellerlogos from '../../assets/design/homepage/sellerlogos.png';
import leadcard from '../../assets/design/homepage/leadcard.png';
import mobileapp from '../../assets/design/homepage/mobileapp.png';
import virtual1 from '../../assets/events/virtualevent1.png';
import virtual2 from '../../assets/events/virtualevent2.png';
import virtual3 from '../../assets/events/virtualevent3.png';
import virtual4 from '../../assets/events/virtualevent4.png';
import virtual5 from '../../assets/events/virtualevent5.png';
import virtual6 from '../../assets/events/virtualevent6.png';
import virtual7 from '../../assets/events/virtualevent7.png';
import virtual8 from '../../assets/events/virtualevent8.png';
import virtual9 from '../../assets/events/virtualevent9.png';
import badge1 from '../../assets/design/homepage/badge1.png';

import { getAvailableSellersAll } from '../../utils/api';

function HomepageSeller() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getAvailableSellersAll();

      if (res.success){
        setSellers(res.data);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  return (
    <div className='homepageseller'>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='homepageseller__hero'>
        <div className='homepageseller__hero-left'>
          <div className='homepageseller__hero-h1'>
            Create your profile and get free leads for life.
          </div>

          <div className='center homepageseller__hero-btns'>
            <button className='homepage__hero-btn homepage__hero-btn--primary' onClick={() => navigate('/signup')}>Get started</button>

          </div>
        </div>

        <div className='homepageseller__hero-right'>
          <img src={csv} />
        </div>
      </div>

      <div className='homepageseller__logos'>
        <img src={sellerlogos} alt='' />
      </div>

      <div className='homepageseller__setup-profile homepageseller__section'>
        <div className='homepageseller__setup-profiel-info homepageseller__section-with-text'>
          <div>
            <h2 className='homepageseller__h2'>Setup Profile</h2>
          </div>

          <p className='mt-30 homepageseller__copy homepagebuyer__virtual-events-info-copy'>
            Create your profile in less than <span>5 minutes</span>, set your ICP filters and start getting leads.
          </p>

          <p className='mt-30'>
            <button className='homepageseller__btn'>Get Started</button>
          </p>
        </div>

        <div className='homepageseller__setup-profile-image'>
          <div>
            <img src={leadcard} alt='' />
          </div>
        </div>
      </div>

      <div className='homepageseller__get-matched homepageseller__section'>
        <div className='homepageseller__get-matched-info homepageseller__section-with-text'>
          <div>
            <h2 className='homepageseller__h2'>Get Matched</h2>
          </div>

          <p className='mt-30 homepageseller__copy homepagebuyer__virtual-events-info-copy'>
            Our members work at the top organizations in the world. Get matched with <span>50k+ enterprise executives.</span>
          </p>

          <p className='mt-30'>
            <button className='homepageseller__btn'>Get Started</button>
          </p>
        </div>

        <div className='homepageseller__setup-profile-image'>
          <div>
            <img src={memberlogos} alt='' style={{boxShadow: 'none'}}/>
          </div>
        </div>
      </div>

      <div className='homepageseller__download-leads homepageseller__section'>
        <div className='homepageseller__download-leads-info homepageseller__section-with-text'>
          <div>
            <h2 className='homepageseller__h2'>Download Leads</h2>
          </div>

          <p className='mt-30 homepageseller__copy homepagebuyer__virtual-events-info-copy'>
            View and download person-level leads in <span>minutes.</span>
          </p>

          <p className='mt-30'>
            <button className='homepageseller__btn'>Get Leads</button>
          </p>
        </div>

        <div className='homepageseller__setup-profile-image'>
          <div>
            <img src={csv} alt='' />
          </div>
        </div>
      </div>

      <div className='homepagebuyer__executive-members'>
        <div className='homepagebuyer__executive-members-info'>
            <h2 className='homepagebuyer__h2'>Swipe</h2>

            <p className='homepagebuyer__copy mt-30'>
              Tinder for Sales, LeadrPro Launches AI-Powered Sales Dating App to Match Businesses with Customers
            </p>

            <div className='mt-30'>
              <button className='homepagebuyer__executive-members-btn'>Download Now</button>
            </div>
        </div>

        <div className='homepagebuyer__executive-members-image'>
          <img src={mobileapp} alt='' />
        </div>
      </div>

      <div className='homepagebuyer__executive-events'>
        <div className='flex' style={{alignItems: 'center'}}>
          <div className='homepagebuyer__executive-events-photos'>
            <img src={executive_events} alt='' />
          </div>

          <div className='homepagebuyer__executive-events-info'>
            <h2 className='homepageseller__h2 ta-r'>Executive Events</h2>

            <p className='mt-30 homepageseller__copy ta-r'>
              Gain access to in person experiences and build personal connections with <span>Industry Leaders and Executives</span>.
            </p>

            <p className='mt-30 ta-r'>
              <button className='homepagebuyer__btn'>Get access</button>
            </p>
          </div>
        </div>

        <div className='mt-30'>
          <div className="center eventhome__sponsors-label">Brought to you by</div>

          <div className='homepagebuyer__sponsors-list'>
            <div><img src={sponsors} /></div>
          </div>
        </div>
      </div>

      <div className='homepagebuyer__virtual-events homepagebuyer__section'>
        <div className='homepagebuyer__virtual-events-info'>
          <div>
            <h2 className='homepagebuyer__h2 flex'><img src={badge1} alt='' style={{height: '80px'}} /><span>Virtual Events</span></h2>
          </div>

          <p className='mt-30 homepagebuyer__copy homepagebuyer__virtual-events-info-copy'>
            These educational segments focus on addressing hot button issues by bringing you an all-star panel of curated experts and <span style={{color: '#480dbe'}}>Thought Leadrs</span>.
          </p>

          <p className='mt-30'>
            <button className='homepagebuyer__btn' onClick={() => navigate('/signup')}>Join now</button>
          </p>
        </div>

        <div className='homepagebuyer__virtual-events-items'>
          <div className='homepagebuyer__virtual-events-list'>
            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual1} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Andy Paul
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual2} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Jared Robin
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual3} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Todd Dewett, PhD
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual4} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Christopher Foulon
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual5} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Anthony Iannarino
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual6} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Chris Dyer
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual7} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                Chris Orlob
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual8} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                John Barrows
              </div>
            </div>

            <div className='homepagebuyer__virtual-events-item'>
              <div className='homepagebuyer__virtual-events-item-image'>
                <img src={virtual9} />
              </div>

              <div className='homepagebuyer__virtual-events-item-name'>
                David Edelman
              </div>
            </div>
          </div>

          <div>
            <span className='link' onClick={() => navigate('/webinars')}>View All</span>
          </div>
        </div>
      </div>

      <div className="eventhome__testimonial">
          <div className="eventhome__testimonial-left">
            <div className="eventhome__testimonial-left-quote">
              “Such an awesome platform to help stay on top of the latest and greatest. I've been a member for over 2 years.”
            </div>

            <div className="eventhome__testimonial-left-quote-name">
              Bradley Schaufenbuel, VP and Chief Information Security Officer at Paychex
            </div>
          </div>

          <div className="eventhome__testimonial-right">
            <div className="eventhome__testimonial-image">
              <img src='https://ik.imagekit.io/ivgrhmd4k/bradley.jpg?updatedAt=1715805813403' />
            </div>
          </div>
        </div>

        <div className='homepage__media-logos'>
          <img src={medialogos} />
        </div>
    </div>
  );
}

export default HomepageSeller;