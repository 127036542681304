import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminActiveSellers } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV } from 'react-icons/fa';

function ActiveSellers() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await adminActiveSellers(new Date().getMonth(), new Date().getFullYear());

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function loadData(month, year){
    setLoaded(false);
    let res = await adminActiveSellers(month, year);

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  async function handleMonthChange(val){
    loadData(new Date(val).getMonth(), new Date(val).getFullYear);
  }

  return (
    <div>
      <Helmet>
        <title>Admin - Active Sellers</title>
      </Helmet>

      <h1>Active Sellers {`(${data.length})`}</h1>

      <div className='admindashboard__top-filters'>
        <select className='input select' onChange={(e) => handleMonthChange(e.target.value)}>
        <option value='9/1/2024'>September 2024</option>
          <option value='8/1/2024'>August 2024</option>
          <option value='7/1/2024'>July 2024</option>
          <option value='6/1/2024'>June 2024</option>
          <option value='5/1/2024'>May 2024</option>
          <option value='4/1/2024'>April 2024</option>
          <option value='3/1/2024'>March 2024</option>
          <option value='2/1/2024'>February 2024</option>
          <option value='1/1/2024'>January 2024</option>

        </select>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && data.length == 0 && (
        <div className="center mt-20">
          No records found...
        </div>
      )}

      {loaded && (
         <div className='admindashboard__activesellers-list'>
          {data.sort((a, b) => parseInt(b.demos_approved) - parseInt(a.demos_approved)).map(item => (
            <div className='admindashboard__activesellers-list-item admindashboard__card'>
              <div className='admindashboard__activesellers-list-item-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
              </div>

              <div className='admindashboard__activesellers-list-item-name'>
                {item.company_id} - {item.name}
              </div>

              <div className='admindashboard__activesellers-list-item-counts'>
                <div>
                  Applications: {item.demo_applications}
                </div> 

                <span>Approved: {item.demos_approved}</span>
                <br/>
                <span>Completed: {item.demos_completed}</span>

                <div>
                  Declined: {item.demos_declined}
                </div>
              </div>
            </div>
          ))}
       </div>
      )}
    </div>
  );
}

export default ActiveSellers;