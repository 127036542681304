import React, { useState, useEffect } from 'react';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { declineDemoSeller, addSellerDeclineReason, getSellerDeclinedReasons} from '../../utils/api';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { FaPenAlt, FaRegEdit, FaRegSave, FaRegStickyNote, FaTimes } from 'react-icons/fa';

const SellerDecline = ({ setShowDeclinedPopup, currentDemo, demos, setDemos, source, setShowReschedule}) => {
  const [declineReason, setDeclineReason] = useState('');
  const [selectedDeclineReason, setSelectedDeclineReason] = useState(null);
  const [showAreYouSure, setShowAreYouSure] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);

  useEffect(() => {
    async function loadSellerDeclinedReasons() {
      let data = await getSellerDeclinedReasons();
      if (data.success) {
        setSellerDeclinedReasons(data.data);
      }
    }

    loadSellerDeclinedReasons();
  }, []);

  async function notificationClosingClick() {
    setShowNotification(false);
    setShowDeclinedPopup(false);
  }

  async function handleSubmit(event, id) {
    event.preventDefault();

    if (selectedDeclineReason === null){
      alert("Please select a reason");
      return;
    }

    setIsProcessing(true);
    
      let res = await declineDemoSeller(id, source);

      if (res.success){
        await addSellerDeclineReason(id, selectedDeclineReason, declineReason);
        setShowAreYouSure(!showAreYouSure);
        setShowNotification(true);
        currentDemo.demo_status = 'Declined';
        currentDemo.seller_status = 'Declined';
      }

      else if (res.status === 403){
        await refresh();

        let res = await declineDemoSeller(id, source);

        if (res.success){
          await addSellerDeclineReason(id, selectedDeclineReason, declineReason);
          setShowAreYouSure(!showAreYouSure);
          setShowNotification(true);
          currentDemo.demo_status = 'Declined';
          currentDemo.seller_status = 'Declined';
        }
    }

    let _filtered = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
    setDemos(_filtered);

    await notificationClosingClick();
  }

  return (
    <>
    <FadeIn delay={120} duration={200}>
      <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowDeclinedPopup(false)}>
            <FaTimes />
          </div>

          {source === 'Decline' && (
            <div className='modal__h2'>
              Not interested
            </div>
          )}

          {source === 'Cancel' && (
            <h2 className='modal__h2'>
              Cancel this demo
            </h2>
          )}
          
          <form onSubmit={(e) => handleSubmit(e, currentDemo.demo_id)}>
            <div className='form-group'>
              <label className='label input-label'><FaRegEdit /> Reason</label>
              <select
                className='input select'
                onChange={(e) => setSelectedDeclineReason(e.target.value)}
                name='choose reason'>
                <option value='0'>Select a reason</option>
                  <>
                    {sellerDeclinedReasons.length > 0 &&
                      sellerDeclinedReasons.map((item, index) => (
                        <option key={index} value={item.decline_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                  </>
              </select>
            </div>

            <div className='form-group'>
              <label className='label input-label'>
                <FaRegStickyNote /> Team Note
              </label>

              <textarea
                autoComplete='off'
                value={declineReason}
                onChange={(event) => setDeclineReason(event.target.value)}
                className='input_box textarea'
                name='Objection Reason'
                placeholder='Enter note'
              />
            </div>

            <div className='center mt-10'>
              {isProcessing === true && (
                <Spinner />
              )}

              {isProcessing === false && source === 'Cancel' && (
                <button className='btn btn--danger'>Confirm Cancel</button>
              )}

              {isProcessing === false && source !== 'Cancel' && (
                <button className='btn'>Decline</button>
              )}
            </div>
          </form>
        </div>

      {showNotification && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={notificationClosingClick}>
              <FaTimes />
            </div>

              <>
                <h2 className='modal__h2'>
                  You have declined a demo with <span className='dashboarddemo__highlight'>
                      {currentDemo.company_name}!
                    </span>
                </h2>

                <div className='modal__body'>
                  We will use this information to improve your future matches.
                </div>

                  <div className='center'>
                    <button className='btn' onClick={() => notificationClosingClick()}>Close</button>
                  </div>
              </>

          </div>
        </>
      )}
      </FadeIn>
    </>
  );
};

export default SellerDecline;