import './LeadFormSubmissions.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { getUserWaitlist,removeFromWaitlist, getLeadFormSubmissions} from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaRegBell, FaTimes } from 'react-icons/fa';
import herocards from '../../assets/design/homepagehub/software/herocards.png';

function LeadFormSubmissions() {
  const [loaded, setLoaded] = useState(false);
  const [waitlist, setWaitlist] = useState([]);
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  async function loadData(){
    let res = await getLeadFormSubmissions();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>LeadrPro - Manage alerts</title>
      </Helmet>

      <div className='leadformsubmissions'>
      
      <div className='priceleads__header'>
                <Link to='/dashboard/price-leads'>
                <div><svg style={{height: '40px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.4 96L144 96l-36.2 0L96 96l0 8.8L96 144l0 40.4 0 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3 48 96c0-26.5 21.5-48 48-48l76.6 0 49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48 416 48c26.5 0 48 21.5 48 48l0 44.3 22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4l0-89 0-40.4 0-39.2 0-8.8-11.8 0L368 96l-71.4 0-81.3 0zM0 448L0 242.1 217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1 512 448s0 0 0 0c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64c0 0 0 0 0 0zM176 160l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg></div>
                <strong>You have a new update regarding your recent request!</strong>
                </Link>
              </div>

              <div className='mt-10'>
                <h1>Your Past Requests</h1>
              </div>

              <div className='priceleads__cards'>
                <div className='priceleads__card'>
                  <div>
                    <i>Reference #: DLKJF2LJ3</i>
                  </div>

                  <div className='form-group priceleads__card-job-title'>
                    Senior Project Manager working in Aerospace
                  </div>

                  <div className='form-group'>
                    <strong>Date Created</strong>
                    <div>
                      Tuesday October 22, 2024
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for</strong>

                    <div>
                      We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                {showDetails == false && (
                  <div className='center'>
                    <button className='btn btn--confirm' onClick={() => navigate('/lead-recs')}>View Recommendations</button>
                  </div>
                )}

                {showDetails && (
                <div className='priceleads__hidden-section'>
                  <div className='form-group'>
                    <strong>Company size</strong>
                    <div>100-250</div>
                  </div>

                  <div className='form-group'>
                    <strong>Number of users</strong>
                    <div>20+</div>
                  </div>

                  <div className='form-group'>
                    <strong>Annual revenue</strong>
                    <div>$10M+</div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for key features</strong>
                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Price expectations</strong>
                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Timeframe</strong>

                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Contact details</strong>

                    <p>
                      Email and phone number available.
                    </p>

                    <div className='priceleads__btns'>
                      <button className='btn btn--primary' onClick={() => setShowPurchase(true)}>Purchase</button>
                    </div>
                  </div>
                  </div>
                )}
                </div>

                <div className='priceleads__card'>
                  <div>
                    <i>Reference #: DLKJF2LJ3</i>
                  </div>

                  <div className='form-group priceleads__card-job-title'>
                    Senior Project Manager working in Aerospace
                  </div>

                  <div className='form-group'>
                    <strong>Date Created</strong>
                    <div>
                      Tuesday October 22, 2024
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for</strong>

                    <div>
                      We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                {showDetails == false && (
                  <div className='center'>
                    <button className='btn btn--confirm' onClick={() => navigate('/price-recs')}>View Recommendations</button>
                  </div>
                )}

                {showDetails && (
                <div className='priceleads__hidden-section'>
                  <div className='form-group'>
                    <strong>Company size</strong>
                    <div>100-250</div>
                  </div>

                  <div className='form-group'>
                    <strong>Number of users</strong>
                    <div>20+</div>
                  </div>

                  <div className='form-group'>
                    <strong>Annual revenue</strong>
                    <div>$10M+</div>
                  </div>

                  <div className='form-group'>
                    <strong>Looking for key features</strong>
                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Price expectations</strong>
                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Timeframe</strong>

                    <div>
                    We've helped 1000's of business professionals search for their next big software solution 1. Tell us what you need 2. Get 3-4 recommendations catered to you and your company 3. Let us broker the demos for you and save you time
                    </div>
                  </div>

                  <div className='form-group'>
                    <strong>Contact details</strong>

                    <p>
                      Email and phone number available.
                    </p>

                    <div className='priceleads__btns'>
                      <button className='btn btn--primary' onClick={() => setShowPurchase(true)}>Purchase</button>
                    </div>
                  </div>
                  </div>
                )}
                </div>
              </div>

        {/* <div className='leadformsubmissions__items'>
          {data.map(item => (
            <div className='leadformsubmissions__item'>
              {item.reference_id}
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
}

export default LeadFormSubmissions;