import { refresh } from "../../services/auth.service";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3003';
/**
 * Defines the default headers for these functions to work with `json-server`
 */
const headers = new Headers();
headers.append('Content-Type', 'application/json');

//et {logger} = require('./winstonLogger');
/**
 * Fetch `json` from the specified URL and handle error status codes and ignore `AbortError`s
 *
 * This function is NOT exported because it is not needed outside of this file.
 *
 * @param url
 *  the url for the requst.
 * @param options
 *  any options for fetch
 * @param onCancel
 *  value to return if fetch call is aborted. Default value is undefined.
 * @returns {Promise<Error|any>}
 *  a promise that resolves to the `json` data or an error.
 *  If the response is not in the 200 - 399 range the promise is rejected.
 */

export async function createGET(endpoint, is_authed = true){
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem('access_token');

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createGETWithRetry(endpoint){
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem('access_token');
  let options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url, options);

  if (response.status === 403){
    await refresh();
    let access_token = localStorage.getItem('access_token');
    options.headers.Authorization = 'Bearer ' + access_token;

    const response = await fetch(url, options);
    if (response.status === 403) return { success: false, status: 403 };
      return response.json();
  }

  return response.json();
}

export async function createPOST(endpoint, data){
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem('access_token');

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 403) return { success: false, status: 403 };
    return response.json();
  });

  return response;
}

export async function createPOSTWithRetry(endpoint, data){
  const url = new URL(`${API_BASE_URL}/${endpoint}`);
  let access_token = localStorage.getItem('access_token');
  let options = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: {
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json'
    }
  };

  const response = await fetch(url, options);

  if (response.status === 403){
    await refresh();
    let access_token = localStorage.getItem('access_token');
    options.headers.Authorization = 'Bearer ' + access_token;

    const response = await fetch(url, options);
    if (response.status === 403) return { success: false, status: 403 };
      return response.json();
  }

  return response.json();
}

export async function AdminDemosBookedThroughEvent(){
  const response = await createGETWithRetry('admin/demos-booked-through-event');
  return response;
}

export async function AdminDemosBookedThroughSellers(){
  const response = await createGETWithRetry('admin/demos-booked-through-sellers');
  return response;
}

export async function AdminDemosBookedBy30(){
  const response = await createGETWithRetry('admin/demos-booked-by-30');
  return response;
}

export async function AdminDemosBookedBy60(){
  const response = await createGETWithRetry('admin/demos-booked-by-60');
  return response;
}

export async function AdminDemosBookedBy90(){
  const response = await createGETWithRetry('admin/demos-booked-by-90');
  return response;
}

export async function getIndexableEvents(){
  const response = await createGETWithRetry('admin/indexable-events');
  return response;
}

export async function getPayNowSellers(){
  const response = await createGETWithRetry('admin/pay-now-sellers');
  return response;
}

export async function getAdminDemosPending(){
  const response = await createGETWithRetry('admin/demos/pending');
  return response;
}

export async function getAdminDemosUpcoming(){
  const response = await createGETWithRetry('admin/demos/upcoming');
  return response;
}

export async function getCompaniesWithAddons(){
  const response = await createGETWithRetry('admin/companies-with-addons');
  return response;
}

export async function getDemoRequestLogs(){
  const response = await createGETWithRetry('admin/demo-request-logs');
  return response;
}

export async function AdminDashboardBreakdown(){
  const response = await createGETWithRetry('admin/dashboard-breakdown');
  return response;
}

export async function AdminUserCountBreakdown(){
  const response = await createGETWithRetry('admin/user-breakdown');
  return response;
}

export async function AdminFeedbackMessages(){
  const response = await createGETWithRetry('admin/feedback-messages');
  return response;
}

export async function AdminCompanyWaitlist(){
  const response = await createGETWithRetry('admin/company-waitlist');
  return response;
}

export async function AdminContactMessages(){
  const response = await createGETWithRetry('admin/contact-messages');
  return response;
}

export async function getIndexableSellers(){
  const response = await createGETWithRetry('admin/indexable-sellers');
  return response;
}

export async function getMonthlyReport(){
  const response = await createGETWithRetry('admin/monthly-report');
  return response;
}

export async function getSearchQueries(){
  const response = await createGETWithRetry('admin/search-queries');
  return response;
}

export async function getAppUsers(){
  const response = await createGETWithRetry('admin/app-users');
  return response;
}

export async function getCompaniesThatNeedRequests(company_id){
  const response = await createGETWithRetry('admin/companies-that-need-requests');
  return response;
}

export async function getCategoriesWithCompanies(){
  const response = await createGETWithRetry('admin/categories-with-companies');
  return response;
}

export async function getCompanyClicks(){
  const response = await createGETWithRetry('admin/company-clicks');
  return response;
}

export async function getCompanyClickBreakdown(){
  const response = await createGETWithRetry('admin/company-click-breakdown');
  return response;
}

export async function getPayNowCompanies(){
  const response = await createGETWithRetry('admin/pay-now-companies');
  return response;
}

export async function getCompanySaves() {
  const response = await createGETWithRetry('admin/company-saves');
  return response;
}

export async function getMailOutboxCampaigns() {
  const response = await createGETWithRetry('admin/mail-outbox-campaigns');
  return response;
}

export async function getSellerUsers() {
  const response = await createGETWithRetry('admin/seller-users');
  return response;
}

export async function getUsersByCompanySizeTable() {
  const response = await createGETWithRetry('admin/user-by-company-size');
  return response;
}

export async function getLatestBuyers() {
  const response = await createGETWithRetry('admin/latest-buyers');
  return response;
}

export async function getLatestDemos() {
  const response = await createGETWithRetry('admin/latest-demos');
  return response;
}

export async function getLatestEventUsers() {
  const response = await createGETWithRetry('admin/latest-event-users');
  return response;
}

export async function getCompanyCredits() {
  const response = await createGETWithRetry('admin/company-credits');
  return response;
}

export async function getPointActivity() {
  const response = await createGETWithRetry('admin/point-activity');
  return response;
}

export async function getPointRewards() {
  const response = await createGETWithRetry('admin/point-rewards');
  return response;
}

export async function getPointUserActivity() {
  const response = await createGETWithRetry('admin/point-user-activity');
  return response;
}

export async function getPointUserRewards() {
  const response = await createGETWithRetry('admin/point-user-rewards');
  return response;
}

export async function getPointTransactions() {
  const response = await createGETWithRetry('admin/point-transactions');
  return response;
}

export async function getEventBreakdown() {
  const response = await createGETWithRetry('admin/event-breakdown');
  return response;
}

export async function getActiveBuyers() {
  const response = await createGETWithRetry('admin/active-buyers');
  return response;
}

export async function getEventsList() {
  const response = await createGETWithRetry('admin/get-events-list');
  return response;
}

export async function getProFeatures() {
  const response = await createGETWithRetry('admin/pro-features');
  return response;
}

export async function getProFeatureCategories() {
  const response = await createGETWithRetry('admin/pro-feature-categories');
  return response;
}

export async function getLeadFormSubmissions() {
  const response = await createGETWithRetry('admin/lead-form-submissions');
  return response;
}

export async function getLeadFormCards() {
  const response = await createGETWithRetry('admin/lead-form-cards');
  return response;
}

export async function getLeadFormTypes() {
  const response = await createGETWithRetry('admin/lead-form-types');
  return response;
}
// -------------------------------------------------------------------------

export async function AdminAddFeedbackNote(feedback_id, admin_note){
  let data = {feedback_id, admin_note};
  const response = await createPOSTWithRetry('admin/add-feedback-note', data);
  return response;
}

export async function AdminUpdateFeedbackFormStatus(feedback_id, status){
  let data = {feedback_id, status};
  const response = await createPOSTWithRetry('admin/feedback-status-update', data);
  return response;
}

export async function AdminAddContactFormNote(contact_form_id, admin_note){
  let data = {contact_form_id, admin_note};
  const response = await createPOSTWithRetry('admin/feedback-status-update', data);
  return response;
}

export async function AdminUpdateContactFormStatus(contact_form_id, status){
  let data = {contact_form_id, status};
  const response = await createPOSTWithRetry('admin/contact-form-update', data);
  return response;
}

export async function adminActiveSellers(month, year){
  let data = {month, year};
  const response = await createPOSTWithRetry('admin/active-sellers', data);
  return response;
}

export async function createCompanyDemoRequests(company_id){
  let data = {company_id};
  const response = await createPOSTWithRetry('admin/create-demo-requests', data);
  return response;
}

export async function adminPayouts(count){
  let data = {count};
  const response = await createPOSTWithRetry('admin/user-payout-requests', data);
  return response;
}

export async function updatePayoutStatus(reference_id, user_payout_request_id){
  let data = {reference_id, user_payout_request_id };
  const response = await createPOSTWithRetry('admin/update-payout-status', data);
  return response;
}

export async function companyIndexLog(company_id){
  let data = {company_id};
  const response = await createPOSTWithRetry('admin/company-index-log', data);
  return response;
}

export async function updateCategory(id, data){
  let payload = {id, data};
  const response = await createPOSTWithRetry('admin/update-category', payload);
  return response;
}

export async function indexCategory(id){
  let payload = {id};
  const response = await createPOSTWithRetry('admin/index-category', payload);
  return response;
}

export async function searchDemos(query, id){
  let payload = {query, id};
  const response = await createPOSTWithRetry('admin/search-demos', payload);
  return response;
}

export async function getMonthlyReportByMonth(month, year){
  let payload = {month, year};
  const response = await createPOSTWithRetry('admin/monthly-report-by-month', payload);
  return response;
}

export async function deleteUser(registered_user_id){
  let payload = {registered_user_id};
  const response = await createPOSTWithRetry('admin/delete-user', payload);
  return response;
}

export async function setAsTestUser(registered_user_id){
  let payload = {registered_user_id};
  const response = await createPOSTWithRetry('admin/set-as-test', payload);
  return response;
}

export async function markProfileAsReviewed(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/profile-reviewed", data);
  return response;
}

export async function sendInitialCampaign(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/send-initial-campaign", data);
  return response;
}

export async function sendInitialCampaignTest(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/send-initial-campaign-test", data);
  return response;
}

export async function skipInitialCampaign(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/skip-initial-campaign", data);
  return response;
}

export async function markCompanyAsPublished(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/company-is-published", data);
  return response;
}

export async function sendNextStepsEmail(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/send-next-steps-email", data);
  return response;
}

export async function sendNextStepsEmailAlt(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/send-next-steps-email-alt", data);
  return response;
}

export async function skipNextStepsEmail(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/skip-next-steps-email", data);
  return response;
}

export async function confirmMeetingScheduled(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/confirm-meeting-scheduled", data);
  return response;
}

export async function getMailOutboxEmais(mail_outbox_campaign_id) {
  let data = { mail_outbox_campaign_id };
  const response = await createPOSTWithRetry("admin/mail-outbox-emails", data);
  return response;
}

export async function getCompaniesByDataset(dataset_id) {
  let data = { dataset_id };
  const response = await createPOSTWithRetry("admin/companies-by-dataset", data);
  return response;
}

export async function getAllMailOutboxEmails(source_id) {
  let data = { source_id };
  const response = await createPOSTWithRetry("admin/all-mail-outbox-emails", data);
  return response;
}

export async function lookupDemos(company_id) {
  let data = { company_id };
  const response = await createPOSTWithRetry("admin/lookup-demos", data);
  return response;
}

export async function getAdminLatestUsers(count) {
  let data = { count };
  const response = await createPOSTWithRetry("admin/latest-users", data);
  return response;
}

export async function getLatestBuyersByMonth(start_date) {
  let data = {start_date};
  const response = await createPOSTWithRetry('admin/latest-buyers-by-month', data);
  return response;
}

export async function getLatestBuyersByDate(start_date, end_date) {
  let data = {start_date, end_date};
  const response = await createPOSTWithRetry('admin/latest-buyers-by-date', data);
  return response;
}

export async function createSellerRequestCampaign(company_id, subject, quantity, campaign_id, single_email) {
  let data = {company_id, subject, quantity, campaign_id, single_email};
  const response = await createPOSTWithRetry('admin/create-seller-request-campaign', data);
  return response;
}

export async function adminSearchCompany(query, id) {
  let data = {query, id};
  const response = await createPOSTWithRetry('admin/company/search', data);
  return response;

  // const url = new URL(`${API_BASE_URL}/admin/company/search`);
  // let access_token = localStorage.getItem("access_token");

  // const response = await fetch(url, {
  //   method: "POST",
  //   mode: "cors",
  //   cache: "no-cache",
  //   credentials: "same-origin",
  //   body: JSON.stringify({ query, id }),
  //   headers: {
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => {
  //   if (response.status === 403) return { success: false, status: 403 };
  //   return response.json();
  // });

  // return response;
}

export async function getDemosByDate(start_date) {
  let data = {start_date};
  const response = await createPOSTWithRetry('admin/demos-by-month', data);
  return response;
}

export async function createPointActivity(name, description, points_earned) {
  let data = {name, description, points_earned};
  const response = await createPOSTWithRetry('admin/create-point-activity', data);
  return response;
}

export async function createPointReward(name, description, point_cost, quantity, type, order_id, dollar_value, image_url) {
  let data = {name, description, point_cost, quantity, type, order_id, dollar_value, image_url};
  const response = await createPOSTWithRetry('admin/create-point-reward', data);
  return response;
}

export async function sendEventUserRewardCampaign(version) {
  let data = {version};
  const response = await createPOSTWithRetry('admin/send-event-user-reward-campaign', data);
  return response;
}

export async function addCompanyCategory(company_id, category) {
  let data = {company_id, category};
  const response = await createPOSTWithRetry('admin/company-category', data);
  return response;
}

export async function deletePointReward(point_reward_id) {
  let data = { point_reward_id };
  const response = await createPOSTWithRetry('admin/delete-point-reward', data);
  return response;
}

export async function updatePointActivity(point_activity_id, name, description, points_earned) {
  let data = { point_activity_id, name, description, points_earned };
  const response = await createPOSTWithRetry('admin/update-point-activity', data);
  return response;
}

export async function updatePointUserRewardStatus(point_user_reward_id) {
  let data = { point_user_reward_id };
  const response = await createPOSTWithRetry('admin/update-point-user-reward-status', data);
  return response;
}

export async function getEventById(event_id) {
  let data = { event_id };
  const response = await createPOSTWithRetry('admin/event-by-id', data);
  return response;
}

export async function updateProFeature(feature_id, name, description, icon, feature_category_id) {
  let data = { feature_id, name, description, icon, feature_category_id };
  const response = await createPOSTWithRetry('admin/update-pro-feature', data);
  return response;
}

export async function addProFeature(name, description, icon, feature_category_id) {
  let data = { name, description, icon, feature_category_id };
  const response = await createPOSTWithRetry('admin/add-pro-feature', data);
  return response;
}

export async function updateLeadFormNotes(lead_form_submission_id, admin_notes, status, number_of_users, annual_revenue, key_features, price_expectations, timeframe, first_name, last_name, job_title, headline, lead_form_submission_type_id, contact_email, contact_phone, company_size, time_to_call, company_name) {
  let data = { lead_form_submission_id, admin_notes, status, number_of_users, annual_revenue, key_features, price_expectations, timeframe, first_name, last_name, job_title, headline, lead_form_submission_type_id, contact_email, contact_phone, company_size, time_to_call, company_name };
  const response = await createPOSTWithRetry('admin/lead-form-notes', data);
  return response;
}

export async function createLeadCards(lead_form_submission_id, companies) {
  let data = { lead_form_submission_id, companies };
  const response = await createPOSTWithRetry('admin/company-lead-cards', data);
  return response;
}

export async function getLeadFormSubmission(lead_form_submission_id) {
  let data = { lead_form_submission_id };
  const response = await createPOSTWithRetry('admin/lead-form-submission-by-id', data);
  return response;
}