import './BuyerReschedule.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import BuyerFeedback from '../../components/feedback/BuyerFeedback';
import SellerFeedback from '../../components/feedback/SellerFeedback';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, refreshToken } from '../../utils/api';
import RescheduleTimes from "../../components/qualifier/RescheduleTimes";

function BuyerReschedule() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [isBuyer, setIsBuyer] = useState(false);
    const [demo, setDemo] = useState({});
    const [valid, setValid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState([]);

    useEffect(() => {
        async function loadDemo(){
            const id = new URLSearchParams(search).get('refid');
            setReferenceId(id);
      
            let response = await getBuyerDemoFromRef(id);
      
            if (response.success){
              if (response.data.length > 0){
                setDemo(response.data[0]);
                console.log('demo data', response.data[0]);
                setValid(true);

                let user = await getCurrentUser();

                if (user.registered_user_id === response.data[0].buyer_id)
                {
                    setIsBuyer(true);
                }

                else{
                    setIsBuyer(false);
                }
              }
      
              else{
                setValid(false);
              }
            }

            else if (response.status === 403){
              let _refresh = await refreshToken();

              if (_refresh.success) {
                localStorage.setItem('access_token', _refresh.data.accessToken);
                localStorage.setItem('refresh_token', _refresh.data.refreshToken);
      
                let response = await getBuyerDemoFromRef(id);
      
                if (response.success){
                  if (response.data.length > 0){
                    setDemo(response.data[0]);
                    console.log('demo data', response.data[0]);
                    setValid(true);

                    let user = await getCurrentUser();

                    if (user.registered_user_id === response.data[0].buyer_id)
                    {
                        setIsBuyer(true);
                    }

                    else{
                        setIsBuyer(false);
                    }
                  }
          
                  else{
                    setValid(false);
                  }
                }
                //setCookie('access_token', _refresh.data.accessToken, 1); // 12.19.2022
              }
            }
      
            else{
              setValid(false);
            }
      
            setLoaded(true);
            console.log(id);
          }
      
          loadDemo();
    }, []);

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
          <title>LeadrPro - Reschedule your demo</title>
        </Helmet>

        {loaded && isBuyer && (
          <div className="buyerreschedule">
             <RescheduleTimes
             demo={demo}
             demos={[]}
             setDemos={setDemos}
             demo_id={demo.demo_id}
             company_name={demo.company_name}
             seller_id={demo.seller_id}
             showSteps={true}
             setShowReschedule={true}
             enableReason={false}
           />
           </div>
        )}
        </div>
    );
}

export default BuyerReschedule;