import { useState, useEffect } from "react";
import './EventDetails.css';
import { getEvent, eventRegistration, addEventAnswer, getEventPostsForPublic, createUserEventInstant, addEventAnswerInstant } from '../../utils/api';
import {
  isAuthed,
  refresh
} from '../../services/auth.service';
import { useNavigate, useParams, Link, Navigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import Spinner from "../../components/layout/Spinner";
import apple from '../../assets/design/app/appleStoreBtn.png';
import googlePlay from '../../assets/design/app/googleBtn.png';
import moment from 'moment-timezone';
import { FaUserCheck, FaRegClock, FaLink, FaCaretLeft, FaArrowLeft, FaMap, FaSlidersH, FaCheckCircle, FaExclamation } from "react-icons/fa";
import { FaCalendarAlt, FaMapMarkerAlt, FaTimes } from "react-icons/fa";
import { getSeoName, checkSession } from "../../utils/utils";
import imgCoin from '../../assets/rewards/lp-coin.png';

function EventDetails() {
    const [event, setEvent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [submittingAnswers, setSubmittingAnswers] = useState(false);
    const [showQuestions, setShowQuestions] = useState(false);
    const [posts, setPosts] = useState([]);
    const [postsLoaded, setPostsLoaded] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [registerName, setRegisterName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    let { id } = useParams();
    const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
    const navigate = useNavigate();

    async function loadEvent(){
      let res = await getEvent(id);

      if (res.success){
        setEvent(res.data);
        setLoading(false);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getEvent(id);

        if (res.success){
          setEvent(res.data);
          setLoading(false);
        }
      }
    }

    useEffect(() => {
      async function loadEvent(){
        let res = await getEvent(id);

        if (res.success){
          setEvent(res.data);
          setLoading(false);
        }

        else if (res.status === 403){
          await refresh();

          let res = await getEvent(id);

          if (res.success){
            setEvent(res.data);
            setLoading(false);
          }
        }
      }

      async function loadPosts(){
        let res = await getEventPostsForPublic(id);
  
        if (res.success){
          setPosts(res.data);
          setPostsLoaded(true);
        }
  
        else if (res.status == 403){
          await refresh();
  
          let res = await getEventPostsForPublic(id);
  
          if (res.success){
            setPosts(res.data);
            setPostsLoaded(true);
          }
        }
      }

      loadEvent();
      loadPosts();
      checkSession('Event Details');
    }, []);

    useEffect(() => {
      if (event){
        if (new Date(event.start_date) < new Date()){
          setIsExpired(true);
        }
      }
    }, [event]);

    async function handleRegister(){
      setSubmitting(true);
      let res = await eventRegistration(event.event_id);

      if (res.success){
        setSubmitting(false);
        await loadEvent();
        setShowConfirmPopup(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await eventRegistration(event.event_id);

      if (res.success){
        setSubmitting(false);
        await loadEvent();
        setShowConfirmPopup(true);

      }
      }
    }

    async function handleRegisterQuestions(){
      setShowQuestions(true);
    }

    // validate that all fields have answers specified
    async function checkAnswers(){
      let valid = true;

      for (let i = 0; i < event.questions.length; i++){
        let answer = document.getElementById('question_' + event.questions[i].event_question_id).value;

        if (answer.length === 0){
          valid = false;
          break;
        }
       // await addEventAnswer(event.questions[i].event_question_id, id, answer);
      }

      return valid;
    }

    async function submitAnswers(){
      let valid = await checkAnswers();

      if (valid === false){
        alert("Please answer all of the questions");
        return;
      }

      setSubmittingAnswers(true);

      let res = await eventRegistration(event.event_id);

      if (res.success){
        for (let i = 0; i < event.questions.length; i++){
          let answer = document.getElementById('question_' + event.questions[i].event_question_id).value;

          if (currentUserId == null){
            await addEventAnswer(event.questions[i].event_question_id, id, answer);
          }

          else{
            await addEventAnswerInstant(event.questions[i].event_question_id, id, answer, currentUserId);
          }
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await eventRegistration(event.event_id);

        if (res.success){
          for (let i = 0; i < event.questions.length; i++){
            let answer = document.getElementById('question_' + event.questions[i].event_question_id).value;
           
            if (currentUserId == null){
              await addEventAnswer(event.questions[i].event_question_id, id, answer);
            }
  
            else{
              await addEventAnswerInstant(event.questions[i].event_question_id, id, answer, currentUserId);
            }
          }
        }
      }

      setSubmittingAnswers(false);
      setShowQuestions(false);
      loadEvent();
    }

    async function submitAnswersInstant(){
      let valid = await checkAnswers();

      if (valid === false){
        alert("Please answer all of the questions");
        return;
      }

      setSubmittingAnswers(true);

        for (let i = 0; i < event.questions.length; i++){
          let answer = document.getElementById('question_' + event.questions[i].event_question_id).value;

          if (currentUserId == null){
            await addEventAnswer(event.questions[i].event_question_id, id, answer);
          }

          else{
            await addEventAnswerInstant(event.questions[i].event_question_id, id, answer, currentUserId);
          }
        }

      setSubmittingAnswers(false);
      setShowQuestions(false);
      setShowConfirmation(true);

      //loadEvent();
    }

    async function handleInstantRegister(reload = false){
      if (registerEmail.length === 0){
        alert("Please provide a valid email address");
        return;
      }
      
      setIsRegistering(true);

      let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let res = await createUserEventInstant({name: registerName, email:registerEmail, event_id: id, timezone: l_timezone});

      if (res.success){
        setIsRegistering(false);
        setCurrentUserId(res.id);

        if (event.requires_approval === true && event.enable_questions === true){
          setShowQuestions(true);
        }

        else{
          alert('sdf');
          setShowConfirmPopup(true);
          setShowConfirmation(true);
        }

        if (reload){
          window.location = window.location;
        }
      }
    }

    return (
        <div className="eventdetails">
          <ScrollToTop />

          <Helmet>
            {event && (
              <title>{`Attend ${event.name}`}</title>
            )}

            {event && event.meta_description && event.meta_description.length > 0 && (
              <meta name="description" content={event.meta_description} />
            )}

            <link rel="canonical" href={`https://www.leadrpro.com/event/${event.event_id}/${event.canonical_url_slug}`} />
          </Helmet>

          {loading && (
            <div className="center">
              <div className="eventdetails__placeholder"></div>
            </div>
          )}

          {event && loading === false && event.embed_url == null && (
            <>
              <div className="eventdetails__back-link">
                {event.is_webinar == false && (
                  <Link className="link" to='/events'>Back to events</Link>
                )}

                {event.is_webinar && (
                    <Link className="link" to='/webinars'>Back to events</Link>
                )}
              </div>
                
              <div className='eventdetails__info'>
                {event.is_host && (
                  <div style={{position: 'absolute', top: '5px', right: '5px'}}>
                    <Link className="btn" to={`/manage-event/${event.event_id}`}><FaSlidersH /></Link>
                  </div>
                )}

                <div className="eventdetails__info-image-wrapper">
                  {event.image_url && event.image_url.length > 0 && (
                    <div className="eventdetails__info-image"><img src={event.image_url} alt='' /></div>
                  )}

                  {(event.image_url == null || event.image_url.length === 0) && (
                    <div className="eventdetails__info-image">
                      <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" alt='' />
                      </div>
                  )}

                  {event.sponsor_id && (
                    <div className="mt-10 mb-10">
                      <button onClick={() => navigate(`/company/${event.sponsor_id}/apply`)} className="btn btn--cta btn--wide btn--full">Book a discovery call with {event.sponsor_company_name} and earn $75</button>
                    </div>
                  )}

                  {event.speakers && event.speakers.length > 0 && (
                    <div className="eventdetails__speakers">
                      <strong>Event speakers</strong>
                      {event.speakers.map((item, index) => (
                        <div key={index} className='eventdetails__speaker'>
                          <div className="eventdetails__speaker-name-wrapper">
                            <div className='eventdetails__speaker-picture'>
                              <img src={imageUrl + 'eventspeaker/tr:di-@@companylogos@@default-buyer.png/' + item.speaker_profile_picture} alt="" />
                            </div>

                            <div className="eventdetails__speaker-name">
                              {item.speaker_name}
                            </div>
                          </div>

                          <div className="eventdetails__speaker-bio">
                            {item.speaker_bio}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="eventdetails__info-description">
                    <h1 className="eventdetails__info-name">{event.name}</h1>

                    <p className="mt-20" dangerouslySetInnerHTML={{ __html: event.description.replace(/\n/g, '<br>') }}></p>
                  </div>
                </div>

                <div className="eventdetails__details">

                  {/* ----------------------------------------------------- */}
                  <div className="eventdetails__info-date-time">
                    <h3 style={{marginBottom: '10px'}}><strong>Event Details</strong></h3>

                  {event.start_date && (
                    <div className="events__list-item-date">
                      <FaCalendarAlt /> <strong>Event date:</strong> {new Date(event.start_date).toLocaleDateString()} {new Date(event.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )}

                  {event.end_date && (
                    <div className="events__list-item-date">
                      <FaCalendarAlt /> <strong>Ends:</strong> {new Date(event.end_date).toLocaleDateString()} {new Date(event.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )}

                  <div><FaRegClock /> <strong>Timezone:</strong> {moment.tz.guess()}</div>

                  {event.is_webinar == false && (
                    <div><FaMapMarkerAlt/> <strong>Location:</strong> {event.city} {event.state}</div>
                  )}

                  {event.is_webinar && event.is_registered === true && event.webinar_url && event.webinar_url.length > 0 && (
                    <div>
                      <FaLink /> <strong>Event URL:</strong> <span>{event.webinar_url}</span>
                    </div>
                  )}

                  {event.requires_approval && (
                    <div>
                      <span><FaExclamation /></span> <i>This event requires prior approval before attending.</i>
                    </div>
                  )}
                  </div>
                  {/* ----------------------------------------------------- */}

                  {/* -----register to see location --------------- */}
                  {event.is_registered == false && event.is_webinar == false && isExpired === false && (
                    <div style={{fontSize: '14px', marginTop: '10px'}}><i>Register to see the full address</i></div>
                  )}
                  {/* -----------------------------------------------------   */}

                  {isExpired && (
                    <div className="eventdetails__expired">
                      This event has already taken place.
                    </div>
                  )}

                    {/* ----- media embed ------------------------ */}
                    {event.embed_media === false && event.media_url && event.media_url.length > 0 && (
                        <div className='eventdetails__register' style={{marginTop: '10px'}}>
                          <h2><strong>Missed attending the event?</strong></h2>

                          <p>
                            You can still watch it here.
                          </p>

                          <a href={event.media_url} target="_blank" className="link">Watch the event</a>
                        </div>
                    )}
                    
                    {event.embed_media === true && event.media_url && event.media_url.length > 0 && (
                        <div className='eventdetails__register' style={{marginTop: '10px'}}>
                          <h2><strong>Missed attending the event?</strong></h2>

                          <p>
                            You can still watch it here.
                          </p>

                          <p>
                          <iframe id="ytplayer" type="text/html" width="100%" height="360"
  src={`${event.media_url}`}
  frameborder="0"></iframe>
                          </p>
                        </div>
                    )}
                    {/* ------------------------------- */}

                  {isExpired === true && isAuthed() && event.highlights && event.highlights.length > 0 && (
                    <div className="eventdetails__key-takeaways">
                      <h2>Event highlights</h2>

                      <div>
                      <p dangerouslySetInnerHTML={{ __html: event.highlights.replace(/\n/g, '<br>') }}></p>
                      </div>
                    </div>
                  )}

                  {isExpired === true && isAuthed() === false && event.highlights && event.highlights.length > 0 && (
                    <div className="eventdetails__key-takeaways">
                      <h2>Event highlights</h2>

                      <p>
                        <i>To read all of the highlights of the webinar and to register for future events, <Link className="link" to='/signup'>create your free account</Link>.</i>
                      </p>

                      {/* <div style={{marginTop: '20px'}}>
                        <div className="form-group">
                          <strong>Name</strong>
                          <input type='text' className="input" value={registerName} onChange={(e)=> setRegisterName(e.target.value)} placeholder="Enter name" />
                        </div>

                        <div className="form-group">
                          <strong>Email</strong>
                          <input type='text' className="input" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} placeholder="Enter email" />
                        </div>

                        <div className='form-group'>
                          {isRegistering === false && (
                          <button className="btn btn--primary eventdetails__btn-register" onClick={() => handleInstantRegister(true)}>Register for free</button>
                          )}

                          {isRegistering && (
                            <Spinner />
                          )}
                        </div>
                      </div> */}
                    </div>
                  )}

                  <div className="center" style={{marginTop: '20px'}}>
                  {submitting === false && isExpired === false && (
                    <>
                      {(event.is_registered || showConfirmation) && (
                        <div className='eventdetails__register'>
                        {event.requires_approval && event.is_approved && (
                          <div>
                            <div>
                            <strong><span style={{color: 'mediumseagreen'}}><FaCheckCircle /></span> You are registered! We look forward to seeing you there.</strong>
                            </div>

                          {event.is_webinar === false && (
                            <div className="eventdetails__address">
                              <div><strong>Event Address</strong></div>
                              <div>{event.address1} {event.address2}</div>
                              <div>{event.city} {event.state} {event.zipcode}</div>
                            </div>
                          )}

                          {event.is_webinar && event.webinar_url && event.webinar_url.length > 0 && (
                            <div className="eventdetails__address">
                              <div><strong>Webinar URL</strong></div>
                              <div>{event.webinar_url}</div>
                            </div>
                          )}

                            {event.venue_instructions && event.venue_instructions.length > 0 && (
                              <div className="eventdetails__address">
                                <div><strong>Venue Instructions</strong></div>
                                <div>{event.venue_instructions}</div>
                              </div>
                            )}
                          </div>
                        )}

                        {event.requires_approval && event.is_approved === false && (
                          <strong className="eventdetails__waiting-approval"><FaUserCheck /> Success. Waiting for approval.</strong>
                        )}

                        {event.requires_approval === false && (
                          <>
                          <div>
                            <strong><span style={{color: 'mediumseagreen'}}><FaCheckCircle /></span> You are registered! We look forward to seeing you there.</strong>
                            </div>

                          {event.is_webinar === false && (
                          <div className="eventdetails__address">
                              <div><strong>Event Address</strong></div>
                              <div>{event.address1} {event.address2}</div>
                              <div>{event.city} {event.state} {event.zipcode}</div>
                            </div>
                          )}

                          {event.is_webinar && (
                            <div className="eventdetails__address">
                              <div><strong>Webinar URL</strong></div>
                              <div>{event.webinar_url}</div>
                            </div>
                          )}

                            {event.venue_instructions && event.venue_instructions.length > 0 && (
                              <div className="eventdetails__address">
                                <div><strong>Venue Instructions</strong></div>
                                <div>{event.venue_instructions}</div>
                              </div>
                            )}
                          </>
                        )}
                        
                        <div style={{marginTop: '20px', fontSize: '12px'}}>
                        <a className='btn btn--full'
                        style={{display: 'block'}}
                href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Event:+${encodeURIComponent(
                  event.name
                )}&dates=${new Date(event.start_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}/${new Date(event.end_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}&details=Event+details:+https://www.leadrpro.com/event/${event.event_id}/${getSeoName(event.name)}&sf=true&output=xml&ctz=${
                  event.timezone
                }&location=${encodeURIComponent(
                  event.address1 + ' ' + event.city + ' ' + event.state
                )}`}
                target='_blank'>
                    <FaCalendarAlt /> Add to calendar
              </a>
                        </div>
                      </div>
                    )}

                    {event.is_registered === false && showConfirmation == false && (
                      <div className='eventdetails__register'>
                        <h3>Register</h3>

                        <p>
                          Register now to save your spot.
                        </p>

                      {event.requires_approval == true && (
                      <div className='eventdetails__requires-approval'>
                        <strong><FaUserCheck /> Requires approval:</strong> This event requires approval before attending.
                      </div>
                      )}

                    {event.requires_approval == true && isAuthed() && event.enable_questions === false && (
                      <div style={{marginTop: '20px'}}>
                        <button className="btn btn--primary" onClick={() => handleRegister()}>Click to apply</button>
                      </div>
                    )}

                    {
                      event.requires_approval === true && event.enable_questions === true && isAuthed() && (
                        <>
                          <div style={{marginTop: '20px'}}>
                            <button className="btn btn--primary" onClick={() => handleRegisterQuestions()}>Click to apply</button>
                          </div>
                        </>
                      )
                    }

                    {event.requires_approval === false && isAuthed() && (
                      <div style={{marginTop: '20px'}}>
                        <button className="btn btn--primary" onClick={() => handleRegister()}>Register for this event</button>
                      </div>
                    )}

                    {isAuthed() == false && (
                      <div style={{marginTop: '20px'}}>
                        <div className="form-group">
                          <strong>Name</strong>
                          <input type='text' className="input" value={registerName} onChange={(e)=> setRegisterName(e.target.value)} placeholder="Enter name" />
                        </div>

                        <div className="form-group">
                          <strong>Email</strong>
                          <input type='text' className="input" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} placeholder="Enter email" />
                        </div>

                        {/* <div className="form-group">
                          <strong>Your company</strong>
                          <input type='text' className="input" value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Enter company name" />
                        </div> */}

                        <div className='form-group'>
                          {isRegistering === false && (
                          <button className="btn btn--primary eventdetails__btn-register" onClick={() => handleInstantRegister()}>Register for event</button>
                          )}

                          {isRegistering && (
                            <Spinner />
                          )}
                        </div>
                      </div>
                    )}
                      </div>
                    )}
                    </>
                  )}

                  {submitting && (
                    <Spinner />
                  )}
                </div>

                {posts.length > 0 && (
                <div className="eventdetails__posts">
                  <h2>Feed</h2>

                  {posts.map(item => (
                    <div className="manageevent__post">
                      <div>
                      <div className="manageevent__guest-photo">
                          <img src={imageUrl + 'profileimages/tr:di-@@profileimages@@default-buyer.png/' + item.profile_image} alt="" />
                        </div>
                      </div>

                      <div style={{marginLeft: '10px'}}>
                        <div className="manageevent__post-date">{new Date(item.date_created).toLocaleDateString()}</div>

                        <div className="manageevent__post-body">
                        <p dangerouslySetInnerHTML={{ __html: item.post_body.replace(/\n/g, '<br>') }}></p>
                        <div>
                          {item.first_name && item.first_name.length > 0 && (
                          <strong>- {item.first_name}</strong>
                          )}
                        </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                )}

                <div className="eventdetails__info-buttons">
                <p>Download our free mobile app in order to network with other guests and participants.</p>

                <a
                    target='_blank'
                    href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
                    <img
                      src={apple}
                    />
                  </a>

                  <a
                    target='_blank'
                    href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
                    <img
                      src={googlePlay}
                    />
                  </a>
                </div>
                </div>
              </div>
            </>
          )}

              {showQuestions && (
                <>
                  <div className="overlay"></div>
                  <div className="modal eventdetails__modal-questions">
                    <div className="modal__close" onClick={() => setShowQuestions(false)}>
                      <FaTimes />
                    </div>

                    <h2 className="bold">Almost done...just a few more questions</h2>

                    <div>
                      {event.questions.map(item => (
                        <>
                          <div className="eventdetails__question">
                            <div className="eventdetails__question-text">
                              Q: {item.question}
                            </div>
                            
                            <div>
                              <textarea id={`question_${item.event_question_id}`} className='input textarea' placeholder="Answer"></textarea>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>

                    <div className='mt-20'>
                      {submittingAnswers === false && currentUserId == null && (
                        <button className="btn btn--full btn--primary pad-15" onClick={() => submitAnswers()}>Submit</button>
                      )}

                      {submittingAnswers === false && currentUserId != null && (
                        <button className="btn btn--full btn--primary pad-15" onClick={() => submitAnswersInstant()}>Submit</button>
                      )}

                      {submittingAnswers == true && (
                        <Spinner />
                      )}
                    </div>
                  </div>
                </>
              )}

              {showConfirmPopup && (
                <>
                  <div className="overlay"></div>
                  <div className="modal">
                    <div className="modal__close" onClick={() => setShowConfirmPopup(false)}>
                      <FaTimes />
                    </div>

                    <div>
                    {event.image_url && event.image_url.length > 0 && (
                      <div className="eventdetails__info-image"><img src={event.image_url} alt='' /></div>
                    )}

                      {(event.image_url == null || event.image_url.length === 0) && (
                        <div className="eventdetails__info-image">
                          <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" alt='' />
                          </div>
                      )}
                    </div>

                    <div className="mt-10">
                      Thanks for registering!
                    </div>

                    <div>
                      <img src={imgCoin} alt='' /> + 1
                    </div>
                  </div>
                </>
              )}
        </div>
    );
}

export default EventDetails;