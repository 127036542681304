import './SellerRescheduleConfirm.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import BuyerFeedback from '../../components/feedback/BuyerFeedback';
import SellerFeedback from '../../components/feedback/SellerFeedback';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getDemoFromRefWithTimes, refreshToken } from '../../utils/api';
import RescheduleTimes from "../../components/qualifier/RescheduleTimes";
import RescheduleConfirmation from '../../components/qualifier/RescheduleConfirmation';
import Spinner from '../../components/layout/Spinner';

function SellerRescheduleConfirm() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [isBuyer, setIsBuyer] = useState(false);
    const [isSeller, setIsSeller] = useState(false);
    const [demo, setDemo] = useState({});
    const [valid, setValid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState([]);

    useEffect(() => {
        async function loadDemo(){
            const id = new URLSearchParams(search).get('refid');
            setReferenceId(id);
      
            //alert(id);
            let response = await getDemoFromRefWithTimes(id);

            console.log('response', response);
      
            if (response.success){
              if (response.data.length > 0){
                setDemo(response.data[0]);
                console.log('demo data', response.data[0]);
                setValid(true);

                let user = await getCurrentUser();

                if (user.registered_user_id === response.data[0].seller_id)
                {
                    setIsSeller(true);
                }

                else{
                    setIsSeller(false);
                }
              }
      
              else{
                setValid(false);
                //setCurrentStep(-1);
              }
            }

            else if (response.status === 403){
              let _refresh = await refreshToken();

              if (_refresh.success) {
                localStorage.setItem('access_token', _refresh.data.accessToken);
                localStorage.setItem('refresh_token', _refresh.data.refreshToken);

                let response = await getDemoFromRefWithTimes(id);

                console.log('response', response);
          
                if (response.success){
                  if (response.data.length > 0){
                    setDemo(response.data[0]);
                    console.log('demo data', response.data[0]);
                    setValid(true);

                    let user = await getCurrentUser();

                    if (user.registered_user_id === response.data[0].seller_id)
                    {
                        setIsSeller(true);
                    }

                    else{
                        setIsSeller(false);
                    }
                  }
          
                  else{
                    setValid(false);
                  }
                }
              }
            }
      
            else{
              alert('not valid');
              setValid(false);
            }
      
            setLoaded(true);
            console.log(id);
          }
      
          loadDemo();
    }, []);

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
          <title>LeadrPro - Reschedule your demo</title>
        </Helmet>

        {loaded && isSeller && (
          <div className="sellerrescheduleconfirm">
             <RescheduleConfirmation
            currentDemo={demo}
            demos={demos}
            setDemos={setDemos}
            setShowRescheduleConfirmation={true}
          />
           </div>
        )}

        {loaded && isSeller === false && (
          <div className='center'>
            <i>Invalid reference ID...</i>
          </div>
        )}

        {loaded === false && (
          <div className='center'>
            <Spinner />
          </div>
        )}
        </div>
    );
}

export default SellerRescheduleConfirm;