import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPayNowCompanies } from '../../utils/api/admin';

function PayNowCompanies() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getPayNowCompanies();
    setData(res.data);
    setLoaded(true);
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPayNowCompanies();
      setData(res.data);
      setLoaded(true);
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    
  }, [selectedFilter])

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Pay Now Companies {`(${data.filter(x => {
              if (selectedFilter === 'All'){
                return x;
              }

              else if (selectedFilter === 'NoRequests'){
                if (x.pending == '0' && x.available_credits != '0')
                  return x;
              }

              else if (selectedFilter === 'WithRequests'){
                if (x.pending != '0')
                  return x;
              }
            }).length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div className='admindashboard__filters'>
            <button className='btn' onClick={() => setSelectedFilter('All')}>
              All
            </button>

            <button className='btn' onClick={() => setSelectedFilter('NoRequests')}>
              With no requests
            </button>

            <button className='btn' onClick={() => setSelectedFilter('WithRequests')}>
              Has available requests
            </button>
          </div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Date created</th>
              <th>Company</th>
              <th></th>
              <th>Available credits</th>
              <th>Last active</th>
              <th>Last request</th>
              <th>Requests</th>
              <th>Active</th>
            </tr>

            {data.filter(x => {
              if (selectedFilter === 'All'){
                return x;
              }

              else if (selectedFilter === 'NoRequests'){
                if (x.pending == '0' && x.available_credits != '0')
                  return x;
              }

              else if (selectedFilter === 'WithRequests'){
                if (x.pending != '0')
                  return x;
              }
            }).map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}</strong></td>

                <td>
                  {new Date(item.date_created).toDateString()}
                </td>

                <td>
                  <div className='buyerdashboard__recommendation-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
                </td>

                <td>
                  {item.name}
                </td>

                <td>
                  {item.available_credits}
                </td>

                <td>
                  {item.last_active && (
                    <span>{new Date(item.last_active).toDateString()}</span>
                  )}

                  {item.last_active == null && (
                    <span><i>n/a</i></span>
                  )}
                </td>

                <td>
                  {item.last_request_date && (
                    <span>{new Date(item.last_request_date).toDateString()}</span>
                  )}

                  {item.last_request_date == null && (
                    <span><i>n/a</i></span>
                  )}
                </td>

                <td>
                  {item.pending != '0' && (
                    <span className='admindashboard__green-flag'>{item.pending}</span>
                  )}

                  {item.pending == '0' && (
                    <span className='admindashboard__red-flag'>None</span>
                  )}
                </td>

                <td>
                  {item.is_inactive === true && (
                    <span className='admindashboard__red-flag'>
                      Not active
                    </span>
                  )}

                  {item.is_inactive === false && (
                    <span className='admindashboard__green-flag'>
                     Active
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default PayNowCompanies;