import './LeadRecommendations.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getUserWaitlist,removeFromWaitlist} from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaRegBell, FaTimes } from 'react-icons/fa';
import herocards from '../../assets/design/homepagehub/software/herocards.png';

function LeadRecommendations() {
  const [loaded, setLoaded] = useState(false);
  const [waitlist, setWaitlist] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getUserWaitlist();

    if (res.success){
      setWaitlist(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getUserWaitlist();

      if (res.success){
        setWaitlist(res.data);
        setLoaded(true);
      }
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  async function handleRemove(id){
    let res = await removeFromWaitlist(id);

    if (res.success){
      loadData();
    }

    else if (res.status === 403){
      await refresh();

      let res = await removeFromWaitlist(id);

      if (res.success){
        loadData();
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Manage alerts</title>
      </Helmet>

      <div className='managealerts'>
      <div className='homepagehub__hub-description-item' style={{background: '#2d284d', color: 'white', padding: '10% 10%', marginBottom: '0px'}}>
          <div className='homepagehub__hub-description-info'>


            <div className='browseproducts__hub-description-info-header'>
              Hello Robert!<br/>
              You're recommendations are ready.
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              Our software experts have found the following companies to match exactly what you're looking for!
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn'>Let's see it</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div className='homepagehub__hub-description-vector-image'>
              <img src={herocards} alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadRecommendations;