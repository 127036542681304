import './SelectICP.css';
import {useEffect, useState} from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { getDepartments, getAllIndustries, getSeniorityLevels, getCompanySizes, addCompanyPreferenceDepartment, addCompanyPreferenceSeniority, addCompanyPreferenceSize, addCompanyPreferenceIndustry, 
  removeCompanyPreferenceIndustry, removeCompanyPreferenceDepartment, removeCompanyPreferenceSeniority, removeCompanyPreferenceSize, getCompanyPreferenceDepartment, getCompanyPreferenceSeniority, getCompanyPreferenceSize, getCompanyPreferenceIndustry, refreshToken } from '../../utils/api';
import React from 'react';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';

const SelectICP = ({userType}) => {
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedSeniority, setSelectedSeniority] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadDepartments(){
      let _res = await getDepartments();

      if (_res.success){
        setDepartments(_res.data);
      }
    }

    async function loadSeniority(){
      let _res = await getSeniorityLevels();

      if (_res.success){
        setSeniorityLevels(_res.data);
      }
    }

    async function loadSizes(){
      let _res = await getCompanySizes();

      if (_res.success){
        setSizes(_res.data);
      }
    }

    async function loadIndustries(){
      let _res = await getAllIndustries();

      if (_res.success){
        setIndustries(_res.data);
        console.log('industries', _res.data);
      }
    }

    async function loadSelectedDepartments(){
      let _res = await getCompanyPreferenceDepartment();

      if (_res.success){
        setSelectedDepartments(_res.data);
      }
    }

    async function loadSelectedIndustries(){
      let _res = await getCompanyPreferenceIndustry();

      if (_res.success){
        setSelectedIndustries(_res.data);
      }
    }

    async function loadSelectedSeniorities(){
      let _res = await getCompanyPreferenceSeniority();

      if (_res.success){
        setSelectedSeniority(_res.data);
      }
    }

    async function loadSelectedSizes(){
      let _res = await getCompanyPreferenceSize();

      if (_res.success){
        setSelectedSizes(_res.data);
      }
    }

    loadDepartments();
    loadIndustries();
    loadSeniority();
    loadSizes();

    loadSelectedDepartments();
    loadSelectedIndustries();
    loadSelectedSeniorities();
    loadSelectedDepartments();
    loadSelectedSeniorities();
    loadSelectedSizes();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleDepartmentChange(e, department_id, item){
    if (e.target.checked){
      let res = await addCompanyPreferenceDepartment(department_id);

      if (res.success){
        setSelectedDepartments([...selectedDepartments, item]);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await addCompanyPreferenceDepartment(department_id);

          if (res.success){
            setSelectedDepartments([...selectedDepartments, item]);
          }
        }
      }
    }

    else{
      let res = await removeCompanyPreferenceDepartment(department_id);

      if (res.success){
        let _filtered = selectedDepartments.filter(x => x.department_id !== department_id);
        setSelectedDepartments(_filtered);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await removeCompanyPreferenceDepartment(department_id);

          if (res.success){
            let _filtered = selectedDepartments.filter(x => x.department_id !== department_id);
            setSelectedDepartments(_filtered);
          }
        }
      }
    }
  }

  async function handleSeniorityChange(e, seniority_level_id, item){
    if (e.target.checked){
      let res = await addCompanyPreferenceSeniority(seniority_level_id);

      if (res.success){
        setSelectedSeniority([...selectedSeniority, item]);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await addCompanyPreferenceSeniority(seniority_level_id);

          if (res.success){
            setSelectedSeniority([...selectedSeniority, item]);
          }
        }
      }
    }

    else{
      await removeCompanyPreferenceSeniority(seniority_level_id);

      let _filtered = selectedSeniority.filter(x => x.seniority_level_id !== seniority_level_id);
      setSelectedSeniority(_filtered);
    }
  }

  async function handleSizeChange(e, company_size_id, item){
    if (e.target.checked){
      let res = await addCompanyPreferenceSize(company_size_id);

      if (res.success){
        setSelectedSizes([...selectedSizes, item]);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await addCompanyPreferenceSize(company_size_id);

          if (res.success){
            setSelectedSizes([...selectedSizes, item]);
          }
        }
      }
    }

    else{
      await removeCompanyPreferenceSize(company_size_id);

      let _filtered = selectedSizes.filter(x => x.company_size_id !== company_size_id);
      setSelectedSizes(_filtered);
    }
  }

  async function handleIndustryChange(e, industry_id, item){
    if (e.target.checked){
      let res = await addCompanyPreferenceIndustry(industry_id);

      if (res.success){
        setSelectedIndustries([...selectedIndustries, item]);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);
          let res = await addCompanyPreferenceIndustry(industry_id);

          if (res.success){
            setSelectedIndustries([...selectedIndustries, item]);
          }
        }
      }
    }

    else{
      await removeCompanyPreferenceIndustry(industry_id);

      let _filtered = selectedIndustries.filter(x => x.industry_id !== industry_id);
      setSelectedIndustries(_filtered);
    }
  }

  async function handleSaveClick(){
    loadSaveBanner();
  }

  return (
    <div className="selecticp">
      <div className=""><button className='btn' onClick={() => navigate(-1)}>Back</button></div>
      <div className="account__header">Select your Ideal Customer Profile</div>

      <p className='account__description'>
        We will only match you with candidates that fit your selected ICP's (Ideal Customer Profiles). Select all that apply.
      </p>

      <div className='selecticp__section'>
        <h2>Preferred Departments</h2>

        <div className='selecticp__list'>
        {departments.map(item => (
          <div style={{margin: "10px"}}>
            <input className="selecticp__checkbox" type="checkbox" id={"dep_" + item.department_id} value={item.department_id} onChange={(e) => handleDepartmentChange(e, item.department_id, item)} checked={selectedDepartments.filter(x=> x.department_id === item.department_id).length > 0?true: false} /> <label className='selecticp__checkbox-label' htmlFor={"dep_" + item.department_id}>{item.name}</label>
          </div>
        ))}
        </div>

        {/* {departments.map(item => (
          <div>
            <input type="checkbox" id={"dep_" + item.department_id} value={item.department_id} onChange={(e) => handleDepartmentChange(e, item.department_id, item)} checked={selectedDepartments.filter(x=> x.department_id === item.department_id).length > 0?true: false} /> <label htmlFor={"dep_" + item.department_id}>{item.name}</label>
          </div>
        ))} */}
      </div>

      <div className='selecticp__section'>
        <h2>Preferred Seniority</h2>

        <div className='selecticp__list'>
        {seniorityLevels.map(item => (
          <div style={{margin: "10px"}}>
            <input className="selecticp__checkbox" type="checkbox" id={"sen_" + item.seniority_level_id} value={item.seniority_level_id} onChange={(e) => handleSeniorityChange(e, item.seniority_level_id, item)} checked={selectedSeniority.filter(x=> x.seniority_level_id === item.seniority_level_id).length > 0?true: false} /> <label className='selecticp__checkbox-label' htmlFor={"sen_" + item.seniority_level_id}>{item.name}</label>
          </div>
        ))}
        </div>

        {/* {seniorityLevels.map(item => (
          <div>
            <input type="checkbox" id={"sen_" + item.seniority_level_id} value={item.seniority_level_id} onChange={(e) => handleSeniorityChange(e, item.seniority_level_id, item)} checked={selectedSeniority.filter(x=> x.seniority_level_id === item.seniority_level_id).length > 0?true: false} /> <label htmlFor={"sen_" + item.seniority_level_id}>{item.name}</label>
          </div>
        ))} */}
      </div>
      
      <div className='selecticp__section'>
        <h2>Preferred Company Sizes</h2>

        <div className='selecticp__list'>
        {sizes.map(item => (
          <div style={{margin: "10px"}}>
            <input className="selecticp__checkbox" type="checkbox" id={"size_" + item.company_size_id} value={item.company_size_id} onChange={(e) => handleSizeChange(e, item.company_size_id, item)} checked={selectedSizes.filter(x=> x.company_size_id === item.company_size_id).length > 0?true: false} /> <label className='selecticp__checkbox-label' htmlFor={"size_" + item.company_size_id}>{item.value}</label>
          </div>
        ))}
        </div>

        {/* {sizes.map(item => (
          <div>
            <input type="checkbox" id={"size_" + item.company_size_id} value={item.company_size_id} onChange={(e) => handleSizeChange(e, item.company_size_id, item)} checked={selectedSizes.filter(x=> x.company_size_id === item.company_size_id).length > 0?true: false} /> <label htmlFor={"size_" + item.company_size_id}>{item.value}</label>
          </div>
        ))} */}
      </div>

      <div className='selecticp__section'>
        <h2>Preferred Company Industries</h2>

        <div className='selecticp__list'>
        {industries.map(item => (
          <div style={{margin: "10px"}}>
            <input className="selecticp__checkbox" type="checkbox" id={"industry_" + item.industry_id} value={item.industry_id} onChange={(e) => handleIndustryChange(e, item.industry_id, item)} checked={selectedIndustries.filter(x=> x.industry_id === item.industry_id).length > 0?true: false} /> <label className='selecticp__checkbox-label' htmlFor={"industry_" + item.industry_id}>{item.name}</label>
          </div>
        ))}
        </div>

        {/* {industries.map(item => (
          <div>
            <input type="checkbox" id={"industry_" + item.industry_id} value={item.industry_id} onChange={(e) => handleIndustryChange(e, item.industry_id, item)} checked={selectedIndustries.filter(x=> x.industry_id === item.industry_id).length > 0?true: false} /> <label htmlFor={"industry_" + item.industry_id}>{item.name}</label>
          </div>
        ))} */}
      </div>

      {/* <div>
        <button className='btn btn--primary' onClick={() => handleSaveClick()}>Save</button>
      </div> */}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
      <div className='profileinfo__saved-banner'>
        Profile updated successfully!
      </div>
      </FadeIn>
      )}
    </div>
  );
}

export default SelectICP;