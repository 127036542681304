import './Search.css';
import {useState} from 'react';
import SearchBar from './SearchBar';
// import BookingCalendar from './BookingCalendar';
// import SellerConfirmation from './SellerConfirmation';
import FadeIn from '../../utils/fadein';
import icon_filter from '../../assets/design/search/filter.png';
import BuyerResults from './BuyerResults';
import SearchFilters from './SearchFilters';
import SellerSearch from './SellerSearch';
import BrowseSellers from './BrowseSellers';

const Search = ({userType}) => {
  const [results, setResults] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirmation, setShowConfirmation]= useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);

  function bookDemo() {
    setShowBooking(!showBooking);
  }

  function confirmation(){
    setShowBooking(false);
    setShowConfirmation(true);
  }

  function cancelConfirmation(){
    setShowConfirmation(false);
    setShowBooking(true);
  }
  
  function congrats(){
    setShowCongrats(true);
    setShowConfirmation(false);
  }

  function cancelCongrats(){
    setShowCongrats(false);
  }

  function removeFilter(type, id){
    let filter = selectedFilters.filter(item => {
      if (item.type===type && item.value === id)
      {
        return false;
      }
      return true;
    });

    setSelectedFilters(filter);
  }

  return (
    <div>
      {/* <SearchBar userType={userType} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} query={query} setQuery={setQuery} /> */}
      
      {/* {showBooking===true && <BookingCalendar clickMe={bookDemo} clickConfirm={confirmation} /> } */}

      {/* {showConfirmation===true&& <SellerConfirmation clickCancel={cancelConfirmation} clickConfirm={congrats} />} */}

      {/* {showCongrats===true&& <SellerCongratulations clickCancel={cancelCongrats} />} */}

      <div className="search__wrapper" style={{display:"flex"}}>
        {userType ==='Buyer' && (
      <SearchFilters userType={userType} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} removeFilter={removeFilter} showFilter={showFilter} setShowFilter={setShowFilter} />
        )}
        
      <div className='search__results' style={{width: "100%"}}>
        {/* <SearchBar userType={userType} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} query={query} setQuery={setQuery} setSelectedCategory={setSelectedCategory} setSelectedCompanyId={setSelectedCompanyId} />

      <div className="search__filter-btn">
        <button className="btn btn--primary" onClick={() => setShowFilter(!showFilter)}>Filters <img style={{height: "15px", verticalAlign:"middle", marginLeft: "7px"}} src={icon_filter} alt="" /></button>
      </div> */}

      {userType==="Seller" && (
        <>
        {/* <SellerResults selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}  query={query} selectedCategory={selectedCategory} selectedCompanyId={selectedCompanyId} setSelectedCompanyId={setSelectedCompanyId} /> */}

        <SellerSearch selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}  query={query} selectedCategory={selectedCategory} selectedCompanyId={selectedCompanyId} setSelectedCompanyId={setSelectedCompanyId} />
        </>
      )}
      
      {userType === "Buyer" && (
        <BuyerResults selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} query={query} selectedIndustry={selectedIndustry} selectedCompanyId={selectedCompanyId} setSelectedCompanyId={setSelectedCompanyId} />
      )}
      </div>
      </div>
    </div>
  )
}

export default Search;