import { useEffect, useState } from 'react';
import close from '../../assets/design/dashboard/close.png';
import congrats from '../../assets/design/dashboard/congrats.png';
import starNotFilled from "../../assets/design/review/star-not-filledv2.svg";
import starFilled from "../../assets/design/review/star-filled.svg";
import { addBuyerFeedback, addBuyerFeedbackNew, getBuyerDemos, getBuyerFeedbackCriteria, addBuyerFeedbackCriteria, getBuyerDemosIncomplete } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import moment from 'moment-timezone';
import FadeIn from '../../utils/fadein';
import { refresh } from '../../services/auth.service';
import { FaTimes } from 'react-icons/fa';

const BuyerDemosIncomplete = ({ buyerObjections }) => {
  const [demos, setDemos] = useState([]);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [objectionReason, setObjectionReason] = useState('');
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [demoId, setDemoId] = useState(0);
  const [selectedObjectionReason, setSelectedObjectionReason] = useState(null);
  const [ratings, setRatings] = useState({});
  const [feedbackRatings, setFeedbackRatings] = useState([]);
  const [feedback1, setFeedback1] = useState('');
  const [feedback2, setFeedback2] = useState('');
  const [feedback3, setFeedback3] = useState('');
  const [currentCriteria, setCurrentCriteria] = useState(0);
  const [selectedFeedbackOption, setSelectedFeedbackOption] = useState(0);
  const [buyerFeedbackCriteria, setBuyerFeedbackCriteria] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState('initial');
  const [submitting, setSubmitting] = useState(false);
  const [feedbackStep, setFeedbackStep] = useState(1);
  const [declineReason, setDeclineReason] = useState('');
  const [problemLookingToSolve, setProblemLookingToSolve] = useState('');
  const [doesProductFix, setDoesProductFix] = useState('');
  const [otherProviders, setOtherProviders] = useState('');
  const [currentBudget, setCurrentBudget] = useState('');
  const [implementationTime, setImplementationTime] = useState('');
  const [planToAddress, setPlanToAddress] = useState('');
  const [otherSoftware, setOtherSoftware] = useState('');
  const [loaded, setLoaded] = useState(false);
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  useEffect(() => {
    async function loadDemos(){
      let res = await getBuyerDemosIncomplete();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getBuyerDemosIncomplete();

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
        }
      }
    }

    loadDemos();
  }, []);
  
  useEffect(() => {
    async function loadSellerFeedbackCriteria(){
      let res = await getBuyerFeedbackCriteria();

      if (res.success){
        setBuyerFeedbackCriteria(res.data);
      }
    }

    loadSellerFeedbackCriteria();
  }, []);

  function leaveFeedbackClickHandle(id, demo) {
    setShowFeedbackPopup(true);
    setCurrentDemo(demo);
    setDemoId(id);
  }

  function handleFeedbackClick(buyer_feedback_criteria_id, i){
    setCurrentCriteria(buyer_feedback_criteria_id);

    // store object into array
    let _filter = feedbackRatings.filter(item => item.buyer_feedback_criteria_id === buyer_feedback_criteria_id);

    if (_filter.length > 0){
      // array has rating
      let _filter = feedbackRatings.filter(item => item.buyer_feedback_criteria_id !== buyer_feedback_criteria_id);
      let _obj = {buyer_feedback_criteria_id, value: i};
      _filter.push(_obj);
      setFeedbackRatings(_filter);
    }

    else{
      // array does not have the feedback just yet
      let obj = {buyer_feedback_criteria_id, value: i};
      setFeedbackRatings([...feedbackRatings, obj]);
    }
  }

    async function submitBuyerFeedback(e, id) {
      e.preventDefault();

      if (feedbackRatings.length === 0){
        alert("Please provide feedback!");
        return;
      }
  
      let objectionReason = e.target.elements.objectionReason.value;
      let objectionReasonText = e.target.elements.objectionReason.options[e.target.elements.objectionReason.selectedIndex].text;
      let objectionDescription = e.target.elements.objectionDescription.value;

      if (objectionDescription === ''){
        alert("Please provide feedback to complete");
        return;
      }

      let res = await addBuyerFeedback(id, false, objectionReason, objectionDescription);
  
      if (res.success) {
        setShowFeedbackPopup(!showFeedbackPopup);
        setFeedbackStatus('initial');

        let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
        let _obj = Object.assign({}, currentDemo);
        let _feedback = {moving_to_proposal: false, reason: objectionReasonText};
        let _arr = [];
        _arr.push(_feedback);
        _obj.feedback_count = parseInt(_obj.feedback_count) + 1;
        _obj.feedback = _arr;
        _filter.push(_obj);
        setDemos(_filter);

        window.location = window.location;
      }
    }

    async function handleSubmit(e){
      if (selectedFeedbackOption === 0){
        alert("Please select a valid option");
        return;
      }

      if (selectedFeedbackOption === 5 && declineReason.length < 50){
        alert('Please provide additional feedback in your response.');
        return;
      }

      if (selectedFeedbackOption === 2 && declineReason.length === 0){
        alert('Please provide available times');
        return;
      }

      setSubmitting(true);

      let res = await addBuyerFeedbackNew(currentDemo.demo_id, true, selectedFeedbackOption, feedback1, feedback2, feedback3, problemLookingToSolve, doesProductFix, otherProviders, currentBudget, implementationTime, planToAddress, otherSoftware, declineReason);
  
      if (res.success) {
        let buyer_feedback_id = res.data;

        for (let i = 0; i < feedbackRatings.length; i++){
          await addBuyerFeedbackCriteria(buyer_feedback_id, feedbackRatings[i].buyer_feedback_criteria_id, feedbackRatings[i].value + 1);
        }

        setFeedbackStatus('confirm');
        setSubmitting(false);
      }

      else if (res.status === 403){
        await refresh();

        let res = await addBuyerFeedbackNew(currentDemo.demo_id, true, selectedFeedbackOption, feedback1, feedback2, feedback3, problemLookingToSolve, doesProductFix, otherProviders, currentBudget, implementationTime, planToAddress, otherSoftware, declineReason);
  
      if (res.success) {
        let buyer_feedback_id = res.data;

        for (let i = 0; i < feedbackRatings.length; i++){
          await addBuyerFeedbackCriteria(buyer_feedback_id, feedbackRatings[i].buyer_feedback_criteria_id, feedbackRatings[i].value + 1);
        }

        setFeedbackStatus('confirm');
        setSubmitting(false);
      }
      }
    }

    async function handleNext1(e){
      if (feedbackRatings.length != buyerFeedbackCriteria.length){
        alert("Please select a valid option");
        return;
      }

      else{
        setFeedbackStep(2);
      }
    }

    async function handleNext2(e){
      if (feedback1.length === 0 || feedback2.length === 0 || feedback3.length === 0 || problemLookingToSolve.length === 0 || doesProductFix.length === 0 || otherProviders.length === 0 || currentBudget.length === 0 || implementationTime.length === 0 || planToAddress.length === 0 || otherSoftware.length === 0){
        alert("Please provide valid feedback for all questions");
        return;
      }

      else{
        setFeedbackStep(3);
      }
    }

    async function handleNotMovingForward(e){
      setSelectedFeedbackOption(5);
      setFeedbackStep(4);
    }

    async function handleProposal(e){
      setSelectedFeedbackOption(1);
      setFeedbackStep(5);
    }

    async function handleFollowUp(e){
      setSelectedFeedbackOption(2);
      setFeedbackStep(6);
    }

    async function handlePricing(e){
      setSelectedFeedbackOption(3);
      setFeedbackStep(7);
    }
  
    function previousPageClick() {
      setFeedbackStatus('initial');
    }

    function handleContinue(){
      window.location = window.location;
    }

    function renderStars(buyer_feedback_criteria_id){
      let arr = [];
      let _filter = feedbackRatings.filter(item => item.buyer_feedback_criteria_id === buyer_feedback_criteria_id);
      let _value = -1;
  
      if (_filter.length > 0){
       _value = _filter[0].value;
      }
  
      for (let i = 0; i < 5; i++){
        let index = ratings[buyer_feedback_criteria_id];
        
        if (i <= _value){
          arr.push(<span onClick={() => handleFeedbackClick(buyer_feedback_criteria_id, i) }><img src={starFilled} className="review__star-filled" style={{height: '30px'}} /></span>);
        }
  
        else{
          arr.push(<span onClick={() => handleFeedbackClick(buyer_feedback_criteria_id, i) }><img src={starNotFilled} className="review__star-not-filled" style={{height: '30px'}} /></span>);
        }
      }
  
      return (
        <div className='dashboarddemo__star'>
          {arr}
        </div>
      )
    }

    function getTrimmedString(inputString = '') {
      const maxLength = 160;
      if (inputString.length <= maxLength) {
          return inputString;
      } else {
          // Truncate the string to the maximum length minus 1 (for the ellipsis)
          // and ensure we don't cut off in the middle of a word.
          let trimmedString = inputString.substr(0, maxLength - 1);
          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
          return `${trimmedString}…`;
      }
    }

  return (
    <>
      {showFeedbackPopup && (
      <>
        <div className='overlay'></div>
        <div className='modal dashboarddemo__feedback-modal'>
          {feedbackStatus === 'initial' && (
            <>
              <div className='modal__close' onClick={() => {setShowFeedbackPopup(false);
          setFeedbackStatus('initial'); setFeedbackStep(1)}}>
              <FaTimes />
            </div>

              <h2 className='buyerdashboard__h1' style={{textDecoration: 'none', marginBottom: '0px'}}>Your Feedback</h2>

              <div className='modal__body'>

              {feedbackStep === 1 && (
                <FadeIn delay={400} duration={400}>
              <div className='dashboarddemo__feedback-modal-section' style={{margin: "auto", marginBottom: "30px"}}>

              <div style={{marginBottom: '15px'}}>
                <strong>Please let us know how your demo went.</strong>
              </div>

                  {buyerFeedbackCriteria.map(item => (
                    <>
                      <div className='dashboarddemo__feedback-criteria' style={{display:"flex", justifyContent: "space-between"}}>
                        <span className='dashboarddemo__star' style={{width: '50%'}}>
                          {item.display_name}
                        </span>

                        {renderStars(item.buyer_feedback_criteria_id)}
                      </div>
                    </>
                  ))}

                  <div className='center' style={{marginTop: '30px'}}>
                    <button className='btn btn--primary' onClick={(e) => handleNext1(e)}>Next</button>
                  </div>
                </div>
                </FadeIn>
              )}
                
                {feedbackStep === 2 && (
                <FadeIn delay={400} duration={400}>
                  <div className='dashboarddemo__feedback-question'>
                  <h3>What did you find most appealing about the product?</h3>
                  
                  <div style={{fontSize: '12px', marginTop: '10px', marginBottom: '10px'}}>
                      Please be as thorough as possible in your response.
                  </div>

                  <textarea className='textarea input' placeholder='Answer' value={feedback1} onChange={(e) => setFeedback1(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>What, if anything, are your hesitations/concerns about the product?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={feedback2} onChange={(e) => setFeedback2(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>Was there anything you wish to have seen or discussed in this presentation that was not?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={feedback3} onChange={(e) => setFeedback3(e.target.value)}></textarea>
                </div>


                {/* ///////////////////////////////// */}


                <div className='dashboarddemo__feedback-question'>
                  <h3>What problem{'(s)'} were you looking to solve?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={problemLookingToSolve} onChange={(e) => setProblemLookingToSolve(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>Does the product fix the problem? Which features are valuable or what is missing?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={doesProductFix} onChange={(e) => setDoesProductFix(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>What other providers are you currently evaluating?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={otherProviders} onChange={(e) => setOtherProviders(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>What is your current budget for a solution, and which department is responsible?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={currentBudget} onChange={(e) => setCurrentBudget(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>Is implementation time or team adoption a concern for you? Please explain.</h3>

                  <textarea className='textarea input' placeholder='Answer' value={implementationTime} onChange={(e) => setImplementationTime(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>If you do not buy this product, how do you plan to address the ongoing issue(s)?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={planToAddress} onChange={(e) => setPlanToAddress(e.target.value)}></textarea>
                </div>

                <div className='dashboarddemo__feedback-question'>
                  <h3>What other related software does your team actively use?</h3>

                  <textarea className='textarea input' placeholder='Answer' value={otherSoftware} onChange={(e) => setOtherSoftware(e.target.value)}></textarea>
                </div>

                  <div className='center' style={{marginTop: '30px'}}>
                    <button className='btn btn--primary' onClick={() => handleNext2()}>Next</button>
                  </div>
              </FadeIn>
              )}

              {feedbackStep === 3 && (
                <FadeIn delay={400} duration={400}>
                  <div>
                    <div><strong>How would you like to proceed?</strong></div>

                    <div style={{marginTop: '30px'}}>
                      <div className='dashboarddemo__feedback-btn' onClick={() => handleProposal()}>
                        📄 Send me a proposal
                      </div>

                      <div className='dashboarddemo__feedback-btn' onClick={() => handleFollowUp()}>
                        📞 Schedule a follow-up call
                      </div>

                      <div className='dashboarddemo__feedback-btn' onClick={() => handlePricing()}>
                        💲 Send me pricing information
                      </div>

                      <div className='dashboarddemo__feedback-btn' onClick={() => handleNotMovingForward()}>
                        ❌ Not moving forward
                      </div>
                    </div>
                  </div>
                </FadeIn>
              )}

              {feedbackStep === 4 && (
                <FadeIn>
                  <div>
                    <div>
                      <strong>We're sorry to hear that the product does not match your current needs. Please explain your reason for not moving forward. Be as thorough as possible in your explanation including reasons such as 'lack of features' or 'challenges with implementation.'</strong>
                    </div>

                    <div style={{marginTop: '30px'}}>
                      <textarea className='input' placeholder='Explain your decision' style={{height: '130px', border:'solid 1px #ccc'}} value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
                      </textarea>

                      <div style={{float:'right', fontSize: '12px', color: '#aaa'}}>
                        50 character min.
                      </div>
                    </div>

                    <div>
                      <span className='link link--cancel' onClick={() => setFeedbackStep(3)}>{'< Back'}</span>
                    </div>

                    {submitting === false && (
                      <div className='center mar10'>
                        <button className='btn btn--primary btn--apply' onClick={(e) => handleSubmit(e)}>Submit and Finish</button>
                      </div>
                    )}

                    {submitting && (
                      <div className='center mar10'>
                        <Spinner />
                      </div>
                    )}
                  </div>
                </FadeIn>
              )}

                {/* send a proposal */}
              {feedbackStep === 5 && (
                <FadeIn>
                  <div>
                    <div><strong>
                      Great! It sounds like your meeting went well and you'd like the company to prepare a formal proposal. By clicking 'Submit' we will automatically send a notification to the company to begin the process and you should hear back from them shortly.
                      </strong>
                      <br/><br/>
                      <strong>If you have any added notes for the seller, please leave them below.</strong>
                    </div>

                    <div style={{marginTop: '30px'}}>
                      <textarea className='input' placeholder='Your notes' style={{height: '130px', border:'solid 1px #ccc'}} value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
                      </textarea>
                    </div>

                    <div>
                      <span className='link link--cancel' onClick={() => setFeedbackStep(3)}>{'< Back'}</span>
                    </div>

                      <div style={{marginTop: '50px'}}>
                      {submitting === false && (
                      <div className='center mar10'>
                        <button className='btn btn--primary btn--apply' onClick={(e) => handleSubmit(e)}>Submit and Finish</button>
                      </div>
                    )}

                    {submitting && (
                      <div className='center mar10'>
                        <Spinner />
                      </div>
                    )}
                      </div>
                  </div>
                </FadeIn>
              )}

              {/* schedule a follow up */}
              {feedbackStep === 6 && (
                <FadeIn>
                  <div>
                    <div><strong>
                      Great! It sounds like your meeting went well and you'd like to receive a follow-up call to continue the process. Please submit your best available times in the following text area and we will automatically notify the seller to begin the scheduling process.
                      </strong>
                    </div>

                    <div style={{marginTop: '30px'}}>
                      <textarea className='input' placeholder='When are you available' style={{height: '80px', border:'solid 1px #ccc'}} value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
                      </textarea>
                    </div>

                    <div>
                      <span className='link link--cancel' onClick={() => setFeedbackStep(3)}>{'< Back'}</span>
                    </div>

                      <div style={{marginTop: '50px'}}>
                      {submitting === false && (
                      <div className='center mar10'>
                        <button className='btn btn--primary btn--apply' onClick={(e) => handleSubmit(e)}>Submit and Finish</button>
                      </div>
                    )}

                    {submitting && (
                      <div className='center mar10'>
                        <Spinner />
                      </div>
                    )}
                      </div>
                  </div>
                </FadeIn>
              )}

              {/* schedule a follow up */}
              {feedbackStep === 7 && (
                <FadeIn>
                  <div>
                    <div><strong>
                      Great! It sounds like your meeting went well and you'd like to receive additional pricing information from the seller. By clicking 'Submit' we will automatically send a notification to the company to begin the process and you should hear back from them shortly.
                      </strong>
                    </div>

                    <div style={{marginTop: '15px'}}><strong>
                      If you have any additional notes that you would like to leave, please add them below.
                      </strong>
                    </div>

                    <div style={{marginTop: '30px'}}>
                      <textarea className='input' placeholder='Added notes' style={{height: '80px', border:'solid 1px #ccc'}} value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
                      </textarea>
                    </div>

                    <div>
                      <span className='link link--cancel' onClick={() => setFeedbackStep(3)}>{'< Back'}</span>
                    </div>

                      <div style={{marginTop: '50px'}}>
                      {submitting === false && (
                      <div className='center mar10'>
                        <button className='btn btn--primary btn--apply' onClick={(e) => handleSubmit(e)}>Submit and Finish</button>
                      </div>
                    )}

                    {submitting && (
                      <div className='center mar10'>
                        <Spinner />
                      </div>
                    )}
                      </div>
                  </div>
                </FadeIn>
              )}

              {feedbackStep === 8 && (
                <FadeIn>
              <h3 className='dashboarddemo__feedback-question-h3'>
                Are you interested in moving forward with <span className=''>{currentDemo.company_name}</span>?
              </h3>

              <div className='dashboarddemo__feedback-options'>
                <div><input type="radio" name="rbOption" id="rb1" value="1" onChange={(e) => setSelectedFeedbackOption(e.target.value)} /> <label htmlFor='rb1'>Yes, I would like them to send me a proposal</label></div>

                <div><input type="radio" name="rbOption" id="rb2" value="2" onChange={(e) => setSelectedFeedbackOption(e.target.value)} /> <label htmlFor='rb2'>Yes, I would like to set up a follow up call for a more in depth discussion</label></div>

                <div><input type="radio" name="rbOption" id="rb3" value="3" onChange={(e) => setSelectedFeedbackOption(e.target.value)} /> <label htmlFor='rb3'>Yes, but I would like them to send me more information and marketing materials, and/or I need to discuss with my team internally</label></div>

                <div><input type="radio" name="rbOption" id="rb4" value="4" onChange={(e) => setSelectedFeedbackOption(e.target.value)} /> <label htmlFor='rb4'>Unsure, need more info</label></div>

                <div><input type="radio" name="rbOption" id="rb5" value="5" onChange={(e) => setSelectedFeedbackOption(e.target.value)} /> <label htmlFor='rb5'>No, not a fit</label></div>
              </div>
              </FadeIn>
              )}
              </div>
            </>
          )}

          {feedbackStatus === 'confirm' && (
            <div className=''>
              <div
                className='modal__h2'>
                Feedback Submitted
              </div>

              <div className=''>
                <div className='mar10'>
                  You're feedback has now been saved.
                </div>

                <div className='mar10'>
                  If you have expressed interest in this company, hang tight and someone will follow up with you very soon!
                </div>

                <div className='mar10'>
                  To get paid for this demo, please click on the 'Request withdraw' link at the top of your dashboard.
                </div>

                <div style={{marginTop: "20px"}}>
                  <button className='btn btn--primary' onClick={() => handleContinue()}>Continue</button>
                </div>
              </div>
            </div>
          )}

          {feedbackStatus === 'yes' && (
            <>
              <img src={close} className='modal__close' onClick={() => {setShowFeedbackPopup(false);
          setFeedbackStatus('initial')}}/>

              <div className='center'>
                <img src={congrats} />
              </div>

              <div
                className='modal__header--large'>
                That's great!
              </div>

              <div className='modal__body'>
                <div className=''>
                  We're glad it went well!
                </div>

                <div className=''>
                  We'll let <span className='dashboarddemo__highlight'>{currentDemo.company_name}</span> know you would like to move
                  forward.
                </div>

                <div style={{marginTop: "30px"}}>
                  <button className='btn btn--primary' onClick={() => handleContinue()}>Continue</button>
                </div>
              </div>
            </>
          )}

          {feedbackStatus === 'no' && (
            <>
              <div className='feedbackpopup__text--large-no'>
                We're sorry to hear that!
              </div>

              <form onSubmit={(e) => submitBuyerFeedback(e, currentDemo.demo_id)}>
                <div className='form-group' style={{ marginBottom: '20px' }}>
                  <label>
                    <div className='feedbackpopup__text--small-no' style={{ marginBottom: '15px' }}>
                      Please select one of the reasons below as to
                      why you won't be moving forward
                    </div>
                  </label>

                  <select className='input_box dropdown-font' id='objectionReason'
                    onChange={(e) =>
                      setSelectedObjectionReason(e.target.value)
                    }>
                    {buyerObjections.map((item) => (
                      <option value={item.objection_reason_id}>
                        {item.reason}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='form-group'>
                  <label className='feedbackpopup__text--small'>
                    Please let the seller know how they could
                    improve with future buyers
                  </label>

                  <textarea autoComplete='off' value={objectionReason}
                    onChange={(event) =>
                      setObjectionReason(event.target.value)
                    }
                    className='input textarea'
                    id='objectionDescription'
                    placeholder='Minimum 40 characters'
                  />
                </div>

                <div className='center'>
                  <button className='btn btn--primary btn--dark'>
                    Submit &amp; finish
                  </button>

                  <div className='link link--cancel mar10' onClick={previousPageClick}>
                    Back
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </>
    )}

    {loaded === false && (
      <Spinner />
    )}

      {loaded === true && demos.filter(item => item.demo_status === 'Completed' && item.feedback_count === '0').length === 0 && (
        <div className='dashboarddemo__no-results'>
          There's nothing to do here for now
        </div>
      )}

        {demos.filter(
            (demo) =>
              demo.demo_status === 'Completed' && demo.feedback_count === '0'
          ).map((item, index) => (
            <div key={index} className=''>
              <div className='dashboarddemo'>
                <div style={{display: 'flex', alignItems: 'start', padding: '20px'}}>
                {/* <div className='buyerdashboard__recommendation-thumbnail' style={{width: '400px'}}>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                  )}
                </div> */}

<div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
                
                <div className='buyerdashboard__recommendation-name'>
                  <div style={{fontSize: '20px'}}>{item.company_name}</div>

                  <div style={{fontSize: '12px'}}>
                    <strong>Date scheduled: </strong>
                        <span className='dashboarddemo__company-info-label'>
                          <span>
                          {
                            weekDays[
                              new Date(item.demo_date).getDay()
                            ]
                          }{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          <br />
                          at{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          {' - '}
                          {moment.tz.guess()}
                          </span>
                        </span>
                  </div>

                  <div className='buyerdashboard__recommendation-description' style={{fontSize: '12px'}}>
                    {item.description && item.description.length > 0 && (
                      <>
                        {getTrimmedString(item.description)}
                      </>
                    )}
                  </div>
                </div>
                </div>

                  {/* <div className='dashboarddemo__company-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div> */}

                  {/* <div className='dashboarddemo__company-info'>
                    <div className='dashboarddemo__company-name'>
                      {item.company_name}
                    </div>

                    <div className='dashboarddemo__company-info-items'>
                    <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          <span>Date Scheduled:{' '}</span>
                          <span>
                          {
                            weekDays[
                              new Date(item.demo_date).getDay()
                            ]
                          }{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          <br />
                          at{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          {' - '}
                          {moment.tz.guess()}
                          </span>
                        </span>
                      </div>
                    </div>

                    {item.description && (
                      <div className='dashboarddemo__company-description'>
                        <div className='dashboarddemo__company-description-text'>
                          {trimCompanyInfo(item.description)}
                        </div>
                      </div>
                    )}

                    {item.description && item.description.length > 0 && (
                      <div>
                        <span className='link__dash' onClick={() => companyInfoClickHandle(item) }>
                          More about this company
                        </span>
                      </div>
                    )}
                  </div> */}

                  <div className='dashboarddemo__date' style={{display:'none'}}>
                    <strong>Scheduled for:</strong>
                      {item.reschedule_date_time && (
                        <>
                          <div className=''>
                            <>
                              {new Date(item.reschedule_date_time).toLocaleDateString()}{' '}
                              at{' '}
                              {new Date(item.reschedule_date_time).toLocaleTimeString()}
                            </>
                          </div>

                          <div className='dashboarddemo__date-timezone'>
                            {item.timezone}
                          </div>
                        </>
                      )}

                      {!item.reschedule_date_time && (
                        <>
                          <div className=''>
                            <>
                              {new Date(item.scheduled_date_time).toLocaleDateString()}{' '}
                              at{' '}
                              {new Date(item.scheduled_date_time).toLocaleTimeString()}
                            </>
                          </div>

                          <div className='dashboarddemo__date-timezone'>
                            {item.timezone}
                          </div>
                        </>
                      )}

                      <div className='form-group'>
                        {item.company_meeting_link && (
                          <a className='link' href={`${item.company_meeting_link}`} target='_blank'>
                          Need to rejoin meeting?
                        </a>
                        )}

                        {!item.company_meeting_link && (
                          <>
                          <a className='link' href={`https://meetings.leadrpro.com/demo?refid=${item.reference_id}`} target='_blank'>
                            Need to rejoin meeting?
                          </a>
                          </>
                        )}
                      </div>
                    </div>

                  <div className='dashboarddemo__actions' style={{padding: '20px'}}>
                    <div className='dashboarddemo__actions-text'>
                      Once you have completed your demo, please leave feedback
                    </div>

                    <button className='btn btn--primary btn--apply' onClick={() => leaveFeedbackClickHandle(item.demo_id, item)}>
                      Leave Feedback
                    </button>
                  </div>
              </div>
            </div>
          ))}
    </>
  )
}

export default BuyerDemosIncomplete;