import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import futureDemoNoticeCalendar from '../../assets/design/dashboard/future-demo-notice-calendar.png';
import LeadCard from '../../components/qualifier/LeadCard';
import RescheduleConfirmation from '../../components/qualifier/RescheduleConfirmation';
import DeclinedPopup from './DeclinedPopup';
import CalendarLinks from '../../components/qualifier/CalendarLinks';
import {sellerReschedule, getSellerDeclinedReasons, addDemoNoShow, refreshToken, getTeamDemosUpcoming, getMeetingLinkStatus, addDemoMeetingLink, removeMeetingLink, getSellerRole, getDemoQualifierAnswers} from '../../utils/api';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { checkSession } from '../../utils/utils';
import ReassignDemo from './ReassignDemo';
import FadeIn from '../../utils/fadein';
import React from 'react'
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaShapes, FaEnvelope, FaVideo, FaEllipsisV, FaRegEnvelope, FaFireAlt, FaTimes, FaLinkedinIn, FaLinkedin } from "react-icons/fa";
import iconCompanyDefault from '../../assets/companylogos/default.png';
import Spinner from '../../components/layout/Spinner';
import moment from 'moment-timezone';

const SellerDemosUpcoming = ({ setUpcomingDemosCount }) => {
  const [showFutureDemoNotice, setShowFutureDemoNotice] = useState(false);
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [demoId, setDemoId] = useState(0);
  const [currentDemo, setCurrentDemo] = useState({});
  const [showReschedule, setShowReschedule] = useState(false);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const [showRescheduleConfirmation, setShowRescheduleConfirmation] = useState(false);
  const [selectedDemo, setSelectedDemo] = useState({});
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);
  const [showNoShow, setShowNoShow] = useState(false);
  const [meetingLinkEnabled, setMeetingLinkEnabled] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [showCustomMeetingLink, setShowCustomMeetingLink] = useState(false);
  const [showCustomMeetingLinkConfirm, setShowCustomMeetingLinkConfirm] = useState(false);
  const [showUpdateCustomMeetingLink, setShowUpdateCustomMeetingLink] = useState(false);
  const [customMeetingLink, setCustomMeetingLink] = useState('');
  const [meetingLinkType, setMeetingLinkType] = useState(1); // default
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  let refMeetingLink = React.createRef();

  async function handleToggleMenu(item){
    if (item.demo_id !== currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(true);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }
  }

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';
    document.cookie =
      cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  async function checkAdminStatus(){
    let res = await getSellerRole();

    if (res.success){
      if (res.data == '1'){
        setIsAdmin(true);
      }
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getSellerRole();

        if (res.success){
          if (res.data == '1'){
            setIsAdmin(true);
          }
        }
      }
    }
  }

  useEffect(async () => {
    let res = await getMeetingLinkStatus();

    if (res.success){
      if (res.data == 'Enabled')
        setMeetingLinkEnabled(true);
      else
        setMeetingLinkEnabled(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getMeetingLinkStatus();

      if (res.success){
        if (res.data == 'Enabled')
          setMeetingLinkEnabled(true);
        else
          setMeetingLinkEnabled(false);
      }
    }

    else{
      setMeetingLinkEnabled(false);
    }

    checkAdminStatus();
    checkSession('Seller Upcoming');
  }, []);

  useEffect(() => {
    async function fetchSellerDeclinedReasons() {
      let data = await getSellerDeclinedReasons();
      if (data.success) {
        setSellerDeclinedReasons(data.data);
      }
    }

    fetchSellerDeclinedReasons();
  }, []);

  async function loadTeamDemos(){
    let res = await getTeamDemosUpcoming();

    if (res.success){
      setDemos(res.data);
      setLoaded(true);
      setUpcomingDemosCount(res.data.length);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getTeamDemosUpcoming();

      if (res.success){
        setDemos(res.data);
        setUpcomingDemosCount(res.data.length);
      }

      setLoaded(true);
    }
  }

  useEffect(() => {
    loadTeamDemos();
  }, []);

  //date and time formatting
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  function futureDemoNoticeClick(demo, id) {
    setShowFutureDemoNotice(!showFutureDemoNotice);
    setDemoId(id);
    setCurrentDemo(demo);
    setShowSubMenu(false);
  }

  function handleNeedsReviewClick(demo) {
    setCurrentDemo(demo);
    setShowRescheduleConfirmation(true);
  }

  function declinedClickHandle(demo, id) {
    setShowCancelConfirm(true);
    setCurrentDemo(demo);
    setShowSubMenu(false);
    //setShowDeclinedPopup(true);
  }

  function handleRescheduleClick(item) {
    setShowCancelConfirm(false);
    setSelectedDemo(item);
    setShowFutureDemoNotice(false);
    setShowReschedule(true);
    setShowSubMenu(false);
  }

  async function confirmReschedule() {
    await sellerReschedule(currentDemo.demo_id);

    let _filter = demos.filter((tmp) => tmp.demo_id !== currentDemo.demo_id);
    let _obj = Object.assign({}, currentDemo);
    _obj.seller_status = 'Rescheduled';
    _filter.push(_obj);
    setDemos(_filter);
    setShowReschedule(false);
  }

  async function handleAnswersClick(item) {
    let res = await getDemoQualifierAnswers(item.demo_id);

    if (res.success){
      let new_item = {...item, qualifier_answers: res.data};
      setCurrentDemo(new_item);
      setShowQualifierAnswers(true);
      setShowSubMenu(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getDemoQualifierAnswers(item.demo_id);

      if (res.success){
        let new_item = {...item, qualifier_answers: res.data};
        setCurrentDemo(new_item);
        setShowQualifierAnswers(true);
        setShowSubMenu(false);
      }
    }
  }

  async function handleReassignClick(demo) {
    setCurrentDemo(demo);
    setShowReassign(true);
    setShowSubMenu(false);
  }

  async function showUpdateMeetingLink(){
    setShowUpdateCustomMeetingLink(true);
  }

  async function handleUseDefaultMeetingLink(){
    setMeetingLinkType(1);
    setShowUpdateCustomMeetingLink(false);
  }

  async function handleUseCustomMeetingLink(){
    setMeetingLinkType(2);
    showUpdateMeetingLink(true);
  }

  async function saveCustomMeetingLink(){
    if (meetingLinkType === 1){
      // remove custom links
      let res = await removeMeetingLink(currentDemo.demo_id);

      if (res.success){
        setShowCustomMeetingLink(false);
        setShowCustomMeetingLinkConfirm(true);
        setShowUpdateCustomMeetingLink(false);

        const updatedItems = demos.map(item => {
          if (item.demo_id === currentDemo.demo_id) {
            // Return a new object with the updated property
            return { ...item, demo_meeting_link: '' };
          }
          return item; // Return the item as is
        });

        setDemos(updatedItems);
      }

      else if (res.status === 403){
        await refresh();

        let res = await removeMeetingLink(currentDemo.demo_id);

        if (res.success){
          setShowCustomMeetingLink(false);
          setShowCustomMeetingLinkConfirm(true);
          setShowUpdateCustomMeetingLink(false);

          const updatedItems = demos.map(item => {
            if (item.demo_id === currentDemo.demo_id) {
              // Return a new object with the updated property
              return { ...item, demo_meeting_link: '' };
            }
            return item; // Return the item as is
          });
  
          setDemos(updatedItems);
        }
      }
    }

    else{
      // custom
      let res = await addDemoMeetingLink(currentDemo.demo_id, customMeetingLink);

      if (res.success){
        setShowCustomMeetingLink(false);
        setShowCustomMeetingLinkConfirm(true);
        setShowUpdateCustomMeetingLink(false);

        const updatedItems = demos.map(item => {
          if (item.demo_id === currentDemo.demo_id) {
            // Return a new object with the updated property
            return { ...item, demo_meeting_link: customMeetingLink };
          }
          return item; // Return the item as is
        });

        setDemos(updatedItems);
      }

      else if (res.status === 403){
        await refresh();

        let res = await addDemoMeetingLink(currentDemo.demo_id, customMeetingLink);

        if (res.success){
          setShowCustomMeetingLink(false);
          setShowCustomMeetingLinkConfirm(true);
          setShowUpdateCustomMeetingLink(false);

          const updatedItems = demos.map(item => {
            if (item.demo_id === currentDemo.demo_id) {
              // Return a new object with the updated property
              return { ...item, demo_meeting_link: customMeetingLink };
            }
            return item; // Return the item as is
          });
  
          setDemos(updatedItems);
        }
      }
    }
  }

  async function handleShowMeetingLink(item, link, type){
    setCurrentDemo(item);
    setCustomMeetingLink(link);
    setShowCustomMeetingLink(true);
    setMeetingLinkType(type);

    if (type === 2){
      setShowUpdateCustomMeetingLink(true);
    }

    else{
      setShowUpdateCustomMeetingLink(false);
    }
  }

  return (
    <>
      <div>
        {showFutureDemoNotice && (
          <>
            <div className='overlay'></div>
            <div className='modal dashboarddemo__modal'>
              <div className='modal__close' onClick={futureDemoNoticeClick}>
                <FaTimes />
              </div>

              <div className='center'>
                <div style={{ marginBottom: '10px', fontSize: '30px' }}>
                  <FaRegCalendarAlt />
                </div>

                <p>Your meeting is scheduled for</p>

                <div className='dashboarddemo__join-meeting-date'>
                  {!currentDemo.reschedule_date_time && (
                    <>
                      <div style={{ marginBottom: '10px' }}>
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}

                        <div>{moment.tz.guess()}</div>
                      </div>

                      <CalendarLinks
                        scheduled_date_time={currentDemo.scheduled_date_time}
                        persona_type={currentDemo.company_name}
                        reference_id={currentDemo.reference_id}
                      />
                    </>
                  )}

                  {currentDemo.reschedule_date_time && (
                    <>
                      <div>
                        {new Date(
                          currentDemo.reschedule_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.reschedule_date_time
                        ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}

                        <div>{moment.tz.guess()}</div>
                      </div>

                      <CalendarLinks
                        scheduled_date_time={currentDemo.reschedule_date_time}
                        persona_type={currentDemo.company_name}
                        reference_id={currentDemo.reference_id}
                        meeting_link={currentDemo.company_meeting_link}
                      />
                    </>
                  )}
                </div>

                <div style={{ marginTop: '10px' }}>
                  <div className='modal__header'>House Rules</div>

                  <div className='dashboarddemo__house-rules'>
                    <div>
                      * Be on time and on a computer with a functional webcam
                    </div>
                    <div>
                      * Do not reschedule within 24 hours of the demo time
                    </div>
                  </div>
                </div>

                <div>
                  Need to{' '}
                  <span
                    className='link'
                    onClick={() => handleRescheduleClick(currentDemo)}>
                    Reschedule
                  </span>
                  ?
                </div>

                {/* <div style={{ marginTop: '40px' }}>
                  {currentUser.registered_user_id == currentDemo.seller_id && (
                  <div className='dashboarddemo__send-message'>
                    <div style={{marginTop:"10px"}}>
                    <a href={'/dashboard/message/' + currentDemo.reference_id} className='btn btn--primary'>
                    Send a message
                  </a>
                  </div>
                  </div>
                  )}
                </div> */}

                <div className='center' style={{ marginTop: '20px' }}>
                  <div><strong>Your Meeting URL</strong></div>
                  {currentDemo.company_meeting_link && (
                    <a
                      className='btn btn--primary btn--apply'
                      href={`${currentDemo.company_meeting_link}`}
                      target='_blank'>
                      {`${currentDemo.company_meeting_link}`}
                    </a>
                  )}

                  {!currentDemo.company_meeting_link && (
                    <a
                      className='link'
                      href={`https://meetings.leadrpro.com/demo?refid=${currentDemo.reference_id}`}
                      target='_blank'>
                      {`https://meetings.leadrpro.com/demo?refid=${currentDemo.reference_id}`}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {loaded === false && (
          <Spinner />
        )}

        {demos.length === 0 && loaded === true && (
          <div className='dashboarddemo__no-results'>
            You have no upcoming demos.
          </div>
        )}

        {loaded == true && demos.length > 0 && (
        <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
        <tr>
            <th></th>
            <th>Company</th>
            <th>Interest</th>
            <th>Date scheduled</th>
            <th>Assigned</th>
            <th></th>
          </tr>
        {demos
          .map((item, index) => (
            <tr key={index}>
              <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              </td>

              <td>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>
                      </div>
                    )}

                    <div className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </div>

                    {(item.seller_company_id == "4520" || item.seller_company_id == '5105' || item.seller_company_id == "375") && (
                      <div className='' style={{fontSize: '12px', marginTop: '10px', background: '#d9ecff', padding: '2px 10px', borderRadius: '10px', fontWeight: 'normal', display: 'inline-block'}}>
                        <span className='' style={{marginRight: '3px'}}><FaRegEnvelope /> </span>
                        {item.buyer_work_email && (
                          <span>
                            {item.buyer_work_email}
                          </span>
                        )}

                        {!item.buyer_work_email && (
                          <span>
                            {item.buyer_email}
                          </span>
                        )}
                      </div>
                    )}

                    {item.linkedin_url && (
                      <div className='dashboard__linkedin-url'>
                        
                        <FaLinkedin /> <span className='ml-5'><a href={item.linkedin_url.startsWith('http')
                          ? item.linkedin_url
                          : 'https://' + item.linkedin_url
                      } target='_blank' className='link' rel='noreferrer'>
                        LinkedIn Profile
                        </a>
                        </span>
                      </div>
                    )}
                  </div>
                </td>

                <td>
                    {item.demo_interest === 'Very interested' && (
                        <div className='dashboarddemo__very-interested'>
                          <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                          <span className='dashboarddemo__interest-label'>Very interested</span></div>
                      )}
                      
                      {item.demo_interest === 'Somewhat interested' && (
                        <div className='dashboarddemo__somewhat-interested'>
                        <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                        <span className='dashboarddemo__interest-label'>Moderate</span></div>
                      )}

                      {/* {item.demo_interest === 'Just researching' && (
                        <span className='dashboarddemo__just-researching'>Researching</span>
                      )} */}
                    </td>

                <td>
                  <div className='dashboarddemo__company-info-label'>
                          <span onClick={() =>
                                futureDemoNoticeClick(item, item.demo_id)} style={{color: 'black', textDecoration: 'underline', cursor: 'pointer'}}>
                          {
                            weekDays[
                              new Date(item.demo_date).getDay()
                            ]
                          }{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          <br />
                          at{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          {' - '}
                          {moment.tz.guess()}
                          </span>
                        </div>

                    {meetingLinkEnabled && (
                      <div style={{marginTop: '15px', color: 'black', fontWeight: 'bold', fontSize: '12px'}}>
                        <span className='dashboarddemo__company-info-label'>
                          <FaVideo /> Meeting Link:{' '}
                        </span>

                        <span>
                          {item.demo_meeting_link && item.demo_meeting_link.length > 0 && (
                            <span className='link' style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleShowMeetingLink(item, item.demo_meeting_link, 2)}>{item.demo_meeting_link} {'(Edit)'}</span>
                          )}

                          {(!item.demo_meeting_link || item.demo_meeting_link.length === 0) && (
                            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleShowMeetingLink(item, item.demo_meeting_link, 1)}>
                              <span>Edit Meeting URL</span>
                            </span>
                          )}
                        </span>
                      </div>
                      )}

                      {meetingLinkEnabled === false && (
                        <div className='' style={{ marginTop: '20px', fontSize: '12px' }}>
                        <div><strong>Your Meeting URL:</strong></div>
                        {item.company_meeting_link && (
                          <a
                            className='btn btn--primary btn--apply'
                            href={`${item.company_meeting_link}`}
                            target='_blank'>
                            {`${item.company_meeting_link}`}
                          </a>
                        )}
      
                        {!item.company_meeting_link && (
                          <a
                            className='link'
                            href={`https://meetings.leadrpro.com/demo?refid=${item.reference_id}`}
                            target='_blank'>
                            {`https://meetings.leadrpro.com/demo?refid=${item.reference_id}`}
                          </a>
                        )}
                      </div>
                      )}
                </td>

                <td>
                  <div className='center'>
                    <div>
                      <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                      {item.seller_profile_image && item.seller_profile_image != '' && (
                        <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                      )}

                      {!item.seller_profile_image && (
                        <img src={iconCompanyDefault} alt="logo not found" />
                      )}
                      </div>
                      <div style={{fontSize: "12px"}}>
                        {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                      </div>
                    </div>
                  </div>
                </td>

              <td>
                  <div style={{position: 'relative'}}>
                    <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div onClick={() =>
                                futureDemoNoticeClick(item, item.demo_id)
                              } className='dashboard__submenu-item'>Meeting details</div>

                      <div className='dashboard__submenu-item' onClick={() => handleAnswersClick(item)}>Buyer Details</div>

                      {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                      )}

                      <div onClick={() => handleReassignClick(item)} className='dashboard__submenu-item'>Reassign</div>

                      <div onClick={() => handleRescheduleClick(item)} className='dashboard__submenu-item'>Reschedule</div>

                      <div onClick={() => declinedClickHandle(item, item.demo_id)} className='dashboard__submenu-item'>Cancel demo</div>
                    </div>
                    </FadeIn>
                    )}
                    </div>
                  </td>

              <div className='' key={index} style={{display:'none'}}>
                <div className='dashboarddemo'>
                  {/* <div className='dashboarddemo__company-logo'>
                    <img
                      src={
                        imageUrl +
                        'tr:di-@@companylogos@@default-buyer.png/' +
                        item.logo
                      }
                    />
                  </div> */}

                  <div className='dashboarddemo__company-info'>
                    {/* <div className='dashboarddemo__company-name'>
                      {item.first_name && item.first_name.length > 0 && (
                        <div className='dashboarddemo__name'>
                          {item.first_name} {item.last_name}
                        </div>
                      )}

                    <span className=''>
                          {item.job_title && item.job_title.length > 0 && (
                            <span>{item.job_title}</span>
                          )}
                          
                          {!item.job_title && (
                            <span>{item.persona_type}</span>
                          )} at {item.company_name}
                        </span>
                    </div> */}

                    <div className='dashboarddemo__company-info-items'>
                    {/* <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          <span>Date Scheduled:{' '}</span>
                          <span onClick={() =>
                                futureDemoNoticeClick(item, item.demo_id)} style={{color: 'black', textDecoration: 'underline', cursor: 'pointer'}}>
                          {
                            weekDays[
                              new Date(item.demo_date).getDay()
                            ]
                          }{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          <br />
                          at{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          {' - '}
                          {moment.tz.guess()}
                          </span>
                        </span>
                      </div> */}

                      {/* {(item.seller_company_id == "12874" || item.seller_company_id == "12621" || item.seller_company_id == "3214" || item.seller_company_id == "375"  || item.seller_company_id == "5105") && (
                        <>
                        <div className='dashboarddemo__company-info-item'>
                          <span className='dashboarddemo__company-info-label'>
                            Contact email:{' '}
                          </span>

                            <span style={{fontSize: "12px", color: "black", marginLeft: "10px", fontWeight: "normal", letterSpacing: "1.2px"}}>
                              {item.buyer_work_email && (
                                <>
                                  {item.buyer_work_email}
                                </>
                              )}

                              {!item.buyer_work_email && (
                                <>
                                  {item.buyer_email}
                                </>
                              )}
                            </span>
                            </div>
                          </>
                          )} */}

                      {/* <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Seniority level:{' '}
                        </span>
                        <span>
                          {item.seniority_level}
                        </span>
                      </div>

                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Department:{' '}
                        </span>
                        <span>
                          {item.department_name}
                        </span>
                      </div> */}

                      {/* <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Industry:{' '}
                        </span>
                        <span>{item.industry_name}</span>
                      </div>

                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Company size:{' '}
                        </span>
                        <span>{item.company_size}</span>
                      </div> */}

                      {/* {item.website && (
                        <div className='dashboarddemo__company-info-item'>
                          <span className='dashboarddemo__company-info-label'>
                            Website:{' '}
                          </span>
                          <span>
                          <a href={item.website.startsWith('http') ? item.website : 'https://' + item.website} target="_blank" rel="nofollow" style={{textDecoration: "underline"}}>{item.website}</a>
                          </span>
                        </div>
                      )} */}

                      {/* {meetingLinkEnabled && (
                      <div className='dashboarddemo__company-info-item' style={{marginTop: '15px', color: 'black', fontWeight: 'bold'}}>
                        <span className='dashboarddemo__company-info-label'>
                          <FaVideo /> Meeting Link:{' '}
                        </span>

                        <span>
                          {item.demo_meeting_link && item.demo_meeting_link.length > 0 && (
                            <span className='link' style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleShowMeetingLink(item, item.demo_meeting_link, 2)}>{item.demo_meeting_link} {'(Edit)'}</span>
                          )}

                          {(!item.demo_meeting_link || item.demo_meeting_link.length === 0) && (
                            <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => handleShowMeetingLink(item, item.demo_meeting_link, 1)}>
                              <span>Edit Meeting URL</span>
                            </span>
                          )}
                        </span>
                      </div>
                      )} */}
                    </div>
                  </div>

                  <div className='dashboarddemo__date'>
                    {/* {item.reschedule_date_time && (
                      <>
                        <div className='center'>
                        <div style={{marginBottom: "10px"}}>Assigned to</div>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>

                          <div style={{fontSize: "12px", cursor: 'pointer', textDecoration: 'underline'}} onClick={() => handleReassignClick(item)}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                      </>
                    )} */}

                    {/* {!item.reschedule_date_time && (
                      <>
                        <div className='center'>
                        <div style={{marginBottom: "10px"}}>Assigned to</div>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                      </>
                    )} */}
                  </div>

                  <div className='dashboarddemo__actions' style={{display: 'none'}}>
                    {item.buyer_status !== 'Rescheduled' &&
                      item.seller_status !== 'Rescheduled' && (
                        <div>
                            <button
                              className='btn btn--primary'
                              onClick={() =>
                                futureDemoNoticeClick(item, item.demo_id)
                              }>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{fill: "white", height: "15px", verticalAlign: "middle"}}><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg> <span style={{verticalAlign: "middle", marginLeft: "7px"}}>Meeting Details</span>
                            </button>
                        </div>
                      )}

                    <div className='dashboarddemo__actions-links'>
                        <div
                          className='btn btn--primary'
                          onClick={() => handleAnswersClick(item)}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 576 512'
                            style={{ height: '15px', verticalAlign: 'middle', fill: "white" }}>
                            <path d='M208 256c35.35 0 64-28.65 64-64c0-35.35-28.65-64-64-64s-64 28.65-64 64C144 227.3 172.7 256 208 256zM464 232h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 232 464 232zM240 288h-64C131.8 288 96 323.8 96 368C96 376.8 103.2 384 112 384h192c8.836 0 16-7.164 16-16C320 323.8 284.2 288 240 288zM464 152h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 152 464 152zM512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416z' />
                          </svg>{' '}
                          <span style={{ verticalAlign: 'middle' }}>
                            Buyer info
                          </span>
                        </div>

                      <div className='center' style={{marginTop: "10px"}}>
                            <div className='btn btn--primary' onClick={() => handleReassignClick(item)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{height: "15px", fill: "white", verticalAlign: "middle"}}><path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg> <span style={{marginLeft: "7px", verticalAlign: "middle"}}>Reassign</span></div>
                          </div>

                          {(currentUser.registered_user_id == item.seller_id) && (
                          <div className='center' style={{marginTop: "10px"}}>
                            <Link to={`/dashboard/message/${item.reference_id}`}
                              className='btn btn--primary'
                              onClick={() => declinedClickHandle(item, item.demo_id)}>
                              <span><FaEnvelope /></span> Send Message
                            </Link>
                          </div>
                          )}

                          {(currentUser.registered_user_id == item.seller_id || isAdmin) && (
                          <div className='center' style={{marginTop: "10px"}}>
                            <div
                              className='btn btn--primary btn--cancel-demo'
                              onClick={() => declinedClickHandle(item, item.demo_id)}>
                              Cancel Demo
                            </div>
                          </div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          ))}
          </table>
          </div>
        )}
      </div>

      {showDeclinedPopup && (
        <div>
          <DeclinedPopup
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Seller'
            companyName={currentDemo.company_name}
            sellerDeclinedReasons={sellerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
            setShowReschedule={setShowReschedule}
          />
        </div>
      )}

      {showRescheduleConfirmation && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{height: "70%", overflow: "auto"}}>
          <RescheduleConfirmation
            currentDemo={currentDemo}
            demos={demos}
            setDemos={setDemos}
            setShowRescheduleConfirmation={setShowRescheduleConfirmation}
            remove_when_done={false}
          />
          </div>
        </>
      )}

      {showReschedule && (
        <>
          <FadeIn delay={150} duration={200}>
            <div className='overlay'></div>

            <div className='modal'>
              <div>
                <div>
                  <div className='dashboarddemo__company-logo--small'>
                    <img
                      src={
                        imageUrl +
                        'tr:di-@@companylogos@@default-buyer.png/' +
                        currentDemo.logo
                      }
                    />
                  </div>
                  <div>
                      <strong>{currentDemo.company_name}</strong>
                  </div>
                  </div>
                <div>
                  {new Date(currentDemo.demo_date).toLocaleDateString()}{' '}{new Date(currentDemo.demo_date).toLocaleTimeString()}
                </div>
              </div>

              <div className='modal__body-small mt-10'>
                Are you sure you want to reschedule this demo? The original date
                will no longer be valid and the other participant will be
                required to submit new available times.
              </div>

              <div className='center mt-10'>
                <button
                  className='btn btn--primary btn--small'
                  onClick={() => confirmReschedule()}>
                  Confirm
                </button>
              </div>

              <div className='modal__close' onClick={() => setShowReschedule(false)}>
                <FaTimes />
              </div>
            </div>
          </FadeIn>
        </>
      )}

      {showReassign === true && (
        <>
          <div className='overlay'></div>
          <div className='modal dashboard__modal-reassign'>
            <div className='modal__close' onClick={() => setShowReassign(false)}>
              <FaTimes />
            </div>
            <ReassignDemo
              demo_id={currentDemo.demo_id}
              demos={demos}
              setDemos={setDemos}
              refresh={loadTeamDemos}
            />

            <div className='center'>
              <span
                className='link link--skip'
                onClick={() => setShowReassign(false)}>
                Close
              </span>
            </div>
          </div>
        </>
      )}

      {showCustomMeetingLink && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close'>
              <img src={close} onClick={() => setShowCustomMeetingLink(false)} />
            </div>

            <h2 className='modal__h2'>Demo Meeting Link</h2>

            <div>
              Choose where you would like to host this demo.
            </div>

            <div style={{marginTop: "20px"}}>
              <div>
                  <input type='radio' ref={refMeetingLink} id='rb1' name='customlink' onClick={() => handleUseDefaultMeetingLink()} checked={meetingLinkType === 1} /><label htmlFor='rb1' style={{marginLeft: "10px"}}>Use the LeadrPro Platform</label>
              </div>

              <div>
                    <input type='radio' ref={refMeetingLink} id='rb2' name='customlink' onClick={() => handleUseCustomMeetingLink()} checked={meetingLinkType === 2} /><label htmlFor='rb2' style={{marginLeft: "10px"}}>Use Custom Meeting Link</label>
              </div>

              {showUpdateCustomMeetingLink && (
                <>
                <div>
                  <input type='text' className='input' value={customMeetingLink} onChange={(e) => setCustomMeetingLink(e.target.value)} placeholder='Custom meeting link (Google Meets, Zoom, etc...)' />
                  </div>
                  </>
              )}

              
<div style={{marginTop: "10px"}}>
                  <button className='btn btn--primary btn--small' onClick={() => saveCustomMeetingLink()}>Save</button>
                  </div>
            </div>
          </div>
        </>
      )}

      {showCustomMeetingLinkConfirm && (
        <>
          <div className='overlay'></div>

          <div className='modal'>
            <div className='modal__close' onClick={() => setShowCustomMeetingLinkConfirm(false)}>
              <img src={close} />
            </div>

            <h2 className='modal__h2'>Success!</h2>
            <div>
              The meeting link has been updated for this demo.
            </div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => setShowCustomMeetingLinkConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}

      {showCancelConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowCancelConfirm(false)}>
              <FaTimes />
            </div>

            <div>
              <h2 className='modal__h2'>Cancel this demo?</h2>

              <p className='modal__body-small' style={{marginTop: "10px", fontSize: '14px'}}>
                You will not be able to book again with this person. Please note that per <a className='link' href={'/policies'} target='_blank'>our policy</a>, there is a $25 fee for cancelling any actively scheduled meetings. If you'd prefer to reschedule instead, <span className='link' onClick={() => handleRescheduleClick(currentDemo)}>click here</span>
              </p>

              <p className='center' style={{marginTop:"20px"}}>
                <button className='btn btn--primary btn--danger' style={{marginLeft: "10px"}} onClick={() => {setShowDeclinedPopup(true);
                  setShowCancelConfirm(false)}}>Yes, I want to cancel this demo</button>
              </p>
            </div>
          </div>
        </>
      )}

      {showQualifierAnswers && (
          <>
            <div className='overlay'></div>
            <LeadCard
              currentDemo={currentDemo}
              setShowQualifierAnswers={setShowQualifierAnswers}
              show_buttons={false}
            />
          </>
        )}
    </>
  );
};

export default SellerDemosUpcoming;
