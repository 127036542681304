import './LinkedIn.css';
import React, {useState, useEffect} from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkLinkedinAccessToken } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';

const LinkedIn = () => {
  const [loaded, setLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [action, setAction] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';

    if (process.env.REACT_APP_NODE_ENV === 'Dev'){
        _domain = 'domain=leadrpro-client-staging.vercel.app';
    }

    document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  useEffect(() => {
    async function tokenCheck(){
      const clientSecret = new URLSearchParams(window.location.search).get('code');
    
      if (clientSecret && clientSecret.length > 0){
        let res = await checkLinkedinAccessToken(clientSecret);

        if (res.success){
          setIsValid(true);

          if (res.action === 'newuser'){
            setAction('Redirecting...');
            setToken(res.token);
            setShowPrompt(true);
            //navigate(`/account-setup?token=${res.token}`);
          }

          else if (res.action === 'login'){
            setAction('Signing in...');
            let tokens = res.data;

            localStorage.setItem('access_token', tokens.accessToken);
            localStorage.setItem('refresh_token', tokens.refreshToken);

            // 5/2/2022 - used in meeting.leadrpro
            setCookie('access_token', tokens.accessToken, 1);
            window.location = '/dashboard/profile';
          }
        }

        else{
          setIsValid(false);
          alert('Could not authenticate');
        }

        setLoaded(true);
      }
    }

    tokenCheck();
  }, []);

  return (
    <>
      <div className='linkedin'>
        {loaded === false && (
          <h2 className='linkedin__h2'>Authorizing LinkedIn...</h2>
        )}

        {loaded === false && (
            <Spinner />
        )}

        {loaded && isValid === true && (
          <>
            <h2 className='linkedin__h2'>{action}</h2>
          </>
        )}

        {loaded && isValid === false && (
          <>
            <h2 className='linkedin__h2'>Authorization Failed</h2>

            <div className='linkedin__links'>
              <div className='linkedin__link'>
                <a href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86cramxaw66v3z&redirect_uri=http%3A%2F%2Flocalhost:3000%2Flinkedin%2Fredirect&state=foobar&scope=r_liteprofile%20r_emailaddress' target='_blank'>Try Again</a>
              </div>

              <div className='linkedin__link'>
                <Link to='/signup'>Sign up using email</Link>
              </div>

              <div className='linkedin__link'>
                <Link to='/login'>Login using email</Link>
              </div>
            </div>
          </>
        )}
      </div>

      {showPrompt && (
            <>
                <div className='overlay'></div>
                <div className='modal modal__prompt'>
                    <h2 className='modal__h2 modal__prompt-h2'>I'm here to</h2>
                    <div className='createaccount__prompt'>
                        <div className='createaccount__prompt-seller' onClick={() => navigate(`/account-setup-s?token=${token}`)}>
                            <div className="center">
                                <img src="https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/Group_1586_ED1sTvO7i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664426118091" />
                            </div>
                            <div><strong>Sell Software</strong></div>
                            <div className='createaccount__prompt-description'>
                                I want to create my company's profile and be added to the marketplace.
                            </div>
                        </div>

                        <div className='createaccount__prompt-buyer' onClick={() => navigate(`/account-setup?token=${token}`)}>
                        <div className="center">
                                <img src="https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/getpaid_ng-V9AArt.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664425911129" />
                            </div>
                            <div><strong>Discover Software</strong></div>

                            <div className='createaccount__prompt-description'>
                                I want to research and discover new software.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>
  )
};

export default LinkedIn;