import './AccountHome.css';
import {useEffect, useState} from 'react';
import {refresh, isBuyer, isSeller} from '../../services/auth.service';
import { getBuyerWorkspace, getSellerWorkspace, getRoles, withdraw } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { FaComment, FaUser, FaChartPie, FaRegEnvelope, FaUserCog, FaRegComments, FaLink, FaShapes, FaRegCheckCircle, FaDollarSign, FaCalendarCheck, FaCalendarAlt, FaPhoneAlt, FaPhone, FaMobile, FaMobileAlt, FaCogs, FaCog, FaUsers, FaTimes, FaCheck, FaListAlt, FaExclamationCircle } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/layout/ScrollToTop';

const AccountHome = () => {
  const [currentView, setCurrentView] = useState('');
  const [workspace, setWorkspace] = useState(null);
  const [sellerWorkspace, setSellerWorkspace] = useState(null);
  const [workspaceLoaded, setWorkspaceLoaded] = useState(false);
  const [sellerWorkspaceLoaded, setSellerWorkspaceLoaded] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [submittingWithdraw, setSubmittingWithdraw] = useState(false);
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isBuyer, setIsBuyer] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [loading ,setLoading] = useState(true);
  const navigate = useNavigate();

  async function loadWorkspace(){
    setLoading(true);
    let res = await getBuyerWorkspace();

    if (res.success){
      setWorkspace(res.data);
      setWorkspaceLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getBuyerWorkspace();

      if (res.success){
        setWorkspace(res.data);
        setWorkspaceLoaded(true);
      }
    }

    setLoading(false);
  }

  async function loadSellerWorkspace(){
    setLoading(true);
    let res = await getSellerWorkspace();

    if (res.success){
      setSellerWorkspace(res.data);
      setSellerWorkspaceLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getSellerWorkspace();

      if (res.success){
        setSellerWorkspace(res.data);
        setSellerWorkspaceLoaded(true);
      }
    }

    setLoading(false);
  }

  async function loadRoles(){
    let res = await getRoles();

    if (res.success){
      setRoles(res.data);
      let data = res.data;
      setIsBuyer(data.filter(x=>x.role_type_id === 1).length > 0);
      setIsSeller(data.filter(x => x.role_type_id === 2).length > 0);
    }

    else if (res.status === 403){
      await refresh();
      let res=  await getRoles();
      if (res.success){
        setRoles(res.data);
        let data = res.data;
        setIsBuyer(data.filter(x=>x.role_type_id === 1).length > 0);
        setIsSeller(data.filter(x => x.role_type_id === 2).length > 0);
      }
    }
  }

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (roles.length > 0){
      let ls = localStorage.getItem('current_view');
      //alert(ls);

      if (roles.filter(x=>x.role_type_id === 1).length > 0){
        if (ls == 'Buyer' || ls == '' || ls == null)
          setCurrentView('Buyer');

        else if (ls == 'Seller'){
          setCurrentView('Seller');
        }
      }

      else if (roles.filter(x => x.role_type_id === 2).length > 0){
        if (ls == 'Seller' || ls == '' || ls == null);
          setCurrentView('Seller');
      }
    }
  }, [roles]);

  useEffect(() => {
    // reload appropriate workspace data
    if (currentView === 'Buyer'){
      loadWorkspace();
      localStorage.setItem('current_view', 'Buyer');
    }

    else if (currentView === 'Seller'){
      loadSellerWorkspace();
      localStorage.setItem('current_view', 'Seller');
    }
  }, [currentView]);

  async function handleSubmitRequest(){
    setSubmittingWithdraw(true);

    let res = await withdraw();

      if (res.success) {
        setShowWithdraw(false);
        setShowWithdrawConfirm(true);
        setWorkspace({...workspace, available_balance: 0});
      }

      else if (res.status === 403){
        await refresh();

        let res = await withdraw();

        if (res.success) {
          setShowWithdraw(false);
          setShowWithdrawConfirm(true);
          setWorkspace({...workspace, available_balance: 0});
        }
      }
  }

  return (
    <>
    <ScrollToTop />
    <Helmet>
      <title>LeadrPro - Workspace Home</title>
    </Helmet>
    <div className='accounthome'>
      <div className='accounthome__toggle'>
        {isBuyer && (
          <div className={currentView == 'Buyer'?'selected':''} onClick={() => setCurrentView('Buyer')}>Discovering Software</div>
        )}

        {isSeller && (
          <div className={currentView == 'Seller'?'selected':''} onClick={() => setCurrentView('Seller')}>Selling Software</div>
        )}
      </div>

      {loading && (
        <div className='center mar20--top'>
          <Spinner />
        </div>
      )}

      {/* start here */}
      {currentView === 'Buyer' && workspaceLoaded === true && (
        <FadeIn delay={200} duration={300}>
          <div className='accounthome__greeting'>
            Hey {workspace.first_name}!<br/>
            Let's find you relevant software
          </div>

      {workspace.work_email_verified === false && (
        <div className='accounthome__top-banner accounthome__top-banner--warning'>
          <div>
            Don't forget to verify your work email!
          </div>

          <div>
            <Link to='/account/work-email'>Go to verification</Link>
          </div>
        </div>
      )}

      <div className='accounthome__main-cards'>
        <div className='accounthome__main-cards-header'>
          <div>Your Activity</div>
          <div className='accounthome__dots'>
            <div><span className='accounthome__dot accounthome__dot--good'></span> Good</div>
            <div><span className='accounthome__dot accounthome__dot--pending'></span> Needs Attention</div>
            <div><span className='accounthome__dot accounthome__dot--warning'></span> Urgent</div>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--pending'>
          <div className='accounthome__card-label'><FaRegEnvelope /> Unread Messages</div>
            <div><span className='accounthome__card-large-text'>{workspace.unread_count}</span>
            <span className='accounthome__card-text'> NEW</span>
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/dashboard/inbox' className='accounthome__card-btn accounthome__card-btn--pending'>Go to Inbox</Link>
          </div>
        </div>

        {workspace.available_balance > 0 && (
          <div className='accounthome__card accounthome__card--pending'>
            <div className='accounthome__card-label'><FaDollarSign /> Pending Funds</div>
            <div><span className='accounthome__card-large-text'>${workspace.available_balance}</span>
            </div>

            <div className='accounthome__card-btns'>
              {parseInt(workspace.available_balance) > 0 && (
              <button className='accounthome__card-btn accounthome__card-btn--pending' onClick={() => setShowWithdraw(true)}>Withdraw Your Funds</button>
              )}

              {parseInt(workspace.available_balance) === 0 && (
                <button className='accounthome__card-btn accounthome__card-btn--pending'>
                  No balance pending
                </button>
              )}
            </div>
          </div>
        )}

        {workspace.needs_feedback > 0 && (
          <div className='accounthome__card accounthome__card--danger'>
            <div className='accounthome__card-label'><FaExclamationCircle /> Requires Your Feedback</div>
              <div><span className='accounthome__card-large-text'>{workspace.needs_feedback}</span>
            </div>

            <div className='accounthome__card-btns'>
              <Link to='/account/completed' className='accounthome__card-btn accounthome__card-btn--danger'>Go to Feedback</Link>
            </div>
          </div>
        )}

        {workspace.rescheduled_demos > 0 && (
          <div className='accounthome__card accounthome__card--danger'>
            <div className='accounthome__card-label'><FaCalendarAlt /> Rescheduling Demos</div>
              <div><span className='accounthome__card-large-text'>{workspace.rescheduled_demos}</span>
            </div>

            <div className='accounthome__card-btns'>
              <Link to='/account/rescheduled' className='accounthome__card-btn accounthome__card-btn--danger'>Go to Reschedule</Link>
            </div>
          </div>
        )}

        {workspace.upcoming_demos > 0 && (
        <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'><FaCalendarCheck /> Upcoming Demos</div>
            <div><span className='accounthome__card-large-text'>{workspace.upcoming_demos}</span>
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/account/upcoming' className='accounthome__card-btn accounthome__card-btn--good'>View Upcoming Demos</Link>
          </div>
        </div>
        )}

        {workspace.completed_demos > 0 && (
          <div className='accounthome__card accounthome__card--good'>
            <div className='accounthome__card-label'><FaRegCheckCircle /> Demos Completed</div>
              <div><span className='accounthome__card-large-text'>{workspace.completed_demos}</span>
            </div>

            <div className='accounthome__card-btns'>
              <Link to='/account/completed' className='accounthome__card-btn accounthome__card-btn--good'>View Completed Demos</Link>
            </div>
          </div>
        )}

        <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'><FaDollarSign /> Total Earned</div>
            <div><span className='accounthome__card-large-text'>${workspace.completed_demos * 75}</span>
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/account/payouts' className='accounthome__card-btn accounthome__card-btn--good'>View Earning History</Link>
          </div>
        </div>

        {/* <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'><FaDollarSign /> Referral's Earned</div>
            <div><span className='accounthome__card-large-text'>$225</span>
          </div>

          <div className='accounthome__card-btns'>
            <button className='accounthome__card-btn accounthome__card-btn--good'>View Referrel List</button>
          </div>
        </div> */}

        <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'>Settings</div>
            <div className='accounthome__card-large-text'>
              <FaCog />
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/account/home' className='accounthome__card-btn accounthome__card-btn--good'>Go to Settings</Link>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'>Recommended Software</div>
            <div className='accounthome__card-text'>
              Review software recommendations tailored to your specific work.
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/recommendations' className='accounthome__card-btn accounthome__card-btn--good'>See Recommendations</Link>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'><FaMobileAlt /> Download The App</div>
            <div className='accounthome__card-text'>
              Take your software stack on the go. Available for Android and iOS.
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/app' className='accounthome__card-btn accounthome__card-btn--good'>Go to Downloads</Link>
          </div>
        </div>
      </div>

      {isSeller === false && (
        <div className='accounthome__become-seller'>
          <div className='accounthome__become-seller-blurb'>
            Interested in becoming a software seller on our platform? Setup your profile and start booking demos today!
          </div>

          <div>
            <Link to='/account/become-a-seller' className='btn btn--primary btn--become-seller'>Setup your seller profile</Link>
          </div>
        </div>
      )}

      <div className='accounthome__main-cards'>
        <div className='accounthome__main-cards-header'>Helpful Links</div>
        <div className='accounthome__card accounthome__card--border'>
          <div className='accounthome__card-label'>Refer a friend</div>
            <div className='accounthome__card-text'>
              Refer a friend and earn $25 when they complete their first demo.
            </div>

          <div className='accounthome__card-btns'>
            <Link to='/account/refer-a-friend' className='accounthome__card-btn'>Refer a Friend</Link>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--border'>
          <div className='accounthome__card-label'>FAQ</div>
            <div className='accounthome__card-text'>
              Have a question? Check out our fAQ to see the most 
            </div>

          <div className='accounthome__card-btns'>
            <Link to='/faq' className='accounthome__card-btn'>Go to FAQ</Link>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--border'>
          <div className='accounthome__card-label'>Support</div>
            <div className='accounthome__card-text'>
              Have a question, comment or concern? Let us know.
            </div>

          <div className='accounthome__card-btns'>
            <Link to='/leave-feedback' className='accounthome__card-btn'>Go to Support</Link>
          </div>
        </div>
      </div>
      {/* end here */}
      </FadeIn>
      )}

      {currentView === 'Seller' && sellerWorkspaceLoaded && (
        <FadeIn delay={200} duration={300}>
          <div className='accounthome__greeting'>
            Hey Walt!<br/>
            Let's work on your sales pipeline!
          </div>

          <div className='accounthome__main-cards'>
            <div className='accounthome__main-cards-header'>
              <div>Your Activity</div>
              <div className='accounthome__dots'>
            <div><span className='accounthome__dot accounthome__dot--good'></span> Good</div>
            <div><span className='accounthome__dot accounthome__dot--pending'></span> Needs Attention</div>
            <div><span className='accounthome__dot accounthome__dot--warning'></span> Urgent</div>
          </div>
            </div>

            {sellerWorkspace.rescheduled_demos > 0 && (
              <div className='accounthome__card accounthome__card--danger'>
                <div className='accounthome__card-label'><FaCalendarCheck /> Reschedules</div>
                  <div><span className='accounthome__card-large-text'>{sellerWorkspace.rescheduled_demos}</span>
                </div>

                <div className='accounthome__card-btns'>
                  <Link to='/account/s/rescheduled' className='accounthome__card-btn accounthome__card-btn--danger'>View Rescheduled</Link>
                </div>
              </div>
            )}

            <div className='accounthome__card accounthome__card--good'>
              <div className='accounthome__card-label'><FaListAlt /> New Requests</div>
                <div><span className='accounthome__card-large-text'>{sellerWorkspace.available_demos}</span>
              </div>

              <div className='accounthome__card-btns'>
                {sellerWorkspace.available_demos > 0 && (
                  <Link to='/account/s/available' className='accounthome__card-btn accounthome__card-btn--good'>View Available Requests</Link>
                )}

                {sellerWorkspace.available_demos == 0 && (
                  <span className='accounthome__card-btn accounthome__card-btn--good'>View Available Requests</span>
                )}
              </div>
            </div>

            {sellerWorkspace.upcoming_demos > 0 && (
              <div className='accounthome__card accounthome__card--good'>
                <div className='accounthome__card-label'><FaCalendarCheck /> Upcoming Demos</div>
                  <div><span className='accounthome__card-large-text'>{sellerWorkspace.upcoming_demos}</span>
                </div>

                <div className='accounthome__card-btns'>
                  {sellerWorkspace.upcoming_demos > 0 && (
                    <Link to='/account/s/upcoming' className='accounthome__card-btn accounthome__card-btn--good'>View Upcoming Demos</Link>
                  )}

                  {sellerWorkspace.upcoming_demos == 0 && (
                    <span className='accounthome__card-btn accounthome__card-btn--good'>View Upcoming Demos</span>
                  )}
                </div>
              </div>
            )}

            {sellerWorkspace.completed_demos > 0 && (
              <div className='accounthome__card accounthome__card--good'>
                <div className='accounthome__card-label'><FaRegCheckCircle /> Demos Completed</div>
                  <div><span className='accounthome__card-large-text'>{sellerWorkspace.completed_demos}</span>
                </div>

                <div className='accounthome__card-btns'>
                  <Link to='/account/s/completed' className='accounthome__card-btn accounthome__card-btn--good'>View Completed Demos</Link>
                </div>
              </div>
            )}

            <div className='accounthome__card accounthome__card--good'>
              <div className='accounthome__card-label'>Settings</div>
                <div className='accounthome__card-large-text'>
                  <FaCog />
              </div>

              <div className='accounthome__card-btns'>
                <Link to='/account/home' className='accounthome__card-btn accounthome__card-btn--good'>Go to Settings</Link>
              </div>
            </div>

            <div className='accounthome__card accounthome__card--good'>
              <div className='accounthome__card-label'>Your Team</div>
                <div className='accounthome__card-large-text'>
                  <FaUsers />
              </div>

              <div className='accounthome__card-btns'>
                <Link to='/account/team' className='accounthome__card-btn accounthome__card-btn--good'>Manage Team</Link>
              </div>
            </div>

            <div className='accounthome__card accounthome__card--good'>
              <div className='accounthome__card-label'>Addons</div>
                <div className='accounthome__card-large-text'>
                  <FaShapes />
              </div>

              <div className='accounthome__card-btns'>
                <Link to='/account/addons' className='accounthome__card-btn accounthome__card-btn--good'>Manage Addons</Link>
              </div>
            </div>

          <div className='accounthome__card accounthome__card--good'>
              <div className='accounthome__card-label'>Custom Recommendations</div>
                <div className='accounthome__card-text'>
                  View personalized leads matching your specific ICP.
                </div>

              <div className='accounthome__card-btns'>
                <Link to='/s/recommendations' className='accounthome__card-btn accounthome__card-btn--good'>View Recommendations</Link>
              </div>
            </div>

            <div className='accounthome__card accounthome__card--good'>
          <div className='accounthome__card-label'><FaMobileAlt /> Download The App</div>
            <div className='accounthome__card-text'>
              Take your software stack on the go. Available for Android and iOS.
          </div>

          <div className='accounthome__card-btns'>
            <Link to='/app' className='accounthome__card-btn accounthome__card-btn--good'>Go to Downloads</Link>
          </div>
        </div>
          </div>

          {isBuyer === false && (
            <div className='accounthome__become-seller'>
              <div className='accounthome__become-seller-blurb'>
                Interested in discovering software and getting paid for your time? Setup your profile and start booking demos today!
              </div>

              <div>
                <Link to='/account/become-a-buyer' className='btn btn--primary btn--become-seller'>Setup your buyer profile</Link>
              </div>
            </div>
          )}

          <div className='accounthome__main-cards'>
            <div className='accounthome__main-cards-header'>Other stuff</div>
            {/* <div className='accounthome__card accounthome__card--border'>
              <div className='accounthome__card-label'>Refer</div>
                <div className='accounthome__card-text'>
                  Refer another software vendor and earn $250 when they complete their first demo.
                </div>

              <div className='accounthome__card-btns'>
                <button className='accounthome__card-btn'>Refer a Seller</button>
              </div>
            </div> */}

            <div className='accounthome__card accounthome__card--border'>
          <div className='accounthome__card-label'>FAQ</div>
            <div className='accounthome__card-text'>
              Have a question? Check out our FAQ section.
            </div>

          <div className='accounthome__card-btns'>
            <Link to='/faq' className='accounthome__card-btn'>Go to FAQ</Link>
          </div>
        </div>

        <div className='accounthome__card accounthome__card--border'>
          <div className='accounthome__card-label'>Support</div>
            <div className='accounthome__card-text'>
              Have a question, comment or concern? Let us know.
            </div>

          <div className='accounthome__card-btns'>
            <Link to='/leave-feedback' className='accounthome__card-btn'>Go to Support</Link>
          </div>
        </div>
          </div>
        </FadeIn>
      )}
      </div>

      {showWithdraw && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowWithdraw(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Withdraw Your Funds
            </h2>

            <div className='mar20--top'>
              You have funds available for withdraw. Submit your request and we will begin processing your payment shortly.
            </div>

            <div className='mar20--top center'>
              {submittingWithdraw === false && (
                <button className='btn btn--primary' onClick={() => handleSubmitRequest()}>Submit Request</button>
              )}

              {submittingWithdraw === true && (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}

      {showWithdrawConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowWithdrawConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Success!
            </h2>

            <div className='mar20--top'>
              Your request has been sent. Please note that payouts take 3-5 days to hit your account.
            </div>

            <div className='mar20--top'>
              If you have not received payment by the Friday following the 3-5 day period, please reach out to support for assistance.
            </div>

            <div className='mar20--top center'>
              <button className='btn' onClick={() => setShowWithdrawConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </>
)
}

  export default AccountHome;