import './ReschedulePolicy.css';
import React, { useEffect, useState } from "react";

function ReschedulePolicy() {

  useEffect(() => {
    document.title = 'LeadrPro - Reschedule Policy';
  }, []);

    return ( 
    <div className='privacy'>
        <h1>Reschedule Policy</h1>

        <p>
          Policy goes here
        </p>

        <h2>Contact Information</h2>
        <p>
        Address: 120 W. Arenas Rd, Suite 2, Palm Springs, CA 92262
        Email: support@leadrpro.com
        </p>
    </div>
    );
}

export default ReschedulePolicy;