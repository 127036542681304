import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { searchDemos } from '../../utils/api/admin';

function DemoLookup() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      
    }

    loadData();
  }, []);

  async function handleSearch(){
    setSubmitting(true);
    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await searchDemos(query, id);

    if (res.success){
      setResults(res.data);
      setSubmitting(false);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await searchDemos(query, id);

      if (res.success){
        setResults(res.data);
        setSubmitting(false);
      }
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      await handleSearch();
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Demo Lookup {`(${results.length})`}</h1>

      <div className='admindashboard__search-lookup'>
        <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />

        <div className='admindashboard__search-lookup-btn'>
          <button className='btn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div>

      {submitting === false && results.length === 0 && (
        <div className="center mt-20"><strong>Nothing to see here...</strong></div>
      )}

      {submitting === true && (
        <Spinner />
      )}

      {loaded && submitting === false && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Demo ID</th>
              <th>Buyer {'<>'} Seller</th>
              <th>Demo Date</th>
              <th>Buyer Status</th>
              <th>Seller Status</th>
              <th>Demo Status</th>
            </tr>

            {results.map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}</strong></td>
                <td>{item.demo_id}</td>

                <td>
                  <div>
                    <strong>Buyer: {item.buyer_company_name} - {item.buyer_first_name}
                    </strong>
                  </div>
                  <div><strong>Seller: {item.seller_company_name} - {item.seller_first_name}</strong></div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>

                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>
                    </div>
                  </td>

                  <td>
                    {new Date(item.demo_date).toDateString()}
                  </td>

                  <td>
                    {item.buyer_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.buyer_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.seller_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.seller_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.demo_status_id === 1 && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.demo_status_id === 4 && (
                      <span className='admindashboard__green-flag'>Completed</span>
                    )}

                    {item.demo_status_id === 2 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}

                    {item.demo_status_id === 5 && (
                      <span className='admindashboard__red-flag'>Expired</span>
                    )}
                  </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default DemoLookup;