import './VerifyEmail.css';
import { resendVerification } from '../../utils/api';

const VerifyEmail = ({email, token}) => {
    async function resendEmail(e){
        e.preventDefault();
        let response = await resendVerification(email);

        if (response.success){

        }
        else{

        }
    }

    return (
         <div className="createaccount center">
            <div className="card center" style={{width: "360px"}}>
                <div className="card__inner">
                    <h1 className="card__header">Please verify your email</h1>

                    <p className="card__text">
                        You're almost there! We've sent a verification email to <strong>{email}</strong>
                    </p>

                    <p className="card__text">
                        Click the link in your email to complete the sign up process. Don't see it? Make sure to check your spam folder.
                    </p>

                    <p className="card__text">Still can't find the email?</p>
                        
                    <div className="form__group center">
                        <button className="btn btn--primary" onClick={resendEmail}>
                            Resend email
                        </button>
                    </div>
                    
                    <p className="card__text">Need help? <a href="/contact">Contact Us</a></p>

                    {/* <div>
                        <a href={"http://localhost:3000/account-setup?token=" + token}>Setup account (Dev)</a>
                    </div>

                    <div>
                        <a href={"https://leadrpro-server-staging.vercel.app/account-setup?token=" + token}>Setup account (Staging)</a>
                    </div> */}
                 </div>
            </div>
        </div>
    )
}

export default VerifyEmail;