import './AppLink.css';
import logo from '../../assets/design/logo-grey.png';
import { FaPhoneAlt, FaMobile, FaMobileAlt } from 'react-icons/fa';
import { useState, useEffect } from 'react';

function AppLink({text = 'Take your inbox on the go! Download the App!'}) {
  const year = new Date().getFullYear();
  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      <div className='applink'>
        <div className='applink__copy' onClick={() => setShowOptions(true)}><FaMobileAlt /> {text}</div>
      </div>

      {showOptions && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='applink__modal-header'>Download for your platform</div>

            <div className='applink__modal-links'>
              <div><a href='https://apps.apple.com/us/app/leadrpro/id6449265881' target='_blank'>I'm on iOS</a></div>
              <div><a href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile' target='_blank'>I'm on Android</a></div>
            </div>

            <div className='center' style={{marginTop: '20px'}}>
              <button className='btn btn--secondary' onClick={() => setShowOptions(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AppLink;
