import { useState } from 'react';
import React from "react";
import './PhoneNumber.css';
import { addPhoneNumber, hRefreshToken } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import {Helmet} from "react-helmet";
import ScrollToTop from '../../components/layout/ScrollToTop';
import { Navigate, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const PhoneNumber = ({setCurrentStep, roleType, setPhoneNumber}) => {
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');
    const [value, setValue] = useState()
    let refPhone = React.createRef();
    const navigate = useNavigate();

    async function handleSubmit(e){
        e.preventDefault();

        let res_phone = await addPhoneNumber(value);

        if (res_phone.success){
            if (roleType === 'Seller'){
                navigate('/select-new-plan');
            }

            else{ 
                setCurrentStep(100);
            }
        }

        else if (res_phone.status === 403){
            await refresh();

            let res_phone = await addPhoneNumber(value);

            if (res_phone.success){
                if (roleType === 'Seller'){
                    navigate('/dashboard/seller');
                }

                else{ 
                    setCurrentStep(100);
                }
            }
        }

        else{
            setHasError(true);
            setError(res_phone.message);
        }
    }

    async function skip(){
        if (roleType === 'Seller'){
           navigate('/dashboard/seller');
        }

        else{
            setCurrentStep(100);
        }
    }

  return (
      <div className="center card__wrapper">
        <ScrollToTop />
        <div className="card card--small phonenumber">
        <h1 className="card__header">Verify your phone number</h1>
        <p>
            <i>We will use your phone number to notify you of upcoming demos and send periodic demo reminders.</i>
        </p>
            <form onSubmit={handleSubmit}>
                <div className='form__group'>
                    <label className='label input__label' for='txtPhone'>Enter phone number</label>

                    <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        country="US"
                        className='input'
                        defaultCountry='US'
                        onChange={setValue}/>

                    {/* <input className='input' ref={refPhone} type='tel' id='txtPhone' required placeholder='+1()' name="phone" /> */}
                </div>

                {hasError && (
                    <div className="form__group">
                        <span className="label label--error">{error}</span>
                    </div>
                )}

                <div className='form__group phonenumber__radio'>
                    <input type='radio' id="radioOptIn" name="optIn" />

                    <label className='label radio-label' for='radioOptIn'>Yes, please keep me updated on news, events and offers
                    </label>
                </div>

                <div className="form__group center" style={{marginTop: "50px"}}>
                    <button className='btn btn--primary'>
                        Continue
                    </button>
                </div>

                <div className="form__group center">
                    <span className="link link--cancel" onClick={() => skip()}>Skip for now</span>
                </div>
            </form>
        </div>
        </div>
  )
}

export default PhoneNumber;