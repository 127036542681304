import './ContactForm.css';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser, refresh } from '../../services/auth.service';
import { useLocation } from 'react-router-dom';
import { saveSiteFeedback , saveContactForm} from '../../utils/api';
import ScrollToTop from '../../components/layout/ScrollToTop';

function ContactForm() {
    const search = useLocation().search;
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');

    useEffect(() => {
 
        document.title = "LeadrPro - Provide Feedback";
    }, []);
    
    async function saveFeedback(){
      if (feedbackText.length > 0){
        setLoading(true);
        let res = await saveContactForm(name, email, subject, feedbackText);

        if (res.success){
          setCompleted(true);
          setLoading(false);
        }

        else{

        }
      }

      else{
        alert("Message can not be left empty");
      }
    }

    return (
        <div className="center card__wrapper">
          <ScrollToTop/>
          <Helmet>
            <meta name="description" content="" />
          </Helmet>

          <div className='sitefeedback'>
            <h1>Send us a message</h1>
            <p className='sitefeedback__copy'>
              We are always working to improve the user experience on our website. If there is anything that you would like to see us improve or feature that you would like to see on our platform, please feel free to share your thoughts below!
            </p>

            {completed === false && (
              <>
              <div className='form-group'>
                <label>Name</label>

                <div>
                  <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name' />
                </div>
              </div>

              <div className='form-group'>
                <label>Email</label>

                <div>
                  <input type='text' className='input' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' />
                </div>
              </div>

              <div className='form-group'>
                <label>Subject</label>

                <div>
                  <input type='text' className='input' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Enter subject' />
                </div>
              </div>

            <div className='sitefeedback__textarea'>
              <label>Message</label>
              <textarea className='input' placeholder='Message...' value={feedbackText} onChange={(e)=>setFeedbackText(e.target.value)}>

              </textarea>
            </div>

            <div style={{marginTop: "20px"}}>
              {loading === false && completed === false && (
              <button className='btn btn--primary' onClick={() => saveFeedback()}>Submit</button>
              )}

              {loading && completed === false && (
                <span>Submitting...</span>
              )}
            </div>

            <p style={{marginTop:"30px"}} className='sitefeedback__disclaimer'>
              <strong>
              If this is a support request please reach out to <i>support@leadrpro.com</i>.
            </strong>
            </p>
            </>
            )}

            {completed && (
              <div>
                Your feedback has been sent! Keep an eye on your inbox and someone will reach out to you within 24 hours.
              </div>
            )}
          </div>
        </div>
    );
}

export default ContactForm;