
import './JoinFeed.css';
import {useEffect, useState} from 'react';
import { getPayoutTransactions, refreshToken, getUserProfile, getAllIndustries } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';

const JoinFeed = () => {
  const [transactions, setTransactions] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPost, setShowPost] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        setLoading(false);
      }

      else if (response.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let response = await getUserProfile();

            if (response.success){
              let data = response.data;

              setUser(response.data);
              setLoading(false);

              let role = data.roles.filter(item => item.role_type_id === 1)[0];
            // setSelectedPersonaType({persona_type_id: role.persona_type_id, name: role.persona_type, icon_image: role.icon_image});
            }
          }
      }
    }

    async function loadIndustries(){
      let res = await getAllIndustries();

      if (res.success){
        setIndustries(res.data);
      }
    }

    loadUserProfile();
    loadIndustries();
  }, []);

  return (
    <>
    <div className="joinfeed">
      <h1 className='feed__h1'>Community</h1>

      <p className='feed__blurb'>
        Share your thoughts, ask the community or answer somebody's question.
      </p>

      <div className='feed__post-btn-wrapper'>
        <button className='feed__post-btn' onClick={() => setShowPost(true)}><FaPlus /><span style={{marginLeft: "5px"}}>Post</span></button>
      </div>
    </div>

    {showPost && (
      <>
        <div className='overlay'></div>

        <div className='modal feed__modal-post'>
          <div className='modal__close' onClick={() => setShowPost(false)}>
            <img src={close} />
          </div>
          
          <div className='feed__modal-post-header'>
            Start a conversation
          </div>

          <div>
            <textarea className='input feed__textarea' placeholder='What would you like to ask/answer/post?'>

            </textarea>
          </div>

          <div>
            <button className='feed__modal-post-btn'>Post</button>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default JoinFeed;