import { useState, useEffect } from "react";
import './BuyerProfilePublic.css';
import { getUserProfile, getUserProfileFromId,getUserCompany,getUserCompanyFromId,getUserPayoutMethods,getUserInterest,getUserInterestFromId, removeUserInterest,getRandomCategories, addUserInterest, createBuyerDemo } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import company_logo from '../../assets/companylogos/yahoo.png';
import BuyerFeedback from '../../components/feedback/BuyerFeedback';
import SellerFeedback from '../../components/feedback/SellerFeedback';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef } from '../../utils/api';
import ReferFriends from './ReferFriends';
import CompanyInfo from "../account/CompanyInfo";
import SellerBookingBuyer from "../../components/demos/SellerBookingBuyer";

function BuyerProfilePublic() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [isBuyer, setIsBuyer] = useState(false);
    const [demo, setDemo] = useState({});
    const [valid, setValid] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [userCompany, setUserCompany] = useState({});
    const [payoutMethods, setPayoutMethods] = useState([]);
    const [userCategories, setUserCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showRefer, setShowRefer] = useState(false);
    const [showBooking, setShowBooking] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const [selectedBuyer, setSelectedBuyer] = useState({});
    const [buyerId, setBuyerId] = useState(0);

    useEffect(() => {
      async function loadUserProfile(){
        const id = new URLSearchParams(search).get('id');
        setBuyerId(id);
        //setReferenceId(id);
        let res = await getUserProfileFromId(id);

        if (res.success){
          let data = res.data;
          setSelectedBuyer(oldState => ({ ...oldState, seniority: data.seniority}));
          setSelectedBuyer(oldState => ({ ...oldState, timezone: data.timezone})); // <<- Merge previous state with new data
          setSelectedBuyer(oldState => ({ ...oldState, persona_type: 'Decision Maker'}));
          setSelectedBuyer(oldState => ({ ...oldState, credits: 5}));
          setSelectedBuyer(oldState => ({ ...oldState, seller_timezone: ''}));

          console.log('user profile', res.data);
          setUserProfile(res.data);
        }
      }

      async function loadUserCompany(){
        const id = new URLSearchParams(search).get('id');
        let res = await getUserCompanyFromId(id);

        if (res.success){
          let data = res.data;
          setSelectedBuyer(oldState => ({ ...oldState, logo: data.logo})); // <<- Merge previous state with new data
          setSelectedBuyer(oldState => ({ ...oldState, company_name: data.name})); // <<- Merge previous state with new data
          setSelectedBuyer(oldState => ({ ...oldState, company_size: data.company_size}));
          setSelectedBuyer(oldState => ({ ...oldState, industry_name: data.industry}));

          console.log('user company', res.data);
          setUserCompany(res.data);
        }
      }

      async function loadPayoutMethods(){
        let res = await getUserPayoutMethods();

        if (res.success){
          console.log('payout methods', res.data);
          setPayoutMethods(res.data);
        }
      }

      async function loadUserCategories(){
        const id = new URLSearchParams(search).get('id');
        let res = await getUserInterestFromId(id);

        if (res.success){
          console.log('categoires', res.data);
          setUserCategories(res.data);
        }
      }

      async function loadCategories(){
        let res = await getRandomCategories();

        if (res.success){
          console.log('categoires', res.data);
          setCategories(res.data);
        }
      }

      loadUserProfile();
      loadUserCompany();
      loadPayoutMethods();
      loadUserCategories();
      loadCategories();

        document.title = "LeadrPro - Public Profile";
    }, []);

    useEffect(() => {
      console.log('selected buyer', selectedBuyer);
    }, [selectedBuyer]);

    async function loadCategories(){
      let res = await getRandomCategories();

      if (res.success){
        setCategories(res.data);
      }
    }

    async function handleShuffle(){
      let res = await loadCategories();
      setCategories(res.data);
    }

    async function handleBooking(){
      let data = {buyer_id: buyerId, credit_cost: 5, scheduled_date: null, scheduled_time: null};
      let res = await createBuyerDemo(data);
      setShowConfirm(true);
    }

    return (
        <div className="buyerprofile buyerprofilepublic">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        {showConfirm && (
          <>
          <div className="overlay"></div>
           <div className="center modal">
            <div className="modal__close" onClick={() => setShowConfirm(false)}>X</div>
           <div className="">
             <div className="">
              <div>
                {userCompany.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userCompany.logo} alt="" />
                )}

                {!userCompany.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userCompany.logo} alt="" />
                )}
              </div>
               <p className="">
                 Congrats! We will notify you once the buyer accepts your request and update the status in your dashboard.
               </p>
             </div>
           </div>
         </div>
         </>
        )}

        <h1>Profile Info</h1>

        <div className="" style={{marginTop: "20px", marginBottom: "30px"}}>
            <div className="buyerprofile__main">
              <div className="buyerprofile__main-header">

              </div>

              <div className="buyerprofile__company-name-wrapper">
                <div className="buyerprofile__company-logo">
                {userCompany.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userCompany.logo} alt="" />
                )}

                {!userCompany.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userCompany.logo} alt="" />
                )}
                </div>

                <div className="">
                  <div><strong>Decision Maker</strong></div>
                  {userProfile.company_name}
                </div>

                <div>
                  <strong>Seniority</strong>
                  <div>{userProfile.seniority}</div>
                </div>

                <div>
                  <div><strong>Department</strong></div>
                  <div>{userProfile.department}</div>
                </div>
              </div>

              <div className="buyerprofilepublic__segments">
                <div className="buyerprofilepublic__segment">
                  <h2>Company Info</h2>

                  <div>
                    <div><strong>description</strong></div>

                    <div>{userCompany.description}</div>
                  </div>

                  <div>
                    <div><strong>Size</strong></div>

                    <div>{userCompany.company_size}</div>
                  </div>

                  <div>
                    <div><strong>Industry</strong></div>

                    <div>{userCompany.industry}</div>
                  </div>

                  <div>
                    <div><strong>Website</strong></div>
                    <div>{userCompany.website}</div>
                  </div>
                </div>

                <div className="buyerprofilepublic__segment">
                  <h2>Software Categories</h2>

                  <div>
                    {userCategories.map(item => (
                      <div>
                      <div className="buyerprofile__selected-category">{item.category_name}</div>
                      </div>
                    ))}
                  </div>
                </div>

                {buyerId && (
                <div className="buyerprofilepublic__segment">
                  <h2>Booking</h2>

                  <p>
                    Interested in booking a demo with Decision Maker at {userCompany.name}?
                  </p>

                  <p className="center" style={{marginTop: "40px"}}>
                    <button className="btn btn--primary" onClick={() => handleBooking()}>Request a demo</button>
                  </p>
                </div>
                )}
              </div>

              {/* <div className="buyerprofile__personal-info buyerprofile__segment">
                <div className="buyerprofile__segment-header">
                  <h2>Personal info</h2>
                </div>

                <div className="buyerprofile__segment-data">
                  <div>
                    <strong>Name</strong>
                    <div>{userProfile.first_name} {userProfile.last_name}</div>
                  </div>

                  <div>
                    <strong>Persona</strong>
                    <div>Decision Maker</div>
                  </div>

                  <div>
                    <strong>Job Title</strong>
                    <div>{userProfile.job_title}</div>
                  </div>

                  <div>
                    <strong>Seniority Level</strong>
                    <div>{userProfile.seniority}</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="buyerprofile__company-info buyerprofile__segment">
                <div className="buyerprofile__segment-header">
                  <h2>Company info</h2>
                </div>

                <div className="buyerprofile__segment-data">
                  <div>
                    <strong>Company Name</strong>
                    <div>{userProfile.company_name}</div>
                  </div>

                  <div>
                    <strong>Size</strong>
                    <div>{userCompany.company_size}</div>
                  </div>

                  <div>
                    <strong>Industry</strong>
                    <div>{userCompany.industry}</div>
                  </div>

                  <div>
                    <strong>Website</strong>
                    <div>{userCompany.website}</div>
                  </div>
                </div>
              </div> */}
            </div>
        </div>

        {showBooking && (
          <>
            <div className="overlay"></div>
            <SellerBookingBuyer selectedBuyer={selectedBuyer} setShowSelectedTime={setShowBooking} />
          </>
        )}
        </div>
    );
}

export default BuyerProfilePublic;