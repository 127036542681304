import { useState, useEffect } from 'react';
import LeadCard from '../../components/qualifier/LeadCard';
import ReassignDemo from './ReassignDemo';
import { getTeamDemosPending, getDemoQualifierAnswers, openedLeadCard, checkHasPaymentMethod, getPackageItemsRemainingCount, getUserCompany} from '../../utils/api';
import { refresh, getCurrentUser } from '../../services/auth.service';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import Spinner from '../../components/layout/Spinner';
import { FaFireAlt, FaEllipsisV, FaTimes } from "react-icons/fa";
import FadeIn from '../../utils/fadein';
import { useNavigate } from 'react-router-dom';
import DeclinedPopup from './DeclinedPopup';
import SellerDecline from './SellerDecline';

const SellerDemosTeam = ({refreshRemaining}) => {
  const imagePath = process.env.REACT_APP_IMAGE_PATH;
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [sellerObjections, setSellerObjections] = useState([]);
  const [currentDemo, setCurrentDemo] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);
  const [showReassign, setShowReassign] = useState(false);
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showExpiresModal, setShowExpiresModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const currentUser = getCurrentUser();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();

  async function loadPaymentMethod() {
    let res = await checkHasPaymentMethod();

    if (res.success) {
      setHasPaymentMethod(res.data);
    } 
    
    else if (res.status === 403) {
      await refresh();
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
      }
    }

    setPaymentMethodLoaded(true);
  }

  async function loadRemainingCount(){
    let res = await getPackageItemsRemainingCount();

    if (res.success){
      setRemainingCount(res.data);
      setRemainingCountLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getPackageItemsRemainingCount();

      if (res.success){
        setRemainingCount(res.data);
        setRemainingCountLoaded(true);
      }
    }
  }

  async function reloadRemaining(){
    await loadRemainingCount();
    await refreshRemaining();
  }

  async function handleToggleMenu(item){
    if (item.demo_id !== currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(true);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }
  }

  useEffect(() => {
    async function loadTeamDemos(){
      let res = await getTeamDemosPending();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }
    }

    
  async function loadUserCompany(){
    let res = await getUserCompany();

    if (res.success){
      setUserCompany(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }
    }
  }

    loadPaymentMethod();
    loadTeamDemos();
    loadUserCompany();
  }, []);

  async function refreshDemos(){
    let res = await getTeamDemosPending();

    if (res.success){
      setDemos(res.data);
      setLoaded(true);
    }
  }

  async function handleAnswersClick(item) {
    setLoading(true);
    setSelectedId(item.demo_id);

    let res = await getDemoQualifierAnswers(item.demo_id);

    if (res.success){
      let new_item = {...item, qualifier_answers: res.data};
      setCurrentDemo(new_item);
      setShowQualifierAnswers(true);
      setLoading(false);
      setShowSubMenu(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getDemoQualifierAnswers(item.demo_id);

      if (res.success){
        let new_item = {...item, qualifier_answers: res.data};
        setCurrentDemo(new_item);
        setShowQualifierAnswers(true);
        setLoading(false);
        setShowSubMenu(false);
      }
    }

    await openedLeadCard(item.demo_id);
  }

  async function handleReassignClick(demo) {
    setCurrentDemo(demo);
    setShowReassign(true);
    setShowSubMenu(false);
  }

  function addDays(d, days){
    const result = new Date(d);
    result.setDate(result.getDate() + days);
    return result;
  }

  // if date sent was over 14 days ago
  function isExpiring(d, last_date){
    let date = new Date();
    let newDate = addDays(date, -14);
    let between = daysBetweenDates(date, last_date);

    if (between < 4 && between > 0){
      return (
        <>
          <span className='dashboarddemo__expires-soon'>Expires in {between} days</span>
        </>
      )
    }

    else if (between < 1){
      return (
        <>
          <span className='dashboarddemo__expires-soon' onClick={() => setShowExpiresModal(true)}>Times have expired</span>
        </>
      )
    }

    else{
      return (
        <></>
      )
    }
  }

  function daysBetweenDates(date1, date2) {
    const millisecondsPerHour = 60 * 60 * 1000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
    const differenceInMilliseconds = date2 - date1; // Get the absolute difference in milliseconds
    const hours = differenceInMilliseconds / millisecondsPerHour; // Convert milliseconds to hours
    return Math.floor(hours / 24);
  }

  async function handleDecline(demo) {
    setCurrentDemo(demo);
    setShowQualifierAnswers(false);
    setShowDeclinedPopup(true);
    setShowSubMenu(false);
  }

  return (
    <>
    <div>
      {loaded === false && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {loaded === true && demos.length === 0 && (
        <div className='dashboarddemo__no-results'>
          <p>We are looking for the best candidates currently that match your ICP's. Hang tight and we will notify you when your requests are ready to review.</p>

          <p style={{marginTop:'20px'}}>
            Or you can start to fill your sales pipeline today by viewing our top <a href='/s/recommendations' className="link">available prospects</a>.
          </p>
        </div>
      )}

      {loaded && demos.length > 0 && (
        <>
    <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
        <tr>
            <th></th>
            <th>Company</th>
            <th>Date applied</th>
            <th>Interest</th>
            <th>Assigned to</th>
            <th></th>
            <th></th>
          </tr>
      {(demos.sort((a, b) => new Date(b.date_applied) - new Date(a.date_applied)))
          .map((item, index) => (
            <tr key={index}>
              <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              </td>

              <td>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </span>
                  </div>
                </td>

                    <td className='dashboarddemo__company-info'>
                      <div className='dashboarddemo__company-info-items'>
                        <div className='dashboarddemo__company-info-item'>
                          <div style={{fontSize: '12px'}}>
                            <span className='dashboarddemo__company-info-label'>Applied on: </span>
                            <span>{new Date(item.date_applied).toLocaleDateString()}</span>
                          </div>

                          <div>
                            {isExpiring(new Date(item.date_applied), new Date(item.last_date_to_approve))}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                    {item.demo_interest === 'Very interested' && (
                        <div className='dashboarddemo__very-interested'>
                          <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                          <span className='dashboarddemo__interest-label'>Very interested</span></div>
                      )}
                      
                      {item.demo_interest === 'Somewhat interested' && (
                        <div className='dashboarddemo__somewhat-interested'>
                        <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                        <span className='dashboarddemo__interest-label'>Moderate</span></div>
                      )}

                      {/* {item.demo_interest === 'Just researching' && (
                        <span className='dashboarddemo__just-researching'>Researching</span>
                      )} */}
                    </td>

                    <td>
                      <div className='center'>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='dashboarddemo__actions'>     
                      <div style={{marginBottom: '10px'}}>
                        {loading === true && item.demo_id == selectedId && (
                          <Spinner />
                        )}
                        {loading === false && (
                          <div
                            className='btn'
                            onClick={() => handleAnswersClick(item)}>
                            <span style={{ verticalAlign: 'middle' }}>
                              Review
                            </span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div style={{position: 'relative'}}>
                    <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item' onClick={() => handleAnswersClick(item)}>Buyer Details</div>

                      {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                      )}

                      <div onClick={() => handleReassignClick(item)} className='dashboard__submenu-item'>Reassign</div>

                      {currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => handleDecline(item, item.demo_id)} className='dashboard__submenu-item'>Not interested</div>
                      )}
                    </div>
                    </FadeIn>
                    )}
                    </div>
                  </td>
            </tr>
          ))}
          </table>
          </div>
          </>
      )}
      </div>

        {showReassign===true && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowReassign(false)}>
                <FaTimes />
              </div>

              <ReassignDemo demo_id={currentDemo.demo_id} demos={demos} setDemos={setDemos} refresh={refreshDemos} />
            </div>
          </>
        )}

        {showDeclinedPopup && (
            <div>
              <SellerDecline
                showDeclinedPopup={showDeclinedPopup}
                setShowDeclinedPopup={setShowDeclinedPopup}
                role='Seller'
                companyName={currentDemo.company_name}
                sellerDeclinedReasons={sellerDeclinedReasons}
                currentDemo={currentDemo}
                setCurrentDemo={setCurrentDemo}
                demos={demos}
                setDemos={setDemos}
                source='Decline'
              />
              {/* <DeclinedPopup
                showDeclinedPopup={showDeclinedPopup}
                setShowDeclinedPopup={setShowDeclinedPopup}
                role='Seller'
                companyName={currentDemo.company_name}
                sellerDeclinedReasons={sellerDeclinedReasons}
                currentDemo={currentDemo}
                setCurrentDemo={setCurrentDemo}
                demos={demos}
                setDemos={setDemos}
                source='Decline'
              /> */}
            </div>
          )}

        {showExpiresModal && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowExpiresModal(false)}>
                <FaTimes />
              </div>

              <h2 className='modal__h2'>
                Submitted times expired
              </h2>

              <div className='mt-10'>
                The available times submitted for this demo application have expired. If you would still like to meet with this person, you will need to submit a reschedule request.
              </div>
            </div>
          </>
        )}

      {showQualifierAnswers && (
        <>
          <div className='overlay'></div>
          <LeadCard 
          demo_id={currentDemo.demo_id}
          currentDemo={currentDemo} 
          setShowQualifierAnswers={setShowQualifierAnswers} 
          demos={demos} 
          setDemos={setDemos}
          hasPaymentMethod={hasPaymentMethod} 
          payment_type={userCompany.payment_type} 
          refreshRemaining={reloadRemaining}
          show_buttons={true} />
        </>
      )}
    </>
  )
}

  export default  SellerDemosTeam;