import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AdminContactMessages, AdminAddContactFormNote, AdminUpdateContactFormStatus } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaRegStickyNote, FaStickyNote, FaTimes } from 'react-icons/fa';
import { FcLockLandscape } from 'react-icons/fc';

function ContactMessages() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showNote, setShowNote] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [noteText, setNoteText] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await AdminContactMessages();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }

    else if(res.status=== 403){
      await refresh();
      let res = await AdminContactMessages();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  async function handleUpdateStatus(id, status){
    let res = await AdminUpdateContactFormStatus(id, status);

    if (res.success){
      loadData();
    }

    else if (res.status === 403){
      await refresh();

      let res = await AdminUpdateContactFormStatus(id, status);

      if (res.success){
        loadData();
      }
    }
  }

  async function handleAddNote(id){
    setSelectedId(id);
    setShowNote(true);
  }

  async function submitNote(){
    let res = await AdminAddContactFormNote(selectedId, noteText);

    if (res.success){
      loadData();
      setShowNote(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await AdminAddContactFormNote(selectedId, noteText);

      if (res.success){
        loadData();
        setShowNote(false);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Contact Form Messages {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th>From</th>
              <th>Message</th>
              <th>Staus</th>
              <th></th>
            </tr>

            {data.map(item => (
              <tr>

                <td><div>
                  <div>
                  {item.name}</div>

                  <div>
                    {item.email}
                  </div>
                </div>
                </td>

                <td style={{width: '50%'}}>
                  <div>
                    <strong>{new Date(item.date_created).toDateString()}</strong>
                  </div>

                  <div className='mt-10' style={{fontSize:'20px', fontWeight:'bold'}}>
                    {item.subject}
                  </div>

                  <div className='mt-10'>
                    {item.contact_message}
                  </div>
                </td>

                <td>
                  {item.status === 'Unread' && (
                    <>
                      <div className='center'>
                        <span className='admindashboard__red-flag'>{item.status}</span>
                      </div>

                      <div className='mt-10 center'>
                        <button className='btn' onClick={() => handleUpdateStatus(item.contact_form_id, 'Read')}>Mark as read</button>
                      </div>
                    </>
                  )}

                  {item.status === 'Read' && (
                    <>
                      <div className='center'>
                        <span className='admindashboard__blue-flag'>{item.status}</span>
                      </div>

                      <div className='mt-10 center'>
                        <button className='btn' onClick={() => handleUpdateStatus(item.contact_form_id, 'Resolved')}>Mark as resolved</button>
                      </div>
                    </>
                  )}

                  {item.status === 'Resolved' && (
                    <div className='center'>
                      <span className='admindashboard__green-label'>{item.status}</span>
                    </div>
                  )}
                </td>

                <td>
                  {item.admin_note && item.admin_note.length > 0 && (
                    <i>{item.admin_note}</i>
                  )}

                  {(item.admin_note == null || item.admin_note.length === 0) && (
                    <button className='btn' onClick={() => handleAddNote(item.contact_form_id)}><FaRegStickyNote /></button>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showNote && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowNote(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
              <textarea className='input textarea' value={noteText} onChange={(e) => setNoteText(e.target.value)} placeholder='Add note'></textarea>

              <div className='mt-10'><button className='btn' onClick={() => submitNote()}>Add note</button></div>
            </div>
          </div>
        </>
      )}

    </>
  );
}

export default ContactMessages;