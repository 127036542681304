import ReviewLanding from './components/ReviewLanding';
import NewUser from './components/NewUser';
import WriteReview from './components/WriteReview';
import ReviewSubmitted from './components/ReviewSubmitted';
import UserInfo from './components/UserInfo';
import { useState , useEffect } from 'react';
import { isAuthed, getCurrentUser } from '../../services/auth.service';

//*  This is going to be the main component that holds all the nested routes for the review pages
function Review() {
  //*Set state in this parent component
  //* Whenever a user clicks a card this state is set to the information about that company.
  const initialUserInfo = {
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    seniority: 0,
    department: "",
    company: "",
    company_size: 0,
    persona_type: 0,
    industry: 0,
  }

  const initialReviewInfo = {
    overall_quality: 0,
    ease_of_use: 0,
    service: 0,
    pricing: 0,
    review: "",
    recommend: 0,
  }

  // better comments extension
  // ? review?company=zoom
  // ? prepopulate query
  // ! check the header comp to auth the user
  // ! check the services file to check if the user is logged in

  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState({});
  const [stage, setStage] = useState(1);
  const [companySelected, setCompanySelected] = useState({});
  const [userInfo, setUserInfo] = useState({ ...initialUserInfo })
  const [form, setForm] = useState({ ...initialReviewInfo });

  useEffect(() => {
    const authenticate = async () => {
      let authed = isAuthed();
      setLoggedIn(authed);
    }

    const getUser = async () => {
      const res = getCurrentUser();
      setUser(res);
    }

    authenticate();
    getUser();
  }, []);

  return (
    <div className='container'>
        {stage === 1 && <ReviewLanding loggedIn={loggedIn} setCompanySelected={setCompanySelected} setStage={setStage} setUser={setUser}/>}

        {stage === 2 && <NewUser loggedIn={loggedIn} setStage={setStage} setUserInfo={setUserInfo} userInfo={userInfo} companySelected={companySelected}/> }

        {stage === 3 && <WriteReview loggedIn={loggedIn} companySelected={companySelected} setStage={setStage} form={form} setForm={setForm}/> }

        {stage === 4 && <UserInfo loggedIn={loggedIn} companySelected={companySelected} userInfo={userInfo} setUserInfo={setUserInfo} setStage={setStage} /> }   
        
        {stage === 5 && <ReviewSubmitted loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} userInfo={userInfo} form={form} companySelected={companySelected} setCompanySelected={setCompanySelected} setStage={setStage} /> }
    </div>
  )
};

export default Review;