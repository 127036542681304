import { useState, useEffect } from 'react';
import AppMobile from './AppMobile';
import AppLanding from './AppLanding';
import { Helmet } from 'react-helmet';

const AppHome = () => {
  // Initialize a state variable to track the screen width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add an event listener to update the windowWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define a breakpoint value for switching between mobile and desktop views
  const breakpoint = 1000; // Adjust this value as needed
  return <>{windowWidth < breakpoint ? <AppLanding /> : <AppLanding />}</>;
};

export default AppHome;
