import { useState, useEffect } from "react";
import './EventDashboard.css';
import { getHostedEvents } from '../../utils/api';
import {
  getCurrentUser,
  isAuthed,
  logout,
  isSeller,
  isBuyer,
  refresh
} from '../../services/auth.service';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import Spinner from "../../components/layout/Spinner";
import moment from 'moment-timezone';
import FadeIn from "../../utils/fadein";
import { FaCalendar, FaCalendarAlt, FaEdit, FaGlobe, FaMapMarkerAlt, FaMicrophoneAlt, FaPlus, FaRegCalendarAlt, FaRegCalendarMinus, FaRegCalendarTimes, FaRegUser, FaSlidersH, FaUnlock } from "react-icons/fa";

function EventDashboard() {
    const [pastEvents, setPastEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [webinars, setWebinars] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    let { id } = useParams();
    const navigate = useNavigate();

    async function loadEvent(){
      let res = await getHostedEvents();

      if (res.success){
        let data = res.data;
        let pastdata = res.data.filter(x => {
          let date = new Date();
          let event_date = new Date(x.start_date);

          return event_date < date;
        });

        let upcomingdata = res.data.filter(x => {
          let date = new Date();
          let event_date = new Date(x.start_date);

          return event_date > date;
        });

        let webinardata = res.data.filter(x => {
          return x.is_webinar == true && new Date(x.start_date) > new Date() - 1;
        })

        setEvents(upcomingdata);
        setPastEvents(pastdata);
        setWebinars(webinardata);

        setLoaded(true);
      }
    }

    useEffect(() => {
      loadEvent();
    }, []);

    return (
        <div className="eventdashboard">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />

          <title>{`LeadrPro.com - Manage Events/Webinars`}</title>
        </Helmet>

        <div className="eventdashboard__list">
          <h1>Manage Events / Webinars</h1>

          <p>
            Create, manage and edit your local events and digital webinars.
          </p>

          {loaded === false && (
            <Spinner />
          )}

          {loaded === true && (
            <FadeIn delay={300} duration={300}>
              <div className="mt-20" style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <div className="eventdashboard__filter">
                  <span className={`eventdashboard__filter-item${selectedTab==1?' selected':''}`} onClick={() => setSelectedTab(1)}><FaRegCalendarAlt /> Upcoming Events</span>

                  <span className={`eventdashboard__filter-item${selectedTab==3?' selected':''}`} onClick={() => setSelectedTab(3)}><FaMicrophoneAlt /> Webinars</span>

                  <span className={`eventdashboard__filter-item${selectedTab==2?' selected':''}`} onClick={() => setSelectedTab(2)}><FaRegCalendarMinus /> Past Events</span>
                </div>

                <div>
                  <Link className="btn" to='/create-event'><FaPlus /> Create new event</Link>
                </div>
              </div>

               {/* UPCOMING */}
              {selectedTab === 1 && (
                <>
              {loaded === true && events.filter(x => x.is_webinar === false).length === 0 && (
                <div className="center">
                  <i>You have no upcoming events coming up...</i>
                </div>
              )}

              {events.filter(x => x.is_webinar === false).map((item, index) => (
                <div className="eventdashboard__item">
                <div className="eventdashboard__item-thumbnail">
                  {item.image_url && (
                    <img src={item.image_url} />
                  )}

                  {(item.image_url == null || item.image_url.length === 0) && (
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                  )}
                </div>
  
                <div className="eventdashboard__item-info">
                  <div className="eventdashboard__item-info-name">
                    {item.name} <span className={`eventdashboard__status eventdashboard__status--${item.status.toLowerCase()}`}>{item.status}</span>
                  </div>
  
                  <div className="eventdashboard__item-info-date">
                    <div><FaRegCalendarAlt /> Starts on: {new Date(item.start_date).toLocaleString()}</div>
                    <div><FaRegCalendarAlt /> Ends on: {new Date(item.end_date).toLocaleString()}</div>
                  </div>
  
                  <div className="eventdashboard__item-info-location">
                    <FaMapMarkerAlt /> Location:
                      {(item.city != null || item.state != null) && (
                        <span className="ml-10">{item.city} {item.state}
                        </span>
                      )}

                      {(item.city == null && item.state == null && (
                        <span className="ml-10"><i>Not Set</i></span>
                      ))}
                  </div>

                  <div>
                    <div><FaRegUser /> Guest count: <span className="eventdashboard__green-flag"><strong>{item.guest_count}</strong></span></div>
                  </div>

                  <div style={{marginTop: '10px', display: 'flex', marginTop:'20px'}}>
                    <div><Link to={`/manage-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaSlidersH /> Manage</Link></div>

                    <div style={{marginLeft: '10px'}}><Link to={`/edit-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaEdit /> Edit</Link></div>

                    <div style={{marginLeft: '10px'}}>
                      <Link to={`/event/${item.event_id}/aaa`} className="btn"><FaGlobe /> Public page</Link>
                    </div>
                  </div>
                </div>
              </div>
              ))}
              </>
              )}

            {selectedTab === 3 && (
                <>
              {loaded === true && webinars.length === 0 && (
                <div className="center">
                  <i>You have no webinars coming up...</i>
                </div>
              )}

              {webinars.map(item => (
                <div className="eventdashboard__item">
                <div className="eventdashboard__item-thumbnail">
                  {item.image_url && (
                    <img src={item.image_url} />
                  )}

                  {(item.image_url == null || item.image_url.length === 0) && (
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                  )}
                </div>
  
                <div className="eventdashboard__item-info">
                  <div className="eventdashboard__item-info-name">
                    {item.name} <span className={`eventdashboard__status eventdashboard__status--${item.status.toLowerCase()}`}>{item.status}</span>
                  </div>
  
                  <div className="eventdashboard__item-info-date">
                    <div><FaRegCalendarAlt /> Starts on: {new Date(item.start_date).toLocaleString()}</div>
                    <div><FaRegCalendarAlt /> Ends on: {new Date(item.end_date).toLocaleString()}</div>
                  </div>
  
                  <div className="eventdashboard__item-info-location">
                    <FaMapMarkerAlt /> Location: {item.webinar_url && item.webinar_url.length > 0 && (
                      <span><a className="link" href={item.webinar_url} target="_blank">URL</a></span>
                    )}

                    {(item.webinar_url == null || item.webinar_url.length == 0 && (
                      <span className="createevent__red-flag">Not set</span>
                    ))}
                  </div>

                  <div>
                    <div><FaRegUser /> Guest count: <span className="eventdashboard__count"><strong>{item.guest_count}</strong></span></div>
                  </div>

                  <div style={{marginTop: '10px', display: 'flex', marginTop:'20px'}}>
                    <div><Link to={`/manage-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaSlidersH /> Manage</Link></div>

                    <div className="ml-10"><Link to={`/edit-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaEdit /> Edit</Link></div>

                    <div className="ml-10">
                      <Link to={`/event/${item.event_id}/aaa`} className="btn"><FaGlobe /> Public page</Link>
                    </div>
                  </div>
                </div>
              </div>
              ))}
              </>
              )}

{selectedTab === 2 && (
                <>
          {loaded === true && pastEvents.length === 0 && (
            <div className="center">
              <i>No past events found...</i>
            </div>
          )}

              {pastEvents.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).map(item => (
                <div className="eventdashboard__item">
                <div className="eventdashboard__item-thumbnail">
                  {item.image_url && (
                    <img src={item.image_url} />
                  )}

                  {(item.image_url == null || item.image_url.length === 0) && (
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                  )}
                </div>
  
                <div className="eventdashboard__item-info">
                  <div className="eventdashboard__item-info-name">
                    {item.name} <span className={`eventdashboard__status eventdashboard__status--${item.status.toLowerCase()}`}>{item.status}</span>
                  </div>
  
                  <div className="eventdashboard__item-info-date">
                    <div><FaRegCalendarAlt /> Starts on: {new Date(item.start_date).toLocaleString()}</div>
                    <div><FaRegCalendarAlt /> Ends on: {new Date(item.end_date).toLocaleString()}</div>
                  </div>
  
                  <div className="eventdashboard__item-info-location">
                    <FaMapMarkerAlt /> {item.city}, {item.state}
                  </div>

                  <div>
                    {item.guest_count != 0 && (
                    <div><FaRegUser /> Guest count: <span className="eventdashboard__green-flag">{item.guest_count}</span></div>
                    )}

                    {item.guest_count == 0 && (
                    <div><FaRegUser /> Guest count: <span className="eventdashboard__grey-flag">{item.guest_count}</span></div>
                    )}
                  </div>

                  <div style={{marginTop: '10px', display: 'flex', marginTop:'20px'}}>
                    <div><Link to={`/manage-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaSlidersH/> Manage</Link></div>

                    <div style={{marginLeft: '10px'}}><Link to={`/edit-event/${item.event_id}`} className="btn" style={{padding: '5px'}}><FaEdit /> Edit</Link></div>

                    <div style={{marginLeft: '10px'}}>
                      <Link to={`/event/${item.event_id}/aaa`} className="btn"><FaGlobe /> Public page</Link>
                    </div>
                  </div>
                </div>
              </div>
              ))}
              </>
              )}
            </FadeIn>
          )}
        </div>
        </div>
    );
}

export default EventDashboard;