import './SoftwareOptIn.css';
import { useState, useEffect } from "react";
import { getCategories, searchCompanyCategories, updateCompanyCategory, updateCompany } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import FadeIn from '../../utils/fadein';
import list from '../../assets/design/signin/list.png';

function SoftwareOptIn({company_id, setCurrentStep}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [categoryResults, setCategoryResults] = useState([]);
    const [categoryQuery, setCategoryQuery] = useState('');
    const [isSoftwareCompany, setIsSoftwareCompany] = useState(false);
    const [showCategories, setShowCategories] = useState(false);

    async function categorySearch(query){
      if (query.length > 0){
        setShowCategoryDropdown(true);
        let data = await searchCompanyCategories(query);

        if (data.data){
            setCategoryResults(data.data);
        }
      }
    }

    useEffect(() => {
      // show categories
      if (isSoftwareCompany){
        setShowCategories(true);
      }

      else{
        setShowCategories(false);
      }
    }, [isSoftwareCompany]);

    async function selectCategory(category_id, name){
      console.log('id', category_id, 'name', name);

      let res = await updateCompanyCategory(category_id);
  
      if (res.success){
        setCategories([...categories, {category_id, name}]);
        setCategoryQuery('');
        setShowCategoryDropdown(false);
      }
    }

    async function handleCategoryChange(e){
      setCategoryResults([]);
      setCategoryQuery(e.target.value);
      await categorySearch(e.target.value);
    }

    async function handleSubmit(){
      if (isSoftwareCompany){
        await updateCompany({is_software_company: isSoftwareCompany});
      }

      setCurrentStep(4);
    }

    async function handleRemove(id){
      let res = await updateCompanyCategory(id);
    
      if (res.success){
        let arr = categories.filter(item => item.category_id !== id);
        setCategories(arr);
    
        //await updateCompanyCategories();
      }
    }

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="card softwareoptin createaccount">
            <div className="createaccount__left">
            <h1 className="card__header createaccount__left-header">Is your company a software company?</h1>
                  <div className="form__group">
                    <input type="radio" id="radioYes" name="issoftware" onClick={() => setIsSoftwareCompany(true)} />
                    <label htmlFor='radioYes'>Yes</label>
                  </div>

                  <div className="form__group">
                    <input type="radio" id="radioNo" name="issoftware" onClick={() => setIsSoftwareCompany(false)} />
                    <label htmlFor='radioNo'>No</label>
                  </div>

                  {showCategories && (
                  <FadeIn delay={250} duration={450}>
                  <div className="form__group" style={{marginTop: "30px"}}>
                    <h3>Software categories</h3>

                    <div style={{position:"relative"}}>
                      <input type="text" name="category" id="txtCategory" value={categoryQuery} onChange={handleCategoryChange}  className="category-dropdown" placeholder="Choose from the list" />

                        {categoryResults.length > 0 && showCategoryDropdown && (
                        <div className="searchfilter__search-results">
                            {categoryResults.map((item, index) => (
                                <div key={index} className="home__hero-category-results-item" onClick={() => selectCategory(item.category_id, item.name)}><span>{item.name}</span></div>
                            ))}
                        </div>
                        )}
                    </div>

                    <div className="companyedit__categories">
                      {categories !== undefined && categories.map((item, index) => (
                        <div className="companyedit__category" key={index}>
                          <span>{item.name}</span>

                          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove(item.category_id)}>
                          <path d="M2.82812 9.32812L8.48498 3.67127" stroke="#BEBEBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M2.82812 3.67188L8.48498 9.32873" stroke="#BEBEBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                      ))}
                      </div>
                  </div>
                  </FadeIn>
                  )}

                  {hasError === true && (
                    <div className="form-group login__error">
                        <span className={classNames("label", {"label--error":hasError})}>{errorMessage}</span>
                    </div>
                  )}

                  <div className="center" style={{marginTop:"50px"}}>
                    <button className='btn btn-primary' onClick={handleSubmit}>
                        Save and continue
                    </button>
                  </div>
                </div>

                <div className="createaccount__right">
                    <div className="createaccount__right-header">
                        Let us know if the company you are signing up with is a software company or not.
                    </div>

                    <div className="createaccount__right-subheader">
                      If you are a software company please choose at least one category your company falls under!
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoftwareOptIn;