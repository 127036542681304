import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import queryString from 'query-string';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getMonthlyReport, getMonthlyReportByMonth, getMailOutboxCampaigns, getMailOutboxEmais } from '../../utils/api/admin';
import './MailOutbox.css';

function MailCampaign({campaignId}) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [emails, setEmails] = useState([]);
  const [emailsLoaded, setEmailsLoaded] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showEmails, setShowEmails] = useState(false);
  // const [campaignId, setCampaignId] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getMailOutboxCampaigns();
    if (res.success){
      setCampaigns(res.data);
      setLoaded(true);
    }
  }

  // useEffect(() => {
  //   const parsed = queryString.parse(window.location.search);
  //   let param = parsed['id'];

  //   if (param && param.length > 0)
  //     setCampaignId(param);
  // }, []);

  useEffect(() => {
    async function loadData(){
      let res = await getMailOutboxCampaigns();
      if (res.success){
        setCampaigns(res.data);
        setLoaded(true);
      }

      let res2 = getMailOutboxEmais(campaignId);

      if (res2.success){
        setEmails(res2.data);
        setEmailsLoaded(true);
      }
    }

    loadData();
  }, [campaignId]);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleShowEmails(mail_outbox_campaign_id){
    setShowEmails(true);
    setSelectedCampaignId(mail_outbox_campaign_id);
  }

  useEffect(async () => {
    if (selectedCampaignId){
      let res = await getMailOutboxEmais(selectedCampaignId);

      if (res.success){
        setEmails(res.data);
        setEmailsLoaded(true);
      }
    }
  }, [selectedCampaignId])

  return (
    <>
      <Helmet>
        <title>Admin - Mail Outbox</title>
      </Helmet>

      <h1>Mail Outbox</h1>

      <p>
        Outbound email campaigns with tracking.
      </p>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
          <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Date sent</th>
              <th>Name</th>
              <th>Template</th>
              <th>Source</th>
              <th>Source ID</th>
              <th></th>
            </tr>
            {campaigns.map(item => (
              <tr>
                <td>{new Date(item.date_created).toLocaleDateString()}</td>
                <td>{item.name}</td>
                <td>{item.template_name}</td>
                <td>{item.type}</td>
                <td>{item.source_id}</td>

                <td>
                  <button className='btn' onClick={() => handleShowEmails(item.mail_outbox_campaign_id)}>View emails</button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {emailsLoaded && (
        <>
      <h2 className='modal__h2'>Mail Outbox</h2>

      <div className='mt-10'>
        <table className='admindashboard__table table'>
          {emails.map(item => (
            <tr>
              <td>{item.email}</td>
              <td>{item.first_name}</td>

              <td>
              <div className={`admindashboard__demo-request-status ${item.status.toLowerCase()}`}>
                {item.status}
              </div>
            </td>
            </tr>
          ))}
        </table>
      </div>
      </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default MailCampaign;