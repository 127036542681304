import { useState, useEffect } from 'react';
import close from '../../assets/design/dashboard/close.png';
import futureDemoNoticeCalendar from '../../assets/design/dashboard/future-demo-notice-calendar.png';
import LeadCard from '../../components/qualifier/LeadCard';
import RescheduleConfirmation from '../../components/qualifier/RescheduleConfirmation';
import ReassignDemo from './ReassignDemo';
import DeclinedPopup from './DeclinedPopup';
import CalendarLinks from '../../components/qualifier/CalendarLinks';
import { sellerReschedule, getSellerDeclinedReasons, getSellerDemosReschedule, refreshToken } from '../../utils/api';
import { checkSession } from '../../utils/utils';
import FadeIn from '../../utils/fadein';
import { Tooltip } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentUser, refresh } from '../../services/auth.service';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import Spinner from '../../components/layout/Spinner';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import SellerDecline from './SellerDecline';

const SellerDemosRescheduled = ({ }) => {
  const [showFutureDemoNotice, setShowFutureDemoNotice] = useState(false);
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [demoId, setDemoId] = useState(0);
  const [currentDemo, setCurrentDemo] = useState({});
  const [showReschedule, setShowReschedule] = useState(false);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const [showRescheduleConfirmation, setShowRescheduleConfirmation] =
    useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [selectedDemo, setSelectedDemo] = useState({});
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';
    document.cookie =
      cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  async function handleToggleMenu(item){
    setCurrentDemo(item);
    setShowSubMenu(!showSubMenu);
  }

  useEffect(() => {
    async function fetchSellerDeclinedReasons() {
      let data = await getSellerDeclinedReasons();
      if (data.success) {
        setSellerDeclinedReasons(data.data);
      }
    }

    fetchSellerDeclinedReasons();
    checkSession('Seller Reschedules');
  }, []);

  async function loadDemos(){
    let res = await getSellerDemosReschedule();

    if (res.success){
      setDemos(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getSellerDemosReschedule();

      if (res.success){
        setDemos(res.data);
      }

      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadDemos(){
      let res = await getSellerDemosReschedule();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await getSellerDemosReschedule();

          if (res.success){
            setDemos(res.data);
          }

          setLoaded(true);
        }
      }
    }

    loadDemos();
  }, []);

  async function refreshDemos(){
    let res = await getSellerDemosReschedule();

    if (res.success){
      setDemos(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

        let res = await getSellerDemosReschedule();

        if (res.success){
          setDemos(res.data);
        }

        setLoaded(true);
      }
    }
  }

  function futureDemoNoticeClick(demo, id) {
    setShowFutureDemoNotice(!showFutureDemoNotice);
    setDemoId(id);
    setCurrentDemo(demo);
  }

  function handleNeedsReviewClick(demo) {
    setCurrentDemo(demo);
    setShowRescheduleConfirmation(true);
  }

  function declinedClickHandle(demo, id) {
    setCurrentDemo(demo);
    setShowDeclinedPopup(true);
  }

  function handleRescheduleClick(item) {
    setSelectedDemo(item);
    setShowFutureDemoNotice(false);
    setShowReschedule(true);
  }

  async function handleReassignClick(demo) {
    setCurrentDemo(demo);
    setShowReassign(true);
  }

  async function confirmReschedule() {
    await sellerReschedule(currentDemo.demo_id);

    let _filter = demos.filter((tmp) => tmp.demo_id !== currentDemo.demo_id);
    let _obj = Object.assign({}, currentDemo);
    _obj.seller_status = 'Rescheduled';
    _filter.push(_obj);
    setDemos(_filter);
    setShowReschedule(false);
  }

  function handleAnswersClick(item) {
    setCurrentDemo(item);
    setShowQualifierAnswers(true);
  }

  return (
    <>
      <div>
        {showQualifierAnswers && (
          <>
            <div className='overlay'></div>
            <LeadCard
              currentDemo={currentDemo}
              setShowQualifierAnswers={setShowQualifierAnswers}
              show_buttons={false}
            />
          </>
        )}

        {showFutureDemoNotice && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={futureDemoNoticeClick}>
                <FaTimes />
              </div>

              <div className='center'>
                <div style={{ marginBottom: '10px' }}>
                  <img src={futureDemoNoticeCalendar} />
                </div>

                <p>Your meeting is scheduled for</p>

                <div className='dashboarddemo__join-meeting-date'>
                  {!currentDemo.reschedule_date_time && (
                    <>
                      <div style={{ marginBottom: '10px' }}>
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleTimeString()}
                      </div>

                      <CalendarLinks
                        scheduled_date_time={currentDemo.scheduled_date_time}
                        persona_type={currentDemo.company_name}
                        reference_id={currentDemo.reference_id}
                      />
                    </>
                  )}

                  {currentDemo.reschedule_date_time && (
                    <>
                      <div>
                        {new Date(
                          currentDemo.reschedule_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.reschedule_date_time
                        ).toLocaleTimeString()}
                      </div>

                      <CalendarLinks
                        scheduled_date_time={currentDemo.reschedule_date_time}
                        persona_type={currentDemo.company_name}
                        reference_id={currentDemo.reference_id}
                        meeting_link={currentDemo.company_meeting_link}
                      />
                    </>
                  )}
                </div>

                <div style={{ marginTop: '10px' }}>
                  <div className='modal__header'>House Rules</div>

                  <div className='dashboarddemo__house-rules'>
                    <div>
                      * Be on time and on a computer with a functional webcam
                    </div>
                    <div>
                      * Do not reschedule within 24 hours of the demo time
                    </div>
                  </div>
                </div>

                <div>
                  Need to{' '}
                  <span
                    className='link'
                    onClick={() => handleRescheduleClick(currentDemo)}>
                    Reschedule
                  </span>
                  ?
                </div>

                <div style={{ marginTop: '40px' }}>
                  <a
                    href={'/dashboard/message/' + currentDemo.reference_id}
                    className='btn btn--primary'>
                    <svg
                      style={{
                        height: '14px',
                        fill: 'white',
                        marginRight: '10px',
                      }}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'>
                      <path d='M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z' />
                    </svg>{' '}
                    Send a message
                  </a>
                </div>

                <div className='center' style={{ marginTop: '20px' }}>
                  {currentDemo.company_meeting_link && (
                    <a
                      className='btn btn--primary btn--apply'
                      href={`${currentDemo.company_meeting_link}`}
                      target='_blank'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 576 512'
                        style={{
                          height: '14px',
                          fill: 'white',
                          marginRight: '10px',
                        }}>
                        <path d='M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z' />
                      </svg>{' '}
                      Join meeting
                    </a>
                  )}

                  {!currentDemo.company_meeting_link && (
                    <a
                      className='btn btn--primary btn--apply'
                      href={`https://meetings.leadrpro.com/demo?refid=${currentDemo.reference_id}`}
                      target='_blank'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 576 512'
                        style={{
                          height: '14px',
                          fill: 'white',
                          marginRight: '10px',
                        }}>
                        <path d='M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z' />
                      </svg>{' '}
                      Join meeting
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {!loaded && (
          <Spinner />
        )}

        {demos.filter(
          (demo) =>
            (demo.buyer_status === 'Rescheduled' ||
              demo.seller_status === 'Rescheduled') &&
            demo.demo_status === 'Pending'
        ).length === 0 && loaded && (
          <div className='dashboarddemo__no-results'>
              You have no rescheduled demos.
          </div>
        )}

        {demos.length > 0 && (
          <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
          <tr>
            <th></th>
            <th>Company</th>
            <th>Original Date</th>
            <th>Assigned</th>
            <th>Feedback</th>
            <th></th>
          </tr>
        {demos
          .filter(
            (demo) =>
              (demo.seller_status === 'Rescheduled' ||
                demo.buyer_status === 'Rescheduled') &&
              demo.demo_status === 'Pending'
          )
          .map((item, index) => (
            <tr>
              <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              </td>

              <td style={{width: '25%'}}>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>

                        {/* {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <Link to={`/dashboard/message/${item.reference_id}`} className='dashboarddemo__open-chat'>Open Chat</Link>
                        )} */}
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </span>
                  </div>
                </td>

                <td>
                  <div className='dashboarddemo__company-info-items'>
                  <div className='dashboarddemo__company-info-item'>
                    <span className='dashboarddemo__company-info-label'>
                      {item.demo_date && (
                        <span className='dashboard__table-date'>
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                        </span>
                      )}

                      {item.demo_date == null && (
                        <span style={{fontWeight: 'normal'}}>
                          <i>Previously not scheduled</i>
                        </span>
                      )}
                    </span>
                  </div>
                  </div>
                </td>

                <td>
                <div className='center'>
                    <div>
                      <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                      {item.seller_profile_image && item.seller_profile_image != '' && (
                        <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                      )}

                      {!item.seller_profile_image && (
                        <img src={iconCompanyDefault} alt="logo not found" />
                      )}
                      </div>
                      <div style={{fontSize: "12px"}}>
                        {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                      </div>
                    </div>
                  </div>
                </td>

                <td className='dashboarddemo__actions'>
                  {item.buyer_status !== 'Rescheduled' &&
                    item.seller_status !== 'Rescheduled' && (
                      <div>
                        <button className='btn btn--primary' onClick={() =>
                            futureDemoNoticeClick(item, item.demo_id)
                          }>
                          Meeting Details
                        </button>
                      </div>
                    )}

                  {item.buyer_status === 'Rescheduled' && (
                      <span className='dashboarddemo__rescheduling-request-tag' style={{cursor: "pointer"}} onClick={() => handleNeedsReviewClick(item)}>
                        <span style={{verticalAlign: "middle"}}>View New Times</span>
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <p className='dashboarddemo__actions-text'>
                          Waiting for times
                      </p>
                    )}
                  </td>

                  <td>
                  <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item' onClick={() => handleAnswersClick(item)}>Buyer Details</div>

                      {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                      )}

                      <div className='dashboard__submenu-item' onClick={() => handleReassignClick(item)}>Reassign</div>

                      <div className='dashboard__submenu-item' onClick={() => declinedClickHandle(item, item.demo_id)}>
                        Cancel Demo
                      </div>
                    </div>
                    </FadeIn>
                    )}
                  </td>
            </tr>
          ))}
          </table>
          </div>
        )}
      </div>

      {showDeclinedPopup && (
        <div>
          <SellerDecline
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Seller'
            companyName={currentDemo.company_name}
            sellerDeclinedReasons={sellerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
          />

          {/* <DeclinedPopup
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Seller'
            companyName={currentDemo.company_name}
            sellerDeclinedReasons={sellerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
          /> */}
        </div>
      )}

      {showRescheduleConfirmation && (
        <>
          <div className='overlay'></div>
          <div className='modal dashboard__modal-reschedule'>
          <RescheduleConfirmation
            currentDemo={currentDemo}
            demos={demos}
            setDemos={setDemos}
            setShowRescheduleConfirmation={setShowRescheduleConfirmation}
            remove_when_done={false}
          />
          </div>
        </>
      )}

      {showReassign === true && (
        <>
          <div className='overlay'></div>
          <div className='modal dashboard__modal-reassign' style={{ maxHeight: '80%', overflow: 'auto' }}>
            <div className='modal__close' onClick={() => setShowReassign(false)}>
              <FaTimes />
            </div>
            <ReassignDemo
              demo_id={currentDemo.demo_id}
              demos={demos}
              setDemos={setDemos}
              refresh={loadDemos}
            />

            <div className='center'>
              <span
                className='link link--skip'
                onClick={() => setShowReassign(false)}>
                Close
              </span>
            </div>
          </div>
        </>
      )}

      {showReschedule && (
        <>
          <FadeIn delay={150} duration={200}>
            <div className='overlay'></div>

            <div className='modal'>
              <div className='modal__header center'>
                Are you sure you want to reschedule this demo? The original date
                will no longer be valid and the other participant will be
                required to submit new available times.
              </div>

              <div className='center'>
                <button
                  className='btn btn--primary btn--dark'
                  onClick={() => confirmReschedule()}>
                  Confirm
                </button>
              </div>

              <div className='modal__close'>
                <img src={close} onClick={() => setShowReschedule(false)} />
              </div>
            </div>
          </FadeIn>
        </>
      )}
    </>
  );
};

export default SellerDemosRescheduled;
