import { Routes, Route, Link, Outlet } from 'react-router-dom';
import './AccountWrapper.css';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { isBuyer, isSeller, getCurrentUser, isAuthed, isEventHost, isAdmin} from '../../services/auth.service';
import { FaComment, FaDot, FaCommentAlt, FaRegComment, FaUser, FaUsers, FaChartPie, FaRegEnvelope, FaUserCog, FaRegComments, FaLink, FaShapes, FaSearch, FaDotCircle, FaStar, FaMoneyCheck, FaRegStar, FaChartLine, FaSlidersH, FaVectorSquare, FaRegUser, FaTicketAlt, FaUserLock, FaDollarSign, FaQuestionCircle, FaUserCircle} from 'react-icons/fa';
import ProfileCard from '../dashboard/ProfileCard';
import { useEffect } from 'react';
import { getFeedUnreplyCount, getUserCompany, getMessageUnreadCount, hasUnreadMessages, adminCheck } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Support from '../../components/support/Support';
import icon_settings from '../../assets/design/account/icon_settings.png';
import icon_inbox from '../../assets/design/account/icon_inbox.png';
import icon_team from '../../assets/design/account/icon_team.png';
import icon_addons from '../../assets/design/account/icon_addons.png';
import icon_dashboard_seller from '../../assets/design/account/icon_dashboard_seller.png';
import icon_howitworks from '../../assets/design/account/icon_howitworks.png';
import icon_referral from '../../assets/design/account/icon_referral.png';
import icon_help from '../../assets/design/account/icon_help.png'; 

function AccountWrapper({view}) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [unreplyCount, setUnreplyCount] = useState(0);
  const [userCompany, setUserCompany] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [hasBuyerUnread, setHasBuyerUnread] = useState(false);
  const [hasSellerUnread, setHasSellerUnread] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [hasCompany, setHasCompany] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [shortMenu, setShortMenu] = useState(false);
  const currentUser = getCurrentUser();

  async function loadUserCompany(){
    let res = await getUserCompany();

    if (res.success){
      setUserCompany(res.data);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }
    }

    else{
      setHasCompany(false);
    }

    setLoaded(true);
  }

  async function loadUnreplyCount(){
    let res = await getFeedUnreplyCount();

    if (res.success){
      setUnreplyCount(res.data);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getFeedUnreplyCount();

      if (res.success){
        setUnreplyCount(res.data);
      }
    }
  }

  async function checkUnread(){
    let res = await hasUnreadMessages();

    if (res.success){
      if (res.data){
        setHasUnread(true);
      }

      else{
        setHasUnread(false);
      }
    }

    if (res.status === 403){
      await refresh();

      let res = await hasUnreadMessages();

      if (res.success){
        if (res.data){
          setHasUnread(true);
        }

        else{
          setHasUnread(false);
        }
      }
    }
  }

  async function loadUnreadCount(){
    let res = await getMessageUnreadCount();

    if (res.success){
      setUnreadCount(parseInt(res.data));
    }

    else if (res.status === 403){
      await refresh();

      let res = await getMessageUnreadCount();

      if (res.success){
        setUnreadCount(parseInt(res.data));
      }
    }
  }

  useEffect(() => {
    loadUnreplyCount();
    loadUserCompany();
    checkUnread();
    loadUnreadCount();
  }, []);

  return (
    <>
    <div className='accountwrapper'>
      <div className={`accountmenu ${shortMenu == true? 'short': ''}`}>
        {/* <div>
          <ProfileCard />
        </div> */}

        {isAuthed() == false && isBuyer() === false && isSeller() === false && (
          <>
            <div className='accountwrapper__menu-links center'>
              <div>
                <button className='btn accountwrapper__btn-signup'>Create Free Account</button>
              </div>

              <div className='accountwrapper__btn-login'>
                <Link to='/login'>Sign in</Link>
              </div>
            </div>
          </>
        )}

        {(isBuyer() === true || isSeller() === true || isEventHost() == true) && (
        <div className='accountwrapper__menu-links'>
          {/* <div onClick={() => setShortMenu(!shortMenu)}>
            <svg style={{height: '15px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
          </div>  */}

        {isBuyer() && isSeller() == false && (
          <>
            <Link to='/dashboard/buyer' className={view==="buyerdashboard"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaRegComments />
              </span>{' '}

              <span className='accountwrapper__menu-item-name'>Dashboard</span>
            </Link>

            <div className='accountwrapper__submenu'>
              <div className='accountwrapper__submenu-item'>
                <Link to='/dashboard/buyer'>
                  <span><FaUsers /></span>
                  <span className='accountwrapper__menu-item-name'>Manage Demos</span>
                </Link>
              </div>
            </div>
          </>
        )}

      {isBuyer() && isSeller() == true && (
        <>
          <Link to='/dashboard/buyer' className={view==="buyerdashboard"?"selected":""}>
            <span className='accountwrapper__icon'>
              <FaRegComments />
            </span>{' '}
            <span className='accountwrapper__menu-item-name'>Buyer Dashboard</span>
          </Link>

          <div className='accountwrapper__submenu'>
              <div className='accountwrapper__submenu-item'>
                <Link to='/dashboard/buyer'>
                  <span><FaUsers /></span>
                  <span className='accountwrapper__menu-item-name'>Manage Demos</span>
                </Link>
              </div>
            </div>
          </>
        )}

       

        {isSeller() && (
          <>
          <div>
            <Link to='/dashboard/seller' className={view==="sellerdashboard"?"selected":""}>
              <span className='accountwrapper__icon'>
                <img src={icon_dashboard_seller} alt='' />
                </span>{' '}
              <span className='accountwrapper__menu-item-name'>Seller Dashboard</span>
            </Link>

            <div className='accountwrapper__submenu'>
              <div className='accountwrapper__submenu-item'>
                <Link to='/dashboard/seller'>
                  <span><FaUsers /></span>
                  <span className='accountwrapper__menu-item-name'>Manage Demos</span>
                </Link>
              </div>

              <div className='accountwrapper__submenu-item'>
                <Link to='/dashboard/my-price-leads' className={view==="priceleads"?"selected":""}>
                  <span><FaDollarSign /></span>
                  <span className='accountwrapper__menu-item-name'>Price Leads</span>
                </Link>
              </div>

              <div className='accountwrapper__submenu-item'>
                <Link to='/search/buyers' className={view==="prospect"?"selected":""}>
                  <span><FaSearch /></span>
                  <span className='accountwrapper__menu-item-name'>Prospect Search</span>
                </Link>
              </div>

              <div className='accountwrapper__submenu-item'>
                <Link to={'/account/team'} className={view==="team"?"selected":""}>
                  <span className='accountwrapper__icon'>
                    <img src={icon_team} alt='' />
                  </span>{' '}

                  <span className='accountwrapper__menu-item-name'>
                    Team
                  </span>
                </Link>
              </div>

              {isSeller() && userCompany && loaded === true && (userCompany.company_id == 375 || userCompany.company_id == 5105 || userCompany.company_id == 9384 || userCompany.company_id == 9672 || userCompany.company_id == 8975 || userCompany.company_id == 9978 || userCompany.company_id == 3270 || userCompany.company_id == 13060) && (
          <Link to={'/feed/company/' + userCompany.company_id} className={view==="feed"?"selected":""}>
            <span>
              <FaRegComment />
            </span>{' '}
            
            <span className='accountwrapper__menu-item-name'>
            Discussion
            {unreplyCount != '0' && (
              <>
              <span className='accountwrapper__feed-unreply-count'>{unreplyCount} New Posts</span>
              </>
            )}
            </span>
          </Link>
        )}

        <div className='accountwrapper__submenu-item'>
          <Link to={'/account/qualifiers'} className={view==="qualifiers"?"selected":""}>
            <span><FaQuestionCircle /></span>
              <span className='accountwrapper__menu-item-name'>Qualification Questions</span>
          </Link>
        </div>

        <div className='accountwrapper__submenu-item'>
          <Link to={'/account/icp'} className={view==="icp"?"selected":""}>
            <span><FaUserCircle /></span>
            <span className='accountwrapper__menu-item-name'>Ideal Customer Profile</span>
          </Link>
        </div>
      </div>
    </div>

          {/* <Link to='/s/recommendations' className={view==="sellerrecommendations"?"selected":""}>
            <span className='accountwrapper__icon'><FaRegStar /></span>{' '}
            <span>Available Buyers</span>
          </Link> */}

          {/* {userCompany && loaded === true && userCompany.enable_search && (
            <Link to='/search/buyers' className={view==="searchbuyers"?"selected":""}>
            <span className='accountwrapper__icon'><FaSearch /></span>{' '}
            <span>Search Buyers</span>
          </Link>
          )}

          {isAdmin() == true && (
            <Link to='/dashboard/my-price-leads' className={view==="searchbuyers"?"selected":""}>
            <span className='accountwrapper__icon'><FaDollarSign /></span>{' '}
            <span>Price Leads</span>
          </Link>
          )} */}
          </>
        )}

        {isEventHost() == true && (
          <>
            <Link to='/account/events' className={view==="eventdashboard"?"selected":""}>
              <span className='accountwrapper__icon'>
                <FaTicketAlt />
              </span>

              <span className='accountwrapper__menu-item-name'>Event Dashboard</span>
            </Link>
          </>
        )}

        {isAuthed() == true && (isBuyer() == true || isSeller() == true) && (
        <Link to='/dashboard/inbox' className={view==="inbox"?"selected":""}>
          <span className='accountwrapper__icon'>
            <img src={icon_inbox} alt="" />
          </span>{' '}
          <span className='accountwrapper__menu-item-name'>
            <span>Inbox</span> 

            {unreadCount > 0 && (
              <span className='header__unread-badge'>
                {unreadCount}
              </span>
            )}

            {
              unreadCount == 0 && (
                <span className='header__unread-badge' style={{backgroundColor:'#f2f2f2', color: '#aaa'}}>
                  0
                </span>
              )
            }
          </span>
        </Link>
        )}

        {/* {isBuyer() && (
          <Link to='/account/payouts' className={view==="payouts"?"selected":""}>
            <span className='accountwrapper__icon'>
              <FaMoneyCheck />
            </span>{' '}
              Payouts
          </Link>
        )} */}

        {isSeller() && (
          <Link to='/account/addons' className={view==="addons"?"selected":""}>
            <span className='accountwrapper__icon'>
              <img src={icon_addons} alt='' />
            </span>{' '}
            
            <span className='accountwrapper__menu-item-name'>Add-ons</span>
          </Link>
        )}

       

        {/* {isSeller() && userCompany && loaded === true && (
          <Link to={'/account/team'} className={view==="feed"?"selected":""}>
          <span className='accountwrapper__icon'>
            <img src={icon_team} alt='' />
          </span>{' '}

          <span className='accountwrapper__menu-item-name'>
            Team
          </span>
        </Link>
        )} */}

        {/* {isBuyer() && (
          <Link to='/dashboard/profile' className={view==="profile"?"selected":""}>
            <span className='accountwrapper__icon'>
              <FaRegUser />
            </span>{' '}
              My Profile
          </Link>
        )} */}

        
        <Link to='/account/home' className={view==="settings"?"selected":""}>
          <span className='accountwrapper__icon'>
            <img src={icon_settings} alt='' />
          </span>{' '}
          
          <span className='accountwrapper__menu-item-name'>
            Settings
          </span>
        </Link>

        {/* <div className='accountwrapper__links'>
        <div className='accountwrapper__feedback'>
                <span style={{fontSize: '11px', marginBottom: '10px'}}>Have a question, issue or comment?</span>
                <button className='btn btn--primary accountwrapper__btn-feedback' onClick={() => navigate('/leave-feedback')}>Send us a message</button>
              </div>
        </div> */}

        {/* <div className='accountwrapper__refer'>
          <div className='accountwrapper__refer-title'>
            Join our partner program
          </div>

          <p>
            **Earn $250 for every new seller and $50 for each new buyer that you refer to our platform. 
            <br/><br/>
            <a href="https://leadrpro.partnerstack.com/?group=leadrproaffiliatepartners
" target="_blank" className='link'>Join our referral program.</a>
          </p>
        </div> */}

        {/* <div className='mt-20 accountwrapper__app-icons'>
            <a href='https://apps.apple.com/us/app/leadrpro/id6449265881' target='_blank'>
              <img
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/appsotre.png?updatedAt=1682699351000'
              />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile' target='_blank'>
              <img
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/google-play-badge__3_.png?updatedAt=1682699483535'
              />
            </a>
            </div> */}
        </div>
        )}
      </div>

      <div className='account__content'>
        <Outlet />
      </div>

      <Support />
    </div>
    </>
  );
}

export default AccountWrapper;
