import './PlanCheckout.css';
import { useState, useEffect } from "react";
import { loginUser } from '../../utils/api';
import classNames from "classnames";
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser, refresh } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import { useLocation } from 'react-router-dom';
import { acceptDemoBuyer, getBuyerObjections, getSellerObjections, addBuyerFeedback, addSellerFeedback, getBuyerDemoFromRef, refreshToken, addUserEventTracking, createSetupIntent , getPackagePurchase, createPackagePurchaseConfirmation} from '../../utils/api';
import RescheduleTimes from "../../components/qualifier/RescheduleTimes";
import { FaAward, FaChartLine, FaHorseHead } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import PlanConfirmation from './PlanConfirmation';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
 import SetupForm from '../payment/SetupForm';
 import Spinner from '../../components/layout/Spinner';
 import FadeIn from '../../utils/fadein';

function PlanCheckout() {
    const [referenceId, setReferenceId] = useState('');
    const [hasError, setHasError] = useState(false);
    const search = useLocation().search;
    const [loaded, setLoaded] = useState(false);
    const [demos, setDemos] = useState([]);
    const [loading ,setLoading] = useState(true);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    let navigate = useNavigate();
    const [intent, setIntent] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [options, setOptions] = useState({});
    const [packagePurchase, setPackagePurchase] = useState(null);
    const [isCharging, setIsCharging] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    let tok = new URLSearchParams(search).get('cartid');

    useEffect(() => {
      async function loadPackagePurchase(){
        let res = await getPackagePurchase(tok);

        if (res.success){
          setPackagePurchase(res.data);
          setLoading(false);
        }

        else if (res.status === 403){
          await refresh();

          let res = await getPackagePurchase(tok);

          if (res.success){
            setPackagePurchase(res.data);
            setLoading(false);
          }
        }

        else{
          navigate('/select-plan');
        }
      }
         loadPackagePurchase();
    }, []);

    async function handleCreateIntent(e){
      e.preventDefault();
      let res = await createSetupIntent();
  
      if (res.success){
        setIntent(res.data);
  
        let _obj = {
          clientSecret: res.data.client_secret
        }
  
        setOptions(_obj);
        setShowForm(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await createSetupIntent();
  
        if (res.success){
          setIntent(res.data);
    
          let _obj = {
            clientSecret: res.data.client_secret
          }
    
          setOptions(_obj);
          setShowForm(true);
        }
      }
    }

    async function handleConfirm(){
      setIsCharging(true);
      let res = await createPackagePurchaseConfirmation(tok, packagePurchase.number_of_demos);

      if (res.success){
        setIsCharging(false);
        setShowConfirmation(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await createPackagePurchaseConfirmation(tok, packagePurchase.number_of_demos);

        if (res.success){
          setIsCharging(false);
          setShowConfirmation(true);
        }
      }
    }

    return (
      <FadeIn delay={400} duration={400}>
        <div className="center card__wrapper">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />
          <title>LeadrPro - Reschedule your demo</title>
        </Helmet>

        {loading === true && (
          <div className='center'>
            <Spinner />
          </div>
        )}
        {showConfirmation === false && loading === false && (
        <div className='selectplan plancheckout'>
          <div style={{textAlign: 'left', marginBottom: '10px'}} onClick={() => navigate(-1)}>
            <span className='link'>{`<< Back`}</span>
          </div>
          <h1>
            {packagePurchase?.name} <span className='plancheckout__demo-count'>{packagePurchase?.number_of_demos} {'Demo(s)'}

            {packagePurchase.package_extra_demos > 0 && (
              <span className='selectplan__plan-price-bonus'>{`(+${packagePurchase.package_extra_demos} bonus)`}</span>
            )}
            </span>

            </h1>
          {/* <h2>Buy demo's up front and save or choose pay as you go and only get charged when you complete a demo.</h2> */}

          <div className='plancheckout__panels'>
            <div className='plancheckout__left'>
              {loading === false && packagePurchase.payment_methods.length === 0 && (
                <>
                  <div className='plancheckout__left-header'>1. Add a valid payment method</div>
                  <div className='plancheckout__left-btn-wrapper'>
                    <button className='selectplan__btn' onClick={(e) => handleCreateIntent(e)}>Add Payment Method</button>
                  </div>
                </>
              )}

              {loading === false && packagePurchase.payment_methods.length > 0 && (
                <>
                  <div className='plancheckout__left-header'>By clicking 'Confirm Purchase' below you authorize LeadrPro to charge the card on file for the purchase of {packagePurchase.number_of_demos} demos. These demos do not expire and are only used up once you successfully complete a schedule meeting on our platform.</div>

                  <div className='plancheckout__left-btn-wrapper'>
                    {isCharging === false && (
                    <button className='selectplan__btn' style={{backgroundColor: 'mediumaquamarine'}} onClick={(e) => handleConfirm(e)}>Confirm Purchase</button>
                    )}

                    {isCharging && (
                      <Spinner />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className='plancheckout__right'>
              <div className='plancheckout__right-header'>
                Package Details
              </div>

              <div className='plancheckout__plan-blurb'>
                <span>{packagePurchase?.short_description}</span>
              </div>

              <div className=''>
                <div className='selectplan__plan-price'>
                  {packagePurchase?.modified_price == null && (
                    <>
                      <span className='selectplan__plan-price-amount'>${packagePurchase?.cost_per_demo}</span><span> per demo</span>
                    </>
                  )}

                  {packagePurchase.modified_price && (
                    <>
                      <div>
                        <span className='selectplan__plan-price-amount' style={{fontSize: 'small', textDecoration: 'line-through', color: 'red'}}>${packagePurchase?.cost_per_demo}</span><span style={{fontSize: 'small', textDecoration: 'line-through', color: 'red'}}> per demo</span>
                      </div>
                    <span className='selectplan__plan-price-amount'>${packagePurchase?.modified_price}</span><span> per demo</span>
                    </>
                  )}

                  {packagePurchase.extra_demos != null && packagePurchase.extra_demos != '0' && (
                    <>
                    <div style={{fontSize: 'small'}}>
                      {`(+ ${packagePurchase.extra_demos} extra demos)`}
                    </div>
                    </>
                  )}
                </div>
              </div>

              <div className='plancheckout__total'>
                <span className='plancheckout__total-label'>Total: </span>
                {packagePurchase?.modified_price == null && (
                  <span>${packagePurchase?.cost_per_demo * packagePurchase?.number_of_demos}</span>
                )}

                {packagePurchase?.modified_price != null && (
                  <span>${packagePurchase?.modified_price * packagePurchase?.number_of_demos}</span>
                )}
              </div>
            </div>

            {showForm && (
              <div className='plancheckout__form'>
            <Elements stripe={stripePromise} options={options}>
              <SetupForm redirecturl={`https://www.leadrpro.com/select-plan-checkout?cartid=${tok}`} />
            </Elements>
            </div>
          )}
          </div>
          </div>
        )}

          {showConfirmation && (
            <div className='selectplan plancheckout'>
            <h1>Order Complete</h1>
  
            <div className='planconfirmation__panels'>
              <div className='planconfirmation__left'>
                <div className='planconfirmation__text'>
                  You have allocated {packagePurchase.number_of_demos + packagePurchase.package_extra_demos} {'demo(s)'} to your account.
                </div>
  
                <div className='planconfirmation__text-small'>
                  Remember these demos do not expire and they are only deducted once you successfully complete a scheduled meeting.
                </div>
              </div>
  
              <div className='plancheckout__right'>
                <div className='plancheckout__right-header'>
                  Package Details
                </div>
  
                <div className=''>
                  <div className='selectplan__plan-price'>
                    <span className='selectplan__plan-price-amount'>{packagePurchase.number_of_demos} {'Demo(s)'}</span>

                    {packagePurchase.package_extra_demos > 0 && (
                      <span className='selectplan__plan-price-bonus'>{`(+${packagePurchase.package_extra_demos} bonus)`}</span>
                    )}

                    {packagePurchase.extra_demos != null && packagePurchase.extra_demos > 0 && (
                      <div className='' style={{fontSize: 'small'}}>
                        {`(+${packagePurchase.extra_demos} demos)`}
                      </div>
                    )}

                    <div style={{fontSize:'small', color: 'black', fontWeight: 'normal'}}>Do not expire</div>
                  </div>
                </div>
  
                <div className='plancheckout__total'>
                  <span className='plancheckout__total-label'>Order ID: </span>
                  <span>{`#${packagePurchase.reference_id.toUpperCase()}`}</span>
                </div>
              </div>
  
              <div className='plancheckout__left-btn-wrapper' style={{marginTop: "20px"}}>
                  <Link to={'/dashboard/seller'} className='selectplan__btn'>Go to your dashboard</Link>
                </div>
            </div>
            </div>
          )}
        </div>
        </FadeIn>
    );
}

export default PlanCheckout;