import './Calendar.css';
import {useEffect, useState} from 'react';
import iconGoogle from '../../assets/design/account/google.png';
import { getOAuthUrl, getOAuthTokens, calendarIsSynced, refreshToken, disconnectCalendar } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import queryString from 'query-string';
import Spinner from '../../components/layout/Spinner';
import { Routes, Route, Link, Outlet } from 'react-router-dom';

const Calendar = ({userType}) => {
  const [code, setCode] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [isSynced, setIsSynced] = useState(false);

  useEffect(() => {
    async function checkCalendar(){
      let res = await calendarIsSynced();

      if (res.success){
        if (res.data){
          // google calendar is synced
          setIsSynced(true);
          setLoaded(true);
        }

        else{
          setLoaded(true);
          setIsSynced(false);
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await calendarIsSynced();

        if (res.success){
          setIsSynced(true);
          setLoaded(true);
        }
      }

      else{
        setLoaded(true);
      }
    }

    checkCalendar();
  }, []);

  useEffect(() => {
    async function oauthCheck(){
      const search = window.location.href; // could be '?foo=bar'
      const params = new URLSearchParams(search);
      const foo = params.get('code'); // bar
      const parsed = queryString.parse(window.location.search);
    
      if (parsed["code"]){
        setCode(parsed["code"]);
        let res = await getOAuthTokens(parsed["code"]);
        setIsSynced(true);
      }
    }

    oauthCheck();
  }, []);

  async function handleGoogleCalendar(){
    let res = await getOAuthUrl();

    if (res.success){
      window.open(res.data);
    }
  }

  async function handleDisconnect(){
    let res = await disconnectCalendar();

    if (res.success){
      window.location = window.location;
    }

    else if (res.status == 403){
      await refresh();

      let res = await disconnectCalendar();

      if (res.success){
        window.location = window.location;
      }
    }
  }

  return (
    <div className="calendar">
      <div><Link to="/account/home" className='btn'>Back</Link></div>

      <div className="account__header">Calendar Sync</div>
      <div className="account__description" style={{fontSize: "14px", marginTop: "20px"}}>Automatically add scheduled demo's directly into your synced calendars.</div>

      <div className='accountinfo__form calendar__form'>
        <div className='calendar__header'>Google</div>

        <div className="calendar__item">
          <div className="calendar__icon"><img src={iconGoogle} alt="" /></div>
          <div className="calendar__title">Google Calendar</div>

          {loaded === false && (
            <Spinner setCompleted={setLoaded} />
          )}

          {!isSynced && loaded && (
            <div>
            <div className="calendar__btn">
              <button className='btn' onClick={() => handleGoogleCalendar()}>Connect</button>
            </div>
          </div>
          )}


      {isSynced && loaded && (
            <div>
              <div className="calendar__btn">
                <span style={{backgroundColor:"#afddaf", color:"black", padding: "5px 10px", borderRadius: "10px", fontSize: "12px"}}>Connected  ✔</span>
              
                <span className='link link--skip' style={{marginLeft: "10px"}} onClick={() => handleDisconnect()}>Disconnect</span>
              </div>
            </div>
      )}
        </div>
      </div>
    </div>
)
}

  export default Calendar;