
import './DemoInvite.css';
import {useEffect, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getPayoutTransactions, refreshToken, getUserProfile, getAllIndustries, getDemoFromInvite, saveDemoAvailableTimesForInvite, answerQualifierQuestion, getCompany, getCompanyFromSlug, SaveDemoInviteForCompany } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaRegClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaRegMoneyBillAlt, FaCamera, FaVideo } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DemoInviteForCompany = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [company, setCompany] = useState(null);
  const [demo, setDemo] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [token, setToken] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);
  const [answer, setAnswer] = useState('');
  const [done, setDone] = useState(false);
  const [answers, setAnswers] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const search = useLocation().search;
  const navigate = useNavigate();
  let tok = new URLSearchParams(search).get('refid');
  let { slug } = useParams();

  useEffect(() => {
    async function loadDemo(){
      let res = await getDemoFromInvite(tok);

      if (res.success){
        setDemo(res.data);
        setLoading(false);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getDemoFromInvite(tok);

          if (res.success){
            setDemo(res.data);
            console.log(res.data);
            setLoading(false);
          }
        }
      }
    }

    async function loadCompany(){
      let res = await getCompanyFromSlug(slug);

      if (res.success){
        setCompany(res.data[0]);
        console.log('company', res.data);
      }

      else{
        alert('nope');
      }
    }
      // based on users current role as buyer or seller

    loadDemo();
    loadCompany();
  }, []);

  async function handleConfirm(){
    if (Object.keys(answers).length === 0){
      alert("Please answer the question provided!");
      return;
    }

    if (date1 == null || date2 == null || date3 == null){
      alert("Pleave provide up to 3 available times!");
      return;
    }

    if (email.length === 0){
      alert("Please provide a valid email address");
      return;
    }

    let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {};
    data.name = name;
    data.email = email;
    data.date1 = date1;
    data.date2 = date2;
    data.date3 = date3;
    data.timezone = s_timezone;

    let body = '';
    body += `<p>Name: ${name}</p>`;
    body += `<p>Email: ${email}</p>`;
    body += `<p>Date 1: ${date1}</p>`;
    body += `<p>Date 2: ${date2}</p>`;
    body += `<p>Date 3: ${date3}</p>`;
    body += `<p>Timezone: ${s_timezone}</p>`;

    setSubmitting(true);

    let _times = [];
    _times.push(date1);
    _times.push(date2);
    _times.push(date3);

    let questionanswer = [];
    let strAnswers = '';

    for (let key in answers) {
      if (answers.hasOwnProperty(key)) {
        let obj = {};

        console.log(company.questions);
        let question = (company.questions.filter(x => x.qualifier_question_id == key));
        console.log('question', question);
        console.log(key + ': ' + answers[key]);
        body += '<div style="margin-top: 15px;margin-bottom: 15px;">';
        body += `<p><strong>Question:</strong> ${question[0].question}</p>`;
        body += `<p><strong>Answers:</strong> ${answers[key]}</p>`;
        body += '</div>';

        obj.question = question[0].question;
        obj.answer = answers[key];
        questionanswer.push(obj);
        strAnswers += `${question[0].question} - ${answers[key]}\n`;
        //await answerQualifierQuestion(key, answers[key], demo.demo_id);
      }
    }

    data.answers = strAnswers;

    await SaveDemoInviteForCompany(company.company_id, body, data);

    setDone(true);
    setShowConfirm(true);
    setSubmitting(false);

    //let res = await saveDemoAvailableTimesForInvite(tok, _times, s_timezone, answer);

    // if (res.success){
    //   setDone(true);
    //   setShowConfirm(true);
    //   setSubmitting(false);

    //   for (let key in answers) {
    //     if (answers.hasOwnProperty(key)) {
    //       console.log(key + ': ' + answers[key]);
    //       //await answerQualifierQuestion(key, answers[key], demo.demo_id);
    //     }
    //   }
    // }
  }

  async function handleBlur(e, id){
    //alert(e.target.value);
   // alert(id);
    //setAnswers()
    const _answers = { ...answers };
    _answers[id] = e.target.value;
    setAnswers(_answers);
  }

  useEffect(() => {
    console.log('answers', answers);
    console.log('count', Object.keys(answers).length);
    for (let key in answers) {
      if (answers.hasOwnProperty(key)) {
        console.log(key + ': ' + answers[key]);
        //await answerQualifierQuestion(key, answers[key], demo.demo_id);
      }
    }
  }, [answers]);

  return (
    <>
    <Helmet>
        <title>Schedule your demo</title>
      </Helmet>

    <div className="demoinvite demoinvite--full">
      {company && loading === false && (
        <>
      <div className='demoinvite__left'>
        <div className='demoinvite__header'>You're Invited!</div>

        <div className='demoinvite__icons'>
          <div><FaVideo /> <span className='demoinvite__icon-label'>Meeting with: <strong>{company.name}</strong></span></div>
          <div><FaRegClock /> <span className='demoinvite__icon-label'>Time: <strong>30 min</strong></span></div>
          <div><FaRegMoneyBillAlt /> <span className='demoinvite__icon-label'>You earn: <strong>$75</strong></span></div>
        </div>

        <div style={{marginTop: "30px"}}>
          <strong>Your name</strong>
          <input type='text' className='input' placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} />
        </div>

        <div style={{marginTop: "10px"}}>
          <strong>Email address</strong>
          <input type='text' className='input' placeholder='Your email address' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className='demoinvite__question'>
          <div><strong>Please answer the following question: </strong></div>
        </div>

        {company.questions && company.questions.length > 0 && (
          <div className='demoinvite__qqs'>
            {company.questions.map((q, index) => (
              <div className='demoinvite__qq'>
                <div>Q: {q.question}</div>

                <div>
                  <textarea className='input' placeholder='Answer...' onBlur={(e) => handleBlur(e, q.qualifier_question_id)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className='demoinvite__right'>
        <h1 className='demoinvite__h1'>Provide your availability</h1>

        <div className='demoinvite__p'>
          Select up to 3 times that you will be available in the next few days:
        </div>

        <div className='demoinvite__times'>
          <div>
            <div>Available time 1:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date1} onChange={(date) => setDate1(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>

          <div>
            <div>Available time 2:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date2} onChange={(date) => setDate2(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>

          <div>
            <div>Available time 3:</div>
            <div className='demoinvite__times-group'>
              <DatePicker selected={date3} onChange={(date) => setDate3(date)} showTimeSelect   dateFormat="Pp" />
            </div>
          </div>
        </div>

        <div>
          {done === false && (
            <button className='btn btn--primary demoinvite__btn-confirm' onClick={() => handleConfirm()}>Submit Times</button>
          )}

          {done && (
            <div className='demoinvite__confirm'>
              You're all set! We'll confirm with you shortly.
            </div>
          )}
        </div>
      </div>
      </>
      )}

      {loading && (
        <div style={{padding: "30px"}} className='center'>
          <Spinner />
        </div>
      )}
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal demoinvite__modal-confirm'>
          <div className='modal__close' onClick={() => setShowConfirm(false)}>
            <img src={close} />
          </div>
          
          <div className='demoinvite__modal-post-header'>
            Success!
          </div>

          <p className='demoinvite__modal-body'>
          Hang tight, and we'll confirm the date and time with you shortly.
          </p>

          <div>
            <button className='btn btn--primary' onClick={() => setShowConfirm(false)}>Close</button>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default DemoInviteForCompany;