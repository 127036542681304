import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  keyframes,
  Center,
  Image,
} from '@chakra-ui/react';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string,
  name: string,
  title: string,
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const TestimonialTest = () => {
  const size = '96px';
  const color = 'seagreen';

  const pulseRing = keyframes`
	0% {
    transform: scale(0.33);
  }
  40%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`;

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.700')}>
        <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
          <Stack spacing={0} align={'center'}>
            <Text
              fontSize={['lg', , '3xl']}
              lineHeight='tight'
              textTransform='uppercase'
              fontWeight='semibold'
              textAlign='center'>
              Hear What Our Users Have To Say
            </Text>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 10, md: 4, lg: 10 }}>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Easily Apply</TestimonialHeading>
                <TestimonialText>
                  Paying me for my attention? I love it! A great motivator to
                  discover new tools.
                </TestimonialText>
              </TestimonialContent>

              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/Screenshot%202023-09-08%20at%2008-18-37%20linkedin-sales-solutions-pAtA8xe_iVM-unsplash.jpg%20(WEBP%20Image%203149%20%C3%97%204724%20pixels)%20_%20Scaled%20(15_).png?updatedAt=1694186355120'
                }
                name={'VP, Enterprise Innovation'}
                title={'Travelers Insurance'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Innovative Discovery</TestimonialHeading>
                <TestimonialText>
                  Super innovative platform. With all the clutter and noise out
                  there, this just makes sense. I wish I had found LeadrPro
                  sooner!
                </TestimonialText>
              </TestimonialContent>

              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/hp2.png?updatedAt=1687907362762'
                }
                name={'Head Of Channel'}
                title={'Partnership At Meta'}
              />
            </Testimonial>
            <Testimonial>
              <TestimonialContent>
                <TestimonialHeading>Valuable Demos</TestimonialHeading>
                <TestimonialText>
                  I see value from these demos. Well worth my time since I can
                  pick and choose what applies to me.
                </TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={
                  'https://ik.imagekit.io/ivgrhmd4k/hp1.png?updatedAt=1687906236079'
                }
                name={'Director Of Customer Care'}
                title={'Ulta Beauty'}
              />
            </Testimonial>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default TestimonialTest;
