import './CreatePassword.css';
import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import { validateToken, setPassword } from '../../utils/api';
import React from "react";
import classNames from "classnames";
import Spinner from '../../components/layout/Spinner';
import {Helmet} from "react-helmet";

const CreatePassword = ({showPassword, setRoleType, setCurrentStep, roleType, setShowSelectRole, setUserId, setAuthenticated}) => {
    const [verified, setVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const [token, setToken] = useState('');
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    let [loaded, setLoaded] = useState(false);
    let [showError, setShowError] = useState(false);
    let inputPassword = React.createRef();  // React use ref to get input value
    let inputConfirm = React.createRef();

    useEffect(() => {
        async function handleValidate(){
            let tok = new URLSearchParams(search).get('token');
            let result = await validateToken(tok);
    
            if (result.success){
                setVerified(true);
                setToken(tok);
            }
    
            else{
                setVerified(false);
            }

            setLoaded(true);
        }
        
        handleValidate();
    }, []);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + d.toUTCString();
        let _domain = 'domain=leadrpro.com';
        document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
      }

    async function handleSubmit(e, role_type){
        e.preventDefault();
        setLoading(true);

        let password = inputPassword.current.value;
        let confirm = inputConfirm.current.value;

        if (password.length === 0 || confirm.length === 0){
            setShowError(true);
            setErrorMessage('Must enter a valid password');
            setLoading(false);
            return;
        }
        
        if (password === confirm){
            setShowError(false);
            let result = await setPassword(password, token, role_type);

            // showPassword(false);
            // setRoleType(role_type);
            // setShowSelectRole(true);

            if (result.success){
                showPassword(false);
                setRoleType(role_type);

                localStorage.setItem('access_token', result.data.accessToken);
                localStorage.setItem('refresh_token', result.data.refreshToken);

                // used for meetings.leadrpro
                setCookie('access_token', result.data.accessToken, 1);

                setUserId(result.data.userId);
                setAuthenticated(true);
                setCurrentStep(2);
                setLoading(false);
            }

            else{
                setLoading(false);
                setErrorMessage(result.error);
                setShowError(true);
            }
        }
        else{
            setLoaded(false);
            setLoading(false);
            setShowError(true);
            setErrorMessage("Passwords do not match");
        }
    }

    if (!verified && loaded)
    return (
        <div className="center card__wrapper">
            <div className="card">
                <div className="card__inner">
                    <h3 className="card__header">Token is no longer valid</h3>
                </div>
            </div>
        </div>
    )

    return (
        <div className="center card__wrapper">
            <div className="card createpassword">
                <div className="createpassword__left">
                <h3 className="card__header createpassword__left-header">Set password</h3>
                <form>
                    <div className="form-group">
                    <label className="createpassword__input-label" for="txtPassword">Set a password</label>

                    <input
                        type="password"
                        id="inputPassword"
                        ref={inputPassword}
                        name="password" className={classNames("createaccount__input", "input", {
                            "input--error":showError
                        })} required
                        placeholder="Enter password" />
                    </div>

                    <div className="form-group">
                        <label className="createpassword__input-label" for="txtConfirm">Confirm password</label>
                        <input
                            type="password"
                            id="inputConfirm"
                            ref={inputConfirm}
                            name="confirm" className={classNames("createaccount__input", "input", {
                                "input--error":showError
                            })} required
                            placeholder="Confirm password"
                        />
                    </div>

                    {showError===true && (
                        <div className="form-group">
                        <div className="label label--error">{errorMessage}
                        </div>
                        </div>
                    )}

                    <div className="form-group center" style={{marginTop: "50px"}}>
                        {loading && (
                            <Spinner completed={completed} setCompleted={setCompleted} />
                        )}

                        {!loading && (
                            <button className="btn btn--primary" onClick={(e) => handleSubmit(e, 1)}>
                                <span>Set password</span>
                            </button>
                        )}
                    </div>
                </form>
                </div>

                <div className="createpassword__right">
                    <div className="login__right-header">
                        Make sure your password:
                    </div>

                    <p style={{marginTop: "30px"}}>
                        <ul className='createpassword__list'>
                            <li>is longer than 5 characters</li>
                            <li>includes at least 1 number</li>
                            <li>includes at least 1 non-numeric symbol ($, %, @, etc)</li>
                            <li>does not include any spaces</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CreatePassword;
