import './SubmitTimes.css';
import {useState,useEffect} from 'react';
import FadeIn from '../../utils/fadein';
import { saveDemoAvailableTimes, getSellerWorkHours, getSellerDemoTimes } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../layout/Spinner';
import moment from 'moment-timezone';
import { FaTimes } from 'react-icons/fa';
import { format, eachHourOfInterval, parseISO, isValid, isAfter, setHours, setMinutes, setSeconds, startOfDay, endOfDay } from 'date-fns';

function SubmitTimes({company_name, demo, demo_id, seller_id, setShowConfirmation, setShowSelectedTime, demoAccepted, showSteps = true}) {
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [sellerWorkHours, setSellerWorkHours] = useState([]);
  const [dates, setDates] = useState([]);
  const [hasWorkHours, setHasWorkHours] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sellerTimezone, setSellerTimezone] = useState('');

  // const dateInNewYork = moment.tz('2024-06-01 09:00', 'Australia/Sydney');

  // console.log('*********** date in new york', dateInNewYork.format());
  // console.log('date obj in new york', new Date(dateInNewYork).toLocaleString());

  let dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

  function convertToISO(dateString) {
    const date = new Date(dateString);
    return date.toISOString(); // This will return the date in ISO 8601 format
}

  // Parse dates and generate hourly intervals
//   const generateTimeSlots = () => {
//     let startDate = convertToISO('4/16/2024');
//     let endDate = convertToISO('4/20/2024');
//     const start = parseISO(startDate);
//     const end = parseISO(endDate);

//     console.log('start', start);
//     console.log('end',  end);
//     return eachHourOfInterval({ start, end });
// };

// const generateTimeSlots2 = () => {
//   let startDate = convertToISO('4/16/2024');
//     let endDate = convertToISO('4/20/2024');
//     let startTime = '08:00';
//     let endTime = '10:00';
//   const start = parseISO(startDate);
//   const end = parseISO(endDate);

//   // Parse startTime and endTime
//   const [startHour, startMinute] = startTime.split(':').map(Number);
//   const [endHour, endMinute] = endTime.split(':').map(Number);

//   const startDateTime = setSeconds(setMinutes(setHours(start, startHour), startMinute), 0);
//   const endDateTime = setSeconds(setMinutes(setHours(end, endHour), endMinute), 0);

//   console.log('start', startDateTime, 'end', endDateTime);
//   // Generate times within the interval
//   const timeSlots = eachHourOfInterval({ start: startDateTime, end: endDateTime });

//   return timeSlots;
// };

// function convertTimeZone(hour, fromZone, toZone) {
//   // If the timezones are the same, return the hour directly
//   if (fromZone === toZone) {
//     return hour;
//   }

//   // Create a date object. The specific date doesn't matter if you only need the hour
//   const date = new Date();
//   date.setHours(hour, 0, 0, 0);  // Reset minutes, seconds, and milliseconds to zero

//   // Convert the hour in the originating timezone to UTC
//   const utcHour = date.toLocaleTimeString('en-US', {
//     timeZone: fromZone,
//     hour12: false,
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit'
//   });

//   // Create a new date with the UTC hour and minute
//   const utcDate = new Date(`1970-01-01T${utcHour}Z`);

//   // Convert the UTC hour to the destination timezone hour
//   const destinationHour = utcDate.toLocaleTimeString('en-US', {
//     timeZone: toZone,
//     hour12: false,
//     hour: '2-digit'
//   });

//   // Return the destination hour as a number
//   return parseInt(destinationHour);
// }

function convertTZ(hour, tzString) {
  const date = new Date();
  let new_date = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  let new_hour = new_date.getHours();
  return new_hour;
}

// console.log('convertTZ', convertTZ(6, 'America/New_York'));

// const hour = 9;
// const toTimeZone = 'America/Los_Angeles';
// const fromTimeZone = 'America/New_York';
// const convertedHour = convertTimeZone(hour, fromTimeZone, toTimeZone);
// console.log('converted hour', convertedHour);

function convertTime(hour, startZone, endZone) {
  // Create a date object. The specific date doesn't matter for hour conversion.
  const date = new Date(`2024-01-01T${hour.toString().padStart(2, '0')}:00:00`);

  // Convert the date to the starting timezone first to ensure correct hour input
  const startDate = date.toLocaleString('en-US', { timeZone: startZone });

  // Create a new date object from the timezone-adjusted string
  const startDateFormat = new Date(startDate);

  // Now convert this date object to the ending timezone
  const resultTime = startDateFormat.toLocaleTimeString('en-US', {
      timeZone: endZone,
      hour: '2-digit',
      hour12: false
  });

  // Extract the hour part and convert it to an integer
  return parseInt(resultTime.split(':')[0]);
}

const generateTimeSlots3 = (startTime, endTime) => {
  let strStart = new Date();
  strStart.setDate(strStart.getDate() + 1);
  let strEnd = new Date();
  strEnd.setDate(strEnd.getDate() + 9);

  let startDate = convertToISO(strStart.toDateString());
  let endDate = convertToISO(strEnd.toDateString());
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  // Extract hour and minute from startTime and endTime
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  // Generate times for each day within the interval
  let days = eachHourOfInterval({ start: startOfDay(start), end: endOfDay(end) });

  days = days.filter(slot => {
      const hour = slot.getHours();
      const minute = slot.getMinutes();
      const isAfterStart = hour > startHour || (hour === startHour && minute >= startMinute);
      const isBeforeEnd = hour < endHour || (hour === endHour && minute <= endMinute);
      return isAfterStart && isBeforeEnd;
  });

  return days;
};

 // Handle checkbox change
 const handleCheckboxChange = (index) => {
  const updatedTimes = [...selectedTimes];
  updatedTimes[index].isSelected = !updatedTimes[index].isSelected;

  setSelectedTimes(updatedTimes);
};

  useEffect(() => {
    async function loadSellerWorkHours(){
      let res = await getSellerWorkHours(seller_id);
      if (res.success){
        setSellerWorkHours(res.data);
        setSellerTimezone(res.timezone);
        let seller_timezone = res.timezone;

        //alert(res.timezone);

        if (res.data.length > 0){
          setHasWorkHours(true);
          let hours = res.data;
          let start = hours[0].start_time;
          let end = hours[0].end_time;
          let _start = Math.floor(hours[0].start_time / 100);
          let _end = Math.floor(hours[0].end_time / 100);

          // create seller date object in their timezone
          // *******************************************************
          //const dateInNewYork = moment.tz('2024-06-01 10:00', demo.seller_timezone);
          const sellerStartDate = moment.tz(`${moment().format('YYYY-MM-DD')} ${_start.toString().padStart(2, '0')}:00`, seller_timezone);
          const sellerEndDate = moment.tz(`${moment().format('YYYY-MM-DD')} ${_end.toString().padStart(2, '0')}:00`, seller_timezone);

          // console.log('&&&&&&&&&&&&&&&&& ', sellerStartDate, new Date(sellerStartDate).toLocaleString());
          // console.log('&&&&&&&&&&&&&&&&& ', sellerEndDate, new Date(sellerEndDate).toLocaleString());


          // console.log('start hour', new Date(new Date(sellerStartDate).toLocaleString()).getHours());

          // console.log('end hour', new Date(new Date(sellerEndDate).toLocaleString()).getHours());
         // `2024-01-01 ${_start.toString().padStart(2, '0')}:00:00`
          // console.log('date in new york', dateInNewYork.format());
          // console.log('date obj in new york', new Date(dateInNewYork));
          // *********************************************************

          // console.log('start', start);
          // console.log('end', end);

          // let converted_start = convertTimeZone(start / 100, demo.seller_timezone, Intl.DateTimeFormat().resolvedOptions().timeZone);
          // let converted_end = convertTimeZone(end / 100, demo.seller_timezone, Intl.DateTimeFormat().resolvedOptions().timeZone);

          // console.log(demo.seller_timezone);
          // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

          // console.log('converted start', converted_start);
          // console.log('converted end', converted_end);

          let convertedStart = new Date(new Date(sellerStartDate).toLocaleString()).getHours();
          let convertedEnd = new Date(new Date(sellerEndDate).toLocaleString()).getHours();

          if (convertedEnd === 0)
            convertedEnd = 23;

          if (convertedEnd < convertedStart)
            convertedEnd = 23;

          let final_start = (convertedStart * 100).toString().padStart(4, '0').replace(/(\d{2})(\d{2})/, '$1:$2');
          let final_end = (convertedEnd * 100).toString().padStart(4, '0').replace(/(\d{2})(\d{2})/, '$1:$2');

          //console.log('---------- final start', final_start);
          setSelectedTimes(generateTimeSlots3(final_start, final_end).map(slot => ({ time: slot, isSelected: false })));
        }
      }
    }

    loadSellerWorkHours();
  }, []);

  useEffect(() => {
    // load week days into view
    async function loadDates(){
      let today = new Date();
      let _dates = [];
      today.setDate(today.getDate() + 2);

      // load all days for the next 2 weeks
      for (let i = 0; i < 10; i++){
        let _start = new Date(today);
        _start.setDate(today.getDate() + i);

        let _dayofweek = _start.getDay();

        if (sellerWorkHours.length > 0){
          let tmp = sellerWorkHours.filter(item => item.day_of_week === _dayofweek);
          if (tmp.length > 0){
            _dates.push(_start);
          }
        }

        else{
          _dates.push(_start);
        }
      }

      setDates(_dates);
    }

    loadDates();
  }, [sellerWorkHours]);

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  async function handleBackClick(){
    setShowSelectedTime(false);
  }

  async function handleDateSelected(e, item){
    if (e.target.checked){
      let _date = new Date(item);
      _date.setHours(e.target.value);
      _date.setMinutes(0);
      _date.setSeconds(0);

      setSelectedTimes([...selectedTimes, _date]);
    }

    else{
     let _date = new Date(item);
     let _filtered = selectedTimes.filter(tmp => {
      if (tmp.getMonth() === _date.getMonth() && tmp.getDate() === _date.getDate() && tmp.getHours() === parseInt(e.target.value))
        return false;

      return true;
     });

     setSelectedTimes(_filtered);
    }
  }

  async function handleSubmit(){

    let _filtered = selectedTimes.filter(x => x.isSelected === true).map(x => {
      const xx = moment.tz(x.time, moment.tz.guess());
      return new Date(xx);
    });

    // save available times
    if (_filtered.length === 0){
      alert('Must select at least 3 times');
    }

    else if (_filtered.length < 3){
      alert("Must select at least 3 times");
    }

    else{
      setSubmitting(true);
      // submit times and set demo to 'Approved' for buyer
      let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let res = await saveDemoAvailableTimes(demo_id, _filtered, s_timezone);

      if (res.success){
        //await hRefreshToken();
        setShowSelectedTime(false);
        setShowConfirmation(true);
        demoAccepted(demo_id);
      }

      else if (res.status === 403){
        await refresh();

        let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let res = await saveDemoAvailableTimes(demo_id, selectedTimes, s_timezone);
  
        if (res.success){
          //await hRefreshToken();
          setShowSelectedTime(false);
          setShowConfirmation(true);
          demoAccepted(demo_id);
        }
      }

      setSubmitting(false);
    }
  }

return (
  <FadeIn delay={120} duration={100}>
  <div className='availabletimes'>
    <div className='modal__body'>
      {showSteps && (
        <h2>Step 2 of 2</h2>
      )}

      <div className='mar10--top qualifierquestion__label' style={{fontSize: '20px'}}>Submit your available times</div>

      <div style={{margin: '10px 0px', backgroundColor: '#e1ecff', color: 'black', padding: '5px', borderRadius: '5px'}}>
        <strong>Company timezone: </strong><i>{sellerTimezone}</i>
      </div>

      <div>
        Select a minimum of 3 times when you will be available for a <span className='dashboarddemo__highlight'>30 - 45 minute</span> demo:
      </div>

      <div style={{display: 'flex'}}>
        <div className='submittimes__list'  style={{marginTop: '20px'}}>
        <div>
          <i>Times shown in your local timezone</i>
        </div>

        <ul>
            {selectedTimes.map((slot, index) => (
                <li key={index}>
                  <div className='submittimes__list-item'>
                    <label>
                        <input
                            type="checkbox"
                            checked={slot.isSelected}
                            onChange={() => handleCheckboxChange(index)}
                        />
                        <span className='submittimes__list-item-text'>                                          {format(slot.time, 'eeee, MMMM dd yyyy hh:mm a')} {/* Updated to 12-hour format */}</span>
                    </label>
                  </div>
                </li>
            ))}
        </ul>
        </div>

        <div className='submittimes__list-results'>
          <h3><strong>Selected times:</strong></h3>

          <div>
            {selectedTimes.filter(x => x.isSelected == true).map(item => (
              <div>
                {format(item.time, 'eeee, MMMM dd yyyy hh:mm a')}
              </div>
            ))}
          </div>

          {selectedTimes.filter(x => x.isSelected == true).length > 0 && (
          <div>
          {submitting === false && (
            <div className='center' style={{marginTop: "20px"}}>
              <button className='btn btn--confirm' style={{padding:'10px'}} onClick={() => handleSubmit()}>Submit Dates/Times</button>
            </div>
            )}

            {submitting && (
              <div className='center' style={{marginTop: "20px"}}>
                <button className='btn btn--confirm'>
                  <Spinner />
                </button>
              </div>
            )}
          </div>
          )}
        </div>
      </div>
    </div>

    <div className='modal__close' onClick={() => handleBackClick()}>
      <FaTimes />
      </div>
  </div>
  </FadeIn>
)
}

export default SubmitTimes;