import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getCompaniesWithAddons } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaChair, FaEllipsisV, FaLink } from 'react-icons/fa';

function Addons() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [total, setTotal] = useState(0);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadCompanies(){
      let res = await getCompaniesWithAddons();

      if (res.success){
        setCompanies(res.data);
        setLoaded(true);

        let _total = 0;
        for (let i = 0; i < res.data.length; i++)
        {
          if (res.data[i].charges && res.data[i].charges.total)
           _total += parseInt(res.data[i].charges.total);
        }
        setTotal(_total);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getCompaniesWithAddons();

        if (res.success){
          setCompanies(res.data);
          setLoaded(true);

          let _total = 0;
          for (let i = 0; i < res.data.length; i++)
          {          
            if (res.data[i].charges && res.data[i].charges.total)
              _total += parseInt(res.data[i].charges.total);
          }
          setTotal(_total);
        }
      }
    }
    
    loadCompanies();
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin - Addons</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
        <div>
          <h1>Addons</h1>

          <div>
            <div>Total charges: ${total}</div>
          </div>

          <div>
            {companies.map((item, index) => (
              <div className='admindashboard__company-card'>
                <div>
                  <strong>#{index + 1}.</strong>
                </div>

                <div className='buyerdashboard__recommendation-logo'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                </div>

                <div>
                  <div className='admindashboard__table-company-name'>{item.name} {`(${item.company_id})`}</div>

                  <div>
                    <strong>Is Paused: </strong> {item.is_paused == true ? <span>Yes</span> : <span>No</span>}
                  </div>

                  <div>
                    <strong>Is Inactive: </strong> {item.is_inactive == true ? <span>Yes</span> : <span>No</span>}
                  </div>
                </div>

                <div>
                  {item.team_seats_enabled == true && (
                    <div><FaChair /> Team seats: <span className='admindashboard__addons-enabled'>On</span></div>
                  )}

                  {item.team_seats_enabled == false && (
                    <span className=''><FaChair /> Team seats: <span className='admindashboard__addons-disabled'>Off</span></span>
                  )}
                </div>

                <div>
                  {item.custom_links_enabled == true && (
                    <div>
                    <div><FaLink /> Custom links: <span className='admindashboard__addons-enabled'>On</span></div>
                    </div>
                  )}

                  {item.custom_links_enabled == false && (
                    <div><FaLink /> Custom links: <span className='admindashboard__addons-disabled'>Off</span></div>
                  )}
                </div>

                {item.charges && item.charges.number_of_seats && (
                  <>
                <div>
                  <strong>Team seats: {item.charges.number_of_seats}</strong>
                </div>

                <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                  Total: ${item.charges.total}
                </div>
                </>
                )}
               </div>
               
            ))}
          </div>
       </div>
      )}
    </>
  );
}

export default Addons;
;
