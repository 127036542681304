import './SearchResults.css';
import './SellerResults.css';
import './SellerSearch.css';
import {useState, useEffect} from 'react';
import { searchCompanies, getAvailableSellers, saveCompany, getSavedCompanies, createSellerDemo, refreshToken, buyerCreatesSellerDemo, getIndustriesWithSellers, addBuyerRequest, getUserProfile, addToWaitlist, addEmailCampaign } from '../../utils/api';
import { getSeoName } from '../../utils/utils';
import Share from './Share';
import Spinner from '../../components/layout/Spinner';
import JoinModal from './JoinModal';
import SellerBooking from '../../components/demos/SellerBooking';
import BuyerBookingSeller from '../../components/demos/BuyerBookingSeller';
import classNames from 'classnames';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import FadeIn from '../../utils/fadein';
import close from '../../assets/design/dashboard/close.png';
import {genTables} from '../../utils/pagination';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import star_selected from '../../assets/design/rating-star-selected.png';
import star_deselect from '../../assets/design/rating-star-unselected.png';

const SellerSearch = ({selectedFilters, query, setQuery, selectedCategory}) => {
  const [results, setResults] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirmation, setShowConfirmation]= useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [savedResults, setSavedResults] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState({});
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [completed, setCompleted] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [sortValue, setSortValue] = useState(0);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [showQualifier, setShowQualifier] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [qualifierCompleted, setQualifierCompleted] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [currentDemoId, setCurrentDemoId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [buyerRequest, setBuyerRequest] = useState('');
  const [showRequest, setShowRequest] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showUnverified, setShowUnverified] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [waitlist, setWaitlist] = useState([]);

  useEffect(() => {
    document.title = "LeadrPro - Browse available demos";
  }, []);

  useEffect(() => {
    async function loadAvailableSellers(){
      let _res = await getAvailableSellers();

      if (_res.success){
        setResults(_res.data);
        setCompleted(true);
        genTables();
        setLoaded(true);
      }

      else{
        if (_res.status === 403){
          let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let _res = await getAvailableSellers();

            if (_res.success){
              setResults(_res.data);
              setCompleted(true);
              genTables();
              setLoaded(true);
            }
          }

          else{
            window.location = '/login';
          }
        }

        else{
          let _res = await getAvailableSellers();

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            genTables();
            setLoaded(true);
          }
        }
      }
    }

    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        let _user = res.data;
        if (_user.work_email_verified){
          setIsVerified(true);
        }

        else{
          setIsVerified(false);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let res = await getUserProfile();

            if (res.success){
              let _user = res.data;
              if (_user.work_email_verified){
                setIsVerified(true);
              }
      
              else{
                setIsVerified(false);
              }
            }
          }
      }
    }

    async function loadIndustries(){
      let res = await getIndustriesWithSellers();

      if (res.success){
        setIndustries(res.data);
      }
    }

    loadAvailableSellers();
    loadIndustries();
    loadUserProfile();
  }, []);

  useEffect(() => {
    async function loadResults() {
      let categories  = selectedFilters.filter(item => item.type === 'Category').map(item => item.value);
      let sizes = selectedFilters.filter(item => item.type === 'CompanySize').map(item => item.value);
      let stages = selectedFilters.filter(item => item.type === 'CompanyStage').map(item => item.value);
      let response = await searchCompanies(query, categories, sizes, stages, perPage, selectedCompanyId, currentPage, sortValue);

      if(response.success){
        setResults(response.data);

        setCompleted(true);
        genTables();

        if(response.data.length > 0){
          let full = parseInt(response.data[0].full_count);
          setTotalResults(full);
        }

        else{
          // no results found
          setTotalResults(0);
        }
      }
  
      else if (response.status && response.status === 403){
        console.log('403');
      }
      else{
        console.log('no response');
        console.log(response.error);
      }

      let response2 = await getSavedCompanies();

      if (response2.success){
        let tmp1 = response2.data.map((item) => item.company_id);
       // console.log('saved results', tmp1);
        setSavedResults(tmp1);
      }
    }

    //loadResults();
  }, [selectedFilters, selectedCompanyId, currentPage, perPage, sortValue]);

  useEffect(() => {
    if (query.length === 0){
      setSelectedCompanyId(0);
    }
  }, [query]);

  async function bookDemo(item) {
    if (item.source && item.source !== ''){
     // alert('has a source');
      // submit booking request
      // buyer to seller
      let data = {seller_id: item.seller_id}
      let res = await createSellerDemo(data);

      if (res.success){
       // setShowConfirmation(true);
        setShowConfirm(true)
      }
    }

    else{
      setShowBooking(!showBooking);
      setSelectedSeller(item);
    }
  }

  function cancelCongrats(){
    setShowCongrats(false);
  }

  async function handleQualifierClick(demo_id, item){
    //alert(isVerified);
    //return;
    if (isVerified === false){
      setShowUnverified(true);
      return;
    }

    setSelectedCompanyId(item.company_id);

    if (demo_id === undefined){
      //alert('undefined');
      // create a pending demo for application
      let data = {seller_id: item.seller_id};
      let _res = await createSellerDemo(data);

      if (_res.success){
        setCurrentDemo(item);

        setCurrentDemoId(_res.data);
        setShowQualifier(true);
      }
    }
  }

  async function handleWaitlistClick(demo_id, item){
    setSelectedCompanyId(item.company_id);
    setCurrentDemo(item);
    setShowWaitlist(true);
  }

  async function handleWaitlistConfirmClick(){
    setSubmitting(true);
    let res = await addToWaitlist(selectedCompanyId);

    if (res.success){
      setShowWaitlistConfirm(true);
      setShowWaitlist(false);
      setSubmitting(false);

      setWaitlist([...waitlist, selectedCompanyId]);
    }

    else{
      alert("Something went wrong");
    }
  }

  function demoAccepted(demo_id){
    //let _filter = demos.filter(item => item.demo_id !== demo_id);
    //setDemos(_filter);
    let _filter = results.filter(item => item.company_id !== selectedCompanyId);
    setResults(_filter);
  }


  useEffect(() => {
    let tmp = parseInt(totalResults) / parseInt(perPage);
    let remainder = totalResults % perPage;

    if (remainder > 0)
      tmp += 1;

    setNumberOfPages(Math.floor(tmp));
  }, [totalResults, perPage]);

  function getPager(){
    let el = [];
    let maxPages = 10;
    let start = 1;
    let end = 10;

   if (currentPage < 10){
    start = 1;
    end = 10;
   }

   else if (currentPage > numberOfPages - 10){
    start = numberOfPages - 10;
    end = numberOfPages;
   }

   else{
    start = currentPage - 5;
    end = currentPage + 5;
   }

   if (numberOfPages < 10)
    end = numberOfPages;

    for (let i = start; i <= end; i++){
      el.push(<span key={i} className={`buyerresults__pager-item ${i === currentPage ? "buyerresults__pager-item--selected": ""}`} onClick={() => setCurrentPage(i)}>{i}</span>);
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  async function handleSubmitRequest(){
    if (buyerRequest && buyerRequest.length > 0){
      let res = await addBuyerRequest(buyerRequest);

      if (res.success){
        setRequestComplete(true);
      }

      else{
        setRequestComplete(true);
      }
    }
  }

  async function handleAvailableClick(){
    setShowComingSoon(false);
  }

  async function handleComingSoonClick(){
    setShowComingSoon(true);
  }

  return (
    <>
    <div className='sellersearch__wrapper'>
    <div className="sellersearch">
      <Helmet>
        <meta name="description" content="" />
      </Helmet>

      <div className='sellersearch__welcome'>
        <div className='sellersearch__request'>
          <span className='sellersearch__request-new'>NEW</span>
          <span className='sellersearch__request-label'>Instant Request</span>
          <span className='sellersearch__request-copy'>Can't find what you're looking for? Submit a request for your exact needs and we will find you the best possible solutions.</span>
          <span className='sellersearch__request-button'><button className='btn btn--primary' onClick={() => setShowRequest(true)}>Submit</button></span>
        </div>

        <h2 className=''>Get paid to discover new software!</h2>

        <div className='sellersearch__copy'>
          <div>Scroll through the list of various software companies and apply to do a demo.</div>
          <div>This is software discovery reimagined. Most of the time we aren't even aware that there is a software solution to our biggest day-to-day pain points.</div>
        </div>
      </div>

      {showBooking && (
        <>
        <div className="overlay"></div>
        <BuyerBookingSeller company={selectedSeller} selectedBuyer={selectedSeller} setSelectedSeller={setSelectedSeller} setShowBooking={setShowBooking} setShowConfirm={setShowConfirm} selectedSellers={selectedSellers} setSelectedSellers={setSelectedSellers} />
        </>
      )}

      {showModal && (
        <FadeIn delay={250} duration={250}>
          <JoinModal setShowModal={setShowModal} />
        </FadeIn>
      )}

      <Spinner completed={completed} setCompleted={setCompleted} />

      {results.length === 0 && loaded && (
        <div className='sellersearch__no-results'>
          There are no available demos right now. Check back daily for new opportunities.
        </div>
      )}

        {results.length > 0 && (
      <div className={classNames("", {
        "hide":!completed
    })}>

      <div className='sellersearch__options'>
        <div className={`sellersearch__option ${showComingSoon ? '' : ' sellersearch__option--selected'}`} onClick={() => handleAvailableClick()}>Available</div>
        <div className={`sellersearch__option ${showComingSoon === false ? '' : ' sellersearch__option--selected'}`} onClick={() => handleComingSoonClick()}>Coming Soon</div>
      </div>

      <div className='sellersearch__filter' style={{display: "none"}}>
        <select className='select input' value={selectedIndustry}
                    onChange={(e) => setSelectedIndustry(e.target.value)}>
          <option value="0">Select industry</option>

          {industries.map(item => (
            <option value={item.industry_id}>{item.name}</option>
          ))}
        </select>
      </div>

        {loaded && showComingSoon===false && (
          <>
        {results.filter(x => x.on_waitlist === false).map(item => (
      <div className="dashboarddemo">
            <div className="dashboarddemo__company-logo">
              {item.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
                
                {!item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
            </div>

        <div>
          <div style={{width: "200px"}}>
            <div className='sellerresults__company-info'>
              <div className='sellersearch__industry'>{item.industry_name}</div>
              <div className="dashboarddemo__company-name">
                {item.name}
              </div>

              {item.average_rating && (
              <div className='sellersearch__ratings'>
                  ⭐{Math.round(item.average_rating * 10) / 10}
              </div>
              )}

              <div className="sellerresults__description">

              <div className='dashboarddemo__company-description-text'>
                {item.description ? (item.description.length > 100 ? item.description.substring(0, 100) + "..." : item.description) : 'No description found'}<br/><br/> <a href={"/company/" + item.company_id + `/${getSeoName(item.name)}`} className="link">Full Company profile</a>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div>
          <div className='dashboarddemo__earn-header'>
            Earn
          </div>

          <div className="dashboarddemo__earn-amount">
            ${item.credit_cost * 15}
          </div>
        </div>

        <div>
          <div className="searchresults__buttons sellerresults__buttons">
            {item.demo_status !== 1 && item.demo_status !== 4 && selectedSellers.includes(item.seller_id) === false && (
              <button className="btn btn--primary" onClick={() => handleQualifierClick(item.demo_id, item)} >Apply</button>
            )}

            {(item.demo_status === 1 || item.demo_status === 4 || selectedSellers.includes(item.seller_id)) && (
              <span>Pending</span>
            )}
          </div>
        </div>
        </div>
        ))}
        </>
        )}

{loaded && showComingSoon===true && (
          <>
        {results.filter(x => (x.on_waitlist === true)).map(item => (
      <div className="dashboarddemo">
            <div className="dashboarddemo__company-logo">
              {item.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
                
                {!item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
            </div>

        <div>
          <div style={{width: "200px"}}>
            <div className='sellerresults__company-info'>
              <div className='sellersearch__industry'>{item.industry_name}</div>
              <div className="dashboarddemo__company-name">
                {item.name}
              </div>

              <div className="sellerresults__description">

              <div className='dashboarddemo__company-description-text'>
                {item.description ? (item.description.length > 100 ? item.description.substring(0, 100) + "..." : item.description) : 'No description found'}<br/><br/> <a href={"/company/" + item.company_id + `/${getSeoName(item.name)}`} className="link">Company profile</a>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div>
          <div className="searchresults__buttons sellerresults__buttons">
            {item.user_on_waitlist === "0" && waitlist.filter(x => x === item.company_id).length === 0 && (
              <button className="btn btn--primary" onClick={() => handleWaitlistClick(item.demo_id, item)} >Join the waitlist</button>
            )}

            {
              (item.user_on_waitlist !== "0" || waitlist.filter(x => x === item.company_id).length > 0) && (
                <span>On the waitlist</span>
              )
            }
          </div>
        </div>
        </div>
        ))}
        </>
        )}
        </div>
        )}

        <div className="pager">
          {getPager()}
        </div>
        
        {showSelectedTime && (
        <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              <AvailableTimes demo={currentDemo} demo_id={currentDemoId} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
            </div>
        </div>
      )}

        {showShare && (
          <div className="overlay">
          <FadeIn duration={250} delay={250}>
            <Share setShow={setShowShare} />
          </FadeIn>
          </div>
        )}

    {showConfirmation && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <img src={close} className="modal__close" onClick={() => setShowConfirmation(false)} />
        
        <div className='center' style={{fontSize: "50px", marginBottom: "20px"}}>
          🎉
        </div>

        <div className='modal__header'>Your request is now being reviewed. You should hear back within 24 hours.</div>

        <div className='modal__header'>In the meantime, keep your profile up to date and check back daily for more demo opportunities!</div>

        <div className='center'>
          <button className='btn btn--primary btn--dark' onClick={() => setShowConfirmation(false)}>Close</button>
        </div>
      </div>
      </>
    )}

      {/* <div className="sellerresults">
      {results.map((item, index) => (
        <div key={index} className="seller-results-card sellerresults__card">
          <div className="results-body sellerresults__body">
            <div className="search-result-image sellerresults__image">
              {item.logo && (
              <img src={"/assets/companylogos/" + item.logo} alt="" />
              )}
              {!item.logo && (
                <span className="sellerresults__initial">{item.name[0]}</span>
              )}
            </div>

            <div className="search-result-info sellerresults__info">
              <div className="results-company-title sellerresults__info-title">
                {item.name}
              </div>

              <div className="results-company-rating sellerresults__rating">
                <div>
                  <div style={{display:"inline-block", paddingRight:"10px"}}>
                   {getStars(item.rating)}
                  </div>

                  {item.rating_count > 0 && (
                    <>
                  <span className="rating-text">{item.rating}</span>
                  <span className="score-text">({item.rating_count})</span>
                    </>
                  )}
                </div>

                <div className="review-link sellerresults__review-link">
                  <a href="#" className="result-links">{item.review_count} reviews</a>
                </div>
              </div>
            </div>
            
            <div className="sellerresults__summary">
            <p className="results-summary sellerresults__description">
              {item.description ? item.description: 'No description found'}<br/> <a href={"/company/" + item.company_id} className="result-links">Learn more about {item.name}</a>
            </p>
            </div>

            <div className="search-result-buttons sellerresults__buttons">
              <div>
                <button className={classNames({"btn--action": savedResults.filter((x => x === item.company_id)).length > 0}, "btn-secondary btn btn__book")} onClick={(e) => save(e, item.company_id)} >Save</button>
              </div>

              <div>
                <button className="btn-secondary btn btn__book" onClick={() => setShowModal(true)} >Book</button>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div> */}

      {showQualifier && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--pad0'>
            <QualifierQuestions demo_id={currentDemoId} setIsCompleted={setQualifierCompleted} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
          </div>
        </>
      )}

    </div>

    {showRequest && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowRequest(false)}>X</div>

          {requestComplete === false && (
            <>
                <h2>Submit a software request</h2>

          <p style={{marginTop: "20px"}}>
            Have a specific software need in mind? Let us know and we will comb through our extensive database and find you the best solutions.
          </p>

          <div style={{marginTop: "10px"}}>
            <textarea className='input textarea' placeholder='Ex: I am looking for content marketing software in the healthcare industry...' value={buyerRequest} onChange={(e) => setBuyerRequest(e.target.value)} style={{height: "100px"}}>

            </textarea>
          </div>

          <div style={{marginTop: "10px"}}>
            <button className='btn btn--primary' onClick={() => handleSubmitRequest()}>Submit request</button>
          </div>
          </>
          )}

          {requestComplete && (
            <>
              <h2>Request submitted!</h2>

            <p>
              Your request has been submitted! We normally take 24-48 hours to find you the best possible results. Hang tight and we will notify you when available!
            </p>
            </>
          )}
        </div>
      </>
    )}

    {showUnverified === true && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <p>
            You must verify your work email before you can apply for a demo.
          </p>

          <p>
            <a href='/account/work-email' className='link'>Verify work email</a>
          </p>
          <div className='center' style={{marginTop: "30px"}}>
            <button className='btn btn--primary' onClick={() => setShowUnverified(false)}>Close</button>
          </div>
        </div>
      </>
    )}
    </div>

    {showWaitlist && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <img src={close} className="modal__close" onClick={() => setShowWaitlist(false)} />

          <div className='sellersearch__waitlist-company-info'>
            <div className="sellersearch__company-logo">
              {currentDemo.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
                
                {!currentDemo.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
            </div>

            <div className='sellersearch__waitlist-company-name'>
              {currentDemo.name}
            </div>
          </div>
          <div>You will be added to the waitlist and will be notified as soon as this company is ready to host demos.</div>

          <div className='center' style={{marginTop: "10px"}}>
            {submitting === false && (
            <button className='btn btn--primary' onClick={() => handleWaitlistConfirmClick()}>Sign me up</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {showWaitlistConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
        <img src={close} className="modal__close" onClick={() => setShowWaitlistConfirm(false)} />
          <div className='center'>🎉 Success!</div>

          <p>
            You have been added to the waitlist. You will be notified once this company is ready to host demos.
          </p>
        </div>
      </>
    )}
    </>
  )
}

export default SellerSearch;