import React from 'react';
import { Box, Flex, Image, Center, Text, Spacer } from '@chakra-ui/react';
import ScrollToTop from '../../../components/layout/ScrollToTop';

const BuyerLogos = () => {
  return (
    <>
    <ScrollToTop />
      <Flex
        direction={'column'}
        boxSize='full'
        bg='white'
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box>
          <Center>
            <Text
              textTransform='uppercase'
              fontWeight='semibold'
              mb={10}
              fontSize={['lg', , '3xl']}
              textAlign='center'>
              Join Our Community Today
            </Text>
          </Center>
        </Box>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          w='full'
          alignItems='center'
          justifyContent='center'
          px={20}
          mb={{ base: 4, md: 10 }}>
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/lyft.png?updatedAt=1692145029779'
            alt='lyft'
            maxW='100px'
          />
          <Spacer />
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/monday.png?updatedAt=1692145105279'
            alt='monday'
            maxW='180px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/paychex.png?updatedAt=1692145158271'
            alt='paychex'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/mastercard2.png?updatedAt=1692145029720'
            alt='mastercard'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/ziprecruiter.webp?updatedAt=1692145158443'
            alt='ziprecruiter'
            maxW='150px'
          />
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          w='full'
          alignItems='center'
          justifyContent='center'
          px={20}
          mb={10}>
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/amazon.png?updatedAt=1692145198515'
            alt='amazon'
            maxW='150px'
          />
          <Spacer />
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/deel.png?updatedAt=1692145293397'
            alt='deel'
            maxW='100px'
            py={{ base: 4, md: 0 }}
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/gopro.png?updatedAt=1692145253809'
            alt='gopro'
            maxW='150px'
          />
          <Spacer />
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/fox.png?updatedAt=1692145446053'
            alt='fox'
            maxW='90px'
            pb={{ base: 4, md: 0 }}
          />

          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/delta.png?updatedAt=1692145388925'
            alt='delta'
            maxW='100px'
          />
        </Flex>

        <Flex
          display={{ base: 'none', md: 'flex' }}
          direction={{ base: 'column', md: 'row' }}
          w='full'
          alignItems='center'
          justifyContent='center'
          px={20}>
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/prudential-financial.jpeg?updatedAt=1692145414653'
            alt='prudential'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/zapier.jpeg?updatedAt=1692145496715'
            alt='zapier'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/expedia.png?updatedAt=1692145340255'
            alt='expedia'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/braintrust.jpeg?updatedAt=1692148578334'
            alt='braintrust'
            maxW='150px'
          />
          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Buyer%20Logos/citigroup.png?updatedAt=1692145366628'
            alt='citi'
            maxW='100px'
          />
        </Flex>
      </Flex>
    </>
  );
};

export default BuyerLogos;
