import './CompleteProfileSeller.css';
import x from '../../assets/design/greyX.png';
import { useState, useEffect } from "react";
import { searchCategories, addCategories } from '../../utils/api';

const CompleteProfileSeller = ({setShowPhoneNumber, setShowProfile, setShowLogoAndTimezone, companyId, setCurrentStep}) => {
    
const [query, setQuery] = useState("");
const [results, setResults] = useState([]);
const [selectedCategories, setSelectedCategories] = useState([]);
const [show, setShow] = useState(false);
    
    useEffect(() => {
      //  console.log('companyid', companyId);
        categorySearch(query);
    }, [query]);

    async function categorySearch(query){
        let data = await searchCategories(query);
        if (data.data){
            setResults(data.data);
            setShow(true);
        }
    }

    async function handleChange(e){
        await setQuery(e.target.value);
       // await categorySearch(query);
       // setShow(true);
    }
    
    function handleClick(category_id, name) {
        setSelectedCategories([...selectedCategories, {category_id, name}]);
        console.log({ selectedCategories });
        setQuery('');
        setShow(false);
    }

    async function handleSubmit(e){
        e.preventDefault();

        let res = await addCategories(companyId, selectedCategories);

        if (res.success){
            //setShowPhoneNumber(true);
            //setShowProfile(false);
            //setShowLogoAndTimezone(true);
            setCurrentStep(4);
        }
    }

    async function handleRemove(id){
        let arr = selectedCategories.filter(item => item.category_id !== id);
        setSelectedCategories(arr);
    }

    return (
        <div className="card-wrapper">
        <div className="card companyprofile__card">
            <div className="companyprofile__card-inner">
                <h2 className="card-header">Complete company profile</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="completesellerprofile__form-group--parent form-group">
                            <label className='label input-label'>Software Category (select all that apply)</label>
                            <input
                            autoComplete="off"
                            className="input completeprofileseller__input-box"
                            value={query}
                                onChange={(e) => handleChange(e)}
                            type='text'
                            placeholder='Choose from the list'
                        />
                        
                        {results.length > 0 && show === true && (
                        <div className="companyprofile__category-results" style={{width: "240px", zIndex: "2"}} >
                            <h3>Categories</h3>

                        <div style={{ marginTop: "20px" }}>        
                            {results.map((item) => (
                                <div className="companyprofile__category-item"
                                     onClick={() => handleClick(item.category_id, item.name)}
                                >
                                    {item.name}
                                </div>
                            ))} 
                        </div>
                        </div>
                    )}
                       
                    {selectedCategories.length > 0 && (
                        <div>
                            {selectedCategories.map(category => (
                            <div className="selected-categories">
                            <div className="category-tag">
                                <span className="completeprofileseller__tag-text">                      {category.name}
                                </span>
                                 <img src={x} alt="" className="completeprofileseller__close" onClick={() => handleRemove(category.category_id)} />
                            </div>
                            </div>
                            ))}
                        </div>
                    )}
                    </div>

                    <div className="form-group">
                        <div className="completeprofileseller__submit-btn--parent">
                            <button className="completeprofileseller__submit-btn btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
    )
}

export default CompleteProfileSeller;