import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import './StartDiscovering.css';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { getPublicCategories, getCompaniesForCategoryById, getFaqQuestions } from '../../utils/api';
import { getTrimmedString } from '../../utils/utils';

function StartDiscovering() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategorySlug, setSelectedCategorySlug] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [faq, setFaq] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCategories(){
      let res = await getPublicCategories();

      if (res.success){
        setCategories(res.data);
        setSelectedCategoryId(res.data[0].category_id);
      }
    }

    loadCategories();
  }, []);

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < Math.ceil(number); i++){
      el.push(<span key={mainindex} className="startdiscovering__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - Math.ceil(number); i++){
      el.push(<span key={mainindex} className="startdiscovering__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  async function handleCategoryClick(id){
    setSelectedCategoryId(id);
    setLoadingCompanies(true);
  }

  async function handleCompanyClick(id, item){
    setLoadingDetails(true);
    setTimeout(() => {
      setSelectedCompany(item);
      setLoadingDetails(false);
    }, 600);
  }

  useEffect(async () => {
    if (selectedCategoryId != null){
      let res = await getCompaniesForCategoryById(selectedCategoryId);

      if (res.success){
        setCompanies(res.data);
        setSelectedCompany(res.data[0]);
        setLoadingCompanies(false);
      }
    }
  }, [selectedCategoryId]);

  function setCircleProgress(percentage) {
    const circle = document.getElementById('circle');
    const radius = 15.9155; // The radius of the circle path
    const circumference = 2 * Math.PI * radius;
  
    const offset = circumference - (percentage / 100) * circumference;
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = offset;
  }
  
  return (
    <div className='startdiscovering'>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='startdiscovering__header'>
        Start discovering software
      </div>
      

      <div className='startdiscovering__categories'>
          {categories.sort((a, b) => b.company_count - a.company_count).slice(0, 7).map((item, index) => (
              <div key={index} className={`startdiscovering__category ${selectedCategoryId == item.category_id ? 'selected': ''}`} onClick={() => handleCategoryClick(item.category_id)}>
              {item.name}
            </div>
          ))}
        </div>

        <div className='startdiscovering__companies'>
          {loadingCompanies === true && (
              <div className='startdiscovering__companies-list-loading center mt-20 mb-20'><Spinner /></div>
          )}

          {loadingCompanies === false && (
          <div className='startdiscovering__companies-list'>
            {loadingCompanies === false && companies.slice(0, 9).map(item => (
              <div className={`startdiscovering__company ${selectedCompany?.company_id == item.company_id ? 'selected' : ''}`} onClick={() => handleCompanyClick(item.company_id, item)}>
                <FadeIn delay={200} duration={200}>
                <div className='startdiscovering__company-name'>
                  {item.name}
                </div>

                <div className='startdiscovering__company-rating'>
                  {getStars(item.average_rating)} <span className='startdiscovering__company-rating-count'>{`(${item.review_count})`}</span>
                </div>

                <div className='startdiscovering__company-logo'>
                {item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                )}
                </div>
                </FadeIn>
              </div>
            ))}
          </div>
          )}

          {loadingDetails && (
            <div className='startdiscovering__company-details'>
              <Spinner />
            </div>
          )}

          {selectedCompany && loadingDetails === false && (
          <div className='startdiscovering__company-details'>
            <FadeIn delay={300} duration={300}>
            <div className='startdiscovering__company-details-header'>
              <div className='startdiscovering__company-details-header-left'>
              <div className='startdiscovering__company-details-header-logo'>
                {selectedCompany.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + selectedCompany.logo} alt="" />
                )}
              </div>

              <div className='startdiscovering__company-details-header-info'>
                <div className='startdiscovering__company-details-header-name'>
                  {selectedCompany.name}
                </div>
                <div className='startdiscovering__company-details-header-rating'>
                  {getStars(selectedCompany.average_rating)} <span className='startdiscovering__company-rating-count'>{`(${selectedCompany.review_count})`}</span>
                </div>
              </div>
              </div>

              <div className='startdiscovering__company-details-header-cta'>
                <button onClick={() => navigate(`/company/${selectedCompany.company_id}/apply`)}>Learn more</button>
              </div>
            </div>

            {selectedCompany.averages && selectedCompany.averages.length > 0 && (
              <div className='startdiscovering__company-details-scores'>
                {selectedCompany.averages.filter(x => x.average != 0 && x.average != null).map(av => (
                  <div className='startdiscovering__company-details-score'>
                    <div className='startdiscovering__company-details-score-label'>
                      {av.name}
                    </div>

                  <div class="score-container">
                    <svg class="score-circle" viewBox="0 0 36 36">
                      <path class={`circle-bg ${parseInt(av.average) < 4 ? 'warning' : ''}`}
                            d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831" />
                      <path class={`circle ${parseInt(av.average) < 4 ? 'warning' : ''}`} style={{strokeDasharray: `${parseFloat(av.average) / 5 * 100}, 50`}}
                            d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831" />
                    </svg>
                      <div class={`score-text2 ${parseInt(av.average) < 4 ? 'warning' : ''}`}>
                        {av.average}
                      </div>
                  </div>
                </div>
                ))}
              </div>
            )}

            <div className='startdiscovering__company-details-description'>
              <div className='startdiscovering__company-details-description-label'>Product description</div>

              {selectedCompany.description && (
                <div className='startdiscovering__company-details-description-text'>
                  {getTrimmedString(selectedCompany.description, 250)}
                </div>
              )}
            </div>
            </FadeIn>
          </div>
          )}
        </div>
    </div>
  );
}

export default StartDiscovering;