import './ReassignDemo.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import close from '../../assets/design/pricing/closing-x.png';
import { getCompanyTeam, reassignDemo, refreshToken} from '../../utils/api';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import Spinner from '../../components/layout/Spinner';
import { refresh } from '../../services/auth.service';

const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

const ReassignDemo = ({demo_id, demos, setDemos, refresh = null}) => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    async function loadTeam(){
      let res = await getCompanyTeam();

      if (res.success){
        setTeam(res.data);
        setLoading(false);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getCompanyTeam();

          if (res.success){
            setTeam(res.data);
            setLoading(false);
          }
        }
      }
    }

    loadTeam();
  }, []);

  async function handleReassign(id){
    let res = await reassignDemo(demo_id, id);

    if (res.success){
      setShowConfirm(true);
      let filter = demos.filter(item => item.demo_id !== demo_id);
      setDemos(filter);

      if (refresh){
        await refresh();
      }
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await reassignDemo(demo_id, id);

          if (res.success){
            setShowConfirm(true);
            let filter = demos.filter(item => item.demo_id !== demo_id);
            setDemos(filter);

            if (refresh){
              await refresh();
            }
          }
        }
    }
  }
  
  return (
    <>
      {showConfirm === false && (
        <>
          <h2 className='modal__h2'>Reassign demo</h2>

          <p><Link to='/account/team' className='link'>+ Invite a team member</Link></p>

      <div className='dashboarddemo__team'>
        {loading && (
          <Spinner />
        )}
        
        {team.map(item => (
          <div className='dashboarddemo__team-member' onClick={() => handleReassign(item.registered_user_id)}>
            <div className='dashboarddemo__team-member-profile-image'>
              {item.profile_image && item.profile_image != '' && (
                <img src={imageUrl + 'profileimages/' + item.profile_image} alt="logo found" />
              )}

              {!item.profile_image && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}
            </div>

            <div>
              {item.first_name && item.first_name.length > 0 && (
                <>
                  <span>{item.first_name}</span>

                  {item.last_name && item.last_name.length > 0 && (
                    <span style={{marginLeft: "10px"}}>
                      {item.last_name.substring(0, 1).toUpperCase()}.
                    </span>
                  )}
                </>
              )}

              {(!item.first_name || item.first_name.length === 0) && (
                <>
                  <span>{item.email}</span>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      </>
      )}

      {showConfirm === true && (
        <>
          <h2 className='modal__h2'>Demo successfully reassigned</h2>
          <p>
            This demo has been reassigned. Your teammate will be notified through email and they will take it from here.
          </p>
        </>
      )}
    </>
  );
};

export default ReassignDemo;
