import './LeadCard.css';
import './RescheduleConfirmation.css';
import {useState,useEffect} from 'react';
import close from '../../assets/design/dashboard/close.png';
import { sellerConfirmsRescheduleDemo, sellerReschedule, refreshToken, getUserCompany, getPackageItemsRemainingCount, demoPackagePurchaseExists } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { FaRegCheckCircle, FaTimes } from 'react-icons/fa';

function RescheduleConfirmation({demo_id, demos, setDemos, currentDemo, isCompleted, setShowRescheduleConfirmation, show_buttons = true, remove_when_done }) {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmation2, setShowConfirmation2] = useState(false);
  const [selectedTimeId, setSelectedTimeId] = useState(0);
  const [selectedTime, setSelectedTime] = useState('');
  const [showReschedule, setShowReschedule] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [company, setCompany] = useState(null);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [purchaseExists, setPurchaseExists] = useState(true);
  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

  async function loadCompany(){
    let res = await getUserCompany();

    if (res.success){
      setCompany(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getUserCompany();

      if (res.success){
        setCompany(res.data);
      }
    }
  }

  async function checkExists(){
    let res = await demoPackagePurchaseExists(currentDemo.demo_id);

    if (res.success){
      if (res.data === true){
        setPurchaseExists(true);
      }

      else{
        setPurchaseExists(false);
      }
    }
  }

  async function loadRemainingCount(){
    let res = await getPackageItemsRemainingCount();

    if (res.success){
      setRemainingCount(res.data);
      setRemainingCountLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getPackageItemsRemainingCount();

      if (res.success){
        setRemainingCount(res.data);
        setRemainingCountLoaded(true);
      }
    }
  }

  useEffect(() => {
    loadCompany();
    loadRemainingCount();
    checkExists();
  }, []);

  async function handleConfirm(){
    if (selectedTimeId === null || selectedTimeId === 0){
      alert('Must select a time in order to book demo')
      return;
    }

    let res = await sellerConfirmsRescheduleDemo(currentDemo.demo_id, selectedTime, selectedTimeId);

    if (res.success){
      setShowConfirmation(true);

      let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);

      if (remove_when_done === false){
        _obj.seller_status = 'Approved';
        _obj.buyer_status = 'Approved';
        _obj.reschedule_date_time = new Date(selectedTime);
        _filter.push(_obj);
      }

      setDemos(_filter);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await sellerConfirmsRescheduleDemo(currentDemo.demo_id, selectedTime, selectedTimeId);

          if (res.success){
            setShowConfirmation(true);

            let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
            let _obj = Object.assign({}, currentDemo);

            _obj.seller_status = 'Approved';
            _obj.buyer_status = 'Approved';
            _obj.reschedule_date_time = new Date(selectedTime);
            _filter.push(_obj);
            setDemos(_filter);
          }
        }
    }
  }

  function handleTimeChange(item){
    setSelectedTimeId(item.demo_available_time_id);
    setSelectedTime(item.available_time);
  }

  function handleClose(){
    setShowRescheduleConfirmation(false);
  }

  function handleRescheduleClick(item) {
    setShowReschedule(true);
  }

  async function confirmReschedule(){
    setSubmitting(true);
    let res = await sellerReschedule(currentDemo.demo_id);

    if (res.success){
      let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);
      _obj.seller_status = 'Rescheduled';
      _obj.buyer_status = 'Approved';
      _filter.push(_obj);
      setDemos(_filter);

      setShowReschedule(false);
      setShowConfirmation2(true);
      setSubmitting(false);
      handleClose();
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await sellerReschedule(currentDemo.demo_id);

        if (res.success){
          let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
          let _obj = Object.assign({}, currentDemo);
          _obj.seller_status = 'Rescheduled';
          _obj.buyer_status = 'Approved';
          _filter.push(_obj);
          setDemos(_filter);

          setShowReschedule(false);
          setShowConfirmation2(true);
          setSubmitting(false);
          handleClose();
        }
      }
    }
  }

return (
  <div>
    <div className='modal__close' onClick={() => setShowRescheduleConfirmation(false)}>
      <FaTimes />
    </div>

    <div className=''>
    <div className=''>
      {showConfirmation === false && (  
        <div className='center'>
          {currentDemo.buyer_timezone && (
            <div className='mb-20' style={{ textAlign: 'left'}}>
              <span style={{background:'#eff5ff', borderRadius: '5px', padding:'5px 20px', fontSize: '14px', fontWeight: 'bold', textAlign: 'left', display: 'inline-block'}}><i>User is in:</i> {currentDemo.buyer_timezone}</span>
            </div>
          )}

           <div className=''>
            <div className=''>
              <div className='rescheduleconfirmation__header'>
                <div className='dashboard__table-company-logo'>
                  <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} />
                </div>

                <span className='ml-10'>{(currentDemo.job_title && currentDemo.job_title.length > 0) ?currentDemo.job_title: currentDemo.persona_type} at {currentDemo.company_name}</span>
              </div>
            </div>
          </div>

          <h2 className='modal__h2' style={{fontSize: '20px'}}>Please confirm the new date and time for this demo.</h2>
          
          <div>
            {currentDemo.demo_available_times.map(time => (
              <div className='rescheduleconfirmation__available-time'>
                 <label htmlFor={`rbTime_${time.demo_available_time_id}`}><input type="radio" id={`rbTime_${time.demo_available_time_id}`} name="available_time" onChange={() => handleTimeChange(time)} /> {weekDays[new Date(time.available_time).getDay()]} {new Date(time.available_time).toLocaleDateString()} at {new Date(time.available_time).toLocaleTimeString()}
                </label>
              </div>
            ))}
          </div>

          <div className='mt-20'>
            <div><strong>None of these times work? No problem.</strong></div>

            <div>You can request that this person submit new times by <span className='link' onClick={() => handleRescheduleClick()}>clicking here.</span></div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className='center'>
          <div className='leadcard__confirmation-header' style={{fontSize: '60px'}}><FaRegCheckCircle /></div>
          <div className='modal__header'>Success!</div>

          <div className='modal__header'>
            Your demo is now scheduled for <span className='dashboarddemo__highlight'>{weekDays[new Date(selectedTime).getDay()]} {new Date(selectedTime).toLocaleDateString()} at {new Date(selectedTime).toLocaleTimeString()}</span>
          </div>

          <div>
            <button className='btn btn--primary' onClick={() => handleClose()}>Close</button>
          </div>
        </div>
      )}
      </div>
    </div>

    {show_buttons && (
    <div className='center' style={{marginTop: "40px"}}>
      {!showConfirmation && (
        <>
          {company && company.payment_type === 'pay_now' && purchaseExists === true && (
            <button className='btn btn--primary btn--dark' onClick={() => handleConfirm()}>Confirm</button>
          )}

          {company && company.payment_type === 'pay_now' && purchaseExists === false && remainingCount > 0 && (
            <>
            <button className='btn btn--primary btn--dark' onClick={() => handleConfirm()}>Confirm</button>
            <div style={{fontSize: '12px', marginTop: '10px'}}>Demo has not yet been charged. Confirmation will use up 1 demo credit.</div>
            </>
          )}

          {company && company.payment_type != 'pay_now' && (
            <button className='btn btn--primary btn--dark' onClick={() => handleConfirm()}>Confirm</button>
          )}

          {company && company.payment_type == 'pay_now' && purchaseExists === false && remainingCount < 1 && (
            <div>
              <Link className='btn btn--payment' to={'/select-plan'}>
                Purchase Credits to Book This Demo
              </Link>
            </div>
          )}
        </>
      )}
    </div>
    )}

    {showReschedule && (
        <>
        <FadeIn delay={150} duration={200}>
          <div className='overlay'></div>

          <div className='modal'>
            <div className='modal__close' onClick={() => setShowReschedule(false)}>
              <FaTimes />
            </div>
            <div className='modal__header center'>The original date will no longer be valid and the other participant will be required to submit new available times.</div>

            <div className='center'>
              {submitting === false && (
                <button className='btn btn--primary btn--dark' onClick={() => confirmReschedule()}>Confirm</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>
          </div>
          </FadeIn>
        </>
      )}

      {showConfirmation2 && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirmation2(false)}>
              <img src={close} />
            </div>

            <p>
              Success! A request to submit new times has been sent. Please wait up 24-hours for new times to be submitted.
            </p>

            <p style={{marginTop: '20px'}}>
              If you have any issues with scheduling, please reach out to us directly <Link to='/leave-feedback' className='link'>here</Link>.
            </p>
          </div>
        </>
      )}
  </div>
)
}

export default RescheduleConfirmation;