import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPointActivity, createPointActivity, getPointUserRewards, updatePointUserRewardStatus } from '../../utils/api/admin';
import './PointActivity.css';

function UserPointRewards() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pointsEarned, setPointsEarned] = useState('');
  const [totalPoints, setTotalPoints]  =useState(0);
  const [costPerCredit, setCostPerCredit] = useState(.25);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getPointUserRewards();

    if (res.success){
      setData(res.data);
      let total = 0;

      for (let i = 0; i < res.data.length; i++){
        total += parseInt(res.data[i].point_cost);
      }

      setTotalPoints(total);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPointUserRewards();

      if (res.success){
        setData(res.data);

        let total = 0;

      for (let i = 0; i < res.data.length; i++){
        total += parseInt(res.data[i].point_cost);
      }

      setTotalPoints(total);

        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function handleCreate(){
    let res = await createPointActivity(name, description, pointsEarned);

    if (res.success){
      loadData();

      setName('');
      setDescription('');
      setPointsEarned('');
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleApprove(id){
    let res = await updatePointUserRewardStatus(id);

    if (res.success){
      loadData();
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Point User Rewards</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div className='admindashboard__cards'>
            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Total Points</strong></div>
              <div className='admindashboard__card-value'>
                {totalPoints} / <span style={{fontSize: '16px', color: '#444'}}>
                  ${(totalPoints * costPerCredit).toFixed(2)}
                </span>
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Cost Per Credit</strong></div>

              <div className='mt-10'>
                <input type='text' className='input' placeholder='Cost per credit...' value={costPerCredit} onChange={(e) => setCostPerCredit(e.target.value)} />
              </div>
            </div>
          </div>

          <div>
            <strong>Records found: {data.length}</strong>
          </div>

          <table className='admindashboard__table table'>
            <tr>
              <th>Date</th>
              <th>Reward</th>
              <th>User</th>
              <th>Points Cost</th>
              <th>Dollar Value</th>
              <th>Payout Method</th>
              <th>Approved By</th>
              <th>Status</th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  {new Date(item.date_created).toLocaleString()}
                </td>

                <td>
                  {item.reward_name}
                </td>

                <td>
                  {item.registered_user_id}
                </td>

                <td>
                  {item.number_of_points}
                </td>

                <td>
                  ${item.dollar_amount}
                </td>

                <td>
                  {item.payout_destination}
                </td>

                <td>
                  {item.approved_by}
                </td>

                <td>
                  {item.status == 'Pending' && (
                    <>
                      <span className='admindashboard__yellow-flag'>Pending</span>
                      <span className='ml-10'><button className='link' onClick={() => handleApprove(item.point_user_reward_id)}>Mark Approved</button></span>
                    </>
                  )}

                  {item.status == 'Approved' && (
                    <span className='admindashboard__green-flag'>Approved</span>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default UserPointRewards;