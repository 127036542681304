import './Notifications.css';
import {useEffect, useState} from 'react';
import { createNotification, getNotifications, refreshToken } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import { refresh } from '../../services/auth.service';

const Notifications = ({userType}) => {
  const [options, setOptions] = useState([]);
  const [enableEmail, setEnableEmail] = useState(false);
  const [enableSms, setEnableSms] = useState(false);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadNotifications(){
      let res = await getNotifications();

      if (res.success){
        if (res.data.length > 0){
          let data = res.data[0];

          setEnableEmail(data.enable_email);
          setEnableSms(data.enable_sms);
          setLoaded(true);
        }
      }

      else if (res.status === 403){
        await refresh();
        let res = await getNotifications();

        if (res.success){
          if (res.data.length > 0){
            let data = res.data[0];

            setEnableEmail(data.enable_email);
            setEnableSms(data.enable_sms);
            setLoaded(true);
          }
        }
      }
    }

    loadNotifications();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleSave(){
     let res = await createNotification('new_demo_request', enableSms, enableEmail);

    if (res.success){

    }

    else if (res.status === 403){
      await refresh();
      let res = await createNotification('new_demo_request', enableSms, enableEmail);
    }

    loadSaveBanner();
  }

  return (
    <div className="notifications">
      <div className=""><Link className="btn" to="/account/home">Back</Link></div>
      <div className="account__header">Notifications</div>

      <div className='accountinfo__form notifications__form'>
        <div>
          Enable/disable how you would like to receive notifications 📩
        </div>

        <div className="notifications__form-group">
          <h2>Demo Notifications</h2>

          <div>
            <h3><strong>New Demo Request</strong></h3>

            <div className="notifications__item">
              <div>SMS Text Notifications</div>
              <div>
              <div className="header__modal-switch--round"><label class="switch">
                <input type="checkbox" checked={enableSms} onChange={(e) => setEnableSms(e.target.checked)}  />
                <span class="slider round"></span>
                    </label>
                </div>
              </div>
            </div>

              <div className="notifications__item">
              <div>Email Notifications</div>
              <div>
              <div className="header__modal-switch--round"><label class="switch">
                <input type="checkbox" checked={enableEmail} onChange={(e) => setEnableEmail(e.target.checked)} />
                <span class="slider round"></span>
                    </label>
                </div>
              </div>
            </div>           
          </div>
        </div>

        <div>
          <button className='btn btn--primary btn--update' onClick={() => handleSave()}>Save</button>
        </div>
      </div>

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </div>
)
}

  export default Notifications;