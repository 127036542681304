import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { createCompanyDemoRequests, getDemoRequestLogs, getCompaniesThatNeedRequests } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisH, FaEllipsisV, FaTimes } from 'react-icons/fa';

function DemoRequests() {
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentDemo, setCurrentDemo] = useState(null);
  const [requestLogs, setRequestLogs] = useState([]);
  const [showRequests, setShowRequests] = useState(false);
  const [currentRequests, setCurrentRequests] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [showNoAvailable, setShowNoAvailable] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadCompanies(){
      let res = await getCompaniesThatNeedRequests();

      if (res.success){
        setCompanies(res.data);
      }
    }

    async function loadRequestLogs(){
      let res = await getDemoRequestLogs();

      if (res.success){
        setRequestLogs(res.data);
        setLoaded(true);
      }
    }

    loadRequestLogs();
    loadCompanies();
  }, []);

  async function loadRequestLogs(){
    let res = await getDemoRequestLogs();

    if (res.success){
      setRequestLogs(res.data);
      setLoaded(true);
    }
  }

  async function loadCompanies(){
    let res = await getCompaniesThatNeedRequests();

    if (res.success){
      setCompanies(res.data);
    }
  }

  async function handleCreateRequests(company_id){
    setSubmitting(true);
    setCurrentCompanyId(company_id);

    let res = await createCompanyDemoRequests(company_id);

    if (res.success){
      if (res.has_available === true){
        await loadRequestLogs();
        loadCompanies();
        setSubmitting(false);
      }

      else{
        setSubmitting(false);
        setShowNoAvailable(true);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Request Management</title>
      </Helmet>

      {loaded === false && (
        <Spinner />
      )}

      <div>
        {loaded && (
        <FadeIn delay={200} duration={200}>
          <h1>Demo Request Management</h1>

          <h2>Active sellers {'(Need requests)'}</h2>
          <div className='admindashboard__request-companies'>
            {companies.map(item => (
              <div className='admindashboard__request-company'>
                <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                  <div className='admindashboard__logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>

                  <div className='admindashboard__demo-card-name'>
                    #{item.company_id} - {item.name}
                  </div>
                </div>

                <div>
                  <div>
                    <strong>Available:</strong> <span>{item.available_buyers}</span>
                  </div>

                  <div>
                    <strong>Per diem:</strong> <span>{item.requests_per_day}</span>
                  </div>

                  {item.last_request_sent && (
                    <>
                      <strong>Last request: </strong>{new Date(item.last_request_sent).toLocaleDateString()}
                    </>
                  )}

                  {item.last_request_sent == null && (
                    <>
                      <strong>Last request: </strong> <span>Never</span>
                    </>
                  )}
                </div>

                <div style={{marginTop: '10px'}}>
                  {submitting === false && (
                    <button className='btn btn--full' onClick={() => handleCreateRequests(item.company_id)}>Send Requests</button>
                  )}

                  {submitting && item.company_id === currentCompanyId && (
                    <Spinner />
                  )}
                </div>
              </div>
            ))}
          </div>

          {loaded && (
            <div className='admindashboard__request-logs-wrapper'>
          <div>
            <strong>Request log</strong>
          </div>

          <div className='admindashboard__request-logs'>
            {requestLogs.map(item => (
              <div onClick={() => {
                setCurrentRequests(item);
                setShowRequests(true);
              }}>
                <div className='admindashboard__request-log'>
                  <div>
                    <strong>#{item.demo_request_log_id}</strong>
                  </div>

                  <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        #{item.company_id} - {item.name}
                      </div>
                    </div>

                    <div>
                      <strong>Date sent: </strong>
                      {new Date(item.date_created).toLocaleDateString()}
                    </div>

                    <div>
                      <strong>Requests sent: </strong>
                      {item.requests.length}
                    </div>
                    
                    <div>
                      <strong>Clicked:</strong> <span>
                        {item.requests.filter(x => x.status == 'Viewed').length}
                      </span>
                    </div>

                    <div>
                      <strong>Applied:</strong> <span>{item.requests.filter(x => x.status == 'Applied').length}</span>
                    </div>

                    <div onClick={() => {
                      setCurrentRequests(item);
                      setShowRequests(true);
                    }}>
                      <FaEllipsisH />
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>
          )}
        </FadeIn>
        )}
      </div>

      {showRequests && (
        <FadeIn delay={200} duration={200}>
          <div className='overlay'></div>
          <div className='modal admindashboard__modal-requests'>
            <div className='modal__close' onClick={()=> setShowRequests(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>User Requests</h2>

          <div className='admindashboard__company-demo-requests'>
            <table className='admindashboard__table'>
              <tr>
                <th></th>
                <th>Date created</th>
                <th>Company</th>
                <th>User</th>
                <th>Template</th>
                <th>Status</th>
              </tr>
              {currentRequests.requests.map((x, index) => (
                <tr>
                  <td><strong>{index + 1}</strong></td>
                  <td>{new Date(x.date_created).toLocaleDateString()}</td>
                  <td>
                  <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + x.logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        {x.company_name}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>#{x.registered_user_id} - {x.first_name}</div>
                    <div><strong>SID:</strong> <span>{x.sid}</span></div>
                    <div><strong>Joined:</strong></div> <span>{new Date(x.date_created_user).toLocaleDateString()}</span>
                  </td>
                  <td>
                    <div><strong>ID: </strong>{x.template_id}</div>
                    <div>{x.template_name}</div>
                    <div>{x.subject}</div>
                  </td>
                  <td>
                    <div className={`admindashboard__demo-request-status ${x.status.toLowerCase()}`}>
                      {x.status}
                    </div>
                  </td>
                </tr>
              ))}
              </table>
            </div>
          </div>
        </FadeIn>
      )}

      {showNoAvailable && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowNoAvailable(false)}>
              <FaTimes />
            </div>

            <div className='center'>
              <h2 className='modal__h2'>Uh oh...There are no users available for this company</h2>
            </div>

            <p>
              It might be good to modify this companies ICP selections in order to improve outreach.
            </p>

            <div className='center' style={{marginTop: '20px'}}>
              <button className='btn' onClick={() => setShowNoAvailable(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DemoRequests;
;
