
import './Feed.css';
import {useEffect, useState} from 'react';
import { refreshToken, getUserProfile, getAllIndustries, addFeedItem, getFeedItems, getFeedItemComments, getFeedItemCommentReplies, addFeedItemComment, addFeedItemLike, addFeedItemCommentLike } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle } from "react-icons/fa";
import close from '../../assets/design/dashboard/close.png';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { Switch } from '@chakra-ui/react';

function Comment({comment, loadComments, currentFeedItemId, is_reply = false}){
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [currentFeedItemCommentId, setCurrentFeedItemCommentId] = useState(null);
  const [replies, setReplies] = useState([]);
  const [likeCount, setLikeCount] = useState(comment.like_count);
  const [replyCount, setReplyCount] = useState(comment.reply_count);
  const [submitting, setSubmitting] = useState(false);
  const [submittingComment, setSubmittingComment] = useState(false);
  const [isAnon, setIsAnon] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  async function handleShowCommentReplyInput(id){
    setCurrentFeedItemCommentId(id);
    setShowReplyInput(!showReplyInput);
  }

  function getTimeAgo(datePosted) {
    const MINUTE = 60 * 1000; // milliseconds in a minute
    const HOUR = 60 * MINUTE; // milliseconds in an hour
    const DAY = 24 * HOUR; // milliseconds in a day
  
    const currentTime = new Date().getTime(); // current timestamp
    const postedTime = new Date(datePosted).getTime(); // posted timestamp
  
    const timeDifference = currentTime - postedTime; // difference in milliseconds
  
    if (timeDifference < MINUTE) {
      const seconds = Math.floor(timeDifference / 1000);
      return `${seconds} seconds ago`;
    } else if (timeDifference < HOUR) {
      const minutes = Math.floor(timeDifference / MINUTE);
      return `${minutes} minute${minutes == '1' ? '': 's'} ago`;
    } else if (timeDifference < DAY) {
      const hours = Math.floor(timeDifference / HOUR);
      return `${hours} hour${hours == '1' ? '': 's'} ago`;
    } else {
      const days = Math.floor(timeDifference / DAY);
      return `${days} day${days == '1' ? '': 's'} ago`;
    }
  }

  async function handleCommentLikeClick(id){
    let res = await addFeedItemCommentLike(id);

    if (res.success){
      if (res.data === true){
        setLikeCount(parseInt(likeCount) + 1);
      }

      else{
        setLikeCount(parseInt(likeCount) - 1);
      }
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addFeedItemCommentLike(id);

        if (res.success){
          await loadComments(currentFeedItemId);
        }
      }
    }

    else{
      //alert('nope');
    }
  }

  async function handleReplyPost(parent_id){
    if (replyText.length === 0){
      alert("Gotta enter something!");
      return;
    }

    setSubmittingComment(true);

    let res = await addFeedItemComment(currentFeedItemId, parent_id, replyText, isAnon);

    if (res.success){
      setSubmittingComment(false);
      setShowReplyInput(false);
      setReplyText('');
      setIsAnon(false);
      handleLoadReplies(parent_id);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addFeedItemComment(currentFeedItemId, parent_id, replyText, isAnon);

        if (res.success){
          setSubmittingComment(false);
          setShowReplyInput(false);
          setReplyText('');
          setIsAnon(false);
          handleLoadReplies(parent_id);
        }
      }
    }

    else{
      //alert('nope');
    }
  }

  async function handleLoadReplies(feed_item_comment_id){
    if (currentFeedItemCommentId == feed_item_comment_id && showReplies){
      setShowReplies(false);
      return;
    }

    setShowReplies(true);

    let res = await getFeedItemCommentReplies(feed_item_comment_id);

    if (res.success){
      setReplies(res.data);
      setReplyCount(res.data.length);
      setCurrentFeedItemCommentId(feed_item_comment_id);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getFeedItemCommentReplies(feed_item_comment_id);

        if (res.success){
          setReplies(res.data);
          setReplyCount(res.data.length);
          setCurrentFeedItemCommentId(feed_item_comment_id);
        }
      }
    }

    else{
      //alert('nope');
    }
  }

  async function handleSwitchToggle(){
    setIsAnon(!isAnon);
  }

  return (
    <div className={is_reply === false ? 'feed__item-comment' : 'feed__item-comment-reply'} >
      <div className='feed__item-comment-header'>
          <div className='feed__item-comment-image'>
            {comment.profile_image && comment.profile_image !== '' && comment.is_anon == false && (
              <img src={baseImageUrl + 'profileimages/' + comment.profile_image} alt="logo found" />
            )}

            {(!comment.profile_image || comment.profile_image === '' || comment.is_anon == true) && (
              <img src={iconCompanyDefault} alt="logo not found" />
            )}
          </div>

          <div className='feed__item-comment-info'>
            {comment.is_anon === false && (
              <>
                <div className='feed__item-comment-info-name'>{comment.first_name} {comment.last_name != null && comment.last_name.length > 0 ? comment.last_name.substring(0, 1): ''}.</div>
                <div className='feed__item-comment-info-job'>
                    {comment.job_title} at {comment.company_name}
                </div>
              </>
            )}

            {comment.is_anon && (
              <div className='feed__item-comment-info-name'>
                {comment.job_title} working in {comment.industry}
              </div>
            )}

            {comment.is_seller && (
              <span className='feed__item-header-role feed__item-header-role-seller feed__item-header-role--small'>Software Provider</span>
            )}

          {comment.is_buyer && (
              <span className='feed__item-header-role feed__item-header-role--small'>Discovering Software</span>
            )}
          </div>
        </div>

        <div className='feed__item-comment-text'>
          {comment.comment_body}
        </div>

        <div className='feed__item-comment-actions'>
          <span onClick={() => handleCommentLikeClick(comment.feed_item_comment_id)}>
          <FaRegThumbsUp /> {likeCount} Like
          </span>

          <span onClick={() => handleShowCommentReplyInput(comment.feed_item_comment_id)}>Reply</span>

          <span>
            <a href={`/company/${comment.company_id}`} target='_blank'>Book a demo with us!</a>
          </span>

          <span onClick={() => handleLoadReplies(comment.feed_item_comment_id)} style={{float: "right"}}>
            {replyCount} repl{replyCount == '1' ? 'y' : 'ies'}
          </span>
        </div>

        <div className='feed__item-comment-postedon'>{getTimeAgo(comment.date_created)}</div>

        {showReplyInput && currentFeedItemCommentId == comment.feed_item_comment_id && (
          <div className='feed__item-comment-input'>
            <div>
              <input type='text' className='input' placeholder='Your reply...' value={replyText} onChange={(e) => setReplyText(e.target.value)} />
            </div>

            <div style={{marginTop: "10px"}}>
              <button className='feed__item-comment-input-btn' onClick={() => handleReplyPost(comment.feed_item_comment_id)}>Post</button>

              <span className='feed__anon'>
                <label htmlFor='email-alerts' mb='0'>
                  Post Anonymously
                </label>

                <Switch className='feed__anon-switch' id='email-alerts' onChange={() => handleSwitchToggle()} />

                {isAnon && (
                  <span className='feed__anon-disclaimer'>* Your name will not be displayed.</span>
                )}
              </span>
            </div>
          </div>
        )}

                {replies.length > 0 && showReplies && (
                  <>
                    {replies.map(reply => (
                      <>
                        <Comment comment={reply} loadComments={loadComments} currentFeedItemId={currentFeedItemId} is_reply={true} />
                      </>
                    ))}
                  </>
                )}
    </div>
  )
}

const Feed = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [user, setUser] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contentBody, setContentBody] = useState('');
  const [showPost, setShowPost] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [showPostConfirm, setShowPostConfirm] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentReply, setCurrentReply] = useState('');
  const [currentFeedItemId, setCurrentFeedItemId] = useState(null);
  const [currentFeedItemCommentId, setCurrentFeedItemCommentId] = useState(null);
  const [showReplies, setShowReplies] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submittingComment, setSubmittingComment] = useState(false);
  const [submittingReply, setSubmittingReply] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('Latest');
  const [isAnon, setIsAnon] = useState(false);
  const [commentIsAnon, setCommentIsAnon] = useState(false);
  let { slug } = useParams();
  const navigate = useNavigate();

  async function loadFeedItems(){
    let res = await getFeedItems(slug);

    if (res.success){
      setItems(res.data);
      setLoading(false);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getFeedItems(slug);

        if (res.success){
          setItems(res.data);
          setLoading(false);
        }
      }
    }

    else{
      alert('failed loading feed items');
    }
  }

  async function handleCommentSwitchToggle(){
    setCommentIsAnon(!commentIsAnon);
  }

  useEffect(() => {
      // based on users current role as buyer or seller
    async function loadUserProfile(){
      let response = await getUserProfile();

      if (response.success){
        let data = response.data;

        setUser(response.data);
        //setLoading(false);
      }

      else if (response.status === 403){
        let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let response = await getUserProfile();

            if (response.success){
              let data = response.data;

              setUser(response.data);
              //setLoading(false);
            }
          }
      }
    }

    async function loadIndustries(){
      let res = await getAllIndustries();

      if (res.success){
        setIndustries(res.data);
      }
    }

    loadUserProfile();
    loadIndustries();
    loadFeedItems();
  }, []);

  async function handlePost(){
    if (contentBody.length === 0){
      alert("Gotta enter something!");
      return;
    }

    setSubmitting(true);
    let res = await addFeedItem(slug, contentBody, isAnon);

    if (res.success)
    {
      setSubmitting(false);
      setContentBody('');
      setShowPost(false);
      setShowPostConfirm(true);

      loadFeedItems();
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addFeedItem(2, contentBody);

        if (res.success)
        {
          setSubmitting(false);
          setContentBody('');
          setShowPost(false);
          setShowPostConfirm(true);

          loadFeedItems();
        }
      }
    }

    else{
      alert('nope');
    }
  }

  function getTimeAgo(datePosted) {
    const MINUTE = 60 * 1000; // milliseconds in a minute
    const HOUR = 60 * MINUTE; // milliseconds in an hour
    const DAY = 24 * HOUR; // milliseconds in a day
  
    const currentTime = new Date().getTime(); // current timestamp
    const postedTime = new Date(datePosted).getTime(); // posted timestamp
  
    const timeDifference = currentTime - postedTime; // difference in milliseconds
  
    if (timeDifference < MINUTE) {
      const seconds = Math.floor(timeDifference / 1000);
      return `${seconds} seconds ago`;
    } else if (timeDifference < HOUR) {
      const minutes = Math.floor(timeDifference / MINUTE);
      return `${minutes} minute${minutes == '1' ? '': 's'} ago`;
    } else if (timeDifference < DAY) {
      const hours = Math.floor(timeDifference / HOUR);
      return `${hours} hour${hours == '1' ? '': 's'} ago`;
    } else {
      const days = Math.floor(timeDifference / DAY);
      return `${days} day${days == '1' ? '': 's'} ago`;
    }
  }

  const updateItemProperty = async (itemId, property, value) => {
    // console.log(itemId, property, value);
    // let filtered = items.filter(x => x.feed_item_id == itemId)[0];
    // setItems({...filtered, [property]: value});

    const updatedItems = items.map(item => {
      if (item.feed_item_id == itemId) {
        // Create a new object with the added property or update the existing property
        item.comments = value;
        item.comment_count = value.length;

        return item;
      }
      return item;
    });

    console.log('updated items', updatedItems);
  
    // Update the state with the modified array
    setItems(updatedItems);
  };

  async function loadComments(feed_item_id){
    setCurrentFeedItemId(feed_item_id);
    let res = await getFeedItemComments(feed_item_id);

    if (res.success){
      await updateItemProperty(feed_item_id, 'comments', res.data);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getFeedItemComments(feed_item_id);

        if (res.success){
         await updateItemProperty(feed_item_id, 'comments', res.data);
        }
      }
    }

    else{

    }
  }

  async function handleReplyClick(id){
   // alert(id);
    if (currentFeedItemId === id){
      setShowCommentInput(!showCommentInput);
    }

    else{
      setShowCommentInput(true);
    }

    setCurrentFeedItemId(id);
  }

  async function handleAddComment(){
    setSubmittingComment(true);

    let res = await addFeedItemComment(currentFeedItemId, null, currentComment, commentIsAnon);

    if (res.success){
      setSubmittingComment(false);
      setShowCommentInput(false);
      setShowComments(true);
      setCommentIsAnon(false);

      await loadComments(currentFeedItemId);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addFeedItemComment(currentFeedItemId, null, currentComment, commentIsAnon);

        if (res.success){
          setSubmittingComment(false);
          setShowCommentInput(false);
          setShowComments(true);
          setCommentIsAnon(false);

          await loadComments(currentFeedItemId);
        }
      }
    }

    else{
      alert('nope');
    }
  }

  async function handleCommentChange(id, e){
    setCurrentComment(e.target.value);
    setCurrentFeedItemId(id);
  }

  async function handleLikeClick(id){
    let res = await addFeedItemLike(id);

    if (res.success){
      if (res.data === true){
        // increase like count by 1
        const updatedItems = items.map(item => {
          if (item.feed_item_id == id) {
            // Create a new object with the added property or update the existing property
            item.like_count = parseInt(item.like_count) + 1;
            item.is_liked = true;  
            return item;
          }
          return item;
        });
          
        setItems(updatedItems);
      }

      else{
        // decrease like count by 1
        const updatedItems = items.map(item => {
          if (item.feed_item_id == id) {
            // Create a new object with the added property or update the existing property
            item.like_count = parseInt(item.like_count) - 1;
            item.is_liked = false; 
            return item;
          }
          return item;
        });
          
        // Update the state with the modified array
        setItems(updatedItems);
      }
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await addFeedItemLike(id);

    if (res.success){
      if (res.data === true){
        // increase like count by 1
        const updatedItems = items.map(item => {
          if (item.feed_item_id == id) {
            // Create a new object with the added property or update the existing property
            item.like_count = parseInt(item.like_count) + 1;
            item.is_liked = true;  
            return item;
          }
          return item;
        });
          
        setItems(updatedItems);
      }

      else{
        // decrease like count by 1
        const updatedItems = items.map(item => {
          if (item.feed_item_id == id) {
            // Create a new object with the added property or update the existing property
            item.like_count = parseInt(item.like_count) - 1;
            item.is_liked = false; 
            return item;
          }
          return item;
        });
          
        // Update the state with the modified array
        setItems(updatedItems);
      }
    }
      }
    }

    else{
      alert('nope');
    }
  }

  async function handleCommentClick(feed_item_id){
    if (currentFeedItemId == feed_item_id)
      setShowComments(!showComments);
    else
      setShowComments(true);

    setCurrentFeedItemId(feed_item_id);
    loadComments(feed_item_id);
  }

  async function sortByPopular(){
    setItems((prevState) => {
      const sortedArray = [...prevState].sort((a, b) => b.like_count - a.like_count);
      return sortedArray;
    });

    setShowFilterDropdown(false);
    setCurrentFilter('Most popular');
  }

  async function sortByLatest(){
    setItems((prevState) => {
      const sortedArray = [...prevState].sort((a, b) => b.date_created - a.date_created);
      return sortedArray;
    });

    setShowFilterDropdown(false);
    setCurrentFilter('Latest');
  }

  async function sortByEngaged(){
    setItems((prevState) => {
      const sortedArray = [...prevState].sort((a, b) => b.comment_count - a.comment_count);
      return sortedArray;
    });

    setShowFilterDropdown(false);
    setCurrentFilter('Most engaged');
  }

  async function handleSwitchToggle(){
    setIsAnon(!isAnon);
  }

  return (
    <>
    <Helmet>
        <title>Community Feed</title>
      </Helmet>

    <div className="feed">
      <div className='feed__tag--active' onClick={() => navigate('/feeds')}>&lt; #{slug}</div>
      <h1 className='feed__h1'>My Feed</h1>

      <p className='feed__blurb'>
        Share your thoughts, ask the community or answer somebody's question.
      </p>

      <div className='feed__post-btn-wrapper'>
        <button className='feed__post-btn' onClick={() => setShowPost(true)}><span style={{marginLeft: "5px"}}>Post</span></button>
      </div>

      <div className='feed__entry'>
        <div className='feed__item-header-image'>
              {user.profile_image && user.profile_image !== '' && (
                <img src={baseImageUrl + 'profileimages/' + user.profile_image} alt="logo found" />
              )}

              {(!user.profile_image || user.profile_image === '') && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}
            </div>

        <div className='feed__entry-input'>
          <input type='text' className='input' placeholder='Start the conversation...' />
        </div>

        <div>
          <button className='btn btn--primary'>Post</button>
        </div>
      </div>
      
      {loading && (
        <Spinner />
      )}

      {items.length > 0 && loading === false && (
      <div>
        <div className='feed__filter-label' style={{textAlign: "right"}} onClick={() => setShowFilterDropdown(!showFilterDropdown)}>
          <span>Sort by:</span> {currentFilter}
        </div>

        {showFilterDropdown && (
        <div className='feed__filter-dropdown'>
          <div onClick={() => sortByLatest()}>Latest</div>
          <div onClick={() => sortByEngaged()}>Most engaged</div>
          <div onClick={() => sortByPopular()}>Most popular</div>
        </div>
        )}
      </div>
      )}

      {items.length === 0 && loading === false && (
        <div>
          <div style={{fontSize: "30px", fontWeight: "bold", textAlign: "center"}}>Be the first to start a conversation!</div>

          <div className='center feed__no-items'>
            <img src="https://ik.imagekit.io/ivgrhmd4k/path33b05.svg?updatedAt=1684645146051" />
            </div>
        </div>
      )}

      <div className='feed__items'>
        {items.map(item => (
          <div className='feed__item'>
          <div className='feed__item-header'>
            {item.company_id == null && (
            <div className='feed__item-header-image'>
              {item.profile_image && item.profile_image !== '' && item.is_anon === false && (
                  <img src={baseImageUrl + 'profileimages/' + item.profile_image} alt="logo found" />
                )}

                {(!item.profile_image || item.profile_image === '' || item.is_anon === true) && (
                  <img src={iconCompanyDefault} alt="logo not found" />
                )}
            </div>
            )}

            {item.company_id && (
               <div className='feed__item-header-image'>
               {item.seller_logo && item.seller_logo !== '' && (
                   <img src={baseImageUrl + 'companylogos/' + item.seller_logo} alt="logo found" />
                 )}
 
                 {(!item.seller_logo || item.seller_logo === '') && (
                   <img src={iconCompanyDefault} alt="logo not found" />
                 )}
              </div>
            )}

            {!item.company_id && (
            <div style={{marginLeft:"20px"}}>
              {item.is_anon === false && (
              <div className='feed__item-header-name'>{item.first_name} {item.last_name != null && item.last_name.length > 0 ? item.last_name.substring(0, 1): ''}. <FaUserCircle style={{fill: "#aaa", height: "12px"}} onClick={() => setShowProfileCard(true)} /></div>
              )}

              {item.is_anon === false && (
              <div className='feed__item-header-job'>{item.job_title} at {item.company_name}</div>
              )}

              {item.is_anon && (
                <div className='feed__item-header-name'>
                  {item.job_title} working in {item.industry}
                </div>
              )}

              {item.is_buyer && item.is_seller === false && (
                <div className='feed__item-header-role feed__item-header-role--small'>Discovering Software</div>
              )}

              {item.is_buyer === false && item.is_seller && (
                <div className='feed__item-header-role feed__item-header-role-seller feed__item-header-role--small'>Software Provider</div>
              )}

              {item.is_buyer === true && item.is_seller === true && (
                <div className='feed__item-header-role-mixed'>
                  <div className='feed__item-header-role feed__item-header-role--small'>Discovering Software</div>
                  <div className='feed__item-header-role feed__item-header-role-seller feed__item-header-role--small'>Software Provider</div>
                </div>
              )}
            </div>
            )}

            {item.company_id != null && (
              <div style={{marginLeft: "20px"}}>
                <div className='feed__item-header-name'>{item.seller_name}
                </div>

                <div className='feed__item-header-role feed__item-header-role-seller feed__item-header-role--small'>Software Provider</div>
              </div>
            )}
          </div>

          <div className='feed__item-body'>
            {item.content_body}
          </div>

          <div className='feed__item-footer'>
            <div className='feed__item-footer-item' onClick={() => handleLikeClick(item.feed_item_id)}>
              {item.is_liked === false && (
                <span style={{opacity: ".3"}}>
                  <FaRegThumbsUp /> <span className='feed__item-like-count'>{item.like_count}</span>
                </span>
              )}

              {item.is_liked && (
                <span>
                  <FaRegThumbsUp /> <span className='feed__item-like-count'>{item.like_count}</span>
                </span>
              )}
            </div>

            <div className='feed__item-footer-item' onClick={() => handleReplyClick(item.feed_item_id)}>
              <FaRegComment /> <span className='feed__item-like-count'>Reply</span>
            </div>

            {item.comment_count != '0' && (
            <div className='feed__item-footer-item feed__item-footer-link'>
              <span className='link' onClick={() => handleCommentClick(item.feed_item_id)}>{item.comment_count} comment{item.comment_count == '1' ? '' : 's'}</span>
            </div>
            )}
          </div>

          <div className='feed__item-actions'>
            <div className='feed__item-actions-menu hide'><svg xmlns="http://www.w3.org/2000/svg" style={{height: "20px"}} viewBox="0 0 128 512"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg></div>

            <div className='feed__item-posted-on'>
              {getTimeAgo(item.date_created)}
            </div>
          </div>

          {showCommentInput && item.feed_item_id == currentFeedItemId && (
            <FadeIn delay={200} duration={200}>
            <div className='feed__item-comment-input'>
              <div>
                <input type='text' className='input' placeholder='Your comment...' onChange={(e) => handleCommentChange(item.feed_item_id, e)} />
              </div>

              <div>
                {submittingComment === false && (
                  <>
                <button className='feed__item-comment-input-btn' onClick={() => handleAddComment()}>Post</button>

                <span className='feed__anon'>
                  <label htmlFor='email-alerts' mb='0'>
                    Post Anonymously
                  </label>

                  <Switch className='feed__anon-switch' id='email-alerts' onChange={() => handleCommentSwitchToggle()} />

                  {isAnon && (
                    <span className='feed__anon-disclaimer'>* Your name will not be displayed.</span>
                  )}
                </span>
                </>
                )}

                {submittingComment && (
                  <Spinner />
                )}
              </div>
            </div>
            </FadeIn>
          )}

          {showComments && currentFeedItemId === item.feed_item_id && (
          <div className='feed__item-comments'>
            <div className='feed__item-comments-list'>
              {item.comments && item.comments.filter(x => x.feed_item_id === item.feed_item_id && x.parent_id === null).map(comment => (
                <div>
                  <Comment comment={comment} loadComments={loadComments} currentFeedItemId={currentFeedItemId}  />
              </div>
              )
              )}
            </div>
          </div>
          )}
        </div>
        ))}
      </div>
    </div>

    {showPost && (
      <>
        <div className='overlay'></div>

        <div className='modal feed__modal-post'>
          <div className='modal__close' onClick={() => setShowPost(false)}>
            <img src={close} />
          </div>
          
          <div className='feed__modal-post-header'>
            Start a conversation
          </div>

          <div>
            <textarea className='input feed__textarea' placeholder='What would you like to ask/answer/post?' value={contentBody} onChange={(e) => setContentBody(e.target.value)}>

            </textarea>
          </div>

          <div>
            {submitting === false && (
              <div>
                <button className='feed__modal-post-btn' onClick={handlePost}>Post</button>

                <span className='feed__anon'>
                  <label htmlFor='email-alerts' mb='0'>
                    Post Anonymously
                  </label>

                  <Switch className='feed__anon-switch' id='email-alerts' onChange={() => handleSwitchToggle()} />

                  {isAnon && (
                    <span className='feed__anon-disclaimer'>* Your name will not be displayed.</span>
                  )}
                </span>
              </div>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {showProfileCard && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
          <div className='modal__close'>
            <img src={close} onClick={() => setShowProfileCard(false)} />
          </div>

          <div>
            <div>
              <div className='feed__item-header-name'>Walter G. <FaUserCircle style={{fill: "#aaa", height: "12px"}} onClick={() => setShowProfileCard(true)} /></div>
              <div className='feed__item-header-job'>CTO at LeadrPro</div>
              <div className='feed__item-header-role'>Discovering Software</div>
            </div>

            <div style={{marginTop: "20px"}}>
              <div>
                <strong>Industry: </strong> Aerospace
              </div>

              <div>
                <strong>Company Size: </strong> 100-200
              </div>

              <div>
                <strong>Department: </strong> Engineering
              </div>
            </div>

            <div style={{marginTop: "20px"}}>
              <button className='feed__btn-invite'>Invite to a demo</button>
            </div>
          </div>
        </div>
      </>
    )}

    {showPostConfirm && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowPostConfirm(false)}>
            <img src={close} />
          </div>

          <div>
            <h2 className='modal__h2'>Your post is now live ✅</h2>

            <p>

            </p>
          </div>
        </div>
      </>
    )}
    </>
  )
}

  export default Feed;