import { useState, useEffect } from "react";
import './MyEvents.css';
import { getEvents, getRegisteredEvents, getEventsByQuery, getEventsRoundtable } from '../../utils/api';
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
import ScrollToTop from "../../components/layout/ScrollToTop";
import { getSeoName, checkSession } from "../../utils/utils";
import { refresh } from "../../services/auth.service";
import { FaCalendarAlt, FaSlidersH } from "react-icons/fa";
import Spinner from "../../components/layout/Spinner";
import moment from 'moment-timezone';
import chase from '../../assets/design/logos/jp.jpg';
import mucker from '../../assets/design/logos/mucker.png';
import kl from '../../assets/design/logos/kl.png';
import heroimage from '../../assets/design/homepagehub/events/eventshero.png';
import heroicon from '../../assets/design/homepagehub/events/heroicon.png';
import FadeIn from "../../utils/fadein";

function MyEvents() {
    const [events, setEvents] = useState([]);
    const [roundtable, setRoundtable] = useState([]);
    const [myEvents, setMyEvents] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [selectedTab, setSelectedTab] = useState(3);
    const navigate = useNavigate();

    useEffect(() => {
      async function loadEvents(){
        let res = await getEvents(page);

        if (res.success){
          setEvents(res.data);
          setLoading(false);
        }
      }

      async function loadRegisteredEvents(){
        let res = await getRegisteredEvents();

        if (res.success){
          setMyEvents(res.data);
          setLoading(false);

          // if (res.data.length > 0)
          //   setSelectedTab(2);
        }

        else if (res.status === 403){
          await refresh();

          let res = await getRegisteredEvents();

          if (res.success){
            setMyEvents(res.data);
            setLoading(false);

            // if (res.data.length > 0)
            //   setSelectedTab(2);
          }
        }
      }

      async function loadRoundtable(){
        let res = await getEventsRoundtable(page);

        if (res.success){
          setRoundtable(res.data);
          setLoading(false);
        }
      }

        loadEvents();
        loadRoundtable();
        loadRegisteredEvents();
        checkSession('Browse Events');
    }, []);

    async function handleEventClick(id, name){
      let seoname = getSeoName(name)
      navigate(`/event/${id}/${seoname}`);
    }

    async function loadMore(){
      setLoading(true);
      let res = await getEvents(page + 1);

      if (res.success){
        setEvents(prevArray => [...prevArray, ...res.data]);
        //setEvents(res.data);
        setPage(prev => prev + 1);
        setLoading(false);
      }
    }

    async function loadEvents(){
      let res = await getEvents(page);

      if (res.success){
        setEvents(res.data);
        setLoading(false);
      }
    }

    async function handleSearch(){
      if (query.length > 0){
        setLoading(true);
        let res = await getEventsByQuery(query);

        if (res.success){
          setPage(1);
          setEvents(res.data);
          setLoading(false);
        }
      }

      else{
        setLoading(true);
        await loadEvents();
      }
    }

    async function handleInputChange(e){
      setQuery(e.target.value);
      
      if (e.target.value == ''){
        await loadEvents();
      }
    }
    
    return (
      <>
      <div className="manageevent">
        <ScrollToTop />

        <Helmet>
          <title>Attend local business events and conferences in your area</title>
          <meta name="description" content="Attend our Founders Roundtable events and hear from industry experts about their strategies and insights going into 2024." />
          <link rel="canonical" href={`https://www.leadrpro.com/events`} />
        </Helmet>

        {/* <div className='homepagehubevents__hero'>
        <div className='homepagehubevents__hero-left'>
          <div className='homepagehubevents__hero-icon'>
            <img src={heroicon} alt='' /> <span className='homepagehubevents__hero-icon-text'>Events Hub</span>
          </div>

          <div className='homepagehub__hero-h1'>
            Network with<br/>
            industry experts
          </div>

          <div className='homepagehub__hero-subheader'>
            Discover in-person events or online webinars where you can meet and network with industry experts who also share their stories and experiences building successful businesses.
          </div>

          <div className='homepagehub__hero-btns'>
            <button onClick={() => navigate('/signup')} className='homepagehub__hero-btn homepagehub__hero-btn--primary'>
              Find an event
            </button>

            <button className='homepagehub__hero-btn'>
              Host an event
            </button>
          </div>
        </div>

        <div className='homepagehubevents__hero-right'>
          <img src={heroimage} alt='' />
        </div>
      </div> */}

        <div className="manageevent__form">
          <h1>Browse Local Events</h1>

          <p className="myevents__description">
            Attend our Founders Roundtable events and hear from industry experts about their strategies and insights going into 2024.
          </p>

          <div className="eventdashboard__filter">
          <span className={`eventdashboard__filter-item${selectedTab==3?' selected':''}`} onClick={() => setSelectedTab(3)}>LeadrPro Roundtable Events</span>

            <span className={`eventdashboard__filter-item${selectedTab==1?' selected':''}`} onClick={() => setSelectedTab(1)}>Upcoming Webinars</span>

            <span className={`eventdashboard__filter-item${selectedTab==2?' selected':''}`} onClick={() => setSelectedTab(2)}>Registered</span>

            <span className={`eventdashboard__filter-item${selectedTab==4?' selected':''}`} onClick={() => setSelectedTab(4)}>Past Events</span>
          </div>

          {selectedTab === 3 && (
            <FadeIn delay={100} duration={100}>
              <div className="myevents__signature-events">

              <div className="myevents__logos-wrapper">
                <div className="myevents__our-sponsors">Our Sponsors</div>
                <div className="myevents__top-banner-logos">
                  <div><img src={chase} /></div>
                  <div><img src={kl} /></div>
                  <div><img src={mucker} /></div>
                </div>
              </div>

              {loading === true && (
                <div className="center">
                  <div className="myevents__item placeholder">

                  </div>

                  <div className="myevents__item placeholder">

                  </div>

                  <div className="myevents__item placeholder">

                  </div>

                  <div className="myevents__item placeholder">

                  </div>
                </div>
              )}

            {roundtable.filter(x => new Date(x.start_date) >= new Date()).map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail'>
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}><img src={x.image_url} />
                    </Link>
                    </div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail">
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>
                      <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </Link>
                  </div>
                )}

                <div className='myevents__item-info'>
                  <div className='myevents__item-info-name'>{x.name} {x.is_registered == true && (
                    <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                  <div className="events__list-item-date">
                    <strong>Event date:</strong> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div>

                <div className="events__list-item-date">
                  <strong>Location:</strong> {x.city} {x.state}
                </div>

                <div style={{marginTop: '20px'}}>
                  <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>Details</Link>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}
                </div>
                </div>
              </div>
            ))}
          </div>
            </FadeIn>
          )}

          {selectedTab === 1 && (
              <FadeIn delay={300} duration={300}>
                {/* <div className='events__filters'>
                  <span className="events__filters-input">
                    <input type="text" className='input' placeholder="Search by name, city, state..." value={query} onChange={(e) => handleInputChange(e)} />
                  </span>

                  <span className="events__filters-btn">
                    <button className="btn" onClick={() => handleSearch()}>Search</button>
                  </span>
                </div> */}

                <div className="myevents__signature-events">
            {events.map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail' onClick={() => handleEventClick(x.event_id, x.name)}><img src={x.image_url} /></div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail" onClick={() => handleEventClick(x.event_id, x.name)}>
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </div>
                )}

                <div className='myevents__item-info'>
                  <div className='myevents__item-info-name'>{x.name} 
                  {x.is_registered == true && (
                    <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                  <div className="events__list-item-date">
                    <strong>Event date:</strong> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div>

                {/* <div className="events__list-item-date">
                  <strong>Location:</strong> {x.city} {x.state}
                </div> */}

                <div style={{marginTop: '20px'}}>
                  <span className="btn" onClick={() => handleEventClick(x.event_id, x.name)}>Details</span>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}
                </div>
                </div>
              </div>
            ))}
          </div>

          <div className="center">
          {loading == false && query.length === 0 && (
            <span className="events__btn-load-more" onClick={() => loadMore()}>Load more...</span>
          )}

          {loading && (
            <Spinner />
          )}
          </div>
          </FadeIn>
            )}

          {selectedTab === 2 && (
              <FadeIn delay={300} duration={300}>
                {myEvents.length === 0 && (
                  <div className='center'>
                    You have not registered for any events.
                  </div>
                )}
                <div className="myevents__signature-events">
            {myEvents.map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail' onClick={() => navigate(`/event/${x.event_id}/${getSeoName(x.name)}`)}><img src={x.image_url} /></div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail" onClick={() => navigate(`/event/${x.event_id}/${getSeoName(x.name)}`)}>
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </div>
                )}

                <div className='myevents__item-info'>
                  <div className='myevents__item-info-name'>{x.name} 
                  
                  {x.is_registered == true && (
                  <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                  <div className="events__list-item-date">
                    <strong>Event date:</strong> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div>

                <div className="events__list-item-date">
                  <strong>Location:</strong> {x.city} {x.state}
                </div>

                <div style={{marginTop: '20px'}}>
                <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>Details</Link>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}

                  <a className='btn'
              href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Event:+${encodeURIComponent(
                x.name
              )}&dates=${new Date(x.start_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}/${new Date(x.end_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}&details=Event+details:+https://www.leadrpro.com/event/${x.event_id}/${getSeoName(x.name)}&sf=true&output=xml&ctz=${
                x.timezone
              }`}
              target='_blank'>
                  <FaCalendarAlt /> Add to calendar
            </a>
                </div>
                </div>
              </div>
            ))}
          </div>
          </FadeIn>
            )}

            {selectedTab === 4 && (
              <FadeIn delay={100} duration={100}>
                <div>
                {roundtable.filter(x => new Date(x.start_date) < new Date()).map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail'>
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}><img src={x.image_url} />
                    </Link>
                    </div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail">
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>
                      <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </Link>
                  </div>
                )}

                <div className='myevents__item-info'>
                  <div className='myevents__item-info-name'>{x.name} {x.is_registered == true && (
                    <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                  <div className="events__list-item-date">
                    <strong>Event date:</strong> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div>

                <div className="events__list-item-date">
                  <strong>Location:</strong> {x.city} {x.state}
                </div>

                <div style={{marginTop: '20px'}}>
                  <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>Details</Link>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}
                </div>
                </div>
              </div>
            ))}
                </div>
              </FadeIn>
            )}
        </div>
      </div>
        
        <div className="events">
          <p className='events__description'>
            Attend local events and network with other attendees using our mobile app. Don't see an event that you are looking for? Not a problem. <a href="https://zviv2pvc6i2.typeform.com/to/WFIw0UAq" target="_blank" className="link">Submit your event</a>
          </p>
        </div>
        </>
    );
}

export default MyEvents;