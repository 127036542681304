import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { getPublicCategories } from "../../utils/api";
import "./Category.css";

function Category() {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadData() {
      let res = await getPublicCategories();

      if (res.success) {
       setData(res.data);
       setLoaded(true);
      }
    }

    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>LeadrPro - Categories</title>
        <link rel="canonical" href="https://www.leadrpro.com/categories" />
      </Helmet>

    <div className="category">
      <div className="category__body">
      <h1>
        Featured Categories
      </h1>

      {loaded === false && (
        <div className='category__placeholders'>
          <div className='category__placeholder'></div>
          <div className='category__placeholder'></div>
          <div className='category__placeholder'></div>
          <div className='category__placeholder'></div>
          <div className='category__placeholder'></div>
        </div>
      )}

      {loaded && data.length > 0 && (
        <div className="category__items">
          {data.map((item, index) => (
            <div className="category__item" key={index}>
              <div className="category__item-name">
                {item.name}
              </div>

              <div className="category__item-description">
                {item.description}
              </div>

              <div className="category__item-url">
                <Link className="link" to={`/category/${item.slug}`}>View all software solutions</Link>
              </div>
            </div>
          ))}
        </div>
      )}
      </div>
    </div>
    </>
  );
}

export default Category;