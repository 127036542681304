import { useState,  useEffect } from 'react';
import './CompleteProfileNewBuyer.css';
import { getCompanySizes, getCompanyIndustries, getSeniorityLevels, saveCompanyProfile, saveUserProfile } from '../../utils/api';
import React from 'react';

const CompleteProfileNewBuyer = ({setShowPhoneNumber, setShowProfile, setShowLogoAndTimezone, companyId, userId}) => {
    const [companySize, setCompanySize] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const [seniority, setSeniority] = useState([]);
    const [department, setDepartment] = useState("");
    
    useEffect(() => {
        companySizeSearch();
        industrySearch();
        senioritySearch();
    }, []);

    //get company sizes from api
    async function companySizeSearch() {
        let data = await getCompanySizes();
        if (data.data){
            setCompanySize(data.data);
        }
    }

    async function industrySearch() {
        let data = await getCompanyIndustries();
        if (data.data) {
            setIndustry(data.data)
        }
    }

    async function senioritySearch() {
        let data = await getSeniorityLevels();
        if (data.data) {
            setSeniority(data.data)
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        let el1 = e.target.elements.companysize.value;
        let el2 = e.target.elements.industry.value;

        let el3 = e.target.elements.jobtitle.value;
        let el4 = e.target.elements.senioritylevel.value;
        let el5 = e.target.elements.department.value;

        let res = await saveCompanyProfile({company_id: companyId, company_size_id: el1, industry_id: el2});

        if (res.success){
            let res2 = await saveUserProfile(el3, el4, el5, userId);

            if (res2.success){
            }
        }

        else{
        }
        setShowProfile(false);
        setShowPhoneNumber(true);
        setShowLogoAndTimezone(true);
    }

    return (
        <div className="center card__wrapper">
        <div className="card">
            <div className="card__inner">
                <h2 className="card__header completeprofilenewbuyer__header">
                    Complete company profile
                </h2>

                <form onSubmit={handleSubmit}>   
                    <div className="completeprofilenewbuyer__column--left column"> 
                    <div className="form-group">
                        <label className='label input-label'>Company size</label>

                        <select
                            className="input-box dropdown-font"
                            type='text'
                            name='companysize'
                            id=''
                            placeholder='Enter company size'
                            required
                        >
                            {companySize.length > 0 && (
                                companySize.map((item) => (
                                    <option value={item.company_size_id}>
                                        {item.value}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                        <label className='label input-label'>Industry</label>
                        <select
                            className="input_box dropdown-font"
                            id=''
                            name='industry'
                            placeholder='Enter Industry'
                        >
                            {industry.length > 0 && (
                                industry.map((item) => (
                                    <option value={item.industry_id}>
                                        {item.name}
                                    </option>
                                ))
                            )}
                        </select>                  
                    </div>
                </div>

                <div className="completeprofilenewbuyer__column--right column">
                    <div className="form-group">
                        <label className='label input-label'>Job title</label>
                        
                        <input
                            value={jobTitle}
                            onChange={event => setJobTitle(event.target.value)}
                            className="input_box"
                            type='text'
                            id=''
                            name='jobtitle'
                            placeholder='Enter job title' />
                    </div>

                    <div className="form-group">
                        <label className='label input-label'>Seniority</label>

                        <select
                            className="input_box dropdown-font"
                            type='text'
                            id=''
                            name="senioritylevel"
                            placeholder='Enter seniority'
                        >
                            {seniority.length > 0 && (
                                    seniority.map((item) => (
                                        <option value={item.seniority_level_id}>
                                            {item.name}
                                        </option>
                                    ))
                            )}
                        </select>
                    </div>

                    <div className="form-group">
                    <label className='label input-label'>Department</label>
                        <input
                            value={department}
                            onChange={event => setDepartment(event.target.value)}
                            className="input_box"
                            type='text'
                            id=''
                            name="department"
                            placeholder='Enter department'
                    />
                    </div>
                    </div>

                        <div className="form-group">
                            <button className="btn btn-primary">Save and continue</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
    )
}

export default CompleteProfileNewBuyer;
