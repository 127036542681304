import { useState, useEffect } from 'react';
import './DemoMessage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { Link, useParams } from 'react-router-dom';
import { getDemoMessages, addDemoMessages, getDemoFromRef, refreshToken, sellerReschedule} from '../../utils/api';
import { FaPaperPlane } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import RescheduleTimes from '../../components/qualifier/RescheduleTimes';
import FadeIn from '../../utils/fadein';
import close from '../../assets/design/dashboard/close.png';
import ScrollToTop from '../../components/layout/ScrollToTop';

const DemoMessage = () => {
  let user = getCurrentUser();
  const search = useLocation().search;
  let [messages, setMessages] = useState([]);
  let [message, setMessage] = useState('');
  let [currentDemo, setDemo] = useState({});
  const [loaded ,setLoaded] = useState(false);
  const [sending, setSending] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showSellerReschedule, setShowSellerReschedule] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [done, setDone] = useState(false);
  const [demos, setDemos] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  let { refid } = useParams();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

  async function getMessages(){
    let _res = await getDemoMessages(refid);

    if (_res.success){
      setMessages(_res.data);
    }

    else if (_res.status === 403){
      await refresh();
      let _res = await getDemoMessages(refid);

      if (_res.success){
        setMessages(_res.data);
      }
    }

    setLoaded(true);
  }

  useEffect(() => {
    async function getMessages(){
      let _res = await getDemoMessages(refid);

      if (_res.success){
        setMessages(_res.data);
      }

      else if (_res.status === 403){
        await refresh();

        let _res = await getDemoMessages(refid);

        if (_res.success){
          setMessages(_res.data);
        }
      }

      setLoaded(true);
    }

    async function loadDemo(){
      let _res = await getDemoFromRef(refid);

      if (_res.success){
        setDemo(_res.data[0]);
      }

      else if (_res.status === 403){
        await refresh();

        let _res = await getDemoFromRef(refid);

        if (_res.success){
          setDemo(_res.data[0]);
        }
      }
    }

    //const interval = setInterval(() => {
    getMessages();
    //}, 3000);

    //getMessages();
    loadDemo();
  }, []);

  async function handleSendClick(){
    if (message === ''){
      return;
    }

    setSending(true);

    let _res = await addDemoMessages(refid, message);

    if (_res.success){
      setMessage('');
      setSending(false);
      await getMessages();
    }

    else if (_res.status === 403){
      await refresh();

      let _res = await addDemoMessages(refid, message);

      if (_res.success){
        setMessage('');
        setSending(false);
        await getMessages();
      }
    }
  }

  async function confirmReschedule(){
    setSubmitting(true);
    let res = await sellerReschedule(currentDemo.demo_id);

    if (res.success){
      setShowSellerReschedule(false);
      setSubmitting(false);
      setShowConfirm(true);
      setDone(true);
    }

    else if (res.status === 403){
      let res = await sellerReschedule(currentDemo.demo_id);

      if (res.success){
        setShowSellerReschedule(false);
        setShowConfirm(true);
        setDone(true);
      }

      setSubmitting(false);
    }
  }

  return (
    <>
    <ScrollToTop />
      <div className='demomessage'>
        <div className='demomessage__info'>
          <div className='btn btn--back' onClick={() => navigate('/dashboard/inbox')}>Back to inbox</div>

          <div className='demomessage__info-companies'>
            {currentDemo.buyer_id === user.registered_user_id && (
              <div>
                <div className='demomessage__logo'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} />
                </div>

                <div className='demomessage__info-name'>
                  {currentDemo.seller_first_name ?? currentDemo.seller_job_title}{' '}
                  from {currentDemo.company_name}
                  
                  {currentDemo.demo_status_id == 4 && (
                  <span className='demomessage__label-completed'>Demo has been completed</span>
                )}

                {currentDemo.demo_status_id == 1 && (currentDemo.buyer_status == 'Rescheduled' || currentDemo.seller_status == 'Rescheduled') && (
                  <span className='demomessage__label-rescheduled'>Demo being rescheduled</span>
                )}

                {currentDemo.demo_status_id == 1 && (currentDemo.buyer_status == 'Approved' && currentDemo.seller_status == 'Approved') && (
                  <span className='demomessage__label-upcoming'>Demo is upcoming</span>
                )}

                <div>
                {currentDemo.demo_date && (
                  <div className='demomessage__info-date'>
                    <div>
                      <span>Demo scheduled for: {new Date(
                            currentDemo.demo_date).toLocaleDateString(undefined, options)}</span>
                    </div>
                  </div>
                )}
                </div>
                </div>
              </div>
            )}

            {currentDemo.seller_id === user.registered_user_id && (
              <div style={{display: 'flex'}}>
                <div className='demomessage__logo'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.buyer_logo} />
                </div>

                <div className='demomessage__info-name'>{currentDemo.buyer_first_name ?? currentDemo.buyer_job_title}{' '}from {currentDemo.buyer_company_name}
                
                {currentDemo.demo_status_id == 4 && (
                  <span className='demomessage__label-completed'>Demo has been completed</span>
                )}

                {currentDemo.demo_status_id == 1 && (currentDemo.buyer_status == 'Rescheduled' || currentDemo.seller_status == 'Rescheduled') && (
                  <span className='demomessage__label-rescheduled'>Demo being rescheduled</span>
                )}

                {currentDemo.demo_status_id == 1 && (currentDemo.buyer_status == 'Approved' && currentDemo.seller_status == 'Approved') && (
                  <span className='demomessage__label-upcoming'>Demo is upcoming</span>
                )}

                <div>
                {currentDemo.demo_date && (
                  <div className='demomessage__info-date'>
                    <div>
                      <span>Demo scheduled for: {new Date(
                            currentDemo.demo_date).toLocaleDateString(undefined, options)}</span>
                    </div>
                  </div>
                )}
                </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {loaded === false && (
          <div className='center'><Spinner /></div>
        )}

        {loaded && (
        <div className='demomessage__messages'>
          <div className='demomessage__input'>
            <textarea type='text' placeholder='Write a message' value={message} onChange={(e) => setMessage(e.target.value)} className='input'></textarea>

            {sending === false && (
            <button className='btn btn--primary demomessage__btn' onClick={() => handleSendClick()}>
                <><FaPaperPlane /></>
            </button>
            )}

          {sending && (
                <Spinner />
              )}
          </div>

          {messages.length === 0 && (
            <div className='demomessage__no-messages'>
              No messages available...
            </div>
          )}

          {messages.map(item => (
            <>
              <div className={`demomessage__message${item.registered_user_id !== user.registered_user_id ? ' other-message': ''}`}>
                <div className='demomessage__message-header'>
                  <div>
                    <div className='demomessage__logo'>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                    </div>
                  </div>
                </div>

                <div className="demomessage__message-text-wrapper">
                <div className='demomessage__date-created'>{new Date(
                       item.date_created
                     ).toLocaleDateString(undefined, options)}</div>
                  <div className='demomessage__message-text'>
                  <div className='demomessage__message-first-name'>{item.first_name ? item.first_name : 'No name'}</div>

                   <div>{item.message_text}</div>
                  </div>
                </div>
              </div>
            </>
          ))}

            <hr style={{marginBottom: "20px"}}/>

          {currentDemo.buyer_id === user.registered_user_id && (
              <div>
                {currentDemo.buyer_status === 'Rescheduled' && (
                  <>
                    <span><i>New times submitted. Awaiting approval.</i></span>
                  </>
                )}

                {currentDemo.buyer_status === 'Approved' && currentDemo.seller_status === 'Approved' && (
                <span className='link' onClick={() => setShowReschedule(true)}>I need to reschedule</span>
                )}
              </div>
            )}
        </div>
        )}
      </div>

      {showReschedule && (
        <>
          <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              <RescheduleTimes
                demo={currentDemo}
                demos={demos}
                setDemos={setDemos}
                demo_id={currentDemo.demo_id}
                company_name={currentDemo.company_name}
                seller_id={currentDemo.seller_id}
                showSteps={true}
                setShowReschedule={setShowReschedule}
              />
            </div>
          </div>
        </>
      )}

      {showSellerReschedule && (
        <>
          <FadeIn delay={150} duration={200}>
            <div className='overlay'></div>

            <div className='modal'>
              <div>
                <div>
                  <div className='dashboarddemo__company-logo--small'>
                    <img
                      src={
                        imageUrl +
                        'tr:di-@@companylogos@@default-buyer.png/' +
                        currentDemo.buyer_logo
                      }
                    />
                  </div>
                  <div>
                      <strong>{currentDemo.buyer_company_name}</strong>
                  </div>
                  </div>
                <div>
                  {new Date(currentDemo.demo_date).toLocaleDateString()}{' '}{new Date(currentDemo.demo_date).toLocaleTimeString()}
                </div>
              </div>

              <div className='modal__body-small' style={{marginTop: "10px"}}>
                Are you sure you want to reschedule this demo? The original date
                will no longer be valid and the other participant will be
                required to submit new available times.
              </div>

              <div className='center' style={{marginTop: "10px"}}>
                <button
                  className='btn btn--primary btn--small'
                  onClick={() => confirmReschedule()}>
                    {submitting === false && (
                      <span>Confirm</span>
                    )}

                    {submitting && (
                      <div>
                        <Spinner />
                      </div>
                    )}
                </button>
              </div>

              <div className='modal__close'>
                <img src={close} onClick={() => setShowSellerReschedule(false)} />
              </div>
            </div>
          </FadeIn>
        </>
      )}

      {showConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirm(false)}>
              <img src={close} />
            </div>

            <div className='modal__body--small'>
              Success! The other party has been notified of the reschedule. Please wait up to 24-48 hours for newly submitted times.
            </div>

            <div className='center'>
              <button className='btn btn--primary' onClick={() => setShowConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DemoMessage;