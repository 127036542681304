import { useState, useEffect } from 'react';
import './Waitlist.css';
import { getCurrentUser } from '../../services/auth.service';
import { getInbox, getUserWaitlist, refreshToken} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import {useNavigate} from 'react-router-dom';
import ScrollToTop from '../../components/layout/ScrollToTop';

const Waitlist = () => {
  let user = getCurrentUser();
  const [inbox, setInbox] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [loaded ,setLoaded] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    async function loadInbox(){
      let _res = await getInbox();

      if (_res.success){
        setInbox(_res.data);
      }

      else if (_res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let _res = await getInbox();

          if (_res.success){
            setInbox(_res.data);
          }
        }
      }

      setLoaded(true);
    }

    async function loadWaitlist(){
      let res = await getUserWaitlist();

      if (res.success){
        setWaitlist(res.data);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getUserWaitlist();

          if (res.success){
            setWaitlist(res.data);
          }
        }
      }
    }

    loadInbox();
    loadWaitlist();
  }, []);

  return (
    <div className='waitlist'>
      <ScrollToTop />
      <div className='demomessages__demos'>
        <h1 className='buyerdashboard__h1'>My Waitlist</h1>

        <p className='demomessages__description'>
          When these companies are ready to begin taking demos, you will be automatically notified.
        </p>

        {waitlist.length === 0 && loaded && (
          <div>
            You have not waitlisted any company's.
          </div>
        )}

        <p>
          {waitlist.map(item => (
            <div className='waitlist__item'>
              <div className='waitlist__company-logo'>
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
              </div>

              <div className='waitlist__company-info'>
                <div className='waitlist__company-name'>{item.name}</div>
                <div className='waitlist__company-description'>{item.description}</div>
              </div>
            </div>
          ))}
        </p>

        {loaded === false && (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default Waitlist;