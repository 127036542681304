// * this component is going to render the dropdowns for the user info in the review pages
// * the first parameter is going to let the function know what dropdown to render
// * the rest of the paramters are going to be state function and state
import { useState, useEffect } from "react"
import { getSeniorityLevels, getPersonaTypes, getCompanySizes } from "../../../utils/api";

function Dropdowns ({ type, class_name, handleChange, userInfo }) {
  // * Load the seniority levels
  const [seniority, setSeniority] = useState([]);

  async function loadSeniority(){
    const { data } = await getSeniorityLevels();
    setSeniority(data);
  }

  useEffect(() => {
    loadSeniority();
  }, []);

  // * Load the roles
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function loadRoles(){
      const { body } = await getPersonaTypes('Buyer');
      console.log(body, "This is the body of the persona types")
      setRoles(body);
    }

    loadRoles();
  }, []);

  // * Load the company sizes
  const [companySizes, setCompanySizes] = useState([]);

  useEffect(() => {
    async function loadCompanySize(){
      const { data } = await getCompanySizes();
      setCompanySizes(data);
    }

    loadCompanySize();
  }, []);



  if(type == "seniority"){
    return (
      <select
        className={`${class_name}`}
        type='text'
        name='seniority'
        placeholder='Seniority Level'
        onChange={handleChange}
        value={userInfo.seniority}
        required
      >
        <option value={-1}>Select Seniority</option>
        {seniority.length > 0 && (
            seniority.map((item, index) => (
                <option key={index} value={item.seniority_level_id}>
                    {item.name}
                </option>
            ))
        )}
      </select>
    )
  }
  if (type == "company_size"){
    return (
      <select
      className={`${class_name}`}
      type='text'
      name='company_size'
      placeholder='Company Size'
      onChange={handleChange}
      value={userInfo.company_size}
      required
      >
        <option value={-1}>Select Company Size</option>
        {companySizes.length > 0 && (
            companySizes.map((item, index) => (
                <option key={index} value={item.company_size_id}>
                    {item.value}
                </option>
            ))
        )}
      </select>
    )
  }
  if(type == "role"){
    return (
      <select
      className={`${class_name}`}
      type='text'
      name='persona_type'
      placeholder='Role'
      onChange={handleChange}
      value={userInfo.persona_type}
      required
    >
      <option value={-1}>Select Role</option>
      {roles.length > 0 && (
          roles.map((item, index) => (
              <option key={index} value={item.persona_type_id}>
                  {item.name}
              </option>
          ))
      )}
    </select>
    )
  }
} 

export default Dropdowns