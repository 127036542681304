import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './DashboardDemo.css';
import close from '../../assets/design/dashboard/close.png';
import DeclinedPopup from './DeclinedPopup';
import RescheduleTimes from '../../components/qualifier/RescheduleTimes';
import SubmitReschedule from '../../components/qualifier/SubmitReschedule';
import CalendarLinks from '../../components/qualifier/CalendarLinks';
import { getSeoName } from '../../utils/utils';
import moment from 'moment-timezone';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { FaTimes, FaEllipsisV, FaRegCalendar, FaRegCalendarAlt, FaRegQuestionCircle } from 'react-icons/fa';
import { getBuyerDemosPending } from '../../utils/api';
import { refresh } from '../../services/auth.service';

const BuyerDemosPending = ({buyerDeclinedReasons}) => {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [showFutureDemoNotice, setShowFutureDemoNotice] = useState(false);
  const [showMeetingDetails, setShowMeetingDetails] = useState(false);
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [hasDemos, setHasDemos] = useState(true);
  const [showJoin, setShowJoin] = useState(true); // 11.17.2022 - show join button
  const [loaded, setLoaded] = useState(false);
  const [demos, setDemos] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const navigate = useNavigate();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  async function handleToggleMenu(item){
    if (item.demo_id == currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(true);
    }
  }

  useEffect(() => {
    async function loadDemos(){
      let res = await getBuyerDemosPending();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getBuyerDemosPending();

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
        }
      }
    }

    loadDemos();
  }, []);

  useEffect(() => {
    let _democount = demos.filter(
      (item) =>
        item.seller_status === 'Approved' &&
        item.buyer_status === 'Approved' &&
        item.demo_status === 'Pending'
    ).length;

    if (_democount > 0){
      setHasDemos(true);
    }
    else
      setHasDemos(false);
  }, [demos]);

  function declinedClickHandle(demo, id) {
    setCurrentDemo(demo);
    setShowDeclinedPopup(true);
    setShowSubMenu(false);
  }

  function formatDate(date) {
    let d = new Date(date);
    let weekDay = d.getDay();
    let day = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    return weekDays[weekDay] + ', ' + months[month] + ' ' + day + ' ' + year;
  }

  function tFormat(time) {
    time = time.toString();
    const zero = '0';

    if (time.length <= 3) {
      time = zero.concat(time);
      const temp = Array.from(time);
      temp.splice(2, 0, ':');
      const formatted = temp.toString().replace(/,/g, '');
      return formatted;
    } else {
      const temp = Array.from(time);
      temp.splice(2, 0, ':');
      const formatted = temp.toString().replace(/,/g, '');
      return formatted;
    }
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  function futureDemoNoticeClick(demo) {
    let dt = new Date();
    dt.setUTCHours(dt.getUTCHours() - 1);

    if (demo.reschedule_date_time !== null) {
      let dt = new Date();
      let dt2 = new Date(demo.reschedule_date_time);

      dt2.setUTCHours(dt2.getUTCHours() - 1);

      if (dt > dt2) {
        setShowJoin(true);
      } else {
        setShowJoin(false);
      }
    } else {
      let dt = new Date();
      let dt2 = new Date(demo.scheduled_date_time);

      dt2.setUTCHours(dt2.getUTCHours() - 1);

      if (dt > dt2) {
        setShowJoin(true);
      } else {
        setShowJoin(false);
      }
    }

    setShowSubMenu(false);
    setShowMeetingDetails(true);
    setCurrentDemo(demo);
  }

  function handleRescheduleClick(item) {
    setShowMeetingDetails(false);
    setCurrentDemo(item);
    setShowReschedule(true);
    setShowSubMenu(false);
  }

  function getTrimmedString(inputString = '') {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        // Truncate the string to the maximum length minus 1 (for the ellipsis)
        // and ensure we don't cut off in the middle of a word.
        let trimmedString = inputString.substr(0, maxLength - 1);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return `${trimmedString}…`;
    }
  }

  return (
    <>
    {loaded=== false && (
      <Spinner />
    )}

      {loaded && demos.length === 0 && (
        <div className='dashboarddemo__no-results'>
          <h2>No upcoming demos found</h2>
        </div>
      )}

      {loaded == true && demos.length > 0 && (
        <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
        <tr>
          <th>Company</th>
          <th>Date applied</th>
          <th>Status</th>
          <th></th>
        </tr>

        {demos.map((item, index) => (
          <tr key={index}>
            <td style={{width: '60%'}}>
              <div style={{display: 'flex', alignItems: 'start'}}>
                {/* <div className='buyerdashboard__recommendation-thumbnail' style={{width: '100%'}}>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                  )}
                </div> */}

                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              
                <div className='buyerdashboard__recommendation-name'>
                  <div style={{fontSize: '20px'}}>{item.company_name}</div>

                  <div className='buyerdashboard__recommendation-description' style={{fontSize: '12px'}}>
                    {item.description && item.description.length > 0 && (
                      <>
                        {getTrimmedString(item.description)}
                      </>
                    )}

<div className='mt-10'>
                    <Link className='link' to={`/company/${item.company_id}/details`}>View more details</Link>
                  </div>
                  </div>
                </div>
              </div>
            </td>

            <td>
              <span>
                <strong>{new Date(item.date_applied).toDateString()}</strong>
              </span>
            </td>

            <td>
              <div className='flex'>
                <span className='dashboard__yellow-flag'>Pending</span>
                <span className='ml-10 pointer' title='Currently being reviewed by the company'><FaRegQuestionCircle /></span>
              </div>
            </td>

          <td className='dashboard__table-menu-icon'>
              <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                <FaEllipsisV />
              </span>

              {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                <FadeIn delay={100} duration={100}>
                  <div className='dashboard__submenu'>
                    {item.reference_id && (
                      <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                    )}

                    <div onClick={() => declinedClickHandle(item, item.demo_id)} className='dashboard__submenu-item'>Cancel demo</div>
                  </div>
                </FadeIn>
              )}
            </td>
          </tr>
        ))}
        </table>
        </div>
      )}

      {showDeclinedPopup && (
        <div>
          <DeclinedPopup
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Buyer'
            buyerDeclinedReasons={buyerDeclinedReasons}
            currentDemo={currentDemo}
            setCurrentDemo={setCurrentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Cancel'
            setShowReschedule={setShowReschedule}
          />
        </div>
      )}

      {showReschedule && (
        <>
          <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              {currentDemo.booking_style === 'daily_view' && (
                <RescheduleTimes
                  demo={currentDemo}
                  demos={demos}
                  setDemos={setDemos}
                  demo_id={currentDemo.demo_id}
                  company_name={currentDemo.company_name}
                  seller_id={currentDemo.seller_id}
                  showSteps={true}
                  setShowReschedule={setShowReschedule}
                />
              )}

              {currentDemo.booking_style === 'list_view' && (
                <SubmitReschedule
                  demo={currentDemo}
                  demos={demos}
                  setDemos={setDemos}
                  demo_id={currentDemo.demo_id}
                  company_name={currentDemo.company_name}
                  seller_id={currentDemo.seller_id}
                  showSteps={true}
                  setShowReschedule={setShowReschedule}
                />
              )}
            </div>
          </div>
        </>
      )}

      {showCompanyInfo && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: "80%", marginLeft: "-40%", maxHeight: "80%"}}>
            <img
              src={close}
              onClick={() => setShowCompanyInfo(false)}
              className='modal__close'
            />
            <div className='modal__header'>Company Info</div>
            <div className=''>{currentDemo.description}</div>

            <div className='' style={{ marginTop: '40px' }}>
              <a className='link' href={
                  '/company/' +
                  currentDemo.company_id +
                  '/' +
                  getSeoName(currentDemo.company_name)
                }>
                See full company profile
              </a>
            </div>
          </div>
        </>
      )}

      {showMeetingDetails === true && (
        <FadeIn delay={200} duration={200}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowMeetingDetails(false)}>
              <FaTimes />
            </div>
            
            <div className='center'>
              <div style={{ marginBottom: '10px', fontSize: '30px'}}>
                <FaRegCalendarAlt />
              </div>

              <p>Your meeting is scheduled for</p>

              <div className='dashboarddemo__join-meeting-date'>
                {currentDemo.reschedule_date_time && (
                  <>
                    <div>
                      {new Date(
                        currentDemo.reschedule_date_time
                      ).toLocaleDateString()}{' '}
                      at{' '}
                      {new Date(
                        currentDemo.reschedule_date_time
                      ).toLocaleTimeString()}
                    </div>

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>

                    <CalendarLinks
                      scheduled_date_time={currentDemo.reschedule_date_time}
                      meeting_link={currentDemo.company_meeting_link}
                      persona_type={currentDemo.company_name}
                      reference_id={currentDemo.reference_id}
                    />
                    <div className='dashboarddemo__calendar-reminder'>
                    </div>
                  </>
                )}

                {!currentDemo.reschedule_date_time && (
                  <>
                    {currentDemo.scheduled_date_time && (
                      <div>
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          currentDemo.scheduled_date_time
                        ).toLocaleTimeString()}
                      </div>
                    )}

                    {!currentDemo.scheduled_date_time && (
                      <>
                        <div>
                          {formatDate(currentDemo.scheduled_date)} at{' '}
                          {tConvert(tFormat(currentDemo.scheduled_time))}
                        </div>
                      </>
                    )}

                    <div className='dashboarddemo__date-timezone'>
                      {moment.tz.guess()}
                    </div>

                    <CalendarLinks
                      scheduled_date_time={currentDemo.scheduled_date_time}
                      persona_type={currentDemo.company_name}
                      reference_id={currentDemo.reference_id}
                      meeting_link={currentDemo.company_meeting_link}
                    />
                    <div className='dashboarddemo__calendar-reminder'>

                    </div>
                  </>
                )}
              </div>

              <div style={{ marginTop: '5px' }}>
                <div className='modal__header'>House Rules</div>

                <div className='dashboarddemo__house-rules'>
                  <div>
                    * Be on time and on a computer with a functional webcam
                  </div>
                  <div>
                    * Do not reschedule within 24 hours of the demo time
                  </div>
                  <div>
                    * If you do not show up, you may be locked from future demos
                    for up to 2 weeks.
                  </div>
                </div>
              </div>

              <div className='mar20'>
                Need to{' '}
                <span className='link' onClick={() => handleRescheduleClick(currentDemo)}>
                  Reschedule
                </span>
                ?
              </div>

              <div className='form-group center' style={{ marginTop: '50px' }}>
                {currentDemo.company_meeting_link && (
                  <a className='btn btn--primary btn--dark'
                    href={`${currentDemo.company_meeting_link}`} target='_blank'>
                    Join meeting
                  </a>
                )}

                {!currentDemo.company_meeting_link && (
                  <>
                    {showJoin && (
                      <a className='btn btn--primary btn--dark' href={`https://meetings.leadrpro.com/demo?refid=${currentDemo.reference_id}`} target='_blank'>
                        Join meeting
                      </a>
                    )}

                    {showJoin === false && (
                      <div style={{ fontSize: '14px' }}>
                        Meeting link will appear here 1-hour before scheduled time
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
};

export default BuyerDemosPending;