import './CreateAccount.css';
import { useEffect, useState } from "react";
import { createUser } from '../../utils/api';
import VerifyEmail from './VerifyEmail';
import classNames from "classnames";
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import payment from '../../assets/design/signup/payment.png';
import list from '../../assets/design/signup/list.png';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import {isAuthed, isSeller} from '../../services/auth.service';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegThumbsUp, FaRegComment, FaPlus, FaUserCircle, FaLinkedin, FaShoppingCart, FaSearch, FaMapPin, FaLocationArrow } from "react-icons/fa";
import { Helmet } from 'react-helmet';

function CreateAccount({authenticated}) {
    const [email, setEmail] = useState("");
    const [optIn, setOptIn] = useState(false);
    const [agree, setAgree] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [userSource, setUserSource] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showPrompt, setShowPrompt] = useState(true);
    const [showReferral, setShowReferral] = useState(false);
    const [referrer, setReferrer] = useState('');
    const navigate = useNavigate();
    const search = useLocation().search;

    useEffect(() => {
        const _email = new URLSearchParams(search).get('email');

        if (_email){
            setEmail(_email);
        }

        if (isAuthed()){
            navigate('/');
        }

        const parsed = queryString.parse(window.location.search);
        let channel = '';
  
        if (parsed['channel']) {
          channel = parsed['channel'];
          if (channel.toLowerCase() === 'email')
            setShowVideo(false);
        }

        setLoaded(true);
    }, []);

    async function handleSubmit(e){
        e.preventDefault();

        setLoading(true);

        let el1 = e.target.elements.email.value;
        let el2 = e.target.elements.optIn.value;
        let el3 = e.target.elements.agree.value;
        let channel_token = getCookie('channel_token');
        //let partner_key = getCookie('ps_gspk');
        let partner_key = getCookie('ps_partner_key');
        let click_id = getCookie('ps_xid');
        let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (el1.length === 0){
            setHasError(true);
            return;
        }

        if (!el3){
            setHasError(true);
            return;
        }

        // if (userSource === '0'){
        //     alert("Please select a")
        //     setHasError(true);
        //     return;
        // }

        let data= {email: el1, optIn: el2, promoCode: promoCode, source: userSource, channel_token, timezone: l_timezone, referrer, partner_key, click_id};
        let res  = await createUser(data);

        setEmail(el1);

        if (res.success){
            navigate(`/account-setup?token=${res.data}`);
            //window.location = 'https://www.leadrpro.com/account-setup?token=' + res.data;
        }
        else{
            setLoading(false);
            setHasError(true);
            setErrorMessage(res.message);
        }
    }

    async function handleSourceChange(val){
        setUserSource(val);

        if (val === 'Referral'){
            setShowReferral(true);
        }
    }

    function getCookie(cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

      async function handleContinue(){
        setShowVideo(false);
      }

    if (completed){
        return <FadeIn delay={250} duration={450}><VerifyEmail email={email} token={token} /></FadeIn>
    }

    return (
        <>
        <Helmet>
          <title>Create Free Account</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://www.leadrpro.com/signup" />
        </Helmet>

        {loaded && showVideo === false && (
        <FadeIn delay={300} duration={300}>
        <div className="card__wrapper">
        <div className="card createaccount">
            <div className="createaccount__left">
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='form__group'>
                            <label className='createaccount__input-label' htmlFor='txtEmail'>Email</label>

                            <input className={classNames("createaccount__input", "input", {
                            "input--error":hasError
                        })} type='email' id='txtEmail' value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Enter your email' name="email" />
                        </div>

                        <div className='form-group'>
                            <label className='createaccount__input-label' htmlFor='txtPromo'>Promo code (optional)</label>
                            <input type='text' className='input' id='txtPromo' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} placeholder='Code' />
                        </div>

                        <div className='form-group'>
                            <label className='createaccount__input-label' htmlFor='selectSource'>How did you hear about us?</label>
                            <select className='input select' id='selectSource' onChange={(e) => handleSourceChange(e.target.value)}>
                                <option value='0'>Select option...</option>
                                <option value='Other'>Other</option>
                                <option value='ProductHunt'>Product Hunt</option>
                                <option value='LinkedIn'>LinkedIn</option>
                                <option value='Facebook'>Facebook</option>
                                <option value='Google'>Google</option>
                                <option value='Email'>Email</option>
                                <option value='Ad'>Ad</option>
                                <option value='Article'>Article</option>
                                <option value='Referral'>Referral</option>
                            </select>
                        </div>

                        {showReferral && (
                            <>
                                <div className='form-group'>
                                    <label>Name and company of peson who referred you</label>
                                    <input type='text' className='input' placeholder='Referrer' value={referrer} onChange={(e) => setReferrer(e.target.value)}/>
                                </div>
                            </>
                        )}

                        <div className='form-group'>
                            <input type='checkbox' id="radioOptIn" name="optIn" checked={optIn} value={optIn} onChange={() => setOptIn(!optIn)} />

                            <label className='createaccount__radio-label label' htmlFor='radioOptIn'>Yes, please keep me updated on news, events and offers
                            </label>
                        </div>

                        <div className='form-group'>
                            <input type='checkbox' id="radioAgree" name="agree" required checked={agree} value={agree} onChange={() => setAgree(!agree)} />

                            <label className='createaccount__radio-label label' htmlFor='radioAgree'>I agree to the <a href="/terms" target="_blank" className='link'>terms</a> and <a href="/privacy" target="_blank" className='link'>privacy</a> policies
                             </label>
                        </div>

                        {hasError === true && (
                            <div className="form-group">
                                <span className={classNames("label", {"label--error":hasError})}>{errorMessage}</span>
                            </div>
                        )}

                        <div className="form__group center" style={{marginTop: "30px"}}>
                            {loading && (
                                <Spinner />
                            )}

                            {!loading && (
                            <button className='btn btn--primary login__btn-create'>
                                Create account
                            </button>
                            )}
                        </div>
                        
                        <div className='login__other-methods'>
                            <a href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86cramxaw66v3z&redirect_uri=https%3A%2F%2Fwww.leadrpro.com%2Flinkedin%2Fredirect&state=foobar&scope=r_liteprofile%20r_emailaddress' target='_blank'>
                            <div className='login__other-methods-linkedin'><FaLinkedin /> <span className='login__other-methods-label'>Sign up using LinkedIn</span></div>
                            </a>
                        </div>

                        <p className="createaccount__text--small center">Already have an account? <Link to="/login" className='link'>Sign in</Link></p>
                </form>
            </div>
        </div>

        <div className="createaccount__right">
            <div className="createaccount__right-header">
                Create your free LeadrPro account and
            </div>

            <div className="createaccount__right-list">
                <img src={list} alt="" />
            </div>

            <div className="createaccount__payment" style={{marginTop: "20px"}}>
                <div style={{fontSize: "12px", marginBottom: "15px"}}>Get paid using your preferred payout method</div>
                    <img src={payment} alt="" />
                </div>
        </div>
        </div>

        {showPrompt && (
            <>
                <div className='overlay'></div>
                <div className='modal modal__prompt'>
                    <h2 className='modal__h2 modal__prompt-h2 createaccount__modal-header'>I'm here to</h2>

                    <div className='createaccount__prompt'>
                        
                    <div className='createaccount__prompt-buyer' onClick={()=> setShowPrompt(false)}>
                        <div className="center">
                                {/* <img src="https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/getpaid_ng-V9AArt.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664425911129" /> */}
                            </div>
                            <div className='createaccount__prompt-icon'><FaSearch /></div>

                            <div className='createaccount__prompt-item-name'><strong>Discover Software</strong></div>

                            <div className='createaccount__prompt-description'>
                                I want to research and discover new software while getting paid for my time.
                            </div>
                        </div>
                        
                        <div className='createaccount__prompt-seller' onClick={() => navigate('/signup/seller')}>
                            <div className="center">
                                {/* <img src="https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/Group_1586_ED1sTvO7i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664426118091" /> */}
                            </div>
                            <div className='createaccount__prompt-icon'><FaShoppingCart /></div>
                            <div className='createaccount__prompt-item-name'><strong>Sell Software</strong></div>
                            <div className='createaccount__prompt-description'>
                                I want to create my company's profile and be added to the marketplace for free.
                            </div>
                        </div>

                        <div className='createaccount__prompt-buyer' onClick={() => navigate('/signup/events')}>
                        <div className="center">
                                {/* <img src="https://ik.imagekit.io/v66nb6oaq/Leadrpro_Pricing/getpaid_ng-V9AArt.png?ik-sdk-version=javascript-1.4.3&updatedAt=1664425911129" /> */}
                            </div>
                            <div className='createaccount__prompt-icon'><FaLocationArrow /></div>

                            <div className='createaccount__prompt-item-name'><strong>Attend Events</strong></div>

                            <div className='createaccount__prompt-description'>
                                I want to register for and attend local events in my area.
                            </div>
                        </div>
                    </div>

                    {/* <p>
                        <i>* You can add </i>
                    </p> */}
                </div>
            </>
        )}
    </div>
    </FadeIn>
        )} 

    {loaded && showVideo === true && (
        <FadeIn delay={250} duration={450}>
        <>
            <div className='createaccount__video' style={{width: "60%", margin: "auto", lineHeight: "1", marginTop: "50px"}}>
                <div style={{fontSize: "40px", fontWeight: "bold", marginBottom: "20px"}}>Watch this quick explainer</div>
                <div style={{fontSize: "40px", fontWeight: "bold", marginBottom: "20px"}}>and earn a $10 sign up <span style={{color: "mediumpurple"}}>bonus</span></div>

                <iframe style={{width: "800px", height: "450px"}} src="https://www.youtube.com/embed/rW2cFBOVVL0?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <div className='center' style={{marginTop: "30px", marginBottom: "30px"}}>
                    <span className='link' onClick={() => handleContinue()}>Continue to Create Your Free Account &gt;&gt;</span>
                </div>
            </div>
        </>
        </FadeIn>
    )}
    </>
    );
}

export default CreateAccount;