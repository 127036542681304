import './AvailableTimes.css';
import {useState,useEffect} from 'react';
import close from '../../assets/design/dashboard/close.png';
import FadeIn from '../../utils/fadein';
import { saveDemoAvailableTimes, getSellerWorkHours, getSellerDemoTimes, refreshToken, hRefreshToken } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../layout/Spinner';
import moment from 'moment-timezone';
import { FaTimes } from 'react-icons/fa';

function AvailableTimes({company_name, demo, demo_id, seller_id, setShowConfirmation, setShowSelectedTime, demoAccepted, showSteps = true}) {
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [sellerWorkHours, setSellerWorkHours] = useState([]);
  const [dates, setDates] = useState([]);
  const [hasWorkHours, setHasWorkHours] = useState(false);
  const [sellerTimes, setSellerTimes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  let dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

  useEffect(() => {
    async function loadSellerWorkHours(){
      let res = await getSellerWorkHours(seller_id);
      if (res.success){
        setSellerWorkHours(res.data);

        if (res.data.length > 0){
          setHasWorkHours(true);
        }
      }
    }

    // async function loadSellerDemoTimes(){
    //   let res = await getSellerDemoTimes(seller_id);
    //   if (res.success){
    //     //setSelectedTimes(res.data);
    //   }
    // }

    loadSellerWorkHours();
    //loadSellerDemoTimes();
  }, []);

  useEffect(() => {
    // load week days into view
    async function loadDates(){
      let today = new Date();
      let _dates = [];
      today.setDate(today.getDate() + 2);

      // load all days for the next 2 weeks
      for (let i = 0; i < 15; i++){
        let _start = new Date(today);
        _start.setDate(today.getDate() + i);

        let _dayofweek = _start.getDay();

        if (sellerWorkHours.length > 0){
          let tmp = sellerWorkHours.filter(item => item.day_of_week === _dayofweek);
          if (tmp.length > 0){
            _dates.push(_start);
          }
        }

        else{
          _dates.push(_start);
        }
      }

      setDates(_dates);
    }

    loadDates();
  }, [sellerWorkHours]);

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  async function handleBackClick(){
    setShowSelectedTime(false);
  }

  async function handleDateSelected(e, item){
    if (e.target.checked){
      let _date = new Date(item);
      _date.setHours(e.target.value);
      _date.setMinutes(0);
      _date.setSeconds(0);

      setSelectedTimes([...selectedTimes, _date]);
    }

    else{
     let _date = new Date(item);
     let _filtered = selectedTimes.filter(tmp => {
      if (tmp.getMonth() === _date.getMonth() && tmp.getDate() === _date.getDate() && tmp.getHours() === parseInt(e.target.value))
        return false;

      return true;
     });

     setSelectedTimes(_filtered);
    }
  }

  async function handleSubmit(){
    // save available times
    if (selectedTimes.length === 0){
      alert('Must select at least 3 times');
    }

    else if (selectedTimes.length < 3){
      alert("Must select at least 3 times");
    }

    else{
      setSubmitting(true);
      // submit times and set demo to 'Approved' for buyer
      let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let res = await saveDemoAvailableTimes(demo_id, selectedTimes, s_timezone);

      if (res.success){
        await hRefreshToken();
        setShowSelectedTime(false);
        setShowConfirmation(true);
        demoAccepted(demo_id);
      }

      else if (res.status === 403){
        await refresh();

        let s_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let res = await saveDemoAvailableTimes(demo_id, selectedTimes, s_timezone);
  
        if (res.success){
          await hRefreshToken();
          setShowSelectedTime(false);
          setShowConfirmation(true);
          demoAccepted(demo_id);
        }
      }

      setSubmitting(false);
    }
  }

  function loadHoursCheckbox(day_of_week, item, index){
    let _now = new Date();
    let seller_timezone = demo.seller_timezone;

    // console.log('seller timezone', seller_timezone);
    // console.log('day of week', day_of_week);

    // default to current timezone if none set
    if (seller_timezone === null){
      seller_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    let seller_offset = getOffset(seller_timezone) * -1;
  //  console.log('seller offset', seller_offset);

   // let seller_offset= getOffset('America/New_York');
    let buyer_offset = _now.getTimezoneOffset();
    //console.log('buyer offset', buyer_offset);
    let diff = (seller_offset - buyer_offset) / 60;

    diff = Math.round(diff);
    // if (diff > 100)
    //   diff = diff - (diff % 100);
   // console.log('diff', diff);

    let el = [];
      if (sellerWorkHours.length > 0){
        let workhour = sellerWorkHours.find(x => x.day_of_week === day_of_week);

        if (workhour){
          let start = Math.floor(workhour.start_time / 100) + diff;
          let end = Math.floor(workhour.end_time / 100) + diff;

          if (start < 0){
            start = start + 24;
            end = end + 24;
          }

          for (let i = start; i < end; i++){
            if (i < 0){
              continue; 
            }

            if (i > 23){
              break;
            }

              el.push(<div key={i}><span><input id={index + '_' + i} type="checkbox" value={i} onChange={(e) => handleDateSelected(e, item)} /></span><label htmlFor={index
               + '_' + i}> {i <= 12 ? (i === 0 ? "12" : i) : i - 12}:00{i >= 12? "pm" : "am"}</label></div>);
          }
        }
      }

      else{
        let start = 9 + diff;
        let end = 17 + diff;

        for (let i = start; i < end; i++){
            el.push(<div><span><input id={index + '_' + i} type="checkbox" value={i} onChange={(e) => handleDateSelected(e, item)} /></span><label htmlFor={index
              + '_' + i}> {i <= 12 ? i : i - 12}:00{i >= 12? "pm" : "am"}</label></div>);
        }
      }

    return (
      <>
        {el}
      </>
    )
  }

return (
  <FadeIn delay={120} duration={300}>
  <div className='availabletimes'>
    <div className='modal__body'>
      {showSteps && (
        <h2>Step 2 of 2</h2>
      )}

      <div className='mar10--top qualifierquestion__label'>Submit your available times</div>

      <div style={{margin: '10px 0px', backgroundColor: '#e1ecff', color: 'black', padding: '5px', borderRadius: '5px'}}>
        <strong>Company timezone: </strong><i>{demo.seller_timezone}</i>
      </div>

      <div>
        Select which time(s) you will be available for a <span className='dashboarddemo__highlight'>30-45 minute</span> demo:
      </div>

      <div style={{marginTop: "5px", fontSize: "small"}}>
        Select a minimum of 3 times.
      </div>

      <div style={{float: 'right', fontSize: 'small'}}>
        Times shown in your current time
      </div>
      <div className='availabletimes__times'>
        {dates.map((item, index) => (
          <>
            <div className='availabletimes__time' key={index}>
              <div className='availabletimes__day'>{dayOfWeek[item.getDay()]} - {item.getMonth() + 1}/{item.getDate()}</div>

              {hasWorkHours && (
                <div className='availabletimes__hours'>
                  {loadHoursCheckbox(item.getDay(), item, index)}
                </div>
              )}

              {!hasWorkHours && (
                <>
                <div className='availabletimes__hours'>
                  {loadHoursCheckbox(item.getDay(), item, index)}
                </div>
                </>
              )}
          </div>
          </>
        ))}
      </div>
    </div>

    {submitting === false && (
    <div className='center' style={{marginTop: "20px"}}>
      <button className='btn btn--confirm' style={{padding:'10px'}} onClick={() => handleSubmit()}>Submit Dates/Times</button>
    </div>
    )}

    {submitting && (
      <div className='center' style={{marginTop: "20px"}}>
        <button className='btn btn--confirm'>
          <Spinner />
        </button>
      </div>
    )}

    <div className='modal__close' onClick={() => handleBackClick()}>
        <FaTimes />
      </div>
  </div>
  </FadeIn>
)
}

export default AvailableTimes;