import './Captcha.css';
import {useState, useEffect } from 'react';
import { createUser } from '../../utils/api';
import React, { useRef } from 'react';
import classNames from "classnames";
import Spinner from '../../components/layout/Spinner';
import Recaptcha from "react-google-recaptcha";
import { searchCompanies, getAvailableSellersAll, saveCompany, getSavedCompanies, createSellerDemo, refreshToken, buyerCreatesSellerDemo, getIndustriesWithSellers, addBuyerRequest, getUserProfile, addToWaitlist, searchAvailableSellers, addEmailCampaign, checkCaptcha } from '../../utils/api';
import { subMilliseconds } from 'date-fns';
import { useEditable } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Captcha = () => {
  const refEmail = React.createRef();
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [isHuman, setIsHuman] = useState(false);
  const captchaRef = useRef(null);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  useEffect(() => {
    async function loadCheckStatus(){
      let _ishuman = getCookie('is_human');
      if (_ishuman === 'true'){
        setIsHuman(true);
        setMessage('Verified ✅');
      }
    }

    loadCheckStatus();
  }, []);

async function handleRecaptcha(e){
  e.preventDefault();
  setSubmitting(true);
  const token = captchaRef.current.getValue();

  if (token == null || token == undefined || token == ''){
    setMessage('Challenge not solved yet.');
    setSubmitting(false);
    return;
  }

  captchaRef.current.reset();

  let res = await checkCaptcha(token);

  if (res.success){
    setCookie('is_human', true, 10);
    setMessage('Success!');
    navigate('/software');
  }

  else{
    setCookie('is_human', false, 10);
    setSubmitting(false);
    setHasError(true);
    setMessage('Uh oh. Something went wrong with your verification. Please try again.');
  }
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

// function setCookie(cname, cvalue, exdays) {
//   const d = new Date();
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//   let expires = 'expires=' + d.toUTCString();
//   let _domain = 'domain=leadrpro.com';
//   document.cookie =
//     cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
// }

  return (
    <div className="captcha">
      <div className='captcha__header'>
        Just a quick security-check! 🤖
      </div>

      <div className='captcha__response'>
        {message}
      </div>

      {isHuman === false && (
      <form onSubmit={(e) => handleRecaptcha(e)}>
        <div className='captcha__widget'>
          <Recaptcha sitekey='6LdRXFcmAAAAAFhP5pzvew6TPahqjbvdVgSjarom' ref={captchaRef} />
        </div>

          <div>
            {submitting === false && (
            <button className='btn btn--primary'>Submit</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </form>
      )}

      {isHuman && (
        <div style={{marginTop: "50px"}}>
          <a href='/software' className='link'>Back to Discovering Software</a>
        </div>
      )}
    </div>
  )
}

export default Captcha;