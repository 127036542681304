import React, { useState } from 'react';
import {
  Box,
  Flex,
  chakra,
  Icon,
  Stack,
  Link,
  Text,
  Badge,
  SimpleGrid,
  useColorModeValue,
  useToken,
  Center,
  Button,
  HStack,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

import TestimonialTest from './TestimonialTest';
import FAQSeller from './FAQSeller';
import PricingSellerHeader from './PricingSellerHeader';

const SellSoftware = () => {
  const topBg = useColorModeValue('gray.800', 'gray.700');
  // const bottomBg = useColorModeValue('gray.200', 'gray.200');
  const bottomBg = useColorModeValue('black', 'gray.200');
  const [bottomBgHex] = useToken('colors', [bottomBg]);
  const [frequency, setFrequency] = useState('cost per demo');

  const Feature = (props) => {
    return (
      <Flex align='center'>
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color='brand.500'
            _dark={{
              color: 'brand.300',
            }}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'></path>
          </Icon>
        </Flex>
        <Box ml={4}>
          <chakra.span
            mt={2}
            color='gray.700'
            _dark={{
              color: 'gray.400',
            }}>
            {props.children}
          </chakra.span>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Helmet>
        <title>Business Intelligence Platform | Pricing - LeadrPro</title>
      </Helmet>
      <Flex
        boxSize='full'
        // bg="#F9FAFB"
        bg='white'
        _dark={{
          bg: 'gray.600',
        }}
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box
          mx='auto'
          textAlign={{
            base: 'left',
            md: 'center',
          }}
          rounded='md'
          shadow='base'
          w='full'
          bg={bottomBg}>
          <Box
            pt={20}
            rounded='md'
            // bg={topBg}
            // bg="purple.500"
            bg='black'>
            <PricingSellerHeader />
            <Box bg='gray.100' pb={16}>
              <chakra.h2 fontSize={'3xl'} pb={4}>
                Have any questions? We're here to help!
              </chakra.h2>
              <Button
                as='a'
                href='https://www.leadrpro.com/signup/seller'
                display='inline-flex'
                alignItems='center'
                justifyContent='center'
                w={{
                  base: 'full',
                  sm: 'auto',
                }}
                mb={{
                  base: 2,
                  sm: 0,
                }}
                bg='purple.500'
                color='white'
                _hover={{
                  bg: 'purple.600',
                  color: 'white',
                  transform: 'scale(1.05)',
                }}
                size='lg'
                cursor='pointer'>
                Contact sales
                <Icon
                  boxSize={4}
                  ml={1}
                  viewBox='0 0 20 20'
                  fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z'
                    clipRule='evenodd'
                  />
                </Icon>
              </Button>
            </Box>
            <Box w='full' px={[10, , 4]} mx='auto'>
              <chakra.p
                fontSize={['lg', , '3xl']}
                color='gray.200'
                textTransform='uppercase'
                fontWeight='semibold'
                mt={16}>
                ...once you find the right candidate
              </chakra.p>
              <Text
                mb={2}
                fontSize={['2xl', , '7xl']}
                fontWeight='bold'
                lineHeight='tight'
                color='white'>
                Simple{' '}
                <chakra.span color='purple.500'>transparent </chakra.span>
                pricing
              </Text>

              <chakra.p mb={6} fontSize={['lg', , 'xl']} color='gray.200'>
                Find the right pricing plan for your business - no matter what
                your needs.
              </chakra.p>
            </Box>
            <Box
              bg={`linear-gradient(180deg, transparent 70%, ${bottomBgHex} 30%)`}>
              <SimpleGrid
                columns={[1, , , 2]}
                gap='24px'
                rounded='md'
                mx={[10, , 24]}
                textAlign='left'>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Pay per demo
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $500
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo{' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        Pay per demo
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={'100px'} spacing={4}>
                        <Feature>Calendar Integration</Feature>
                        <Feature>Video Chat</Feature>
                        <Feature>Instant Messaging</Feature>
                        <Feature>Unlimited Qualifier Questions</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Premium Package
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $400
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo ($2,000 total){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        5 Demo Package
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={5} spacing={4}>
                        <Feature>
                          All Features From Pay Per Demo Included
                        </Feature>
                        <Feature>Rollover Demos</Feature>
                        <Feature>Team Management</Feature>
                        <Feature>Real Time Notifications</Feature>
                        <Feature>Only Pay For Approved Demos</Feature>
                        <Feature>Custom Onboarding</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
              </SimpleGrid>
              <SimpleGrid
                pb='100px'
                columns={[1, , , 2]}
                gap='24px'
                rounded='md'
                mx={[10, , 24]}
                textAlign='left'
                mt={5}>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Growth
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $300
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /demo ($3,000 total){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        10 Demo Package
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={'100px'} spacing={4}>
                        <Feature>All Features From Premium Included</Feature>
                        <Feature>Prospect Search</Feature>
                        <Feature>Video Recording Integration</Feature>
                        <Feature>Automatic Candidate Filtering</Feature>
                        <Feature>Dedicated Customer Success Manager</Feature>
                      </Stack>
                      <a
                        href='https://www.leadrpro.com/signup/seller'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          color='white'
                          bg='purple.500'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  bg='white'
                  _dark={{
                    bg: 'gray.800',
                  }}
                  pt={10}
                  shadow='lg'
                  rounded='md'>
                  <Flex direction='column'>
                    <Box px={10} pb={5}>
                      <Badge
                        mb={1}
                        fontSize='xs'
                        letterSpacing='wide'
                        colorScheme='brand'
                        fontWeight='medium'
                        rounded='full'
                        px={4}
                        py={1}>
                        Enterprise / Unicorn
                      </Badge>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        $250
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          / demo ($5,000 total){' '}
                        </chakra.span>
                      </Text>
                      <chakra.p
                        mb={6}
                        fontSize='md'
                        color='gray.500'
                        _dark={{
                          color: 'gray.500',
                        }}>
                        20 Demo Package
                      </chakra.p>
                    </Box>
                    <Flex
                      px={10}
                      pt={5}
                      pb={10}
                      direction='column'
                      bg='gray.50'
                      _dark={{
                        bg: 'gray.900',
                      }}
                      roundedBottom='md'>
                      <Stack mb={5} spacing={4}>
                        <Feature>All Features From Growth Included</Feature>
                        <Feature>
                          Deliver Booked Demos Directly To Your Dashboard
                        </Feature>
                      </Stack>
                      <a
                        href='https://calendly.com/leadrpro/leadrpro-demo'
                        target={'_blank'}>
                        <Link
                          w='full'
                          display='inline-flex'
                          alignItems='center'
                          justifyContent='center'
                          px={5}
                          py={3}
                          mt={'200px'}
                          border='solid transparent'
                          fontWeight='semibold'
                          rounded='md'
                          shadow='md'
                          bg='purple.500'
                          color='white'
                          _hover={{
                            bg: 'purple.600',
                            color: 'white',
                            transform: 'scale(1.05)',
                          }}>
                          Sign me up!
                        </Link>
                      </a>
                    </Flex>
                  </Flex>
                </Box>
              </SimpleGrid>

              {/* <Box mb={20}>
                <Flex
                  mb={12}
                  mx={[10, , 24]}
                  p={10}
                  mt={5}
                  rounded='md'
                  bg='#F9FAFB'
                  _dark={{
                    bg: 'gray.700',
                  }}
                  align='center'
                  direction={['column', , 'row']}>
                  <Stack flex='0.8' pr={10}>
                    <Badge
                      mb={1}
                      fontSize='xs'
                      letterSpacing='wide'
                      colorScheme='purple'
                      fontWeight='medium'
                      rounded='full'
                      px={4}
                      py={1}
                      w='fit-content'>
                      Limited Time Offer
                    </Badge>
                    <Text textAlign='left'>
                      Get your first two demos{' '}
                      <chakra.span fontWeight='bold'>50%</chakra.span> off! Sign
                      up to LeadrPro today and get your first two demos at half
                      price! We guarantee you'll love it so much you'll wonder
                      how you ever did sales without us!
                    </Text>
                  </Stack>
                  <Link
                    as={'a'}
                    href='https://calendly.com/leadrpro/leadrpro-demo'
                    target={'_blank'}
                    w={['full', , 'auto']}
                    display='inline-flex'
                    alignItems='center'
                    justifyContent='center'
                    px={5}
                    py={3}
                    mt={[5, , 0]}
                    border='solid transparent'
                    fontWeight='semibold'
                    rounded='md'
                    shadow='md'
                    h='fit-content'
                    color='white'
                    bg='purple.500'
                    _hover={{
                      bg: 'purple.600',
                      color: 'white',
                      transform: 'scale(1.05)',
                    }}
                    flex='0.2'>
                    Get Discount Now!
                  </Link>
                </Flex>
              </Box> */}

              <TestimonialTest />
            </Box>

            <Box w='full' px={[10, , 4]} mx='auto' textAlign='center' py={12}>
              <Text
                mb={2}
                fontSize='5xl'
                fontWeight='bold'
                lineHeight='tight'
                color='purple.500'>
                Compare LeadrPro Plans
              </Text>
              <Center>
                <chakra.p
                  mb={6}
                  fontSize={['lg', , 'xl']}
                  color='gray.300'
                  _dark={{
                    color: 'gray.400',
                  }}
                  maxW='2xl'>
                  Compare the average number of demos booked and{' '}
                  <chakra.span fontStyle={'italic'}>
                    {' '}
                    save more per demo!
                  </chakra.span>
                </chakra.p>
              </Center>

              <Flex justify='center' mx={['auto', 0]} mb={-2}>
                <Stack
                  direction='row'
                  justify='space-between'
                  p='2'
                  textAlign='center'
                  rounded='md'
                  bg='purple.500'>
                  <Button
                    colorScheme='brand'
                    variant={frequency === 'monthly demos' ? 'solid' : 'ghost'}
                    onClick={() => setFrequency('monthly demos')}
                    px={6}
                    border='1px solid white'
                    color='white'
                    _hover={{
                      bg: 'white',
                      color: 'black',
                      border: '1.5px solid white',
                    }}>
                    Avg Number Of Demos
                  </Button>
                  <Button
                    colorScheme='brand'
                    variant={frequency === 'cost per demo' ? 'solid' : 'ghost'}
                    onClick={() => setFrequency('cost per demo')}
                    px={6}
                    color='white'
                    border='1px solid white'
                    _hover={{
                      bg: 'white',
                      color: 'black',
                      border: '1.5px solid white',
                    }}>
                    Cost Per Demo
                  </Button>
                </Stack>
              </Flex>
              <Center>
                <Flex direction='row' px={10} pb={10}>
                  <Box
                    rounded={['none', 'lg']}
                    shadow={['none', 'md']}
                    bg='white'
                    _dark={{
                      bg: 'gray.800',
                    }}
                    px={4}
                    mx={8}
                    mt={10}
                    w='lg'>
                    <Flex
                      direction='column'
                      justify='space-between'
                      p='6'
                      color='gray.200'
                      _dark={{
                        color: 'gray.600',
                      }}>
                      <chakra.p
                        mb={1}
                        fontSize='lg'
                        fontWeight='semibold'
                        color='gray.700'
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Pay Per Demo
                      </chakra.p>

                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        {frequency === 'monthly demos' ? '' : '$'}

                        {frequency === 'monthly demos' ? 10 : 500}
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /{frequency}
                        </chakra.span>
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    rounded={['none', 'lg']}
                    shadow={['none', 'md']}
                    bg='white'
                    _dark={{
                      bg: 'gray.800',
                    }}
                    w='lg'
                    px={4}
                    mt={10}
                    mx={8}>
                    <Flex
                      direction='column'
                      justify='space-between'
                      p='6'
                      color='gray.200'
                      _dark={{
                        color: 'gray.600',
                      }}>
                      <chakra.p
                        mb={1}
                        fontSize='lg'
                        fontWeight='semibold'
                        color='gray.700'
                        _dark={{
                          color: 'gray.400',
                        }}>
                        Bulk Package
                      </chakra.p>
                      <Text
                        mb={2}
                        fontSize='5xl'
                        fontWeight={['bold', 'extrabold']}
                        color='gray.900'
                        _dark={{
                          color: 'gray.50',
                        }}
                        lineHeight='tight'>
                        {frequency === 'monthly demos' ? '' : '$'}
                        {frequency === 'monthly demos' ? 40 : 300}
                        <chakra.span
                          fontSize='2xl'
                          fontWeight='medium'
                          color='gray.600'
                          _dark={{
                            color: 'gray.400',
                          }}>
                          {' '}
                          /{frequency}
                        </chakra.span>
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Center>
            </Box>
          </Box>
        </Box>
      </Flex>
      <FAQSeller />
    </>
  );
};

export default SellSoftware;

//todo TO MAKE LETS GET STARTED BUTTON FIXED AT BOTTOM ==> ADD FIXED HEIGHT TO BULLET POINT FEATURE LIST, EYEBALL THEN SET
