import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import congrats from '../../assets/design/dashboard/congrats.png';
import starNotFilled from "../../assets/design/review/star-not-filledv2.svg";
import starFilled from "../../assets/design/review/star-filled.svg";
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { getCurrentUser, refresh } from '../../services/auth.service';
import {acceptDemoSeller,getSellerDemos,getSellerObjections,addSellerFeedback,getSellerDeclinedReasons, getSellerFeedbackCriteria, addSellerFeedbackCriteria, getDemoQualifierAnswers, getTeamDemosIncomplete, refreshToken} from '../../utils/api';
import moment from 'moment-timezone';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { FaEllipsisH, FaEllipsisV } from 'react-icons/fa';
import LeadCard from '../../components/qualifier/LeadCard';

const SellerDemosIncomplete = ({ status, setIncompleteDemosCount, showOnLoad = false }) => {
  const imagePath = process.env.REACT_APP_IMAGE_PATH;
  const [role, setRole] = useState('');
  const [sellerDemos, setSellerDemos] = useState([]);
  const [sellerObjections, setSellerObjections] = useState([]);
  const [feedbackStatus, setFeedbackStatus] = useState('initial');
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [estimatedValue, setEstimatedValue] = useState('');
  const [currentDemo, setCurrentDemo] = useState({});
  const [objectionReason, setObjectionReason] = useState('');
  const [selectedObjectionReason, setSelectedObjectionReason] = useState(null);
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);
  const [sellerFeedbackCriteria, setSellerFeedbackCriteria] = useState([]);
  const [ratings, setRatings] = useState({});
  const [feedbackRatings, setFeedbackRatings] = useState([]);
  const [currentCriteria, setCurrentCriteria] = useState(0);
  const [movingToProposal, setMovingToProposal] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const currentUser = getCurrentUser();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';
    document.cookie =
      cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  async function handleToggleMenu(item){
    setCurrentDemo(item);
    setShowSubMenu(!showSubMenu);
  }

  useEffect(async () => {
    async function fetchSellerObjections() {
      let data = await getSellerObjections();
      if (data.success) {
        setSellerObjections(data.data);
      }
    }

    await fetchSellerObjections();
  }, [currentDemo]);

  // useEffect(() => {
  //   if (demos.length > 0){
  //     setShowNotification(true);
  //   }
  // }, []);

  useEffect(() => {
    async function loadTeamDemos(){
      let res = await getTeamDemosIncomplete();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);

        if (res.data.length > 0){
          //setShowNotification(true);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await getTeamDemosIncomplete();

          if (res.success){
            setDemos(res.data);

            if (res.data.length > 0){
              //setShowNotification(true);
            }
          }

          setLoaded(true);
        }
      }
    }

    loadTeamDemos();
  }, []);

  useEffect(() => {
    async function fetchSellerDeclinedReasons() {
      let data = await getSellerDeclinedReasons();
      if (data.success) {
        setSellerDeclinedReasons(data.data);
      }
    }

    async function loadSellerFeedbackCriteria(){
      let res = await getSellerFeedbackCriteria();

      if (res.success){
        setSellerFeedbackCriteria(res.data);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await getSellerFeedbackCriteria();

          if (res.success){
            setSellerFeedbackCriteria(res.data);
          }
        }
      }
    }

    fetchSellerDeclinedReasons();
    loadSellerFeedbackCriteria();
  }, []);

  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];

  function formatDate(date) {
    let d = new Date(date);
    let weekDay = d.getDay();
    let day = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    return weekDays[weekDay] + ', ' + months[month] + ' ' + day + ' ' + year;
  }

  function convertDateTime(_date) {
    let date = new Date(_date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (
      date.getMonth() +
      1 +
      '/' +
      date.getDate() +
      '/' +
      date.getFullYear() +
      '  ' +
      strTime
    );
  }

  async function closingClickFeedback() {
      setFeedbackStatus('initial');
      setShowFeedbackPopup(!showFeedbackPopup);
    }

  async function handleYesClick(){
    let res = await addSellerFeedback(currentDemo.demo_id, true, estimatedValue);
    if(res.success){
      setFeedbackStatus('confirm');
      let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);
      let _feedback = {moving_to_proposal: false, reason: ""};
      let _arr = [];
      _arr.push(_feedback);
      _obj.feedback_count = parseInt(_obj.feedback_count) + 1;
      _obj.feedback = _arr;
      _filter.push(_obj);
      setDemos(_filter);
    }
  }

  function leaveFeedbackClickHandle(id, demo) {
    setShowFeedbackPopup(true);
    setCurrentDemo(demo);
  }

  async function handleSubmitFeedback(){
    setSubmitting(true);
    let id = currentDemo.demo_id;

    if (feedbackRatings.length === 0){
      alert("Please provide feedback!");
      return;
    }

    let parsedValue = parseInt(estimatedValue);

     let res = await addSellerFeedback(id, movingToProposal, parsedValue, selectedObjectionReason, objectionReason);

    if (res.success) {
      let seller_feedback_id = res.data;

      for (let i = 0; i < feedbackRatings.length; i++){
        console.log(feedbackRatings[i]);
        await addSellerFeedbackCriteria(seller_feedback_id, feedbackRatings[i].seller_feedback_criteria_id, feedbackRatings[i].value + 1);
      }

      let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
      let _obj = Object.assign({}, currentDemo);
      let _feedback = {moving_to_proposal: false, reason: ''};
      let _arr = [];
      _arr.push(_feedback);
      _obj.feedback_count = parseInt(_obj.feedback_count) + 1;
      _obj.feedback = _arr;
      _filter.push(_obj);
      setDemos(_filter);

      setShowFeedbackPopup(!showFeedbackPopup);
      //setFeedbackStatus('initial');
      setShowCompleted(true);
      setSubmitting(false);

      let filter = demos.filter(x => x.demo_id !== id);
      setDemos(filter);
      setEstimatedValue('');
      setSelectedObjectionReason(null);
      setObjectionReason('');
      setFeedbackRatings([]);
    }
  }

  function handleFeedbackClick(seller_feedback_criteria_id, i){
    //setRatings(...rating)
    console.log(seller_feedback_criteria_id, i);

    setCurrentCriteria(seller_feedback_criteria_id);

    // store object into array
    let _filter = feedbackRatings.filter(item => item.seller_feedback_criteria_id === seller_feedback_criteria_id);

    if (_filter.length > 0){
      // array has rating
      let _filter = feedbackRatings.filter(item => item.seller_feedback_criteria_id !== seller_feedback_criteria_id);
      let _obj = {seller_feedback_criteria_id, value: i};
      _filter.push(_obj);
      setFeedbackRatings(_filter);
    }

    else{
      // array does not have the feedback just yet
      let obj = {seller_feedback_criteria_id, value: i};
      setFeedbackRatings([...feedbackRatings, obj]);
    }
  }

  function renderStars(seller_feedback_criteria_id){
    let arr = [];
    let _filter = feedbackRatings.filter(item => item.seller_feedback_criteria_id === seller_feedback_criteria_id);
    let _value = -1;

    if (_filter.length > 0){
     _value = _filter[0].value;
    }

    for (let i = 0; i < 5; i++){
      let index = ratings[seller_feedback_criteria_id];
      
      if (i <= _value){
        arr.push(<span onClick={() => handleFeedbackClick(seller_feedback_criteria_id, i) }>
          <FaStar />
        </span>);
      }

      else{
        arr.push(<span onClick={() => handleFeedbackClick(seller_feedback_criteria_id, i) }>
          <FaRegStar />
        </span>);
      }
    }

    return (
      <div className='dashboarddemo__star'>
        {arr}
      </div>
    )
  }

  async function handleFeedbackClose(){
    //window.location = window.location;
  }

  useEffect(() => {
    renderStars(currentCriteria);
  }, [ratings, currentCriteria]);

    async function submitSellerFeedback(e, id) {
      e.preventDefault();
  
      let objectionReason = e.target.elements.objectionReason.value;
      let objectionReasonText = e.target.elements.objectionReason.options[e.target.elements.objectionReason.selectedIndex].text;
      let objectionDescription = e.target.elements.objectionDescription.value;

      if (objectionDescription.length === 0){
        alert('Please provide feedback to submit');
        return;
      }
    
      let res = await addSellerFeedback(id, false, estimatedValue, objectionReason, objectionDescription);
  
      if (res.success) {
        setShowFeedbackPopup(!showFeedbackPopup);
        setFeedbackStatus('initial');

        let _filter = demos.filter(tmp => tmp.demo_id !== currentDemo.demo_id);
        let _obj = Object.assign({}, currentDemo);
        let _feedback = {moving_to_proposal: false, reason: objectionReasonText};
        let _arr = [];
        _arr.push(_feedback);
        _obj.feedback_count = parseInt(_obj.feedback_count) + 1;
        _obj.feedback = _arr;
        _filter.push(_obj);
        setDemos(_filter);
      } else {

      }
    }

    async function handleAnswersClick(item){
      let res = await getDemoQualifierAnswers(item.demo_id);
  
      if (res.success){
        let new_item = {...item, qualifier_answers: res.data};
        setCurrentDemo(new_item);
        setShowQualifierAnswers(true);
        setShowSubMenu(false);
      }
  
      else if (res.status === 403){
        await refresh();
        let res = await getDemoQualifierAnswers(item.demo_id);
  
        if (res.success){
          let new_item = {...item, qualifier_answers: res.data};
          setCurrentDemo(new_item);
          setShowQualifierAnswers(true);
          setShowSubMenu(false);
        }
      }
    }

  return (
    <div>
      {showNotification && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowNotification(false)}>
              <img src={close} />
            </div>

            <div className='modal__body'>
            <h2 className='modal__h2'>
              Overdue Feedback!
            </h2>

              <p style={{marginTop: "20px"}}>
              Please provide post-demo feedback to the following completed demos. They help to improve the quality of your future matches!
              </p>
            </div>
          </div>
        </>
      )}
      {showFeedbackPopup && (
        <>
          <div className='overlay'></div>
          <div className='modal center dashboarddemo__feedback-modal' style={{textAlign: "left"}}>
          <div className='modal__close' onClick={() => {setShowFeedbackPopup(false);
          setFeedbackStatus('initial')}}><img src={close} /></div>

          <h2>How did your demo go</h2>

          <p style={{fontSize: "14px", marginBottom: "15px"}}>* Your feedback isn't shared with the other party, and is used to improve your future matches!</p>

          <div style={{display: "flex", marginBottom: "20px"}}>
            <div className='dashboard__feedback-company-logo'>
              <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo } />
            </div>

            <div style={{marginLeft: "20px"}}>
              <div style={{fontSize: "20px", fontWeight: "bold"}}>{currentDemo.company_name}</div>

              <div className='dashboarddemo__date center'>
                <span>Demo on: </span>
                      {currentDemo.reschedule_date_time && (
                        <>
                          <div>
                          {weekDays[new Date(currentDemo.reschedule_date_time).getDay()]}{' '}
                            {new Date(currentDemo.reschedule_date_time).toLocaleDateString()}
                            <br/>
                            at {new Date(currentDemo.reschedule_date_time).toLocaleTimeString()}
                          </div>

                          <div className='dashboarddemo__date-timezone'>
                              {currentDemo.timezone}
                          </div>
                        </>
                      )}

                      {!currentDemo.reschedule_date_time && (
                        <>
                          {currentDemo.scheduled_date_time && (
                            <>
                            {weekDays[new Date(currentDemo.scheduled_date_time).getDay()]}{' '}
                            {new Date(currentDemo.scheduled_date_time).toLocaleDateString()}
                            <br/>
                            at {new Date(currentDemo.scheduled_date_time).toLocaleTimeString()}
                            </>
                          )}

                          {!currentDemo.scheduled_date_time && (
                            <>
                              <span className=''>
                                {formatDate(currentDemo.scheduled_date)}
                              </span>
                            </>
                          )}

                          <div className='dashboarddemo__date-timezone'>
                            {currentDemo.timezone}
                          </div>
                        </>
                      )}
                    </div>
            </div>
          </div>

            {feedbackStatus === 'initial' && (
              <>
                <div className='dashboarddemo__feedback-modal-section' style={{margin: "auto", marginBottom: "30px"}}>
                  {sellerFeedbackCriteria.map(item => (
                    <>
                      <div className='dashboarddemo__feedback-criteria' style={{display:"flex", justifyContent: "space-between"}}>
                        <span className=''>
                          {item.display_name}
                        </span>

                        {renderStars(item.seller_feedback_criteria_id)}
                      </div>
                    </>
                  ))}
                </div>

                <div className='dashboarddemo__feedback-modal-section' style={{display:"flex", justifyContent: "space-between"}}>
                  <div>
                    <span className='dashboard__feedback-q'>Q: </span>Do you think this person is going to move forward to the proposal stage?
                  </div>

                  <div>
                    <input type="radio" id="radio_yes" name="sellerfeedback" onChange={() => setMovingToProposal(true)}></input>
                    <label htmlFor='radio_yes'>Yes</label>
                  </div>

                  <div>
                    <input type="radio" id="radio_no" name="sellerfeedback" onChange={() => setMovingToProposal(false)}></input>
                    <label htmlFor='radio_no'>No</label>
                  </div>
                </div>

                {movingToProposal === false && (
                  <FadeIn delay={200} duration={200}>
                  <div className='dashboarddemo__feedback-modal-section'>
                    <label>
                        Why do you think the prospect won't move
                        forward?
                    </label>

                    <select
                      className='input_box dropdown-font'
                      type='text'
                      id='objectionReason'
                      onChange={(e) =>
                        setSelectedObjectionReason(e.target.value)
                      }>
                        <option>Select reason...</option>
                      {sellerObjections.map((item) => (
                        <option
                          value={item.seller_objection_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                    </select>
                    </div>
                    </FadeIn>
                  )}

                <div className='dashboarddemo__feedback-modal-section'>
                  <div>
                  <span className='dashboard__feedback-q'>Q: </span>What do you think the estimated ACV would
                      have been? (Type '0' if unsure)
                    </div>

                  <input type='text' autoComplete='off' value={estimatedValue} onChange={(event) => {
                    if (
                      !Number.isInteger(
                        Number(event.target.value)
                      )
                    ) {
                      alert('Must be a numeric value');
                    } else {
                      setEstimatedValue(event.target.value);
                    }
                  }}
                  className='input'
                  id='estimatedValue'
                  placeholder='Estimated ACV in USD' />
                </div>

                <div className='form-group'>
                    <label className='feedbackpopup__text--small'>
                    <span className='dashboard__feedback-q'>Q: </span>Please let us know your thoughts on how the
                      call went
                    </label>

                    <textarea autoComplete='off' value={objectionReason}
                      onChange={(event) =>
                        setObjectionReason(event.target.value)
                      }
                      className='input textarea'
                      id='objectionDescription'
                      placeholder='Your feedback...' />
                  </div>

                <div className='dashboarddemo__feedback-modal-btns center'>
                  {submitting === false && (
                  <button className='btn btn--primary' onClick={() => handleSubmitFeedback()}>Submit feedback</button>
                  )}

                  {submitting && (
                    <span>
                      <Spinner />
                    </span>
                  )}
                </div>
              </>
            )}

          {feedbackStatus === 'yes' && (
            <>
              <img src={close} className='modal__close' onClick={() => closingClickFeedback() } />

              <div>
                <img src={congrats} />
              </div>

              <div className='modal__header--large'>
                That's great!
              </div>

              <div className='modal__body'>
                What do you think the estimated ACV would be?
              </div>

              <div className='mar10'>
                <button className='btn btn--primary btn--dark' onClick={() => handleYesClick()}>
                  Submit
                </button>
              </div>
            </>
          )}

          {feedbackStatus === 'confirm' && (
            <>
              <div className='modal__header'>
                Success!
              </div>

              <div>
                Your feedback has now been recorded and this demo has been updated to <span className='dashboarddemo__highlight'>completed.</span>
              </div>

              <div  style={{marginTop:"20px"}}>
                <button className='btn btn--dark' onClick={() => closingClickFeedback()}>Close</button>
              </div>
            </>
          )}

            {feedbackStatus === 'no' && (
              <>
                <div className='feedbackpopup__text--large-no'>
                  We're sorry to hear that!
                </div>

                <form onSubmit={(e) => submitSellerFeedback(e, currentDemo.demo_id)}>
                  <div>

                    <div className='form__group'>
                    <div>
                      What do you think the estimated ACV would
                      have been?
                    </div>

                    <input type='text' autoComplete='off' value={estimatedValue}
                      onChange={(event) => {
                        if (
                          !Number.isInteger(
                            Number(event.target.value)
                          )
                        ) {
                          alert('Must be a numeric value');
                        } else {
                          setEstimatedValue(event.target.value);
                        }
                      }}
                      className='sellerdashboard__feedback-modal-acv-input'
                      id='estimatedValue'
                      placeholder='Estimated ACV in USD' />
                  </div>
                  </div>

                  <div>
                    <button className='btn btn--primary btn--dark'>
                      Submit
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </>
      )}

      {showCompleted && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
          <div>
                Your feedback has now been recorded and this demo has been updated to <span className='dashboarddemo__highlight'>completed.</span>
              </div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => setShowCompleted(false)}>Close</button>
            </div>
          </div>
        </>
      )}

      {loaded === false && (
        <Spinner />
      )}

      {demos.filter(item => item.demo_status === 'Completed').length === 0 && loaded === true && (
        <div className='dashboarddemo__no-results'>
          <p>
            Nothing to do here for now.
          </p>
        </div>
      )}

      {demos.length > 0 && (
        <div className='dashboard__table-wrapper'>
      <table className='table dashboard__table'>
        <tr>
          <th></th>
          <th>Company</th>
          <th>Scheduled on</th>
          <th>Assigned</th>
          <th>Feedback</th>
          <th></th>
        </tr>

      {demos.filter((demo) =>
              demo.demo_status === 'Completed'
          )
          .map((item, index) => (
            <tr key={index}>
                  <td>
                    <div className='dashboard__table-company-logo'>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo } />
                    </div>
                  </td>

                  <td>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </span>
                  </div>
                </td>

                <td>
                      <div className='dashboarddemo__company-info-items'>
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          <span className='dashboard__table-date'>
                       
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          </span>
                        </span>
                      </div>
                      </div>
                    </td>

                    <td className='dashboarddemo__date center'>
                    <div className='center'>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                    </td>

                  <td className='dashboarddemo__actions'>
                    <button className='btn' onClick={() => leaveFeedbackClickHandle(item.demo_id, item)}>
                      Submit Feedback
                    </button>
                  </td>

                  <td>
                    <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item' onClick={() => handleAnswersClick(item)}>Buyer Details</div>

                      {item.buyer_feedback_count > 0 && loaded && (
                      <div className='dashboard__submenu-item'>Open Feedback</div>
                      )}

                      {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Open Chat</div>
                      )}
                    </div>
                    </FadeIn>
                    )}
                  </td>
            </tr>
          ))}
          </table>
          </div>
      )}

          {showQualifierAnswers && (
        <>
          <div className='overlay'></div>
          <LeadCard currentDemo={currentDemo} setShowQualifierAnswers={setShowQualifierAnswers} show_buttons={false} />
        </>
      )}
          </div>
  )
}

  export default  SellerDemosIncomplete;