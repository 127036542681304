import './Invitations.css';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import { getInvitations, sendInvites, refreshToken } from '../../utils/api';

const Invitations = ({userType}) => {
  const [invitations, setInvitations] = useState([]);
  const [email, setEmail] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  async function loadInvitations(){
    let res = await getInvitations();

    if (res.success){
      setInvitations(res.data);
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getInvitations();

        if (res.success){
          setInvitations(res.data);
        }
      }
    }
  }

  useEffect(() => {
    loadInvitations();
  }, []);

  async function handleSend(){
    if (email.length === 0){
      alert('Must enter a valid email');
      return;
    }

    let arr = [];
    arr.push(email);
    let res = await sendInvites(arr);

    if (res.success){
      await loadInvitations();
      setEmail('');

      setShowConfirm(true);
    }
  }

  return (
    <>
    <div className="">
      <Link className='link' to='/account/home'>Back</Link>
      <div className="account__header">Refer a friend</div>

      <div className='invitations__copy'>
        Refer a friend to sign up today and get a $5 bonus directly into your account.
      </div>

      <div style={{display: "flex"}}>
        <input type='email' className='input' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email address' />
        <button className='btn btn--primary' style={{marginLeft: "10px"}} onClick={() => handleSend()}>Invite</button>
      </div>

      <div className='accountinfo__form notifications__form'>
        {invitations.length > 0 && (
          <div>
          <table className='table invitations__table'>
            <tr>
              <th>Email</th>
              <th>Status</th>
            </tr>
          {invitations.map(item => (
            <tr>
              <td>{item.email}</td>
              <td><span className={'invitations__status invitations__' + item.status.toLowerCase()}>{item.status}</span></td>
            </tr>
          ))}
          </table>
        </div>
        )}
      </div>
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
          <div className='modal__close'>
            <img src={close} onClick={() => setShowConfirm(false)} />
          </div>

          <div className=''>
            <div className="center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{height: "30px"}}><path d="M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z"/></svg>
            </div>

            <p>
              Your invitation has been sent! Once the other party has finished signing up, you will automatically see the bonus in your next payout!
            </p>
          </div>
        </div>
      </>
    )}
    </>
)
}

  export default Invitations;