import './ReviewLanding.css';
import searchicon from '../../../assets/design/homepage/search.png';
import { useState, useEffect } from 'react';
import { getCompaniesReview, searchCategories } from '../../../utils/api';
import { Helmet } from 'react-helmet';

function ReviewLanding({ loggedIn, setCompanySelected, setStage }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [query, setQuery] = useState('');
  const [end, setEnd] = useState(false);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(0);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const queryParams = new URLSearchParams(window.location.search);
  const companyParam = queryParams.get('company');

  useEffect(() => {
    async function loadCompanies() {
      if (companiesPage === 1) {
        try {
          setNoResultsFound(false);
          setLoading(true);
          setPageLoading(true);
          setEnd(false);

          const { data } = await getCompaniesReview(
            companiesPage,
            query,
            categorySelected
          );

          if (!data || data.length === 0) {
            setCompanies([]);
            return setNoResultsFound(true);
          }

          setCompanies(data);
        } catch (err) {
        } finally {
          setPageLoading(false);
          setLoading(false);
        }
      } else {
        try {
          setNoResultsFound(false);
          setLoading(true);
          setPageLoading(true);
          setEnd(false);

          const { data } = await getCompaniesReview(
            companiesPage,
            query,
            categorySelected
          );

          if (!data || data.length === 0) {
            if (companies.length > 0) {
              return setEnd(true);
            }

            setCompanies([]);
            return setNoResultsFound(true);
          }
          setCompanies((prev) => [...prev, ...data]);
        } catch (err) {
        } finally {
          setPageLoading(false);
          setLoading(false);
        }
      }
    }

    loadCompanies();
  }, [companiesPage, query, categorySelected]);

  useEffect(() => {
    if (companyParam) {
      async function loadCompany() {
        let input = document.getElementById('review__company-search');
        input.value = companyParam;
        const { data } = await getCompaniesReview(
          companiesPage,
          companyParam,
          categorySelected
        );
        setCompanies(data);
      }

      loadCompany();
    }
  }, []);

  //*Sets the state to whatever company card the user selects
  const handleClick = (company) => {
    setCompanySelected(company);

    if (loggedIn) {
      return setStage((value) => value + 2);
    }

    setStage((value) => value + 1);
  };

  //* Handles the change in the input for the company searched
  const handleChange = async ({ target }) => {
    setCompaniesPage(1);
    setNoResultsFound(false);

    if (target.value === '') {
      setCompaniesPage(1);
      return setQuery('');
    }

    setQuery(target.value);
  };

  //* Handles the change in the category change
  const handleCategoryChange = async ({ target }) => {
    setNoResultsFound(false);

    if (target.value === '') {
      setCategorySelected(0);
      setCategories([]);
    }

    const { data } = await searchCategories(target.value);
    setCategories(data);
  };

  //* Handles whenever a user clicks on a category
  const handleCategoryClick = (category_id, name) => {
    setCompaniesPage(1);
    setCategorySelected(category_id);

    let categoryInput = document.querySelector('#category');
    categoryInput.value = name;
    setCategories([]);
  };

  const renderCompanies = (company, index) => {
    return (
      <span
        key={index}
        className='review__company-card-anchor'
        onClick={() => handleClick(company)}>
        <div className='review__company-card'>
          {company.logo && (
            <div>
              <img
                src={
                  imageUrl +
                  'tr:di-@@companylogos@@default-buyer.png/' +
                  company.logo
                }
                alt=''
              />
            </div>
          )}

          {!company.logo && (
            <span className='review__company-initial'>{company.name[0]}</span>
          )}

          <p>{company.name}</p>
        </div>
      </span>
    );
  };

  return (
    <>
      <Helmet>
        <title>Best Enterprise Software | Review - LeadrPro</title>
      </Helmet>
      <div className='review__main-container'>
        <h1 className='review__title'>Write a Review</h1>

        <div className='input-box review__company-input'>
          <img
            src={searchicon}
            className='icon review__company-search'
            alt=''
          />

          <input
            id='review__company-search'
            className='input review__input'
            placeholder='Search for a company you want to review'
            onChange={handleChange}
          />
        </div>

        <div className='review__filter-companies'>
          <p className='review__filter-companies-title'>Filter by category</p>

          <input
            id='category'
            className='input review__categories-input'
            placeholder='Search catagories'
            onChange={handleCategoryChange}
            autoComplete='off'
          />
          {categories.length > 0 && (
            <div className='input review__hero-category-results'>
              {categories.map((item, index) => (
                <div key={index} className='review__hero-category-item'>
                  <a
                    className='review__hero-category-text'
                    onClick={() =>
                      handleCategoryClick(item.category_id, item.name)
                    }>
                    {item.name}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='review__company-list'>
          {noResultsFound && query !== '' && categorySelected === 0 && (
            <div className='review__no-results-found-wrapper'>
              <h3 className='review__no-results-found'>
                '{query}' is not in our system.
              </h3>
              <span>
                <a href='https://try.leadrpro.com/submit-company-review/'>
                  Send LeadrPro
                </a>{' '}
                a request to add them and we'll notify you when it's done.
              </span>
            </div>
          )}

          {noResultsFound && query === '' && (
            <div className='review__no-results-found-wrapper'>
              <span className='review__no-results-found'>
                No results found.
              </span>
            </div>
          )}

          {noResultsFound && query !== '' && categorySelected !== 0 && (
            <div className='review__no-results-found-wrapper'>
              <span className='review__no-results-found'>
                No results found.
              </span>
            </div>
          )}

          {companies && companies.map(renderCompanies)}
        </div>

        {pageLoading && companies.length <= 0 && (
          <div className='review__page-loading'>
            <span>Loading Page...</span>
          </div>
        )}

        {companies.length >= 12 && !end && (
          <div className='review__load-more-container'>
            {loading ? (
              <span className='review__load-more-results'>Loading...</span>
            ) : (
              <span
                className='review__load-more-results'
                onClick={() => setCompaniesPage((value) => value + 1)}>
                Load more results...
              </span>
            )}
          </div>
        )}

        {end && (
          <div className='review__end-of-results'>
            <span>End of Results.</span>
          </div>
        )}
      </div>
    </>
  );
}

export default ReviewLanding;
