import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { searchDemos, getCompanyCredits } from '../../utils/api/admin';

function CompanyCredits() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getCompanyCredits();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function handleSearch(){
    setSubmitting(true);
    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await searchDemos(query, id);

    if (res.success){
      setResults(res.data);
      setSubmitting(false);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await searchDemos(query, id);

      if (res.success){
        setResults(res.data);
        setSubmitting(false);
      }
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      await handleSearch();
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Company Credits {`(${data.length})`}</h1>

      {submitting === false && data.length === 0 && (
        <div className="center mt-20"><strong>Nothing to see here...</strong></div>
      )}

      {submitting === true && (
        <Spinner />
      )}

      {loaded && submitting === false && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Company ID</th>
              <th>Company</th>
              <th>Total Purchases</th>
              <th>Remaining Credits</th>
              <th>Last Application</th>
              <th>Last Approved</th>
              <th>Is Inactive</th>
              <th>On Search</th>
            </tr>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <strong>#{index}.</strong>
                </td>
                <td>
                  {item.company_id}
                </td>
                <td>
                  <div className='flex'>
                    <div>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} style={{maxHeight: '24px'}} />
                    </div>

                    <div className='ml-10'>
                      {item.name}
                    </div>
                  </div>
                </td>

                <td>
                  {item.total_purchases}
                </td>

                <td>
                  {item.remaining_credits}
                </td>

                <td>
                  {item.last_application && (
                    <>
                  {new Date(item.last_application).toDateString()}
                  </>
                  )}

                  {item.last_application == null && (
                    <span>N/A</span>
                  )}
                </td>

                <td>
                  {item.last_approved && (
                    <>
                  {new Date(item.last_approved).toDateString()}
                  </>
                  )}

                  {item.last_approved == null && (
                    <span>N/A</span>
                  )}
                </td>

                <td>
                  {item.is_inactive == true && (
                    <span className='admindashboard__red-flag'>Inactive</span>
                  )}

                  {item.is_inactive == false && (
                    <span className='admindashboard__green-flag'>Active</span>
                  )}
                </td>

                <td>
                {item.is_seller == true && (
                    <span className='admindashboard__green-flag'>Yes</span>
                  )}

                  {item.is_seller == false && (
                    <span className='admindashboard__red-flag'>No</span>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default CompanyCredits;