import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import React from 'react';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaCheckCircle, FaEllipsisV, FaEnvelope, FaPaperPlane, FaPhoneAlt, FaRegPaperPlane, FaRegStickyNote, FaStickyNote, FaTimes } from 'react-icons/fa';
import { getLeadFormSubmissions, updateLeadFormNotes, getPayNowCompanies, getLeadFormCards, createLeadCards, getLeadFormSubmission } from '../../utils/api/admin';
import { Editor } from '@tinymce/tinymce-react';

function LeadFormCards() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [adminNotes, setAdminNotes] = useState('');
  const [status, setStatus] = useState('');
  const [showLeadGenerator, setShowLeadGenerator] = useState(false);
  const [activeSellers, setActiveSellers] = useState([]);
  const [description, setDescription] = useState('');
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [isDecisionMaker, setIsDecisionMaker] = useState(false);
  const [timeToCall, setTimeToCall] = useState('');
  const [numberOfUsers, setNumberOfUsers] = useState('');
  const [annualRevenue, setAnnualRevenue] = useState('');
  const [keyFeatures, setKeyFeatures] = useState('');
  const [priceExpectations, setPriceExpectations] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [contactEmail, setContactEmail]= useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [showVendors, setShowVendors] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  let refStatus = React.createRef();

  async function loadData(){
    let res = await getLeadFormCards();

    if (res.success){
      setData(res.data);
      setLoaded(true);

      setNumberOfUsers(res.data.number_of_users);
      setAnnualRevenue(res.data.annual_revenue);
      setKeyFeatures(res.data.key_features);
      setPriceExpectations(res.data.price_expectations);
      setTimeframe(res.data.timeframe);
    }
  }

  useEffect(() => {
    async function loadSellers(){
      //let res = await adminActiveSellers('10', '2024')
      let res = await getPayNowCompanies();

      if (res.success){
        setActiveSellers(res.data);
      }
    }

    async function loadData(){
      let res = await getLeadFormCards();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
    loadSellers();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleDetailsClick(id){
    let res = await getLeadFormSubmission(id);

    if (res.success){
      let item = res.data;

      setSelectedId(id);
      setSelectedItem(item);
      setAdminNotes(item.admin_notes);
      setFullName(item.name);
      setTimeToCall(item.time_to_call);
      setNumberOfUsers(item.number_of_users);
      setAnnualRevenue(item.annual_revenue);
      setKeyFeatures(item.key_features);
      setPriceExpectations(item.price_expectations);
      setTimeframe(item.timeframe);
      setStatus(item.status);

      setShowDetails(true);
    }
  }

  async function handleSaveNotes(){
    let res = await updateLeadFormNotes(selectedId, adminNotes, refStatus.current.value, numberOfUsers, annualRevenue, keyFeatures, priceExpectations, timeframe, firstName, lastName);

    if (res.success){
      setShowDetails(false);
      loadData();
    }
  }

  async function handleCreateCompanyLeads(){
    setShowDone(true);
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Lead Form Cards {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Date</th>
              <th>Company</th>
              <th>Lead Card Reference #</th>
              <th>Submission Reference #</th>
              <th>Lead</th>
              <th>Status</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date_created).toLocaleString()}</td>

                <td>
                  <span>
                    <img style={{height: '30px'}} src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </span>

                  <span className='ml-10'>
                    {item.seller_company_name}
                  </span>
                </td>

                <td>
                  {item.reference_id}
                </td>

                <td>
                  {item.lead_form_reference_id}
                </td>

                <td>
                  <div>{item.name}</div>
                  <div>{item.job_title}</div>
                  <div>{item.company_name}</div>
                </td>

                <td>
                  <div>
                    {item.status == 'Pending' && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.status != 'Pending' && item.status != 'Closed' && (
                      <span className='admindashboard__blue-flag'>{item.status}</span>
                    )}

                    {item.status == 'Closed' && (
                      <span className='admindashboard__green-flag'>Closed</span>
                    )}
                  </div>
                </td>

                <td>
                  <span className='link' onClick={() => handleDetailsClick(item.lead_form_submission_id, item)}>
                    Open Lead Form
                  </span>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

{showDetails && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide' style={{width: '70%', marginLeft: '-35%'}}>
            <div className='modal__close' onClick={() => setShowDetails(false)}>
              <FaTimes />
            </div>

            {selectedItem.status == 'Pending' && (
              <div>
                <span className='admindashboard__yellow-flag'>Pending</span>
              </div>
            )}

            {selectedItem.status == 'Closed' && (
              <div>
                <span className='admindashboard__green-flag'>Closed</span>
              </div>
            )}
            <h2 className='modal__h2'>Lead Form Submission</h2>

            <div>
              <i>Reference #: {selectedItem.reference_id}</i>
            </div>

            <div className='mt-10'>
              {selectedItem.registered_user_id != null && (
                <span className='admindashboard__green-flag'>
                  Registered User
                </span>
              )}

            <div className='form-group'>
                <strong>Full Name</strong>
                <div>
                  <input type='text' className='input' placeholder='Full name' value={fullName} onChange={(e) => setFullName(e.target.value)} />
                </div>
              </div>

              <div className='form-group'>
                <strong>First Name</strong>
                <div>
                  <input type='text' className='input' placeholder='First name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
              </div>

              <div className='form-group'>
                <strong>Last Name</strong>
                <div>
                  <input type='text' className='input' placeholder='Last name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
              </div>

              <div className='form-group'>
                <strong>Job title</strong>
                <div>
                  <input type='text' className='input' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder='Job title' />
                </div>
              </div>

              <>
                <div className='form-group'>
                  <strong>Company</strong>

                  <div>
                    <input type='text' placeholder='Company name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} className='input' />
                  </div>
                </div>
              </>

              <div className='form-group'>
                <strong>Company size</strong>

                <div>
                  <select className='input select'>
                    <option>Select company size</option>
                    <option>0-10</option>
                    <option>10-50</option>
                    <option>50-250</option>
                    <option>250-500</option>
                    <option>500-1000</option>
                    <option>1000+</option>
                  </select>
                </div>
              </div>

              {selectedItem.email_preference == true && (
                <div className='form-group'>
                  <strong>Contact email</strong>: {selectedItem.contact_email}

                  <div>
                    <input type='text' placeholder='Contact email' value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} className='input' />
                  </div>
                </div>
              )}

              <div className='form-group'>
                <strong>Contact phone</strong>: {selectedItem.contact_phone}

                <div>
                  <input type='text' placeholder='Contact phone' value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} className='input' />
                </div>
              </div>


              <div className='form-group'>
                <strong>Is decision maker</strong>

                <span className='ml-10'>
                  <input type='checkbox' value={isDecisionMaker} onChange={(e) => setIsDecisionMaker(e.target.checked)} />
                </span>
              </div>

              <div className='form-group'>
                <strong>Time to call</strong>

                <div>
                  <input type='text' className='input' value={timeToCall} onChange={(e) => setTimeToCall(e.target.value)} placeholder='Time to call' />
                </div>
              </div>

              <div className='form-group'>
                <strong>Looking for</strong>
                <div>
                  <i>{selectedItem.request_body}</i>
                </div>
              </div>

              <div className='form-group'>
                <strong>Number of users</strong>
                <div>
                  <input type='text' className='input textarea' value={numberOfUsers} onChange={(e) => setNumberOfUsers(e.target.value)} placeholder='Number of users' />
                </div>
              </div>

              <div className='form-group'>
                <strong>Annual Revenue</strong>
                <div>
                  <input type='text' className='input textarea' value={annualRevenue} onChange={(e) => setAnnualRevenue(e.target.value)} placeholder='Annual revenue' />
                </div>
              </div>

              <div className='form-group'>
                <strong>Key features</strong>
                <div>
                  <textarea className='input textarea' value={keyFeatures} onChange={(e) => setKeyFeatures(e.target.value)} placeholder='Key features'></textarea>
                </div>
              </div>

              <div className='form-group'>
                <strong>Price expectations</strong>
                <div>
                  <textarea className='input textarea' value={priceExpectations} onChange={(e) => setPriceExpectations(e.target.value)} placeholder='Price expections'></textarea>
                </div>
              </div>

              <div className='form-group'>
                <strong>Timeframe</strong>
                <div>
                  <textarea className='input textarea' value={timeframe} onChange={(e) => setTimeframe(e.target.value)} placeholder='Timeframe'></textarea>
                </div>
              </div>

              <div className='form-group'>
                <strong>Admin notes</strong>
              <div>
                <textarea style={{height: '200px'}} className='input textarea' placeholder='Admin notes' value={adminNotes} onChange={(e) => setAdminNotes(e.target.value)}></textarea>
              </div>
            </div>
            </div>

            <div style={{display: 'flex', alignItems: 'center'}} className='mt-10'>
            <span className=''>
                <select className='input select' ref={refStatus} value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value='Pending'>Pending</option>
                  <option value='1stCall'>1st Call</option>
                  <option value='Closed'>Closed</option>
                </select>
              </span>

              <span className='ml-10'><button className='btn' onClick={() => handleSaveNotes()}>Save</button></span>

              <span className='ml-10'>
              <button className='btn' onClick={() => setShowLeadGenerator(true)}>Generate Leads</button>
              </span>

              <span className='ml-10'>
                <button className='btn' onClick={() => setShowVendors(true)}>Load Vendor List</button>
              </span>
            </div>

            {/* {showVendors && (
            <div className='mt-20'>
              <div>
                <i>Select all companies to create lead cards for:</i>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {activeSellers.map(item => (
                    <div style={{border: 'solid 1px #ccc', padding: '10px', borderRadius: '10px', fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: '5px'}}>
                      <span><input type='checkbox' id={`cbCompany_${item.company_id}`} /></span>
                      <span className='ml-10'>
                      <img style={{maxHeight: '20px', borderRadius: '5px'}} src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} /> <label htmlFor={`cbCompany_${item.company_id}`}>{item.name}</label>
                        </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className='mt-20'>
                  <button className='btn btn--primary' onClick={() => handleCreateCompanyLeads()}>Create Company Lead Cards</button>
              </div>
            </div>
            )} */}
          </div>
        </>
      )}
      
      {showLeadGenerator && (
        <>
          <div className='overlay'></div>
          <div className='modal modal--wide' style={{width: '80%', marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowLeadGenerator(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Lead Form Generator</h2>

            <div style={{display: 'flex'}}>
              <div style={{width: '70%'}}>
              <div className='mt-10'>
              {selectedItem.registered_user_id != null && (
                <span className='admindashboard__green-flag'>
                  Registered User
                </span>
              )}

              <div>
                <strong>Name</strong>: {selectedItem.name}
              </div>

              <div className='form-group'>
                <strong>Job title</strong>: {selectedItem.job_title}
                <div>
                  <input type='text' className='input' placeholder='Job title' />
                </div>
              </div>

              {selectedItem.registered_user_id != null && (
                <>
                  <div>
                    <strong>Company</strong>: {selectedItem.company_name}
                  </div>
                </>
              )}

              {selectedItem.email_preference == true && (
                <div>
                  <strong>Contact email</strong>: {selectedItem.contact_email}
                </div>
              )}

              {selectedItem.phone_preference == true && (
              <div>
                <strong>Contact phone</strong>: {selectedItem.contact_phone}
              </div>
              )}

              <div>
                <strong>Time to call</strong>: {selectedItem.time_to_call}
              </div>

              <div>
                <strong>Looking for</strong>: {selectedItem.request_body}
              </div>

              <div className='mt-10'>
                <strong>Admin Notes</strong>
                <div>
                  {selectedItem.admin_notes}
                </div>
              </div>

              <div className='mt-10'>
                <strong>Lead Card Editor</strong>

                <div>
                <Editor
      apiKey='cdi8hbnuihfmdcpjhe2c0jf8b7bov88gr0amec9khuxoykpg'
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(newValue, editor) => setDescription(newValue)}
      initialValue={description}
    />
                </div>
              </div>
            </div>
              </div>

              <div style={{width: '30%', padding: '0px 10px', overflow: 'auto', height: '500px'}}>
                <h3><strong>Active Sellers</strong></h3>
                <div>
                  {activeSellers.map(item => (
                    <div style={{border: 'solid 1px #ccc', padding: '10px', borderRadius: '10px', fontWeight: 'bold', display: 'flex', alignItems: 'center', margin: '5px 0px'}}>
                      <span><input type='checkbox' /></span>
                      <span className='ml-10'>
                      <img style={{maxHeight: '20px', borderRadius: '5px'}} src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} /> {item.name}
                        </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='mt-20'>
              <button className='btn btn--primary' style={{backgroundColor: 'black', color: 'white', width: '100%', borderRadius: '30px'}}>Create Pricing Leads</button>
            </div>
          </div>
        </>
      )}

      {showDone && (
        <>
          <div className='overlay'></div>
          <div className='modal center'>
            <div className='center' style={{fontSize: '50px'}}><FaRegPaperPlane /></div>

            <div className='mt-10'>
              <div><FaCheckCircle/> Lead cards generated</div>
              <div><FaCheckCircle /> Emails have been sent</div>
            </div>

            <div className='mt-20 center'>
              <button className='btn btn--primary' onClick={() => setShowDone(false)}>Done</button>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default LeadFormCards;