import './Privacy.css';
import React, { useEffect } from "react";

function Privacy03012023() {

  useEffect(() => {
    document.title = 'LeadrPro - Privacy Policy';
  }, []);

    return ( 
    <div className='privacy'>
        <h1>Privacy Policy</h1>

        <div>
        Last Updated: 3/1/2023
        </div>
        <h2>Introduction</h2>

        <p>
This “Privacy Notice” describes the privacy practices of LeadrPro, Inc. (“LeadrPro,” “we,” “our,” or “us”) in connection with the www.LeadrPro.com website and any other website that we own or control and which posts or links to this Privacy Notice, and our related products and services (collectively, the “Platform”). This Privacy Notice also explains the rights and choices available to individuals with respect to their information. Any terms not defined in this Privacy Notice have the same meanings as set out in our Terms and Conditions.
</p>

<p>
Please read this Privacy Notice carefully to understand our policies and practices regarding your information. If you do not agree with our policies and practices, your choice is not to use our Platform. By accessing or using the Platform, you acknowledge this Privacy Notice. If you do not agree with our information handling practices, please do not use our Platform.
</p>

<h2>Updates to This Notice</h2>
<p>
We may update this Privacy Notice based upon evolving laws, regulations and industry standards, or as we may make changes to our Platform. We will post changes to our Privacy Notice on this page and encourage you to review our Privacy Notice when you use our Platform to stay informed. If we make changes that materially alter your privacy rights, we will take appropriate measures to inform you, consistent with the significance of the changes we make.  If you disagree with the changes to this Privacy Notice, you should discontinue your use of the Platform.
</p>

<h2>Children</h2>
<p>
The Platform is not directed to, and we do not knowingly collect personal information from, anyone under the age of 18. If a parent or guardian becomes aware that his or her child has provided us with information, they should contact us. We will delete such information from our files as soon as reasonably practicable.
</p>

<h2>Personal Information We Collect</h2>
<h3>Information You Provide Us</h3>

<p>
We may collect information that personally identifies you such as your name, email address, or other data that can be reasonably linked to such information by LeadrPro (“Personal Information”) when you use our Platform or otherwise interact with us. You may choose to provide us with your Personal Information to sign up for an account either as a Seller or a Buyer, when you complete forms on our Platform or respond to surveys, and when you contact us.
</p>

<h3>Information We Automatically Collect</h3>
<p>
When you use our Platform, LeadrPro may automatically collect certain information about the device you use to access our Platform including unique identifiers tied to your device and browser. We may also collect your browsing history and interactions you have with our Platform. We may automatically collect this information by using cookies and web beacons.
</p>

<p>
A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
</p>

<p>
Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit LeadrPro, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
</p>

<h2>How We Use Personal Information</h2>

<p>We use your personal information for the following purposes and as otherwise described in this Privacy Notice or at the time of collection:</p>

<ul>
<li>To provide, operate and improve the Platform;</li>
<li>To fulfill any orders you place using our Platform;</li>
<li>To establish and maintain your account on the Platform;</li>
<li>To communicate with you about the Platform, including by sending you announcements and administrative messages;</li>
<li>To personalize your experience with the Platform and communicate with you;</li>
<li>To provide support and maintenance;</li>
<li>To respond to your request and feedback;</li> 
<li>To provide you with LeadrPro marketing communications;</li>
<li>To help us advertise the Platform and display advertisements on the Platform;</li>
<li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
<li>To comply with legal obligations; and</li>
<li>With your consent.</li>
</ul>

<h2>How We Share Personal Information</h2>

<p>We do not share your personal information with third parties without your consent, except as described in this Privacy Notice. We may share your personal information with our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Notice.</p>

<p>We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate the Platform (such as professional advisors, customer support, hosting, analytics, email delivery, marketing, and database management services). These third parties may use your personal information only as directed or authorized by us and in a manner consistent with this Privacy Notice, and are prohibited from using or disclosing your information for any other purpose. We may also share your personal information for compliance purposes.</p>

<p>We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution.</p>

<h2>Do Not Track</h2>

<p>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.</p>

<h2>Links to Third-Party Sites</h2>

<p>The Platform may contain links to other websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or online services that are not associated with us. We do not control third party websites, mobile applications or online services, and we are not responsible for their actions. Other websites, mobile applications and services follow different rules regarding the collection, use and sharing of your personal information. We encourage you to read the privacy policies of the other websites, mobile applications and online services you use.</p>

<h2>Data Security</h2>

<p>The security of your personal information is important to us. We employ a number of administrative, technical, and physical safeguards designed to protect the personal information we collect. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Platform like in customer reviews.</p>

<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Platform.</p>

<h2>Note To International Users</h2>

<p>The Platform is hosted in the United States. If you are a user accessing the Platform from the European Union, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure that differ from United States laws, please be advised that through your continued use of the Platform, which is governed by US law, you are transferring your Personal Information to the United States and you consent to that transfer.</p>

<h2>California’s Shine the Light Law</h2>

<p>California Civil Code Section 1798.83 permits users of our website that are California residents to request certain information regarding our disclosure of the information you provide through the website to third parties for their direct marketing purposes. To make such a request, please contact us at the information provided below.</p>

<h2>Contact Us</h2>

<p>
For questions or comments about this Privacy Notice and our privacy practices, contact us at: 
</p>

support@leadrpro.com
202 Bicknell Ave., Santa Monica, CA 90405 

    </div>
    );
}

export default Privacy03012023;