import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getAdminDemosPending } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { create } from 'd3';

function DemosPending() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [demosPending, setDemosPending] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [currentDemo, setCurrentDemo] = useState(null);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [breakdown, setBreakdown] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadDemosPending(){
      let res = await getAdminDemosPending();

      if (res.success){
        setDemosPending(res.data);
        setBreakdown(createBreakdown(res.data));
        setLoaded(true);
      }

      else{
        alert('failed to load and retry');
      }
    }

    loadDemosPending();
  }, []);

  function createBreakdown(data){
    const result = data.reduce((acc, { seller_company_name, seller_has_viewed }) => {
      if (!acc[seller_company_name]) {
        acc[seller_company_name] = { true_count: 0, false_count: 0 };
      }
      if (seller_has_viewed) {
        acc[seller_company_name].true_count += 1;
      } else {
        acc[seller_company_name].false_count += 1;
      }
      return acc;
    }, {});

    return result;
  }

  return (
    <>
      <Helmet>
        <title>Admin - Demos Pending</title>
      </Helmet>

      {loaded === false && (
        <Spinner />
      )}

      <div>
        {loaded && (
        <>
          <h1>Demos Pending {`(${demosPending.length})`}</h1>

          <div>
            <div>
              <span className='admindashboard__has-been-reviewed' style={{borderRadius: '10px', fontWeight: 'normal', padding: '2px 10px'}}>Reviewed</span> - <span>{demosPending.filter(x => x.seller_has_viewed).length}</span>
            </div>

            <div>
              <span className='admindashboard__has-not-been-reviewed' style={{borderRadius: '10px', fontWeight: 'normal', padding: '2px 10px'}}>Not Reviewed</span> - <span>{demosPending.filter(x => x.seller_has_viewed === false).length}</span>
            </div>

            <div>
              <button className='btn' onClick={() => setShowBreakdown(true)}>View breakdown</button>
            </div>
          </div>

          <div className='admindashboard__demos'>
            {demosPending.map(item => (
              <div className='admindashboard__demo-card'>
                <div className='admindashboard__demo-card-top-banner'>
                  <span>#{item.demo_id} - </span>
                  <span>{new Date(item.date_applied).toLocaleDateString()}</span>

                  <div className='admindashboard__demo-card-submenu'>
                  <span className='admindashboard__demo-card-submenu-icon' onClick={() => {
                    if (currentDemo != null && currentDemo.demo_id === item.demo_id){
                      setShowSubMenu(!showSubMenu);
                    }

                    else{
                    setShowSubMenu(true);
                    setCurrentDemo(item);
                    }
                  }}>
                    <FaEllipsisV />
                  </span>

                  {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item'>Buyer Details</div>

                      {item.buyer_feedback_count > 0 && loaded && (
                      <div className='dashboard__submenu-item'>Open Feedback</div>
                      )}
                    </div>
                    </FadeIn>
                    )}
                  </div>
                </div>

                <div>
                    {item.seller_has_viewed === true && (
                      <div className='admindashboard__has-been-reviewed'>Has been reviewed</div>
                    )}
                    
                    {item.seller_has_viewed === false && (
                      <div className='admindashboard__has-not-been-reviewed'>Has not been reviewed</div>
                    )}
                  </div>

                <div className='admindashboard__demo-left-right-parent'>
                  <div className='admindashboard__demo-card-left'>
                    <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        {item.seller_company_name}
                      </div>
                    </div>

                    <div className='admindashboard__demo-card-body'>
                      <div><strong>Pay method: </strong>{item.payment_type}</div>
                      <div><strong>Last active: </strong>
                      {item.last_active && (
                        <>
                          {new Date(item.last_active).toLocaleDateString()}
                        </>
                      )}
                      </div>

                      <div>
                      {item.seller_is_inactive && (
                        <div className='admindashboard__not-active'>Inactive</div>
                      )}
                      </div>
                    </div>
                  </div>

                  <div className='admindashboard__demo-card-right'>
                    <div className='admindashboard__demo-card-header' style={{display: 'flex'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>

                      <div className='admindashboard__demo-card-name'>
                        {item.buyer_company_name}
                      </div>
                    </div>

                    <div className='admindashboard__demo-card-body'>
                      <div>
                        {item.interest_level == 'Very interested' && (
                          <div className='dashboarddemo__very-interested'>
                            {item.interest_level}
                          </div>
                        )}

                        {item.interest_level === 'Somewhat interested' && (
                          <div className='dashboarddemo__somewhat-interested'>
                            {item.interest_level}
                          </div>
                        )}
                      </div>

                      <div>
                        <strong>Job title:</strong> <span>{item.buyer_job_title}</span>
                      </div>

                      <div>
                        <strong>Timezone:</strong> <span>{item.buyer_timezone}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        )}
      </div>

      {showBreakdown && (
        <>
          <div className='overlay'></div>
          <div className='modal admindashboard__modal-breakdown'>
            <div className='modal__close' onClick={() => setShowBreakdown(false)}>
              <FaTimes />
            </div>

            <h1>Breakdown</h1>

            <div>
            <table className='dashboard__table table'>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Reviewed (True)</th>
            <th>Not Reviewed (False)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(breakdown).map(([seller_company_name, counts]) => (
            <tr key={seller_company_name}>
              <td>{seller_company_name}</td>
              <td>{counts.true_count}</td>
              <td>{counts.false_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DemosPending;
;
