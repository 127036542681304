import './CreateAccount.css';
import { useEffect, useState } from "react";
import { createUserSeller, checkTeamInvite, createUserEvent } from '../../utils/api';
import VerifyEmail from './VerifyEmail';
import classNames from "classnames";
import FadeIn from '../../utils/fadein';
import {Helmet} from "react-helmet";
import Spinner from '../../components/layout/Spinner';
import list_sellers from '../../assets/design/signup/list_sellers.png';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate, Link, useParams } from 'react-router-dom';
import {isAuthed, isSeller} from '../../services/auth.service';

function CreateAccountEvent({authenticated}) {
    const [email, setEmail] = useState("");
    const [optIn, setOptIn] = useState(false);
    const [agree, setAgree] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('');
    const [inviteToken, setInviteToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [isInvite, setIsInvite] = useState(false);
    const [userSource, setUserSource] = useState('');
    const navigate = useNavigate()
    const search = useLocation().search;

    useEffect(() => {
        async function checkInvite(){
            const _refer = new URLSearchParams(search).get('refer');

            if (_refer){
                setInviteToken(_refer);

                let res = await checkTeamInvite(_refer);

                if (res.success){
                    if (res.data)
                    {
                        setIsInvite(true);
                    }
                }
            }
        }

        if (isAuthed()){
            navigate('/');
        }

        checkInvite();
    }, []);

    function getCookie(cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

    async function handleSubmit(e){
        e.preventDefault();

        setLoading(true);

        let el1 = e.target.elements.email.value;
        let el2 = e.target.elements.optIn.value;
        let el3 = e.target.elements.agree.value;
        let channel_token = getCookie('channel_token');
        let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let partner_key = getCookie('ps_partner_key');

        if (el1.length === 0){
            setHasError(true);
            return;
        }

        if (!el3){
            setHasError(true);
            return;
        }

        let data= {email: el1, optIn: el2, source: userSource, channel_token, timezone: l_timezone, partner_key};
        let res  = await createUserEvent(data);

        setEmail(el1);

        if (res.success){
            const searchParams = new URLSearchParams(window.location.search);

            if (searchParams.get('eventid') != null){
                let urlToEncode = `https://www.leadrpro.com/event/${searchParams.get('eventid')}/${'details'}`;
                navigate(`/account-setup-e?token=${res.data}&redirect=${encodeURIComponent(urlToEncode)}`);
                //navigate(`/account-setup-e?token=${res.data}`);
            }

            else{
                navigate(`/account-setup-e?token=${res.data}`);
            }
        }
        else{
            setLoading(false);
            setHasError(true);
            setErrorMessage(res.message);
        }
    }

    if (completed){
        return <FadeIn delay={250} duration={450}><VerifyEmail email={email} token={token} /></FadeIn>
    }

    return (
        <div className="card__wrapper">
        <div className="card createaccount">
            <div className="createaccount__left">

            {/* <h1 className="card__header">Create Free Account</h1> */}
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='form__group'>
                            <label className='createaccount__input-label' htmlFor='txtEmail'>Enter email</label>

                            <input className={classNames("createaccount__input", "input", {
                            "input--error":hasError
                        })} type='email' id='txtEmail' required placeholder='Enter your email' name="email" />
                        </div>

                        <div className='form-group'>
                            <label className='createaccount__input-label' htmlFor='selectSource'>How did you hear about us?</label>
                            <select className='input select' id='selectSource' onChange={(e) => setUserSource(e.target.value)}>
                                <option value='ProductHunt'>Product Hunt</option>
                                <option value='LinkedIn'>LinkedIn</option>
                                <option value='Facebook'>Facebook</option>
                                <option value='Google'>Google</option>
                                <option value='Email'>Email</option>
                                <option value='Ad'>Ad</option>
                                <option value='Article'>Article</option>
                                <option value='Referral'>Referral</option>
                                <option value='Other'>Other</option>
                            </select>
                        </div>

                        <div className='form-group'>
                            <input type='checkbox' id="radioOptIn" name="optIn" checked={optIn} value={optIn} onChange={() => setOptIn(!optIn)} />

                            <label className='createaccount__radio-label label' htmlFor='radioOptIn'>Yes, please keep me updated on news, events and offers
                            </label>
                        </div>

                        <div className='form-group'>
                            <input type='checkbox' id="radioAgree" name="agree" required checked={agree} value={agree} onChange={() => setAgree(!agree)} />

                            <label className='createaccount__radio-label label' htmlFor='radioAgree'>I agree to the <a href="/terms" className='link' target="_blank">terms</a> and <a href="/privacy" target="_blank" className='link'>privacy</a> policies
                             </label>
                        </div>

                        {hasError === true && (
                            <div className="form-group">
                                <span className={classNames("label", {"label--error":hasError})}>{errorMessage}</span>
                            </div>
                        )}

                        <div className="form__group center" style={{marginTop: "30px"}}>
                            {loading && (
                                <Spinner />
                            )}

                            {!loading && (
                            <button className='btn btn--primary'>
                                Register
                            </button>
                            )}
                        </div>

                        <p className="createaccount__text--small center">Already have an account? <Link to="/login" className='link'>Sign in</Link></p>
                </form>
            </div>
        </div>

        <div className="createaccount__right">
            <div className="createaccount__right-header">
               Join today and:
            </div>

            <div className="createaccount__right-list">
                <ul style={{listStyleType: 'disc', fontSize: '20px', fontWeight: 'bold'}}>
                  <li>Register for our exclusive events</li>
                  <li>Browse through 1000's of local events</li>
                  <li>Chat with guests using our exclusive app</li>
                </ul>
            </div>
        </div>
        </div>
    </div>
    );
}

export default CreateAccountEvent;