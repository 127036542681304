import { useState, useEffect } from 'react';
import LeadCard from '../../components/qualifier/LeadCard';
import DeclinedPopup from './DeclinedPopup';
import ReassignDemo from './ReassignDemo';
import {getSellerObjections, getSellerDeclinedReasons, getSellerDemosAvailable, refreshToken, openedLeadCard, checkHasPaymentMethod, createDemoReminder, getUserCompany, getPackageItemsRemainingCount, getDemoQualifierAnswers, sellerReschedule, getSellerRecommendationsForDashboard} from '../../utils/api';
import { refresh, getCurrentUser } from '../../services/auth.service';
import { FaFireAlt, FaUsers, FaClock, FaRegBell, FaEnvelope, FaCogs, FaListAlt, FaList, FaListUl, FaSearch, FaEllipsisV, FaRegEnvelope, FaTimes, FaPaperPlane } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
import close from '../../assets/design/dashboard/close.png';
import { Link, useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import { getBaseImageUrl } from '../../utils/utils';
import SellerDecline from './SellerDecline';

const SellerDemosAvailable = ({ refreshRemaining }) => {
  const imagePath = process.env.REACT_APP_IMAGE_PATH;
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const [sellerDeclinedReasons, setSellerDeclinedReasons] = useState([]);
  const [showReassign, setShowReassign] = useState(false);
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  const [showSnooze, setShowSnooze] = useState(false);
  const [showSnoozeConfirm, setShowSnoozeConfirm] = useState(false);
  const [reminderDays, setReminderDays] = useState(7);
  const [reminderDate, setReminderDate] = useState('');
  const [userCompany, setUserCompany] = useState(null);
  const [showExpiresModal, setShowExpiresModal] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [showInReview, setShowInReview] = useState(false);
  const [selectedDemo, setSelectedDemo] = useState(null);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showRescheduleConfirm, setShowRescheduleConfirm] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [recommended, setRecommended] = useState([]);
  const [recommendedLoaded, setRecommendedLoaded] = useState(false);
  const [showBuyerInvite, setShowBuyerInvite] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const currentUser = getCurrentUser();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = getBaseImageUrl();
  const navigate = useNavigate();

  async function handleToggleMenu(item){
    if (item.demo_id !== currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(true);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }
  }

  async function loadRecommended(){
    let res = await getSellerRecommendationsForDashboard();

    if (res.success){
      setRecommended(res.data);
      setRecommendedLoaded(true);
    }
  }

  async function loadRemainingCount(){
    let res = await getPackageItemsRemainingCount();

    if (res.success){
      setRemainingCount(res.data);
      setRemainingCountLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getPackageItemsRemainingCount();

      if (res.success){
        setRemainingCount(res.data);
        setRemainingCountLoaded(true);
      }
    }
  }

  async function reloadRemaining(){
    await loadRemainingCount();
    await refreshRemaining();
  }

  useEffect(async () => {
    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getUserCompany();

        if (res.success){
          setUserCompany(res.data);
        }
      }
    }

    async function loadPaymentMethod() {
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
      } 

      setPaymentMethodLoaded(true);
    }

    await loadPaymentMethod();
    await loadUserCompany();
    await loadRemainingCount();
    await loadRecommended();
  }, []);

  useEffect(() => {
    async function loadDemos(){
      let res = await getSellerDemosAvailable();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getSellerDemosAvailable();

        if (res.success){
          setDemos(res.data);
        }

        setLoaded(true);
      }
    }

    loadDemos();
  }, []);
 
  useEffect(() => {
    async function fetchSellerDeclinedReasons() {
      let data = await getSellerDeclinedReasons();
      if (data.success) {
        setSellerDeclinedReasons(data.data);
      }
    }

    fetchSellerDeclinedReasons();
  }, []);

  async function handleReviewClick(demo){
    setLoading(true);
    setSelectedId(demo.demo_id);

    let res = await getDemoQualifierAnswers(demo.demo_id);

    if (res.success){
      let new_item = {...demo, qualifier_answers: res.data};
      setCurrentDemo(new_item);
      setShowQualifierAnswers(true);
      setLoading(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getDemoQualifierAnswers(demo.demo_id);

      if (res.success){
        let new_item = {...demo, qualifier_answers: res.data};
        setCurrentDemo(new_item);
        setShowQualifierAnswers(true);
        setLoading(false);
      }
    }

    await openedLeadCard(demo.demo_id);
  }

  async function handleReassignClick(demo){
    setCurrentDemo(demo);
    setShowReassign(true);
    setShowSubMenu(false);
  }

  async function handleSnoozeClick(demo){
    setCurrentDemo(demo);
    setShowSnooze(true);
  }

  async function handleDecline(demo) {
    setCurrentDemo(demo);
    setShowQualifierAnswers(false);
    setShowDeclinedPopup(true);
    setShowSubMenu(false);
  }

  async function handleSetReminder(){
    let date = new Date();
    date.setDate(date.getDate() + reminderDays);

    setReminderDate(date.toLocaleDateString());

    let res = await createDemoReminder(currentDemo.demo_id, date);

    if (res.success){
      setShowSnooze(false);
      setShowSnoozeConfirm(true);

      let filter = demos.filter(item => item.demo_id !== currentDemo.demo_id);
      setDemos(filter);
    }

    else if (res.status == 403){
      await refresh();
      let res = await createDemoReminder(currentDemo.demo_id, date);

      if (res.success){
        setShowSnooze(false);
        setShowSnoozeConfirm(true);

        let filter = demos.filter(item => item.demo_id !== currentDemo.demo_id);
        setDemos(filter);
      }
    }
  }

  function addDays(d, days){
    const result = new Date(d);
    result.setDate(result.getDate() + days);
    return result;
  }

  // if date sent was over 14 days ago
  function isExpiring(d, last_date, item){
    let date = new Date();
    let newDate = addDays(date, -14);
    let between = daysBetweenDates(date, last_date);

    if (between < 4 && between > 0){
      return (
        <>
          <span className='dashboarddemo__expires-soon'>Expires in {between} days</span>
        </>
      )
    }

    else if (between === 0){
      return (
        <>
          <span className='dashboarddemo__expires-soon'>Expiring soon</span>
        </>
      )
    }

    else if (between < 0){
      return (
        <>
          <span className='dashboarddemo__expires-soon' onClick={() => {
            handleShowExpires(item)
            }}>Times have expired</span>
        </>
      )
    }

    else{
      return (
        <></>
      )
    }
  }

  function daysBetweenDates(date1, date2) {
    const millisecondsPerHour = 60 * 60 * 1000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
    const differenceInMilliseconds = date2 - date1; // Get the absolute difference in milliseconds
    const hours = differenceInMilliseconds / millisecondsPerHour; // Convert milliseconds to hours
    return Math.floor(hours / 24);
  }

  async function confirmReschedule() {
    setRescheduling(true);
    let res = await sellerReschedule(selectedDemo.demo_id);

    if (res.success){
      let _filter = demos.filter((tmp) => tmp.demo_id !== selectedDemo.demo_id);
      let _obj = Object.assign({}, selectedDemo);
      _obj.seller_status = 'Rescheduled';
      _filter.push(_obj);
      setDemos(_filter);
      setRescheduling(false);
      setShowRescheduleConfirm(true);
      setShowReschedule(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await sellerReschedule(selectedDemo.demo_id);

      if (res.success){
        let _filter = demos.filter((tmp) => tmp.demo_id !== selectedDemo.demo_id);
        let _obj = Object.assign({}, selectedDemo);
        _obj.seller_status = 'Rescheduled';
        _filter.push(_obj);
        setDemos(_filter);
        setRescheduling(false);
        setShowRescheduleConfirm(true);
        setShowReschedule(false);
      }
    }
  }

  async function handleShowExpires(item){
    setShowExpiresModal(true);
    setSelectedDemo(item);
    setRescheduling(false);
    setShowRescheduleConfirm(false);
  }

  function handleRescheduleClick(item) {
   // setShowCancelConfirm(false);
    setSelectedDemo(item);
    //setShowFutureDemoNotice(false);
    setShowReschedule(true);
    setShowSubMenu(false);
  }

  return (
    <>
      {loaded === false && (
        <Spinner />
      )}

    <div>
      {loaded && demos.length === 0 && (
        <div className='dashboarddemo__no-results'>
          <div>
            <p>We are looking for the best candidates currently that match your ICP's. Hang tight and we will notify you when your requests are ready to review.</p>

            <p className='mt-20'>
              Or you can start to fill your sales pipeline today by viewing our top <a href='/s/recommendations' className="link">available prospects</a>.
            </p>
          </div>
        </div>
      )}

    {loaded && demos.length > 0 && (
    <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
        <tr>
            <th></th>
            <th>Company</th>
            <th>Date applied</th>
            <th>Interest</th>
            <th>Review</th>
            <th></th>
          </tr>
      {(demos.filter((demo) => (demo.buyer_status === 'Approved' && demo.seller_status === 'Pending') && demo.demo_status !== 'Declined')
      .sort((a, b) => new Date(b.date_applied) - new Date(a.date_applied)))
          .map((item, index) => (
              <tr className='sellerupcomingdemos__container' key={index}>
                <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
                </td>

                <td>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>
                      </div>
                    )}

                    <div className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </div>

                    {(item.seller_company_id == "4520" || item.seller_company_id == '5105' || item.seller_company_id == "375") && (
                      <div className='' style={{fontSize: '12px', marginTop: '10px', background: '#d9ecff', padding: '2px 10px', borderRadius: '10px', fontWeight: 'normal', display: 'inline-block'}}>
                        <span className='' style={{marginRight: '3px'}}><FaRegEnvelope /> </span>
                        {item.buyer_work_email && (
                          <span>
                            {item.buyer_work_email}
                          </span>
                        )}

                        {!item.buyer_work_email && (
                          <span>
                            {item.buyer_email}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </td>

                <td className='dashboarddemo__company-info'>
                  <div className='dashboarddemo__company-info-items'>
                    <div className='dashboarddemo__company-info-item'>
                      <div style={{fontSize: '12px'}}>
                        <span className='dashboarddemo__company-info-label'>Applied on: </span>
                        <span>{new Date(item.date_applied).toLocaleDateString()}</span>
                      </div>

                      <div>
                        {isExpiring(new Date(item.date_applied), new Date(item.last_date_to_approve), item)}
                      </div>

                      {item.in_review === true && (
                        <div className='dashboarddemo__very-interested' style={{cursor: 'pointer'}} onClick={() => setShowInReview(true)}>
                          <span style={{verticalAlign:'middle'}}><FaSearch /></span>
                          <span className='dashboarddemo__interest-label'>In Review</span>
                        </div>
                      )}
                    </div>
                  </div>
                </td>

                <td>
                    {item.demo_interest === 'Very interested' && (
                        <div className='dashboarddemo__very-interested'>
                          <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                          <span className='dashboarddemo__interest-label'>Very interested</span></div>
                      )}
                      
                      {item.demo_interest === 'Somewhat interested' && (
                        <div className='dashboarddemo__somewhat-interested'>
                        <span style={{verticalAlign:'middle'}}><FaFireAlt /></span>
                        <span className='dashboarddemo__interest-label'>Moderate</span></div>
                      )}

                      {/* {item.demo_interest === 'Just researching' && (
                        <span className='dashboarddemo__just-researching'>Researching</span>
                      )} */}
                    </td>

                    <td className='dashboarddemo__actions'> 
                      <div style={{marginBottom: '10px'}}>
                        {loading === true && item.demo_id == selectedId && (
                          <Spinner />
                        )}
                        {loading === false && (
                          <div
                            className='btn'
                            onClick={() => handleReviewClick(item)}>
                            <span style={{ verticalAlign: 'middle' }}>
                              Review
                            </span>
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div style={{position: 'relative'}}>
                        <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                          <FaEllipsisV />
                        </span>

                      {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                        <FadeIn delay={100} duration={100}>
                      <div className='dashboard__submenu'>
                        <div className='dashboard__submenu-item' onClick={() => handleReviewClick(item)}>Review</div>

                        {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                          <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                        )}

                        <div onClick={() => handleReassignClick(item)} className='dashboard__submenu-item'>Reassign</div>

                        <div onClick={() => handleRescheduleClick(item)} className='dashboard__submenu-item'>Request Reschedule</div>

                        <div onClick={() => handleDecline(item, item.demo_id)} className='dashboard__submenu-item'>Not interested</div>
                      </div>
                      </FadeIn>
                      )}
                    </div>
                  </td>
              </tr>
          ))}
          </table>
          </div>
    )}
          </div>

          {showDeclinedPopup && (
            <div>
              <SellerDecline
                showDeclinedPopup={showDeclinedPopup}
                setShowDeclinedPopup={setShowDeclinedPopup}
                role='Seller'
                companyName={currentDemo.company_name}
                sellerDeclinedReasons={sellerDeclinedReasons}
                currentDemo={currentDemo}
                setCurrentDemo={setCurrentDemo}
                demos={demos}
                setDemos={setDemos}
                source='Decline'
              />
              
              {/* <DeclinedPopup
                showDeclinedPopup={showDeclinedPopup}
                setShowDeclinedPopup={setShowDeclinedPopup}
                role='Seller'
                companyName={currentDemo.company_name}
                sellerDeclinedReasons={sellerDeclinedReasons}
                currentDemo={currentDemo}
                setCurrentDemo={setCurrentDemo}
                demos={demos}
                setDemos={setDemos}
                source='Decline'
              /> */}
            </div>
          )}

        {showReassign===true && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowReassign(false)}>
                <FaTimes />
              </div>
            
              <ReassignDemo demo_id={currentDemo.demo_id} demos={demos} setDemos={setDemos} />

              <div className='center'>
                <span className='link link--skip' onClick={() => setShowReassign(false)}>Close</span>
              </div>
            </div>
          </>
        )}

        {showSnooze && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowSnooze(false)}>
                <FaTimes />
              </div>

              <h2 className='modal__h2'><span style={{verticalAlign: "middle"}}><FaRegBell /></span> <span style={{verticalAlign: "middle"}}>Set a reminder</span></h2>

              <p className='mt-10 mb-10' style={{ fontSize: "14px"}}>
                If this demo is not a fit at this time, you can set it aside for now and we will remind you at a future date.
                <br/>
                <br/>
                Remind me in:
              </p>

              <div>
                <div>
                  <ul>
                    <li>
                      <input type="radio" value={7} name='reminder' id="rb1" onChange={() => setReminderDays(7)} /> <label htmlFor='rb1'>1 week</label>
                    </li>

                    <li>
                      <input type="radio" value={30} name='reminder' id="rb2" onChange={() => setReminderDays(30)} /> <label htmlFor='rb2'>1 month</label>
                    </li>

                    <li>
                      <input type="radio" value={60} name='reminder' id="rb4" onChange={() => setReminderDays(60)} /> <label htmlFor='rb4'>2 months</label>
                    </li>

                    <li>
                      <input type="radio" value={90} name='reminder' id="rb3" onChange={() => setReminderDays(90)} /> <label htmlFor='rb3'>3 months</label>
                    </li>
                  </ul>
                </div>

                <div className='mt-30'>
                  <button className='btn btn--primary' onClick={() => handleSetReminder()}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {showSnoozeConfirm && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowSnoozeConfirm(false)}>
                <FaTimes />
              </div>

              <div>
                Success! We will notify you again about this demo on: <strong>{reminderDate}</strong>
              </div>

              <div className='center' style={{marginTop: "30px"}}>
                <button className='btn btn--primary' onClick={() => setShowSnoozeConfirm(false)}>Close</button>
              </div>
            </div>
          </>
        )}

        {showExpiresModal && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowExpiresModal(false)}>
                <FaTimes />
              </div>

              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className='dashboarddemo__company-logo' style={{borderRadius: '5px'}}>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + selectedDemo.logo} />
                  </div>

                  <div className='dashboarddemo__company-name' style={{marginLeft: '10px'}}>
                    <span className=''>
                      {selectedDemo.job_title && selectedDemo.job_title.length > 0 && (
                        <span>{selectedDemo.job_title}</span>
                      )}
                      
                      {!selectedDemo.job_title && (
                        <span>{selectedDemo.persona_type}</span>
                      )} at {selectedDemo.company_name}
                    </span>
                  </div>
                </div>

                <div style={{marginTop: '20px'}}>
                  The suggested times for this demo have expired. If you would still like to meet with this person, you will need to submit a reschedule request.
                </div>

                <div style={{marginTop: '20px'}} className='center'>
                  {rescheduling === false && showRescheduleConfirm === false && (
                  <button className='btn btn--primary' onClick={() => confirmReschedule()}>Request new times</button>
                  )}

                  {rescheduling === false && showRescheduleConfirm && (
                    <div style={{fontSize: '14px'}}>
                      We've notified the party and will email you when new times have been sent.
                    </div>
                  )}

                  {rescheduling && (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {showInReview && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <div className='modal__close' onClick={() => setShowInReview(false)}>
                <img src={close} />
              </div>

              <div>
                This demo application is currently undergoing secondary review for accuracy. Once approved it will be available for you to book.
              </div>
            </div>
          </>
        )}

      {showQualifierAnswers && (
        <>
          <div className='overlay'></div>
          <LeadCard 
          demo_id={currentDemo.demo_id}
          currentDemo={currentDemo} 
          setShowQualifierAnswers={setShowQualifierAnswers} 
          demos={demos} 
          setDemos={setDemos} 
          hasPaymentMethod={hasPaymentMethod}
          payment_type={userCompany.payment_type} 
          refreshRemaining={reloadRemaining} />
        </>
      )}

      {showBuyerInvite && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close'>
              <FaTimes />
            </div>

            <div className='modal__h2'>
              Send a demo invite
            </div>

            <div className='mt-20'>
              Send a friendly demo invite to this user to see if they are interested in your product/solution.
            </div>

            <div className='mt-20 center'>
              <button className='btn btn--cta btn--book'><FaPaperPlane /> Send</button>
            </div>
          </div>
        </>
      )}

{showReschedule && (
        <>
          <FadeIn delay={150} duration={200}>
            <div className='overlay'></div>

            <div className='modal'>
              <div>
                <div>
                  <div className='dashboarddemo__company-logo--small'>
                    <img
                      src={
                        imageUrl +
                        'tr:di-@@companylogos@@default-buyer.png/' +
                        currentDemo.logo
                      }
                    />
                  </div>
                  <div>
                      <strong>{currentDemo.company_name}</strong>
                  </div>
                  </div>
                <div>
                  {new Date(currentDemo.demo_date).toLocaleDateString()}{' '}{new Date(currentDemo.demo_date).toLocaleTimeString()}
                </div>
              </div>

              <div className='modal__body-small mt-10'>
                Are you sure you want to reschedule this demo? The original date
                will no longer be valid and the other participant will be
                required to submit new available times.
              </div>

              <div className='center mt-10'>
                <button
                  className='btn btn--primary btn--small'
                  onClick={() => confirmReschedule()}>
                  Confirm
                </button>
              </div>

              <div className='modal__close' onClick={() => setShowReschedule(false)}>
                <FaTimes />
              </div>
            </div>
          </FadeIn>
        </>
      )}
      </>
  )
}

  export default  SellerDemosAvailable;