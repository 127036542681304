import './CategorySelection.css';
import {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import { getSignUpCategories, searchCategories, addCategories, addUserInterest, removeUserInterest } from '../../utils/api';
import React from "react";
import classNames from "classnames";
import Spinner from '../../components/layout/Spinner';
import {Helmet} from "react-helmet";

const CategorySelection = () => {

    const search = useLocation().search;
    const [token, setToken] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategories2, setSelectedCategories2] = useState([]);
    const [query, setQuery] = useState('');
    const [show, setShow] = useState(false);
    const [results, setResults] = useState([]);

    useEffect(() => {
      async function loadCategories(){
        let _res = await getSignUpCategories();

        if (_res.success){
          setCategories(_res.data);
        }
      }

      loadCategories();
    }, []);

    async function handleClick(category_id){
      // category selection
      if (selectedCategories.includes(category_id)){
        let _array = selectedCategories.filter(item => item != category_id);
        setSelectedCategories(_array);
        await removeUserInterest(category_id, "High");
      }

      else{
        setSelectedCategories([...selectedCategories, category_id]);
        await addUserInterest(category_id, "High");
      }
    }

    async function handleClick2(category_id, name){
      // category selection
      let found = selectedCategories2.filter(item => item.category_id === category_id).length > 0;

      if (!found){
          selectedCategories2.push({category_id, name});
          await addUserInterest(category_id, "High");
      }

      else{
        // remove from list
        let _array = selectedCategories2.filter(item => item.category_id !== category_id);
        setSelectedCategories2(_array);
      }

      setQuery('');
      setResults([]);
    }

    async function handleClick3(category_id){
      let _array = selectedCategories2.filter(item => item.category_id !== category_id);
      setSelectedCategories2(_array);

      await removeUserInterest(category_id, "High");
    }

    async function handleChange(e){
      setQuery(e.target.value);
    }

    async function handleRemove(category_id){

    }

    useEffect(() => {
      //  console.log('companyid', companyId);
        categorySearch(query);
    }, [query]);

    async function categorySearch(query){
        let data = await searchCategories(query);
        if (data.data){
            setResults(data.data);
            setShow(true);
        }
    }

    useEffect(() => {
      console.log(selectedCategories);
    }, [selectedCategories]);

    return (
        <div className="">
          <div className="card createaccount categoryselection">
            {/* <div className="createaccount__right">
              <div className="createaccount__right-header">To get started please search your category or select at least 3 software categories that interest you below</div>

              <div className="createaccount__right-subheader">
                This will help LeadrPro generate specific search results for you!
              </div>
            </div> */}

            <div className="createaccount__left">
              <h1>Category select</h1>

              <p>
              To get started please search your category or select at least 3 software categories that interest you below.
              </p>

              <div className='form__group' style={{position: "relative"}}>
                <input autoComplete="off" className="input completeprofileseller__input-box"
                            value={query} onChange={(e) => handleChange(e)}
                            type='text'
                            placeholder='Choose from the list'
                        />

                  {selectedCategories2.map((item, index) => (
                      <span className='selected categoryselection__category' onClick={() => handleClick3(item.category_id)}>
                        {item.name}
                      </span>
                    ))
                    }
                        
                  {results.length > 0 && show === true && (
                  <div className="companyprofile__category-results" style={{width: "100%", zIndex: "2"}} >

                  <div style={{ marginTop: "20px" }}>        
                      {results.map((item) => (
                          <div className="companyprofile__category-item"
                                onClick={() => handleClick2(item.category_id, item.name)}
                          >
                              {item.name}
                          </div>
                      ))} 
                  </div>
                  </div>
              )}
        </div>


            <div className='categoryselection__categories'>              
              {categories.map((item, index) => (
                <span className={`${selectedCategories.includes(item.category_id) ? "selected" :""} categoryselection__category`} onClick={() => handleClick(item.category_id)}>
                  {item.name}
                </span>
              ))
              }
            </div>

            <div className='form__group center'>
              <a className="btn btn--primary" href="/dashboard/buyer">Submit and finish</a>
            </div>
          </div>
          </div>
        </div>
    )
}

export default CategorySelection;
