import "./ReviewSubmitted.css";
import { useEffect, useState } from "react";
import { addReview, addReviewCriteria, getCompaniesReview } from "../../../utils/api"
import { getCurrentUser } from "../../../services/auth.service";

function ReviewSubmitted ({loggedIn, setLoggedIn, user, setUser, userInfo, form, companySelected, setCompanySelected, setStage}) {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const imageUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const submitReview = async () => {
      const user = await getCurrentUser();
      setUser(user)
      if(!user){
        const dataToSubmit = {
          company_id: companySelected["company_id"],
          email: userInfo["email"],
          first_name: userInfo["first_name"],
          last_name: userInfo["last_name"],
          company_name: companySelected["name"],
          persona_type_id: userInfo["persona_type"],
          job_title: userInfo["job_title"],
          department: userInfo["department"],
          industry_id: userInfo["industry"],
          seniority_id: userInfo["seniority"],
          company_size_id: userInfo["company_size"],
          review: form["review"],
          price: form["pricing"],
          recommend: form["recommend"]
        }
        let response = await addReview(dataToSubmit)
        if(response.success){
        const review_id = response.data.company_review_id;
        await addReviewCriteria({company_review_id: review_id, review_criteria_id: 1, value: form["overall_quality"]});
        await addReviewCriteria({company_review_id: review_id, review_criteria_id: 2, value: form["ease_of_use"]})
        await addReviewCriteria({company_review_id: review_id, review_criteria_id: 3, value: form["service"]})
        }
      } else {
        const dataToSubmit = {
          registered_user_id: user.registered_user_id,
          company_id: companySelected["company_id"],
          review: form["review"],
          price: form["pricing"],
          recommend: form["recommend"]
        }
        let response = await addReview(dataToSubmit);
        if(response.success){
          const review_id = response.data.company_review_id;
          await addReviewCriteria({company_review_id: review_id, review_criteria_id: 1, value: form["overall_quality"]});
          await addReviewCriteria({company_review_id: review_id, review_criteria_id: 2, value: form["ease_of_use"]})
          await addReviewCriteria({company_review_id: review_id, review_criteria_id: 3, value: form["service"]})
          }
      }
    }
    const loadCompanies = async () => {
      const page = Math.floor(Math.random() * (20 - 0 + 1)) + 0;
      // console.log(page, "this is the page number")
      try {
        setLoading(true)
        const { data } = await getCompaniesReview(page, "");
        setCompanies(data.slice(0,6))
      } catch (err) {
        console.log("ERR MESSAGE: ", err);
      } finally {
        setLoading(false);
      }
    }
    submitReview();
    loadCompanies();
  }, [])

  const handleCLick = (company) => {
    if(user){
      setCompanySelected(company)
      return setStage(3)
    }
    // console.log("card clicked");
    // console.log(company);
    // console.log(userInfo)
    setCompanySelected(company)
    setLoggedIn(true);
    setStage(3)
  }

  const renderCompanies = (company, index) =>{
    return (
      <a className="review__submitted-card-anchor" key={index} onClick={() => handleCLick(company)} >
        <div className="review__submitted-company-card" >
          <img src={imageUrl + "/assets/logos/" + company.logo} alt=""/>
          <p>{company.name}</p>
        </div>
      </a>
    )
  }

  const continueBrowsing = () => {
    setLoggedIn(true);
    setStage(1)
  }

  return (
    <div className="review__submitted-main-containter">
      <div className="review__submitted-headers">
        <h3>
          Your review has been submitted!
        </h3>
        <h5>Please allow up to 24 hours for your review to be approved and published. Once approved, your Gift Card will be issued and a confirmation will be sent.</h5>
      </div>

      <div className="review__submitted-cards-container">
        <div><p>Please review these companies!</p></div>
        {loading && 
          <div className="review__submitted-loading"><span>Loading Page...</span></div>
        }
        <div className="review__submitted-company-list">{companies.map(renderCompanies)}</div>
      </div>
      <button className="btn-action review__submitted-continue-btn" onClick={continueBrowsing}>Continue Browsing</button>
    </div>
  )
}

export default ReviewSubmitted