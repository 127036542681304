import './CompanyEdit.css';
import {useEffect, useState} from 'react';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'
import { getUserCompany, getUserCompanyCategories, getCompanyCustomerProfileCategories, updateCompany, updateCompanyCategory, searchCompanyCategories,updateQualifierQuestion, addCompanyMedia, getCompanyMedia, removeCompanyMedia, addCompanyCategory, hRefreshToken, refreshToken, getCompanyPricing, deleteCompanyPricing, updateCompanyPricing, createCompanyPricing, getUserCompanyFeatures, addCompanyFeature, removeCompanyFeature, companyFeatureMoveUp, companyFeatureMoveDown } from '../../utils/api';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import FadeIn from '../../utils/fadein';
import {Helmet} from "react-helmet";
import { refresh, isBuyer, isSeller } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import { FaAsterisk, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp, FaRegTrashAlt, FaTimes } from 'react-icons/fa';

const CompanyEdit = () => {
  const [company, setCompany] = useState({});
  const [categories, setCategories] = useState([]);
  const [categories2, setCategories2] = useState([]);
  const [newDescription, setNewDescription] =useState('');
  const [pain, setPain] = useState('');
  const [icp, setIcp] = useState('');
  const [newIcp, setNewIcp] = useState('');
  const [unique, setUnique] = useState('');
  const [newPain, setNewPain] = useState('');
  const [newUnique, setNewUnique] = useState('');
  const [questions, setQuestions] = useState([]);
  const [logo, setLogo] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showQuestionEdit, setShowQuestionEdit] = useState(false);
  const [categoryQuery, setCategoryQuery] = useState('');
  const [categoryResults, setCategoryResults] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [media, setMedia] = useState([]);
  const [hasCompany, setHasCompany] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const [isSeller, setIsSeller] = useState(false);
  const [hasSellerRole, setHasSellerRole] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const thumbnailUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companythumbnails/';
  const [fileName, setFileName] = useState('');
  const [fileName2, setFileName2] = useState('');
  const [companyPricing, setCompanyPricing] = useState([]);
  const [pricingLoaded, setPricingLoaded] = useState(false);
  const [showEditingPrice, setShowEditingPrice] = useState(false);
  const [showCreatingPrice, setShowCreatingPrice] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [planName, setPlanName] = useState('');
  const [planDescription, setPlanDescription] = useState('');
  const [planPrice, setPlanPrice] = useState('');
  const [planPerUnit, setPlanPerUnit] = useState('');
  const [submittingPrice, setSubmittingPrice] = useState(false);
  const [features, setFeatures] = useState([]);
  const [featureText, setFeatureText] = useState('');
  const [tagline, setTagline] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function getCompany(){
      // return the current users company information
      let result = await getUserCompany();
      if (result.success){
        let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions, company_id, thumbnail_image} = result.data;
        setCompany(result.data);
        setQuestions(questions);
        //setIsSeller(result.data.is_seller);
        setHasSellerRole(result.data.has_seller_role);
        setTagline(result.data.tagline);

        if (thumbnail_image){
          setThumbnail(thumbnail_image);
        }

        if (description)
          setNewDescription(description);

        if (logo){
          setLogo(logo);
          setFileName(logo);
        }

        if (pain_point){
          setPain(pain_point);
          setNewPain(pain_point);
        }

        if (unique_value_proposition){
          setUnique(unique_value_proposition);
          setNewUnique(unique_value_proposition);
        }

        if (ideal_customer_profile){
          setIcp(ideal_customer_profile);
          setNewIcp(ideal_customer_profile);
        }

        setLoaded(true);
        setHasCompany(true);
    }
    else if (result.status === 403){
      let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let result = await getUserCompany();
          if (result.success){
            let {name, logo, description, pain_point, unique_value_proposition, ideal_customer_profile, questions, company_id, thumbnail_image} = result.data;
            setCompany(result.data);
            setQuestions(questions);
            //setIsSeller(result.data.is_seller);
            setHasSellerRole(result.data.has_seller_role);
            setTagline(result.data.tagline);

            if (thumbnail_image){
              setThumbnail(thumbnail_image);
            }
    
            if (description)
              setNewDescription(description);
    
            if (logo){
              setLogo(logo);
              setFileName(logo);
            }
    
            if (pain_point){
              setPain(pain_point);
              setNewPain(pain_point);
            }
    
            if (unique_value_proposition){
              setUnique(unique_value_proposition);
              setNewUnique(unique_value_proposition);
            }
    
            if (ideal_customer_profile){
              setIcp(ideal_customer_profile);
              setNewIcp(ideal_customer_profile);
            }

            setHasCompany(true);
            setLoaded(true);
        }

        else{
          setHasCompany(false);
          setLoaded(true);
        }
        }

        else{
          // not refreshed
        }
    }

    else{
      setHasCompany(false);
      setLoaded(true);
    }
  }

  async function loadPricing(){
    let res = await getCompanyPricing();

    if (res.success){
      setCompanyPricing(res.data);
      setPricingLoaded(true);
    }

    else if (res.status == 403){
      await refresh();
      let res = await getCompanyPricing();

      if (res.success){
        setCompanyPricing(res.data);
        setPricingLoaded(true);
      }
    }
  }

  async function loadFeatures(){
    let res = await getUserCompanyFeatures();

    if (res.success){
      setFeatures(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getUserCompanyFeatures();

      if (res.success){
        setFeatures(res.data);
      }
    }
  }

    async function loadMedia(){
      let res = await getCompanyMedia();

      if (res.success){
        setMedia(res.data);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getCompanyMedia();

        if (res.success){
          setMedia(res.data);
        }
      }
    }

    getCompany();
    loadMedia();
    loadPricing();
    loadFeatures();
  }, []);

  async function loadPricing(){
    let res = await getCompanyPricing();

    if (res.success){
      setCompanyPricing(res.data);
      setPricingLoaded(true);
    }

    else if (res.status == 403){
      await refresh();
      let res = await getCompanyPricing();

      if (res.success){
        setCompanyPricing(res.data);
        setPricingLoaded(true);
      }
    }
  }

  useEffect(() => {
    async function loadCategories(){
      let result = await getUserCompanyCategories();

      if (result.success){
        setCategories(result.data);
      }
    }

    async function loadCustomerProfile(){
      let result = await getCompanyCustomerProfileCategories();

      if (result.success){
        setCategories2(result.data);
      }
    }

    loadCustomerProfile();
    loadCategories();
  }, []);

  async function handleCategoryChange(e){
    setCategoryResults([]);
    setCategoryQuery(e.target.value);
    await categorySearch(e.target.value);
  }

  async function categorySearch(query){
    if (query.length > 0){
      setShowCategoryDropdown(true);
      let data = await searchCompanyCategories(query);

      if (data.data){
          setCategoryResults(data.data);
      }
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function selectCategory(category_id, name){
    let res = await updateCompanyCategory(category_id);

    if (res.success){
    setCategories([...categories, {category_id, name}]);
    setCategoryQuery('');
    setShowCategoryDropdown(false);
    }
  }

// adding a new category
async function handleCategorySubmit(e){
  if (categoryQuery.length > 0){
    let res = await addCompanyCategory(categoryQuery);

    if (res.success){
      let category_id = res.data;
      loadSaveBanner();
      setCategories([...categories, {category_id, name:categoryQuery}]);
      setCategoryQuery('');
      setShowCategoryDropdown(false);
    }
  }
}

async function handleRemove(id){
  let res = await updateCompanyCategory(id);

  if (res.success){
    let arr = categories.filter(item => item.category_id !== id);
    setCategories(arr);
  }
}

  async function handleSaveCompanyPain(){
    let res = await updateCompany({pain_point: newPain});

    if (res.success){
      setCompany({...company, pain_point: newPain});
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({pain_point: newPain});

      if (res.success){
        setCompany({...company, pain_point: newPain});
        loadSaveBanner();
      }
    }
  }

  async function handleSaveCompanyDescription(){
    let res = await updateCompany({description: newDescription});

    if (res.success){
      setCompany({...company, description: newDescription});
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({description: newDescription});

      if (res.success){
        setCompany({...company, description: newDescription});
        loadSaveBanner();
      }
    }
  }

  async function handleSaveCompanyTagline(){
    let res = await updateCompany({tagline: tagline});

    if (res.success){
      setCompany({...company, tagline: tagline});
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({tagline: tagline});

      if (res.success){
        setCompany({...company, tagline: tagline});
        loadSaveBanner();
      }
    }
  }

  async function handleSaveValueProp(){
    let res = await updateCompany({unique_value_proposition: newUnique});

    if (res.success){
      setUnique(newUnique);
      setCompany({...company, unique_value_proposition: newUnique});

      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await updateCompany({unique_value_proposition: newUnique});

      if (res.success){
        setUnique(newUnique);
        setCompany({...company, unique_value_proposition: newUnique});

        loadSaveBanner();
      }
    }
  }

  async function handleSaveIcp(){
    let res = await updateCompany({ideal_customer_profile: newIcp});

    if (res.success){
      //setShowICP(false);
      setCompany({...company, ideal_customer_profile: newIcp});
      setIcp(newIcp);
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({ideal_customer_profile: newIcp});

      if (res.success){
        //setShowICP(false);
        setCompany({...company, ideal_customer_profile: newIcp});
        setIcp(newIcp);
        loadSaveBanner();
      }
    }
  }

  useEffect(() => {
    // file has been changed
    setLogo(fileName);
    handleSaveLogo();
    
  }, [fileName]);

  useEffect(() => {
    setThumbnail(fileName2);
    handleSaveThumbnail();
  }, [fileName2]);

  async function handleSaveLogo(){

    if (fileName.length > 0){
    let res = await updateCompany({logo: fileName});

    if (res.success){
     // loadSaveBanner();
     await hRefreshToken();
     //window.location = window.location;
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({logo: fileName});

      if (res.success){
        //loadSaveBanner();
        await hRefreshToken();
        //window.location = window.location;
      }
    }
  }
  }

  async function handleSaveThumbnail(){

    if (fileName2.length > 0){
    let res = await updateCompany({thumbnail_image: fileName2});

    if (res.success){
     // loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateCompany({thumbnail_image: fileName2});

      if (res.success){
        //loadSaveBanner();
      }
    }
  }
  }

  function onError(){
    console.log('error');
  }

  async function onSuccess(e){
    setFileName(e.name);
  }

  function onThumbnailSuccess(e){
    setFileName2(e.name);
  }

  async function handleSaveScreenshot(name){
    let res = await addCompanyMedia('Image', name);

    if (res.success){
      loadMedia();
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await addCompanyMedia('Image', name);

      if (res.success){
        loadMedia();
        loadSaveBanner();
      }
    }
  }

  async function onScreenshotSuccess(e){
    if (e.name && e.name.length > 0){
      await handleSaveScreenshot(e.name);
    }
  }

  function handleQuestionChange(value){
    //let q = questions.find(item => item.qualifier_question_id === qualifier_question_id);
    setCurrentQuestion({...currentQuestion, question: value})
  }

  async function updateQuestion(){
    await updateQualifierQuestion(currentQuestion.qualifier_question_id, currentQuestion.question);
    setShowQuestionEdit(false);

    let obj = {qualifier_question_id: currentQuestion.qualifier_question_id, question: currentQuestion.question};
    let _tmp = questions.filter(item => item.qualifier_question_id !== currentQuestion.qualifier_question_id);
    _tmp.push(obj);
    setQuestions(_tmp);
  }

  async function loadMedia(){
    let res = await getCompanyMedia();

    if (res.success){
      setMedia(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getCompanyMedia();

      if (res.success){
        setMedia(res.data);
      }
    }
  }

  async function handleRemoveMedia(company_media_id){
    let res = await removeCompanyMedia(company_media_id);

    if (res.success){
      loadMedia();
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await removeCompanyMedia(company_media_id);

      if (res.success){
        loadMedia();
        loadSaveBanner();
      }
    }
  }

  async function handleDeletePricing(id){
    let res = await deleteCompanyPricing(id);

    if (res.success){
      loadPricing();
      loadSaveBanner();
    }

    else if  (res.status == 403){
      await refresh();

      let res = await deleteCompanyPricing(id);

      if (res.success){
        loadPricing();
        loadSaveBanner();
      }
    }
  }

  async function handleEditPrice(id, item){
    setSelectedPriceId(id);
    setSelectedPrice(item);
    setPlanName(item.name);
    setPlanDescription(item.description);
    setPlanPrice(item.price);
    setPlanPerUnit(item.unit);
    setShowEditingPrice(true);
  }

  async function updatePricingPlan(){
    let res = await updateCompanyPricing(selectedPriceId, planName, planDescription, planPrice, planPerUnit);

    if(res.success){
      setShowEditingPrice(false);
      loadPricing();
      loadSaveBanner();
    }

    else if (res.status == 403){
      await refresh();

      let res = await updateCompanyPricing(selectedPriceId, planName, planDescription, planPrice, planPerUnit);

      if(res.success){
        setShowEditingPrice(false);
        loadPricing();
        loadSaveBanner();
      }
    }
  }

  async function createPricingPlan(){
    if (planName.length === 0 || planPrice.length === 0){
      alert("Must enter name and price to save");
      return;
    }

    setSubmittingPrice(true);
    let res = await createCompanyPricing(planName, planDescription, planPrice, planPerUnit);

    if (res.success){
      setShowCreatingPrice(false);
      setSubmittingPrice(false);
      loadPricing();
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await createCompanyPricing(planName, planDescription, planPrice, planPerUnit);

      if (res.success){
        setShowCreatingPrice(false);
        setSubmittingPrice(false);
        loadPricing();
        loadSaveBanner();
      }
    }
  }

  async function handleAddPricing(){
    setShowCreatingPrice(true);
    setPlanName('');
    setPlanDescription('');
    setPlanPrice('');
    setPlanPerUnit('');
  }

  async function loadFeatures(){
    let res = await getUserCompanyFeatures();

    if (res.success){
      setFeatures(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getUserCompanyFeatures();

      if (res.success){
        setFeatures(res.data);
      }
    }
  }

  async function handleAddFeature(){
    let res = await addCompanyFeature(featureText);

    if(res.success){
      setFeatureText('');
      loadFeatures();
    }

    else if (res.status === 403){
      await refresh();

      let res = await addCompanyFeature(featureText);

      if(res.success){
        setFeatureText('');
        loadFeatures();
      }
    }
  }

  async function handleRemoveFeature(id){
    let res = await removeCompanyFeature(id);

    if (res.success){
      loadFeatures();
    }

    else if(res.status == 403){
      await refresh();

      let res = await removeCompanyFeature(id);

      if (res.success){
        loadFeatures();
      }
    }
  }

  async function moveUpFeature(company_feature_id, index){
    let prev = features[index-1];
    let res = await companyFeatureMoveUp(company_feature_id, prev.company_feature_id, index);

    if (res.success){
     //await getCompany();
     loadFeatures();
     loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();

      let res = await companyFeatureMoveUp(company_feature_id, prev.company_feature_id, index);

      if (res.success){
        loadFeatures();
        loadSaveBanner();
      }
    }
  }

  async function moveDownFeature(company_feature_id, index){
    let next = features[index+1];
    let res = await companyFeatureMoveDown(company_feature_id, next.company_feature_id, index);

    if (res.success){
     loadFeatures();
     loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await companyFeatureMoveDown(company_feature_id, next.company_feature_id, index);

      if (res.success){
      loadFeatures();
      loadSaveBanner();
      }
    }
  }

  return (
    <div className="companyedit">
      <Helmet>
        <title>LeadrPro - Edit Company Profile</title>
        <meta name="description" content="" />
      </Helmet>
      
      <div className=""><span className='btn' onClick={() => navigate(-1)}>Back</span> 
      
      {isSeller() && (
      <Link target="_blank" style={{marginLeft: '10px'}} className="btn btn--primary" to={`/company/${company.company_id}`}>View public profile</Link>
      )}
      </div>
      <div className="account__header">Configure your company profile</div>

      <p className='account__description'>
        Your company profile is the face of your company.
        Keep your companies public profile information up to date.
      </p>

      {loaded === false && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {hasCompany === true && loaded === true && (
      <div className='companyedit__form' id='top'>
        {isSeller() && (
        <div className='companyedit__tabs'>
          <a href='#thumbnail' className='companyedit__tab'>Thumbnail</a>
          <a href='#description' className='companyedit__tab'>Description</a>
          <a href='#categories' className='companyedit__tab'>Categories</a>
          <a href='#pain' className='companyedit__tab'>Pain point</a>
          <a href='#icp' className='companyedit__tab'>Ideal customer profile</a>
          <a href='#unique' className='companyedit__tab'>Unique value proposition</a>
          <a href='#features' className='companyedit__tab'>Features</a>
          <a href='#screenshots' className='companyedit__tab'>Screenshots</a>
          <a href='#pricing' className='companyedit__tab'>Pricing</a>
        </div>
        )}

        <div style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic', marginBottom: '10px', color: 'tomato'}}><FaAsterisk /> Required fields</div>

        {isSeller() && (
        <div className='accountinfo__form-group'>
            <div className='profileinfo__form-group-title'>
              Company tagline
            </div>

            <p className='account__description'>
              Enter your companies elevator pitch. Keep it short, simple but eye-catching.
            </p>

            <div className='form-group'>
              <textarea className='input textarea' value={tagline} onChange={(e) => setTagline(e.target.value)}></textarea>
            </div>

            <div className="form__group">
              <button className="btn btn--primary" onClick={handleSaveCompanyTagline}>Save</button>
            </div>
          </div>
        )}
        
        <div className="accountinfo__form-group">
        <div className='profileinfo__form-group-title' style={{marginBottom: "10px"}}>Company logo      <span style={{fontSize: '12px', color: '#bbb', marginLeft: '10px'}}>Min. 100px x 100px</span>
          <span style={{fontSize: '10px', color: 'tomato', marginLeft: '10px'}}><FaAsterisk /></span>
        </div>

          <div className="companyedit__companyinfo">
            <div className="companyedit__logo">
              <div className="companyedit__logo-image">
              {logo !== '' && (
                <img src={imageUrl + logo} alt="logo found" />
              )}

              {logo === '' && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}

              </div>

              <div className='companyedit__upload'>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/companylogos"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
            </div>
          </div>
        </div>

        {isSeller() && (
        <div className='accountinfo__form-group' id='thumbnail'>
          <div className='profileinfo__form-group-title'>Thumbnail / header image <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>

          <div className="companyedit__thumbnail">
              <div className="companyedit__thumbnail-image">
                {thumbnail !== '' && (
                  <img src={thumbnailUrl + thumbnail} alt="logo found" />
                )}

                {thumbnail === '' && (
                  <div style={{padding: '10px 0px'}}><i>No image set...</i></div>
                )}
              </div>

              <div className='companyedit__upload'>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/companythumbnails"}
                  onError={onError} onSuccess={onThumbnailSuccess}
                />
                </IKContext>
              </div>
            </div>
        </div>
        )}

        <div className="accountinfo__form-group" id="description">
          <div className="companyedit__description">
            <div className="profileinfo__form-group-title">Company Description <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>
            
            <p className='account__description'>
              Briefly explain what your company does.
            </p>
            <div>
              <div className="form__group">
                <textarea placeholder="Add your companies description" value={newDescription} onChange={(e) => setNewDescription(e.target.value)}>{company.description}</textarea>
              </div>

              {((isSeller() && hasSellerRole) || !isSeller()) && (
              <div className="form__group">
                <button className="btn btn--primary" onClick={handleSaveCompanyDescription}>Save</button>
              </div>
              )}
            </div>
          </div>
        </div>

        {isSeller() && (
        <div className="accountinfo__form-group" id="categories">
          <div className="companyedit__categories">
            <div className="profileinfo__form-group-title">Company Categories</div>
          </div>

          <p className='account__description'>
            Select as many categories that apply to your particular business.
          </p>

          <div className="form__group">
            <div style={{position:"relative"}}>
            <div>
                <input type="text" name="category" autoComplete='off' id="txtCategory" value={categoryQuery} onChange={handleCategoryChange}  className="category-dropdown" placeholder="Start typing..." />

                  {categoryResults.length > 0 && showCategoryDropdown && (
                  <div className="searchfilter__search-results" style={{top: "60px"}}>
                      {categoryResults.map((item, index) => (
                          <div key={index} className="home__hero-category-results-item" onClick={() => selectCategory(item.category_id, item.name)}><span>{item.name}</span></div>
                      ))}
                  </div>
                  )}

                  {((isSeller() && hasSellerRole) || !isSeller()) && (
                  <div className="form__group">
                    <button className="btn btn--primary" onClick={handleCategorySubmit}>Save</button>
                  </div>
                  )}
                </div>
            </div>

            <div className="companyedit__categories">
            {categories !== undefined && categories.map((item, index) => (
              <div className="companyedit__category" key={index}>
                <span>{item.name}</span>

                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemove(item.category_id)}>
                <path d="M2.82812 9.32812L8.48498 3.67127" stroke="#BEBEBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2.82812 3.67188L8.48498 9.32873" stroke="#BEBEBE" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            ))}
            </div>
          </div>
        </div>
        )}

      {isSeller() && (
      <div className="accountinfo__form-group" id="pain">
        <div className="companyedit__name">
              <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>What pain are you solving?</div>

              <div>
                  <textarea className="input" value={newPain} onChange={(e) => setNewPain(e.target.value)} placeholder="Pain point"></textarea>

                  {((isSeller() && hasSellerRole) || !isSeller()) && (
                  <div className='profileinfo__btn'>
                    <button className="btn btn--primary" onClick={handleSaveCompanyPain}>Save</button>
                  </div>
                  )}
                </div>
            </div>
        </div>
      )}

        {isSeller() && (
        <div className="accountinfo__form-group" id="icp">
        <div className="companyedit__name">
            <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>What's your Ideal Customer Profile (ICP)?</div>

            <div>
              <textarea className="input" value={newIcp} onChange={(e) => setNewIcp(e.target.value)} placeholder="What is your Ideal Customer Profile?"></textarea>

              {((isSeller() && hasSellerRole) || !isSeller()) && (
              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveIcp}>Save</button>
              </div>
              )}
            </div>
            </div>
        </div>
        )}

        {isSeller() && (
        <div className="accountinfo__form-group" id="unique">
        <div className="companyedit__name">
            <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>What do you do that is better than others (unique value propositions)?</div>

            <div>
              <textarea className="input" value={newUnique} onChange={(e) => setNewUnique(e.target.value)} placeholder="List some of the unique value propositions that are better than others."></textarea>

              {((isSeller() && hasSellerRole) || !isSeller()) && (
              <div className='profileinfo__btn'>
                <button className="btn btn--primary" onClick={handleSaveValueProp}>Save</button>
              </div>
              )}
            </div>
            </div>
        </div>
        )}

        {isSeller() && (
        <div className='accountinfo__form-group' id='features'>
          <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>Features</div>

          <p className='account__description'>
            Highlight the main points that make your business stand out.
          </p>

          <div className='companyedit__features'>
            {features.length == 0 && (
              <div style={{marginBottom: '10px'}}><strong>No features found...</strong></div>
            )}
              {features.map((item, index) => (
                <div className='companyedit__feature'>
                  <div className='companyedit__feature-text'>{item.feature_text}</div>

                  <div className='companyedit__feature-btns'><button className='btn' onClick={() => handleRemoveFeature(item.company_feature_id)}><FaRegTrashAlt /></button>

                  {index < features.length - 1 && (
                    <button className='btn' onClick={() => moveDownFeature(item.company_feature_id, index)}><FaRegArrowAltCircleDown /></button>
                  )}

                  {index > 0 && (
                    <button className='btn' onClick={() => moveUpFeature(item.company_feature_id, index)}><FaRegArrowAltCircleUp /></button>
                  )}
                  </div>
                </div>
              ))}
          </div>

          <div>
            <input type='text' className='input' value={featureText} onChange={(e) => setFeatureText(e.target.value)} placeholder='Add feature...' />

            <div style={{marginTop: '20px'}}>
                <button className='btn' onClick={() => handleAddFeature()}>Add</button>
            </div>
          </div>
        </div>
        )}

        {isSeller() && (
        <div className='accountinfo__form-group' id='screenshots'>
        <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>Upload product screenshots</div>

        {media.length == 0 && (
          <div style={{margin: '10px 0px'}}>
            <strong>No images found...</strong>
          </div>
        )}

        <div className="companyedit__screenshot-upload">
            <div>
              <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
              <IKUpload
                fileName="logo.jpg"
                tags={["sample-tag1", "sample-tag2"]}
                customCoordinates={"10,10,10,10"}
                isPrivateFile={false}
                useUniqueFileName={true}
                responseFields={["tags"]}
                folder={"/companyscreenshots"}
                onError={onError} onSuccess={onScreenshotSuccess}
              />
              </IKContext>
            </div>
          </div>

          <div className='companyedit__screenshots'>
            {media.map(item => (
              <div className='companyedit__screenshot'>
                <img src={screenshotUrl + item.media_source} alt="logo found" />

                <div className='companyedit__screenshot-remove'>
                  <span className='link' onClick={() => handleRemoveMedia(item.company_media_id)}>Remove</span>
                </div>
              </div>
            ))}
          </div>
          </div>
        )}

        {isSeller() && (
          <div className='accountinfo__form-group' id='pricing'>
            <div className="profileinfo__form-group-title" style={{marginBottom: "10px"}}>Pricing / Packages</div>

            <div>
              <button className='btn' onClick={() => handleAddPricing()}>Add pricing / package</button>
            </div>

            {pricingLoaded == true && companyPricing.length > 0 && (
              <FadeIn delay={300} duration={300}>
              <div className='companyedit__pricing-list'>
                {companyPricing.sort((a, b) => {
                  return a.price - b.price
                }).map(item => (
                    <div className='companyedit__pricing-item'>
                      <div className='companyedit__pricing-item-name'>{item.name}</div>
                      <div className='companyedit__pricing-item-description'>{item.description}</div>
                      <div className='companyedit__pricing-item-price'>${item.price} <span className='companyedit__per-unit'>/ {item.unit}</span></div>

                      <div className='companyedit__pricing-item-btns'>
                        <button className='btn' onClick={()=> handleEditPrice(item.company_pricing_id, item)}>Edit</button>
                        <button className='btn' onClick={() => handleDeletePricing(item.company_pricing_id)}>Delete</button>
                      </div>
                    </div>
                ))}
              </div>
              </FadeIn>
            )}
          </div>
        )}

          <div className='center'>
              <a href='#top' className='link'>Back to the top</a>
          </div>
      </div>
      )}

      {loaded && hasCompany === false && (
        <>
          <div>
            <p>You have not added a company profile.</p>

            <p>
              <a href='/account/company' className="link">Add My Company</a>
            </p>
          </div>
        </>
      )}

      {showQuestionEdit && (
        <>
          <div className='overlay'></div>
          <div className='companyedit__modal modal'>
            <h3>Update qualifer question</h3>
            <br/><br/>

            <div>
              <input type="text" className='input' value={currentQuestion.question} onChange={(e) => handleQuestionChange(e.target.value)} />
            </div>

            <div className='center' style={{marginTop: "30px"}}>
              <button className="btn btn--primary" onClick={() => updateQuestion()}>Update</button>
              <span className='link link--cancel' onClick={() => setShowQuestionEdit(false)}>Cancel</span>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
        Profile updated successfully!
        </div>
      </FadeIn>
      )}

      {showEditingPrice && (
        <>
          <div className='overlay'></div>
          <div className='modal companyedit__modal-add-price'>
            <div className='modal__close' onClick={() => setShowEditingPrice(false)}><FaTimes /></div>

            <h2 className='modal__h2'>Edit plan</h2>

            <div>
              <div className='form-group'>
                <strong>Plan/package name</strong>
                <input type='text' className='input' value={planName} onChange={(e) => setPlanName(e.target.value)} placeholder='Name' />
              </div>

              <div className='form-group'>
                <strong>Plan/package description</strong>
                <textarea className='textarea' value={planDescription} onChange={(e) => setPlanDescription(e.target.value)} placeholder='Description'></textarea>
              </div>

              <div className='form-group'>
                <strong>Plan/package price</strong>
                <input type='text' placeholder='Price' value={planPrice} onChange={(e) => setPlanPrice(e.target.value)} className='input' />
              </div>

              <div className='form-group'>
              <strong>Plan/package per unit text {'(ex. $10 / per user)'}</strong>
                <input type='text' placeholder='Per unit text' onChange={(e) => setPlanPerUnit(e.target.value)} value={planPerUnit} className='input' />
              </div>

              <div className='form-group'>
                <button className='btn btn--save' onClick={() => updatePricingPlan()}>Save changes</button>
              </div>
            </div>
          </div>
        </>
      )}

      {showCreatingPrice && (
        <>
          <div className='overlay'></div>
          <div className='modal companyedit__modal-add-price'>
            <div className='modal__close' onClick={() => setShowCreatingPrice(false)}><FaTimes /></div>

            <h2 className='modal__h2'>Add pricing plan/package</h2>

            <p className='account__description'>
              This will be displayed in your companies public profile.
            </p>

            <div>
              <div className='form-group'>
                <strong>Plan/package name</strong>
                <input type='text' className='input' value={planName} onChange={(e) => setPlanName(e.target.value)} placeholder='Name' />
              </div>

              <div className='form-group'>
                <strong>Plan/package description</strong>
                <textarea className='textarea' value={planDescription} onChange={(e) => setPlanDescription(e.target.value)} placeholder='Description'></textarea>
              </div>

              <div className='form-group'>
                <strong>Plan/package price</strong>
                <input type='text' placeholder='Price' value={planPrice} onChange={(e) => setPlanPrice(e.target.value)} className='input' />
              </div>

              <div className='form-group'>
                <strong>Plan/package per unit text {'(ex. $10 / per user)'}</strong>
                <input type='text' placeholder='Per unit text' onChange={(e) => setPlanPerUnit(e.target.value)} value={planPerUnit} className='input' />
              </div>

              <div className='form-group center'>
                {submittingPrice == false && (
                <button className='btn btn--primary' onClick={() => createPricingPlan()}>Add plan/package</button>
                )}

                {submittingPrice && (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CompanyEdit;