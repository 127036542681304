import './PayoutInfo.css';
import {useEffect, useState} from 'react';
import { getPayoutTransactions, getUserPayoutMethods, removePayoutMethod, setPrimaryPayoutMethod } from '../../utils/api';
import {Helmet} from "react-helmet";

const PayoutInfo = ({userType}) => {
  const [userPayoutMethods, setUserPayoutMethods] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
      async function loadUserPayoutMethods(){
        let response = await getUserPayoutMethods();

        if(response.success){
          setUserPayoutMethods(response.data);
        }
      }

      async function loadTransactions(){
        let res = await getPayoutTransactions();
        if (res.success){
          setTransactions(res.data);
        }

        else{
            setTransactions([]);
        }
      }

      loadTransactions();
      loadUserPayoutMethods();

      document.title = "LeadrPro - Manage Payouts";
  }, []);

  async function handleRemovePayoutMethod(payout_method_id){
    try {
      let res = await removePayoutMethod(payout_method_id);

      if (res.success){
        let data = userPayoutMethods.filter(item => item.payout_method_id !== payout_method_id);
        setUserPayoutMethods(data);
      }
    } catch (error) {
      
    }
  }

  async function handleSetPrimaryPayoutMethod(payout_method_id){
    try {
      let res = await setPrimaryPayoutMethod(payout_method_id);

      if (res.success){
        //let data = userPayoutMethods.filter(item => item.payout_method_id !== payout_method_id);
        //setUserPayoutMethods(data);
       // let data = userPayoutMethods.filter(item => item.payout_method_id === payout_method_id)
       let response = await getUserPayoutMethods();

       if(response.success){
         setUserPayoutMethods(response.data);
       }
      }
    } catch (error) {
      
    }
  }

  return (
    <div className="payoutinfo">
      <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="account__back-link"><a href="/account/home">Back</a></div>
      <div className="account__header">Payout Info</div>

      <div className="account__description">
        Choose how you would like to get paid once you have completed demos.
      </div>

      <div className='accountinfo__form payoutinfo__form'>
        <div>
          <a href='payout/method' className="btn btn--primary" style={{padding: "15px"}}>Add payout method</a>
        </div>
      </div>

      <div className="payoutinfo__methods">
        {userPayoutMethods.map((item, index) => (
          <div className="" style={{display:"flex", alignItems: "center", marginBottom: "10px", justifyContent: "space-between"}} key={index}>
            <div className='payoutinfo__item'>
              <img className="payoutinfo__method-icon" src={"/assets/payoutmethods/" + item.icon_url} alt="" style={{marginTop: "0px"}} />
              <span style={{marginLeft: "10px"}}>{item.source}</span>
          </div>

          <div>
            {item.is_primary===false && (
            <span className="link" style={{marginLeft: "10px", marginRight: "10px"}} onClick={() => handleSetPrimaryPayoutMethod(item.payout_method_id)}>Set as primary</span>
            )}

            {item.is_primary===true && (
              <strong>Primary</strong>
            )}

            <span onClick={() => handleRemovePayoutMethod(item.payout_method_id)}><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0768 2.66602L0.910156 2.66602" stroke="#262626" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.99219 5.5L6.99219 8.5" stroke="#262626" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.99219 5.5L3.99219 8.5" stroke="#262626" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.41016 1H7.57682" stroke="#262626" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.24219 2.66602V10.5827C9.24219 10.6932 9.19829 10.7992 9.12015 10.8773C9.04201 10.9555 8.93603 10.9993 8.82552 10.9993H2.15885C2.04835 10.9993 1.94237 10.9555 1.86423 10.8773C1.78609 10.7992 1.74219 10.6932 1.74219 10.5827V2.66602" stroke="#262626" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>
          </div>
          </div>
        ))}
      </div>

      <div className="payoutinfo__transactions">
        <h2>Payout history</h2>

        <div className="payoutinfo__table">
          <table className="table">
            <thead>
            <tr>
              <th>Completed Date</th>
              <th>Company</th>
              <th>Earned</th>
              <th>Status</th>
            </tr>
            </thead>

            <tbody>
            {transactions && (
              transactions.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date_created).toLocaleDateString()}</td>
                <td>{item.company_name}</td>
                <td>${item.amount}</td>
                <td><span className={`payoutinfo__transaction--${item.status.toLowerCase()}`}>{item.status}</span></td>
              </tr>
            ))
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

  export default PayoutInfo;