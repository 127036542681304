import { useState, useEffect } from "react";
import './Webinars.css';
import { getRegisteredWebinars, getEventsWebinars } from '../../utils/api';
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import { getSeoName } from "../../utils/utils";
import { refresh } from "../../services/auth.service";
import { FaCalendarAlt, FaRegCalendarAlt, FaSlidersH } from "react-icons/fa";
import FadeIn from "../../utils/fadein";
import Spinner from "../../components/layout/Spinner";

function Webinars() {
    const [events, setEvents] = useState([]);
    const [myEvents, setMyEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [loadedRegistered, setLoadedRegistered] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      async function loadEvents(){
        let res = await getEventsWebinars();

        if (res.success){
          let _upcoming = res.data.filter(x => {
            let date = new Date();
            let event_date = new Date(x.start_date);

            return event_date >= date;
          });

          setEvents(_upcoming);

          let _past = res.data.filter(x => {
            let date = new Date();
            let event_date = new Date(x.start_date);

            return event_date < date;
          });

          setPastEvents(_past);
          setLoaded(true);
        }
      }

      async function loadRegisteredEvents(){
        let res = await getRegisteredWebinars();

        if (res.success){
          setMyEvents(res.data);
          setLoadedRegistered(true);
        }

        else if (res.status === 403){
          await refresh();

          let res = await getRegisteredWebinars();

          if (res.success){
            setMyEvents(res.data);
            setLoadedRegistered(true);
          }
        }
      }

        loadEvents();
        loadRegisteredEvents();
    }, []);

    return (
      <>
      <div className="manageevent">
        <ScrollToTop />

        <Helmet>
          <title>RSVP for our free business webinars</title>
          <meta name="description" content="Attend our free online webinars and hear from industry experts about their strategies and insights going into 2024." />
          <link rel="canonical" href={`https://www.leadrpro.com/webinars`} />
        </Helmet>

        <div className="manageevent__form">
          <h1>Upcoming Webinars</h1>

          <p className="myevents__description">
            Attend our free online webinars and hear from industry experts about their strategies and insights going into 2024.
          </p>

          <div className="eventdashboard__filter">
            <span className={`eventdashboard__filter-item${selectedTab ===1?' selected':''}`} onClick={() => setSelectedTab(1)}>Upcoming Webinars</span>
            <span className={`eventdashboard__filter-item${selectedTab ===2?' selected':''}`} onClick={() => setSelectedTab(2)}>Registered</span>

            <span className={`eventdashboard__filter-item${selectedTab ===3?' selected':''}`} onClick={() => setSelectedTab(3)}>Past Webinars</span>
          </div>

          {loaded === false && (
            <div className="webinars__placeholder">
              <Spinner />
            </div>
          )}

          {selectedTab === 1 && (
            <FadeIn delay={100} duration={100}>
              {events.length === 0 && loaded && (
                <div className="center"><i>No upcoming events found. Check back often.</i></div>
              )}

            <div className="myevents__signature-events">
            {events.map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail'><img src={x.image_url} alt='LeadrPro webinar thumbnail' /></div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail">
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" alt='leadrpro webinar thumbnail' />
                    </div>
                )}

                <div className='myevents__item-info'>
                  <div className='webinars__item-info-name'>
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>{x.name}</Link> 
                  {x.is_registered === true && (
                    <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                    <div className="webinars__list-item-date">
                      <span><FaRegCalendarAlt /></span> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )}

                  {/* {x.end_date && (
                    <div className="events__list-item-date">
                      <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )} */}

                <div style={{marginTop: '20px'}}>
                  <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>View Full Details</Link>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}
                </div>
                </div>
              </div>
            ))}
          </div>
          </FadeIn>
            )}

        {selectedTab === 2 && (
              <FadeIn delay={100} duration={100}>
                {myEvents.length === 0 && (
                  <div className='center'>
                    You have not registered for any events.
                  </div>
                )}
                <div className="myevents__signature-events">
            {myEvents.map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail'><img src={x.image_url} alt='LeadrPro webinar thumbnail' /></div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail">
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" alt='LeadrPro webinar thumbnail' />
                    </div>
                )}

                <div className='myevents__item-info'>
                  <div className='webinars__item-info-name'>

                  <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>{x.name}</Link>
                  
                  {x.is_registered === true && (
                  <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                    <div className="webinars__list-item-date">
                      <span><FaRegCalendarAlt /></span> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )}


                {/* {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div> */}

                {/* <div className="events__list-item-date">
                  <strong>Location:</strong> {x.city} {x.state}
                </div> */}

                <div style={{marginTop: '20px'}}>
                  <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>View Full Details</Link>

                  {x.is_host && (
                    <Link className="btn" to={`/manage-event/${x.event_id}`}><FaSlidersH /> Manage Event</Link>
                  )}

                  <a className='btn'
              href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Event:+${encodeURIComponent(
                x.name
              )}&dates=${new Date(x.start_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}/${new Date(x.end_date).toISOString().replace(/-|:|\.\d\d\d/g, '')}&details=Event+details:+https://www.leadrpro.com/event/${x.event_id}/${getSeoName(x.name)}&sf=true&output=xml&ctz=${
                x.timezone
              }`}
              target='_blank' rel='noreferrer'>
                  <FaCalendarAlt /> Add to calendar
            </a>
                </div>
                </div>
              </div>
            ))}
          </div>
          </FadeIn>
            )}

        {selectedTab === 3 && (
            <FadeIn delay={100} duration={100}>
              {pastEvents.length === 0 && (
                <div className="center"><i>No events found.</i></div>
              )}

            <div className="myevents__signature-events">
            {pastEvents.sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).map(x => (
              <div className="myevents__item">
                {x.image_url && (
                  <div className='myevents__item-thumbnail'><img src={x.image_url} alt='LeadrPro webinar thumbnail' /></div>
                )}

                {(x.image_url === null || x.image_url.length === 0) && (
                  <div className="myevents__item-thumbnail">
                    <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" alt='LeadrPro webinar thumbnail' />
                    </div>
                )}

                <div className='myevents__item-info'>
                  <div className='webinars__item-info-name'>

                  <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>{x.name}</Link>

                  {x.is_registered === true && (
                    <span className={`eventdashboard__status eventdashboard__status--published`}>Registered</span>
                  )}</div>

                  {x.start_date && (
                    <div className="webinars__list-item-date">
                      <span><FaRegCalendarAlt /></span> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  )}


                {/* {x.end_date && (
                  <div className="events__list-item-date">
                    <strong>Ends:</strong> {new Date(x.end_date).toLocaleDateString()} {new Date(x.end_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div>{moment.tz.guess()}</div> */}

                <div style={{marginTop: '20px'}}>
                  <Link className="btn" to={`/event/${x.event_id}/${x.canonical_url_slug}`}>View Full Details</Link>
                </div>
                </div>
              </div>
            ))}
          </div>
          </FadeIn>
            )}
        </div>
      </div>
        
        <div className="events">
          <p className='events__description'>
            Attend local events and network with other attendees using our mobile app. Don't see an event that you are looking for? Not a problem. <a href="https://zviv2pvc6i2.typeform.com/to/WFIw0UAq" target="_blank" className="link" rel='noreferrer'>Submit your event</a>
          </p>
        </div>
        </>
    );
}

export default Webinars;