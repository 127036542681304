import './SellerInvite.css';
import { useState, useEffect } from "react";
import { saveUserProfile, updateUser, getCompanyInvite, checkHostInvite, createUserSellerInvite, createUserHostInvite, addBuyerRole } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import classNames from "classnames";
import React from "react";
import Spinner from '../../components/layout/Spinner';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

const EventInvite = () => {
  const [invite, setInvite] = useState(null);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  let [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  let [showError, setShowError] = useState(false);
  const [selected, setSelected] = useState(false);
  let inputPassword = React.createRef();  // React use ref to get input value
  let inputConfirm = React.createRef();
  let inputFirstName = React.createRef();
  const search = useLocation().search;
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadInvite(){
      const _refer = new URLSearchParams(search).get('refer');

      if (_refer){
        setToken(_refer);
        let res = await checkHostInvite(_refer);

        if (res.success){
          if (res.data.registered_user_id){
            setAlreadyExists(true);
          }

          setInvite(res.data);
          setIsValid(true);
          setSelected(false);
        }

        else{
          setIsValid(false);
          setErrorMessage(res.message);
          setShowError(true);
        }

        setLoaded(true);
      }
    }

    loadInvite();
  }, []);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';
    document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);

    let password = inputPassword.current.value;
    let confirm = inputConfirm.current.value;
    let name = inputFirstName.current.value;
    let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (password.length < 6 || confirm.length < 6){
        setShowError(true);
        setErrorMessage('Must enter a valid password. Passwords must be 6 characters or greater, include at least 1 number and at least 1 special character (#, $, %, &)');
        setLoading(false);
        setSubmitting(false);
        return;
    }

    if (password === confirm){
      setShowError(false);
      setSubmitting(true);

      let result = await createUserHostInvite({email: invite.email, invite_token: token, password: password, timezone: l_timezone, enable_buyer: selected, first_name: name});

        if (result.success){
            localStorage.setItem('access_token', result.data.accessToken);
            localStorage.setItem('refresh_token', result.data.refreshToken);

            // used for meetings.leadrpro
            setCookie('access_token', result.data.accessToken, 1);
            // window.location = '/account/profile';
            setShowConfirmation(true);
            setLoading(false);
            setSubmitting(false);
        }

        else{
            setLoading(false);
            setErrorMessage(result.error);
            setShowError(true);
            setSubmitting(false);
        }
    }

    else{
        setLoaded(false);
        setLoading(false);
        setShowError(true);
        setSubmitting(false);
        setErrorMessage("Passwords do not match");
    }
  }

  async function handleContinue(){
      window.location = '/account/events';
  }

  return (
    <div className='sellerinvite center'>
      <Helmet>
          <title>You've been invited to host</title>
          <meta name="description" content="" />
        </Helmet>

      {invite && (
        <>
      <h2 className='card-header'>
        You have been invited as event host/manager.
      </h2>

      <div className='sellerinvite__company'>
        {invite.image_url && invite.image_url.length > 0 && (
          <div>
            <img src={invite.image_url} />
          </div>
        )}
        
        <div className='sellerinvite__company-name'>Event: {invite.name}</div>
      </div>

      <div>
        <div>Your email</div>
        <i>{invite.email}</i>
      </div>

      <div>
        {alreadyExists === false && (
      <form className='sellerinvite__form'>
          <div className="form-group">
              <label className="createpassword__input-label" for="txtFirstName">First name</label>

              <input type="text" id="txtFirstName" className='createaccount__input input' ref={inputFirstName} name="firstname"
                  placeholder="Your first name" />
              </div>

            <div className="form-group">
              <label className="createpassword__input-label" for="txtPassword">Set a password</label>

              <input
                  type="password"
                  id="inputPassword"
                  ref={inputPassword}
                  name="password" className={classNames("createaccount__input", "input", {
                      "input--error":showError
                  })} required
                  placeholder="Enter password" />
              </div>

              <div className="form-group">
                  <label className="createpassword__input-label" for="txtConfirm">Confirm password</label>
                  <input
                      type="password"
                      id="inputConfirm"
                      ref={inputConfirm}
                      name="confirm" className={classNames("createaccount__input", "input", {
                          "input--error":showError
                      })} required
                      placeholder="Confirm password"
                  />
              </div>

              {showError===true && (
                  <div className="form-group">
                  <div className="label label--error">{errorMessage}
                  </div>
                  </div>
              )}
{/* 
            <div style={{marginTop: '20px', fontSize: '14px', textAlign: 'left'}}>
              <input type='checkbox' id='cbBuyer' checked={selected} value={selected} onChange={(e) => setSelected(e.target.checked)} /> <label htmlFor='cbBuyer'>I'm also interested in taking on paid demos. I may receive periodic suggestions for new software related to my work. <Link to='/how-it-works' className='link' target='_blank'>Learn more.</Link></label>
            </div> */}

              <div className="center" style={{marginTop: "50px"}}>
                {submitting === false && (
                  <button className="btn btn--primary" onClick={(e) => handleSubmit(e)}>
                      <span>Create Account</span>
                  </button>
                )}

                {submitting && (
                  <Spinner />
                )}
              </div>
          </form>
        )}

        {alreadyExists === true && (
          <div>
              Success! You have successfully been added to your team' account. <br/><br/>
              <Link to='/account/events' className='link'>Go to your host dashboard</Link>
          </div>
        )}
      </div>
      </>
      )}

      {!invite && loaded === true && (
        <>
          <div className='center'>Your invitation token has expired. Please reach out to your company administrator.</div>
        </>
      )}

      {showConfirmation && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__h2'>
              Your account is ready!
            </div>

            <div style={{marginTop: '20px', fontSize: '14px', textAlign: 'left'}}>
              You can now edit the event, view the guest list and invite other hosts.
            </div>

            {selected === true && (
              <div>
                  
              </div>
            )}

            <div style={{marginTop: '30px'}}>
              <button className='btn btn--primary' onClick={() => handleContinue()}>Go to my Dashboard</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventInvite;
