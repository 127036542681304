import "./UserInfo.css"
import Dropdowns from "./ReviewDropdowns";
import { useState } from "react";
import { searchIndustries } from "../../../utils/api";

function UserInfo({ loggedIn, companySelected, userInfo, setUserInfo, setStage}) {

  const [categories, setCategories] = useState([]);
  const [categoryClicked, setCategoryClicked] = useState(false);
  const [categorySelected, setCategorySelected] = useState({});

  const handleCancel = (event) => {
    setStage((value) => value - 1);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setStage((value) => value + 1)
  }

  const handleChange = ({ target }) => {
    setUserInfo({
      ...userInfo,
      [target.name]: target.value
    })
  }

  //* Handles whenever a user clicks on a category
  const handleCategoryClick = (category) => {
    let categoryInput = document.querySelector("#industry")
    categoryInput.value = category.name;
    setCategoryClicked(true);
    setCategorySelected(category);
    setCategories([])
    setUserInfo({
      ...userInfo,
      ["industry"]: category.industry_id
    })
  }

  const handleCategoryChange = async ({ target }) => {
    if (target.value == ""){
      return setCategories([])
    }
    try {
      const { data } = await searchIndustries(target.value)
      setCategories(data);
    } catch (err) {
    }
  }

  return (
    <form className="review__user-info-main-container" onSubmit={handleSubmit}>
      <h2 className="review__user-info-title">Write a review about {companySelected.name}</h2>

      <div className="review__user-info-input-container">
        <h4>You're almost there, please complete the form</h4>
        <div className="review__user-info-input-list">
          <div className="review__user-info-input-row">
            <div className="review__user-info-input-column">
              <label>First Name</label>
              <input 
                name="first_name"
                onChange={handleChange}
                required
              />
            </div>
            <div className="review__user-info-input-column">
              <label>Last Name</label>
              <input 
                name="last_name"
                onChange={handleChange}
                required
              />
            </div>
            <div className="review__user-info-input-column">
              <label>Job Title</label>
              <input
                name="job_title"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="review__user-info-input-row">
            <div className="review__user-info-input-column">
              <label>Seniority</label>
              <div className="review__custom-select">
                <Dropdowns type={"seniority"} class_name={"review__user-info-dropdown"} handleChange={handleChange} userInfo={userInfo}/>
              </div>
            </div>
            <div className="review__user-info-input-column">
              <label>Department</label>
              <input
                name="department"
                onChange={handleChange}
                required
              />
            </div>
            <div className="review__user-info-input-column">
              <label>Company</label>
              <input
                name="company"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="review__user-info-input-row">
            <div className="review__user-info-input-column">
              <label>Company Size</label>
              <div className="review__custom-select">
                <Dropdowns type={"company_size"} class_name={"review__user-info-dropdown"} handleChange={handleChange} userInfo={userInfo}/>
              </div>
            </div>
            <div className="review__user-info-input-column">
              <label>What is your role using this software?</label>
              <div className="review__custom-select">
                <Dropdowns type={"role"} class_name={"review__user-info-dropdown"} handleChange={handleChange} userInfo={userInfo}/>
              </div>
            </div>
            <div className="review__user-info-input-column">
              <label for="industry">Industry</label>
              <input
                id="industry"
                name="industry"
                onChange={handleCategoryChange}
                required
                autoComplete="off"
                placeholder={categoryClicked == false ? "Search" : categorySelected.name }
              />
              {categories && categories.length > 0 && (
                <div className="input review__user-info-category-results" >
                  {categories.map((item, index) => (
                    <div key={index} className="review__hero-category-item" >
                      <a className="review__hero-category-text" onClick={() => handleCategoryClick(item)}>{item.name}</a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="review__user-info-btn-conatiner">
        <button className="review__user-info-previous-btn" onClick={handleCancel}>Previous page</button>
        <button className="btn-action review__user-info-submit-btn" type="submit">Submit a review</button>
      </div>
    </form>
    
  )
}

export default UserInfo;