import './SellerConfirm.css';
import { useState, useEffect } from "react";
import { loginUser, sendInvites } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import congrats from '../../assets/design/signup/congrats.png';

function SellerConfirm({company, setShow}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {       
        document.title = "LeadrPro - Success!";
    }, []);

    return (
        <div className="center card__wrapper">
        <div className="card" style={{position: "relative", width: "500px", padding: "5%"}}>
          <div style={{padding: "5%"}}>
          <h1 style={{fontSize: "24px", marginBottom: "25px"}}>Thanks for your request!</h1>

          <div style={{marginBottom: "20px"}}>
          <div className="searchresults__company-logo sellerresults__company-logo">
              {company.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + company.logo} alt="" />
                )}
                
                {!company.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + company.logo} alt="" />
                )}
            </div>
          </div>

          <div style={{fontSize: "14px"}}>
            <strong>{company.name}</strong> has been notified and will have someone follow up with you within 48 hours
          </div>

          <div style={{position: "absolute", top: "10px", right: "20px", fontSize: "18px", cursor: "pointer"}} onClick={() => setShow(false)}>
            X
          </div>
          </div>
        </div>
        </div>
    );
}

export default SellerConfirm;