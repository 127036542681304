import { refreshToken, createUserSessionPage, createUserSession } from '../utils/api.js';
import { refresh } from '../services/auth.service.js';

export function getSeoName(name){
  let new_name = name.replaceAll(' ', '-');
  new_name = new_name.replaceAll(',', '-');
  new_name = new_name.replaceAll('.', '-');
  new_name = new_name.replaceAll(';', '');
  new_name = new_name.replaceAll('[', '');
  new_name = new_name.replaceAll(']', '');
  new_name = new_name.replaceAll('(', '');
  new_name = new_name.replaceAll(')', '');
  new_name = new_name.replaceAll('#', '');
  new_name = new_name.replaceAll('&', '');
  new_name = new_name.replaceAll(':', '');
  new_name = new_name.toLocaleLowerCase();
  return new_name;
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setLocalCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export async function checkSession(page_name) {
  let tok = getCookie('session_token');

  if (tok.length > 0) {
    let res = await createUserSessionPage(tok, page_name);

  } else {
    let res = await createUserSession();

    if (res.success) {
      let token = res.data;
      setLocalCookie('session_token', token, 365);

      await createUserSessionPage(token, page_name);
    }
  }
}

export function getTrimmedString(inputString = '', maxLength = 160) {
  if (inputString.length <= maxLength) {
      return inputString;
  } else {
      let trimmedString = inputString.substr(0, maxLength - 1);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
      return `${trimmedString}…`;
  }
}

export function getBaseImageUrl(){
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  return baseImageUrl;
}