import './DemoFeedback.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getBuyerFeedbackByReference, getDemoFromRefForTeam } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';

function DemoFeedback() {
  const [buyerFeedback, setFeedback] = useState(null);
  const [currentDemo, setCurrentDemo] = useState(null);
  const [loading, setLoading] = useState(false);
  const {refid} = useParams();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    document.title = 'LeadrPro - Demo Feedback';
  }, []);

  useEffect(() => {
    async function loadFeedback(){
      setLoading(true);
      let res = await getBuyerFeedbackByReference(refid);

      if (res.success){
        setFeedback(res.data[0]);
        setLoading(false);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getBuyerFeedbackByReference(refid);

        if (res.success){
          setFeedback(res.data[0]);
          setLoading(false);
        }
      }
    }

    async function loadDemo(){
      let res = await getDemoFromRefForTeam(refid);

      if (res.success){
        setCurrentDemo(res.data[0]);
      }

      else if (res.status === 403){
        await refresh();
        let res = await getDemoFromRefForTeam(refid);

        if (res.success){
          setCurrentDemo(res.data[0]);
        }
      }
    }

    loadFeedback();
    loadDemo();
  }, []);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className='demofeedback'>
        <h2>Demo Feedback</h2>

        {loading && (
          <Spinner />
        )}

        {loading == false && buyerFeedback && (
          <div>
            {currentDemo && (
             <table className='table dashboard__table'>
                  <tr>
                  <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.buyer_logo} />
                  </div>
              </td>

              <td>
                  <div className='dashboarddemo__company-name'>
                    {currentDemo.buyer_first_name && currentDemo.buyer_first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{currentDemo.buyer_first_name} {currentDemo.buyer_last_name}</span>
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {currentDemo.buyer_job_title && currentDemo.buyer_job_title.length > 0 && (
                        <span className=''>{currentDemo.buyer_job_title}</span>
                      )}
                       at {currentDemo.buyer_company_name}
                    </span>
                  </div>
                </td>
                  </tr>
                </table>
            )}

               <table className='table dashboard__table dashboard__feedback-table'>
                  <tr>
                    {buyerFeedback.feedback_option && (
                      <>
                      <td className=''>
                        <h3>Are you interested in moving forward?</h3>
                      </td>

                      <td>
                        <p className=''>
                          {buyerFeedback.feedback_option}
                        </p>
                      </td>
                      </>
                    )}
                  </tr>

                    {buyerFeedback.feedback_text && (
                      <tr>
                        <td>Feedback</td>
                        <td className=''>
                          {buyerFeedback.feedback_text}
                        </td>
                      </tr>
                    )}

                  {buyerFeedback.feedback1 && (
                    <tr className=''>
                      <td>What, if anything, did you find most appealing about the product?</td>

                      <td className=''>
                        {buyerFeedback.feedback1}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.feedback2 && (
                    <tr className=''>
                      <td>What, if anything, are your hesitations/concerns about the product?</td>

                      <td className=''>
                        {buyerFeedback.feedback2}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.feedback3 && (
                    <tr className=''>
                      <td>Was there anything you wish to have seen or discussed in this presentation that was not?</td>

                      <td className=''>
                        {buyerFeedback.feedback3}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.does_product_fix && (
                    <tr>
                      <td>
                        Does this product/service fix a problem that you are having?
                      </td>

                      <td>
                        {buyerFeedback.does_product_fix}
                      </td>
                    </tr>
                  )}

                </table>
          </div>
        )}
      </div>
    </>
  );
}

export default DemoFeedback;
