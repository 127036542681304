import './CreatePassword.css';
import {useState} from 'react';
import CreatePassword from './CreatePassword';
import CompleteProfileExistingBuyer from './CompleteProfileExistingBuyer';
import CompleteProfileEvents from './CompleteProfileEvents';
import CompleteProfileNewBuyer from './CompleteProfileNewBuyer';
import CompleteProfileExistingSeller from './CompleteProfileSeller';
import CompanyLogoAndTimezone from './CompanyLogoAndTimezone';
import CategorySelection from './CategorySelection';
import PhoneNumber from './PhoneNumber';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import SelectRole from './SelectRole';
import SelectRoleEvent from './SelectRoleEvent';
import ReferFriends from './ReferFriends';
import React from 'react';
import FadeIn from '../../utils/fadein';

const AccountSetupEvent = ({setAuthenticated}) => {
  const [roleType, setRoleType] = useState('');
  const [companyExists, setCompanyExists] = useState(false);
  const [phoneNumber, setPhoneNumber] =useState('');
  const [companyId, setCompanyId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [showPassword, setShowPassword] = useState(true);
  const [showSelectRole, setShowSelectRole] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [showLogoAndTimezone, setShowLogoAndTimezone] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showOptIn, setShowOptIn] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);  // which step in signup we are on

  return (
    <div>
      {currentStep === 1 && (
        <FadeIn delay={250} duration={450}>
          <CreatePassword
            showPassword={setShowPassword}
            setRoleType={setRoleType}
            roleType="2"
            setShowSelectRole={setShowSelectRole}
            setUserId={setUserId}
            setCurrentStep={setCurrentStep}
            setAuthenticated={setAuthenticated}
          />
        </FadeIn>
      )}

      {currentStep === 2 && (
        <FadeIn delay={250} duration={450}>
          <SelectRoleEvent
            roleType="5"
            setCompanyExists={setCompanyExists}
            showSelectRole={setShowSelectRole}
            setShowPhoneNumber={setShowPhoneNumber}
            userId={userId}
            setShowProfile={setShowProfile}
            setCompanyId={setCompanyId}
            setCurrentStep={setCurrentStep}
          />
        </FadeIn>
      )}

      {currentStep === 3 && (
        <FadeIn delay={250} duration={450}>
          <CompleteProfileExistingSeller 
            setCurrentStep={setCurrentStep}
            companyId={companyId} />
        </FadeIn>
      )}

      {currentStep === 4 && (
        <FadeIn delay={250} duration={450}>
          <CompleteProfileEvents
            setShowPhoneNumber={setShowPhoneNumber}
            setShowProfile={setShowProfile}
            setShowLogoAndTimezone={setShowLogoAndTimezone}
            companyId={companyId}
            setCurrentStep={setCurrentStep}
            userId={userId}
            roleType="5"
          />
        </FadeIn>
      )}

      {currentStep === 51 && (
        <FadeIn delay={250} duration={450}>
          <CompanyLogoAndTimezone setShowLogoAndTimezone={setShowLogoAndTimezone} showPhoneNumber={showPhoneNumber} setShowPhoneNumber={setShowPhoneNumber} setShowProfile={setShowProfile} setCurrentStep={setCurrentStep} />
        </FadeIn>
      )}

      {currentStep === 6 && (
        <FadeIn delay={250} duration={450}>
          <PhoneNumber
            setShowVerifyPhone={setShowVerifyPhone}
            setShowPhoneNumber={setShowPhoneNumber}
            setPhoneNumber={setPhoneNumber}
            setCurrentStep={setCurrentStep}
            roleType="Seller"
          />
        </FadeIn>
      )}

      {currentStep === 7 && (
        <FadeIn delay={250} duration={450}>
          <VerifyPhoneNumber phoneNumber={phoneNumber} setCurrentStep={setCurrentStep} role="Seller" />
        </FadeIn>
      )}

      {currentStep === 8 && (
        <FadeIn delay={250} duration={450}>
          <ReferFriends setCurrentStep={setCurrentStep} roleType="2" />
        </FadeIn>
      )}
    </div>
  );
};

export default AccountSetupEvent;
