import './SelectPlan.css';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser, refresh } from '../../services/auth.service';
import { useLocation } from 'react-router-dom';
import { addUserEventTracking, createPackagePurchase, getCompanyPackages } from '../../utils/api';
import { FaAward, FaChartLine, FaHorseHead, FaExclamation, FaExclamationCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import ScrollToTop from '../../components/layout/ScrollToTop';

function SelectPlan({is_first_time}) {
    const [loaded, setLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [companyPackages, setCompanyPackages] = useState([]);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
      async function loadPackages(){
        let res = await getCompanyPackages();
        
        if (res.success){
          let filtered = res.data;

          if (is_first_time === false){
            // remove first time plans
            filtered = res.data.filter(x => x.first_time_customer_only === false);
          }

          else{
            // first time user
            filtered = res.data.filter(x => x.existing_customer_only === false);
          }

          setCompanyPackages(filtered);
          setLoaded(true);
        }

        else if (res.status === 403){
          await refresh();
          let res = await getCompanyPackages();
        
          if (res.success){
            let filtered = res.data;

            if (is_first_time === false){
              // remove first time plans
              filtered = res.data.filter(x => x.first_time_customer_only === false);
            }

            else{
              // first time user
              filtered = res.data.filter(x => x.existing_customer_only === false);
            }

            setCompanyPackages(filtered);
            setLoaded(true);
          }

          else{
            navigate('/login');
          }
        }

        else{

        }
      }
      
      loadPackages();
    }, []);

    async function handleNotReady(){
      let res = await addUserEventTracking('Select plan skip', null);
      navigate('/dashboard/seller');
    }

    async function handlePackageSelect(company_package_id){
      setSubmitting(true);
      setSelectedPackageId(company_package_id);
      let res = await createPackagePurchase(company_package_id);

      if (res.success){
        await addUserEventTracking('Package Selected', company_package_id);
        let refid = res.data;
        navigate(`/select-plan-checkout?cartid=${refid}`);
      }

      else if (res.status === 403){
        await refresh();
        let res = await createPackagePurchase(company_package_id);

        if (res.success){
          await addUserEventTracking('Package Selected', company_package_id);
          let refid = res.data;
          navigate(`/select-plan-checkout?cartid=${refid}`);
        }
      }
      else{
        alert('Something went wrong! Contact support.');
        setSubmitting(false);
      }
    }

    return (
        <div className="center card__wrapper">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />
          <title>LeadrPro - Select a demo package</title>
        </Helmet>

        <div className='selectplan'>
          <h1>Select the option that's right for you

          {loaded && is_first_time && (
            <span style={{fontSize: '14px', fontWeight: 'normal', marginLeft: '10px', textDecoration: 'underline'}}><Link className='link link--cancel' to={'/dashboard/seller'}>{`(Skip for now)`}</Link></span>
          )}
          </h1>

          <h2>Buy demo's up front and save or <Link className='link' to={'/dashboard/seller'}>pay as you go</Link> and only get charged when you approve and complete demo's.</h2>

          {loaded === false && (
            <Spinner />
          )}

          {loaded && is_first_time && (
            <>
          <div className='selectplan__trials-header'><span style={{verticalAlign:'middle'}}><FaExclamationCircle /></span> <span style={{verticalAlign:'middle'}}>Limited time offers</span></div>
          <div className='selectplan__plans selectplan__trials'>
            {companyPackages.length > 0 && companyPackages.filter(x => (x.is_trial === true)).map(item => (
              <div className='selectplan__plan'>
              <div className='selectplan__plan-name'>{item.name}</div>

              <div className='selectplan__plan-price'>
                <span className='selectplan__plan-price-amount'>${item.number_of_demos * item.cost_per_demo}</span> <span> for {item.number_of_demos} Demos</span>

                {item.extra_demos > 0 && (
                  <span className='selectplan__plan-price-bonus'>{`(+${item.extra_demos} bonus)`}</span>
                )}
              </div>

              <div className='selectplan__plan-blurb'>
                {item.short_description}
              </div>

            {selectedPackageId !== item.company_package_id && (
              <div className='selectplan__btn' onClick={() => handlePackageSelect(item.company_package_id)}>
                Choose Package
              </div>
              )}

              {selectedPackageId === item.company_package_id && (
                <Spinner />
              )}
            </div>
            ))}
          </div>
          </>
          )}

        {/* {loaded && is_first_time === false && (
            <>
          <div className='selectplan__trials-header'><FaExclamationCircle /> Limited time offers</div>
          <div className='selectplan__plans selectplan__trials'>
            {companyPackages.length > 0 && companyPackages.filter(x => (x.is_trial === true)).map(item => (
              <div className='selectplan__plan'>
              <div className='selectplan__plan-name'>{item.name}
                {item.is_sale == true && (
                  
                  <span className='selectplan__sale-label'>
                    {item.sale_message != null && item.sale_message.length > 0 && (
                      <>{item.sale_message}</>
                    )}

                    {(item.sale_message == null || item.sale_message.length == 0) && (
                      <>Limited time</>
                    )}
                  </span>
                )}
              </div>

              <div className='selectplan__plan-price'>
                <span className='selectplan__plan-price-amount'>${item.number_of_demos * (item.modified_price == null ? item.cost_per_demo : item.modified_price)}</span> <span> for {item.number_of_demos} Demo{'(s)'}</span>

                {item.extra_demos > 0 && (
                  <span className='selectplan__plan-price-bonus'>{`(+${item.extra_demos} bonus)`}</span>
                )}
              </div>

              <div className='selectplan__plan-blurb'>
                {item.short_description}
              </div>

            {selectedPackageId !== item.company_package_id && (
              <div className='selectplan__btn' onClick={() => handlePackageSelect(item.company_package_id)}>
                Choose Package
              </div>
              )}

              {selectedPackageId === item.company_package_id && (
                <Spinner />
              )}
            </div>
            ))}
          </div>
          </>
          )} */}

          <div className='selectplan__trials-header'>Current packages</div>
          <div className='selectplan__plans'>
          {companyPackages.length > 0 && companyPackages.filter(x => (x.is_trial === false)).map(item => (
            <div className='selectplan__plan'>
              <div className='selectplan__plan-name'>
                {item.name === 'Premium' && (
                  <span className='selectplan__plan-name-icon'><FaAward /></span>
                )}

                {item.name === 'Growth' && (
                  <span className='selectplan__plan-name-icon'><FaChartLine /></span>
                )}

                {item.name === 'Unicorn' && (
                  <span className='selectplan__plan-name-icon'><FaHorseHead /></span>
                )}

                {item.name}</div>

              <div className='selectplan__plan-price'>
                <span className='selectplan__plan-price-amount'>{item.number_of_demos} Demos</span> <span>/ ${item.modified_price == null ? item.cost_per_demo : item.modified_price} per demo</span>
              </div>

              <div className='selectplan__plan-blurb'>
                {item.short_description}
              </div>

              {selectedPackageId !== item.company_package_id && (
              <div className='selectplan__btn' onClick={() => handlePackageSelect(item.company_package_id)}>
                Continue
              </div>
              )}

              {selectedPackageId === item.company_package_id && (
                <Spinner />
              )}
            </div>
          ))}

            {/* <div className='selectplan__plan'>
              <div className='selectplan__plan-name'><FaChartLine /> Growth</div>

              <div className='selectplan__plan-price'>
                <span className='selectplan__plan-price-amount'>10 Demos</span> <span>/ $300 per demo</span>
              </div>

              <div className='selectplan__plan-blurb'>
                Only pay for the demos that you approve and successfully meet with. No obligation.
              </div>

              <div className='selectplan__btn'>
                Continue
              </div>
            </div> */}

            {/* <div className='selectplan__plan'>
              <div className='selectplan__plan-name'><FaHorseHead /> Unicorn</div>

              <div className='selectplan__plan-price'>
                <span className='selectplan__plan-price-amount'>20 Demos</span> <span>/ $250 per demo</span>
              </div>

              <div className='selectplan__plan-blurb'>
                Only pay for the demos that you approve and successfully meet with. No obligation.
              </div>

              <div className='selectplan__btn'>
                Continue
              </div>
            </div> */}
          </div>

          <div style={{textAlign: 'left', marginTop: '20px'}}>
            <Link to={'/leave-feedback'} className='link'>Contact sales for questions</Link>
          </div>
        </div>

        {is_first_time === true && (
          <div className='center' style={{marginTop: "20px"}}>
            <span className='link' onClick={() => handleNotReady()} style={{fontSize: '14px'}}>Skip for now</span>
          </div>
        )}
        </div>
    );
}

export default SelectPlan;