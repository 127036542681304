import "./WriteReview.css"
import starNotFilled from "../../../assets/design/review/star-not-filledv2.svg"
import starFilled from "../../../assets/design/review/star-filled.svg"
import { useState } from "react"

//! Whatever the user puts into the form will be put into the state
//! that is from the parent component that way it can be passed to other componenets

function WriteReview({ loggedIn, companySelected, setStage, form, setForm}){
//* use state for each set of stars
  const [overallIndex, setOverallIndex] = useState(0);
  const [easeIndex, setEaseIndex] = useState(0);
  const [serviceIndex, setServiceIndex] = useState(0);
  const [overallStarClicked, setOverallStarClicked] = useState(false);
  const [easeStarClicked, setEaseStarClicked] = useState(false);
  const [serviceStarClicked, setServiceStarClicked] = useState(false);

  //*Error states
  const [overallQualityError, setOverallQualityError] = useState(false);
  const [easeError, setEaseError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const [pricingError, setPricingError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [recommendError, setRecommendError] = useState(false);
  const [err, setErr] = useState(false);
  // TODO: create states for when the user tries to submit without entering a field in the review
  // TODO: then add the conditional rendering for the font color of the affected fields


  //* Overall stars handlers
  const overallMouseOver = (index) => {
    setOverallIndex(() => index);
  }

  const overallMouseLeave = () => {
    setOverallIndex(0);
  }

  const overallMouseClick = (index) => {
    if (index){
      return setOverallIndex(() => index);
    }
    setOverallStarClicked(true);
  }

  //* Ease stars handlers
  const easeMouseOver = (index) => {
    setEaseIndex(() => index)
  }

  const easeMouseLeave = () => {
    setEaseIndex(0);
  }

  const easeMouseClick = (index) => {
    if(index){
      return setEaseIndex(() => index);
    }
    setEaseStarClicked(true);
  }

  //* Service stars handlers
  const serviceMouseOver = (index) => {
    setServiceIndex(() => index);
  }

  const serviceMouseLeave = () => {
    setServiceIndex(0);
  }

  const serviceMouseClick = (index) => {
    if(index){
      return setServiceIndex(() => index)
    }
    setServiceStarClicked(true);
  }

  // * Star renders for each category
  const renderOverallStars = () => {
    let array = [];
    if(overallStarClicked) {

      for(let i = 0; i < overallIndex; i++){
        array.push(
          <span className="star-btn" onClick={() => overallMouseClick(i + 1) }>
            <img src={starFilled} className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = overallIndex; i < 5 ; i++) {
        array.push(
          <span className="star-btn"  onClick={() => overallMouseClick(i + 1) }>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    } else {

      for(let i = 0; i < overallIndex; i++){
        array.push(
          <span className="star-btn" onMouseEnter={()=> overallMouseOver(i + 1)} onMouseLeave={() => overallMouseLeave()} onClick={() => overallMouseClick() }>
            <img src={starFilled} className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = overallIndex; i < 5 ; i++) {
        array.push(
          <span className="star-btn" onMouseEnter={()=> overallMouseOver(i + 1)} onMouseLeave={() => overallMouseLeave()} onClick={() => overallMouseClick() }>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    }

    return array;
  }

  const renderEaseStars = () => {
    let array = [];
    if(easeStarClicked) {
      for(let i = 0; i < easeIndex; i++){
        array.push(
          <span className="star-btn" onClick={() => easeMouseClick(i + 1)}>
            <img src={starFilled}  className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = easeIndex; i < 5; i++) {
        array.push(
          <span className="star-btn" onClick={() => easeMouseClick(i + 1)}>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    } else {

      for(let i = 0; i < easeIndex; i++){
        array.push(
          <span className="star-btn" onMouseEnter={()=> easeMouseOver(i + 1)} onMouseLeave={() => easeMouseLeave()} onClick={() => easeMouseClick()}>
            <img src={starFilled}  className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = easeIndex; i < 5; i++) {
        array.push(
          <span className="star-btn" onMouseEnter={()=> easeMouseOver(i + 1)} onMouseLeave={() => easeMouseLeave()} onClick={() => easeMouseClick()}>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    }
    return array;
  }

  const renderServiceStars = () => {
    let array = [];
    if(serviceStarClicked) {
      //render stars without the on mouse leave
      for(let i = 0; i < serviceIndex; i++){
        array.push(
          <span className="star-btn" onClick={() => serviceMouseClick(i + 1)} >
            <img src={starFilled}  className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = serviceIndex; i < 5; i++) {
        array.push(
          <span className="star-btn" onClick={() => serviceMouseClick(i + 1)}>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    } else {
      for(let i = 0; i < serviceIndex; i++){

        array.push(
          <span className="star-btn" onMouseEnter={()=> serviceMouseOver(i + 1)} onMouseLeave={() => serviceMouseLeave()} onClick={() => serviceMouseClick()}>
            <img src={starFilled}  className="review__star-not-filled" />
          </span>
        )
      }

      for (let i = serviceIndex; i < 5; i++) {
        array.push(
          <span className="star-btn" onMouseEnter={()=> serviceMouseOver(i + 1)} onMouseLeave={() => serviceMouseLeave()} onClick={() => serviceMouseClick()}>
            <img src={starNotFilled} className="review__star-not-filled" />
          </span>
        )
      }

    }
    return array;
  }


  //* Pricing cards
  const [pricingIndex, setPricingIndex] = useState(0);
  const [pricingClicked, setPricingClicked] = useState(false);

  const priceMouseOver = (index) => {
    setPricingIndex(() => index);
  }

  const priceMouseLeave = () => {
    setPricingIndex(0)
  }

  const pricingCardClicked = (index) => {
    if(index) {
      return setPricingIndex(index)

    }
    setPricingClicked(true)
  }

  const renderPricingCards = () => {
    let array = [];
    if(pricingClicked){
      for (let i = 0; i < pricingIndex; i++){
        let sign = ""
        for(let j = 0 ; j <= i; j++){
          sign += "$"
        }
        array.push(
          <div className="review__pricing-card-clicked" onClick={() => pricingCardClicked(i + 1)}>
            <p>{sign}</p>
          </div>
        )
      }

      for(let i = pricingIndex; i < 5; i++){
        let sign = ""
        for(let j = 0 ; j <= i; j++){
          sign += "$"
        }
        array.push(
          <div className="review__pricing-card" onClick={() => pricingCardClicked(i + 1)}>
            <p>{sign}</p>
          </div>
        )
      }
    } else {
      for (let i = 0; i < pricingIndex; i++){
        let sign = ""
        for(let j = 0 ; j <= i; j++){
          sign += "$"
        }
        array.push(
          <div className="review__pricing-card-clicked" onMouseOver={() => priceMouseOver(i + 1)} onMouseLeave={() => priceMouseLeave()} onClick={() => pricingCardClicked()}>
            <p>{sign}</p>
          </div>
        )
      }

      for(let i = pricingIndex; i < 5; i++){
        let sign = ""
        for(let j = 0 ; j <= i; j++){
          sign += "$"
        }
        array.push(
          <div className="review__pricing-card" onMouseOver={() => priceMouseOver(i + 1)} onMouseLeave={() => priceMouseLeave()} onClick={() => pricingCardClicked()}>
            <p>{sign}</p>
          </div>
        )
      }
    }
    return array;
  }

  //* Recommend cards
  const [recommendIndex, setRecommendIndex] = useState(0);
  const [recommendClicked, setRecommendClicked] = useState(false);

  const recommendMouseOver = (index) => {
    setRecommendIndex(() => index);
  }

  const recommendMouseLeave = () => {
    setRecommendIndex(0);
  }

  const recommendCardClicked = (index) => {
    if(index) {
      setRecommendIndex(index)
    }
    setRecommendClicked(true);
  }


  const renderRecommendCards = () => {
    let array = [];
    if(recommendClicked){
      for (let i = 0; i < recommendIndex; i++){
        array.push(
          <div className="review_rec-card-clicked" onClick={() => recommendCardClicked(i+1)}><p>{i+1}</p></div>
        )
      }

      for(let i = recommendIndex; i < 10; i++){
        array.push(
          <div className="review_rec-card" onClick={() => recommendCardClicked(i+1)}><p>{i+1}</p></div>
        )
      }
    } else {
      for (let i = 0; i < recommendIndex; i++){
        array.push(
          <div className="review_rec-card-clicked" onMouseOver={() => recommendMouseOver(i+1)} onMouseLeave={() => recommendMouseLeave()} onClick={() => recommendCardClicked()}><p>{i+1}</p></div>
        )
      }

      for(let i = recommendIndex; i < 10; i++){
        array.push(
          <div className="review_rec-card" onMouseOver={() => recommendMouseOver(i+1)} onMouseLeave={() => recommendMouseLeave()} onClick={() => recommendCardClicked()}><p>{i+1}</p></div>
        )
      }
    }
    return array;
  }

  const handleChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value,
    })
  }

  //* Submit Handler
  const submitHandler = (event) => {
    event.preventDefault();
    setOverallQualityError(false);
    setEaseError(false);
    setServiceError(false);
    setPricingError(false);
    setReviewError(false);
    setRecommendError(false);
    let errors = [];
    let textarea = document.querySelector("#review");
    
    //* Validations
    if(overallIndex === 0){
      errors.push("1")
      setOverallQualityError(() => true);
      setErr(() => true)
    }
    if(easeIndex === 0){
      errors.push("1")
      setEaseError(() => true);
      setErr(() => true)
    }
    if(serviceIndex === 0){
      errors.push("1")
      setServiceError(() => true);
      setErr(() => true)
    }
    if(pricingIndex === 0){
      errors.push("1")
      setPricingError(() => true);
      setErr(() => true)
    }
    if(textarea.value == ""){
      errors.push("1");
      setReviewError(true);
      setErr(() => true);
    }
    if(recommendIndex === 0){
      errors.push("1")
      setRecommendError(() => true);
      setErr(() => true)
    }
    if(errors.length > 0){
      return
    }

    setForm({
      ...form,
      ["overall_quality"]: overallIndex,
      ["ease_of_use"]: easeIndex,
      ["service"]: serviceIndex,
      ["pricing"]: pricingIndex,
      ["recommend"]: recommendIndex
    })
    if(loggedIn){
      return setStage((value) => value + 2);
    }
    setStage((value) => value + 1);
  }

  const handleCancel = (event) => {
    if(loggedIn){
      return setStage((value) => value -2)
    }
    setStage((value) => value - 1);
  }

  return (
    <div className="review__WriteReview-main-container">
      <h3>Help others by reviewing {companySelected.name}</h3>
      <form onSubmit={submitHandler}>
        <div className="review__stars-container">
          <div className="review__overall-quality">
            {renderOverallStars()}
            <p className={!overallQualityError ? "review__overall-quality-label" : "review__overall-quality-label-error"}>Overall Quality</p>
          </div>

          <div className="review__ease-of-use">
            {renderEaseStars()}
            <p className={!easeError ? "review__overall-quality-label" : "review__overall-quality-label-error"}>Ease of Use</p>
          </div>

          <div className="review__customer-service">
            {renderServiceStars()}
            <p className={!serviceError ? "review__overall-quality-label" : "review__overall-quality-label-error"}>Customer Service</p>
          </div>

          <div className="review__comapny-pricing">
            <div className="review__company-pricing-label">
              <h4 className={!pricingError ? "review__label" : "review__overall-label-error"}>How does the pricing for {companySelected.name} compare to similar products?</h4>
            </div>

            <div className="review__pricing-card-container">
              {renderPricingCards()}
            </div>

            <div className="review__price-tier-container">
              <p>Inexpensive</p>
              <p>Mid-Tier</p>
              <p>Expensive</p>
            </div>

          </div>

          <div className="review__overall-exp">
            <div className="review__overall-exp-label">
              {/* //TODO implement a state for the text area to detect if there is no input
              */}
              <h4 className={!reviewError ? "review__label" : "review__overall-label-error"}>Did {companySelected.name} meet your expectations? Why or why not?</h4>
            </div>
            <textarea
            id="review" 
            className="review__overall-exp-text-area"
            placeholder="Enter here"
            name="review"
            onChange={handleChange}
            ></textarea>
          </div>

          <div className="review__likely-recommend">
            <h4 className={!recommendError ? "review__label" : "review__overall-label-error"}>How likely is it that you would recommend {companySelected.company_name} to a friend or colleague?</h4>

            <div className="review__rec-card-container">
              {renderRecommendCards()}
            </div>

            <div className="review__rec-cards-under">
              <p>Not likely</p>
              <p>Somewhat likely</p>
              <p>Very likely</p>
            </div>
          </div>

          <div className="review__form-buttons">
            <button className="btn review__previous-btn" onClick={handleCancel}>Previous page</button>
            <button className="btn-action review__continue-btn">Save and continue</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default WriteReview;