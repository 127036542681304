import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { AdminUserCountBreakdown, deleteUser, setAsTestUser, getAdminLatestUsers, getUsersByCompanySizeTable, getLatestBuyers, getLatestDemos, 
  getLatestBuyersByMonth, getLatestBuyersByDate
 } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaCheckCircle, FaEllipsisV, FaEnvelope, FaGlobe, FaLinkedin, FaMap, FaRegEnvelope, FaRegMap, FaTimes, FaTimesCircle } from 'react-icons/fa';
import DemosPending from './DemosPending';
import './Buyers.css';

function BuyersWithDate() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [buyerCount, setBuyerCount] = useState(0);
  const [breakdown, setBreakdown] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSetTestConfirm, setShowSetTestConfirm] = useState(false);
  const [sizeBreakdown, setSizeBreakdown] = useState([]);
  const [countApplications, setCountApplications] = useState(0);
  const [countTotalApplications, setCountTotalApplications] = useState(0);
  const [countApproved, setCountApproved] = useState(0);
  const [countTotalApproved, setCountTotalApproved] = useState(0);
  const [countDeclined, setCountDeclined] = useState(0);
  const [buyersWithDemos, setBuyersWithDemos] = useState(0);
  const [latestDemos, setLatestDemos] = useState([]);
  const [showDemos, setShowDemos] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let _date = new Date();
      _date.setDate(1);

      let res = await getLatestBuyersByMonth(_date.toDateString());

      if (res.success){
        setUsers(res.data);
        setLoaded(true);

        let count_applications = 0;
        let count_approved = 0;
        let count_declined = 0;
        let count_withdemos = 0;

        for (let i = 0; i < res.data.length; i++){
            count_applications += parseInt(res.data[i].application_count);
            count_approved += parseInt(res.data[i].approved_count);
            count_declined += parseInt(res.data[i].declined_count);
        }

        setCountApplications(count_applications);
        setCountApproved(count_approved);
        setCountDeclined(count_declined);

        setCountTotalApplications(res.data[0].total_application_count);
        setCountTotalApproved(res.data[0].total_approved_count);
      }
    }

    async function loadDemos(){
      let res = await getLatestDemos();

      if (res.success){
        setLatestDemos(res.data);
      }
    }

    async function loadSizeBreakdown(){
      let res = await getUsersByCompanySizeTable();

      if (res.success)
      {
        setSizeBreakdown(res.data);
      }
    }

    async function loadDataBreakdown(){
      let res = await AdminUserCountBreakdown();

      if (res.success){
        setBreakdown(res.data[0]);
       // setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await AdminUserCountBreakdown();

        if (res.success){
          setBreakdown(res.data[0]);
        //  setLoaded(true);
        }
      }
    }

    loadData();
    loadDataBreakdown();
    loadSizeBreakdown();
    loadDemos();
  }, []);

  async function loadData(){
    let res = await getLatestBuyersByDate(startDate, endDate);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);

      let count_applications = 0;
      let count_approved = 0;
      let count_declined = 0;
      let count_withdemos = 0;

      for (let i = 0; i < res.data.length; i++){
          count_applications += parseInt(res.data[i].application_count);
          count_approved += parseInt(res.data[i].approved_count);
          count_declined += parseInt(res.data[i].declined_count);
      }

      setCountApplications(count_applications);
      setCountApproved(count_approved);
      setCountDeclined(count_declined);

      if (res.data.length > 0)
        setCountTotalApplications(res.data[0].total_application_count);
      setCountTotalApproved(res.data[0].total_approved_count);
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }

    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await adminSearchUsers(query, id);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query, id);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      await handleSearch();
    }
  }

  async function handleConfirmDelete(){
    let res = await deleteUser(selectedUserId);

    if (res.success){
      setShowDeleteConfirm(false);
      setShowSubMenu(false);
      await loadData();
    }
  }

  async function handleSetTest(){
    let res = await setAsTestUser(selectedUserId);

    if (res.success){
      setShowSetTestConfirm(false);
      setSelectedUserId(null);
      setShowSubMenu(false);
      await loadData();
    }
  }

  async function handleMonthChange(val){
    setLoaded(false);
    loadData(val);
  }

  async function handleFilter(){
    loadData();
  }

  return (
    <>
      <Helmet>
        <title>Admin - Users</title>
      </Helmet>

      <div>
        {breakdown !== null && (
          <>
            <h1>Users {`(${parseInt(breakdown.buyer_count) + parseInt(breakdown.seller_count) + parseInt(breakdown.event_host_count) + parseInt(breakdown.attendee_count)})`}</h1>

          <div style={{display: 'flex', margin: '10px 0px'}}>
            <span className='admindashboard__role-tag--buyer'>
              Buyers: {breakdown.buyer_count}
            </span>

            <span className='admindashboard__role-tag--seller'>
              Sellers: {breakdown.seller_count}
            </span>

            <span className='admindashboard__role-tag--host'>
              Event Hosts: {breakdown.event_host_count}
            </span>

            <span className='admindashboard__role-tag--attendee'>
              Event Attendees: {breakdown.attendee_count}
            </span>

            <span className='admindashboard__red-flag'>
              Buyers - not verified: {breakdown.buyer_count_not_verified}
            </span>
          </div>
          </>
        )}
      </div>

      {/* <div className='buyers__date-header'>
        <select className='input select' onChange={(e) => handleMonthChange(e.target.value)}>
          <option value='7/1/2024'>July 2024</option>
          <option value='6/1/2024'>June 2024</option>
          <option value='5/1/2024'>May 2024</option>
          <option value='4/1/2024'>April 2024</option>
          <option value='3/1/2024'>March 2024</option>
        </select>
      </div> */}

      <div className='flex'>
        <div>
          <div><strong>Start date</strong></div>
          <input type='date' className='input' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>

        <div className='ml-10'>
          <div><strong>End date</strong></div>
          <input type='date' className='input' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>

        <div className='ml-10'>
          <button className='btn' onClick={() => handleFilter()}>Filter</button>
        </div>
      </div>

      <div className='admindashboard__cards'>
        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Users</strong></div>
          <div className='admindashboard__card-value'>
          {users.length}
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Applications</strong></div>
          <div className='admindashboard__card-value' onClick={() => setShowDemos(true)}>
            {countApplications} / <span style={{fontSize: '14px'}}>Total: {countTotalApplications}</span>
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong> Approved</strong></div>
          <div className='admindashboard__card-value'>
            {countApproved} / <span style={{fontSize: '14px'}}>Total: {countTotalApproved}</span>
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Declined</strong></div>
          <div className='admindashboard__card-value'>
            {countDeclined}
          </div>
        </div>
      </div>

      {/* <div>
        <div className='admindashboard__search-lookup'>
          <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />

          <div className='admindashboard__search-lookup-btn'>
            <button className='btn' onClick={() => handleSearch()}>Search</button>
          </div>
        </div>
      </div> */}

      {loaded===false && (
        <Spinner />
      )}

      {loaded && users.length === 0 && (
        <div className='center mt-20'><strong>Nothing to see here...</strong></div>
      )}

      {loaded && users.length > 0 && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th></th>
              <th>Roles</th>
              <th>Apps</th>
              <th>Approved</th>
              <th>Declined</th>
              <th></th>
              <th></th>
            </tr>

            {users.map((item, index) => (
              <tr key={index} className='relative'>
                <td>
                  <div className='flex' style={{alignItems:'center'}}>
                    <div className='buyerdashboard__recommendation-logo'>
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.company_logo} />
                    </div>

                    <div className='ml-10' style={{fontSize: '16px', fontWeight: 'bold'}}>
                      <div style={{fontSize: '12px'}}>{new Date(item.date_created).toLocaleDateString()}</div>
                      <div>
                        {item.first_name} {item.last_name} - #{item.registered_user_id}
                      </div>

                      <div>
                        {item.job_title}
                      </div>

                      <div>
                        {item.company_id} - {item.company_name}
                      </div>

                      <div>
                        {item.company_size}
                      </div>

                      <div>
                        <div>
                          <strong>Tz: </strong> {item.timezone}
                        </div>
                      </div>

                      <div>
                        <strong>Country: </strong>{item.country_name && item.country_name.length > 0 ? item.country_name : <i>Not set</i>}
                      </div>

                      {item.linkedin_url && (
                  <div>
                    <strong>LI: </strong>

                    {item.linkedin_url && item.linkedin_url.length > 0 && (
                      <span className='ml-10'><a href={item.linkedin_url}>LinkedIn</a></span>
                    )}

                  {(item.linkedin_url === null || item.linkedin_url.length === 0) && (
                      <span className='ml-10'><i>Not set</i></span>
                    )}
                  </div>
                  )}
                    </div>
                  </div>
                </td>

                {/* <td>
                  <div>

                    <div style={{fontSize: '20px'}} className='bold'>
                      {item.email} {item.is_verified && (<span><FaCheckCircle style={{color: 'mediumseagreen'}} /></span>)}

                      {item.is_verified === false && (
                        <span><FaTimesCircle style={{color: 'red'}} /></span>
                      )}
                    </div>
                  </div>
                 


                  {item.work_email && (
                    <div><strong>Work: </strong> <span className='admindashboard__green-flag'>{item.work_email}</span></div>
                  )}

                  {item.work_email === null && (
                    <div><strong>Work:</strong> <span className='admindashboard__red-flag'>Not found</span></div>
                  )}


                </td> */}

                <td>
                  <div>
                    {item.is_buyer && (
                      <span className='admindashboard__role-tag--buyer'>Buyer</span>
                    )}

                    {item.is_seller && (
                      <span className='admindashboard__role-tag--seller'>Seller</span>
                    )}

                    {item.is_event_host && (
                      <span className='admindashboard__role-tag--host'>Host</span>
                    )}

                    {item.is_event_attendee && (
                      <span className='admindashboard__role-tag--attendee'>Events</span>
                    )}

                    {item.is_test_account && (
                      <span className='admindashboard__red-flag'>Test</span>
                    )}
                  </div>
                </td>

                <td>
                  <div>
                    {item.application_count}
                  </div>
                </td>

                <td>
                  {item.approved_count}
                </td>

                <td>
                  {item.declined_count}
                </td>

                <td>
                  <div>
                    <div className='relative'>
                      <span className='admindashboard__demo-card-submenu-icon' onClick={() => {
                        if (selectedUserId != null && selectedUserId === item.registered_user_id){
                          setShowSubMenu(!showSubMenu);
                        }

                        else{
                          setShowSubMenu(true);
                          setSelectedUserId(item.registered_user_id);
                        }
                      }}>
                        <FaEllipsisV />
                      </span>

                      {showSubMenu == true && selectedUserId == item.registered_user_id && (
                        <FadeIn delay={100} duration={100}>
                          <div className='dashboard__submenu'>
                            <div className='dashboard__submenu-item' onClick={() => {
                              setSelectedUserId(item.registered_user_id);
                              setShowSetTestConfirm(true);
                            }}>Set as test account</div>

                            <div className='dashboard__submenu-item' onClick={() => {
                              setSelectedUserId(item.registered_user_id); setShowDeleteConfirm(true);

                            }}>Delete account</div>
                          </div>
                        </FadeIn>
                        )}
                      </div>
                    </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {showDeleteConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowDeleteConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Delete user?
            </h2>

            <p className='mt-10 mb-10'>
              This user will be completely removed from the site.
            </p>

            <div className='mt-20 center'>
              <button className='btn' onClick={() => handleConfirmDelete()}>Confirm</button>
            </div>
          </div>
        </>
      )}

      {showSetTestConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowSetTestConfirm(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Set as test account
            </h2>

            <div className='mt-20 center'>
              <button className='btn' onClick={() => handleSetTest()}>Set as test account</button>
            </div>
          </div>
        </>
      )}

      {showDemos && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '80%',marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowDemos(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Latest buyer demos</h2>
            <div className='mt-10'>
              <table className='table admindashboard__table'>
                {latestDemos.filter(x => new Date(x.date_created).getMonth() == new Date().getMonth() && new Date(x.date_created).getFullYear() == new Date().getFullYear()).map((item, index) => (
                  <>
                    <tr className=''>
                      <td>
                       #{index} - {new Date(item.date_created).toLocaleDateString()}
                      </td>

                    <td>
                        <div className='companylookup__demo-company'>
                        <div className=''>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} style={{maxHeight: '34px'}} />
                        </div>
                        <div>{item.buyer_company_name}</div>

                        <div>
                          {item.buyer_status == 'Approved' && (
                            <span className='admindashboard__green-flag'>{item.buyer_status}</span>
                          )}

{item.buyer_status == 'Rescheduled' && (
                            <span className='admindashboard__blue-flag'>{item.buyer_status}</span>
                          )}

{item.buyer_status == 'Declined' && (
                            <span className='admindashboard__red-flag'>{item.buyer_status}</span>
                          )}
                        </div>
                      </div>
                    </td>

                    <td>
                        <div className='companylookup__demo-company'>
                        <div className=''>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} style={{maxHeight: '34px'}} />
                        </div>
                        <div>{item.seller_company_name}</div>

                        <div>
                          {item.seller_status == 'Approved' && (
                            <span className='admindashboard__green-flag'>{item.seller_status}</span>
                          )}

                          {item.seller_status == 'Pending' && (
                            <span className='admindashboard__yellow-flag'>
                              {item.seller_status}
                            </span>
                          )}

                          {item.seller_status == 'Declined' && (
                            <span className='admindashboard__red-flag'>
                              {item.seller_status}
                            </span>
                          )}

{item.seller_status == 'Rescheduled' && (
                            <span className='admindashboard__blue-flag'>
                              {item.seller_status}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>

                  <td>
                    <div>
                      {item.payment_type}
                    </div>
                  </td>

                  <td>
                    <div>
                      {item.demo_status_id == 1 && (
                        <span className='admindashboard__yellow-flag'>{item.demo_status}</span>
                      )}

                      {item.demo_status_id == 4 && (
                        <span className='admindashboard__green-flag'>{item.demo_status}</span>
                      )}

{item.demo_status_id == 3 && (
                        <span className='admindashboard__red-flag'>{item.demo_status}</span>
                      )}
                    </div>
                  </td>
                </tr>
                  </>
                ))}
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BuyersWithDate;