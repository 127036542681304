import './Charges.css';
import {useEffect, useState} from 'react';
import {  getCharges } from '../../utils/api';
import FadeIn from '../../utils/fadein';

const Charges = ({userType}) => {
  const [charges, setCharges] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadCharges(){
      let res = await getCharges();

      if(res.success){
        setLoaded(true);
        setCharges(res.data);
      }
    }

    loadCharges();
  }, []);

  return (
    <div>
      <div className="account__back-link"><a href="/account/home">Back</a></div>
      <div className="account__header">Charges</div>

      <div>
        {charges.length > 0&& (
        <table className='table'>
          <tr>
            <th>Date</th>
            <th>Reference #</th>
            <th>Amount</th>
            <th>Receipt URL</th>
            <th></th>
          </tr>

          {charges.map(item => (
            <tr>
              <td>{new Date(item.date_created).toLocaleDateString()}</td>
              <td>{item.reference_id}</td>
              <td>{'$' + item.amount}</td>
              <td><a href={item.receipt_url} target='_blank' className='link'>View receipt</a></td>
              <td><span>Paid</span></td>
            </tr>
          ))}
        </table>
        )}

        {charges.length === 0 && (
          <div>
            <i>You have no charges currently</i>
          </div>
        )}
      </div>
    </div>
)
}

  export default Charges;