import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { adminPayouts, updatePayoutStatus } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEnvelope, FaGlobe, FaRegEnvelope } from 'react-icons/fa';

function Payouts() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [countPending, setCountPending] = useState(0);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await adminPayouts(200);

      if (res.success){
        setPayouts(res.data);
        setLoaded(true);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    loadData();
  }, []);

  async function loadData(){
    let res = await adminPayouts(200);

    if (res.success){
      setPayouts(res.data);
      setLoaded(true);
    }

    else{
      alert('failed to reload');
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }
    let res = await adminSearchUsers(query);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handlePaid(id, reference_id){
    setSubmitting(true);
    setSelectedId(id);

    let res = await updatePayoutStatus(reference_id, id);

    if (res.success){
      await loadData();
      setSelectedId(null);
      setSubmitting(false);
    }

    else{
      alert('failed to reload and retry');
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Payouts</title>
      </Helmet>

      <h1>Payouts</h1>

      <div className='admindashboard__cards'>
        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Payouts</strong></div>
          <div className='admindashboard__card-value'>
          {payouts.length}
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Pending</strong></div>
          <div className='admindashboard__card-value'>
          {payouts.filter(x => x.status == 'Pending').length}
          </div>
        </div>
      </div>

      <div className='admindashboard__search-lookup'>
        <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} />

        <div className='admindashboard__search-lookup-btn'>
          <button className='btn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && payouts.length === 0 && (
        <div className='center mt-20'><strong>Nothing to see here...</strong></div>
      )}

      {loaded && payouts.length > 0 && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th></th>
              <th>Requested on</th>
              <th>Reference ID</th>
              <th>User</th>
              <th>Company</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>

            {payouts.map((item, index) => (
              <tr>
                <td>
                  <strong>#{index + 1}</strong>
                </td>

                <td>
                  {new Date(item.date_created).toLocaleDateString()}
                </td>

                <td>
                  #{item.reference_id}
                </td>

                <td>
                {item.first_name} {item.last_name} - #{item.registered_user_id}
                </td>

                <td>
                  {item.company_name}
                </td>

                <td>
                  ${item.amount}
                </td>

                <td>
                  {item.status === 'Pending' && (
                    <>
                    <div>
                      <span className='admindashboard__yellow-flag'>{item.status}</span>
                    </div>

                    <div className='mt-10'>
                    <span>
                      {submitting === false && (
                      <button className='btn' onClick={() => handlePaid(item.user_payout_request_id, item.reference_id)}>
                      Mark as paid</button>
                      )}

                      {submitting === true && selectedId === item.user_payout_request_id && (
                        <Spinner />
                      )}
                      </span>
                      </div>
                    </>
                  )}
                  
                  {item.status === 'Paid' && (
                    <span className='admindashboard__green-flag'>{item.status}</span>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </>
  );
}

export default Payouts;