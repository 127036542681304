import React from 'react';
import { Heading, Box, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const CategoryLanding = () => {
  return (
    <>
      <Box px={24} py={24} mx='auto' mr={10} mb={36}>
        <Box maxW='5xl'>
          <Heading size='3xl' pb={16}>
            Category Title Goes Here
          </Heading>
          <Text fontSize='4xl' fontWeight='900' pb={4} pt={10}>
            Overview
          </Text>
          <Text fontSize={'lg'}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam,
            ipsa ipsam quidem, voluptates enim vitae explicabo architecto,
            minima molestiae veritatis voluptatibus. Hic consequuntur quae
            repellat necessitatibus qui itaque magni provident fugiat aspernatur
            quisquam harum eum, earum repudiandae saepe perferendis suscipit
            nulla aliquid mollitia? Alias eum explicabo ducimus enim iusto minus
            commodi consequatur! Sed, totam, commodi vitae error placeat
            quisquam maiores quis cupiditate incidunt iure consequatur officiis
            illo nisi ex? Nihil ex consectetur officiis? Perferendis,
            repellendus velit perspiciatis eveniet saepe voluptatibus ullam
            dignissimos nisi ipsum praesentium debitis cumque odio voluptates ea
            tenetur soluta commodi necessitatibus ducimus, dicta nobis!
            Reiciendis, sequi nemo?
          </Text>
          <Text fontSize='4xl' fontWeight='900' pb={4} pt={20}>
            Benefits
          </Text>
          <Text fontSize={'lg'}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
            dolorem. Maxime corrupti odit, perferendis recusandae consequatur
            asperiores cupiditate aperiam impedit omnis dolorem reiciendis fuga?
            Dolore necessitatibus sed illo quod aliquid unde tempore totam nulla
            nesciunt itaque voluptatum odio sit exercitationem non, in numquam
            ratione, eius repudiandae incidunt? Omnis nesciunt quidem neque nam
            ullam sint consectetur. Rem accusantium obcaecati suscipit officiis
            consequuntur amet cumque iusto doloremque quod dicta voluptates
            corrupti praesentium excepturi omnis, aperiam eveniet consectetur
            quos eaque inventore voluptatibus ipsum fugiat impedit aut!
            Doloribus labore illo magnam. Quidem, labore rerum beatae at facere
            consectetur commodi saepe praesentium aut tempore ipsum nisi, iusto
            non tempora placeat assumenda debitis iste eos excepturi sapiente
            velit? Ab tempora odio necessitatibus recusandae? Quisquam, quia
            nemo?
          </Text>
          <Text fontSize='4xl' fontWeight='900' pb={4} pt={20}>
            Recommendations
          </Text>
          <Text fontSize={'lg'}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
            dolorem. Maxime corrupti odit, perferendis recusandae consequatur
            asperiores cupiditate aperiam impedit omnis dolorem reiciendis fuga?
            Dolore necessitatibus sed illo quod aliquid unde tempore totam nulla
            nesciunt itaque voluptatum odio sit exercitationem non, in numquam
            ratione, eius repudiandae incidunt? Omnis nesciunt quidem neque nam
            ullam sint consectetur. Rem accusantium obcaecati suscipit officiis
            consequuntur amet cumque iusto doloremque quod dicta voluptates
            corrupti praesentium excepturi omnis, aperiam eveniet consectetur
            quos eaque inventore voluptatibus ipsum fugiat impedit aut!
            Doloribus labore illo magnam. Quidem, labore rerum beatae at facere
            consectetur commodi saepe praesentium aut tempore ipsum nisi, iusto
            non tempora placeat assumenda debitis iste eos excepturi sapiente
            velit? Ab tempora odio necessitatibus recusandae? Quisquam, quia
            nemo? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
            minima, nulla alias commodi est hic modi explicabo nemo eius sed ad
            maxime omnis quis, in dignissimos autem, asperiores officia ea.
          </Text>
          <Text fontSize='4xl' fontWeight='900' pb={4} pt={20}>
            Resources
          </Text>
          <Text fontSize={'lg'}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Dignissimos velit, temporibus labore quas optio dolorem iste nisi
            sapiente repellendus numquam veniam reprehenderit porro nihil
            commodi ducimus iusto dolore corrupti fugiat aperiam consequatur
            laudantium adipisci asperiores? Suscipit deleniti ea temporibus
            pariatur modi ad velit omnis, similique voluptas eum accusamus aut
            quod!
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default CategoryLanding;
