import { useState, useEffect } from 'react';
import { openedLeadCard, checkHasPaymentMethod, createDemoReminder, getUserCompany, getPackageItemsRemainingCount, getSellerRecommendationsForDashboard, addSellerRecommendationStatus, createBuyerDemo} from '../../utils/api';
import { refresh, getCurrentUser } from '../../services/auth.service';
import { FaRegEnvelope, FaTimes, FaPaperPlane, FaRegCheckCircle } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import { getBaseImageUrl, getTrimmedString } from '../../utils/utils';

const SellerDemosInvite = () => {
  const imagePath = process.env.REACT_APP_IMAGE_PATH;
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const [recommended, setRecommended] = useState([]);
  const [recommendedLoaded, setRecommendedLoaded] = useState(false);
  const [showBuyerInvite, setShowBuyerInvite] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [showInviteConfirm, setShowInviteConfirm] = useState(false);
  const [numberShowing, setNumberShowing] = useState(7);
  const [showLeavingWebsite, setShowLeavingWebsite] = useState(false);
  const [externalWebsite, setExternalWebsite] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = getBaseImageUrl();
  const navigate = useNavigate();

  async function handleLoadMore(){
    setNumberShowing(prev => prev + 7);
  }

  async function loadRecommended(){
    let res = await getSellerRecommendationsForDashboard();

    if (res.success){
      setRecommended(res.data);
      setRecommendedLoaded(true);
      setLoaded(true);
    }
  }

  useEffect(async () => {
    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getUserCompany();

        if (res.success){
          setUserCompany(res.data);
        }
      }
    }

    await loadUserCompany();
    await loadRecommended();
  }, []);

  async function handleInviteBuyer(item){
    setSelectedBuyer(item);
    setShowBuyerInvite(true);
  }

  async function handleBuyerNoMatch(item){
    let res = await addSellerRecommendationStatus(item.registered_user_id, 'Hide', 'Seller dashboard recommendation');

    if (res.success){
      let filtered = recommended.filter(x => x.registered_user_id !== item.registered_user_id);
      setRecommended(filtered);
    }
  }

  async function handleSendInvite(){
    setSendingRequest(true);
    let res = await addSellerRecommendationStatus(selectedBuyer.registered_user_id, 'Approved', 'Seller dashboard recommendation');

    if (res.success){
      let data2 = {buyer_id: selectedBuyer.registered_user_id, credit_cost: 5, message: 'Booked through dashboard recommendations'}
      await createBuyerDemo(data2);

      let _tmp = recommended.filter(x => x.registered_user_id !== selectedBuyer.registered_user_id);
      setRecommended(_tmp);
      setShowInviteConfirm(true);
      setSendingRequest(false);
    }
  }

  async function handleLeave(){

  }

  return (
    <>
      {loaded === false && (
        <Spinner />
      )}

    <div>
      {loaded && (
        <div className='dashboarddemo__no-results'>

          {recommended.length === 0 && (
            <>
              No matches were found with your current ideal customer profile selections. Please consider <Link className='link' to='/account/icp'>updating your preferences</Link> in order to increase your potential talent pool.
            </>
          )}

          {recommended.length > 0 && (
            <FadeIn delay={200} duration={200}>
            <div className='dashboard__recommended-buyers'>
              <h2 className='dashboard__recommended-buyers-h2'>Available to book</h2>

              <p className='mt-10 mb-10'>
                These candidates match your <Link className='link' to='/account/icp'>ideal customer profile</Link> selections and are ready to book. Send them an invite and track their application status.
              </p>

              <table className='dashboard__table table'>
              {recommended.slice(0, numberShowing).map(item => (
                <tr>
                    <td style={{width: '33%'}}>
                      <div className='flex va-m' style={{alignItems: 'center'}}>
                      <div className='dashboard__recommended-buyer-logo-wrapper'>
                        <div className='dashboard__recommended-buyer-logo'>
                          <div className='dashboard__table-company-logo'>
                            <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                          </div>
                        </div>
                      </div>

                      <div className='ml-10'>
                        <div className='dashboard__recommended-buyer-company-name'>    
                          {item.company_name}
                        </div>

                        <div className='dashboard__recommended-buyer-industry'>
                          {item.industry}
                        </div>

                        {item.description && item.description.length > 0 && (
                        <div className='dashboard__recommended-buyer-description'>
                          {getTrimmedString(item.description)}
                        </div>
                        )}

                        {item.website && item.website.length > 0 && (
                          <div className='mt-10 fs-12'>
                            <span className='link' target='_blank' rel='noreferrer' onClick={() => {
                              setExternalWebsite(item.website.startsWith('http') ? item.website : 'https://' + item.website);
                              setShowLeavingWebsite(true);
                            }}>Company website</span>
                          </div>
                        )}
                      </div>
                      </div>
                    </td>

                    <td style={{width: '25%'}}>
                      <div className='dashboard__recommended-buyer-name-wrapper'>
                        <div className='dashboard__recommended-buyer-name'>
                          {item.first_name} {item.last_name}
                        </div>

                        <div className='dashboard__recommended-buyer-job-title'>
                          {item.job_title}
                        </div>
                      </div>
                    </td>

                    <td style={{width: '25%'}}>
                      <ul className='dashboard__recommended-buyer-stats'>
                        <li><strong>Company size:</strong> {item.company_size}</li>
                        <li><strong>Department:</strong> {item.department}</li>
                        <li><strong>Seniority:</strong> {item.seniority}</li>
                        <li><strong>Country:</strong> {item.country}</li>
                      </ul>
                    </td>

                    <td style={{width: '25%'}}>
                      <div className='center'>
                        <button className='dashboard__recommended-buyer-btn' onClick={() => handleInviteBuyer(item)}>Send Invite</button>

                        <div className='mt-10 center fs-12'>
                          <span className='link link--cancel' onClick={() => handleBuyerNoMatch(item)}>Not a good match</span>
                        </div>
                      </div>
                    </td>
                </tr>
              ))}
              </table>
            </div>

            <div className='center mt-20'>
              <button className='btn' onClick={() => handleLoadMore()}>Load more</button>
            </div>
            </FadeIn>
          )}

          {recommendedLoaded === true && recommended.length === 0 && (
            <div>
              <p>We are looking for the best candidates currently that match your ICP's. Hang tight and we will notify you when your requests are ready to review.</p>

              <p className='mt-20'>
                Or you can start to fill your sales pipeline today by viewing our top <a href='/s/recommendations' className="link">available prospects</a>.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
 
      {showBuyerInvite && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => {
              setShowBuyerInvite(false);
              setShowInviteConfirm(false);
            }}>
              <FaTimes />
            </div>

            <div className='modal__h2'>
              Send a demo invite
            </div>

            <div className='flex va-m mt-10' style={{alignItems: 'center'}}>
              <div className='dashboard__recommended-buyer-logo-wrapper'>
                <div className='dashboard__recommended-buyer-logo'>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + selectedBuyer.logo} />
                  </div>
                </div>
              </div>

              <div className='dashboard__recommended-buyer-company-name ml-10'>{selectedBuyer.company_name}</div>
            </div>

            <div className='mt-20'>
              Send a friendly demo invite to this user to see if they are interested in your product/solution.
            </div>

            <div className='mt-20 center'>
              {sendingRequest === false && showInviteConfirm === false && (
              <button className='btn btn--cta btn--book' onClick={() => handleSendInvite()}><FaPaperPlane /> Send</button>
              )}

              {sendingRequest === false && showInviteConfirm === true && (
                <div className='bold'>
                  <span style={{color: 'mediumseagreen'}}><FaRegCheckCircle /></span> Invite has been sent! We will notify you if this person is interested in applying for a demo.
                </div>
              )}

              {sendingRequest && (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}

      {showLeavingWebsite && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowLeavingWebsite(false)}>
              <FaTimes />
            </div>

            <div className='modal__h2'>Leaving LeadrPro</div>

            <div>
              You are now leaving LeadrPro. Please note that our privacy policy does not apply to external websites. Click 'Continue' to proceed or 'Cancel' to stay on this page.
            </div>

            <div className='mt-20 center'>
              <a className='btn' rel='noreferrer' target='_blank' href={externalWebsite}>Continue</a>
            </div>
          </div>
        </>
      )}
      </>
  )
}

  export default  SellerDemosInvite;