import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV } from 'react-icons/fa';
import { DatasetController } from 'chart.js';
import { getCompanySaves } from '../../utils/api/admin';

function CompanySaves() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getCompanySaves();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }

      else if(res.status=== 403){
        await refresh();
        let res = await getCompanySaves();

        if (res.success){
          setData(res.data);
          setLoaded(true);
        }
      }
    }

    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin - Company Saves</title>
      </Helmet>

      <h1>Company Saves {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table dashboard__table table'>
            <tr>
              <th></th>
              <th></th>
              <th>Date saved</th>
              <th>Company</th>
              <th>Name</th>
              <th>Buyer company</th>
            </tr>

            {data.map((item, index) => (
              <tr>
                <td><strong>#{index}</strong></td>
                <td><div className='admindashboard__logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div></td>

                  <td>{new Date(item.date_created).toLocaleDateString()}</td>
                <td>
                  <div className='admindashboard__table-company-name'>{item.name} {`(#${item.company_id})`}</div></td>
                <td>{item.first_name} {item.last_name}</td>
                <td>{item.buyer_company_name}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </>
  );
}

export default CompanySaves;