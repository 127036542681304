import './Software.css';
import {useEffect, useState} from 'react';
import CategoryMultiSelect from '../../components/dropdowns/CategoryMultiSelect';
import { getUserInterest } from '../../utils/api';
import {Helmet} from "react-helmet";

const Software = ({userType}) => {
  const [categoriesHigh, setCategoriesHigh] = useState([]);
  const [categoriesMedium, setCategoriesMedium] = useState([]);
  const [categoriesLow, setCategoriesLow] = useState([]);

  useEffect(() => {
    async function loadCategories(){
      let res = await getUserInterest();

      if (res.success){
        let data_high = res.data.filter(item => item.interest_level === "High").map((item) => {
          if (item.interest_level==="High"){
            let tmp = {category_id: item.category_id, name: item.category_name};
            return tmp;
          }
        });

        let data_medium = res.data.filter(item => item.interest_level === "Medium").map((item) => {
          if (item.interest_level==="Medium"){
            let tmp = {category_id: item.category_id, name: item.category_name};
            return tmp;
          }
        });

        let data_low = res.data.filter(item => item.interest_level === "Low").map((item) => {
          if (item.interest_level==="Low"){
          let tmp = {category_id: item.category_id, name: item.category_name};
          return tmp;
          }
        });

        setCategoriesHigh(data_high);
        setCategoriesMedium(data_medium);
        setCategoriesLow(data_low);
      }
    }

    loadCategories();

    document.title = "LeadrPro - Software";
  }, []);

  return (
    <div className="software">
      <Helmet>
          <meta name="description" content="" />
      </Helmet>

      <div className="account__back-link"><a href="/account/home">Back</a></div>
      <div className="account__header">Software</div>

      <div>
        <div className="software__interests">
          <h3>High Interest</h3>

          <div className="software__high">
            <CategoryMultiSelect categories={categoriesHigh} setCategories={setCategoriesHigh} interestLevel="High" />
          </div>
        </div>

        <div className="software__interests">
          <h3>Medium Interest</h3>

          <div className="software__medium">
            <CategoryMultiSelect categories={categoriesMedium} setCategories={setCategoriesMedium} interestLevel="Medium" />
          </div>
        </div>

        <div className="software__interests">
          <h3>Low Interest</h3>

          <div className="software__low">
            <CategoryMultiSelect categories={categoriesLow} setCategories={setCategoriesLow} interestLevel="Low" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Software;