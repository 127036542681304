import './BecomeBuyer.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { addBuyerRole } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';

function BecomeBuyer() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  async function handleJoin(){
    setSubmitting(true);
    let res = await addBuyerRole();

    if (res.success){
      setTimeout(() => {
        setSubmitting(false);
        setShowConfirmation(true);
        localStorage.setItem('current_view', 'Buyer');
      }, 4000);
    }

    else if (res.status === 403){
      await refresh();
      let res = await addBuyerRole();

      if (res.success){
        setTimeout(() => {
          setSubmitting(false);
          setShowConfirmation(true);
          localStorage.setItem('current_view', 'Buyer');
        }, 4000);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <div className='becomeseller'>
        <div className='becomeseller__window'>
          {loaded === false && (
            <div className='center'>
              <Spinner />
            </div>
          )}

          {loaded && (
            <>
              <h1>Start discovering software</h1>

              <p>
                Get matched with customized software suggestions based on your profession and get paid 75$ when you successfully book a demo meeting.
              </p>

              <ul>
                <li>Create your free public profile page</li>
                <li>Browse through our catelog of innovative software solutions</li>
                <li>Get paid $75 when you successfully book a demo call</li>
              </ul>

              <div className='becomeseller__btns'>
                {submitting === false && (
                  <button className='btn btn--primary' onClick={() => handleJoin()}>Start Discovering Software</button>
                )}

                {submitting && (
                  <Spinner />
                )}
                <br/>
                <Link to='/account/workspace' className='link'>{'< Back to Workspace'}</Link>
              </div>
            </>
          )}
        </div>
      </div>

      {showConfirmation && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>Success!</h2>

            <p>
              You now have access to the buyer workspace, where you can find relevant software recommendations and manage your future demos.
            </p>

            <div className='mar20--top center'>
              <Link to='/account/workspace' className='link'>Take me to my Workspace</Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BecomeBuyer;
;
