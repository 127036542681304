import { useEffect, useState } from 'react';
import './DashboardDemo.css';
import futureDemoNoticeCalendar from '../../assets/design/dashboard/future-demo-notice-calendar.png';
import DeclinedPopup from './DeclinedPopup';
import RescheduleTimes from '../../components/qualifier/RescheduleTimes';
import SubmitReschedule from '../../components/qualifier/SubmitReschedule';
import CalendarLinks from '../../components/qualifier/CalendarLinks';
import { getBuyerDemosRescheduled, refreshToken } from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import { FaEllipsisV, FaRegCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import FadeIn from '../../utils/fadein';
import { refresh, getCurrentUser } from '../../services/auth.service';
import { getTrimmedString, getBaseImageUrl } from '../../utils/utils';
import BuyerDecline from './BuyerDecline';

const BuyerDemosRescheduled = ({ buyerDeclinedReasons }) => {
  const [currentDemo, setCurrentDemo] = useState({});
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [hasDemos, setHasDemos] = useState(true);
  const baseImageUrl = getBaseImageUrl();
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navigate = useNavigate();

  async function handleToggleMenu(item){
    if (item.demo_id == currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(true);
    }
  }

  useEffect(() => {
    async function loadDemos(){
      let res = await getBuyerDemosRescheduled();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getBuyerDemosRescheduled();

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
          }
      }
    }

    loadDemos();
  }, []);

  useEffect(() => {
    let _democount = demos.filter(item => (item.seller_status === 'Approved' || item.seller_status === 'Rescheduled') && (item.buyer_status === 'Approved' || item.buyer_status === 'Rescheduled') && item.demo_status === 'Pending').length;

    if (_democount > 0)
      setHasDemos(true);
    else
      setHasDemos(false);
  }, [demos]);

  function declinedClickHandle(demo, id) {
    setCurrentDemo(demo);
    setShowDeclinedPopup(true);
    setShowSubMenu(false);
  }

  function handleRescheduleClick(item) {
    setCurrentDemo(item);
    setShowReschedule(true);
    setShowSubMenu(false);
  }

  return (
    <>
      {loaded === false && (
        <div className='center'>
          <Spinner />
        </div>
      )}

      {loaded && demos.length === 0 && (
        <div className='dashboarddemo__no-results'>
          <h2>No rescheduled demos found</h2>
        </div>
      )}

      {loaded && demos.length > 0 && (
        <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
          <tr>
            <th>Company</th>
            <th>Status</th>
            <th></th>
          </tr>
        {loaded && demos.map((item) => (
            <tr>
              <td style={{width: '60%'}}>
              <div style={{display: 'flex', alignItems: 'start'}}>
              <div className='buyerdashboard__recommendation-thumbnail' style={{width: '100%'}}>
              {item.thumbnail_image && (
                <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                )}
              </div>
              
              <div className='buyerdashboard__recommendation-name'>
                <div style={{fontSize: '20px'}}>{item.company_name}</div>

                <div className='dashboarddemo__company-name'>
                      <div className='dashboarddemo__company-info-items'>
                      {item.seller_first_name && item.seller_first_name.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Meeting with:{' '}
                        </span>
                        <span>{item.seller_first_name}</span>{' '}

                        {item.seller_last_name && item.seller_last_name.length > 0 && (
                          <span>
                            {item.seller_last_name.substring(0,1).toUpperCase()}.
                          </span>
                        )}
                      </div>
                    )}

                    {item.seller_email && item.seller_email.length > 0 && (
                      <div className='dashboarddemo__company-info-item mt-10'>
                        <span className='dashboarddemo__company-info-label'>
                          Follow-up email:{' '}
                        </span>
                        <span>{item.seller_email}</span>{' '}
                      </div>
                    )}

                    <div className='mt-10'>
                      <span>Original date: </span>
                      <span className='dashboard__table-date'>
                      {new Date(
                        item.demo_date
                      ).toLocaleDateString()}
                      </span>
                    </div>
                    </div>
                  </div>
              </div>
              </div>
            </td>

            <td>
              {item.buyer_status === 'Rescheduled' && item.seller_status === 'Approved' && (
                <p className='dashboarddemo__actions-text'>Times submitted. Waiting for confirmation</p>
                  )}

                {item.seller_status === 'Rescheduled' && (
                  <div className='center' style={{marginBottom: "10px"}}>
                    <button className='btn' onClick={() => handleRescheduleClick(item)}>Submit New Times</button>
                  </div>
                )}
            </td>

            <td className='dashboard__table-menu-icon'>
                <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                  <FaEllipsisV />
                </span>

                {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                  <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      {item.reference_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                      )}

                      <div onClick={() => declinedClickHandle(item, item.demo_id)} className='dashboard__submenu-item'>Cancel demo</div>
                    </div>
                  </FadeIn>
                )}
              </td>

              {/* <div className='dashboarddemo'>
                  <div className='dashboarddemo__company-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>

                  <div className='dashboarddemo__company-info'>
                    <span className='dashboarddemo__company-name'>
                      Demo with {item.company_name}
                    </span>

                    <div className='dashboarddemo__company-info-items'>
                      <div className='dashboarddemo__company-info-item'>
                          <span className='dashboarddemo__company-info-label'>Date rescheduled: </span>
                          <span>{new Date(item.date_rescheduled).toLocaleDateString()}</span>
                        </div>

                    {item.seller_first_name && item.seller_first_name.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Meeting with:{' '}
                        </span>
                        <span>{item.seller_first_name}</span>{' '}

                        {item.seller_last_name && item.seller_last_name.length > 0 && (
                          <span>
                            {item.seller_last_name.substring(0,1).toUpperCase()}.
                          </span>
                        )}
                      </div>
                    )}
                    </div>

                    {item.description && item.description.length > 0 && (
                      <div className='link__dash' onClick={() => companyInfoClickHandle(item)}>
                        More about company
                      </div>
                    )}
                  </div>

                    <div className='dashboarddemo__date'>
                    {item.seller_status === 'Rescheduled' && (
                        <span className='dashboarddemo__rescheduling-request-tag'>Reschedule Requested</span>
                      )}
                      
                      <strong>Original date</strong>

                      {item.reschedule_date_time && (
                        <>
                          <div className={(item.buyer_status === 'Rescheduled' || item.seller_status === 'Rescheduled') ? 'dashboarddemo__date-rescheduling': ''}>
                            <>
                            {weekDays[new Date(item.reschedule_date_time).getDay()]}{' '}
                              {new Date(
                                item.reschedule_date_time
                              ).toLocaleDateString()}{' '}
                              <br/>
                              at{' '}
                              {new Date(
                                item.reschedule_date_time
                              ).toLocaleTimeString()}
                            </>
                          </div>

                          <div className='dashboarddemo__date-timezone'>
                              {item.timezone}
                          </div>
                        </>
                      )}

                      {!item.reschedule_date_time && (
                        <>
                          <div className={(item.buyer_status === 'Rescheduled' || item.seller_status === 'Rescheduled') ? 'dashboarddemo__date-rescheduling': ''}>
                            <>
                            {weekDays[new Date(item.scheduled_date_time).getDay()]}{' '}
                              {new Date(
                                item.scheduled_date_time
                              ).toLocaleDateString()}{' '}
                              <br/>
                              at{' '}
                              {new Date(
                                item.scheduled_date_time
                              ).toLocaleTimeString()}
                            </>
                          </div>

                          <div className='dashboarddemo__date-timezone'>
                              {item.timezone}
                          </div>
                        </>
                      )}
                    </div>

                    <div className='dashboarddemo__actions'>
                      {item.buyer_status === 'Approved' && item.seller_status === 'Approved' && (
                        <button className='btn btn--primary' onClick={() => futureDemoNoticeClick(item)}>
                          Meeting Details
                        </button>
                      )}

                      {item.buyer_status === 'Rescheduled' && item.seller_status === 'Approved' && (
                        <p className='dashboarddemo__actions-text'>Waiting for confirmation</p>
                      )}

                      {item.seller_status === 'Rescheduled' && (
                        <div style={{marginBottom: "10px"}}>
                          <button className='btn btn--primary btn--dark' onClick={() => handleRescheduleClick(item)}>Submit New Times</button>
                        </div>
                      )}

                    <div>
                      <a href={"/dashboard/message/" + item.reference_id} className='btn btn--primary'>Send a message</a>
                    </div>

                      <div className='dashboarddemo__actions-links'>
                        <div className='btn btn--primary btn--cancel-demo' onClick={() => declinedClickHandle(item, item.demo_id)}>
                          Cancel Demo
                        </div>
                      </div>
                    </div>
              </div> */}
            </tr>
          ))}
          </table>
          </div>
      )}

    {showDeclinedPopup &&
     (
      <div>
        <BuyerDecline
          showDeclinedPopup={showDeclinedPopup}
          setShowDeclinedPopup={setShowDeclinedPopup}
          role='Buyer'
          buyerDeclinedReasons={buyerDeclinedReasons}
          currentDemo={currentDemo}
          setCurrentDemo={setCurrentDemo}
          demos={demos}
          setDemos={setDemos}
          source='Cancel'
        />

        {/* <DeclinedPopup
          showDeclinedPopup={showDeclinedPopup}
          setShowDeclinedPopup={setShowDeclinedPopup}
          role='Buyer'
          buyerDeclinedReasons={buyerDeclinedReasons}
          currentDemo={currentDemo}
          setCurrentDemo={setCurrentDemo}
          demos={demos}
          setDemos={setDemos}
          source='Cancel'
        /> */}
      </div>
    )}

    {showReschedule && (
      <>
        <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              {currentDemo.booking_style === 'daily_view' && (
                <RescheduleTimes demo={currentDemo} demos={demos} setDemos={setDemos} demo_id={currentDemo.demo_id} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} showSteps={true} setShowReschedule={setShowReschedule} enableReason={false} />
              )}

              {currentDemo.booking_style === 'list_view' && (
                <SubmitReschedule demo={currentDemo} demos={demos} setDemos={setDemos} demo_id={currentDemo.demo_id} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} showSteps={true} setShowReschedule={setShowReschedule} enableReason={false} />
              )}
            </div>
        </div>
      </>
    )}
    </>
  )
}

export default BuyerDemosRescheduled;