import { useState, useEffect } from 'react';
import './VerifyPhoneNumber.css';
import { sendPhoneVerification, verifyPhoneToken, hRefreshToken } from '../../utils/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerifyPhoneNumber = ({phoneNumber, role}) => {
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  let refCode1 = React.createRef();
  let refCode2 = React.createRef();
  let refCode3 = React.createRef();
  let refCode4 = React.createRef();

  useEffect(() => {
    //alert(role);
  }, []);

  async function validateCode(code){
    const res = await verifyPhoneToken(phoneNumber, code);

    if (res.success) {
        if (res.data===true){
            setHasError(false);
            setError('');

            alert(role);
            if (role === "Seller"){
                window.location = '/dashboard/seller';
            }

            else
                window.location = '/account/payout';
        }

        else{
            setHasError(true);
            setError('Invalid SMS code');
        }
    }

    else {
        await hRefreshToken();

        if (role === 'Seller'){
            window.location = '/dashboard/seller';
        }
        window.location = '/account/payout';
    }
  }

  function checkCode(){
    let code = '';
    code = `${code1}${code2}${code3}${code4}`;

    if (code.length === 4){
        validateCode(code);
    }
  }

  async function resendCode(e){
      e.preventDefault();
    const res = await sendPhoneVerification(phoneNumber);

    if (res.success){
        setMessage("Code has been sent!");
    }

    else{
        setMessage("Whoops! Something went wrong. Please try again later.");
    }
  }

  useEffect(() => {
    setCode1(code1.substring(0, 1));
    checkCode();
  }, [code1]);

  useEffect(() => {
    setCode2(code2.substring(0, 1));
    checkCode();
  }, [code2]);

  useEffect(() => {
    setCode3(code3.substring(0, 1));
    checkCode();
  }, [code3]);

  useEffect(() => {
    setCode4(code4.substring(0, 1));
    checkCode();
  }, [code4]);

  return (
    <div className="center card__wrapper">
        <div className="card card--small">
            <div className="card__inner">
                <h1 className="card__header">Please verify your phone number</h1>
                    <div>
                        <form>
                            <p className="card__text">
                            You're almost there! We've sent code to <strong>{phoneNumber}</strong>
                            </p>

                        <div className="verifyphonenumber__codeinputparent">
                            <input className='verifyphonenumber__codeinput' type='text' id='code1' required placeholder='' value={code1} ref={refCode1} name="code1" onChange={(e) => setCode1(e.target.value)} />
                            
                            <input className='verifyphonenumber__codeinput' type='text' id='code2' required placeholder='' value={code2} ref={refCode2} name="code2" onChange={(e) => setCode2(e.target.value)} />
                            
                            <input className='verifyphonenumber__codeinput' type='text' id='code3' required placeholder='' value={code3} ref={refCode3} name="code3" onChange={(e) => setCode3(e.target.value)} />
                            
                            <input className='verifyphonenumber__codeinput' type='text' id='code4' required placeholder='' value={code4} ref={refCode4} name="code4" onChange={(e) => setCode4(e.target.value)} />
                        </div>

                        {hasError && (
                        <div>
                            <span className="card-text" style={{color: "#FF374C"}}>{error}</span>
                        </div>
                        )}

                        {message.length > 0 && (
                            <div>
                                {message}
                            </div>
                        )}

                        <div>
                            <span className="card-text">Didn't get the code?</span>
                        </div>

                        <div>
                            <button className='btn btn-primary' onClick={(e) => resendCode(e)}>
                                Resend code
                            </button>
                        </div>

                        <p className="card-text center">Need help? <a href="/contact">Contact us</a></p>
                    </form>
                </div>
            </div>
        </div>
        </div>
  )
}

export default VerifyPhoneNumber;

