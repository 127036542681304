import React from 'react';
import './AppLanding.css';
import {
  Box,
  chakra,
  Flex,
  Link,
  Image,
  Center,
} from '@chakra-ui/react';
import {
  VStack,
  Heading,
  Text,
  HStack,
  Button,
} from '@chakra-ui/react';
import mobile from './homeAssets/mobileTopHero.png';
import desktop from './homeAssets/desktopTopHero.png';
import apple from './homeAssets/appleStoreBtn.png';
import googlePlay from './homeAssets/googleBtn.png';
import swipe_meet from '../../assets/design/app/swipe_meet.png';
import hero2 from '../../assets/design/app/app_hero2.png';
import chase from '../../assets/design/logos/jp.jpg';
import mucker from '../../assets/design/logos/mucker.png';
import kl from '../../assets/design/logos/kl.png';
import { Helmet } from 'react-helmet';

const AppLanding = () => {
  return (
    <>
    <Helmet>
          <meta name="description" content="" />
          <title>{`LeadrPro Mobile App`}</title>
          <link rel="canonical" href="https://www.leadrpro.com/app" />
        </Helmet>
    <>
    <Box overflowX='hidden' mb='60px'>
        <Center>
          <Flex mt='80px' alignItems='center' className='applanding__section'>
            <VStack align='start' maxW='450px' mr='80px'>
              <Heading
                fontFamily='poppins'
                fontSize='42px'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'>
                  <div>
                  <a href="https://www.producthunt.com/posts/leadrpro-for-events?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-leadrpro&#0045;for&#0045;events" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=431555&theme=light&period=daily" alt="LeadrPro&#0032;for&#0032;Events - The&#0032;networking&#0032;app&#0032;for&#0032;events | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
                  </div>

                Swipe, Match and Meet
              </Heading>
              <Text
                textAlign='left'
                fontSize='18px'
                fontFamily='poppins'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                Connect with 50k+ enterprise prospects in real time.
              </Text>
              
              <HStack pt='25px'>
                <a
                  target='_blank'
                  href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
                  <Image
                    src={apple}
                    mr='15px'
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>

                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
                  <Image
                    src={googlePlay}
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>
              </HStack>
            </VStack>

            <Box position='relative' ml='30px'>
              <img src={swipe_meet} />
            </Box>
          </Flex>
        </Center>
      </Box>

      <Box overflowX='hidden' mb='60px'>
        <Center>
          <Flex mt='80px' alignItems='center' className='applanding__section'>
            <VStack align='start' maxW='450px' mr='80px'>
              <Heading
                fontFamily='poppins'
                fontSize='42px'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'>

                Get Paid to Demo New Software
              </Heading>
              <Text
                textAlign='left'
                fontSize='18px'
                fontFamily='poppins'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                Find solutions and get paid instantly via PayPal, Venmo, CashApp, Zelle, Amazon or Visa Gift Card.
              </Text>
              
              <HStack pt='25px'>
                <a
                  target='_blank'
                  href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
                  <Image
                    src={apple}
                    mr='15px'
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>

                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
                  <Image
                    src={googlePlay}
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>
              </HStack>
            </VStack>

            <Box position='relative'>
              <img src={hero2} />
            </Box>
          </Flex>
        </Center>
      </Box>

      <Box overflowX='hidden' mb='150px'>
        <Center>
          <Flex mt='80px' alignItems='center' className='applanding__section'>
            <VStack align='start' maxW='450px'>
              <Heading
                fontFamily='poppins'
                fontSize='42px'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'>
                Gain Access to Exclusive Events
              </Heading>
              <Text
                textAlign='left'
                fontSize='18px'
                fontFamily='poppins'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                Find events and conferences, connect with peers beforehand, prioritize and coordinate in-person meetings to maximize productivity.
              </Text>
              
              <HStack pt='25px'>
                <a
                  target='_blank'
                  href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
                  <Image
                    src={apple}
                    mr='15px'
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>

                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
                  <Image
                    src={googlePlay}
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>
              </HStack>
            </VStack>

            <Box position='relative' ml='150px'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/VKfeG0aujOI?si=BVJSAdlwt_oARBFf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Box>
          </Flex>
        </Center>
      </Box>

      <Box>
          <div className='center'>
            <h3>EVENT SPONSORS</h3>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <div><img src={chase} style={{height: '80px'}} /></div>
            <div><img src={kl} style={{height: '80px'}} /></div>
            <div><img src={mucker} style={{height: '80px'}} /></div>
          </div>
      </Box>
    </>

      <Box mt={10} mb={32} mx={20}>
        <Center>
          <Flex className='applanding__articles'>
            <Box className='applanding__article'>
              <Flex alignItems='center' justifyContent='center'>
                <Box
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/software'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Browse our Software Solutions
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Discover the latest innovative software solutions for your
                      business and get paid to participate in live software
                      demos. New vendors added almost every day!
                    </chakra.p>
                  </Box>

                  <Box mt={4}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.20.58_PM.png?updatedAt=1682706090138' />
                  </Box>
                </Box>
              </Flex>
            </Box>

            <Box className='applanding__article'>
              <Flex alignItems='center' justifyContent='center'>
                <Box
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/blog'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Check out our Blog
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Check out the LeadrPro blog to stay up to date on the
                      latest trends in tech and learn industry secrets!
                    </chakra.p>
                  </Box>

                  <Box mt={4}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.31.30_PM.png?updatedAt=1682707041435' />
                  </Box>
                </Box>
              </Flex>
            </Box>

            <Box className='applanding__article'>
              <Flex alignItems='center' justifyContent='center'>
                <Box
                  
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/signup'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Create your free account today
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Sign up today and start booking demos! Whether you have an
                      innovative software solution you'd like to sell or if
                      you'd like to get paid to discover new software for your
                      business... signing up is fast, free and easy!
                    </chakra.p>
                  </Box>

                  <Box mt={6}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.34.31_PM.png?updatedAt=1682706914400' />
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default AppLanding;
