import './CompleteProfileExistingBuyer.css';
import { useState, useEffect } from "react";
import React from 'react';
import { getSeniorityLevels, saveUserProfile, updateUser, getDepartments, getCountries } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';

const CompleteProfileEvents = ({setShowPhoneNumber,setShowProfile,setShowLogoAndTimezone,companyId,setCurrentStep,userId,roleType}) => {
  const [jobTitle, setJobTitle] = useState('');
  const [seniority, setSeniority] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(226);
  const [submitting, setSubmitting] = useState(false);
  let { redirect } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCountries(){
      let res = await getCountries();

      if (res.success){
        setCountries(res.data);
      }
    }

    senioritySearch();
    loadDepartments();
    loadCountries();
  }, []);

  async function senioritySearch() {
    let data = await getSeniorityLevels();
    if (data.data) {
      setSeniority(data.data);
    }
  }

  async function loadDepartments() {
    let data = await getDepartments();
    if (data.data) {
      setDepartments(data.data);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitting(true);
    let el1 = e.target.elements.senioritylevel.value;
    let el2 = e.target.elements.jobtitle.value;
    let el3 = selectedDepartment;

    let res = await saveUserProfile(el2, el1, el3, linkedin);

    if (res.success){
      await updateUser({first_name: firstName, last_name: lastName, country_id: selectedCountry});

      setSubmitting(false);
      setShowPhoneNumber(false);
      setShowProfile(false);
      setShowLogoAndTimezone(false);
      //setCurrentStep(6);

      const searchParams = new URLSearchParams(window.location.search);
      const decodedUrl = decodeURIComponent(searchParams.get('redirect'));

      // console.log('decodeurl', decodedUrl);
      // if (decodedUrl != null && decodedUrl.length > 0){
      //   window.location = decodedUrl;
      // }

      // else{
        navigate('/events');
     // }

      // const parsed = queryString.parse(window.location.search);
      // let _redirect = '';

      // if (parsed["redirect"]){
      //   _redirect = parsed["redirect"];
      //   if (_redirect && _redirect.length > 0){
      //     navigate(`${_redirect}`);
      //   }
      // }

      // else
      // navigate('/events');
    }

    else if (res.status === 403){
      await refresh();

      let res = await saveUserProfile(el2, el1, el3, linkedin);

      if (res.success){
        await updateUser({first_name: firstName, last_name: lastName, country_id: selectedCountry});

        setSubmitting(false);
        setShowPhoneNumber(false);
        setShowProfile(false);
        setShowLogoAndTimezone(false);

        navigate('/events');
        //setCurrentStep(6);
      }
    }
  }

  async function handleDepartmentChange(e){
    setSelectedDepartment(e.target.value);
  }

  return (
    <div className='card__wrapper center'>
      <div className='card'>
        <div className="card__inner">
          <p>
            <span style={{fontWeight: "bold", fontSize: "20px"}}>2. Tell us a little about yourself</span>
          </p>

          <form className='' onSubmit={handleSubmit}>
            <div className="form-group" style={{display:"flex", justifyContent: "space-between"}}>
              <div style={{marginRight:"5px"}}>
                <label className="label input-label" htmlFor='txtFirstName'>First name</label>
                <input type="text" autoComplete='off' required value={firstName} onChange={(event) => setFirstName(event.target.value)}
                className="input" id="txtFirstName" name="firstname" placeholder="First name" />
              </div>

              <div style={{marginLeft: "5px"}}>
                <label className="label input-label" htmlFor='txtLastName'>Last name</label>
                <input type="text" autoComplete='off' required value={lastName} onChange={(event) => setLastName(event.target.value)} className="input" id="txtLastName" name="lastname" placeholder="Last name" />
              </div>
            </div>

            <div className='form-group'>
              <label className='label input-label' htmlFor='txtJobTitle'>Job title</label>
              <input
                autoComplete='off'
                value={jobTitle}
                onChange={(event) => setJobTitle(event.target.value)}
                className='input_box'
                type='text'
                required
                name='jobtitle'
                id='txtJobTitle'
                placeholder='Enter job title'
              />
            </div>

            <div style={{display: 'flex'}}>
            <div className='form-group' style={{width: '100%'}}>
              <label className='label input-label' htmlFor='select_seniority'>Seniority</label>
              <select
                className='input_box dropdown-font'
                type='text'
                id='select_seniority'
                name='senioritylevel'
                required
                placeholder='Enter seniority'>
                {seniority.length > 0 &&
                  seniority.map((item) => (
                    <option value={item.seniority_level_id}>{item.name}</option>
                  ))}
              </select>
            </div>

            <div className='form-group' style={{marginLeft: '5px', width: '100%'}}>
              <label className='label input-label' htmlFor='selectDepartment'>Department</label>
              <select id='selectDepartment' className='input' required onChange={(e) => handleDepartmentChange(e)}>
                  {departments.map((item, index) => (
                    <>
                      <option key={index} value={item.department_id}>{item.name}</option>
                    </>
                  ))}
              </select>
            </div>
            </div>

            {/* {roleType !== '2' && (
            <div className='form-group'>
              <label className="label input-label" htmlFor='txtLinkedIn'>LinkedIn profile</label>
              <input type="text" autoComplete='off' required value={linkedin} onChange={(event) => setLinkedin(event.target.value)}
              className="input" id="txtLinkedIn" name="linkedin" placeholder="LinkedIn URL" />
            </div>
            )} */}

            <div className='form-group'>
            <label className="label input-label" htmlFor='selectCountry'>Country</label>
            <select className='input' id='selectCountry' value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              <option>Select country</option>

              {countries.map(item => (
                <option value={item.country_id}>{item.display_name}</option>
              ))}
            </select>
          </div>

          <div className='form-group center' style={{marginTop: "30px"}}>
            {submitting === false && (
            <button className='btn btn-primary'>Next</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfileEvents;
