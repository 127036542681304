import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AdminFeedbackMessages, AdminAddFeedbackNote, AdminUpdateFeedbackFormStatus } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes, FaRegStickyNote } from 'react-icons/fa';

function FeedbackMessages() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [currentTab, setCurrentTab] = useState('All');
  const [selectedId, setSelectedId] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const [noteText, setNoteText] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await AdminFeedbackMessages();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }

    else if(res.status=== 403){
      await refresh();
      let res = await AdminFeedbackMessages();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await AdminFeedbackMessages();

      if (res.success){
        setData(res.data);
        setSubData(res.data);
        setLoaded(true);
      }

      else if(res.status=== 403){
        await refresh();
        let res = await AdminFeedbackMessages();

        if (res.success){
          setData(res.data);
          setSubData(res.data);
          setLoaded(true);
        }
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    if (currentTab === 'All'){
        setSubData(data);
    }

    else if (currentTab === 'Unread'){
      setSubData(data.filter(x => x.status === 'Unread'));
    }

    else if (currentTab === 'Read'){
      setSubData(data.filter(x => x.status === 'Resolved'));
    }
  }, [currentTab]);

  async function handleUpdateStatus(id, status){
    let res = await AdminUpdateFeedbackFormStatus(id, status);

    console.log('res', res);
    console.log('res success', res.success);
    if (res.success){
      loadData();
    }

    else if (res.status === 403){
      await refresh();

      let res = await AdminUpdateFeedbackFormStatus(id, status);

      if (res.success){
        loadData();
      }
    }
  }

  async function handleAddNote(id){
    setSelectedId(id);
    setShowNote(true);
  }

  async function submitNote(){
    let res = await AdminAddFeedbackNote(selectedId, noteText);

    if (res.success){
      loadData();
      setShowNote(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await AdminAddFeedbackNote(selectedId, noteText);

      if (res.success){
        loadData();
        setShowNote(false);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Feedback Messages {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div className='admindashboard__filters'>
            <button className='btn' onClick={() => setCurrentTab('All')}>All</button>
            <button className='btn' onClick={() => setCurrentTab('Unread')}>Unread</button>
            <button className='btn' onClick={() => setCurrentTab('Resolved')}>Resolved</button>
          </div>

          <table className='table admindashboard__table'>
            <tr>
              <th></th>
              <th>From</th>
              <th>Message</th>
              <th>Staus</th>
              <th>Note</th>
            </tr>

            {data.map(item => (
              <tr>
                <td>
                <div className='admindashboard__logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
                </td>

                <td><div>
                  <div>
                    <strong>{item.first_name} {item.last_name}</strong>
                  </div>

                  <div>
                    {item.name}
                  </div>
                </div>
                </td>

                <td style={{width: '30%'}}>
                  <div>
                    {new Date(item.date_created).toDateString()}
                  </div>

                  <div className='mt-10' style={{fontSize: '20px', fontWeight:'bold'}}>
                    Re: {item.subject}
                  </div>

                    <div className='mt-10'>
                      <strong>{item.feedback_text}</strong>
                    </div>
                  </td>

                <td>
                  {item.status === 'Unread' && (
                    <div>
                    <span className='admindashboard__red-flag'>{item.status}</span>
                    <span className='ml-10'><button className='btn' onClick={() => handleUpdateStatus(item.feedback_id, 'Read')}>Mark as read</button></span>
                    </div>
                  )}

                  {item.status === 'Read' && (
                    <div>
                      <span className='admindashboard__blue-flag'>{item.status}</span>
                      <span className='ml-10'>
                        <button className='btn' onClick={() => handleUpdateStatus(item.feedback_id, 'Resolved')}>Resolved</button>
                      </span>
                    </div>
                  )}

                  {item.status === 'Resolved' && (
                    <span className='admindashboard__green-label'>{item.status}</span>
                  )}
                </td>

                <td>
                  {item.admin_note && item.admin_note.length > 0 && (
                    <i>{item.admin_note}</i>
                  )}

                  {(item.admin_note == null || item.admin_note.length === 0) && (
                    <button className='btn' onClick={() => handleAddNote(item.feedback_id)}><FaRegStickyNote /></button>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

      {showNote && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowNote(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
              <textarea className='input textarea' value={noteText} onChange={(e) => setNoteText(e.target.value)} placeholder='Add note'></textarea>

              <div className='mt-10'><button className='btn' onClick={() => submitNote()}>Add note</button></div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FeedbackMessages;