import './Payouts.css';
import {useEffect, useState} from 'react';
import { getPayoutTransactions, refreshToken } from '../../utils/api';
import {Helmet} from "react-helmet";
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import ScrollToTop from '../../components/layout/ScrollToTop';
import Spinner from '../../components/layout/Spinner';
import { refresh } from '../../services/auth.service';

const Payouts = () => {
  const [transactions, setTransactions] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
      async function loadTransactions(){
        let res = await getPayoutTransactions();
        if (res.success){
          setTransactions(res.data);
          setLoading(false);
        }

        else if (res.status === 403){
          let _refresh = await refreshToken();

          if (_refresh.success){
            localStorage.setItem('access_token', _refresh.data.accessToken);
            localStorage.setItem('refresh_token', _refresh.data.refreshToken);

            let res = await getPayoutTransactions();
            if (res.success){
              setTransactions(res.data);
              setLoading(false);
            }
          }
        }

        else{
          setTransactions([]);
        }
      }

      loadTransactions();

      document.title = "LeadrPro - Demo Payouts";
  }, []);

  return (
    <div className="payoutinfo">
      <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className=""><button className='btn' onClick={() => navigate(-1)}>Back</button></div>
      <div className="account__header">Payout History</div>

      {loading === false && transactions.length > 0 && (
        <div>
          * Note that payments take 3-5 days to process and are sent out on Friday's. If you have not received your payment within that timeframe, please feel free to reach out <Link className='link' to={'/leave-feedback'}>support</Link>.
        </div>
      )}

      <div className="payoutinfo__transactions">
        {loading && (
          <Spinner />
        )}

        {loading === false && transactions.length == 0 && (
          <div>
            <strong>You have not completed any demos yet.</strong>
          </div>
        )}

        {loading === false && transactions.length > 0 && (
        <div className="payoutinfo__table">
          <table className="table dashboard__table">
            <thead>
            <tr>
              <th>Refernce #</th>
              <th>Completed Date</th>
              <th>Company</th>
              <th>Amount</th>
              <th>Status</th>
              {/* <th>Payout Link</th> */}
            </tr>
            </thead>

            <tbody>
            {transactions && (
              transactions.map((item, index) => (
              <tr key={index}>
                <td>{item.reference_id}</td>
                <td>{new Date(item.date_created).toLocaleDateString()}</td>
                <td><span className='payoutinfo__company-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </span> <span>{item.company_name}</span></td>
                <td>${item.amount}</td>
                <td><span className={`payoutinfo__transaction--${item.status.toLowerCase()}`}>{item.status}</span></td>
                {/* <td>
                  {item.payment_link && (
                    <span>
                      <a href={item.payment_link} target="_blakn" className='payouts__payout-link'>Payout link</a>
                    </span>
                  )}
                </td> */}
              </tr>
            ))
            )}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </div>
  )
}

  export default Payouts;