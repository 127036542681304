import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { getCategoryFromSlug, getCompaniesForCategoryBySlug } from "../../utils/api";
import "./CategoryDetails.css";
import { getTrimmedString } from "../../utils/utils";
import ScrollToTop from "../../components/layout/ScrollToTop";
import { FaStar, FaRegStar } from "react-icons/fa";

function CategoryDetails() {
  const [loaded, setLoaded] = useState(false);
  const [categoryLoaded, setCategoryLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState(null);
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  let { slug } = useParams();

  useEffect(() => {
    async function loadData() {
      let res = await getCompaniesForCategoryBySlug(slug);
      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    async function loadCategory(){
      let res = await getCategoryFromSlug(slug);
      if (res.success){
        setCategory(res.data);
        setCategoryLoaded(true);
      }
    }

    loadCategory();
    loadData();
  }, []);

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  return (
    <div className="categorydetails">
      <ScrollToTop />
      <Helmet>
        {categoryLoaded === true && category != null && (
            <title>{`${category.name} Software Solutions`}</title>
        )}

        {categoryLoaded === true && category != null && (
            <link rel="canonical" href={`https://www.leadrpro.com/category/${category.slug}`} />
        )}

        {categoryLoaded === true && category.meta_description && category.meta_description.length > 0 && (
          <meta name='description' content={category.meta_description} />
        )}
      </Helmet>

      {loaded === false && (
        <>
          <div className='categorydetails__placeholders'>
            <div className='categorydetails__placeholder'></div>
            <div className='categorydetails__placeholder'></div>
            <div className='categorydetails__placeholder'></div>
            <div className='categorydetails__placeholder'></div>
            <div className='categorydetails__placeholder'></div>
          </div>
        </>
      )}

      {loaded && (
       <div className="categorydetails__items">
        <div>
          <Link className="link" to={'/categories'}>Back to call categories</Link>
        </div>

        {categoryLoaded && (
          <h1>
            {category.name} Solutions
          </h1>
        )}

        {data.map((item, index) => (
          <div className="categorydetails__item" key={index}>
            <div className='flex'>
              <div className='buyerdashboard__recommendation-thumbnail'>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                )}
              </div>
                
              <div className='buyerdashboard__recommendation-name'>
                <div>{item.name}</div>

                <div className="company__review">
                  {item.average_rating && (
                    <div className="flex">
                        {getStars(Math.ceil(item.average_rating))}

                        <div className='ml-20'>
                          <span className="" style={{fontWeight: 'bold', fontSize: '12px'}}>{Math.round(item.average_rating * 10) / 10} out of 5</span>
                        </div>
                    </div>
                  )}
                </div>

                <div className='buyerdashboard__recommendation-description'>
                  {item.description && item.description.length > 0 && (
                    <>
                      {getTrimmedString(item.description)}
                    </>
                  )}
                </div>

                <div>
                  <Link className="btn" to={`/company/${item.company_id}/${item.url_slug}`}>View company profile</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
       </div>
      )}
    </div>
  );
}

export default CategoryDetails;