import './SellerBooking.css';
import { useState, useEffect } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import {
  getBuyerWorkHours,
  getSellerWorkHours,
  getUserWorkHours,
  getSellerDemoHours,
  acceptDemoBuyer,
} from '../../utils/api';
import Spinner from '../layout/Spinner';

function SellerBookingBuyer({
  selectedBuyer,
  setSelectedBuyer,
  setShowSelectedBuyers,
  setShowSelectedTime,
  demo_id,
  showAcceptedPopup,
  setShowAcceptedPopup,
}) {
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [buyerWorkHours, setBuyerWorkHours] = useState([]);
  const [sellerWorkHours, setSellerWorkHours] = useState([]);
  const [selectedDateFormatted, setSelectedDateFormatted] = useState('');
  const [disableDates, setDisableDates] = useState([]);
  const [startDate, setStartDate] = useState();
  const [currentMonth, setCurrentMonth] = useState(0);
  let minDate = new Date();
  minDate.setDate(minDate.getDate() + 2);

  let imageUrl = process.env.REACT_APP_IMAGE_URL;
  let dayOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    async function loadWorkHours() {
      //let res = await getBuyerWorkHours();
      let res = await getSellerWorkHours(selectedBuyer.seller_id);

      if (res.success) {
        setSellerWorkHours(res.data);
      }
    }

    // current users work hours
    async function loadBuyerWorkHours() {
      let res = await getUserWorkHours();
      if (res.success) {
        setBuyerWorkHours(res.data);
      }
    }

    loadWorkHours();
    loadBuyerWorkHours();
  }, []);

  useEffect(() => {
    console.log('buyer work hours', buyerWorkHours);
  }, [buyerWorkHours]);

  useEffect(() => {
    console.log('seller work hours', selectedBuyer.seller_id, sellerWorkHours);
  }, [sellerWorkHours]);

  useEffect(() => {
    //setDisableDates([]);
    let _alldisabled = [];
    let tmp_disabled = [];

    // if user has some work hours, disable any and all dates not on list
    if (sellerWorkHours.length > 0) {
      let _weekdays = getWeekDaysForMonth(
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      );

      let _day_of_week = sellerWorkHours.map((item) => item.day_of_week);

      console.log('day of week', _day_of_week);
      let remaining = [];

      // ex: 1, 2, 3 -> mon, tue, wed
      for (let i = 0; i < 7; i++) {
        if (_day_of_week.includes(parseInt(i))) {

        } else {
          // days to disable
          remaining.push(i);
        }
      }

      console.log('remaining', remaining);

      // disable all days found in _weekdays matching remaining days
      for (let x = 0; x < remaining.length; x++) {
        let tmp2 = _weekdays[remaining[x]];
        for (let y = 0; y < tmp2.length; y++) {
          let _tmp3 = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            tmp2[y]
          );

          tmp_disabled.push(_tmp3);
        }
      }


      //setDisableDates([[], ...tmp_disabled]);
      //setDisableDates(tmp_disabled);

      // remaining are days to remove from calendar
      // iterate through _weekdays for those days and add to disabled dates
    }

    // buyers
    if (buyerWorkHours.length > 0) {
      let _weekdays = getWeekDaysForMonth(
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      );
      let _day_of_week = buyerWorkHours.map((item) => item.day_of_week);
      let remaining = [];

      for (let i = 0; i < 7; i++) {
        if (_day_of_week.includes(parseInt(i))) {
        } else {
          remaining.push(i);
        }
      }

      //let tmp_disabled = [];

      // disable all days found in _weekdays matching remaining days
      for (let x = 0; x < remaining.length; x++) {
        let tmp2 = _weekdays[remaining[x]];
        for (let y = 0; y < tmp2.length; y++) {
          let _tmp3 = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            tmp2[y]
          );

          //tmp_disabled.push(_tmp3);
          //setDisableDates([...disableDates, _tmp3]);
        }
      }

      //setDisableDates([...disableDates, ...tmp_disabled]);

      // remaining are days to remove from calendar
      // iterate through _weekdays for those days and add to disabled dates
    }

    let _overflow = [];
    console.log('selecte date', selectedDate);
    for (let i = 1; i < 7; i++){
      let _month = selectedDate.getMonth();
      _month += 1;
      let _tmpdate = new Date(selectedDate.getFullYear(), _month, i);
      
      _overflow.push(_tmpdate);
    }
    
    _overflow.push(new Date('9/11/2022'));

    _alldisabled = tmp_disabled.concat(_overflow);
    setDisableDates(_alldisabled);
    //setDisableDates([...disableDates, ..._overflow]);
    
  }, [sellerWorkHours, buyerWorkHours, startDate]);

  useEffect(() => {
    //console.log(disableDates);

    disableDates.map((item) => {
      //   console.log('ccc', item);
      //console.log('aaa', item.getFullYear());
    });
  }, [disableDates]);

  useEffect(() => {
    let day = selectedDate.getDay();
    let month = selectedDate.getMonth();
    let date = selectedDate.getDate();

    if (day > 0)
      setSelectedDateFormatted(
        `${dayOfWeek[day - 1]}, ${months[month]} ${date}`
      );
    else setSelectedDateFormatted(`${dayOfWeek[6]}, ${months[month]} ${date}`);

    loadDemoHours();
  }, [selectedDate]);

  async function handleCancelTime() {
    setShowSelectedTime(false);
    // setShowSelectedBuyers(true);
  }

  async function handleSaveTime() {
    if (selectedTime === 0) {
      alert('Must select a valid time');
      return;
    }
    selectedBuyer.scheduled_date = selectedDate;
    selectedBuyer.scheduled_time = selectedTime;

    let updatedValue = {
      scheduled_date: selectedDate,
      scheduled_time: selectedTime,
    };

    // setSelectedBuyer(buyer => ({
    //   ...buyer,
    //   ...updatedValue
    // }));

    let _date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
    let _minutes = Math.floor((selectedTime / 100)) * 60;

    if (selectedTime % 100 > 0)
      _minutes += 30;

    var newDateObj = new Date(_date.getTime() + _minutes*60000);   
     console.log('new date', _date);
     console.log('new date 2', newDateObj);

    // used for dashboard rescheduling
    if (demo_id && demo_id > 0) {
      // reschedule the demo
      // rescheduleDemoSeller(demo_id, selected_date, selected_time)
      // alt: rescheduleDemoBuyer(...)
      let res3 = await acceptDemoBuyer(demo_id, selectedDate, selectedTime, newDateObj);

      if (res3.success) {
        console.log('demo has been accepted');
      }
    }

    setShowSelectedTime(false);

    //set show state for next modal
    setShowAcceptedPopup(true);
  }

  function timeConvert(n) {
    var num = n;
    var hours = num / 100;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 100;
    var rminutes = Math.round(minutes);

    if (n === 0) {
      return `12:00AM`;
    } else if (n === 30) {
      return `12:30AM`;
    } else {
      return `${rhours > 12 ? rhours - 12 : rhours}:${
        rminutes < 10 ? '0' + rminutes : rminutes
      } ${rhours > 11 ? 'PM' : 'AM'}`;
    }
  }

  async function handleOnChange(e) {}

  async function handleHourClick(i) {
    setSelectedTime(i);
  }

  // run through every day in the month
  // store the weekday that each day lands on
  function getWeekDaysForMonth(month, year) {
    let last_day = new Date(year, month, 0).getDate();
    let result = {};

    for (let i = 1; i <= last_day; i++) {
      let _date = new Date(year, month, i);
      let _day = _date.getDay();

      if (_day in result) {
        result[_day].push(i);
      } else {
        result[_day] = [];
        result[_day].push(i);
      }
    }

    return result;
  }

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  // if works hours are null on both sides, no limits
  // if one side is null, use the other sides min and max
  // if both sides are not null,
  function loadDemoHours() {
    // get buyer hours for selected day
    // get seller hours for selected day
    console.log('timezone', selectedBuyer.timezone);
    console.log('timezone seller', selectedBuyer.seller_timezone);

    let hours_b = buyerWorkHours.filter(
      (item) => item.day_of_week === selectedDate.getDay()
    );

    let hours_s = sellerWorkHours.filter(
      (item) => item.day_of_week === selectedDate.getDay()
    );

    let start = 0;
    let end = 2400;

    // if (
    //   (buyerWorkHours.length > 0 && hours_b.length === 0) ||
    //   (sellerWorkHours.length > 0 && hours_s.length === 0)
    // ) {
    //   return <div>Not available</div>;
    // }

     if (
      (sellerWorkHours.length > 0 && hours_s.length === 0)
    ) {
      return <div>Not available</div>;
    }

    // if (hours_b.length === 0 && hours_s.length === 0) {

    // } else if (hours_b.length > 0 && hours_s.length === 0) {
    //   console.log('hours_s', hours_s);
    //   start = hours_b[0].start_time;
    //   end = hours_b[0].end_time;
    // } else if (hours_b.length === 0 && hours_s.length > 0) {
    //   // use seller hours as the hour limits
    //   start = hours_s[0].start_time;
    //   end = hours_s[0].end_time;
    // } else {
    //   start =
    //     hours_s[0].start_time > hours_b[0].start_time
    //       ? hours_s[0].start_time
    //       : hours_b[0].start_time;
    //   end =
    //     hours_s[0].end_time < hours_b[0].end_time
    //       ? hours_s[0].end_time
    //       : hours_b[0].end_time;
    // }

    if (hours_b.length === 0 && hours_s.length === 0) {
      
    } else if (hours_s.length > 0) {
      // use seller hours as the hour limits
      start = hours_s[0].start_time;
      end = hours_s[0].end_time;
    } else {
      // start =
      //   hours_s[0].start_time > hours_b[0].start_time
      //     ? hours_s[0].start_time
      //     : hours_b[0].start_time;
      // end =
      //   hours_s[0].end_time < hours_b[0].end_time
      //     ? hours_s[0].end_time
      //     : hours_b[0].end_time;
    }

    let seller_timezone = '';

    if (selectedBuyer.seller_timezone)
      seller_timezone = selectedBuyer.seller_timezone;
    else
      seller_timezone = 'America/Los_Angeles';

    let _now = new Date();
    let seller_offset = getOffset(seller_timezone);
    let buyer_offset = _now.getTimezoneOffset();
    console.log('seller offset', seller_offset);
    console.log('buyer offset', buyer_offset);
    let diff = (Math.abs(seller_offset) - Math.abs(buyer_offset)) / 60;
    console.log('diff', diff);

    let el = [];

    start += (diff * 100);
    end += (diff * 100);

    if (start % 100 !== 0) {
      start -= 30;
    }

    for (let i = start; i < end; i += 100) {
      el.push(
        <div
          key={i}
          className={`demo__hours-item ${
            selectedTime === i ? 'demo__hours-item--selected' : ''
          }`}
          data-value={i}
          onClick={() => handleHourClick(i)}>
          {timeConvert(i)}
        </div>
      );

      el.push(
        <div
          key={i + 30}
          className={`demo__hours-item ${
            selectedTime === i + 30 ? 'demo__hours-item--selected' : ''
          }`}
          data-value={i + 30}
          onClick={() => handleHourClick(i + 30)}>
          {timeConvert(i + 30)}
        </div>
      );
    }

    return <div className='demo__hours'>{el}</div>;
  }

  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      // if (datesToAddClassTo.find(dDate => isSameDay(dDate, date))) {
      //   return 'myClassName';
      // }
    }
  }

  function handleClickDay(e) {
    console.log('selected date', e);
    setSelectedDate(e);
    setSelectedTime(0);
  }

  function handleClickMonth(e) {
    console.log('month has changed');
  }

  function handleStartChange(e) {
    console.log('selected date has changed', e);
    let month = e.activeStartDate.getMonth();
    let now = new Date();
    let month_now = now.getMonth();
    console.log('month', month);
    console.log('month now', month_now);

    setStartDate(e.activeStartDate);
    setCurrentMonth(e.activeStartDate.getMonth());

    if (now !== month_now) {
      console.log('other month');
      setSelectedDate(e.activeStartDate);
    } else {
      console.log('this month');
      setSelectedDate(now);
    }
  }

  useEffect(() => {}, [startDate]);
  return (
    <div className='selectedBuyers__time modal'>
      <span className='modal__close' onClick={() => handleCancelTime()}>
        X
      </span>

      <div style={{width: "150px", textAlign: "center"}}>
        <div>
          Schedule your meeting with {selectedBuyer.company_name}
        </div>
        {/* <div className='account__back-link'>
          <span className='link' onClick={() => setShowSelectedTime(false)}>
            Back
          </span>
        </div> */}

        <div>
          <div className='selectedBuyers__time-logo'>
            {selectedBuyer.logo && (
              <img
                src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + selectedBuyer.logo}
                alt='logo found'
              />
            )}
            {!selectedBuyer.logo && (
              <span className='selectedBuyers__initial'>
                {selectedBuyer.company_name[0]}
              </span>
            )}
          </div>
        </div>

        <div className='selectedBuyers__role'>
          <div className='selectedBuyers__persona-type'>
            {selectedBuyer.persona_type}
          </div>
          <div
            className='selectedBuyers__company-name'
            style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '15px' }}>
            {selectedBuyer.company_name}
          </div>
        </div>

        <div className='selectedBuyers__company-info'>
          <div>{selectedBuyer.seniority}</div>
          <div>{selectedBuyer.company_size}</div>
          <div>{selectedBuyer.industry_name}</div>
        </div>

        <div style={{ marginTop: '42px' }}>
          <div style={{ fontWeight: '500', fontSize: '9px' }}>Credit cost</div>
          <div className='selectedBuyers__time-cost'>
            {selectedBuyer.credits}
          </div>
        </div>
      </div>

      <div>
        <Calendar
          onChange={handleOnChange}
          minDate={minDate}
          value={selectedDate}
          tileClassName={tileClassName}
          onActiveStartDateChange={handleStartChange}
          onClickDay={handleClickDay}
          onClickMonth={handleClickMonth}
          tileDisabled={({ date, view }) =>
            view === 'month' && // Block day tiles only
            disableDates.some(
              (disabledDate) =>
                date.getFullYear() === disabledDate.getFullYear() &&
                date.getMonth() === disabledDate.getMonth() &&
                date.getDate() === disabledDate.getDate()
            )
          }
        />
      </div>

      <div className='selectedBuyers__time-hours'>
        <div className='selectedBuyers__selected-date'>
          {selectedDateFormatted}
        </div>

        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          {loadDemoHours()}
        </div>

        <div>
          <button
            className='btn btn--primary btn--full'
            onClick={() => handleSaveTime()}>
            Book Meeting
          </button>
        </div>

        <div className='center'>
          <span
            className='link link--cancel'
            onClick={() => handleCancelTime()}>
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
}

export default SellerBookingBuyer;
