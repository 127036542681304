import './SearchFilters.css';
import SellerFilters from './SellerFilters';
import BuyerFilters from './BuyerFilters';
import classNames from "classnames";

const SearchFilters = ({userType, showFilter, setShowFilter, setSelectedFilters, selectedFilters, removeFilter}) => {
  async function clearFilters(){
    setSelectedFilters([]);
  }

  return (
      <div className={classNames("searchfilters", {
        "show":showFilter
    })}>
          {/* <h3 className="searchfilters__header"> Filters
            <span className="searchfilter__clear-all link" onClick={() => clearFilters()}>Clear all</span>
          </h3>

          {userType === "Seller" && <SellerFilters setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} removeFilter={removeFilter} setShowFilter={setShowFilter} />} */}

          {userType === "Buyer" &&
          <> 
            <BuyerFilters setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} removeFilter={removeFilter} setShowFilter={setShowFilter} /> 
          </>}
      </div>
  )
}

export default SearchFilters;