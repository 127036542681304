import { useState, useEffect } from "react";
import './CreateEvent.css';
import { getEvent, eventRegistration, createEvent, getEventIdFromSlug, resetPassword } from '../../utils/api';
import {
  getCurrentUser,
  isAuthed,
  logout,
  isSeller,
  isBuyer,
  refresh
} from '../../services/auth.service';
import classNames from "classnames";
import { useNavigate, useParams, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ScrollToTop from "../../components/layout/ScrollToTop";
import "react-datepicker/dist/react-datepicker.css";
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import { getSeoName } from "../../utils/utils";

function Redirect() {
    const [event, setEvent] = useState([]);
    let {slug} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      document.title = "LeadrPro - Manage Events";

     async function checkSlug(){
      let res = await getEventIdFromSlug(slug);

      if (res.success){
        let event_id = res.data;
        navigate(`/event/${event_id}/${slug}`);
      }
     }

     checkSlug();

    }, []);

    return (
        <div className="createevent">
          <ScrollToTop />
        <Helmet>
          <meta name="description" content="" />

          {event && (
            <title>{`Create new event`}</title>
          )}
        </Helmet>
        </div>
    );
}

export default Redirect;