import { useState, useEffect } from 'react';
import './BuyerProfile.css';
import { getCurrentUser } from '../../services/auth.service';
import { refreshToken, getUserProfile, getUserCompany, getSeniorityLevels, getCompanySizes, getCountries, getDepartments, updateUserProfile, updateUser, addUrlSlug, getAllIndustries, updateCompany} from '../../utils/api';
import Spinner from '../../components/layout/Spinner';
import {useNavigate} from 'react-router-dom';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'
import FadeIn from '../../utils/fadein';
import moment from 'moment-timezone';
import close from '../../assets/design/dashboard/close.png';
import { FaClock, FaUser, FaEdit, FaRegEdit, FaPen, FaPenSquare, FaPenAlt, FaListOl, FaCreditCard, FaPencilAlt, FaRegUserCircle, faRegCopy } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BuyerProfile = () => {
  let user = getCurrentUser();
  const [loaded ,setLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState('About');
  const [showAboutEdit, setShowAboutEdit] = useState(false);
  const [showBioEdit, setShowBioEdit] = useState(false);
  const [showSocialEdit, setShowSocialEdit] = useState(false);
  const [showCompanyInfoEdit, setShowCompanyInfoEdit] = useState(false);
  const [showCompanyInfoEdit2, setShowCompanyInfoEdit2] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [seniority, setSeniority] = useState([]);
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [biography, setBiography] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [website, setWebsite] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [newSeniority, setNewSeniority] = useState(null);
  const [newTimezone, setNewTimezone] = useState(null);
  const [seniorityLevel, setSeniorityLevel] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [seniorityId, setSeniorityId] = useState(null);
  const [companySizeId, setCompanySizeId] = useState(null);
  const [industryId, setIndustryId] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSeniority, setSelectedSeniority] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(0);
  const [countryName, setCountryName] = useState('');
  const [slug, setSlug] = useState('');
  const [isSeller, setIsSeller] = useState(false);
  const [hasSellerRole, setHasSellerRole] = useState(false);
  const [copied, setCopied] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    let _domain = 'domain=leadrpro.com';
    document.cookie =
      cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
  }

  useEffect(async () => {
    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        setUserProfile(res.data);
        setJobTitle(res.data.job_title);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setBiography(res.data.biography);
        setDepartmentId(res.data.department_id);
        setNewSeniority(res.data.seniority_level_id);
        setNewTimezone(res.data.tz);
        setWebsite(res.data.website);
        setIndustryId(res.data.industry_id);
        setLinkedin(res.data.linkedin_url);
        setSelectedCountry(res.data.country_id);
        setCountryName(res.data.country_name);
        setSelectedSeniority(res.data.seniority_level_id);
        setLoaded(true);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await getUserProfile();

          if (res.success){
            setUserProfile(res.data);
            setJobTitle(res.data.job_title);
            setFirstName(res.data.first_name);
            setLastName(res.data.last_name);
            setBiography(res.data.biography);
            setDepartmentId(res.data.department_id);
            setSeniority(res.data.seniority_level_id);
            setNewSeniority(res.data.seniority_level_id);
            setWebsite(res.data.website);
            setIndustryId(res.data.industry_id);
            setNewTimezone(res.data.tz);
            setSelectedCountry(res.data.country_id);
            setCountryName(res.data.country_name);
            setSelectedSeniority(res.data.seniority_level_id);
            setLoaded(true);
          }
        }
      }
    }

    async function loadUserCompany(){
      let res = await getUserCompany();

      if (res.success){
        setUserCompany(res.data);
        setCompanyName(res.data.name);
        setCompanyDescription(res.data.description);
        setCompanySizeId(res.data.company_size_id);
        setIsSeller(res.data.is_seller);
        setHasSellerRole(res.data.has_seller_role);
        setLoaded(true);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await getUserCompany();

          if (res.success){
            setUserCompany(res.data);
            setCompanyName(res.data.name);
            setCompanyDescription(res.data.description);
            setCompanySizeId(res.data.company_size_id);
            setIsSeller(res.data.is_seller);
            setHasSellerRole(res.data.has_seller_role);
            setLoaded(true);
          }
        }
      }
    }

    async function loadSeniority(){
      let data = await getSeniorityLevels();
      setSeniority(data.data);
    }

    async function loadCountries(){
      let res = await getCountries();

      if (res.success){
        setCountries(res.data);
      }
    }

    async function loadCompanySizes(){
      let res = await getCompanySizes();

      if (res.success){
        setCompanySizes(res.data);
      }
    }

    async function loadIndustries(){
      let res = await getAllIndustries();

      if (res.success){
        setIndustries(res.data);
      }
    }

    async function loadTimezones(){
      const timezoneList = moment.tz.names();
      setTimezones(timezoneList);
    }

    async function loadDepartments() {
      let data = await getDepartments();
      if (data.data) {
        setDepartments(data.data);
      }
    }

    await loadCountries();
    await loadDepartments();
    await loadCompanySizes();
    await loadIndustries();
    await loadTimezones();
    await loadSeniority();
    await loadUserProfile();
    await loadUserCompany();
  }, []);

  async function handleDepartmentChange(e){
    setSelectedDepartment(e.target.value);
    setDepartmentId(e.target.value);
  }

  async function handleSeniorityChange(e){
    console.log(e.target.value);
    setSelectedSeniority(e.target.value);
    setSeniorityId(e.target.value);
  }

  async function handleBioSubmit(){
    if (firstName.length === 0 || lastName.length === 0){
      alert("Must enter a valid name");
      return;
    }

    setShowBioEdit(false);

    let data = {first_name: firstName, last_name: lastName, biography};
    let res = await updateUser(data);

    if (res.success){
      setShowBioEdit(false);
      setUserProfile(prevState => ({
        ...prevState, first_name: firstName, last_name: lastName, biography: biography}));
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

        let res = await updateUser(data);

        if (res.success){
          setShowBioEdit(false);
          setUserProfile(prevState => ({
            ...prevState, first_name: firstName, last_name: lastName, biography: biography}));
        }
      }
    }
  }

  async function handleSocialSubmit(){
    let data = {linkedin_url: linkedin};
    let res = await updateUserProfile(data);

    if (res.success){
      setShowSocialEdit(false);
      setUserProfile(prevState => ({
        ...prevState, linkedin_url: linkedin}));
    }
  }

  async function handleJobPositionSubmit(){
    setShowAboutEdit(false);

    let data = {job_title: jobTitle, department_id: departmentId, seniority_level_id: selectedSeniority};
    let res = await updateUserProfile(data);

    if (res.success){
      let res2 = await updateUser({country_id: selectedCountry});
      let _seniority = seniority.filter(x => x.seniority_level_id == selectedSeniority)[0];
      let _department = departments.filter(x => x.department_id == departmentId)[0];

      setShowAboutEdit(false);
      setUserProfile(prevState => ({
        ...prevState, job_title: jobTitle, department_id: departmentId, department_name: _department.name, seniority_level_id: selectedSeniority, seniority: _seniority.name}));

      let country = countries.filter(x => x.country_id == selectedCountry)[0];
      setCountryName(country.display_name);

      setUserCompany(prevState => ({
        ...prevState, country_id: selectedCountry, country_name: country.display_name
      }))
    }
  }

  function validateSlug(slug) {
    // regular expression to match a valid slug
    const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  
    // check if the slug matches the regular expression
    return regex.test(slug);
  }

  async function handleUrlSubmit(){
    if (slug.length > 0){
      let valid = validateSlug(slug.toLocaleLowerCase());
      if (valid === false){
        alert("Invalid url format");
        return;
      }
      let res = await addUrlSlug(slug.toLowerCase());

      if (res.success){
        setUserProfile(prevState => ({
          ...prevState, slug: slug}));
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

          let res = await addUrlSlug(slug.toLowerCase());

            if (res.success){
              setUserProfile(prevState => ({
                ...prevState, slug: slug}));
            }

            else{
              alert("URL is already taken");
            }
        }
      }

      else{
        alert("URL is already taken");
      }
    }

    else{
      alert("Must enter a valid url path");
    }
  }

  async function handleCompanyInfoSave(){
    let data = {name: companyName, description: companyDescription}
    let res = await updateCompany(data);

    if (res.success){
      setShowCompanyInfoEdit(false);
      setUserCompany(prevState => ({
        ...prevState, name: companyName, description: companyDescription}));
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

        let res = await updateCompany(data);

        if (res.success){
          setShowCompanyInfoEdit(false);
          setUserCompany(prevState => ({
            ...prevState, name: companyName, description: companyDescription}));
        }
      }
    }
  }

  async function handleCompanyInfoSave2(){
     let data = {company_size_id: companySizeId, industry_id: industryId, website: website};
     let res = await updateCompany(data);

    if (res.success){
      let _size = companySizes.filter(x => x.company_size_id == companySizeId)[0];
      let _industry = industries.filter(x => x.industry_id == industryId)[0];

      setShowCompanyInfoEdit2(false);
      setUserProfile(prevState => ({
        ...prevState, company_size_id: companySizeId, industry_id: industryId, website: website, company_size: _size.value, industry: _industry.name}));
    }

    else if (res.status === 403){
      let _refresh = await refreshToken();

      if (_refresh.success){
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        setCookie('access_token', _refresh.data.accessToken, 1);  // 12.19.2022

        let data = {company_size_id: companySizeId, industry_id: industryId, website: website};
        let res = await updateCompany(data);

        if (res.success){
          let _size = companySizes.filter(x => x.company_size_id == companySizeId)[0];
          let _industry = industries.filter(x => x.industry_id == industryId)[0];

          setShowCompanyInfoEdit2(false);
          setUserProfile(prevState => ({
            ...prevState, company_size_id: companySizeId, industry_id: industryId, website: website, company_size: _size.value, industry: _industry.name}));
        }
     }
    }
  }

  async function handleCountrySelect(item){
    setSelectedCountry(item);
  }

  async function onError(){

  }

  async function onSuccess(e){
    if (e.name){
    setUserProfile({
      ...userProfile,profile_image: e.name
      });

      let res = await updateUserProfile({profile_image: e.name});
    }
  }

  async function onCompanyLogoSuccess(e){
    if (e.name){
    setUserCompany({
      ...userCompany,logo: e.name
      });

    let res = await updateCompany({logo: e.name});
    }
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <>
      <div className='buyerprofile'>
        {userProfile && userProfile.is_buyer && (
          <>
            <h1 className='buyerdashboard__h1'>Your Profile</h1>
            {/* <p>
              Create your own public page and have software providers book with you directly.
            </p> */}
          </>
        )}

        {userProfile && userProfile.is_buyer === false && (
          <h1 className='buyerdashboard__h1'>Edit Your Profile</h1>
        )}

        <div className='buyerprofile__tabs'>
          <div className={currentTab === 'About' ? 'active': ''} onClick={() => setCurrentTab('About')}>About Me</div>
          <div className={currentTab === 'Company' ? 'active': ''} onClick={() => setCurrentTab('Company')}>My Company</div>
          {/* <div className={currentTab === 'Rewards' ? 'active': ''} onClick={() => setCurrentTab('Rewards')}>My Rewards</div> */}
        </div>

        {currentTab === 'About' && loaded && (
            <FadeIn delay={200} duration={200}>
        <div className='buyerprofile__main'>
          {!userProfile.slug && (
            <>
            <div><strong>Public URL</strong></div>

            {userProfile.is_buyer && (
            <p style={{marginTop:"10px", marginBottom: "10px", fontSize: "14px"}}>
              Make money from your SPAM.
            </p>
            )}

            <div className='buyerprofile__profile-url-missing'>
              <div>
                <span className='buyerprofile__profile-url'>https://www.leadrpro.com/profile/</span><input type='text' className='input' value={slug} placeholder='Enter custom URL' onChange={(e) => setSlug(e.target.value)} />
                
              </div>

              <div>
              <span className='link' style={{marginLeft: "5px"}} onClick={() => handleUrlSubmit()}>Save</span>
              </div>
            </div>
            </>
              )}

              {userProfile.slug && userProfile.slug.length > 0 && (
                <>
                <strong>Public URL</strong>

                {userProfile.is_buyer && (
                <p style={{marginTop:"10px", marginBottom: "10px", fontSize: "14px"}}>
                  Copy and paste the following blurb and reply to all solicitors on email or LinkedIn:
                </p>
                )}

                {userProfile.is_buyer && (
                <p className='buyerprofile__url-blurb' style={{marginTop:"10px", marginBottom: "10px", fontSize: "14px"}}>
                  Thanks for reaching out. If you are sincerely interested in meeting with me, book a time here: https://www.leadrpro.com/profile/{userProfile.slug}
                </p>
                )}

                <div className='buyerprofile__profile-url-missing'>
                    <div>
                      <span className='buyerprofile__profile-url'>https://www.leadrpro.com/profile/{userProfile.slug}</span>

                      <span className='buyerprofile__copy-link-wrapper'>
                      <CopyToClipboard text={`https://www.leadrpro.com/profile/${userProfile.slug}`} onCopy={handleCopy}>
                        <button className='buyerprofile__copy-link'>{copied ? 'Copied!' : 'Copy'}</button>
                </CopyToClipboard>
                      </span>
                    </div>
                  </div>
                </>
              )}

          <div>
            <strong>Profile Info</strong>
          </div>
          <div className='buyerprofile__main-item buyerprofile__about-me-header'>
            <span className='buyerprofile__edit-icon' onClick={() => setShowBioEdit(true)}>
              <FaRegEdit />
            </span>

            <div className='buyerprofile__about-me-image'>
              {userProfile.profile_image && userProfile.profile_image !== '' && (
                <img src={baseImageUrl + 'profileimages/' + userProfile.profile_image} alt="logo found" />
              )}

              {(!userProfile.profile_image || userProfile.profile_image === '') && (
                <img src={iconCompanyDefault} alt="logo not found" />
              )}
            </div>

            <div className='buyerprofile__about-me-bio'>
              <h2 className='buyerprofile__about-me-name'>{userProfile.first_name} {userProfile.last_name}</h2>

              <p className='buyerprofile__about-me-bio-text'>
                {userProfile.biography && userProfile.biography.length > 0 && (
                  <>
                    {userProfile.biography}
                  </>
                )}

                {(!userProfile.biography || userProfile.biography == '') && (
                  <>
                    <i>No biography found</i>
                  </>
                )}
              </p>

              {userProfile.slug && userProfile.slug.length > 0 && (
                <div className='buyerprofile__profile-url'>
                  <a href={'/profile/' + userProfile.slug}>https://www.leadrpro.com/profile/{userProfile.slug}</a>
                </div>
              )}
            </div>
          </div>

          <div className='buyerprofile__main-item'>
            <div className='buyerprofile__about-me-info'>
              <div><span className='buyerprofile__edit-icon' onClick={() => setShowAboutEdit(true)}>
              <FaRegEdit />
                </span></div>
              <div className='buyerprofile__list-item'>
                <div className='buyerprofile__list-item-label'><strong>Job Title</strong></div>
                <div className='buyerprofile__list-item-value'>{userProfile.job_title}</div>
              </div>

              <div className='buyerprofile__list-item'>
                <div className='buyerprofile__list-item-label'><strong>Seniority</strong></div>
                <div className='buyerprofile__list-item-value'>{userProfile.seniority}</div>
              </div>

              <div className='buyerprofile__list-item'>
                <div className='buyerprofile__list-item-label'><strong>Department</strong></div>
                <div className='buyerprofile__list-item-value'>{userProfile.department_name}</div>
              </div>

              <div className='buyerprofile__list-item'>
                <div className='buyerprofile__list-item-label'><strong>Location</strong></div>
                <div className='buyerprofile__list-item-value'>{countryName}</div>
              </div>

              {/* <div className='buyerprofile__list-item'>
                <div className='buyerprofile__list-item-label'><strong>Timezone</strong></div>
                <div className='buyerprofile__list-item-value'>{userProfile.tz}</div>
              </div> */}
            </div>
          </div>

          <div className='buyerprofile__main-item'>
            <div>
              <div className='buyerprofile__social-item'>
              <span className='buyerprofile__edit-icon' onClick={() => setShowSocialEdit(true)}>
                <FaRegEdit />
              </span>

                <div className='buyerprofile__social-item-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                </div>

                <div className='buyerprofile__social-item-name'><strong>LinkedIn</strong></div>

                {userProfile.linkedin_url && (
                  <div className='buyerprofile__social-item-url'><a href={userProfile.linkedin_url}>{userProfile.linkedin_url}</a></div>
                )}

                {!userProfile.linkedin_url && (
                  <div className='buyerprofile__social-item-url'>
                    <i>Not set</i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        </FadeIn>
        )}

        {currentTab === 'Company' && (
          <FadeIn delay={200} duration={200}>
            <div className='buyerprofile__main'>
              <div className='buyerprofile__main-item buyerprofile__about-me-header'>
                {((isSeller && hasSellerRole) || !isSeller) && (
                  <span className='buyerprofile__edit-icon' onClick={() => setShowCompanyInfoEdit(true)}>
                    <FaRegEdit />
                  </span>
                )}

                <div className='buyerprofile__about-me-image'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + userCompany.logo} />
                </div>

                <div className='buyerprofile__about-me-bio'>
                  <h2 className='buyerprofile__about-me-name'>{userCompany.name}</h2>

                  <div className='buyerprofile__about-me-bio-text'>
                    <div><strong>Company Description</strong></div>

                    <div style={{marginTop: "5px"}}>{userCompany.description}</div>
                  </div>
                </div>
              </div>

              <div className='buyerprofile__main-item'>
                <div className='buyerprofile__about-me-info'>
                  {((isSeller && hasSellerRole) || !isSeller) && (
                    <span className='buyerprofile__edit-icon' onClick={() => setShowCompanyInfoEdit2(true)}>
                      <FaRegEdit />
                    </span>
                  )}
                  <div className='buyerprofile__list-item'>
                    <div className='buyerprofile__list-item-label'><strong>Company Size</strong></div>
                    <div className='buyerprofile__list-item-value'>{userProfile.company_size}</div>
                  </div>

                  <div className='buyerprofile__list-item'>
                    <div className='buyerprofile__list-item-label'><strong>Industry</strong></div>
                    <div className='buyerprofile__list-item-value'>{userProfile.industry}</div>
                  </div>

                  <div className='buyerprofile__list-item'>
                    <div className='buyerprofile__list-item-label'><strong>Website</strong></div>
                    <div className='buyerprofile__list-item-value'>{userProfile.website}</div>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        )}

{currentTab === 'Rewards' && (
          <FadeIn delay={200} duration={200}>
            <div className='buyerprofile__main'>
              <div className='buyerprofile__main-item'>
                <div><strong>Your Demo Payouts</strong></div>
                <div className='center' style={{fontSize: "24px", padding: "10px", fontWeight: "bold"}}>Total: $600</div>
              </div>

              <div className='buyerprofile__main-item'>
                
              </div>
            </div>
          </FadeIn>
        )}

        {loaded === false && (
          <Spinner />
        )}
      </div>

      {showAboutEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal buyerprofile__modal'>
            <div className='modal__close' onClick={() => setShowAboutEdit(false)}>
              <img src={close} />
            </div>
            <div className='buyerprofile__modal-header'>Edit your job position info</div>

            <div className='buyerprofile__modal-body'>
              <div>
                <label htmlFor='txtJobTitle'>Job Title</label>
                <input type="text" id="txtJobTitle" className="input" value={jobTitle} onChange={event => { setJobTitle(event.target.value); }} placeholder='Enter your job title' />
              </div>

              <div>
                <label>Seniority Level</label>
                
                <div>
                <select className="input"
                    name='seniority'
                    value={seniorityId}
                    required
                    onChange={(e) => handleSeniorityChange(e)}
                >
                    <option>Select seniority</option>
                    {
                        seniority.map((item, index) => (
                          <>
                            {newSeniority === item.seniority_level_id && (
                            <option key={index} selected value={item.seniority_level_id}>
                                {item.name}
                            </option>
                            )}

                            {newSeniority !== item.seniority_level_id && (
                            <option key={index} value={item.seniority_level_id}>
                                {item.name}
                            </option>
                            )}
                          </>
                        ))
                    }
                </select>
              </div>
              </div>

              <div>
                <label>Department</label>
                
                <select id='selectDepartment' className='input' value={departmentId} onChange={(e) => handleDepartmentChange(e)}>
                  <option>Select your department...</option>
                  {departments.map((item, index) => (
                    <>
                    {departmentId === item.department_id && (
                      <option key={index} value={item.department_id} selected>{item.name}</option>
                    )}

                    {departmentId !== item.department_id && (
                      <option key={index} value={item.department_id}>{item.name}</option>
                    )}
                    </>
                  ))}
              </select>
              </div>

              <div>
                <label>Location</label>

                <div>
                  <select className='input' id='selectCountry' value={selectedCountry} onChange={(e) => handleCountrySelect(e.target.value)}>
                    <option>Select country</option>

                    {countries.map(item => (
                      <option value={item.country_id}>{item.display_name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className='buyerprofile__modal-footer'>
              <button className='btn btn--primary' onClick={() => handleJobPositionSubmit()}>Save</button>
            </div>
          </div>
        </>
      )}

{showBioEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal buyerprofile__modal'>
            <div className='modal__close' onClick={() => setShowBioEdit(false)}>
              <img src={close} />
            </div>
            <div className='buyerprofile__modal-header'>Edit your personal info</div>

            <div className='buyerprofile__modal-body'>
              <label htmlFor='txtFirstName'>Profile Picture</label>
              <div style={{display:"flex", alignItems:"center"}}>
              <div className='buyerprofile__modal-profile-image'>
                {userProfile.profile_image && userProfile.profile_image !== '' && (
                  <img src={baseImageUrl + 'profileimages/' + userProfile.profile_image} alt="logo found" />
                )}

                {(!userProfile.profile_image || user.profile_image === '') && (
                  <img src={iconCompanyDefault} alt="logo not found" />
                )}
              </div>

              <div className="buyerprofile__modal-file-upload" style={{display: "flex"}}>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/profileimages"}
                  onError={onError} onSuccess={onSuccess}
                />
                </IKContext>
              </div>
              </div>

              <div>
                <label htmlFor='txtFirstName'>First Name</label>
                <input type="text" id="txtFirstName" className="input" value={firstName} onChange={event => { setFirstName(event.target.value); }} placeholder='Enter your first name' />
              </div>

              <div>
                <label htmlFor='txtLastName'>Last Name</label>
                <input type="text" id="txtLastName" className="input" value={lastName} onChange={event => { setLastName(event.target.value); }} placeholder='Enter your last name' />
              </div>

              <div>
                <label htmlFor='txtBio'>Biography</label>
                <textarea id="txtBio" className="input" value={biography} onChange={event => { setBiography(event.target.value); }} placeholder='Enter your biography'></textarea>
              </div>
            </div>

            <div className='buyerprofile__modal-footer'>
              <button className='btn btn--primary' onClick={() => handleBioSubmit()}>Save</button>
            </div>
          </div>
        </>
      )}

{showSocialEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal buyerprofile__modal'>
            <div className='modal__close' onClick={() => setShowSocialEdit(false)}>
              <img src={close} />
            </div>
            <div className='buyerprofile__modal-header'>Edit your social links</div>

            <div className='buyerprofile__modal-body'>
              <div>
                <label htmlFor='txtFirstName'>LinkedIn</label>
                <input type="url" id="txtLinkedIn" className="input" value={linkedin} onChange={event => { setLinkedin(event.target.value); }} placeholder='LinkedIn URL' />
              </div>
            </div>

            <div className='buyerprofile__modal-footer'>
              <button className='btn btn--primary' onClick={() => handleSocialSubmit()}>Save</button>
            </div>
          </div>
        </>
      )}

      {showCompanyInfoEdit && (
        <>
        <div className='overlay'></div>
        <div className='modal buyerprofile__modal'>
          <div className='modal__close' onClick={() => setShowCompanyInfoEdit(false)}>
            <img src={close} />
          </div>
          <div className='buyerprofile__modal-header'>Edit your company info</div>

          <div className='buyerprofile__modal-body'>
            <div style={{display:"flex", alignItems:"center"}}>
              <div className='buyerprofile__modal-profile-image'>
                {userCompany.logo && userCompany.logo !== '' && (
                  <img src={baseImageUrl + 'companylogos/' + userCompany.logo} alt="logo found" />
                )}

                {(!userCompany.logo || userCompany.logo === '') && (
                  <img src={iconCompanyDefault} alt="logo not found" />
                )}
              </div>

              <div className="buyerprofile__modal-file-upload" style={{display: "flex"}}>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/companylogos"}
                  onError={onError} onSuccess={onCompanyLogoSuccess}
                />
                </IKContext>
              </div>
            </div>

            <div>
              <label htmlFor='txtCompanyName'>Company Name</label>
              <input type="text" id="txtCompanyName" className="input" value={companyName} onChange={event => { setCompanyName(event.target.value); }} placeholder='Enter your company name' />
            </div>

            <div>
              <label htmlFor='txtCompanyDescription'>Description</label>
              <textarea id="txtCompanyDescription" className="input" value={companyDescription} onChange={event => { setCompanyDescription(event.target.value); }} placeholder='Enter your description'></textarea>
            </div>
          </div>

          <div className='buyerprofile__modal-footer'>
            <button className='btn btn--primary' onClick={() => handleCompanyInfoSave()}>Save</button>
          </div>
        </div>
      </>
      )}

{showCompanyInfoEdit2 && (
        <>
        <div className='overlay'></div>
        <div className='modal buyerprofile__modal'>
          <div className='modal__close' onClick={() => setShowCompanyInfoEdit2(false)}>
            <img src={close} />
          </div>
          <div className='buyerprofile__modal-header'>Edit your company info</div>

          <div className='buyerprofile__modal-body'>
            <div>
              <label htmlFor='selectCompanySizes'>Company Size</label>
              <select id='selectCompanySizes' className='input' value={companySizeId} onChange={(e) => setCompanySizeId(e.target.value)}>
                  <option>Select your company size...</option>
                  {companySizes.map((item, index) => (
                    <>
                    {companySizeId === item.company_size_id && (
                      <option key={index} value={item.company_size_id} selected>{item.value}</option>
                    )}

                    {companySizeId !== item.company_size_id && (
                      <option key={index} value={item.company_size_id}>{item.value}</option>
                    )}
                    </>
                  ))}
              </select>
            </div>

            <div>
              <label htmlFor='selectIndustries'>Industry</label>
              <select id='selectIndustries' className='input' value={industryId} onChange={(e) => setIndustryId(e.target.value)}>
                  <option>Select your industry...</option>
                  {industries.map((item, index) => (
                    <>
                    {industryId === item.industry_id && (
                      <option key={index} value={item.industry_id} selected>{item.name}</option>
                    )}

                    {industryId !== item.industry_id && (
                      <option key={index} value={item.industry_id}>{item.name}</option>
                    )}
                    </>
                  ))}
              </select>
            </div>

            <div>
              <label htmlFor='txtWebsite'>Website</label>
              <input type="text" id="txtWebsite" className="input" value={website} onChange={event => { setWebsite(event.target.value); }} placeholder='Enter your company website' />
            </div>
          </div>

          <div className='buyerprofile__modal-footer'>
            <button className='btn btn--primary' onClick={() => handleCompanyInfoSave2()}>Save</button>
          </div>
        </div>
      </>
      )}
    </>
  )
}

export default BuyerProfile;