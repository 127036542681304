import './ReferFriends.css';
import { useState, useEffect } from "react";
import { loginUser, sendInvites } from '../../utils/api';
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed } from '../../services/auth.service';
import patterns from '../../assets/design/patterns.png';
import list from '../../assets/design/signin/list.png';
import congrats from '../../assets/design/signup/congrats.png';

function ReferFriends({setCurrentStep, roleType}) {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');

    useEffect(() => {       
        document.title = "LeadrPro - Success!";
    }, []);

    async function handleSubmit(e){
        e.preventDefault();

        if (email1.length === 0 && email2.length === 0 && email3.length === 0){
          alert("Must enter at least one valid email");
        }

        else{
          let data = [];
          if (email1.length > 0)
          data.push(email1);

          if (email2.length > 0)
          data.push(email2);

          if (email3)
          data.push(email3);

          let _res = await sendInvites(data);

          if (roleType === "2"){
            window.location = "/dashboard/seller"
          }

          else{
            setCurrentStep(100);
          }
        }
    }

    function handleSkip(){
      // move to success creen

      if (roleType === "2"){
        window.location = "/dashboard/seller"
      }

      else{
        setCurrentStep(100);
      }
    }

    return (
        <div className="center card__wrapper">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>

        <div className="card referfriends createaccount">
            <div className="createaccount__left">
                <h1 className="card__header createaccount__left-header">Refer friends (optional)</h1>

                <div>
                    <div className="form-group">
                      <label className="label">Email 1</label>
                      <input type="email" placeholder="Email" className="input" value={email1} onChange={(e) => setEmail1(e.target.value)} />
                    </div>

                    <div className="form-group">
                      <label className="label">Email 2</label>
                      <input type="email" placeholder="Email" className="input" value={email2} onChange={(e) => setEmail2(e.target.value)} />
                    </div>

                    <div className="form-group">
                      <label className="label">Email 3</label>
                      <input type="email" placeholder="Email" className="input" value={email3} onChange={(e) => setEmail3(e.target.value)} />
                    </div>
                </div>

                <div className='form-group center'>
                  {/* <a href="" className="">Previous page</a> */}
                  <button className='btn btn-primary' onClick={handleSubmit}>Send invites</button>

                  <div className="center" style={{marginTop: "20px"}}>
                    <span className="link link--cancel" onClick={handleSkip}>Skip for now</span>
                  </div>
                </div>
                </div>

                <div className="createaccount__right">
                  <div className="login__right-header">
                      Earn $5 for every new signup!
                  </div>

                  <div className="createaccount__right-subheader">
                    Refer friends, coworkers or peers. Instant credit, available for withdrawal within 24 hours.
                  </div>
                </div>
            </div>
        </div>
    );
}

export default ReferFriends;