import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { indexCompany, adminUpdateCompany} from '../../utils/api';
import { getIndexableSellers, companyIndexLog } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaTimes } from 'react-icons/fa';

function DemosPending() {
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [indexable, setIndexable] = useState([]);
  const [indexableFilter, setIndexableFilter] = useState('All');
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    async function loadIndexable(){
      let res = await getIndexableSellers();

      if (res.success){
        setIndexable(res.data);
        setLoaded(true);
      }

      else if (res.status == 403){
        await refresh();

        let res = await getIndexableSellers();

        if (res.success){
          setIndexable(res.data);
          setLoaded(true);
        }
      }
    }

    loadIndexable();
  }, []);

  async function loadIndexable(){
    let res = await getIndexableSellers();

    if (res.success){
      setIndexable(res.data);
      setLoaded(true);
    }

    else if (res.status == 403){
      await refresh();

      let res = await getIndexableSellers();

      if (res.success){
        setIndexable(res.data);
        setLoaded(true);
      }
    }
  }

  async function handlePageIndex(company_id){
    let res = await indexCompany(company_id);

    if (res.success){
      await loadIndexable();
    }

    else if (res.status === 403){
      await refresh();

      let res = await indexCompany(company_id);

      if (res.success){
        await loadIndexable();
      }
    }
  }

  async function handleIndexSubmit(company_id){
    let res = await companyIndexLog(company_id);

    if (res.success){
      loadIndexable();
    }

    else if (res.status === 403){
      await refresh();

      let res = await companyIndexLog(company_id);

      if (res.success){
        loadIndexable();
      }
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleUpdateCanonical(id, item){
    setCurrentUrl(`${item.url_slug}`);
    setSelectedId(id);
    setShowEdit(true);
  }

  async function confirmUpdate(){
    let data = { url_slug: currentUrl};
    let res = await adminUpdateCompany(selectedId, data, 'Updating canonical url');

    if (res.success){
      setShowEdit(false);
      loadSaveBanner();
      loadIndexable();
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminUpdateCompany(selectedId, data, 'Updating canonical url');

      if (res.success){
        setShowEdit(false);
        loadSaveBanner();
        loadIndexable();
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Company Indexing</title>
      </Helmet>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
      <div>
            <h1>Indexes - {indexable.filter(x => {
                if (indexableFilter === 'All')
                  return x;

                else if (indexableFilter === 'Indexed')
                {
                  if (x.is_indexed === true)
                    return x;
                }

                else if (indexableFilter === 'NotIndexed'){
                  if (x.is_indexed === false)
                    return x;
                }
              }).length}</h1>

            <div className='admindashboard__index-filters'>
              <button className={`btn ${indexableFilter === 'All' ? 'admindashboard__selected' : ''}`} onClick={() => setIndexableFilter('All')}>All</button>

              <button className={`btn ${indexableFilter === 'Indexed' ?'admindashboard__selected': ''}`} onClick={() => setIndexableFilter('Indexed')}>Indexed</button>

              <button className={`btn ${indexableFilter === 'NotIndexed' ? 'admindashboard__selected': ''}`} onClick={() => setIndexableFilter('NotIndexed')}>Not indexed</button>
            </div>

            <div className='admindashboard__indexes'>
              {indexable.filter(x => {
                if (indexableFilter === 'All')
                  return x;

                else if (indexableFilter === 'Indexed')
                {
                  if (x.is_indexed === true)
                    return x;
                }

                else if (indexableFilter === 'NotIndexed'){
                  if (x.is_indexed === false)
                    return x;
                }
              }).map(item => (
                <div className='admindashboard__index-item'>
                  <div className='buyerdashboard__recommendation-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>

                  <div>
                    <div className='admindashboard__table-name'>#{item.company_id} - {item.name}</div>
                      {/* <div><a href={item.website} target='_blank'>Company website</a></div> */}
                      <div style={{fontSize: '12px'}}><strong>Date joined:</strong> {new Date(item.date_created).toLocaleDateString()}</div>

                      {item.last_active && (
                        <div style={{fontSize: '12px'}}>Last active: {new Date(item.last_active).toLocaleDateString()}</div>
                      )}

                      <div>
                        <strong>Canonical URL: </strong>
                       
                        <a href={`https://www.leadrpro.com/company/${item.company_id}/${item.url_slug}`}>{`https://www.leadrpro.com/company/${item.company_id}/${item.url_slug}`}</a>

                        <div className='link' onClick={() => handleUpdateCanonical(item.company_id, item)}>
                          Edit URL
                        </div>
                      </div>

                      <div>
                        <strong>Last submitted: </strong>
                        {item.last_index_date && (
                          <span>{new Date(item.last_index_date).toLocaleDateString()}</span>
                        )}

                        {item.last_index_date == null && (
                          <span>Never</span>
                        )}
                      </div>

                      <div className='mt-10'>
                        {item.is_indexed == true && (
                          <span className='admindashboard__green-flag'>Is Indexed</span>
                        )}

                        {item.is_indexed === false && (
                          <span className='admindashboard__red-flag'>Not Indexed</span>
                        )}
                      </div>

                      {item.is_indexed == false && (
                        <div className='mt-20 admindashboard__btns'>
                          <button className='btn' onClick={() => handlePageIndex(item.company_id)}>Mark as indexed</button>

                          <button className='btn' onClick={() => handleIndexSubmit(item.company_id)}>Submitted</button>
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
      )}

      {showEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Edit URL Slug</h2>

            <div className='mt-10'>
              <input type='text' className='input' value={currentUrl} onChange={(e) => setCurrentUrl(e.target.value)} />

              <div className='mt-10'><button className='btn' onClick={() => confirmUpdate()}>Update</button></div>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default DemosPending;
;
