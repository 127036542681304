import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getAppUsers } from '../../utils/api/admin';

function AppUsers() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getAppUsers();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <>
      <Helmet>
        <title>Admin - App Users</title>
      </Helmet>

      <h1>App Users {`(${data.length})`}</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
         <table className='admindashboard__table table'>
          <tr>
            <th>Date Joined</th>
            <th></th>
            <th>Source channel</th>
            <th>Company</th>
            <th>User</th>
            <th></th>
          </tr>

          {data.map(item => (
            <tr>
              <td>
                {new Date(item.date_created).toDateString()}
              </td>
              <td>
                <div className='admindashboard__logo'>
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                </div>
              </td>

              <td>
                <div><strong>Channel: </strong> {item.channel}</div>
                <div><strong>Campaign: </strong> {item.campaign}</div>
              </td>

              <td>
                <strong>{item.name}</strong>
              </td>

              <td><strong>#{item.registered_user_id}.</strong> {item.first_name} {item.last_name}</td>

              <td>
                {item.is_buyer && (
                  <span className='admindashboard__blue-flag'>Buyer</span>
                )}

                {item.is_seller && (
                  <span className='admindashboard__red-flag'>Seller</span>
                )}

                {item.is_event_host && (
                  <span className='admindashboard__yellow-flag'>Host</span>
                )}

              {item.is_event_attendee && (
                  <span className='admindashboard__green-flag'>Attendee</span>
                )}
              </td>
            </tr>
          ))}
         </table>
        </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default AppUsers;