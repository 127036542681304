import { useEffect, useState } from 'react';
import close from '../../assets/design/dashboard/close.png';
import { getSeoName } from '../../utils/utils';
import { getBuyerDemosCompleted } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';

const BuyerDemosCompleted = ({  }) => {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  useEffect(() => {
    async function loadDemosCompleted(){
      let res = await getBuyerDemosCompleted();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getBuyerDemosCompleted();

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
        }
      }
    }

    loadDemosCompleted();
  }, []);

  function getTrimmedString(inputString = '') {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        // Truncate the string to the maximum length minus 1 (for the ellipsis)
        // and ensure we don't cut off in the middle of a word.
        let trimmedString = inputString.substr(0, maxLength - 1);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return `${trimmedString}…`;
    }
  }

  return (
    <>
      {loaded === false && (
        <Spinner />
      )}

      {loaded && demos.filter((demo) => demo.demo_status === 'Completed' && demo.feedback_count !== '0').length === 0 && (
        <div className='dashboarddemo__no-results'>
          <span>You have not completed any demos</span>
        </div>
      )}

      {loaded && demos.filter((demo) => demo.demo_status === 'Completed' && demo.feedback_count !== '0').length > 0 && (
        <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
          <tr>
            <th>Company</th>
            <th>Feedback</th>
          </tr>
        {demos.filter(
            (demo) => demo.demo_status === 'Completed' && demo.feedback_count !== '0')
          .map((item, index) => (
            <tr key={index}>
              <td style={{width: '80%'}}>
              <div style={{display: 'flex', alignItems: 'start'}}>
              <div className='buyerdashboard__recommendation-thumbnail' style={{width: '40%'}}>
              {item.thumbnail_image && (
                <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                )}
              </div>
              
              <div className='buyerdashboard__recommendation-name'>
                <div style={{fontSize: '20px'}}><Link to={`/company/${item.company_id}/${item.url_slug}`}>{item.company_name}</Link></div>

                <div style={{fontSize: "12px"}}>
                  <strong>Met with: </strong>{item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                </div>

                <div style={{fontSize: "12px"}}>
                  <strong>Met on: <span className='dashboard__table-date'>
                    {new Date(
                      item.demo_date
                    ).toLocaleDateString()}
                    </span></strong>
                </div>

                <div className='buyerdashboard__recommendation-description' style={{fontSize: '12px'}}>
                  {item.description && item.description.length > 0 && (
                    <>
                      {getTrimmedString(item.description)}
                    </>
                  )}
                </div>
              </div>
              </div>
            </td>

              {/* <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              </td> */}

              {/* <td>
                  <div className='dashboarddemo__company-name'>
                    <span className='dashboarddemo__company-name'>
                      {item.company_name}
                    </span>

                      <div className='dashboarddemo__company-info-items'>
                      {item.seller_first_name && item.seller_first_name.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Met with:{' '}
                        </span>
                        <span>{item.seller_first_name}</span>{' '}

                        {item.seller_last_name && item.seller_last_name.length > 0 && (
                          <span>
                            {item.seller_last_name.substring(0,1).toUpperCase()}.
                          </span>
                        )}
                      </div>
                    )}

                    {item.seller_email && item.seller_email.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Follow-up email:{' '}
                        </span>
                        <span>{item.seller_email}</span>{' '}
                      </div>
                    )}
                    </div>
                  </div>
                </td> */}

                {/* <td>
                    <div className=''>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                            <img src={baseimageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                    </td> */}
{/* 
                <td>
                  <div className='dashboarddemo__company-info-items'>
                  <div className='dashboarddemo__company-info-item'>
                    <span className='dashboarddemo__company-info-label'>
                      <span className='dashboard__table-date'>
                    
                      {new Date(
                        item.demo_date
                      ).toLocaleDateString()}
                      </span>
                    </span>
                  </div>
                  </div>
                </td> */}

              <td>           
              <div className='dashboarddemo__actions'>
                {item.buyer_feedback_option_id != null && (
                  <div className='dashboarddemo__company-info-item'>

                      <span className='dashboard__table-feedback'>
                        {(item.buyer_feedback_option_id != '4' && item.buyer_feedback_option_id != '5') ? '✅' : '❌'}{' - '}
                        {item.feedback_text}
                      </span>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
          </table>
          </div>
)}
    </>
  )
}

export default BuyerDemosCompleted;