import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import LeadCard from '../../components/qualifier/LeadCard';
import {getTeamDemosCompleted, getTeamDemosCompletedSearch, getDemoBuyerFeedback, getDemoQualifierAnswers} from '../../utils/api';
import { getCurrentUser, refresh } from '../../services/auth.service';
import { checkSession } from '../../utils/utils';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import Spinner from '../../components/layout/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { FaEllipsisH, FaEllipsisV } from 'react-icons/fa';
import FadeIn from '../../utils/fadein';

const SellerDemosCompleted = () => {
  let navigate = useNavigate();
  const [currentDemo, setCurrentDemo] = useState({});
  const [showBuyerFeedbackDetails, setShowBuyerFeedbackDetails] = useState(false);
  const [showQualifierAnswers, setShowQualifierAnswers] = useState(false);
  const [demos, setDemos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [buyerFeedback, setBuyerFeedback] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const currentUser = getCurrentUser();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  useEffect(() => {
    async function loadTeamDemos(){
      let res = await getTeamDemosCompleted();

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getTeamDemosCompleted();

        if (res.success){
          setDemos(res.data);
        }

        setLoaded(true);
      }
    }

    loadTeamDemos();
    checkSession('Seller Completed');
  }, []);

  async function loadBuyerFeedback(demo_id, item){
    setSelectedId(demo_id);
    setLoading(true);

    let res = await getDemoBuyerFeedback(demo_id);
    
    if (res.success){
      setBuyerFeedback(res.data[0]);
      setCurrentDemo(item);
      setShowBuyerFeedbackDetails(true);
      setLoading(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getDemoBuyerFeedback(demo_id);
    
      if (res.success){
        setBuyerFeedback(res.data[0]);
        setCurrentDemo(item);
        setShowBuyerFeedbackDetails(true);
        setLoading(false);
      }
    }
  }

  // first_name, last_name, job_title, linkedin_url, company_name, company_size, demo_date, moving_to_proposal, feedback1, feedback2, feedback3
  function csvExport(){
    let filter = demos.filter(item => {
      if (item.demo_status === 'Completed'){
        return item;
      }
    });

    let allrows = [];

      let row0 = [];
      row0.push('First name');
      row0.push('Last name');
      row0.push('Email');
      row0.push('Job title');
      row0.push('LinkedIn URL');
      row0.push('Company name');
      row0.push('Company size');
      row0.push('Demo date');

      allrows.push(row0);

    let rows = filter.map((item, index) => {
      let row = [];

      row.push(item.first_name);
      row.push(item.last_name);
      row.push(item.buyer_email);
      row.push(item.job_title.replace(',', ''));
      row.push(item.linkedin_url);
      row.push(item.company_name);

      if (item.company_size)
        row.push(item.company_size.replace(',', ''));
      else
        row.push('');
      
      if (item.reschedule_date_time){
        row.push(item.reschedule_date_time);
      }

      else if (item.scheduled_date_time){
        row.push(item.scheduled_date_time);
      }

      return row;
    });

    let result = [...allrows, ...rows];
    
    let csvContent = "data:text/csv;charset=utf-8,";
    
    result.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  async function handleAnswersClick(item){
    let res = await getDemoQualifierAnswers(item.demo_id);

    if (res.success){
      let new_item = {...item, qualifier_answers: res.data};
      setCurrentDemo(new_item);
      setShowQualifierAnswers(true);
      setShowSubMenu(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getDemoQualifierAnswers(item.demo_id);

      if (res.success){
        let new_item = {...item, qualifier_answers: res.data};
        setCurrentDemo(new_item);
        setShowQualifierAnswers(true);
        setShowSubMenu(false);
      }
    }
  }

  async function loadTeamDemos(){
    let res = await getTeamDemosCompleted();

    if (res.success){
      setDemos(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getTeamDemosCompleted();

      if (res.success){
        setDemos(res.data);
      }

      setLoaded(true);
    }
  }

  async function handleKeyUp(val){
    if (val.length > 2){
      let res = await getTeamDemosCompletedSearch(val);

      if (res.success){
        setDemos(res.data);
        setLoaded(true);
      }
  
      else if (res.status === 403){
        await refresh();
  
        let res = await getTeamDemosCompletedSearch(val);

        if (res.success){
          setDemos(res.data);
        }

        setLoaded(true);
      }
    }

    else{
      await loadTeamDemos();
    }
  }

  async function handleToggleMenu(item){
    if (item.demo_id == currentDemo.demo_id){
      setCurrentDemo(item);
      setShowSubMenu(!showSubMenu);
    }

    else{
      setCurrentDemo(item);
      setShowSubMenu(true);
    }
  }

  return (
    <>
    <div>
      {loaded === false && (
        <Spinner />
      )}

        {loaded && demos.length > 0 && (
          <div className='dashboard__table-menu'>
            <div style={{fontSize: "12px", marginTop: "10px", marginBottom: "10px"}}>
              <span className='btn' style={{padding: '5px 10px'}} onClick={() => csvExport()}>Export completed demo's to CSV</span>
            </div>

            <div>
              <input className='input dashboard__input-search' type='text' placeholder='Enter search query...' onKeyUp={(e) => handleKeyUp(e.target.value)} />
            </div>
          </div>
        )}

          {demos.length === 0 && loaded === true && (
          <div className='dashboarddemo__no-results'>
            No demos found.
          </div>
        )}

        {loaded && demos.length > 0 && (
          <div className='dashboard__table-wrapper'>
        <table className='table dashboard__table'>
          <tr>
            <th></th>
            <th>Company</th>
            <th>Met on</th>
            <th>Assigned</th>
            <th>Feedback</th>
            <th></th>
          </tr>
      {demos.map((item, index) => (
            <tr key={index}>
              <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>
              </td>

              <td>
                  <div className='dashboarddemo__company-name'>
                    {item.first_name && item.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{item.first_name} {item.last_name}</span>

                        {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <Link to={`/dashboard/message/${item.reference_id}`} className='dashboarddemo__open-chat'>Open Chat</Link>
                        )}
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {item.job_title && item.job_title.length > 0 && (
                        <span className=''>{item.job_title}</span>
                      )}
                      
                      {!item.job_title && (
                        <span>{item.persona_type}</span>
                      )} at {item.company_name}
                    </span>
                  </div>
                </td>

                    <td>
                      <div className='dashboarddemo__company-info-items'>
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          <span className='dashboard__table-date'>
                       
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          </span>
                        </span>
                      </div>
                      </div>
                    </td>

                    <td>
                    <div className='center'>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                    <div className='dashboarddemo__actions'>

                    {item.buyer_feedback_option_id != null && (
                      <div className='dashboarddemo__company-info-item'>
                          <div className='dashboarddemo__company-info-label'>
                            <strong>Wants to follow up?</strong>
                          </div>

                          <span className='dashboard__table-feedback'>
                            {(item.buyer_feedback_option_id != '4' && item.buyer_feedback_option_id != '5') ? '✅' : '❌'}{' - '}
                            {item.feedback_text}
                          </span>
                        </div>
                      )}

                      {item.buyer_feedback_count > 0 && (
                        <div className='' style={{marginTop: '10px'}}>
                          {loading === false && (
                            <button className='dashboard__table-feedback-btn' onClick={() => loadBuyerFeedback(item.demo_id, item)}>View  all feedback</button>
                          )}

                          {loading === true && selectedId == item.demo_id && (
                            <Spinner />
                          )}
                        </div>
                      )}

                      {item.buyer_feedback_count == 0 && (
                        <div className='dashboard__waiting-for-feedback'>
                          Waiting for feedback
                        </div>
                      )}
                    </div>
                  </td>

                  <td className='dashboard__table-menu-icon'>
                    <span className='dashboard__submenu-icon' onClick={() => handleToggleMenu(item)}>
                      <FaEllipsisV />
                    </span>

                    {showSubMenu == true && currentDemo.demo_id == item.demo_id && (
                      <FadeIn delay={100} duration={100}>
                    <div className='dashboard__submenu'>
                      <div className='dashboard__submenu-item' onClick={() => handleAnswersClick(item)}>Buyer Details</div>

                      {item.buyer_feedback_count > 0 && loaded && (
                      <div className='dashboard__submenu-item' onClick={() => loadBuyerFeedback(item.demo_id, item)}>Open Feedback</div>
                      )}

                      {item.reference_id && currentUser.registered_user_id == item.seller_id && (
                        <div onClick={() => navigate(`/dashboard/message/${item.reference_id}`)} className='dashboard__submenu-item'>Go to messages</div>
                      )}
                    </div>
                    </FadeIn>
                    )}
                  </td>
            </tr>
          ))}
          </table>
          </div>
        )}

        {/* {demos.filter((demo) =>
          demo.demo_status === 'Completed')
          .map((item) => (
            <>
              <div className='sellerupcomingdemos__container'>
                <div className='dashboarddemo'>
                    <div className='dashboarddemo__company-logo'>
                      <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                    </div>

                    <div className='dashboarddemo__company-info'>
                      <div className='dashboarddemo__company-name'>
                      {item.first_name && item.first_name.length > 0 && (
                        <div className='dashboarddemo__name'>
                          <span>{item.first_name} {item.last_name}</span>

                          {item.reference_id && currentUser.registered_user_id == currentDemo.seller_id && (
                          <Link to={`/dashboard/message/${item.reference_id}`} className='dashboarddemo__open-chat'>Open Chat</Link>
                          )}
                        </div>
                      )}

                        <span className=''>
                          {item.job_title && item.job_title.length > 0 && (
                            <span>{item.job_title}</span>
                          )}
                          
                          {!item.job_title && (
                            <span>{item.persona_type}</span>
                          )} at {item.company_name}
                        </span>
                      </div>

                      <div className='dashboarddemo__company-info-items'>
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          <span>Scheduled On:{' '}</span>
                          <span>
                          
                          {new Date(
                            item.demo_date
                          ).toLocaleDateString()}
                          <br />
                          at{' '}
                          {new Date(
                            item.demo_date
                          ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                          {' - '}
                          {moment.tz.guess()}
                          </span>
                        </span>
                      </div>

                      {item.buyer_feedback_option_id != null && (
                      <div className='dashboarddemo__company-info-item'>
                          <span className='dashboarddemo__company-info-label'>
                            Wants to follow up:{' '}
                          </span>
                          <span>
                            {(item.buyer_feedback_option_id != '4' && item.buyer_feedback_option_id != '5') ? '✅' : '❌'}{' - '}
                            {item.feedback_text}
                          </span>
                        </div>
                      )}
                      </div>
                    </div>

                    <div className='dashboarddemo__date'>

<div className='center'>
                        <div style={{fontSize: "11px", marginBottom: "10px"}}>Assigned to:</div>
                        <div>
                          <div className='dashboarddemo__team-member-profile-image' style={{display:"inline-block"}}>
                          {item.seller_profile_image && item.seller_profile_image != '' && (
                            <img src={baseimageUrl + 'profileimages/' + item.seller_profile_image} alt="logo found" />
                          )}

                          {!item.seller_profile_image && (
                            <img src={iconCompanyDefault} alt="logo not found" />
                          )}
                          </div>
                          <div style={{fontSize: "12px"}}>
                            {item.seller_first_name} {(item.seller_last_name && item.seller_last_name.length > 0) ? item.seller_last_name.substring(0, 1) +'.' : '' }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='dashboarddemo__actions'>
                      {item.buyer_feedback_count > 0 && (
                        <div>
                          <button className='btn btn--primary' onClick={() => loadBuyerFeedback(item.demo_id, item)}>View feedback</button>
                        </div>
                      )}

                      <div className='dashboarddemo__actions-links'>
                              <div className='btn btn--primary' onClick={() => handleAnswersClick(item)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{height: "15px", verticalAlign: "middle"}}><path d="M208 256c35.35 0 64-28.65 64-64c0-35.35-28.65-64-64-64s-64 28.65-64 64C144 227.3 172.7 256 208 256zM464 232h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 232 464 232zM240 288h-64C131.8 288 96 323.8 96 368C96 376.8 103.2 384 112 384h192c8.836 0 16-7.164 16-16C320 323.8 284.2 288 240 288zM464 152h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 152 464 152zM512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416z"/></svg> <span style={{verticalAlign: "middle"}}>Company info</span></div>
                          </div>

                      {item.buyer_feedback_count == 0 && (
                        <div className='dashboarddemo__actions-text'>
                          Waiting for feedback
                        </div>
                      )}
                    </div>
                </div>
              </div>
            </>
          ))} */}

          {showBuyerFeedbackDetails && (
            <>
              <div className='overlay'></div>
              <div className='modal dashboarddemo__feedback-modal'>
                <img src={close} className='modal__close' onClick={() => setShowBuyerFeedbackDetails(false)} />
                <table className='table dashboard__table'>
                  <tr>
                  <td>
                  <div className='dashboard__table-company-logo'>
                    <img src={imageUrl +'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} />
                  </div>
              </td>

              <td>
                  <div className='dashboarddemo__company-name'>
                    {currentDemo.first_name && currentDemo.first_name.length > 0 && (
                      <div className='dashboarddemo__name'>
                        <span className='dashboard__table-name'>{currentDemo.first_name} {currentDemo.last_name}</span>
                      </div>
                    )}

                    <span className='dashboard__table-job-title'>
                      {currentDemo.job_title && currentDemo.job_title.length > 0 && (
                        <span className=''>{currentDemo.job_title}</span>
                      )}
                      
                      {!currentDemo.job_title && (
                        <span>{currentDemo.persona_type}</span>
                      )} at {currentDemo.company_name}
                    </span>
                  </div>
                </td>
                  </tr>
                </table>

                <div className='detailspopup__inner-item-container'>

                <table className='table dashboard__table dashboard__feedback-table'>
                  <tr>
                    {buyerFeedback.feedback_option && (
                      <>
                      <td className=''>
                        <h3>Are you interested in moving forward?</h3>
                      </td>

                      <td>
                        <p className=''>
                          {buyerFeedback.feedback_option}
                        </p>
                      </td>
                      </>
                    )}
                  </tr>

                    {buyerFeedback.feedback_text && (
                      <tr>
                        <td>Feedback</td>
                        <td className=''>
                          {buyerFeedback.feedback_text}
                        </td>
                      </tr>
                    )}

                  {buyerFeedback.feedback1 && (
                    <tr className=''>
                      <td>What, if anything, did you find most appealing about the product?</td>

                      <td className=''>
                        {buyerFeedback.feedback1}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.feedback2 && (
                    <tr className=''>
                      <td>What, if anything, are your hesitations/concerns about the product?</td>

                      <td className=''>
                        {buyerFeedback.feedback2}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.feedback3 && (
                    <tr className=''>
                      <td>Was there anything you wish to have seen or discussed in this presentation that was not?</td>

                      <td className=''>
                        {buyerFeedback.feedback3}
                      </td>
                    </tr>
                  )}

                  {buyerFeedback.does_product_fix && (
                    <tr>
                      <td>
                        Does this product/service fix a problem that you are having?
                      </td>

                      <td>
                        {buyerFeedback.does_product_fix}
                      </td>
                    </tr>
                  )}

                </table>

                {/* {buyerFeedback.feedback_option && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>Are you interested in moving forward?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {buyerFeedback.feedback_option}
                      </p>
                    </div>
                  )} */}

                  {/* <div className='detailspopup__comments-container'>
                    {buyerFeedback.feedback_text && (
                      <>
                        <h3>Feedback</h3>
                        <p className='detailspopup__comments-text'>
                          {buyerFeedback.feedback_text}
                        </p>
                      </>
                    )}
                  </div> */}

                  {buyerFeedback.buyer_feedback_option_id != '5' && (
                    <div className='dashboarddemo__feedback-contact-info'>
                      <div><strong>Contact name: </strong> {buyerFeedback.first_name} {buyerFeedback.last_name}</div>

                      <div><strong>Contact email: </strong> 
                      {buyerFeedback.buyer_work_email && (
                        <>
                          {buyerFeedback.buyer_work_email}
                        </>
                      )}
                      
                      {!buyerFeedback.buyer_work_email && (
                        <>
                          {buyerFeedback.email}
                        </>
                      )}
                      </div>
                    </div>
                  )}

                  {/* {currentDemo.buyer_feedback[0].feedback1 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>What, if anything, did you find most appealing about the product?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {currentDemo.buyer_feedback[0].feedback1}
                      </p>
                    </div>
                  )} */}

                  {/* {buyerFeedback.feedback1 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>What, if anything, did you find most appealing about the product?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {buyerFeedback.feedback1}
                      </p>
                    </div>
                  )} */}

                  {/* {currentDemo.buyer_feedback[0].feedback2 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>What, if anything, are your hesitations/concerns about the product?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {currentDemo.buyer_feedback[0].feedback2}
                      </p>
                    </div>
                  )} */}

                  {/* {buyerFeedback.feedback2 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>What, if anything, are your hesitations/concerns about the product?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {buyerFeedback.feedback2}
                      </p>
                    </div>
                  )} */}

                  {/* {currentDemo.buyer_feedback[0].feedback3 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>Was there anything you wish to have seen or discussed in this presentation that was not?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {currentDemo.buyer_feedback[0].feedback3}
                      </p>
                    </div>
                  )} */}

                  {/* {buyerFeedback.feedback3 && (
                    <div className='dashboarddemo__feedback-modal-item'>
                      <h3>Was there anything you wish to have seen or discussed in this presentation that was not?</h3>

                      <p className='dashboarddemo__feedback-modal-answer'>
                        {buyerFeedback.feedback3}
                      </p>
                    </div>
                  )} */}

                  {/* <div className='detailspopup__comments-container'>
                    {currentDemo.buyer_feedback[0].feedback_text && (
                      <>
                        <h3>Feedback</h3>
                        <p className='detailspopup__comments-text'>
                          {currentDemo.buyer_feedback[0].feedback_text}
                        </p>
                      </>
                    )}
                  </div> */}

                  <div className='center mar20'>
                    <button className='btn btn--primary' onClick={() => setShowBuyerFeedbackDetails(false) }>Close</button>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>

      {showQualifierAnswers && (
        <>
          <div className='overlay'></div>
          <LeadCard currentDemo={currentDemo} setShowQualifierAnswers={setShowQualifierAnswers} show_buttons={false} />
        </>
      )}
    </>
  )
}

  export default  SellerDemosCompleted;