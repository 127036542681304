import './PayoutMethodAdd.css';
import {useEffect, useState} from 'react';
import React from 'react';
import { getPayoutMethods, savePayoutMethod } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { refresh } from 'aos';

const PayoutMethodAdd = ({userType}) => {
  const [methods, setMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [source, setSource] = useState('');
  let refSource = React.createRef();
    
  //useNavigate
  const navigate = useNavigate();

  useEffect(() => {
    async function loadPayoutMethods(){
      let res = await getPayoutMethods();

      if (res.success){
        setMethods(res.data);
      }
    }

    loadPayoutMethods();
    document.title = "LeadrPro - Manage Payouts";
  }, []);

  function handleSelectPayoutMethod(id){
    let item = methods.filter(item => item.payout_method_id === id)[0];
    setSelectedMethod(item);
    setShowModal(true);
  }

  // save 'source' to current user
  async function handleSavePayoutMethod(){
    if (refSource.current.value !== ''){
      let res = await savePayoutMethod(selectedMethod.payout_method_id, refSource.current.value);

      if (res.success){
        setShowModal(false);
        navigate('/account/payout');
      }
    }

    else{
      alert("Please enter a value");
    }
  }

  return (
    <div className="accountinfo payoutmethodadd">
      <div className="account__back-link"><a href="/account/payout">Back</a></div>
      <div className="account__header">Add payout method</div>

      <div className='accountinfo__form payoutmethods__form'>
        <h3>Choose one of the methods</h3>

        <div className="payoutmethodadd__methods">
          {methods.map(item => (
            <div className="payoutmethodadd__method">
                <div className="payoutmethodadd__method-name">
                  <span><img className="payoutinfo__method-icon" src={"/assets/payoutmethods/" + item.icon_url} alt="" />
                  </span>

                  <span>{item.display_name}</span>
                </div>

                <div className="payoutmethodadd__choose">
                  <button className="btn btn--primary" onClick={() => handleSelectPayoutMethod(item.payout_method_id)}>Choose</button>
                </div>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
      <div className="overlay">
      <div className="modal payoutmethodadd__modal">
        <div>
        <img className="payoutmethodadd__method-icon" src={"/assets/payoutmethods/lg-" + selectedMethod.icon_url} alt="" />
        </div>

        <div style={{textAlign:"left"}}>
          <label className="label">Username</label>
          <input type="text" className="input" ref={refSource} value={source} onChange={(e) => setSource(e.target.value)} />
        </div>

        <div className="payoutmethodadd__btns">
          <div><button className="btn btn--primary" onClick={() => handleSavePayoutMethod()}>Submit</button></div>
          <div><a href="javascript:void(0)" className="link--cancel" onClick={() => setShowModal(false)}>Cancel</a></div>
        </div>
      </div>
      </div>
      )}
    </div>
  )
}

  export default PayoutMethodAdd;